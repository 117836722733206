import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  admissionProgramId,
  admissionTypeId,
  blockDepartmentId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  examinationId,
  examTypeID,
  facultyDepartmentId,
  facultyTypeId,
  hostelId,
  hostelTypeId,
  programs,
  Search,
  Select,
  SessionStorage,
} from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert, DeleteWithConfirmation } from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
  RESET_SEARCH_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
  Setup_Admission_Blocks,
  Hostel_MangeHostel,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_SeatType,
  Setup_CreateSetupDetail,
} from "../../utils/Config";

const initialSearchFields = {
  OperationId: 0,
  MHostelId: 0,
  HostelId: 0,
  TotalNoOfRooms: 0,
  Address: "",
  Note: "",
  IsActive: 1,
  IsDeleted: 0,
  UserId: 0,
};
const initialFormFields = {
  OperationId: 1,
  MHostelId: 0,
  HostelId: 0,
  TotalNoOfRooms: 0,
  Address: "",
  Note: "",
  IsActive: 0,
  IsDeleted: 0,
  UserId: 0,
};
const initialSetupFields = {
  MasterId: 0,
  Detail: "",
  ParentId: 0,
  UserId: 0,
};
const initialHotelTypeFields = {
  MasterId: 0,
  Detail: "",
  ParentId: 0,
  UserId: 0,
};

const ManageHostel = () => {
  initialSetupFields.UserId = decryptData("loginId", SessionStorage);
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [dropdownList, setDropDownList] = useState([]);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleFormHotelType, setToggleFormHotelType] = useState(false);
  const [setupFields, setSetupFields] = useState(initialSetupFields);
  const [hotelTypeFields, setHotelTypeFields] = useState(
    initialHotelTypeFields
  );
  useEffect(() => {
    manageHostelData();
    getDropDown();
  }, []);

  const manageHostelData = () => {
    Hostel_MangeHostel(initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function getDropDown() {
    const payload = {
      operationId: Select,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        setDropDownList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const columns = [
    { field: "HostelName", name: "Hostel Name" },
    { field: "HostelTypeName", name: "Hostel Type Name" },
    { field: "TotalNoOfRooms", name: "Total Rooms" },
    { field: "Address", name: "Address" },
    { field: "Note", name: "Note" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const handleSetupChange = (e) => {
    setSetupFields({
      ...setupFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleSetupChangeHotelType = (e) => {
    setHotelTypeFields({
      ...hotelTypeFields,
      [e.target.name]: e.target.value,
    });
  };
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == hostelId)}
          label="Hostel"
          name="HostelId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.HostelId}
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="No Of Rooms"
          name="TotalNoOfRooms"
          onChange={handleSearchChange}
          value={SearchFields?.TotalNoOfRooms}
          isNumber="true"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Address"
          name="Address"
          onChange={handleSearchChange}
          value={SearchFields?.Address}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Note"
          name="Note"
          onChange={handleSearchChange}
          value={SearchFields?.Note}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      OperationId: 0,
      MHostelId: 0,
      HostelId: SearchFields?.HostelId,
      TotalNoOfRooms: SearchFields?.TotalNoOfRooms,
      Address: SearchFields?.Address,
      Note: SearchFields?.Note,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    Hostel_MangeHostel(payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function createSetup(payload) {
    Setup_CreateSetupDetail(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              if (payload.MasterId == 1142) {
                setToggleFormHotelType(false);
                setHotelTypeFields({
                  ...hotelTypeFields,
                });
              } else {
                setToggleForm(false);
                setSetupFields({
                  ...initialSetupFields,
                });
              }
              getDropDown();
            } else if (results.isConfirmed) {
              if (payload.MasterId == 1142) {
                setToggleFormHotelType(false);
                setHotelTypeFields({
                  ...hotelTypeFields,
                });
              } else {
                setToggleForm(false);
                setSetupFields({
                  ...initialSetupFields,
                });
              }
              getDropDown();
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  }
  const submitForm = (id) => {
    const payload = {
      OperationId: 1,
      MHostelId: FormFields?.MHostelId,
      HostelId: FormFields?.HostelId,
      TotalNoOfRooms: FormFields?.TotalNoOfRooms,
      Address: FormFields?.Address,
      Note: FormFields?.Note,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };
    Hostel_MangeHostel(payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          manageHostelData();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    const data = {
      OperationId: 1,
      MHostelId: obj?.MHostelId,
      HostelId: obj?.HostelId,
      TotalNoOfRooms: obj?.TotalNoOfRooms,
      Address: obj?.Address,
      Note: obj?.Note,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        const data = {
          OperationId: 1,
          MHostelId: obj?.MHostelId,
          HostelId: obj?.HostelId,
          TotalNoOfRooms: obj?.TotalNoOfRooms,
          Address: obj?.Address,
          Note: obj?.Note,
          IsActive: 0,
          IsDeleted: 0,
          UserId: decryptData("loginId", SessionStorage),
        };

        Hostel_MangeHostel(data)
          .then((res) => {
            if (res.data.Table[0].HasError === 0) {
              CustomSuccessAlert(res.data.Table[0].Message);
              manageHostelData();
            } else {
              CustomErrorMessage(res.data.Table[0].Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
    manageHostelData();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const onAddSetup = (e, masterId) => {
    e.preventDefault();
    setToggleForm(true);
    setSetupFields({
      ...setupFields,
      MasterId: masterId,
    });
  };
  const handleCancelSetup = () => {
    setToggleForm(false);
    manageHostelData();
  };
  const submitSetup = (e) => {
    e.preventDefault();
    createSetup(setupFields);
  };
  const onAddSetupHotelType = (e, masterId) => {
    e.preventDefault();
    setToggleFormHotelType(true);
    setHotelTypeFields({
      ...hotelTypeFields,
      MasterId: masterId,
    });
  };
  const handleCancelSetupHotelType = () => {
    setToggleFormHotelType(false);
    manageHostelData();
  };
  const submitSetupHotelType = (e) => {
    e.preventDefault();
    createSetup(hotelTypeFields);
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == hostelId)}
          label="Hostel"
          name="HostelId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.HostelId}
          onChange={handleAddChange}
        />
        <p
          style={{ fontSize: "12px", marginBottom: "0px" }}
          className="text-right"
        >
          <a href="#" onClick={(e) => onAddSetup(e, 1144)}>
            Add Hostel
          </a>
        </p>
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="No Of Rooms"
          name="TotalNoOfRooms"
          onChange={handleAddChange}
          value={FormFields?.TotalNoOfRooms}
          isNumber="true"
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Address"
          name="Address"
          onChange={handleAddChange}
          value={FormFields?.Address}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Note"
          name="Note"
          onChange={handleAddChange}
          value={FormFields?.Note}
        />
      </Col>
    </Fragment>
  );
  const customModal = (
    <Modal
      isOpen={toggleForm}
      centered
      // toggle={toggle}
      modalTransition={{ timeout: 10 }}
      // backdrop="static"
    >
      <ModalHeader
      // toggle={toggle}
      >
        Add Hostel
      </ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <Col lg="6" md="6" xs="12">
              <FormGroupInput
                label="Hostel"
                name="Detail"
                onChange={handleSetupChange}
                value={setupFields?.Detail}
              />
            </Col>
            <Col lg="6" md="6" xs="12">
              <FormGroupSelect
                list={dropdownList?.filter(
                  (x) => x.SetupMasterId == hostelTypeId
                )}
                label="Hostel Type"
                name="ParentId"
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={setupFields?.ParentId}
                onChange={handleSetupChange}
              />
              <p
                style={{ fontSize: "12px", marginBottom: "0px" }}
                className="text-right"
              >
                <a href="#" onClick={(e) => onAddSetupHotelType(e, 1142)}>
                  Add Hostel Type
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="mt-2"
            >
              <Button color="primary" type="button" onClick={submitSetup}>
                Submit
              </Button>
              <Button color="default" onClick={handleCancelSetup}>
                Close
              </Button>
            </div>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
  const customModalSecond = (
    <Modal
      isOpen={toggleFormHotelType}
      centered
      // toggle={toggle}
      modalTransition={{ timeout: 10 }}
      // backdrop="static"
    >
      <ModalHeader
      // toggle={toggle}
      >
        Add Hostel Type
      </ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <Col lg="6" md="6" xs="12">
              <FormGroupInput
                label="Name"
                name="Detail"
                onChange={handleSetupChangeHotelType}
                value={hotelTypeFields?.Detail}
              />
            </Col>
          </Row>
          <Row>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="mt-2"
            >
              <Button
                color="primary"
                type="button"
                onClick={submitSetupHotelType}
              >
                Submit
              </Button>
              <Button color="default" onClick={handleCancelSetupHotelType}>
                Close
              </Button>
            </div>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
  return (
    <CrudFormComponent
      formName="Manage Hostel"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      customModal={customModal}
      customModalSecond={customModalSecond}
    />
  );
};

export default ManageHostel;
