import React, { Fragment, useRef } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col } from "reactstrap";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import {
  allowancesSetupMasterId,
  Delete,
  Insert,
  Search,
  Select,
  setupMasterId,
  Update,
  allowOnlyString,
  benefitsSetupId,
  degreeSetupId,
  citySetupId,
  countrySetupId,
  academicYearId,
  seatTypeId,
  SessionStorage,
} from "../../common/SetupMasterEnum";

import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_SEARCH_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
  ADM_AdmissionSecureStudent,
  ADM_EligibilityCriteriaDependency,
  ADM_Registered_Candidates,
  PostRequest,
  Setup_MasterDetails_Operation,
} from "../../utils/Config";
import { CustomErrorMessage } from "../../components/Alert";
import { useReactToPrint } from "react-to-print";
import { decryptData } from "../../EncryptData";
import RegisteredCandidatesDegreeProgramReport from "./AdmissionReports/RegisteredCandidatesDegreeProgramReport";
import { useState } from "react";
import { ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, ADM_REGISTERED_CANDIDATES } from "../../utils/UrlConstants";

const initialSearchFields = {
  OperationID: Select,
  AcademicYeaID: 0,
  FacultyDepartmentID: 0,
  FacultyDepartmentProgramID: 0,
  UserId: decryptData("loginId", SessionStorage),
};

const RegisteredCandidatesDegreeProgram = () => {
  initialSearchFields.UserId = decryptData("loginId", SessionStorage);
  const printReport = useRef();
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    getADM_Registered_Candidates();
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    });
  }, []);

  const columns = [
    { field: "AcademicYear", name: "Academic Year" },
    { field: "ProgramType", name: "Program Type" },
    { field: "NIC_FormB", name: "NIC B-Form" },
    { field: "Name", name: "Name" },
    { field: "FatherName", name: "Father Name" },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
    {
      field: "AdmissionFacultyDepartment",
      name: "Admission Faculty Department",
    },
  ];

  const getADM_Registered_Candidates = () => {
    // ADM_Registered_Candidates(initialSearchFields)
    PostRequest(ADM_REGISTERED_CANDIDATES, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        CustomErrorMessage("Something went wrong");
      });
  };

  const handleSearchChange = (e) => {
    if (e.target.name === "FacultyDepartmentID") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value,
      });
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
      let data2 = { name: "FacultyDepartmentProgramID", value: 0 };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data2 });
    } else {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
    }
  };

  const onChange_ADM_EligibilityCriteriaDependency = (payload) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID === 2) {
            const data = { name: "Departments", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID === 3) {
            const data = { name: "Programs", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          label="Academic Year"
          name="AcademicYeaID"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYeaID}
        />
      </Col>
      <Col md="3" lg="3">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDepartmentID"
          list={SupportingTables?.Departments}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.FacultyDepartmentID}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col md="3" lg="3">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramID"
          list={SupportingTables?.Programs}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.FacultyDepartmentProgramID}
          onChange={handleSearchChange}
          required
          disabled={SearchFields?.FacultyDepartmentID == 0 ? true : false}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const poyload = {
      OperationID: Search,
      AcademicYeaID: SearchFields?.AcademicYeaID,
      FacultyDepartmentID: SearchFields?.FacultyDepartmentID,
      FacultyDepartmentProgramID: SearchFields?.FacultyDepartmentProgramID,
      UserId: decryptData("loginId", SessionStorage),
    };
   // ADM_Registered_Candidates(poyload)
    PostRequest(ADM_REGISTERED_CANDIDATES, poyload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        CustomErrorMessage("Something went wrong");
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getADM_Registered_Candidates();
  };

  const onHandlePrint = useReactToPrint({
    content: () => printReport.current,
  });

  const customButton = (
    <>
      <Button
        disabled={TableList.length > 0 ? false : true}
        className="btn"
        color="primary"
        onClick={onHandlePrint}
      >
        Print
      </Button>
      <div style={{ display: "none" }}>
        <RegisteredCandidatesDegreeProgramReport ref={printReport} />
      </div>
    </>
  );

  return (
    <CrudFormComponent
      formName="Registered Candidates Degree Program"
      customButton={customButton}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      hideAction={true}
    />
  );
};

export default RegisteredCandidatesDegreeProgram;
