import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import { useEffect } from "react";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  Setup_ShiftTiming,
  TMS_GetShift,
  TMS_UpdateShift,
} from "../../../utils/Config";
import {
  campusCity,
  campusType,
  department,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  formatDateFunction,
} from "../../../functions/DateFormatFunction";
import Swal from "sweetalert2";
import { decryptData } from "../../../EncryptData";

const initialValues = {
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  EmployeeId: 0,
  OperationID: 1,
};
const initialFormValues = {
  EmployeeId: 0,
  IsActive: null,
  StartDate: "",
  TMSShiftId: 0,
  UserIp: "192.168.1.1",
  UserId: 0,
  RecID: 0,
  IsNewTMS: 0,
  EmployeeId_: "",
  ShiftStatus: "",
};
const InchargeShiftScheduler = () => {
  const animatedComponents = makeAnimated();

  const [searchFields, setSearchFields] = useState({
    ...initialValues,
  });
  const [formFields, setFormFields] = useState({
    ...initialFormValues,
    UserId: decryptData("loginId", SessionStorage),
  });
  const [resData, setResData] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const [shiftSchedulerList, setShiftSchedulerList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleInputChange = (event) => {
    if (event.target.name == "CampusTypeId") {
      searchFields.CampusCityId = 1644;
    }
    setSearchFields({
      ...searchFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    const result = event.map((obj) => JSON.stringify(obj.Id)).join(",");
    setSearchFields({
      ...searchFields,
      EmployeeId: result,
    });
    setFormFields({
      ...formFields,
      EmployeeId_: result,
    });
  };
  useEffect(() => {
    getMasterDetailAllDropdown();
    getShiftData();
    getEmployeeDropDown();
  }, []);
  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        setResData({ tables: res?.data });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getEmployeeDropDown() {
    const payload = {
      operationId: 1,
      employeeId:decryptData("EmplId", SessionStorage),
      case: 3,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {
        setEmployeeList(
          res?.data?.Table?.map((x, index) => ({
            ...x,
            label: x.Name,
            value: index + 1,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getTMSShift(data) {
    TMS_GetShift(data)
      .then((res) => {
        setShiftSchedulerList(
          res?.data?.Table?.map((x, index) => ({
            ...x,
            StartDate: formatDateFunction(x.StartDate, "-"),
            IsActive: x.IsActive == true ? 1 : 0,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getShiftData() {
    Setup_ShiftTiming()
      .then((res) => {
        setShiftList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onSearch = (e) => {
    e.preventDefault();
    getTMSShift(searchFields);
  };

  const handleAddChange = (e, index) => {
    let obj = shiftSchedulerList[index];
    obj = {
      ...obj,
      [e.target.name]: e.target.value,
    };

    shiftSchedulerList[index] = obj;
    setShiftSchedulerList([...shiftSchedulerList]);
  };

  const onAdd = () => {
    shiftSchedulerList.push(formFields);

    setShiftSchedulerList([...shiftSchedulerList]);

  };
  const onDelete = (e, index) => {
    shiftSchedulerList.splice(index, 1);
    setShiftSchedulerList([...shiftSchedulerList]);
  };
  function updateShiftData(data) {
    TMS_UpdateShift(data)
      .then((res) => {
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onUpdate = () => {

    const data = {
      shiftModals_: shiftSchedulerList,
    };
    updateShiftData(data);
  };
  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>Select Shift</CardTitle>
        <CardBody>
          <form onSubmit={onSearch}>
            <Row>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Campus Type"
                  list={resData?.tables?.filter(
                    (x) => x.SetupMasterId == campusType
                  )}
                  name="CampusTypeId"
                  value={searchFields.CampusTypeId}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={resData?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == campusCity &&
                      x.parentid == searchFields.CampusTypeId
                  )}
                  label="Campus City"
                  name="CampusCityId"
                  onChange={handleInputChange}
                  value={searchFields.CampusCityId}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  disabled={!searchFields.CampusTypeId}
                  required
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={resData?.tables?.filter(
                    (x) => x.SetupMasterId == department
                  )}
                  label="Department"
                  name="DepartmentId"
                  onChange={handleInputChange}
                  value={searchFields.DepartmentId}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <Label>Employees</Label>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  onChange={handleInputChangeSelect}
                  isMulti={true}
                  options={employeeList}
                  value={selectedOption}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12" className="text-right mt-2">
                <Button color="primary" type="submit">
                  Search
                </Button>
                <Button color="default">Cancel</Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>Shift Details</CardTitle>
        <CardBody>
          <Row>
            <Col className="mt-3">
              <Table bordered striped responsive>
                <thead>
                  <tr>
                    <th style={{ width: "290px" }}>Employee</th>
                    <th style={{ width: "400px" }}>Shift</th>
                    <th style={{ width: "230px" }}>From</th>
                    <th>Active / Inactive</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {shiftSchedulerList?.map((data, index) => {
                    return (
                      <tr key={index} title={data.ShiftStatus}>
                        <td>{data.EmployeeName}</td>
                        <td>
                          <FormGroupSelect
                            fieldId="TMSShiftId"
                            fieldName="ShiftName"
                            list={shiftList}
                            name="TMSShiftId"
                            value={data.TMSShiftId}
                            onChange={(e) => handleAddChange(e, index)}
                            disabled={
                              data.ShiftStatus == "Running Shift" ? true : false
                            }
                          />
                        </td>
                        <td>
                          <FormGroupInput
                            type="date"
                            name="StartDate"
                            value={data.StartDate}
                            onChange={(e) => handleAddChange(e, index)}
                            disabled={
                              data.ShiftStatus == "Running Shift" ? true : false
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={data.IsActive == 1 ? true : false}
                            name="IsActive"
                            value={data.IsActive}
                            onChange={(e) =>
                              handleAddChange(
                                {
                                  target: {
                                    name: e.target.name,
                                    value: e.target.checked == true ? 1 : 0,
                                  },
                                },
                                index
                              )
                            }
                            disabled={
                              data.ShiftStatus == "Running Shift" ? true : false
                            }
                          />
                        </td>
                        <td>
                          {data.ShiftStatus !== "Running Shift" ? (
                            <Button
                              color="danger"
                              className="btnic"
                              size="sm"
                              onClick={() => onDelete(data, index)}
                            >
                              <i className="fa fa-trash"> </i>
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            {shiftSchedulerList.length > 0 ? (
              <Col lg="12" md="12" xs="12" className="text-right">
                <Button color="warning" onClick={onAdd}>
                  Add New
                </Button>
                <Button color="primary" onClick={onUpdate}>
                  {submitLoad ? (
                    <div className="form-load">
                      <Spinner color="light" size="sm" />
                    </div>
                  ) : null}
                  Update
                </Button>
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default InchargeShiftScheduler;
