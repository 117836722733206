import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Table } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_FORM_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import Spinner from "react-spinkit";
import { useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import DegreeProgramLedgerReport from "../Reports/ControllerExamination/DegreeProgramLedgerReport";
import { PostRequest } from "../../../utils/Config";
import { ACAD_EXAM_CONTROLLER_REPORTS_OPS } from "../../../utils/UrlConstants";
import {
  academicYearId,
  partYearID,
  Search,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  operationId: Search,
  parameterID: Update,
  academicYearID: 5623,
  partID: 3726,
  semesterID: 0,
  facultyDepartmentProgramID: 4768,
  userID: decryptData("loginId", SessionStorage),
  userIP: decryptData(UserNetworkInfo)?.IPv4,
};

const initialFormFields = {
  operationId: Search,
  parameterID: Update,
  academicYearID: 0,
  partID: 0,
  semesterID: 0,
  facultyDepartmentProgramID: 0,
  userID: decryptData("loginId", SessionStorage),
  userIP: decryptData(UserNetworkInfo)?.IPv4,
};

const DegreeProgramLedger = () => {
  const { SearchFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const printDPLedgerReport = useRef();

  const [formLoad, setFormLoad] = useState(true);

  useEffect(() => {
    getDegreeProgramLedger();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

  function getDegreeProgramLedger() {
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS_OPS, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getDegreeProgramLedgerempty() {
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS_OPS, initialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: initialFormFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="academicYearID"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.academicYearID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="partID"
          onChange={handleSearchChange}
          value={SearchFields?.partID}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDeptId"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDeptId}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="facultyDepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.facultyDepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.FacultyDeptId == null}
        />
      </Col>
    </Fragment>
  );

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "DayDate", name: "Day" },
  ];

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>S.No.</th>
              {TableList?.length > 0
                ? Object.keys(TableList?.[0])?.map((column, index) => (
                    <Fragment key={index}>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        key={index + 1}
                      >
                        {column}
                      </th>
                    </Fragment>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {TableList &&
              TableList?.map((value, ind) => (
                <tr key={ind}>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {ind + 1}
                  </td>
                  {Object.keys(TableList[ind])?.map((column, index) => (
                    <Fragment key={index}>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        key={index + 2}
                      >
                        {TableList[ind][column]}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  const handlePrintReport = useReactToPrint({
    content: () => printDPLedgerReport.current,
  });

  const customButton = (
    <Fragment>
      <Button color="primary" onClick={handlePrintReport}>
        Print Degree Program Ledger Report
      </Button>
      <div style={{ display: "none" }}>
        <DegreeProgramLedgerReport ref={printDPLedgerReport} />
      </div>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      operationId: Search,
      parameterID: Update,
      userID: decryptData("loginId", SessionStorage),
      userIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS_OPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getDegreeProgramLedgerempty();
  };

  return (
    <CrudFormComponent
      formName="Degree Program Ledger"
      customButton={customButton}
      hideAction={true}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
    />
  );
};

export default DegreeProgramLedger;
