import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { CustomErrorMessage } from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import {
  Admission_GetStudendAdmissionRecord,
  PostRequest,
  Setup_MasterDetails_All_Dropdowns,
} from "../../../utils/Config";
import { REG_ID } from "../../../utils/EncryptedConstants";
import Attandance from "../../../assets/img/Academic/Assessment01.png";
import Exam from "../../../assets/img/Academic/Attendance01.png";
import { Puff } from "react-loader-spinner";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import { ACADEMIC_RECORD } from "../../../utils/UrlConstants";

const initialState = {
  OperationID: 0,
  AttandanceRecordID: 0,
  CourseSemesterLocationTimeSlotMappingID: 0,
  EnrollmentID: 0,
  IsPresent: false,
  IsSaved: false,
  IsLocked: false,
  UserId: 0,
  ClassDate: "",
  LecturerId: 0,
  CourseId: 0,
  AcademicYearId: 0,
  AttandanceRecord_: [],
};

const attendanceRecord = {
  AttandanceRecordID: 0,
  CourseSemesterLocationTimeSlotMappingID: 0,
  EnrollmentDetailID: 0,
  IsPresent: false,
  IsSaved: false,
  IsLocked: false,
  IsActive: false,
  CreatedBy: 0,
  ModifiedBy: 0,
  UserIP: null,
};

const AttandanceScreen = (props) => {
  const {
    lecturerId = null,
    course = null,
    timeslotList = [],
    attandanceList = [],
    setAttandanceList = () => {},
    setCourseId = null,
    setIsTakenId = 0,
  } = props;

  const dispatch = useDispatch();

  const [toggleForm, setToggleForm] = useState(false);
  const [onClickExam, setOnClickExam] = useState(false);
  const [onClickAttandance, setOnClickAttandance] = useState(false);
  const [onClickNext, setOnClickNext] = useState(1);
  const [onloading, setOnLoading] = useState(false);
  const [timeslotId, setTimeSlotId] = useState("");

  useEffect(() => {
    let tsId = timeslotList?.find(
      (x) => x.IsSelected === true || timeslotList?.[0]
    );
    setTimeSlotId(tsId?.courseSemesterLocationTimeSlotMappingId);
    setIsTakenId(timeslotList?.[0]?.IsTaken === true ? 1 : 0);
    getStudentList(tsId?.courseSemesterLocationTimeSlotMappingId);
  }, []);

  const getStudentList = (timeslot) => {
    const payload = {
      ...initialState,
      OperationID: 8,
      AcademicYearId: course.academicYearId,
      LecturerId: lecturerId,
      CourseId: course.CourseId,
      CourseSemesterLocationTimeSlotMappingID: timeslot,
    };
    PostRequest(ACADEMIC_RECORD, payload)
      .then((res) => {
        setAttandanceList(res.data.Table);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Container className="mb-3" fluid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label
          style={{
            fontSize: 14,
            background: "#dfdede",
            padding: "5px 15px",
            borderRadius: 3,
            boxShadow: "0px 0px 3px lightgrey",
          }}
        >
          <b>{course.CourseName}</b>
        </label>
        {/* <label
          style={{
            fontSize: 14,
            background: "#dfdede",
            padding: "5px 15px",
            borderRadius: 3,
            boxShadow: "0px 0px 3px lightgrey",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 30,
          }}
        > */}
        {/* <b>Time Slot </b> */}
        <Input
          type="select"
          style={{
            fontSize: 13,
            background: "#dfdede",
            border: "1px solid #a69a9a",
            padding: "5px 15px",
            width: "auto",
          }}
          value={timeslotId}
          onChange={(e) => {
            setTimeSlotId(e.target.value);
            getStudentList(e.target.value);
            setCourseId(e.target.value);
            setIsTakenId(e.target.selectedOptions[0]?.getAttribute("newfield"));
          }}
        >
          {timeslotList?.map((x, index) => (
            <option
              key={index}
              value={x.courseSemesterLocationTimeSlotMappingId}
              newfield={x.IsTaken === true ? 1 : 0}
            >
              <strong>Time Slot: </strong>
              {x.classDate}
            </option>
          ))}
        </Input>
        {/* </label> */}
      </div>
      <div
        style={{
          minHeight: "32vw",
          height: "32%",
          maxHeight: "32vw",
          overflowY: "auto",
          margin: "15px 0px",
        }}
      >
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Student Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {attandanceList?.length > 0 ? (
              <>
                {attandanceList?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ color: "#4473c5" }}>{index + 1}</td>
                    <td style={{ color: "#4473c5" }}>{item.StudentName}</td>
                    <td style={{ color: "#4473c5" }}>
                      <div className="form-check-inline mt-1">
                        <Label className="form-check-Label">
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            checked={item.IsPresent}
                            onChange={(e) => {
                              attandanceList[index].IsPresent =
                                e.target.checked;
                              setAttandanceList([...attandanceList]);
                            }}
                          />{" "}
                          Present
                        </Label>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default AttandanceScreen;
