import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../../utils/CommonMethods";
import DatePicker from "react-datepicker";
import { Setup_UpdateEmployee } from "../../../../utils/Config";
import { formatDateFunction2 } from "../../../../functions/DateFormatFunction";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { decryptData } from "../../../../EncryptData";
import { SessionStorage } from "../../../../common/SetupMasterEnum";

const Academics = (props) => {
  const { id } = useParams();
  const EmplId =
    id == 0
      ? decryptData("EmplId", SessionStorage)
      : decryptData("EmplGetId", SessionStorage);
  const LoginId = decryptData("loginId", SessionStorage);
  const FirstName = props?.firstName;
  const CNIC = props?.CNIC;
  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    EmployeeAcademicsDetails_: [],
    EmployeeResearchWorkDetails_: [],
  };
  const employeeAcademicsDetails_ = {
    EmployeeId: EmplId,
    EmployeeDegreeMappingId: 0,
    UniversityName: "",
    Qualificationid: 0,
    AreaofExpertise_Competencies: "",
    AreaofExpertise_CompetenciesId: 0,
    DegreeId: 0,
    MajorSubjectid: 0,
    MajorSubjects: "",
    DegreeStatusId: 0,
    FormDate: null,
    EndDate: null,
    Grade_GPA_Percentage: "",
    CountryofDegreeId: 0,
    IsActive: 1,
    IsDeleted: 0,
    UserIP: "192.168.168",
  };
  const employeeResearchWorkDetails_ = {
    EmployeeId: EmplId,
    ResearchworkId: 0,
    TitleofDissertationThesis: "",
    Nooftudentssupervised: "",
    TotalTeachingandResearchExperience: "",
    DegreeId: 0,
    IsActive: 1,
    IsDeleted: 0,
    UserIP: "192.168.168",
  };
  const formData = props.data;
  const researchData = props?.researchData;
  const list = props.list;
  const [formFields, setFormFields] = useState({
    ...initialValues,
    // ...formData,
  });
  const [academicFormFields, setAcademicFormFields] = useState({
    ...employeeAcademicsDetails_,
  });
  const [researchFormFields, setResearchFormFields] = useState({
    ...employeeResearchWorkDetails_,
  });
  const [academicData, setAcademicData] = useState([]);
  const [researchDatas, setResearchDatas] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);

  const handleInputChange = (event) => {
    setAcademicFormFields({
      ...academicFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    let obj = {
      ...academicFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setAcademicFormFields(obj);
  };
  const handleResearchChange = (event) => {
    setResearchFormFields({
      ...researchFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleResearchChangeSelect = (event) => {
    let obj = {
      ...researchFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setResearchFormFields(obj);
  };
  useEffect(() => {
    setAcademicData(formData);
  }, [formData]);

  useEffect(() => {
    setResearchDatas(researchData);
  }, [researchData]);

  const columns = [
    { field: "UniversityName", name: "Name of Institute" },
    { field: "FormDate", name: "From Date" },
    { field: "EndDate", name: "To Date" },
    { field: "Qualification", name: "Education" },
    { field: "Degree", name: "Degree" },
    { field: "MajorSubject", name: "Majors" },
    { field: "MajorSubjects", name: "Major Subject(s)" },
    { field: "CountryofDegree", name: "Country of Degree" },
    {
      field: "AreaofExpertise_Competencie",
      name: "Area of Expertise/Competencies",
    },
    { field: "Grade_GPA_Percentage", name: "Grade/CGPA/%" },
    { field: "DegreeStatus", name: "Degree Status" },
  ];

  const researchColumn = [
    { field: "TitleofDissertationThesis", name: "Thesis Title" },
    { field: "Degree", name: "Degree" },
    { field: "Nooftudentssupervised", name: "No. of Students Supervised" },
  ];

  // Academic Crud
  const onAddAcademic = (e) => {
    e.preventDefault();
    academicFormFields.FormDate = formatDateFunction2(
      academicFormFields.FormDate
    );
    academicFormFields.EndDate = formatDateFunction2(
      academicFormFields.EndDate
    );
    academicFormFields.index > -1
      ? (academicData[academicFormFields.index] = academicFormFields)
      : academicData.push({
          ...academicFormFields,
          index: -1,
        });
    setAcademicData([...academicData]);
    setAcademicFormFields({
      ...employeeAcademicsDetails_,
    });
  };
  const onEditAcademic = (index, obj) => {
    setAcademicFormFields({
      ...obj,
      FormDate: new Date(obj.FormDate),
      EndDate: new Date(obj.EndDate),
      index: index,
    });
  };
  const onDeleteAcademic = (index) => {
    academicData.splice(index, 1);
    setAcademicData([...academicData]);
  };
  const onCancelAcademic = () => {
    setAcademicFormFields({
      ...employeeAcademicsDetails_,
    });
  };
  // Academic Crud Close
  const AllDateSet = (event, type) => {
    if (type === "FormDate") {
      setAcademicFormFields({
        ...academicFormFields,
        FormDate: event,
      });
    } else if (type === "EndDate") {
      setAcademicFormFields({
        ...academicFormFields,
        EndDate: event,
      });
    }
  };
  // Research Crud
  const onAddResearch = (e) => {
    e.preventDefault();
    researchFormFields.index > -1
      ? (researchDatas[researchFormFields.index] = researchFormFields)
      : researchDatas.push({
          ...researchFormFields,
          index: -1,
        });
    setResearchDatas([...researchDatas]);
    setResearchFormFields({
      ...employeeResearchWorkDetails_,
    });
  };
  const onEditResearch = (index, obj) => {
    setResearchFormFields({
      ...obj,
      index: index,
    });
  };
  const onDeleteResearch = (index) => {
    researchDatas.splice(index, 1);
    setResearchDatas([...researchDatas]);
  };
  const onCancelResearch = () => {
    setResearchFormFields({
      ...employeeResearchWorkDetails_,
    });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (academicData.length == 0) {
      Swal.fire({
        title: "Error",
        text: "Please Add Academic Information",
        icon: "error",
      });
    } else {
      let formData = new FormData();
      formData.append("UserId", formFields.UserId);
      formData.append("EmployeeId", formFields.EmployeeId);
      formData.append("FirstName", FirstName);
      formData.append("CNIC", CNIC);
      academicData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeAcademicsDetails_[${index}].${item}`,
            element[item]
          );
        });
      });
      researchDatas.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeResearchWorkDetails_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateEmployee(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };

  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Academic Details</CardTitle>
        <CardBody>
          <form onSubmit={onAddAcademic}>
            <Row>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Name Of Institute"
                  name="UniversityName"
                  value={academicFormFields.UniversityName}
                  onChange={handleInputChange}
                  required
                  maxLength="200"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="Qualificationid"
                  value={academicFormFields.Qualificationid}
                  label="Education"
                  list={list.QualificationList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChangeSelect}
                  nameValue="Qualification"
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  list={list.HighestDegreeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="DegreeId"
                  value={academicFormFields.DegreeId}
                  label="Degree"
                  onChange={handleInputChangeSelect}
                  nameValue="Degree"
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  list={list.SubjectList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="MajorSubjectid"
                  value={academicFormFields.MajorSubjectid}
                  label="Majors"
                  onChange={handleInputChangeSelect}
                  nameValue="MajorSubject"
                  // required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Major Subject(s)"
                  onChange={handleInputChange}
                  name="MajorSubjects"
                  value={academicFormFields.MajorSubjects}
                  maxLength="200"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Country of Degree"
                  list={list.CountryList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChangeSelect}
                  nameValue="CountryofDegree"
                  name="CountryofDegreeId"
                  value={academicFormFields.CountryofDegreeId}
                  required
                />
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Area of Expertise/Competencies"
                  list={list.AreaList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="AreaofExpertise_CompetenciesId"
                  value={academicFormFields.AreaofExpertise_CompetenciesId}
                  onChange={handleInputChangeSelect}
                  nameValue="AreaofExpertise_Competencie"
                  // required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Degree Status"
                  list={list.DegreeStatusList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="DegreeStatusId"
                  value={academicFormFields.DegreeStatusId}
                  onChange={handleInputChangeSelect}
                  nameValue="DegreeStatus"
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    From Date<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    selected={academicFormFields.FormDate}
                    onChange={(e) => AllDateSet(e, "FormDate")}
                    className="form-control"
                    name="FormDate"
                    required={
                      academicFormFields.DegreeStatusId == 2191 ? true : false
                    }
                    disabled={
                      academicFormFields.DegreeStatusId == 2191 ? false : true
                    }
                    showYearDropdown={true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    To Date<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    selected={academicFormFields.EndDate}
                    onChange={(e) => AllDateSet(e, "EndDate")}
                    className="form-control"
                    name="EndDate"
                    required={
                      academicFormFields.DegreeStatusId == 2191 ? true : false
                    }
                    disabled={
                      academicFormFields.DegreeStatusId == 2191 ? false : true
                    }
                    showYearDropdown={true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                    minDate={academicFormFields.FormDate}
                  />
                </div>
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Grade/CGPA/%"
                  onChange={handleInputChange}
                  name="Grade_GPA_Percentage"
                  value={academicFormFields.Grade_GPA_Percentage}
                  maxLength="6"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="4"></Col>
              <Col className="text-right" sm="8">
                <Button color="primary">Add/Update</Button>
                <Button
                  color="default"
                  type="button"
                  onClick={onCancelAcademic}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>List of Academics</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={academicData}
                onEdit={onEditAcademic}
                onDelete={onDeleteAcademic}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mt-3">
        <CardTitle>Research</CardTitle>
        <CardBody>
          <form onSubmit={onAddResearch}>
            <Row>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Thesis Title"
                  onChange={handleResearchChange}
                  name="TitleofDissertationThesis"
                  value={researchFormFields.TitleofDissertationThesis}
                  required
                  maxLength="500"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Degree"
                  list={list.HighestDegreeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleResearchChangeSelect}
                  nameValue="Degree"
                  name="DegreeId"
                  value={researchFormFields.DegreeId}
                  required
                />
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="No. of Students Supervised"
                  onChange={handleResearchChange}
                  name="Nooftudentssupervised"
                  value={researchFormFields.Nooftudentssupervised}
                  // required
                  isNumber="true"
                  maxLength="4"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="4"></Col>
              <Col className="text-right" sm="8">
                <Button color="primary">Add/Update</Button>
                <Button
                  color="default"
                  type="button"
                  onClick={onCancelResearch}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>

      <Card className="mt-3">
        <CardTitle>Research Details</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={researchColumn}
                rows={researchDatas}
                onEdit={onEditResearch}
                onDelete={onDeleteResearch}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" onClick={onUpdate}>
                  {submitLoad ? <Spinner color="light" size="sm" /> : null}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Academics;
