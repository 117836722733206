import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import { SET_CRUD_FROM_FIELDS } from "../../../../redux/actionType/CrudActionTypes";

const Vendor = (props) => {
  const dispatch = useDispatch();

  const {
    SupportingTables: { CustomerData },
  } = useSelector((state) => state.CrudFormReducer);

  const { partyInformation_ = {}, setPartyInformation_ = () => {} } = props;

  useEffect(() => {
    setPartyInformation_({ ...partyInformation_, ...CustomerData?.[0] });
  }, [CustomerData]);

  const handleAddChange = (e) => {
    setPartyInformation_({
      ...partyInformation_,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            label="Chart of Account"
            name="CoaID"
            value={partyInformation_?.CoaID}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            label="Contact Person"
            name="ContactPerson"
            value={partyInformation_?.ContactPerson}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            label="Cell Phone:"
            name="CellPhone"
            isNumber="true"
            maxLength={12}
            value={partyInformation_?.CellPhone}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="3" md="3" xs="3">
          <FormGroupInput
            label="CNIC:"
            name="Cnic"
            isNumber="true"
            maxLength={13}
            value={partyInformation_?.Cnic}
            onChange={handleAddChange}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="6">
          <FormGroupInput
            label="Mailing Address"
            type="textarea"
            name="MailingAddress"
            maxLength={255}
            value={partyInformation_?.MailingAddress}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="6" md="6" xs="6">
          <FormGroupInput
            label="Shipping Address"
            type="textarea"
            name="ShippingAddress"
            maxLength={255}
            value={partyInformation_?.ShippingAddress}
            onChange={handleAddChange}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg="2" md="2" xs="12">
          <FormGroupInput
            label="Country:"
            name="Country"
            maxLength={22}
            value={partyInformation_?.Country}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="2" md="2" xs="12">
          <FormGroupInput
            label="City:"
            name="City"
            maxLength={22}
            value={partyInformation_?.City}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="2" md="2" xs="12">
          <FormGroupInput
            label="TelePhone:"
            name="TelePhone"
            isNumber="true"
            maxLength={12}
            value={partyInformation_?.TelePhone}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="2" md="2" xs="12">
          <FormGroupInput
            label="Fax:"
            name="Fax"
            maxLength={30}
            value={partyInformation_?.Fax}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="2" md="2" xs="12">
          <FormGroupInput
            label="Email:"
            name="Email"
            maxLength={25}
            value={partyInformation_?.Email}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="2" md="2" xs="12">
          <FormGroupInput
            label="Web:"
            name="Web"
            maxLength={30}
            value={partyInformation_?.Web}
            onChange={handleAddChange}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="6">
          <FormGroupInput
            label="Remarks"
            type="textarea"
            name="Remarks"
            maxLength={255}
            value={partyInformation_?.Remarks}
            onChange={handleAddChange}
            required
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Vendor;
