import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Input, Table, Tooltip } from "reactstrap";
import { Delete, Select } from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import Spinner from "react-spinkit";
import { CURRENT_SEMESTER } from "../../../utils/UrlConstants";

const StudentFiles = () => {
  const { FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const [formLoader, setFormLoader] = useState(false);

  const timeoutRef = useRef(null);

  const [saveToolTip, setSaveToolTip] = useState(false);

  useEffect(() => {
    setFormLoader(true);
    getSemesterFiles();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getSemesterFiles = () => {
    const payload = {
      operationId: Select,
      studentDetailID: params?.id1,
      courseSemesterMappingID: params?.id2,
      parameterID: 7,
      userID: 0,
    };
    PostRequest(CURRENT_SEMESTER, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1,
          },
        });
        let studentDetails = {
          name: "StudentDetails",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: studentDetails,
        });
        timeoutRef.current = setTimeout(() => setFormLoader(false), 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "DayDate", name: "Day" },
    { field: "OutlineDescription", name: "Outline Description" },
    { field: "FileStatus", name: "File Status" },
    { field: "ViewLecture", name: "View Lecture" },
  ];

  const customTable = (
    <Fragment>
      {formLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
      <Table bordered striped responsive style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="text-center">S.No.</th>
            {columns &&
              columns?.map((column, index) => (
                <th
                  style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                  key={index}
                  className="text-center"
                >
                  {column.name}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {TableList?.map((item, index) => (
            <tr key={index}>
              <td
                style={{ width: "5%", verticalAlign: "middle" }}
                className="text-center"
              >
                {index + 1}
              </td>
              <td
                style={{ width: "20%", verticalAlign: "middle" }}
                className="text-center"
              >
                {item.Courses}
              </td>
              <td
                style={{ width: "20%", verticalAlign: "middle" }}
                className="text-center"
              >
                {item.DayDate}
              </td>
              <td
                style={{ width: "20%", verticalAlign: "middle" }}
                className="text-center"
              >
                {item.OutlineDescription}
              </td>
              <td
                  className={
                    item.IsLectureUpload === 1 ? "text-success" : "text-danger"
                  }
                >
                  {item.IsLectureUpload === 1 ? "File Uploaded" : "File Not Uploaded"}
                </td>
              <td className="text-center">
                <a
                  style={{
                    pointerEvents: item.IsLectureUpload !== 1 && "none",
                  }}
                  className={`btn round-cubre btn-info ${item.IsLectureUpload !== 1 && 'disabled' }`}
                  href={item.IsLectureUpload === 1 ? item.FilePath : "#"}
                  // className="btn round-cubre btn-info"
                  // href={item.FilePath}
                  // download
                  target="_blank"
                >
                  <i className="fa fa-eye" color="primary"></i>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
       )} 
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Student Semester Files"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      // rows={TableList}
      customTable={customTable}
      //   onClickSave={onSave}
    />
  );
};

export default StudentFiles;
