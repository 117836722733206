import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import {
  admissionFacultyTypeId,
  admissionProgramId,
  admissionTypeId,
  campusCity,
  campusType,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  majorsAdId,
  programTypes,
  Search,
  typeOfSeatId,
  undergraduateId,
} from "../../common/SetupMasterEnum";
import {
  PostRequest,
} from "../../utils/Config";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import CandidateStatusReportPDF from "./CandidateStatusReportPDF";
import {
  ADM_ELIGIBILITY_CRITERIA_DEPENDENCY,
  REPORT_CANDIDATE_STATUS,
  SETUP_MASTER_DETAIL_ALL_DROPDOWN,
} from "../../utils/UrlConstants";

const initialSearchFields = {
  CampusTypeId: 1284,
  CampusCityId: 1644,
  ProgramId: 3479,
  ProgramTypesId: 4009,
  AdmissionTypeId: 4012,
  MajorId: 0,
  AdmissionFacultyTypeId: 0,
  FacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  ApplicationStatusId: -1,
  DomicileID: 0,
  TypeOfSeatId: 0,
  QuotaId: 0,
};

const CandidatesStatusReport = () => {
  const componentReference = useRef();
  const {
    SearchFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [candData, setCandData] = useState([]);
  // const headers = [
  //   { label: "First Name", key: "firstname" },
  //   { label: "Last Name", key: "lastname" },
  //   { label: "Email", key: "email" },
  // ];

  // const data = [
  //   { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
  //   { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  //   { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
  // ];
  useEffect(() => {
    getMasterDetailAllDropdown();
    getRegisteredRecords(initialSearchFields);
    getADM_EligibilityCriteriaDependency();
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getRegisteredRecords(payload) {
    PostRequest(REPORT_CANDIDATE_STATUS, payload)
      .then((res) => {
        let data = {
          name: "records",
          value: res.data,
        };
        setCandData(res?.data?.Table);
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "ADMRegID", name: "Reg. ID", key: "ADMRegID", label: "Reg. ID" },
    {
      field: "ApplicationID",
      name: "App. ID",
      key: "ApplicationID",
      label: "App. ID",
    },
    {
      field: "Cnic",
      name: "CNIC/B-Form/Passport No",
      key: "Cnic",
      label: "CNIC/B-Form/Passport No",
    },
    {
      field: "Name",
      name: "Candidate Name",
      key: "Name",
      label: "Candidate Name",
    },
    {
      field: "ProgramName",
      name: "Program",
      key: "ProgramName",
      label: "Program",
    },
    {
      field: "AdmissionTypeName",
      name: "Admission Type",
      key: "AdmissionTypeName",
      label: "Admission Type",
    },
    {
      field: "MajorName",
      name: "Major",
      key: "MajorName",
      label: "Major",
    },
    {
      field: "AdmissionFacultyDepartmentName",
      name: "Department",
      key: "AdmissionFacultyDepartmentName",
      label: "Department",
    },
    {
      field: "FacultyDepartmentProgramName",
      name: "Department Program",
      key: "FacultyDepartmentProgramName",
      label: "Department Program",
    },
    {
      field: "DomicileName",
      name: "Domicile Province",
      key: "DomicileName",
      label: "Domicile Province",
    },
    {
      field: "DepartmentChoice",
      name: "Department Choices",
      key: "DepartmentChoice",
      label: "Department Choices",
    },
    {
      field: "CandidateStatusName",
      name: "Application Status",
      key: "CandidateStatusName",
      label: "Application Status",
    },
    { field: "Remarks", name: "Remarks", key: "Remarks", label: "Remarks" },
  ];

  const onSearch = (e) => {
    e.preventDefault();
    getRegisteredRecords(searchFields);
  };

  const onCancel = () => {
    getRegisteredRecords(initialSearchFields);
    setSearchFields(initialSearchFields);
  };
  const onPrint = useReactToPrint({
    content: () => componentReference.current,
  });

  const getADM_EligibilityCriteriaDependency = () => {
    let payload = {
      operationID: Search,
      caseID: 1,
      paremeterID: undergraduateId,
    };
    PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
      .then((res) => {
        let data = { name: "MajorIds", value: res.data };
        dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency = (
    operationID,
    CaseID,
    ParameterID
  ) => {
    let payload = {
      operationID: operationID,
      caseID: CaseID,
      paremeterID: ParameterID,
    };
    PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
      .then((res) => {
        if (CaseID == 2) {
          let data = { name: "FacultyDepartment", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        } else if (CaseID == 3) {
          let data = { name: "FacultyDepartmentProgramId", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleInputChange = (e) => {
    if (e.target.name === "MajorId") {
      setSearchFields({
        ...searchFields,
        FacultyDepartmentId: 0,
        FacultyDepartmentProgramId: 0,
        [e.target.name]: e.target.value,
      });
      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency(
        5,
        2,
        e.target.value
      );
    } else if (e.target.name === "FacultyDepartmentId") {
      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency(
        5,
        3,
        e.target.value
      );
      setSearchFields({
        ...searchFields,
        FacultyDepartmentProgramId: 0,
        [e.target.name]: e.target.value,
      });
    } else {
      setSearchFields({
        ...searchFields,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <Container fluid>
      <Card className="mt-3">
        <form onSubmit={onSearch}>
          <CardTitle>Search Candidate Status</CardTitle>
          <CardBody>
            <Row>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Majors"
                  name="MajorId"
                  list={SupportingTables?.MajorIds?.Table}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.MajorId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDepartmentId"
                  list={SupportingTables?.FacultyDepartment?.Table}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.FacultyDepartmentId}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.FacultyDepartmentProgramId?.Table}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.FacultyDepartmentProgramId}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == 1100
                  )}
                  label="Domicile Province"
                  name="DomicileID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.DomicileID}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == typeOfSeatId
                  )}
                  label="Seat Type"
                  name="TypeOfSeatId"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.TypeOfSeatId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == 1101
                  )}
                  label="Quota"
                  name="QuotaId"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.QuotaId}
                  onChange={handleInputChange}
                  disabled={searchFields?.TypeOfSeatId === 3476 ? true : false}
                />
              </Col>
              <Col lg="4" md="6" sm="12" xs="12">
                <div className="form-group">
                  <Label>Application Status</Label>
                  <div className="form-control" onChange={handleInputChange}>
                    <Input type="radio" value={0} name="ApplicationStatusId" />
                    Pending
                    <Input type="radio" value={1} name="ApplicationStatusId" />
                    Completed
                    <Input type="radio" value={2} name="ApplicationStatusId" />
                    Approved
                    <Input type="radio" value={3} name="ApplicationStatusId" />
                    Rejected
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Search
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card>
        <CardTitle>
          <Row>
            <Col>
              <h6>Candidate Status Report</h6>
            </Col>
            <Col className="text-end">
              <CSVLink
                headers={columns}
                data={candData}
                filename={"CandidateStatusReport.csv"}
                className="btn btn-success"
                // target="_blank"
              >
                Excel
              </CSVLink>
              <Button color="danger" onClick={onPrint}>
                PDF
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table bordered striped responsive style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {SupportingTables?.records?.Table?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td>{item.ADMRegID}</td>
                    <td>{item.ApplicationID}</td>
                    <td>{item.Cnic}</td>
                    <td>{item.Name}</td>
                    <td>{item.ProgramName}</td>
                    <td>{item.AdmissionTypeName}</td>
                    <td>{item.MajorName}</td>
                    <td>{item.AdmissionFacultyDepartmentName}</td>
                    <td>{item.FacultyDepartmentProgramName}</td>
                    <td>{item.DomicileName}</td>
                    <td>{item.DepartmentChoice}</td>
                    <td style={{ width: "80px" }}>
                      {item.CandidateStatusName}
                      {/* {item.Status === 0 ? "Pending" : "Completed"} */}
                    </td>

                    <td style={{ width: "140px" }}>{item.Remarks}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <div style={{ display: "none" }}>
        <CandidateStatusReportPDF ref={componentReference} />
        {/* <AdmissionReportPrint ref={componentReference} /> */}
      </div>
    </Container>
  );
};

export default CandidatesStatusReport;
