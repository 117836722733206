import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Label } from "reactstrap";
import {
  academicYearId,
  campusCity,
  campusType,
  Delete,
  EmployeeCategoryID,
  Insert,
  partYearID,
  Search,
  Select,
  semesterId,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  AlreadyExistAlert,
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteAlert,
  DeleteWithConfirmation,
  SuccessAlert,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import {
  getUserIPInfo,
  onChange_Select_Department_Program,
} from "../../../functions/generalFunctions";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  SET_MULTI_CRUD_FORM_FIELD,
} from "../../../redux/actionType/CrudActionTypes";
import { Acad_SetupCourse, PostRequest } from "../../../utils/Config";
import MultiSelect from "react-select";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { SETUP_CREDIT_HOURS } from "../../../utils/UrlConstants";

const SetupCreditHours = () => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const initialSearchFields = {
    OperationId: Select,
    CreditHoursID: 0,
    CrHrs: 0,
    EmployeeCategoryID: 0,
    CreatedBy: 0,
    ModifiedBy: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialFormFields = {
    OperationId: Select,
    CreditHoursID: 0,
    CrHrs: 0,
    EmployeeCategoryID: 0,
    CreatedBy: 0,
    ModifiedBy: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const [EmployeeCategoryList] = useSetupDetailList(EmployeeCategoryID);

  const dispatch = useDispatch();

  useEffect(() => {
    getSetupCreditHours(initialSearchFields);
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
  }, []);

  function getSetupCreditHours(payload) {
    PostRequest(SETUP_CREDIT_HOURS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "EmployeeCategory", name: "Employee Category" },
    { field: "CreditHours", name: "Total Credit Hours" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Employee Category"
          name="EmployeeCategoryID"
          onChange={handleSearchChange}
          value={SearchFields?.EmployeeCategoryID}
          list={EmployeeCategoryList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Total Credit Hours"
          name="CrHrs"
          onChange={handleSearchChange}
          isNumber="true"
          value={SearchFields?.CrHrs}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...initialSearchFields,
      ...SearchFields,
      OperationId: Select,
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(SETUP_CREDIT_HOURS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      ...initialFormFields,
      ...FormFields,
      OperationId: id,
      ModifiedBy: decryptData("loginId", SessionStorage),
    };
    PostRequest(SETUP_CREDIT_HOURS, payload)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table[0]?.Column1);
          getSetupCreditHours(initialSearchFields);
        } else {
          CustomErrorMessage(res?.data?.Table[0]?.Column1);
        }
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onDeleteRow = (obj) => {
    console.log(obj);
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getSetupCreditHours(initialFormFields);
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const onEditRow = (obj) => {
    let data = {
      OperationId: Update,
      EmployeeCategoryID: obj?.EmployeeCategoryID,
      CreditHoursID: obj?.CreditHoursID,
      CrHrs: obj?.CrHrs,
      UserIP: decryptData(UserNetworkInfo)?.IPv4, 
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
    // onChange_Select_Department_Program({
    //   operationID: 6,
    //   caseID: 3,
    //   paremeterID: obj.FacultyDepartmentID,
    // }).then((res) => {
    //   dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
    // });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Employee Category"
          name="EmployeeCategoryID"
          onChange={handleAddChange}
          value={FormFields?.EmployeeCategoryID}
          list={EmployeeCategoryList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={FormFields?.EmployeeCategoryID > 0 ? true : false}
          required
        />
      </Col>

      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Total Credit Hours"
          name="CrHrs"
          onChange={handleAddChange}
          isNumber="true"
          value={FormFields?.CrHrs}
          required
        />
      </Col>
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Credit Hours"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      // onDelete={onDeleteRow}
      onEdit={onEditRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      modalSize="lg"
    />
  );
};

export default SetupCreditHours;
