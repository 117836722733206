import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  admissionFacultyTypeId,
  admissionTypeId,
  boardId,
  campusCity,
  campusType,
  examinationId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  majorsAdId,
  programTypes,
  Search,
  SessionStorage,
  totalMarksId,
  undergraduateId,
  yearId,
} from "../../../../common/SetupMasterEnum";
import { CustomErrorMessage } from "../../../../components/Alert";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../../EncryptData";
import { formatDateFunction2 } from "../../../../functions/DateFormatFunction";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../../redux/actionType/CrudActionTypes";
import {
  ADM_EligibilityCriteriaDependency,
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
  PostRequest,
} from "../../../../utils/Config";
import {
  ADMISSION_USER_ID,
  EMPLOYEE_NAME,
  LAST_NAME,
  REG_ID,
  MIDDLE_NAME,
  EMAIL,
  CNIC_BFORM,
  MOBILE,
} from "../../../../utils/EncryptedConstants";
import { ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../../../utils/UrlConstants";

const initialFormFields = {
  AdmARID: 0,
  ADMRegID: 0,
  ExaminationID: 0,
  DegreeName: "",
  MajorSubjects: "",
  YearId: 0,
  SeatNo_RollNumber: "",
  ObtainedMarks: "",
  TotalMarks: "",
  BoardID: 0,
  University: "",
  DepartmentName: "",
  UserIP: "192.168.14.1",
};

const Academics = (props) => {
  initialFormFields.ADMRegID = decryptData(REG_ID, SessionStorage);
  const dispatch = useDispatch();
  const initialProgramAppliedForFields = {
    languageId: 0,
    Year: 0,
    WhatsAppNo: "",
    AlternativeNumber: "",
    LastDegreeFromSalu: "",
    Other: "",
    ProgramId: props.Programid,
    CampusID: 0,
    CampusCityId: 0,
    ProgramTypeId: 0,
    AdmissionTypeId: 0,
    MajorId: 0,
    AdmissionFacultyTypeId: 0,
    AdmissionFacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    ShiftId: 0,
    Name: `${decryptData(EMPLOYEE_NAME, SessionStorage)} ${
      decryptData(MIDDLE_NAME, SessionStorage) === ""
        ? ""
        : decryptData(MIDDLE_NAME, SessionStorage)
    } ${
      decryptData(LAST_NAME, SessionStorage) === ""
        ? ""
        : decryptData(LAST_NAME, SessionStorage)
    }`,
    FatherName: "",
    SurName: "",
    GenderID: 0,
    GuardianName: "",
    Cnic: decryptData(CNIC_BFORM, SessionStorage),
    Picture: "",
    DOB: null,
    PostalAddress: "",
    PermanantAddress: "",
    CorrespondatAddressId: 0,
    NationalityID: 0,
    ReligionID: 0,
    DomicileID: 0,
    DomicileDistricId: 0,
    Ph: "",
    Mobile: decryptData(MOBILE, SessionStorage),
    Email: decryptData(EMAIL, SessionStorage),
    AreEmployeedAnyWhere: 0,
    EmployeedDepartment: "",
    NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase: 0,
    UnderTaking: 0,
    UserIP: "192.168.14.1",
  };
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const personalInfoData = SupportingTables?.students?.Table1[0];
  const academicRecords = SupportingTables?.students?.Table2;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState(initialFormFields);
  const [programAppliedFor, setProgramAppliedFor] = useState({
    ...initialProgramAppliedForFields,
    ...personalInfoData,
  });
  const [academicData, setAcademicData] = useState([]);
  const [editValue, setEditValue] = useState(false);
  const [majorValue, setMajorValue] = useState(personalInfoData?.MajorName);
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleProgramAppliedForChange = (event) => {
    // if (event.target.name === "AdmissionFacultyDepartmentId") {
    //   let data = {
    //     name: "AdmissionFacultyDepartmentId",
    //     value: event.target.value,
    //   };
    //   dispatch({
    //     type: SET_INITIAL_DROPDOWN_FORM_STATE,
    //     payload: data,
    //   });
    // }
    if (event.target.name == "AdmissionTypeId") {
      if (event.target.value != 4012 || event.target.value != 4014) {
        setMajorValue("");
        formFields.MajorSubjects = "";
        formFields.ExaminationID = 0;
      }
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
        MajorId: 0,
        AdmissionFacultyTypeId: 0,
        AdmissionFacultyDepartmentId: 0,
        FacultyDepartmentProgramId: 0,
      });
    } else if (event.target.name == "MajorId") {
      formFields.MajorSubjects = "";
      formFields.ExaminationID = 0;
      if (
        programAppliedFor.AdmissionTypeId == 4012 ||
        programAppliedFor.AdmissionTypeId == 4014
      ) {
        setMajorValue(event.target.selectedValue);
      } else {
        setMajorValue("");
      }
      let data = {
        name: "MajorId",
        value: event.target.value,
      };
      dispatch({
        type: SET_INITIAL_DROPDOWN_FORM_STATE,
        payload: data,
      });
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
        AdmissionFacultyTypeId: 0,
        AdmissionFacultyDepartmentId: 0,
        FacultyDepartmentProgramId: 0,
      });
    } else if (event.target.name == "AdmissionFacultyTypeId") {
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
        AdmissionFacultyDepartmentId: 0,
        FacultyDepartmentProgramId: 0,
      });
    } else if (event.target.name == "AdmissionFacultyDepartmentId") {
      if (event.target.value === 4082 || event.target.value === 4096) {
        if (SupportingTables?.DomicileID === 3603) {
          setProgramAppliedFor({
            ...programAppliedFor,
            [event.target.name]: event.target.value,
          });
        } else {
          CustomErrorMessage(
            "Candidates other than Sindh Domicile are not eligible to apply in this Program"
          );
        }
      } else {
        setProgramAppliedFor({
          ...programAppliedFor,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
      });
    }
  };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  useEffect(() => {
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 1,
      paremeterID: undergraduateId,
    });
    setAcademicData(academicRecords);
  }, [academicRecords]);
  useEffect(() => {
    setProgramAppliedFor({
      ...initialProgramAppliedForFields,
      ...personalInfoData,
    });
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 2,
      paremeterID: personalInfoData?.MajorId,
    });
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 3,
      paremeterID: personalInfoData?.AdmissionFacultyDepartmentId,
    });
  }, [personalInfoData]);
  const onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency = (
    payload
  ) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID == 1) {
            let data = { name: "MajorIdsSc", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID == 2) {
            let data = { name: "FacultyDepartmentSc", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else {
            let data = { name: "FacultyDepartmentProgramIdSc", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const columns = [
    { field: "ExaminationName", name: "Examination" },
    { field: "DegreeName", name: "Degree" },
    { field: "MajorSubjects", name: "Group/ Major Subjects" },
    { field: "YearName", name: "Year" },
    { field: "SeatNo_RollNumber", name: "Seat No" },
    { field: "ObtainedMarks", name: "Obtained Marks" },
    { field: "TotalMarks", name: "Total Marks" },
    { field: "BoardName", name: "Board" },
    { field: "University", name: "School/College/Institute/University" },
  ];

  const onAdd = (e) => {
    e.preventDefault();
    if (formFields.index > -1) {
      if (
        parseInt(formFields.ObtainedMarks) > parseInt(formFields.TotalMarks)
      ) {
        CustomErrorMessage("Obtained marks should be less then Total marks");
      } else if (formFields?.ExaminationID == 3590) {
        //inter examination id
        if (academicData.some((x) => x.ExaminationID == 3589)) {
          //f matric examination id present
          let matricId = academicData
            .filter((x) => x.ExaminationID == 3589)
            .map((y) => y.YearId);
          if (matricId[0] < formFields.YearId) {
            academicData[formFields.index] = formFields;
            setEditValue(false);
            setAcademicData([...academicData]);
            setFormFields({
              ...initialFormFields,
            });
          } else {
            CustomErrorMessage(
              "Intermediate or DAE record date has to be greater than the Matriculation record date"
            );
          }
        } else {
          academicData[formFields.index] = formFields;
          setEditValue(false);
          setAcademicData([...academicData]);
          setFormFields({
            ...initialFormFields,
          });
        }
      } else if (formFields?.ExaminationID == 3589) {
        //matric examination id
        if (academicData.some((x) => x.ExaminationID == 3590)) {
          //f intermediate examination id present
          let interId = academicData
            .filter((x) => x.ExaminationID == 3590)
            .map((y) => y.YearId);
          if (interId[0] > formFields.YearId) {
            academicData[formFields.index] = formFields;
            setEditValue(false);
            setAcademicData([...academicData]);
            setFormFields({
              ...initialFormFields,
            });
          } else {
            CustomErrorMessage(
              "Matriculation record date has to be less than the Intermediate or DAE record date"
            );
          }
        } else {
          setEditValue(false);
          academicData[formFields.index] = formFields;
          setAcademicData([...academicData]);
          setFormFields({
            ...initialFormFields,
          });
        }
      } else {
        setEditValue(false);
        academicData[formFields.index] = formFields;
        setAcademicData([...academicData]);
        setFormFields({
          ...initialFormFields,
        });
      }
    } else {
      if (
        parseInt(formFields.ObtainedMarks) > parseInt(formFields.TotalMarks)
      ) {
        CustomErrorMessage("Obtained marks should be less then Total marks");
      } else {
        if (
          academicData.some(
            (x) =>
              x.ExaminationID == formFields.ExaminationID &&
              x.ExaminationID != 3703
          )
        ) {
          CustomErrorMessage("Cannot Insert same record multiple times");
        } else {
          if (formFields.ExaminationID === 3590) {
            //intermediateID

            // new Condition

            if (academicData.some((x) => x.ExaminationID === 3589)) {
              //matricID
              let matricId = academicData
                .filter((x) => x.ExaminationID === 3589)
                .map((y) => y.YearId);
              if (formFields.YearId > matricId[0]) {
                academicData.push({
                  ...formFields,
                  index: -1,
                });
                setAcademicData([...academicData]);
                setFormFields({
                  ...initialFormFields,
                });
              } else {
                CustomErrorMessage(
                  "Intermediate or DAE record date has to be greater than the Matriculation record date"
                );
              }
            } else {
              academicData.push({
                ...formFields,
                index: -1,
              });
              setAcademicData([...academicData]);
              setFormFields({
                ...initialFormFields,
              });
            }
          } else if (formFields.ExaminationID === 3589) {
            //matricID
            if (academicData.some((x) => x.ExaminationID === 3590)) {
              //interID
              let interId = academicData
                .filter((x) => x.ExaminationID === 3590)
                .map((y) => y.YearId);
              if (formFields.YearId < interId[0]) {
                academicData.push({
                  ...formFields,
                  index: -1,
                });
                setAcademicData([...academicData]);
                setFormFields({
                  ...initialFormFields,
                });
              } else {
                CustomErrorMessage(
                  "Matriculation record date has to be less than the Intermediate or DAE record date"
                );
              }
            } else {
              academicData.push({
                ...formFields,
                index: -1,
              });
              setAcademicData([...academicData]);
              setFormFields({
                ...initialFormFields,
              });
            }
          } else {
            academicData.push({
              ...formFields,
              index: -1,
            });
            setAcademicData([...academicData]);
            setFormFields({
              ...initialFormFields,
            });
          }
        }
      }
    }
  };

  /**
   * Request Call Function
   * @param {*} payload
   */

  return (
    <Fragment>
      <form onSubmit={onAdd}>
        <Row className="mt-2">
          <Col md="3" lg="3">
            <FormGroupSelect
              label="Campus Type"
              name="CampusID"
              list={SupportingTables?.tables?.filter(
                (x) => x.SetupMasterId == campusType
              )}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              value={programAppliedFor?.CampusID}
              onChange={handleProgramAppliedForChange}
              required
              disabled={true}
            />
          </Col>
          <Col md="3" lg="3">
            <FormGroupSelect
              label="Campus City"
              name="CampusCityId"
              list={SupportingTables?.tables?.filter(
                (x) =>
                  x.SetupMasterId == campusCity &&
                  x.parentid == programAppliedFor?.CampusID
              )}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              value={programAppliedFor?.CampusCityId}
              onChange={handleProgramAppliedForChange}
              required
              disabled={true}
            />
          </Col>
          <Col md="3" lg="3">
            <FormGroupSelect
              label="Program Types"
              name="ProgramTypeId"
              list={SupportingTables?.tables?.filter(
                (x) =>
                  x.SetupMasterId == programTypes &&
                  // x.SetupDetailId != 3698 &&
                  x.parentid == programAppliedFor?.CampusCityId
              )}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              value={programAppliedFor?.ProgramTypeId}
              onChange={handleProgramAppliedForChange}
              required
              disabled={true}
            />
          </Col>
          <Col md="3" lg="3">
            <FormGroupSelect
              label="Admission Type"
              name="AdmissionTypeId"
              list={SupportingTables?.tables?.filter(
                (x) =>
                  x.SetupMasterId == admissionTypeId &&
                  x.parentid == programAppliedFor?.ProgramTypeId
              )}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              value={programAppliedFor?.AdmissionTypeId}
              onChange={handleProgramAppliedForChange}
              required
              disabled={true}
            />
          </Col>
          <Col md="3" lg="3">
            <FormGroupSelect
              label={`${
                programAppliedFor?.AdmissionTypeId == 4012 ||
                programAppliedFor?.AdmissionTypeId == 4014
                  ? "Intermediate Majors"
                  : "Bachelors Majors"
              }`}
              name="MajorId"
              list={SupportingTables?.MajorIdsSc?.Table}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              value={programAppliedFor?.MajorId}
              onChange={handleProgramAppliedForChange}
              required
              disabled={true}
            />
          </Col>

          <Col md="3" lg="3">
            <FormGroupSelect
              label="Faculty Department"
              name="AdmissionFacultyDepartmentId"
              list={SupportingTables?.FacultyDepartmentSc?.Table}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              value={programAppliedFor?.AdmissionFacultyDepartmentId}
              // onChange={handleProgramAppliedForChange}
              required
              disabled
              // disabled={
              //   programAppliedFor?.AdmissionTypeId == 0 ||
              //   programAppliedFor?.MajorId == 0 ||
              //   programAppliedFor?.AdmissionFacultyTypeId == 0
              //     ? true
              //     : false
              // }
            />
          </Col>
          <Col md="3" lg="3">
            <FormGroupSelect
              label="Faculty Department Program"
              name="FacultyDepartmentProgramId"
              list={SupportingTables?.FacultyDepartmentProgramIdSc?.Table}
              fieldId="SetupDetailId"
              fieldName="SetupDetailName"
              value={programAppliedFor?.FacultyDepartmentProgramId}
              // onChange={handleProgramAppliedForChange}
              required
              disabled
              // disabled={
              //   programAppliedFor?.AdmissionTypeId == 0 ||
              //   programAppliedFor?.MajorId == 0 ||
              //   programAppliedFor?.AdmissionFacultyTypeId == 0 ||
              //   programAppliedFor?.AdmissionFacultyDepartmentId == 0
              //     ? true
              //     : false
              // }
            />
          </Col>
        </Row>
      </form>
      <div className="table-responsive">
        <Row>
          <Col className="mt-3">
            <div>
              <table className="table table-striped mb-0 mt-2">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Examination</th>
                    <th className="text-center">Degree</th>
                    <th className="text-center">Group/ Major Subject</th>
                    <th className="text-center">Year</th>
                    <th className="text-center">Seat No</th>
                    <th className="text-center">Obtained Marks</th>
                    <th className="text-center">Total Marks</th>
                    <th className="text-center">Board</th>
                    <th className="text-center">
                      School/College/Institute/University
                    </th>
                    <th className="text-center">View</th>
                  </tr>
                </thead>
                <tbody>
                  {academicData?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.ExaminationName}</td>
                        <td className="text-center">{item.DegreeName}</td>
                        <td className="text-center">{item.MajorSubjects}</td>
                        <td className="text-center">{item.YearName}</td>
                        <td className="text-center">
                          {item.SeatNo_RollNumber}
                        </td>
                        <td className="text-center">{item.ObtainedMarks}</td>
                        <td className="text-center">{item.TotalMarks}</td>
                        <td className="text-center">{item.BoardName}</td>
                        <td className="text-center">{item.University}</td>
                        <td className="text-center">
                          <a
                            className="btn round-cubre btn-info"
                            href={item.DocumentPathNew}
                            // download
                            target="_blank"
                          >
                            <i className="fa fa-eye" color="primary"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {academicData.length == 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    background: "#e9e9e9",
                    marginTop: -5,
                    padding: 20,
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  No Data Available
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Academics;
