import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import { ADM_Update_PersonalInfo } from "../../utils/Config";
import { decryptData } from "../../EncryptData";
import { SessionStorage } from "../../common/SetupMasterEnum";

const UpdateEmployeeProfile = (props) => {
  const dispatch = useDispatch();

  const initialSearchFields = {
    OperationId: 0,
    AdmRegId: 0,
    Nic: 0,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    FatherName: "",
    Email: "",
    MobileNo: "",
    FileName: "",
    DocumentName: "",
    DocumentPath: "",
    UserId: decryptData("loginId", SessionStorage),
  };
  const initialFormFields = {
    OperationId: 0,
    AdmRegId: 0,
    Nic: 0,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    FatherName: "",
    Email: "",
    MobileNo: "",
    FileName: "",
    DocumentName: "",
    DocumentPath: "",
    UserId: decryptData("loginId", SessionStorage),
  };
  const columns = [
    { field: "AdmRegId", name: "Reg Id" },
    { field: "Nic", name: "CNic" },
    { field: "FirstName ", name: "First Name" },
    { field: "MiddleName", name: "Middle Name" },
    { field: "LastName", name: "Last Name" },
    { field: "FatherName", name: "Father Name" },
    { field: "Email", name: "Email" },
    { field: "MobileNo", name: "Mobile No" },
    { field: "DocumentPath", name: "Document Image" },
    // { field: "AdmissionTypeName", name: "Admission Type" },
    // { field: "FacultyDepartmentName", name: "Faculty Department" },
    // {
    //   field: "FacultyDepartmentProgramName",
    //   name: "Faculty Department Program",
    // },
  ];
  useEffect(() => {
    let data = {
      name: "AdmissionFacultyDepartmentId",
      value: formFields?.AdmissionFacultyDepartmentId,
    };
    dispatch({
      type: SET_INITIAL_DROPDOWN_FORM_STATE,
      payload: data,
    });
  }, []);
  const [fileName, setFileName] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const [formFields, setFormFields] = useState(initialFormFields);
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [updateButton, setUpdateButton] = useState(false);
  
  const handleSearchChange = (event) => {
    setSearchFields({
      ...searchFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageUpload = (event) => {
    setFileName(event.target.files[0].name);
    setFormFields({
      ...formFields,
      DocumentPath: event.target.files[0],
    });
  };
  const onSearch = (e) => {
    e.preventDefault();
    updateProfile(searchFields);
  };
  const searchProfile = (payload) => {
    // setSubmitLoad(true);
    ADM_Update_PersonalInfo(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          let data = {
            name: "UpdateProfileInfo",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        // setSubmitLoad(false);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        setUpdateButton(false);
      });
  };
  const onEdit = (index, obj) => {
    setFormFields({
      ...obj,
      index: index,
    });
    setModalToggle(true);
  };
  const onUpdate = (e) => {
    e.preventDefault();
    let formData = new FormData();
    Object.keys(formFields).forEach((d) => {
      formData.append(d, formFields[d]);
    });
    searchProfile(formData);
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateProfile = (payload) => {
    // setSubmitLoad(true);
    ADM_Update_PersonalInfo(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          Swal.fire({
            title: "Updated",
            text: res?.data?.Table[0]?.Message,
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Something Went Wrong",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        // setSubmitLoad(false);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        setUpdateButton(false);
      });
  };

  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Employee Profile</CardTitle>
        <CardBody>
          <form onSubmit={onSearch}>
            <Row>
              <Col md="4" lg="4">
                <FormGroupInput
                  label="CNIC"
                  name="Cnic"
                  value={searchFields?.Nic}
                  onChange={handleSearchChange}
                  required
                />
              </Col>
            </Row>
            <Col lg="12" md="12" xs="12" className="text-right mt-2">
              <Button color="primary" className="btn" type="submit">
                Search
              </Button>
              <Button
                className="btn"
                color="default"
                // onClick={onCancel}
                type="button"
              >
                Cancel
              </Button>
            </Col>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>
          <Row>
            <Col>Employee Profile List</Col>
          </Row>
        </CardTitle>
        <CardBody>
          <FormGroupTable
            columns={columns}
            rows={SupportingTables?.UpdateProfileInfo}
            onEdit={onEdit}
            // onDownloadText={onDownload}
          />
        </CardBody>
      </Card>
      <Modal centered isOpen={modalToggle}>
        <ModalHeader>Update Employee Profile</ModalHeader>
        <ModalBody>
          <form onSubmit={onUpdate}>
            <Row>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="First Name"
                  name="FirstName"
                  value={formFields?.FirstName}
                  onChange={handleSearchChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Middle Name"
                  name="MiddleName"
                  value={formFields?.MiddleName}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Last Name"
                  name="LastName"
                  value={formFields?.LastName}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="FatherName"
                  name="Father Name"
                  value={formFields?.FatherName}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="CNIC"
                  name="Cnic"
                  value={formFields?.Nic}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Mobile No"
                  name="MobileNo"
                  value={formFields?.MobileNo}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Email"
                  name="Email"
                  value={formFields?.Email}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <div className="form-group">
                  <label className="form-label">
                    Upload Attachment<span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    name="DocumentPath"
                    onChange={handleImageUpload}
                    accept=".pdf"
                    required
                  />
                </div>
              </Col>
              <Col lg="12" md="12" xs="12" className="text-right mt-2">
                <Button color="primary" className="btn" type="submit">
                  Add
                </Button>
                <Button
                  className="btn"
                  color="default"
                  type="button"
                  //   onClick={onClose}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default UpdateEmployeeProfile;
