import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import logoimg from "../../../../assets/img/logo.png";

const DegreeProgramLedgerReport = forwardRef((props, ref) => {
  const { TableList } = useSelector((state) => state.CrudFormReducer);

  return (
    <div ref={ref} style={{ fontSize: 12, margin: 6 }}>
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={logoimg} height="100" />
                    <h3 style={{ alignContent: "flex-end" }}>
                      SHAIKH AYAZ UNIVERSITY, SHIKARPUR
                    </h3>
                  </div>

                  <div style={{ marginTop: -30, marginBottom: 30 }}>
                    <h6 className="text-center">
                      Degree Program Ledger Report
                    </h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 10 }}>
            <table
              style={{
                width: "100%",
                border: "1px",
                borderStyle: "solid",
              }}
            >
              <thead>
                <tr
                  style={{
                    borderBottom: "0.5px solid",
                    color: "white",
                    padding: 5,
                    border: "1px",
                  }}
                >
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                      backgroundColor: "#772647",
                    }}
                  >
                    S.No.
                  </th>
                  {TableList?.length > 0
                    ? Object.keys(TableList?.[0])?.map((column, index) => (
                        <Fragment key={index}>
                          <th
                            style={{
                              textAlign: "center",
                              border: "1px",
                              borderStyle: "solid",
                              padding: 5,
                              backgroundColor: "#772647",
                            }}
                            key={index + 1}
                          >
                            {column}
                          </th>
                        </Fragment>
                      ))
                    : null}
                </tr>
              </thead>

              <tbody>
                {TableList &&
                  TableList?.map((value, ind) => (
                    <tr
                      style={{
                        borderBottom: "0.5px solid",
                        lineHeight: "30px",
                      }}
                      key={ind}
                    >
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px",
                          borderStyle: "solid",
                        }}
                      >
                        {ind + 1}
                      </td>
                      {Object.keys(TableList[ind])?.map((column, index) => (
                        <Fragment key={index}>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px",
                              borderStyle: "solid",
                            }}
                            key={index + 2}
                          >
                            {TableList[ind][column]}
                          </td>
                        </Fragment>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default DegreeProgramLedgerReport;
