import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import Header from "../../../../assets/img/header-img-report.jpg";

const SemesterResultReport = forwardRef((props, ref) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);

  return (
    <div ref={ref} style={{ fontSize: 12, margin: 20 }}>
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "4%" }}></td>
              <td style={{ width: "46%" }}>
                <table style={{ width: "50%" }}>
                  <thead>
                    <tr
                      style={{
                        border: "1px solid",
                        color: "black",
                        padding: 5,
                      }}
                    >
                      <th style={{ fontSize: 9 }} className="text-center">
                        Term Back Formula
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        border: "1px solid",
                        color: "black",
                        padding: 5,
                      }}
                    >
                      {/* <td style={{ width: "2.8%" }}></td> */}
                      <td
                        className="text-center"
                        style={{ fontSize: 9, width: "10%", padding: 5 }}
                      >
                        A student who is fail in four courses in any semester or
                        did not maintain G.P.A. 1.33 in a semester or did not
                        maintain continuity in appearing in the Examinations but
                        avails
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ width: "46%", textAlign: "-webkit-right" }}>
                <table style={{ width: "50%" }}>
                  <thead>
                    <tr
                      style={{
                        border: "1px solid",
                        color: "black",
                        padding: 5,
                      }}
                    >
                      <th
                        style={{ fontSize: 9 }}
                        colSpan="4"
                        className="text-center"
                      >
                        Gradding Formula
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        border: "1px solid",
                        color: "black",
                        padding: 5,
                      }}
                    >
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        87-100
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        A
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        G.P. 4.00
                      </td>
                    </tr>
                    <tr
                      style={{
                        border: "1px solid",
                        color: "black",
                        padding: 5,
                      }}
                    >
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        72 - 86
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        B
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        G.P.3.0 to 3.90
                      </td>
                    </tr>
                    <tr
                      style={{
                        border: "1px solid",
                        color: "black",
                        padding: 5,
                      }}
                    >
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        60 - 71
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        C
                      </td>
                      <td
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        G.P.2.0 to 2.90
                      </td>
                    </tr>
                    <tr
                      style={{
                        border: "1px solid",
                        color: "black",
                        padding: 5,
                      }}
                    >
                      <td
                        colSpan="4"
                        className="text-center"
                        style={{
                          fontSize: 9,
                          width: "10%",
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        Below 60 ---- Fail
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{ width: "4%" }}></td>
            </tr>
          </tbody>
        </table>
      </Row>

      <br />

      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 10 }}>
            <table
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ fontSize: 14, fontWeight: "bold" }}>
                    Bachelor Studies (Computer Science) BSCS (4 Years)
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: 14, fontWeight: "bold" }}>
                    Mr. / Miss . {SupportingTables?.SemesterResult?.[0]?.Name}{" "}
                    S/O {SupportingTables?.SemesterResult?.[0]?.FatherName}{" "}
                    {SupportingTables?.SemesterResult?.[0]?.Reg_No}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: 14, fontWeight: "bold" }}>
                    {SupportingTables?.SemesterResult?.[0]?.Semester}{" "}
                    {SupportingTables?.SemesterResult?.[0]?.AcademicYear}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 10 }}>
            <table
              style={{
                width: "100%",
                border: "1px",
                borderStyle: "solid",
              }}
            >
              <thead>
                <tr
                  style={{
                    borderBottom: "0.5px solid",
                    color: "black",
                    padding: 5,
                  }}
                >
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Course
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Credit hours
                  </th>

                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Obt - Grade - G.P
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Remarks
                  </th>
                </tr>
              </thead>
              <tbody>
                {SupportingTables?.SemesterResult?.length > 0 &&
                  SupportingTables?.SemesterResult?.map((x) => {
                    return (
                      <tr>
                        <td
                          style={{ border: "1px solid black" }}
                          className="text-center"
                        >
                          {x.Course}
                        </td>

                        <td
                          style={{ border: "1px solid black" }}
                          className="text-center"
                        >
                          {x.CrHrs}
                        </td>
                        <td
                          style={{ border: "1px solid black" }}
                          className="text-center"
                        >
                          {x.Marks}
                        </td>
                        <td
                          style={{ border: "1px solid black" }}
                          className="text-center"
                        >
                          FAIL
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default SemesterResultReport;
