import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Nav,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/login.css";
import logoimg from "../../../assets/img/logo.png";
import loginbg from "../../../assets/img/login-bg2.jpg";
// import Swal from "sweetalert2";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";

import propTypes from "prop-types";

const LoginFormComponent = (props) => {
  const {
    fieldsPanel,
    fieldEmployeePanel,
    formSubmit,
    admissionSubmit,
    initialFormFields,
    loginType,
    fieldNewRegistration,
    buttonName,
    disableButton,
  } = props;

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("1");
  const loginData = () => {
    localStorage.setItem("loginId", "2");
    props.history.push("/pages/dashboard");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    formSubmit(activeTab);
  };

  const handleAdmissionSubmit = (e) => {
    e.preventDefault();
    admissionSubmit();
  };

  return (
    <div className="auth-page">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg="6">
            <Card className="auth-form">
              <CardBody className="p-4">
                <div className="login-brand">
                  <img src={logoimg} height="130" />
                </div>
                {loginType === "Existing" && (
                  <Fragment>
                    <Nav pills className="nav-fill mt-3">
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "1" ? "loginTab1 active" : "loginTab1"
                          }
                          onClick={() => {
                            setActiveTab("1");
                            dispatch({
                              type: RESET_FORM_FIELDS,
                              payload: initialFormFields,
                            });
                          }}
                        >
                          Student
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            activeTab === "2" ? "loginTab2 active" : "loginTab2"
                          }
                          onClick={() => {
                            setActiveTab("2");
                            dispatch({
                              type: RESET_FORM_FIELDS,
                              payload: initialFormFields,
                            });
                          }}
                        >
                          Employee
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-content-inner"
                      activeTab={activeTab}
                    >
                      <TabPane tabId={activeTab}>
                        <Form onSubmit={handleFormSubmit}>
                          <h3 className="authf-title mb-1">
                            Welcome {activeTab == 1 ? "Student" : "Employee"}!
                          </h3>
                          <p className="authf-stitle mb-3">
                            Kindly Login to Continue
                          </p>
                          {activeTab == 1 ? (
                            <Fragment>{fieldsPanel}</Fragment>
                          ) : (
                            <Fragment>{fieldEmployeePanel}</Fragment>
                          )}
                          <FormGroup className="form-group text-right">
                            <Link to="/forgotusername" className="forgot-link">
                              Forgot LoginId
                            </Link>
                            <Link to="/forgotpassword" className="forgot-link">
                              {" "}
                              | Forgot Password
                            </Link>
                          </FormGroup>
                          {/* <Button color="primary" type="button" onClick={loginData}> */}
                          <div className="text-center mt-4 mb-3">
                            <Button
                              color="primary"
                              className="w-50"
                              type="submit"
                              disabled={
                                disableButton == undefined
                                  ? false
                                  : disableButton
                              }
                            >
                              Login
                            </Button>
                          </div>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </Fragment>
                )}
                {loginType === "New" && (
                  <form onSubmit={handleAdmissionSubmit}>
                    {fieldsPanel && (
                      <>
                        <h4 className="authf-title mb-1">
                          Welcome to SAUS Admission System
                        </h4>
                        <p className="authf-stitle mb-3">
                          Kindly Sign In with your Credentials
                        </p>
                        {fieldsPanel}
                        <FormGroup className="form-group text-right">
                          <Link
                            to="/admissionregistration"
                            className="forgot-link"
                          >
                            Register for the Admission
                          </Link>
                          {/* <Link to="/forgotusername" className="forgot-link">
                            {" "}
                            | Forgot LoginId
                          </Link> */}
                          <Link
                            to="/candidateforgotpassword"
                            className="forgot-link"
                          >
                            {" "}
                            | Forgot Password
                          </Link>
                        </FormGroup>
                      </>
                    )}
                    {fieldNewRegistration && (
                      <>
                        <h4 className="authf-title mb-1">
                          New User Registration
                        </h4>
                        <p className="authf-stitle mb-3">
                          Kindly Register for Admission
                        </p>
                        {fieldNewRegistration}
                        <FormGroup className="form-group text-right">
                          <Link
                            type="button"
                            to="/admissionlogin"
                            className="forgot-link"
                          >
                            Login
                          </Link>
                        </FormGroup>
                      </>
                    )}

                    <div className="text-center mt-1 mb-1">
                      <Button
                        disabled={
                          disableButton == undefined ? false : disableButton
                        }
                        color="primary"
                        className="w-50"
                        type="submit"
                      >
                        {buttonName}
                      </Button>
                    </div>
                  </form>
                )}
                <div className="form-copyright text-center">
                  Copyright © {new Date().getFullYear()} SAUS.
                </div>
                {fieldNewRegistration && (
                  <div className="form-copyright text-center">
                    UAN: 0243-920126
                  </div>
                )}
              </CardBody>
              {loginType != "Existing" && (
                <CardFooter>
                  <div className="text-center">
                    <h6>
                      Important Announcement: Entrance Test will be Conducted on
                      Sunday, 10th of December, 2023
                    </h6>
                  </div>
                </CardFooter>
              )}
            </Card>
          </Col>
          <Col
            lg="6"
            style={{
              backgroundImage: `url(${loginbg})`,
              backgroundSize: "cover",
            }}
          ></Col>
        </Row>
      </Container>
    </div>
  );
};

LoginFormComponent.propTypes = {
  fieldsPanel: propTypes.element,
  fieldEmployeePanel: propTypes.element,
  fieldNewRegistration: propTypes.element,
  formSubmit: propTypes.func,
  admissionSubmit: propTypes.func,
  initialFormFields: propTypes.object,
  loginType: propTypes.string,
  buttonName: propTypes.string,
  disableButton: propTypes.bool,
};

export default LoginFormComponent;
