import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Label } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";
import DatePicker from "react-datepicker";
import SelectDropDown from "react-select";
import makeAnimated from "react-select/animated";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  TMS_TMS_Employee_Leave_History,
} from "../../../utils/Config";
import {
  campusCity,
  campusType,
  department,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  formatDateFunction1,
  formatDateFunction2,
} from "../../../functions/DateFormatFunction";
import { CustomErrorMessage } from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  EmployeeList: new Array(0),
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  EmployeeId: 0,
  PeriodFrom: "",
  PeriodTo: "",
};
const initialFormFields = { SetupDetailName: "" };

const InchargeLeaveHistory = () => {
  const {
    SearchFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
    getDropDown();
    getEmployeeDropDown();
  }, []);
  const animatedComponents = makeAnimated();

  function getDropDown() {
    const payload = {
      operationId: Select,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {

        let data = {
          name: "SuportingTable",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getEmployeeDropDown() {
    const payload = {
      operationId: Select,
      employeeId: decryptData("EmplId", SessionStorage),
      case: 3,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {

        let data = {
          name: "EmployeeList",
          value: res.data.Table.map((x, index) => ({
            ...x,
            label: x.Name,
            value: index + 1,
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "CreatedDate", name: "Date" },
    { field: "SetupDetailName", name: "Leave Type" },
    { field: "EmployeeReason", name: "Reason Of Leave" },
    { field: "SetupDetailName1", name: "Status" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {};

  const handleSearchDropDownChange = (e) => {
    let data = { name: "EmployeeId", value: e.Id };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const AllDateSet = (event, type) => {

    if (type === "PeriodFrom") {
      setFromDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodFrom = date;
      let data1 = {
        name: "PeriodFrom",
        value: SearchFields?.PeriodFrom,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    } else if (type === "PeriodTo") {
      setToDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodTo = date;
      let data1 = {
        name: "PeriodTo",
        value: SearchFields?.PeriodTo,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    }
  };

  const cancelSearch = () => {

    setFromDate("");
    setToDate("");
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Campus Type"
          list={SupportingTables?.SuportingTable?.filter(
            (x) => x.SetupMasterId == campusType
          )}
          name="CampusTypeId"
          value={SearchFields?.CampusTypeId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.SuportingTable?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == SearchFields?.CampusTypeId
          )}
          label="Campus City"
          name="CampusCityId"
          onChange={handleSearchChange}
          value={SearchFields?.CampusCityId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          disabled={!SearchFields?.CampusTypeId}
          
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.SuportingTable?.filter(
            (x) => x.SetupMasterId == department
          )}
          label="Department"
          name="DepartmentId"
          onChange={handleSearchChange}
          value={SearchFields?.DepartmentId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <Label>Employees</Label>
        <SelectDropDown
          closeMenuOnSelect={true}
          components={animatedComponents}
          onChange={handleSearchDropDownChange}
          // isMulti={true}
          options={SupportingTables?.EmployeeList}
          // value={SearchFields.EmployeeId}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select From Date</label>
          <DatePicker
            selected={fromDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodFrom")}
            className="form-control"
            name="PeriodFrom"
            placeholderText={dateFormatPlaceholder}
            required
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select To Date</label>
          <DatePicker
            selected={toDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodTo")}
            className="form-control"
            name="PeriodTo"
            placeholderText={dateFormatPlaceholder}
            required
          />
        </div>
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    if (SearchFields?.EmployeeId != 0) {
      const payload = {
        operationId: Select,
        leaveAppliedId: 0,
        employeeId: SearchFields?.EmployeeId,
        fromDate: SearchFields?.PeriodFrom,
        toDate: SearchFields?.PeriodTo,
        createdBy: 0,
        modifiedBy: 0,
        userIP: "string",
      };

      TMS_TMS_Employee_Leave_History(payload)
        .then((res) => {

          if (res.data.Table.length > 0) {
            setFromDate("");
            setToDate("");
            let data = res.data.Table.map((x) => ({
              ...x,
              CreatedDate: formatDateFunction2(x.CreatedDate),
            }));

            dispatch({
              type: SET_INITIAL_CRUD_FORM_STATE,
              payload: {
                List: data,
                FormFields: initialFormFields,
                SearchFields: initialSearchFields,
              },
            });
          } else {
            CustomErrorMessage("No Record Found");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      CustomErrorMessage("Please Select Employee");
    }
  };

  return (
    <CrudFormComponent
      formName="Leave History"
      hideAction={true}
      newButton={false}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default InchargeLeaveHistory;
