import React, { forwardRef, Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import logoimg from "../../assets/img/logo.png";
import { Select, SessionStorage } from "../../common/SetupMasterEnum";
import { decryptData } from "../../EncryptData";
import {
  Admission_ADM_CandidateStatus,
  Admission_PrintAdmissionForm,
} from "../../utils/Config";
import { REG_ID } from "../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import { useState } from "react";

const PostgraduateAdmissionForm = forwardRef((props, ref) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const [number, setNumber] = useState(13);

  const dispatch = useDispatch();

  useEffect(() => {
    getCadidateStatus();
  }, []);

  const getAdmissionReportInfo = (ADMRegID, ProgramId) => {
    Admission_PrintAdmissionForm(ADMRegID, ProgramId).then((res) => {
      let data = {
        name: "ReportData",
        value: {
          Table: res.data?.Table,
          Table1: res.data?.Table1,
          Table2: res?.data?.Table2,
          Table3: res?.data?.Table3,
          Table4: res?.data?.Table4,
          Table5: res?.data?.Table5,
        },
      };
      dispatch({
        type: SET_INITIAL_DROPDOWN_FORM_STATE,
        payload: data,
      });
    });
  };

  const getCadidateStatus = () => {
    let data = {
      operationID: Select,
      admRegID: decryptData(REG_ID, SessionStorage),
      createdBy: 0,
      modifiedBy: 0,
      isActive: true,
      userIp: "string",
    };
    Admission_ADM_CandidateStatus(data)
      .then((res) => {
        let data = {
          name: "CandidateStatus",
          value: res.data.Table[0],
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        getAdmissionReportInfo(
          res?.data?.Table[0]?.ADMRegID,
          res?.data?.Table[0]?.ProgramId
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cnicBoxes = () => {
    return Array.from(Array(13), (e, i) => {
      return (
        <td
          key={i}
          style={{
            border: "1px",
            borderStyle: "solid",
            height: "20px",
            width: "20px",
          }}
        >
          {e}
        </td>
      );
    });
  };

  return (
    <div ref={ref}>
      <Container fluid>
        <div style={{fontSize:13}} className="card-div">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "20%", textAlign: "center" }} colspan="1">
                  <img src={logoimg} height="90" />
                </td>
                <td style={{ width: "65%", textAlign: "center" }} colspan="2">
                  {" "}
                  <p style={{ fontWeight: "bold" }}>
                    SHAH ABDUL LATIF UNIVERSITY
                  </p>
                  <p>Directorate of Postgraduate Studies</p>
                </td>
                <td style={{ width: "15%", textAlign: "center" }} colspan="1">
                  <tr>
                    <td style={{ height: "40px" }}>
                      Serial No: _______________
                    </td>
                  </tr>
                  <tr>
                    <td >
                      <img src={logoimg} style={{ marginBottom:'10%'}} height="70" />
                    </td>
                  </tr>
                </td>
              </tr>
              <tr>
               
                <td colSpan="3" className="text-center underline-heading">
                  <div style={{marginLeft:"20%", fontWeight:"bold"}}>
                    <p style={{marginBottom:"0"}}>Admission Form</p>
                    <p> MS/MPhil Programs</p>
                
                  </div>
                </td >
                <td rowSpan={2}   style={{border: "2px", borderStyle: "solid",height:"130px"}}>
                    <div className="text-center">Recent Photograph</div>
                </td>
               
              </tr>
              <tr>
                <td style={{ width: "30%", textAlign: "center" }} colSpan="2">
                  Tick (<i class="fa fa-check" aria-hidden="true"></i>) your
                  option:
                </td>
                <td colSpan="1">
                  <input type="checkbox"></input>&nbsp;&nbsp;&nbsp;&nbsp;MS{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="checkbox"></input>&nbsp;&nbsp;&nbsp;&nbsp;MPhil
                </td>
             
              </tr>
              <tr>
                <td style={{ width: "30%", textAlign: "center" }} colSpan="1">
                  Department/Subject
                </td>
                <td style={{ width: "30%" }} colSpan="1">
                  <input style={{ width: "80%" }} type="input"></input>
                </td>
                <td style={{ width: "20%", textAlign: "end" }}>Year</td>
                <td style={{ width: "30%", textAlign: "center" }} colSpan="1">
                  <input type="input"></input>
                </td>
                <td style={{ height: "50px" }}></td>
              </tr>
              <tr>
                <td
                  colSpan="4"
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  PERSONAL DETAILS
                </td>
              </tr>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "25%", textAlign: "center" }}>
                  Full Name: <br /> (IN BLOCK LETTERS)
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  ________________________________________
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  Fateher's/Husband's Name
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  _________________________________
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="text-center">Gender: </td>
                <td>
                  <input type="checkbox"></input> <label>Male</label>
                </td>
                <td>
                  <input type="checkbox"></input> <label>Female</label>
                </td>
                <td> CNIC#</td>
                <td>
                  <table>
                    <tbody>
                      <tr>{cnicBoxes()}</tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "25%", textAlign: "center" }}>
                  Mobile No:
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  _______________________
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  Alternative Number
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  _______________________
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  (if any) Language:
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  ________________
                </td>
              </tr>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "25%", textAlign: "center" }}>
                  Date of Birth:
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  ______ /______ /______ <br /> (dd - mm - yyyy)
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  Domicile (District/Provice)
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  _______________________
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "25%", textAlign: "center" }}>
                  Last Degree from: SALU
                </td>
                <td style={{ width: "40%" }}>
                  ____________________________________________________________________
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>Other</td>
                <td style={{ width: "25%" }}>
                  ___________________________________
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "15%", textAlign: "center" }}>
                  Present Address:
                </td>
                <td style={{ width: "85%" }}>
                  __________________________________________________________________________________________________________________
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "100%", textAlign: "center" }}>
                  ______________________________________________________________________________________________________________________________________
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "15%", textAlign: "center" }}>
                  Present Address:
                </td>
                <td style={{ width: "85%", textAlign: "center" }}>
                  ________________________________________________________________________________________________________________
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "10%", textAlign: "center" }}>Email:</td>
                <td style={{ width: "55%" }}>
                  ____________________________________________________________________
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>Whatsapp#</td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  ___________________________________
                </td>
              </tr>
              <tr>
                <td
                  colSpan="4"
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  GAT General/Subject Test Result (HEC approved)
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr style={{ height: "50px" }}>
                <td style={{ width: "30%", textAlign: "center" }}>
                  <div className="eligibilty-journal">
                    <ul>
                      <li>MPhil GAT (General) Score</li>
                    </ul>
                  </div>
                </td>
                <td style={{ width: "15%", textAlign: "center" }}>
                  _____________________
                </td>
                <td style={{ width: "15%", textAlign: "center" }}>
                  Passing Date
                </td>
                <td style={{ width: "15%", textAlign: "center" }}>
                  _____________________
                </td>
                <td style={{ width: "15%", textAlign: "center" }}>
                  Valid upto
                </td>
                <td style={{ width: "15%", textAlign: "center" }}>
                  ______________________
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  UNDERTAKING
                </td>
              </tr>
              <tr>
                <td>
                  I hereby undertake that the entire made in admission form are
                  correct and complete to the best of my knowledge and that my
                  admission to the University is provisional and is subjected to
                  concellation if any irregularity is found in my admission
                  from/documents, Further, my name has not been
                  registered/enrolled as regular student for any other degree in
                  any field of stufy at other Institute/University, I also
                  undertake to abide by the rules and regulation of the SHAH
                  ABDUL LATIF UNIVERSITY{" "}
                </td>
              </tr>
              <tr>
                <td style={{ height: "30px" }}></td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Dated: ___/____/20___</td>
                <td style={{ width: "50%", textAlign: "end" }}>
                  _________________________
                  <br />
                  Sgnature of Applicant
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ height: "200px" }}></td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  ACADEMIC RECORD
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  (Candidates may fill Division / Percentages/ CGPA whichever is
                  applicable in the respective Examination and attach the
                  attested copies of all the certificates)
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead
              style={{
                border: "1px",
                borderStyle: "solid",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Name of Exam
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Subject(s)
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Board/University/DAI
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Year of Admission
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Year of Passing
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Marks Obtained
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Total Marks
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  Grade/Percentage/CGPA
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Matric / SSC
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Inter / HSC
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Graduation (BS/BA/B.Sc/B.Com/BBA/LLB)
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Masters(MA/M.Sc/M.Com/MBA)
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ height: "20px" }}></td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  EMPLOYEMENT RECORD
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%" }}>
            <thead
              style={{
                border: "1px",
                borderStyle: "solid",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  Name of Organization
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  Designation
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  BPS/Grade
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  Place of Posting
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                  rowSpan={2}
                >
                  Type of Organization
                </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  Period
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  From
                </th>
                <th
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  To
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Graduation (BS/BA/B.Sc/B.Com/BBA/LLB)
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Masters(MA/M.Sc/M.Com/MBA)
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  asd
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ height: "20px" }}></td>
              </tr>
              <tr>
                <td style={{ height: "10px", fontWeight: "bold" }}>
                  {" "}
                  Name and Address of your Employer
                  ................................................................................................................................................
                </td>
              </tr>
              <tr>
                <td style={{ height: "10px", fontWeight: "bold" }}>
                  {" "}
                  ......................................................................................................................................
                  Phone No .................................................
                </td>
              </tr>
              <tr>
                <td style={{ height: "20px" }}></td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td
                  className="underline-heading"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  FOR OFFICE USE ONLY
                </td>
              </tr>
              <tr>
                <td style={{ height: "10px" }}></td>
              </tr>
              <tr>
                <td
                  className="underline-heading"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Departmental Pre-Admission Test Result:
                </td>
              </tr>
              <tr>
                <td>
                  Genral Test Score for MSC/MPhil
                  ___________________________________________ Stands @S.No.
                  _____________________________ in Merit List.
                </td>
              </tr>
              <tr>
                <td style={{ height: "20px" }}></td>
              </tr>
              <tr>
                <td>
                  The Applicant has qualified the pre-admission test and he/she
                  may be provisionally admitted in{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" /> MS{" "}
                </td>
                <td>
                  <input type="checkbox" /> MPhil{" "}
                </td>
                <td>Program of the year ________________________________ </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ height: "20px" }}></td>
              </tr>
              <tr style={{ textAlign: "end" }}>
                <td style={{ width: "30%" }}></td>
                <td style={{ width: "30%" }}></td>
                <td style={{ fontWeight: "bold" }} className="text-center">
                  {" "}
                  _____________________________ <br />
                  Director/Chairman/Chairperson Institute/Department Office Seal
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ height: "20px" }}></td>
              </tr>
              <tr style={{ textAlign: "start", fontWeight: "bold" }}>
                <td style={{ width: "30%" }}> Dated ________________</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td style={{ height: "10px" }}></td>
              </tr>
              <tr>
                <td
                  className="underline-heading"
                  style={{ fontWeight: "bold" }}
                >
                  Document Required:
                </td>
              </tr>
              <tr>
                <div className="eligibilty-journal">
                  <ul>
                    <li>
                      Attested copies (Pas/Transcripts) of all the certificates
                      & degrees / CNIC / Domicile.
                    </li>
                    <li>03 Passport size fresh Photographs (Attested).</li>
                    <li>
                      NOC / Permission Letter / Study Leave in case of employees
                      where applicable.
                    </li>
                    <li>
                      Eligibility Certificate if your last degree is other than
                      this University
                    </li>
                  </ul>
                </div>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", textAlign: "end" }}>
                  {" "}
                  ______________________{" "} <br/> <div style={{marginRight:15}}>Director PGS</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
});

export default PostgraduateAdmissionForm;
