//ADMISSION URL STARTED

export const SETUP_YEAR_DEGREE_MAPPING = "/Setup/YearMapping_Degree";

export const ADMISSION_STATISTICS_REPORT = "/Admission/ADM_Statistics_RPT";

//ADMISSION URL ENDED

// ACADEMICS URL

export const ACAD_SUPPLI_IMPROVEMENT_OPS = "Academics/Acad_SuppliImprovmentOps";

export const TERM_BACK_SEMESTER = "/Academics/TermBackSemester";

export const SETUP_DEGREE_PARAMETER = "/academics/acad_setupdegreeparameters";

export const COURSE_SEMESTER_MAPPING = "/academics/acad_coursesemestermapping";

export const COURSE_SEMESTER_LECTURE_MAPPING =
  "/academics/acad_coursesemesterlecturermapping";

export const VISITING_FACULTY_PER_RATE_LIST =
  "/Academics/Acad_SetupVisitingFacultyRates";

export const SETUP_CREDIT_HOURS = "/Academics/Acad_SetupCreditHours";

export const SETUP_LOCATION = "/academics/acad_setuplocations";

export const SETUP_FAC_DEP_PRO_LEC_COU_SCO_CRI =
  "/academics/acad_setupfacdeptproglectcoursescorecriteria";

export const SETUP_FACULTY_DEPARTMENT_PROGRAM_EXAMS =
  "/academics/acad_setupfacultydepartmentprogramexams";

export const COURSESEMESTERFACDEPTPROGLRECTMAPPING =
  "/Academics/Acad_CourseSemesterFacDeptProgLectMapping";

export const ENROLLMENTOPS = "/Academics/Acad_EnrollmentOps";

export const COURSESEMESTERLOCATIONTIMESLOTMAPPING =
  "/Academics/Acad_CourseSemesterLocationTimeSlotMapping";

export const SETUPFACULTYDEPARTMENTPROGRAMLECTURER =
  "/Academics/Acad_SetupFacultyDepartmentProgramLecturer";

export const SETUPFACULTYDEPARTMENTPROGRAMEXAMSMARKSCRITERIAPARAMETERS =
  "Academics/Acad_SetupFacultyDepartmentProgramExamsMarksCriteriaParameters";

export const SETUP_FAC_DEPT_PROG_EX =
  "/Academics/Acad_SetupFacultyDepartmentProgramExams";

export const SETUP_DATE_OF_ANNOUNCEMENT =
  "/Academics/Acad_SetupDateOfAnnouncement";

export const FACULTY_DEPARTMENT_PROGRAM_EXAMS_SCHEDULE_MAPPING =
  "Academics/Acad_FacultyDepartmentProgramExamsScheduleMapping";

export const ENROLLED_STUDENT_ACADEMIC_RECORD =
  "Academics/Acad_EnrolledStudentAcademicRecord";

export const PRINT_IDCARD = "/academics/acad_printidcard";

export const FACULTY_DEPARTMENT_PROGRAM_COURSE_MAPP =
  "academics/acad_facultydepartmentprogramcoursemapping";

export const GENERATE_VOUCHER = "academics/acad_generatevoucher";

export const GENERATE_VOUCHER_FOR_ACADEMIC_YEAR =
  "academics/Acad_GenerateVoucherForNextPart";

export const ACADEMIC_RECORD = "academics/acad_attandancerecord";

export const ACAD_SETUP_ASSIGNMENT_RATE = "academics/SetupAssignmentRate";
export const ACAD_SETUP_FACULTY_RATE = "academics/SetupFacultyRate";
// export const ATTANDANCE_RECORD = "academics/acad_attandancerecord";

// Student Adacemics

export const STUDENT_LOGIN_OPS = "Academics/Acad_StudentLoginOps";

export const CURRENT_SEMESTER = "Academics/Acad_CurrentSemesterStudentOps";

export const CURRENT_SEMESTER_FACULTY_OPS =
  "Academics/Acad_CurrentSemesterFacultyOps";

export const FACULTY_LECTURE_UPLOAD_COURSE_LECTURES_TIME_SLOT_MAPPING =
  "Academics/Acad_FacultyLecturerUploadCourseLecturesTimeSlotMapping";

export const SUPPLE_IMPROVEMENT = "Academics/SuppleImprovement";

export const STUDENT_VOUCHER = "";

// Examination Controller

export const ACAD_EXAM_CONTROLLER_REPORTS_OPS =
  "Academics/Acad_ExamControllerReportsOps";

export const ACAD_FEES_STATUS = "Academics/Acad_BPVoucherReport";

export const ACAD_EXAM_CONTROLLER_REPORTS =
  "Academics/ExaminationControllerReports";

export const EXAM_CONTROLLER_STUDENT_TRANSCRIPT =
  "Academics/ExaminationControllerReports";
//HOD Academics

export const CURRENT_SEMESTER_HOD_OPS = "Academics/Acad_CurrentSemesterHODOps";

export const SETUP_ATTENDANCE_CRITERIA =
  "Academics/Acad_SetupAttandanceCriteria";

export const ACAD_HOD_REPORTS_OPS = "Academics/Acad_HODReportsOps";
export const ACAD_REMUNERATION_ASSIGNMENT =
  "Academics/Acad_RemunerationAssignmentsReport";

export const ACAD_SEMESTERMARKSHEET = "Academics/Acad_Semestermarksheet";

export const CLASS_CONDUCTREPORT = "Academics/Acad_ClassConductreport";

export const SUBJECT_ALLOTMENT_REPORT = "Academics/Acad_SubjectAllotmentReport";

export const ACAD_FACULTY_DEPART_TRANSFER =
  "Academics/Acad_FacultyDepartmentTransfer";

export const ACAD_ACTIVE_ACADEMICS_OPS = "Academics/Acad_ActiveAcademicsOps";

export const ACAD_STUDENT_ATTENDANCE_REPORT =
  "Academics/Acad_StudentAttendanceReport";

//Examination Controller URL

export const ACAD_EXAM_CONTOLLER_REPORTSOPS =
  "Academics/Acad_ExamControllerReportsOps";

export const ACAD_UPLOAD_ATTENDANCE_FILE_BY_CANDIDATE =
  "Academics/Acad_UploadAttendanceFileByCandidate";

export const ACAD_SETUPCOMMITEE = "Academics/Acad_SetupCommittee";

export const ACAD_DEPARTMENTCOMMITTEEMEMBERS =
  "Academics/Acad_DepartmentCommitteeMembers";

export const ACAD_RECOUNTING_RECHECKING_CASESOPS =
  "Academics/Acad_Recounting_Recehecking_CasesOps";

export const ACAD_COMMITTEE_RECOUNTING_RECHECKING_CASESOPS =
  "Academics/Acad_Committee_Recounting_Recehecking_CasesOps";

// \\ ACADEMICS URL

// ADMISSION URL

// END ADMISSION URL

export const SETUP_SUPPLY_IMPROVEMENT_FEE_STRUCTURE =
  "Setup/Setup_SupplyImprovementFeeStructure";

//SETUP URL START

export const SETUP_ACADEMIC_YEAR = `Admission/CreateAcademicYear`;
export const ADM_AdmissionVoucherDueDateExtend = `Admission/ADM_AdmissionVoucherDueDateExtend`;
export const SETUP_CANDIDATE_ACTIONS_CONTROL = `Setup/Setup_CandidateActionsControl_`;
// URL CREATED FROM OLD FUNCTIONS STARTS
// GENERAL SETUPS
export const SECURITY_SETUP_ROLE_OPERATIONS = `/SecuritySetup/RoleOperation`;
export const SECURITY_SETUP_GET_APPLICATIONS = `/SecuritySetup/GetApplications`;
export const SETUP_MASTER_DETAIL_ALL_DROPDOWN = `/Setup/MasterDetail_All_DropDown`;
export const SETUP_MASTER_DETAIL_OPERATIONS = `/Setup/MasterDetail_Operation`;
export const SETUP_LEAVE_DEFINITION_OPERATIONS = `/Setup/LeaveDefinition_Operations`;
export const SETUP_ADM_SEAT_TYPE = `/Setup/Setup_ADM_SeatType`;
export const SETUP_MARKS = `/Setup/SetupMarks`;
export const SETUP_MENU_ITEM_OPERATION = `/Setup/MenuItem_Operation`;
//HRMS SETUPS
export const SETUP_SHIFT_TIMING = `/Setup/GetEmpShift`;
export const SETUP_GET_EMPLOYEE_PROFILE = `/Setup/GetEmployeeProfile`;
export const SETUP_SEARCH_EMPLOYEE =`/Setup/SearchEmployee`;
//ADMISSION SETUPS
export const ADM_ELIGIBILITY_CRITERIA_DEPENDENCY = `/Admission/ADM_EligibilityCriteriaDependency`;
export const ADM_FACULTY_DEPARTMENT_FEE_STRUCTURE = `/Setup/Setup_ADM_FeeStructureProgram`;
export const SETUP_ADMISSION_BLOCKS = `/Setup/Setup_Blocks`;
export const SET_ADMISSION_ELIGIBILITY = `/Setup/SetAdmissionEligibility`;
export const SETUP_ADMISSION_ASSIGN_BLOCK = `/Setup/AssignBlock`;
export const ADM_SETUP_MERIT_SCORE = `/Admission/ADM_SetupMeritScore`;
export const ADMISSION_SETUP_TEST_TIME_DETAIL = `/Admission/ADM_SetupTestTimeDetail`;
export const SETUP_ADMISSION_SCORE_CRITERIA = `/Setup/ScoreCalculationCriteria`;
export const SETUP_DASHBOARD = `/Setup/GetDashboard`;
export const SETUP_ADM_COURSE_MAPPING = `/Academics/Setup_ADM_CourseSMapping`;
//ACADEMIC SETUPS
export const ACAD_SETUP_COURSE = `/Academics/Acad_SetupCourse`;

export const ACAD_SETUP_TIME_SLOTS = `/Academics/Acad_SetupTimeSlots`;
export const ACAD_SETUP_LECTURER = `/Academics/Acad_SetupLecturer`;
export const ACAD_STUDENT_DETAIL_OPS = `/Academics/Acad_StudentDetailOps`;
//SETUP URL END

//ADMISSIONS
export const ADMISSION_ADM_DASHBOARD = `/Admission/ADM_DashBoard`;
export const ADM_APPROVED_REJECT_SELFFINANCE = `/Admission/ADM_Approved_Reject_SelfFinance`;
export const ADM_REGISTERED_CANDIDATES = `/Admission/ADM_Registered_Candidates`;
export const ADM_ADDMANUALPREFERENCES = `/Admission/ADM_AddManualPreferences`;
export const ADM_ADDQUOTA = `/Admission/ADM_AddQuota`;
export const ADM_MANUALCONSIDERATION = `/Admission/ADM_ManualSeatConsideration`;
export const ADMISSION_SCHEDULE_RECORDS = `/Admission/AdmissionScheduleForAdmission`;
export const ADMISSION_DESCRIPTION = `/Admission/CreateDescription`;
export const REPORT_CANDIDATE_STATUS = `/Reports/CandidateStatusReport`;
export const ADM_SETUP_MERIT_PARAMETER = `/Admission/ADM_SetupMeritParameter`;
export const ADM_DEPARTMENT_PROGRAM_CANDIDATE_REPORT = `/Admission/ADM_Department_Program_Candidate_Report`;
export const ADM_SECURE_ADMISSION_SEATS_FACULTY_WISE = `/Admission/ADM_SecureAdmissionSeatsFacultyWise`;
export const ADMISSION_GET_REGISTER_RECORDS = `/Admission/GetRegisteredRecords`;
export const GET_ADMISSION_CANDIDATE_LIST = `/Admission/GetCandidateListDropdown`;
export const ADMISSION_UPDATE_REGISTRATION_RECORD = `/Admission/StudentRegistrationApproval`;
export const ADMISSION_CANDIDATELIST = `/Admission/GetCandidateListDropdown`;
export const ADMISSION_CANDIDATE_STATUS = `/Admission/ADM_CandidateStatus`;
// export const GET_STUDENT_ADMISSION_RECORD = `/Admission/GetStudentAdmissionRecord/${ADMRegId}/${Programid}`
export const ADMISSION_UPDATE_FEE_CHALLAN = `/Admission/UpdateFeeChallan`;
export const ADMISSION_INTERVIEW_SCREENING = `/Admission/InterviewScreening`;
export const ADMISSION_SCHEDULER = `/Admission/AdmissionSchedule`;
export const ADMISSION_EXAM = `/Admission/CreateExam`;
export const ADMISSION_MERITLIST = `/Admission/ADM_OpenMeritList`;
export const APPROVED_REJECT_MERITLIST = `/Admission/ADM_Approved_RejectMeritList`;
export const ADM_ADMISSION_SECURE_STUDENT = `/Admission/ADM_AdmissionSecureStudent`;
export const ADM_PRINT_TEST_SLIP = `/Admission/ADM_PrintTestSlip`;
export const ADM_PRINT_VOUCHER = `/Admission/ADM_PrintVoucher`;
export const ADM_ADD_MANUAL_OPML_SEAT_ALLOCATION = `/Admission/ADM_AddManualOPMLSeatAllocation`;
export const ADM_ADM_OFFERED_PROGRAMS = `/Admission/ADM_ADM_OfferedPrograms`;
export const ADMISSION_ADM_FORGETPASSWORD = `/Admission/ADM_ForgetPassword`;
export const ADMISSION_ADM_REGISTRATION = `/Admission/ADM_Registration`;
//MultiPart/FormData API
export const ADMISSION_OMR_RECORD = `/Admission/OMRRecord`;
export const ADM_UPLOAD_FEE_CHALLAN = `/Admission/ADMUploadFeeChallan`;
export const ADMISSION_SAMPLE_PAPER = `/Admission/SamplePaper`;
export const ADM_UPDATE_PERSONALINFO = `/Admission/ADM_Update_PersonalInfo`;
export const ADMISSION_CREATEADMISSIONRECORD = `/Admission/CreatAdimissionRecord`;
//URL CREATED FROM OLD FUNCTIONS END
// Start Login
export const LOGIN = "SecuritySetup/usp_UserLogin";
// End Login

//Finance URLS

export const CHARTOFACCOUNT = "BPS/SetupChartOfAccounts";
export const ACCOUNTNATURE = "BPS/SetupAccountNature";
export const JOURNALS = "BPS/SetupJournals";
export const TRANSACTION_TYPE = "BPS/SetupTransType";
// export const Customer ="BPS/SetupTransType";
export const CUSTOMER_SETUP = "BPS/SetupParty";
export const VENDOR_SETUP = "BPS/SetupParty";
export const VOUCHERS = "BPS/Vouchers";
export const COST_CENTER = "BPS/SetupCostCenter";
export const COMPANY = "BPS/SetupCompany";
export const SETUP_FIN_COA = "/Setup/Setup_FIN_COA";
export const SETUP_ACCOUNT_NATURE = "/BPS/SetupAccountNature";
export const SETUP_JOURNAL = "/BPS/SetupJournals";
export const FINANCE_FIN_HEAD_ACCOUNTLIST = "/Finance/FIN_HeadAccountsList";
export const FINANCE_PAYMENT_MODEL = "/Finance/PaymentModel";
export const FINANCE_GL_REPORT = "/Finance/GLReport";

//Finance URLS End
