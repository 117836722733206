import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import {
  PostRequest,
  Setup_MasterDetails_All_Dropdowns,
} from "../../utils/Config";

import { useReactToPrint } from "react-to-print";

import AdmissionStatisticsReport from "./AdmissionReports/AdmissionStatisticsReport";
import { ADMISSION_STATISTICS_REPORT } from "../../utils/UrlConstants";
import { decryptData } from "../../EncryptData";
import { UserNetworkInfo } from "../../utils/EncryptedConstants";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import { academicYearId } from "../../common/SetupMasterEnum";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../Hooks/useSetupDetailList";

const AdmissionStatistics = () => {
  const initialSearchFields = {
    OperationID: 0,
    ModifiedBy: 0,
    AcademicYearId: 3637,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  useEffect(() => {
    getAdmissionStatistics();
  }, []);
  const componentReference = useRef();
  const handlePrintAdmissionStatistics = useReactToPrint({
    content: () => componentReference.current,
  });
  const dispatch = useDispatch();

  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const columns = [
    {
      field: "AdmissionFacultyDepartment",
      name: "Department",
    },
    {
      field: "FacultyDepartmentProgram",
      name: "Program",
    },
    {
      field: "TotalApplicationRecieved",
      name: "Applications Recieved ",
    },
    {
      field: "Male",
      name: "Male",
    },
    {
      field: "Female",
      name: "Female",
    },

    {
      field: "Approved",
      name: "Approved",
    },

    {
      field: "Pending",
      name: "Pending",
    },

    {
      field: "Moved",
      name: "Moved",
    },

    {
      field: "Rejected",
      name: "Rejected",
    },
    {
      field: "Revert",
      name: "Revert",
    },
    {
      field: "Trashed",
      name: "Trashed",
    },

    {
      field: "TotalPaid",
      name: "Total Paid",
    },
    {
      field: "TotalUnPaid",
      name: "Total UnPaid",
    },
    {
      field: "AmountDeposited",
      name: "Amount Deposited",
    },
  ];

  function getAdmissionStatistics() {
    PostRequest(ADMISSION_STATISTICS_REPORT, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };
  const [academicYearList] = useSetupDetailList(academicYearId);

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearId"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearId}
          list={academicYearList?.filter(
            (x) =>
              x.SetupDetailId === 3637 ||
              x.SetupDetailId === 5623 ||
              x.SetupDetailId === 5658
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...initialSearchFields,
      AcademicYearId: SearchFields?.AcademicYearId,
    };
    PostRequest(ADMISSION_STATISTICS_REPORT, payload).then((res) => {
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: res?.data,
        },
      });
    });
  };

  const customButton = (
    <Button onClick={handlePrintAdmissionStatistics}>Print</Button>
  );

  const customTable = (
    <Fragment>
      {TableList?.Table?.length > 0 ? (
        <Fragment>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th key={index}>{column.name}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {TableList?.Table?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td>{item?.AdmissionFacultyDepartment}</td>
                    <td>{item?.FacultyDepartmentProgram}</td>
                    <td>{item?.TotalApplicationRecieved}</td>
                    <td>{item?.Male}</td>
                    <td>{item?.Female}</td>
                    
                    <td>{item?.Approved}</td>
                    <td>{item?.Pending}</td>

                    <td>{item?.Moved}</td>

                    <td>{item?.Rejected}</td>
                    <td>{item?.Revert}</td>
                    <td>{item?.Trashed}</td>
                    <td>{item?.TotalPaid}</td>
                    <td>{item?.TotalUnPaid}</td>

                    <td>{item?.AmountDeposited}</td>
                  </tr>
                );
              })}
              {TableList?.Table1?.map((tab, id) => {
                return (
                  <tr key={id}>
                    <td colSpan={2}></td>
                    <td style={{ fontWeight: "bold" }}>{tab?.Total}</td>
                    <td style={{ fontWeight: "bold" }}>
                      {tab?.TotalApplicationRecieved}
                    </td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalMale}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalFemale}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalApproved}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalPending}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalMoved}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalRejected}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalRevert}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalTrashed}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalPaid}</td>
                    <td style={{ fontWeight: "bold" }}>{tab?.TotalUnPaid}</td>
                    <td style={{ fontWeight: "bold" }}>
                      {tab?.TotalAmountDeposited}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div style={{ display: "none" }}>
            <AdmissionStatisticsReport ref={componentReference} />
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Admission Statistics"
      // buttonName="Add"
      customButton={customButton}
      modalSize="lg"
      tableColumns={columns}
      hideAction={true}
      customTable={customTable}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      // cancelSearch={cancelSearch}
      // handleCancel={handleCancel}
    />
  );
};

export default AdmissionStatistics;
