import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Input, Label, Row } from "reactstrap";
import CardCrudComponent from "../../../components/FormComponents/CardCrudForm";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupDatePicker from "../../../components/GeneralComponent/FormGroupDatePicker";

import {
  campusCity,
  campusType,
  department,
  monthId,
  Select,
  SessionStorage,
  yearId,
} from "../../../common/SetupMasterEnum";

import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Setup_CreateEmployee,
  Setup_GetEmployeeProfile,
  Setup_MasterDetails_All_Dropdowns,
  Setup_ReportingTo,
  Setup_SearchEmployee,
} from "../../../utils/Config";

import Swal from "sweetalert2";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  EmployeeId: 0,
  MonthId: 0,
  YearId: 0,
  AttendanceType : "0"
};
const initialFormFields = {
  employeeId: 0,
  employeeCode: 0,
  costCenterId: 0,
  hiringDepartmentId: 0,
  previousEmployeeCode: "",
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  fatherName: "",
  countryId: 0,
  cityId: 0,
  religionId: 0,
  genderId: 0,
  nationalityID: 0,
  matrialStatusId: 0,
  dateOfBirth: "",
  countryOfBirthId: 0,
  placeOfBirth: 0,
  domicileDistrict: "",
  highestDegreeid: 0,
  totalExperience: 0,
  cnic: "",
  cnicIssueDate: "",
  cnicExpiryDate: "",
  filerNonFiler: 0,
  ntn: "",
  ssn: "",
  primaryCellNo: "",
  homePhoneNo: "",
  workEmail: "",
  secondaryCellNo: "",
  officialPhoneNo: "",
  personalEmail: "",
  currentAddress: "",
  correspondenceAddress: 0,
  softwreLoginId: "",
  fileNo: "",
  employeeSectorId: 0,
  provinceId: 0,
  actual_DesignationId: 0,
  campusTypeId: 0,
  campusCityId: 0,
  // employeeTypeId: 0,
  employeeCateId: 0,
  employementStatusId: 0,
  hiringDesignationId: 0,
  hiringEmployeeGrade: 0,
  additionalChargeId: 0,
  currentDesignationId: 0,
  currentDepartmentId: 0,
  currentGradeId: 0,
  employeeStatusId: 0,
  facultyTypeId: 0,
  statusId: 0,
  idStatusId: 0,
  payRollTypeId: 0,
  joiningDate: "",
  probotionStartDate: "",
  probationEndDate: "",
  probotionExtendToDate: "",
  probationPeriodDays: 0,
  contractStartDate: "",
  contractEndDate: "",
  confirmationDate: "",
  relievingRetirementDate: "",
  tmsShiftId: 0,
  reportingto: 0,
  designationofRA: "",
  cardNumber: 0,
  accessCardId: 0,
  collectReasonId: 0,
  accessCardIssueDate: "",
  accessCardCollectDate: "",
  priodEndDate: "",
  inchargeId: 0,
  noticePeriodDays: 0,
  fax: "",
  isContractual: 0,
  pictureName: "",
  tmsLeaveId: 0,
  resignedDate: "",
  noOfChildren: 0,
  lastworkingDate: "",
  terminationTypeId: 0,
  noticePeriodTypeId: 0,
  hodid: 0,
  officialMobileNumber: "",
  managerId: 0,
  personalityDescription: "",
  officeExtension: "",
  leaveTypeId: 0,
  payScaleId: 0,
  domainId: 0,
  remarks: "",
  isDeleted: 0,
  isActive: 1,
  createdBy: 0,
  ModifiedBy: 0,
  userIP: "192.168.616",
  companyId: 0,
  locationId: 0,
  businessUnitId: 0,
  jobCategoryId: 0,
};

const ManualAttendance = () => {
  const {
    SearchFields,
    FormFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();
  const [imageUpload, setImageUpload] = useState();
  const [formLoad, setFormLoad] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  useEffect(() => {
    getEmployee();
    getMasterDetailAllDropdown();
  }, []);
  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        SearchFields: initialSearchFields,
        FormFields: {
          ...initialFormFields,
          createdBy: decryptData("loginId", SessionStorage),
        },
      },
    });
  }, []);

//   is ki jaga get attendance aye ga 
  function getEmployee() {
    setFormLoad(true);
    Setup_GetEmployeeProfile()
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: {
              ...initialFormFields,
              createdBy: decryptData("loginId", SessionStorage),
            },
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  }
  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: Select,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const columns = [
    { field: "AttendanceDate", name: "Attendance Date" },
    { field: "TimeIn", name: "Time In" },
    { field: "TimeOut", name: "Time Out" },
  ];
  function getReportingData(data) {
    Setup_ReportingTo(data)
      .then((res) => {
        setEmployeeList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleSearchChange = (e) => {

    if (e.target.name == "CampusTypeId") {
      SearchFields.CampusCityId = 1644;
    }
    else if (e.target.name == "DepartmentId") {
        getReportingData(e.target.value);
      }
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Row>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            label="Campus Type"
            name="CampusTypeId"
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == campusType
            )}
            onChange={handleSearchChange}
            value={SearchFields.CampusTypeId}
            // required
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            list={SupportingTables?.tables?.filter(
              (x) =>
                x.SetupMasterId == campusCity &&
                x.parentid == SearchFields.CampusTypeId
            )}
            label="Campus City"
            name="CampusCityId"
            onChange={handleSearchChange}
            value={SearchFields.CampusCityId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            disabled={!SearchFields.CampusTypeId}
            // required
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == department
            )}
            label="Department"
            name="DepartmentId"
            onChange={handleSearchChange}
            value={SearchFields.DepartmentId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            // required
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            label="Employee"
            name="EmployeeId"
            onChange={handleSearchChange}
            value={SearchFields?.EmployeeId}
              list={employeeList}
            fieldId="EmployeeId"
            fieldName="DisplayName"
            required
            intString="true"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            name="MonthId"
            label="Month"
            onChange={handleSearchChange}
            value={SearchFields.MonthId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == monthId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            name="YearId"
            label="Year"
            onChange={handleSearchChange}
            value={SearchFields.YearId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == yearId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <div className="form-group">
            <Label>
              {" "}
              Type
              <span className="text-danger">*</span>
            </Label>
            <div className="form-control" onChange={handleSearchChange}>
              <Input type="radio" defaultChecked value="0" name="AttendanceType" /> Payrollwise
              <Input type="radio" value="1" name="AttendanceType" /> Monthwise
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );

  const submitForm = () => {
    setSubmitLoad(true);
    Setup_CreateEmployee(FormFields, imageUpload)
      .then((res) => {
        setSubmitLoad(true);
        dispatch({
          type: RESET_FORM_FIELDS,
          payload: {
            ...initialFormFields,
            createdBy: decryptData("loginId", SessionStorage),
          },
        });
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              getEmployee();
            } else if (results.isConfirmed) {
              getEmployee();
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitSearch = () => {
    setFormLoad(true);
    Setup_SearchEmployee(SearchFields)
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: {
              ...initialFormFields,
              createdBy: decryptData("loginId", SessionStorage),
            },
            SearchFields: SearchFields,
          },
        });
        // } else {
        //   getEmployee();
        // }
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  };
  const cancelSearch = () => {
    getEmployee();
  };

  const onEditRow = (obj) => {};

  const onDeleteRow = (obj) => {};

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="6">
        <FormGroupDatePicker
          label="Start Date"
          name="StartDate"
          onChange={handleAddChange}
          value={FormFields.StartDate}
        />
      </Col>
      <Col lg="3" md="3" xs="3">
        <FormGroupSelect
          label="Start Time"
          name="StartTime"
          onChange={handleAddChange}
          value={FormFields.StartTime}
        />
      </Col>
      <Col lg="3" md="3" xs="3">
        <FormGroupSelect
          label="Start Hours"
          name="StartHours"
          onChange={handleAddChange}
          value={FormFields.StartHours}
        />
      </Col>
      <Col lg="6" md="6" xs="6">
        <FormGroupDatePicker
          label="End Date"
          name="EndDate"
          onChange={handleAddChange}
          value={FormFields.EndDate}
        />
      </Col>
      <Col lg="3" md="3" xs="3">
        <FormGroupSelect
          label="End Time"
          name="EndTime"
          onChange={handleAddChange}
          value={FormFields.EndTime}
        />
      </Col>
      <Col lg="3" md="3" xs="3">
        <FormGroupSelect
          label="End Hours"
          name="EndHours"
          onChange={handleAddChange}
          value={FormFields.EndHours}
        />
      </Col>
    </Fragment>
  );
  return (
    <CardCrudComponent
      formName="Daily Activity"
      buttonName="Add"
      modalSize="md"
      tableColumns={columns}
      customRows={TableList}
      formPanel={formPanel}
      formLoad={formLoad}
      submitLoad={submitLoad}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
    />
  );
};

export default ManualAttendance;
