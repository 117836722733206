import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import {
  admissionFacultyTypeId,
  admissionProgramId,
  admissionTypeId,
  campusCity,
  campusType,
  domicileProvinceId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  majorsAdId,
  majorsId,
  programTypes,
  quotaId,
  Search,
  SessionStorage,
  typeOfSeatId,
  undergraduateId,
} from "../../common/SetupMasterEnum";
import {
  Admission_CandidateList,
  Admission_GetRegisterRecords,
  Admission_GetStudendAdmissionRecord,
  Admission_UpdateRegistrationRecord,
  ADM_EligibilityCriteriaDependency,
  Setup_MasterDetails_All_Dropdowns,
  PostRequest,
} from "../../utils/Config";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import Swal from "sweetalert2";
import PreviewUnderGraduate from "./AdmissionAppicationPreview/PreviewUnderGraduate";
import { CustomErrorMessage } from "../../components/Alert";
import { decryptData } from "../../EncryptData";
import { REG_ID } from "../../utils/EncryptedConstants";
import Select from "react-select";
import {
  ADMISSION_CANDIDATELIST,
  ADMISSION_GET_REGISTER_RECORDS,
  ADMISSION_UPDATE_REGISTRATION_RECORD,
  ADM_ELIGIBILITY_CRITERIA_DEPENDENCY,
  SETUP_MASTER_DETAIL_ALL_DROPDOWN,
} from "../../utils/UrlConstants";

const AdmissionApplication = () => {
  const [pageNo, setPageNo] = useState(1);
  const initialSearchFields = {
    CampusTypeId: 1284,
    CampusCityId: 1644,
    ProgramsId: 3479,
    ProgramTypesId: 4009,
    AdmissionTypeId: 4012,
    MajorId: 0,
    AdmissionFacultyDepartmentId: 0,
    // AdmissionFacultyTypeId: 0,
    // FacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    ApplicationStatusId: -1,
    DomicileID: 0,
    TypeOfSeatId: 0,
    QuotaId: 0,
    Candidate: "0",
    PageNumber: pageNo,
    RowsOfPage: 50,
    Cnic: "",
  };
  const initialFormFields = {
    StudentApplicationId: 0,
    ApprovalAndReject: 0,
    RegistrationId: 0,
    Remarks: "",
  };

  const initialMoveToFields = {
    StudentApplicationId: 0,
    ApprovalAndReject: 4,
    RegistrationId: 0,
    Remarks: "",
    TypeOfSeatId: 0,
    QuotaId: 0,
  };

  const {
    SearchFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [formFields, setFormFields] = useState(initialFormFields);
  const [moveToFields, setMoveToFields] = useState(initialMoveToFields);
  const [confirmToolTipOpen, setConfirmToolTipOpen] = useState(false);
  const [revertToolTipOpen, setRevertToolTipOpen] = useState(false);
  const [moveToolTipOpen, setMoveToolTipOpen] = useState(false);
  const [rejectToolTipOpen, setRejectToolTipOpen] = useState(false);
  const [trashToolTipOpen, setTrashToolTipOpen] = useState(false);
  const [programId, setProgramId] = useState("");
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleFormSubmit, setToggleFormSubmit] = useState(false);
  const [toggleMoveTo, setToggleMoveTo] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [domicId, setDomicId] = useState(0);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };
  const toggle = (data) => {
    if (data === "confirm") {
      setConfirmToolTipOpen(!confirmToolTipOpen);
    } else if (data === "revert") {
      setRevertToolTipOpen(!revertToolTipOpen);
    } else if (data === "move") {
      setMoveToolTipOpen(!moveToolTipOpen);
    } else if (data === "reject") {
      setRejectToolTipOpen(!rejectToolTipOpen);
    } else {
      setTrashToolTipOpen(!trashToolTipOpen);
    }
  };
  useEffect(() => {
    getMasterDetailAllDropdown();
    getRegisteredRecords(initialSearchFields);
    getCandidateRecords();
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 1,
      paremeterID: undergraduateId,
    });
  }, []);

  const onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency = (
    payload
  ) => {
    // ADM_EligibilityCriteriaDependency(payload)
    PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
      .then((res) => {
        if (payload.caseID == 1) {
          let data = { name: "MajorIds", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        } else if (payload.caseID == 2) {
          let data = { name: "FacultyDepartment", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        } else {
          let data = { name: "FacultyDepartmentProgramId", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getRegisteredRecords(payload) {
    // Admission_GetRegisterRecords(payload)
    PostRequest(ADMISSION_GET_REGISTER_RECORDS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        let data = {
          name: "records",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleInputChange = (e) => {
    if (e.target.name === "MajorId") {
      setSearchFields({
        ...searchFields,
        AdmissionFacultyDepartmentId: 0,
        FacultyDepartmentProgramId: 0,
        [e.target.name]: e.target.value,
      });
      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
        operationID: Search,
        caseID: 2,
        paremeterID: e.target.value,
      });
    } else if (e.target.name === "AdmissionFacultyDepartmentId") {
      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
        operationID: Search,
        caseID: 3,
        paremeterID: e.target.value,
      });
      setSearchFields({
        ...searchFields,
        FacultyDepartmentProgramId: 0,
        [e.target.name]: e.target.value,
      });
    } else {
      setSearchFields({
        ...searchFields,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleAddChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleMoveToChange = (e) => {
    setMoveToFields({
      ...moveToFields,
      [e.target.name]: e.target.value,
    });
  };

  const columns = [
    { field: "AppId", name: "App. ID" },
    { field: "CNICPassportNo", name: "CNIC/B-Form/Passport No" },
    { field: "ContactNo", name: "Contact No." },
    { field: "Applicant", name: "Candidate Name" },
    // { field: "CampusCityName", name: "Campus City" },
    // { field: "Program", name: "Program" },
    // { field: "AdmissionTypeId", name: "Admission Type" },
    // { field: "MajorName", name: "Major" },
    { field: "AdmissionFacultyDepartmentName", name: "Applied Dept." },
    { field: "DepartmentChoices", name: "Faculty Dept. Program" },

    { field: "SeatTypeName", name: "Seat Type Name" },
    { field: "QuotaName", name: "Quota Name" },

    { field: "DomicileName", name: "Domicile Province" },
    // { field: "DepartmentChoices", name: "Dept. Choices" },
    { field: "ApplicationStatusId", name: "Can. App. Status" },
    { field: "AdminStatusName", name: "Adm. App. Status" },
    { field: "ConsumerNumber", name: "Consumer Number" },
    { field: "FeeStatus", name: "Fee Status" },
    { field: "Remarks", name: "Remarks" },
    { field: "Action", name: "Action" },
  ];

  const onSearch = (e) => {
    e.preventDefault();
    const onSearchFields = {
      CampusTypeId: 1284,
      CampusCityId: 1644,
      ProgramsId: 3479,
      ProgramTypesId: 4009,
      AdmissionTypeId: 4012,
      MajorId: searchFields?.MajorId,
      AdmissionFacultyDepartmentId: searchFields?.AdmissionFacultyDepartmentId,
      FacultyDepartmentProgramId: searchFields?.FacultyDepartmentProgramId,
      ApplicationStatusId:
        searchFields?.ApplicationStatusId === undefined
          ? -1
          : searchFields?.ApplicationStatusId,
      DomicileID: searchFields?.DomicileID,
      TypeOfSeatId: searchFields?.TypeOfSeatId,
      QuotaId: searchFields?.QuotaId,
      Candidate:
        searchFields.Candidate === undefined ? "0" : searchFields.Candidate,
      Cnic: searchFields?.Cnic,
      PageNumber: 1,
      RowsOfPage: 50,
    };
    getRegisteredRecords(onSearchFields);
  };

  const updateRegistrationRecord = (payload) => {
    // setSubmitLoad(true);

    // Admission_UpdateRegistrationRecord(payload)
    PostRequest(ADMISSION_UPDATE_REGISTRATION_RECORD, payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
              setToggleFormSubmit(false);
              const onSearchFields = {
                CampusTypeId: 1284,
                CampusCityId: 1644,
                ProgramsId: 3479,
                ProgramTypesId: 4009,
                AdmissionTypeId: 4012,
                MajorId: searchFields?.MajorId,
                AdmissionFacultyDepartmentId:
                  searchFields?.AdmissionFacultyDepartmentId,
                FacultyDepartmentProgramId:
                  searchFields?.FacultyDepartmentProgramId,
                ApplicationStatusId:
                  searchFields?.ApplicationStatusId === undefined
                    ? -1
                    : searchFields?.ApplicationStatusId,
                DomicileID: searchFields?.DomicileID,
                TypeOfSeatId: searchFields?.TypeOfSeatId,
                QuotaId: searchFields?.QuotaId,
                Candidate:
                  searchFields.Candidate === undefined
                    ? "0"
                    : searchFields.Candidate,
                Cnic: searchFields?.Cnic,
                PageNumber: pageNo,
                RowsOfPage: 50,
              };
              getRegisteredRecords(onSearchFields);
              setToggleMoveTo(false);
            } else if (results.isConfirmed) {
              setToggleFormSubmit(false);
              const onSearchFields = {
                CampusTypeId: 1284,
                CampusCityId: 1644,
                ProgramsId: 3479,
                ProgramTypesId: 4009,
                AdmissionTypeId: 4012,
                MajorId: searchFields?.MajorId,
                AdmissionFacultyDepartmentId:
                  searchFields?.AdmissionFacultyDepartmentId,
                FacultyDepartmentProgramId:
                  searchFields?.FacultyDepartmentProgramId,
                ApplicationStatusId:
                  searchFields?.ApplicationStatusId === undefined
                    ? -1
                    : searchFields?.ApplicationStatusId,
                DomicileID: searchFields?.DomicileID,
                TypeOfSeatId: searchFields?.TypeOfSeatId,
                QuotaId: searchFields?.QuotaId,
                Candidate:
                  searchFields.Candidate === undefined
                    ? "0"
                    : searchFields.Candidate,
                Cnic: searchFields?.Cnic,
                PageNumber: pageNo,
                RowsOfPage: 50,
              };
              getRegisteredRecords(onSearchFields);
              setToggleMoveTo(false);
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };
  const onEdit = (item) => {
    setFormFields({
      ...formFields,
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 7,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    });
    setToggleFormSubmit(true);
  };
  const onConfirm = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 2,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    Swal.fire({
      text:
        'Are you sure you want to Approve Application ID "' +
        item?.ApplicationID +
        '"?',
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#24344e",
    }).then((result) => {
      if (result.isConfirmed) {
        updateRegistrationRecord(payload);
      }
    });
  };

  const onRevert = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 5,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    Swal.fire({
      text:
        'Are you sure you want to Revert Application ID "' +
        item?.ApplicationID +
        '"?',
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#24344e",
    }).then((result) => {
      if (result.isConfirmed) {
        updateRegistrationRecord(payload);
      }
    });
  };
  const onMove = (item) => {
    setDomicId(item?.DomicileID);
    setToggleMoveTo(true);
    setMoveToFields({
      ...moveToFields,
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 4,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    });
    let SeatTypeId = {
      name: "SeatTypeId",
      value: item.SeatTypeId,
    };
    dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: SeatTypeId });
    let QuotaId = {
      name: "QuotaId",
      value: item.QuotaId,
    };
    dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: QuotaId });
    // const payload = {
    //   StudentApplicationId: item.StudentApplicationId,
    //   ApprovalAndReject: 4,
    //   RegistrationId: item.ADMRegID,
    //   Remarks: item.Remarks,
    // };
    // updateRegistrationRecord(payload);
  };
  const onRefuse = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 3,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    Swal.fire({
      text:
        'Are you sure you want to Reject Application ID "' +
        item?.ApplicationID +
        '"?',
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#24344e",
    }).then((result) => {
      if (result.isConfirmed) {
        updateRegistrationRecord(payload);
      }
    });
  };
  const onTrash = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 8,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    Swal.fire({
      text:
        'Are you sure you want to Trash Application ID "' +
        item?.ApplicationID +
        '"?',
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#24344e",
    }).then((result) => {
      if (result.isConfirmed) {
        updateRegistrationRecord(payload);
      }
    });
  };

  const handlePrivewClick = (e, item) => {
    e.preventDefault();
    setProgramId(item.ProgramId);
    getStudendRecords(item.ProgramId, item.ADMRegID);
  };
  const handleFormCancel = () => {
    setToggleForm(false);
  };
  const handleFormSubmitCancel = () => {
    setToggleFormSubmit(false);
  };

  const handleMoveToSubmitCancel = () => {
    setToggleMoveTo(false);
    setMoveToFields(initialMoveToFields);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    updateRegistrationRecord(formFields);
  };
  const onSubmitMoveTo = (e) => {
    e.preventDefault();
    // const payload = {
    //   StudentApplicationId: item.StudentApplicationId,
    //   ApprovalAndReject: 4,
    //   RegistrationId: item.ADMRegID,
    //   Remarks: item.Remarks,
    // };
    updateRegistrationRecord(moveToFields);
  };
  function getStudendRecords(programID, ADMRegID) {
    const payload = {
      ADMRegId: ADMRegID,
      Programid: programID,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          setToggleForm(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getCandidateRecords() {
    Admission_CandidateList()
      .then((res) => {
        let data = {
          name: "candidates",
          value: res.data.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onCancel = () => {
    setSelectedOption(null);
    getRegisteredRecords({ ...initialSearchFields, Candidate: "0" });
    setSearchFields({ ...initialSearchFields, Candidate: "0" });
  };
  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    // const result = event.Id;
    const result = event.map((obj) => JSON.stringify(obj.value)).join(",");
    searchFields.Candidate = result;
  };

  const handlePagination = (type) => {
    if (type == "Previous") {
      setPageNo(pageNo - 1);
      searchFields.PageNumber = pageNo - 1;
      getRegisteredRecords(searchFields);
    } else if (type == "Next") {
      setPageNo(pageNo + 1);
      searchFields.PageNumber = pageNo + 1;
      getRegisteredRecords(searchFields);
    }
  };

  return (
    <Container fluid>
      {menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu).some(
        (x) => x.FeatureId === 6
      ) ? (
        <Card className="mt-3">
          <form onSubmit={onSearch}>
            <CardTitle>Online Applications</CardTitle>
            <CardBody>
              <Row>
                <Col md="9" lg="9">
                  <Row>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupInput
                        name="Cnic"
                        value={searchFields?.Cnic}
                        onChange={handleInputChange}
                        label="CNIC"
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <Label>Candidate Name</Label>
                      <Select
                        closeMenuOnSelect={false}
                        styles={customStyles}
                        // components={animatedComponents}
                        onChange={handleInputChangeSelect}
                        isMulti={true}
                        options={SupportingTables?.candidates}
                        value={selectedOption}
                        maxMenuHeight="200px"
                      />
                    </Col>
                    <Col md="3" lg="3">
                      <FormGroupSelect
                        label="Intermediate Majors"
                        name="MajorId"
                        list={SupportingTables?.MajorIds?.Table}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={searchFields?.MajorId}
                        onChange={handleInputChange}
                        // required
                        disabled={
                          searchFields?.AdmissionTypeId == 0 ? true : false
                        }
                      />
                    </Col>

                    <Col md="3" lg="3">
                      <FormGroupSelect
                        label="Faculty Department"
                        name="AdmissionFacultyDepartmentId"
                        list={SupportingTables?.FacultyDepartment?.Table}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={searchFields?.AdmissionFacultyDepartmentId}
                        onChange={handleInputChange}
                        // required
                        disabled={searchFields?.MajorId == 0 ? true : false}
                        // disabled={
                        //   programAppliedFor?.AdmissionTypeId == 0 ||
                        //   programAppliedFor?.MajorId == 0 ||
                        //   programAppliedFor?.AdmissionFacultyTypeId == 0
                        //     ? true
                        //     : false
                        // }
                      />
                    </Col>
                    <Col md="3" lg="3">
                      <FormGroupSelect
                        label="Faculty Department Program"
                        name="FacultyDepartmentProgramId"
                        list={
                          SupportingTables?.FacultyDepartmentProgramId?.Table
                        }
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={searchFields?.FacultyDepartmentProgramId}
                        onChange={handleInputChange}
                        // required
                        disabled={
                          searchFields?.AdmissionFacultyDepartmentId == 0
                            ? true
                            : false
                        }
                        // disabled={
                        //   programAppliedFor?.AdmissionTypeId == 0 ||
                        //   programAppliedFor?.MajorId == 0 ||
                        //   programAppliedFor?.AdmissionFacultyTypeId == 0 ||
                        //   programAppliedFor?.AdmissionFacultyDepartmentId == 0
                        //     ? true
                        //     : false
                        // }
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        list={SupportingTables?.tables?.filter(
                          (x) => x.SetupMasterId == domicileProvinceId
                        )}
                        label="Domicile Province"
                        name="DomicileID"
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={searchFields?.DomicileID}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        list={SupportingTables?.tables?.filter(
                          (x) => x.SetupMasterId == typeOfSeatId
                        )}
                        label="Seat Type"
                        name="TypeOfSeatId"
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={searchFields?.TypeOfSeatId}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        list={SupportingTables?.tables?.filter(
                          (x) => x.SetupMasterId == quotaId
                        )}
                        label="Quota"
                        name="QuotaId"
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={searchFields?.QuotaId}
                        onChange={handleInputChange}
                        disabled={
                          searchFields?.TypeOfSeatId === 3476 ? true : false
                        }
                      />
                    </Col>

                    <Col lg="12" md="12" sm="12" xs="12">
                      <div className="form-group">
                        <Label>Admin Application Status</Label>
                        <div
                          className="form-control"
                          onChange={handleInputChange}
                        >
                          <Input
                            type="radio"
                            value={0}
                            name="ApplicationStatusId"
                          />
                          Pending
                          <Input
                            type="radio"
                            value={5}
                            name="ApplicationStatusId"
                          />
                          Reverted
                          <Input
                            type="radio"
                            value={2}
                            name="ApplicationStatusId"
                          />
                          Approved
                          <Input
                            type="radio"
                            value={3}
                            name="ApplicationStatusId"
                          />
                          Rejected
                          <Input
                            type="radio"
                            value={8}
                            name="ApplicationStatusId"
                          />
                          Trashed
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md="3" lg="3" className="card-section">
                  <Row>
                    <Col md="6" lg="6">
                      <Card>
                        <CardBody>
                          <p className="card-total mb-0">
                            {SupportingTables?.records?.Table2?.[0]?.Approved}
                          </p>
                          <p className="card-number card-number-approved  mb-0 pb-0">
                            Total Approved
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6" lg="6">
                      <Card>
                        <CardBody>
                          <p className="card-total mb-0">
                            {SupportingTables?.records?.Table2?.[0]?.Pending}
                          </p>
                          <p className="card-number card-number-pending mb-0 pb-0">
                            Total Pending
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6" lg="6">
                      <Card>
                        <CardBody>
                          <p className="card-total mb-0">
                            {SupportingTables?.records?.Table2?.[0]?.Revert_}
                          </p>
                          <p className="card-number card-number-reverted mb-0 pb-0">
                            Total Reverted
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6" lg="6">
                      <Card>
                        <CardBody>
                          <p className="card-total mb-0">
                            {SupportingTables?.records?.Table2?.[0]?.Rejected}
                          </p>
                          <p className="card-number card-number-rejected mb-0 pb-0">
                            Total Rejected
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6" lg="6">
                      <Card>
                        <CardBody>
                          <p className="card-total mb-0">
                            {SupportingTables?.records?.Table2?.[0]?.Trashed}
                          </p>
                          <p className="card-number card-number-trashed mb-0 pb-0">
                            Total Trashed
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-right">
                    <Button color="secondary" type="submit">
                      Search
                    </Button>
                    <Button type="button" onClick={onCancel} color="default">
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>
      ) : null}

      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              Online Application
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          {menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu).some(
            (x) => x.FeatureId === 4
          ) ? (
            <Fragment>
              <Table
                className="adm-app-table"
                bordered
                striped
                responsive
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Preview</th>
                    <th>S.No.</th>
                    {/* {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))} */}
                    <th>App.ID</th>
                    <th>CNIC/B-Form/Passport No</th>
                    <th>Contact No.</th>
                    <th>Candidate Name</th>
                    {/* <th>Campus City</th>
                <th>Program</th>
                <th>Admission Type</th>
                <th>Major</th> */}
                    <th>Applied Dept</th>
                    <th>Faculty Dept. Program</th>
                    <th>Seat Type Name</th>
                    <th>Quota Name</th>
                    <th>Domicile Province</th>
                    <th>Can App. Status</th>
                    <th>Adm. App. Status</th>
                    <th>Consumer Number</th>
                    <th>Fee Status</th>
                    <th>Remarks</th>
                    {menuTable?.Table2?.filter(
                      (x) => x.MenuId === selectedMenu
                    ).some((x) => x.FeatureId === 2) ? (
                      <th>Action</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {TableList?.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td className="text-center">
                          <Button
                            style={{ lineHeight: "1" }}
                            color="secondary"
                            className="btnic"
                            size="sm"
                            onClick={(e) => handlePrivewClick(e, item)}
                          >
                            <i className="fa fa-eye" color="primary"></i>
                          </Button>
                        </td>
                        <td>
                          {ind +
                            1 +
                            SupportingTables?.records?.Table1?.[0]?.Min_}
                        </td>

                        <td>{item.ApplicationID}</td>
                        <td>{item.Cnic}</td>
                        <td>{item?.ContactNo}</td>
                        <td>{item.Name}</td>
                        {/* <td>{item.CampusCityName}</td>
                    <td>{item.ProgramName}</td>
                    <td>{item.AdmissionTypeName}</td>
                    <td>{item.MajorName}</td> */}
                        <td>{item.AdmissionFacultyDepartmentName}</td>
                        <td>{item.FacultyDepartmentProgramName}</td>
                        <td>{item.SeatTypeName}</td>
                        <td>
                          {item.QuotaName === null ? "-" : item.QuotaName}
                        </td>
                        <td>{item.DomicileName}</td>

                        {/* <td>{item.DepartmentChoice}</td> */}
                        <td style={{ width: "80px" }}>
                          {item.CandidateStatusName}
                        </td>
                        <td>{item.AdminStatusName}</td>
                        <td>{item?.ConsumerNumber}</td>
                        <td>
                          <strong
                            className={
                              item.FeeStatus === "Paid"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {" "}
                            {item.FeeStatus}
                          </strong>
                        </td>
                        <td
                          style={{ width: "140px" }}
                        >
                          <Label>{item.Remarks}</Label>
                          {menuTable?.Table2?.filter(
                            (x) => x.MenuId === selectedMenu
                          ).some((x) => x.FeatureId === 2) ? (
                            <Button
                              style={{ lineHeight: "1" }}
                              color="warning"
                              className="btnic"
                              size="sm"
                              onClick={() => onEdit(item)}
                              id="edit"
                            >
                              <i className="fa fa-pencil"> </i>
                            </Button>
                          ) : null}
                        </td>
                        {menuTable?.Table2?.filter(
                          (x) => x.MenuId === selectedMenu
                        ).some((x) => x.FeatureId === 2) ? (
                          <td
                            style={{ width: "160px" }}
                          >
                            <Button
                              style={{ lineHeight: "1" }}
                              color="success"
                              className="btnic"
                              size="sm"
                              onClick={() => onConfirm(item)}
                              id="confirm"
                              disabled={
                                item.CandidateStatus === 1
                                  ? item.AdminStatus === 0
                                    ? false
                                    : true
                                  : true
                              }
                            >
                              <i className="fa fa-thumbs-o-up"> </i>
                            </Button>
                            <Tooltip
                              placement="bottom"
                              isOpen={confirmToolTipOpen}
                              target="confirm"
                              toggle={() => toggle("confirm")}
                            >
                              Approve
                            </Tooltip>
                            <Button
                              style={{ lineHeight: "1" }}
                              color="primary"
                              className="btnic"
                              size="sm"
                              onClick={() => onRevert(item)}
                              id="revert"
                              disabled={
                                item.CandidateStatus === 1
                                  ? item.AdminStatus === 0
                                    ? false
                                    : true
                                  : true
                              }
                            >
                              <i className="fa fa-undo"></i>
                            </Button>
                            <Tooltip
                              placement="bottom"
                              isOpen={revertToolTipOpen}
                              target="revert"
                              toggle={() => toggle("revert")}
                            >
                              Revert
                            </Tooltip>
                            <Button
                              style={{ lineHeight: "1" }}
                              color="secondary"
                              className="btnic"
                              size="sm"
                              onClick={() => onMove(item)}
                              id="move"
                              disabled={
                                item.AdminStatus === 2 || item.AdminStatus === 4
                                  ? false
                                  : true
                              }
                            >
                              <i className="fa fa-exchange"> </i>
                            </Button>
                            <Tooltip
                              placement="bottom"
                              isOpen={moveToolTipOpen}
                              target="move"
                              toggle={() => toggle("move")}
                            >
                              Move
                            </Tooltip>
                            <Button
                              style={{ lineHeight: "1" }}
                              color="danger"
                              className="btnic"
                              size="sm"
                              onClick={() => onRefuse(item)}
                              id="reject"
                              disabled={
                                item.CandidateStatus === 0 ||
                                item.CandidateStatus === 1
                                  ? item.AdminStatus === 0
                                    ? false
                                    : true
                                  : true
                              }
                            >
                              <i className="fa fa-remove"></i>
                            </Button>
                            <Tooltip
                              placement="bottom"
                              isOpen={rejectToolTipOpen}
                              target="reject"
                              toggle={() => toggle("reject")}
                            >
                              Reject
                            </Tooltip>
                            <Button
                              style={{ lineHeight: "1" }}
                              color="danger"
                              className="btnic"
                              size="sm"
                              onClick={() => onTrash(item)}
                              id="trash"
                              disabled={
                                item.CandidateStatus === 0 ||
                                item.CandidateStatus === 1
                                  ? item.AdminStatus === 0
                                    ? false
                                    : true
                                  : true
                              }
                            >
                              <i className="fa fa-trash-o"></i>
                            </Button>
                            <Tooltip
                              placement="bottom"
                              isOpen={trashToolTipOpen}
                              target="trash"
                              toggle={() => toggle("trash")}
                            >
                              Trash
                            </Tooltip>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Row>
                <Col md="12" lg="12" className="text-right">
                  <Button
                    color="default"
                    disabled={
                      SupportingTables?.records?.Table1?.[0]?.Min_ == 0
                        ? true
                        : false
                    }
                    onClick={(e) => handlePagination("Previous")}
                  >
                    Previous
                  </Button>
                  <Button
                    disabled={
                      SupportingTables?.records?.Table3?.[0]?.Max_Count <=
                      SupportingTables?.records?.Table1?.[0]?.Max_
                        ? true
                        : false
                    }
                    color="default"
                    onClick={(e) => handlePagination("Next")}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md="12" lg="12" className="text-right">
                  <p style={{ fontWeight: "bold", margin: 10 }}>
                    Showing {SupportingTables?.records?.Table1?.[0]?.Min_} to{" "}
                    {SupportingTables?.records?.Table1?.[0]?.Max_} of{" "}
                    {SupportingTables?.records?.Table3?.[0]?.Max_Count}
                  </p>
                </Col>
              </Row>
            </Fragment>
          ) : null}
          <Modal
            isOpen={toggleForm}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
            // backdrop="static"
            size="xl"
          >
            <ModalHeader
            // toggle={toggle}
            >
              Record Scrutiny
            </ModalHeader>
            <ModalBody>
              <form>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <PreviewUnderGraduate Programid={programId} />
                  </div>
                </Row>
                <Row className="mt-3">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="default" onClick={handleFormCancel}>
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={toggleFormSubmit}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
          >
            <ModalHeader
            // toggle={toggle}
            >
              Add Remarks
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroupInput
                      name="Remarks"
                      maxLength="150"
                      required
                      onChange={handleAddChange}
                      value={formFields.Remarks}
                    />
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button
                      color="default"
                      type="button"
                      onClick={handleFormSubmitCancel}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={toggleMoveTo}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
          >
            <ModalHeader
            // toggle={toggle}
            >
              Move To
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmitMoveTo}>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <FormGroupSelect
                      list={SupportingTables?.tables?.filter((x) =>
                        x.SetupMasterId == typeOfSeatId &&
                        SupportingTables?.SeatTypeId == 3476
                          ? x.SetupDetailId !== SupportingTables?.SeatTypeId
                          : x.SetupMasterId == typeOfSeatId &&
                            x.SetupDetailId !== 3476
                      )}
                      label="Seat Type"
                      name="TypeOfSeatId"
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={moveToFields?.TypeOfSeatId}
                      onChange={handleMoveToChange}
                      required
                    />
                  </Col>
                  {moveToFields?.TypeOfSeatId == 3477 ? (
                    <Col lg="6" md="6" xs="12">
                      <FormGroupSelect
                        list={SupportingTables?.tables?.filter((x) =>
                          x.SetupMasterId == 1101 && domicId !== 3603
                            ? x.SetupDetailId == 3600
                            : x.SetupMasterId == 1101 && x.SetupDetailId != 3594
                        )}
                        label="Quota"
                        name="QuotaId"
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={moveToFields?.QuotaId}
                        onChange={handleMoveToChange}
                        required
                      />
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Move To Reserved Seat
                    </Button>
                    <Button
                      color="default"
                      type="button"
                      onClick={handleMoveToSubmitCancel}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AdmissionApplication;
