import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Input, Table, Tooltip } from "reactstrap";
import {
  Insert,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../../../components/Alert";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { PostFormRequest, PostRequest } from "../../../utils/Config";
import {
  CURRENT_SEMESTER_FACULTY_OPS,
  FACULTY_LECTURE_UPLOAD_COURSE_LECTURES_TIME_SLOT_MAPPING,
} from "../../../utils/UrlConstants";
import Spinner from "react-spinkit";

const FacultyFiles = () => {
  const { FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const timeoutRef = useRef(null);

  const [formLoad, setFormLoad] = useState(true);

  const [saveToolTip, setSaveToolTip] = useState(false);

  const intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    enrollmentID: 0,
    MidTermExamMarks: 0,
    FinalExamMarks: 0,
    CpMarks: 0,
    IsSaved: true,
    IsLockByLecturer: true,
  };

  const intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_ = {
    FacultyLecturerCourseOutlineTimeSlotMappingID: 0,
    CourseSemesterLocationTimeSlotMappingID: 0,
    OutlineDescription: "",
    IsTaken: false,
    IsCompleted: false,
  };

  const intialfacultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL =
    {
      FacultyLecturerUploadCourseLecturesTimeSlotMappingID: 0,
      CourseSemesterLocationTimeSlotMappingID: 0,
      FilePath: {},
      FileName: "",
    };

  const [
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
  ] = useState(intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_);

  const [
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    setFacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
  ] = useState(
    intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_
  );

  const [
    facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL,
    setFacultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL,
  ] = useState(
    intialfacultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL
  );

  const intialFormFields = {
    operationId: Select,
    parameterID: 5,
    employeeID: decryptData("EmplId", SessionStorage),
    courseSemesterLecturerMappingId: 0,
    courseSemesterMappingID: 0,
    userID: 0,
    userIP: "string",
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_: [
      enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    ],
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_: [
      facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    ],
    facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL: [
      facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL,
    ],
  };

  useEffect(() => {
    getFacultyExamRecap();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getFacultyExamRecap = () => {
    const payload = {
      ...intialFormFields,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
    };
    PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: intialFormFields,
          },
        });
        setFacultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL([
          ...res?.data?.Table.map((x) => ({
            FacultyLecturerUploadCourseLecturesTimeSlotMappingID:
              x.FacultyLecturerUploadCourseLecturesTimeSlotMappingID,
            CourseSemesterLocationTimeSlotMappingID:
              x.CourseSemesterLocationTimeSlotMappingID,
            FilePath: {},
            FilePath1: {},
            FileName: "",
          })),
        ]);
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "DayDate", name: "Day" },
    { field: "LectureFile", name: "Lecture File" },
    { field: "File", name: "File Status" },
    { field: "ViewLecture", name: "View Lecture" },
  ];

  const handleDocChange = (e, index) => {
    TableList[index]["FilePath"] = e.target.files[0];
    TableList[index]["FileName"] = e.target.files[0].name;
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: TableList,
        FormFields: FormFields,
      },
    });
    setFacultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL({
      FilePath: e.target.files[0],
      FileName: e.target.files[0].name,
    });
  };

  const onSave = () => {
    const data = {
      parameterID: 5,
      employeeID: decryptData("EmplId", SessionStorage),
      userID: 0,
      userIP: "string",
      operationId: Insert,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
    };
    FormFields.operationId = Insert;
    FormFields.parameterID = 5;
    FormFields.courseSemesterLecturerMappingId = params?.id1;
    FormFields.courseSemesterMappingID = params?.id2;
    FormFields.facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL =
      TableList;
    let formData = new FormData();
    Object.keys(data).forEach((d) => {
      formData.append(d, data[d]);
    });
    FormFields.enrolledStudentAcademicRecordDetail_FacultyOps_TBL_.forEach(
      (element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `enrolledStudentAcademicRecordDetail_FacultyOps_TBL_[${index}].${item}`,
            element[item]
          );
        });
      }
    );
    FormFields.facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_.forEach(
      (element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_[${index}].${item}`,
            element[item]
          );
        });
      }
    );
    FormFields.facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL.forEach(
      (element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL_[${index}].${item}`,
            element[item]
          );
        });
      }
    );
    WarningWithConfirmation(
      `Are you sure you want to submit course files?`
    ).then((result) => {
      if (result.isConfirmed) {
        PostFormRequest(
          FACULTY_LECTURE_UPLOAD_COURSE_LECTURES_TIME_SLOT_MAPPING,
          formData
        )
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              getFacultyExamRecap();
              CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const toggle = (data) => {
    if (data === "Save") {
      setSaveToolTip(!saveToolTip);
    }
  };

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="text-center">S.No.</th>
              {columns &&
                columns?.map((column, index) => (
                  <th
                    style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    key={index}
                    className="text-center"
                  >
                    {column.name}
                  </th>
                ))}
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {TableList?.map((item, index) => (
              <tr key={index}>
                <td
                  style={{ width: "5%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {index + 1}
                </td>
                <td
                  style={{ width: "20%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.Courses}
                </td>
                <td
                  style={{ width: "20%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.DayDate}
                </td>
                <td className="text-center">
                  <a
                    style={{
                      pointerEvents: item.PathCheck !== 1 && "none",
                    }}
                    className={`btn round-cubre btn-info ${item.PathCheck !== 1 && 'disabled' }`}
                    href={item.PathCheck === 1 ? item.FilePath : "#"}
                    // download
                    target="_blank"
                  >
                    <i className="fa fa-eye" color="primary"></i>
                  </a>
                </td>
                <td
                  className={
                    item.PathCheck === 1 ? "text-success" : "text-danger"
                  }
                >
                  {item.PathCheck === 1 ? "File Uploaded" : "File Not Uploaded"}
                </td>
                <td className="text-center">
                  <div className="form-group">
                    <Input
                      id="LectureFile"
                      type="file"
                      name="FilePath"
                      onChange={(e) => handleDocChange(e, index)}
                      accept=".pdf/,image/jpeg,image/jpg,image/png,text/csv,.docx,.doc,.xls,.txt"
                      // required
                    />
                  </div>
                </td>
                <td
                  style={{ width: "5%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  <Tooltip
                    placement="bottom"
                    isOpen={saveToolTip}
                    target="Confirm"
                    toggle={() => toggle("Save")}
                  >
                    Save Detail
                  </Tooltip>
                  <Button
                    color="success"
                    className="btnic"
                    size="sm"
                    onClick={onSave}
                    id="Confirm"
                  >
                    <i className="fa fa-floppy-o"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Faculty Semester Files"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      customTable={customTable}
      //   onClickSave={onSave}
    />
  );
};

export default FacultyFiles;
