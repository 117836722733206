import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, SessionStorage } from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { ACADEMIC_RECORD, CURRENT_SEMESTER_FACULTY_OPS } from "../../../utils/UrlConstants";
import { Button } from "reactstrap";

const FacultyCurrentSemester = (props) => {

  const initialFields = {
    OperationID: 0,
    AttandanceRecordID: 0,
    CourseSemesterLocationTimeSlotMappingID: null,
    EnrollmentDetailID: null,
    IsPresent: false,
    IsSaved: false,
    IsLocked: false,
    UserId: decryptData("loginId", SessionStorage),
    ClassDate: null,
    LecturerId: 0,
    CourseId: 0,
    AcademicYearId: 0,
    AttandanceRecord_: [],
  };
  
  const enrolledStudentAcademicRecordDetail_FacultyOps_TBL_ = [
    {
      EnrolledStudentAcademicRecordDetailID: 0,
      EnrollmentID: 0,
      MidTermExamMarks: 0,
      FinalExamMarks: 0,
      CPMarks: 0,
      OtherMarks:0,
      TotalMarks:0,
      IsSaved: true,
      IsLockByLecturer: true,
    },
  ];

  const facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_ = [
    {
      facultyLecturerCourseOutlineTimeSlotMappingID: 0,
      courseSemesterLocationTimeSlotMappingID: 0,
      outlineDescription: "",
      isTaken: false,
      isCompleted: false,
    },

    
  ];
  const facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL_ = [
    {
      CourseSemesterLocationTimeSlotMappingID: 0,
      FilePath: "string",
      FileName: "string",
    },
    
  ];

  const intialFormFields = {
    operationId: Select,
    parameterID: Select,
    employeeID: decryptData("EmplId", SessionStorage),
    courseSemesterLecturerMappingId: 0,
    courseSemesterMappingID: 0,
    userID: 0,
    userIP: "192.168.1.14",
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    facultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL_,
  };

  const { TableList } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const [formLoad, setFormLoad] = useState(true);

  const [onClickButton, setOnClickButton] = useState("");


  useEffect(() => {
    getFacultyCourse();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getFacultyCourse = () => {
    PostRequest(CURRENT_SEMESTER_FACULTY_OPS, intialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table?.map((x) => ({
              ...x,
              Outline: "Outline",
              ClassSchedule: "Class Schedule",
              Files: "Files",
              Awards: "Awards",
              MidTerm: "MidTerm Exam Attendance",
              FinalTerm: "FinalTerm Exam Attendance",

            })),
            FormFields: intialFormFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Course Name" },
    { field: "Outline", name: "Outline" },
    { field: "ClassSchedule", name: "Class Schedule" },
    { field: "Files", name: "Files" },
    { field: "MidTerm", name: "Mid Term Attendance"},
    { field: "FinalTerm", name: "Final Term Attendance"},
    { field: "Awards", name: "Awards" },
  ];

  const onClickRow = (type, index, obj) => {
    let courseMappingId = decryptData(
      obj?.CourseSemesterLecturerMappingId, 
      "CourseMappingId",
      SessionStorage
    );
    if (type === "Class Schedule") { 
      props.history.push(
        `/pages/academics/faculty/facultyschedule/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesterMappingId}`
      );
    } else if (type === "Awards") {
      props.history.push(
        `/pages/academics/faculty/facultyexamrecap/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesterMappingId}`
      );
    }
    else if (type === "Files") {
      props.history.push(
        `/pages/academics/faculty/facultyfiles/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesterMappingId}`
      );
    } 
    else if (type === "Outline") {
      props.history.push(
        `/pages/academics/faculty/facultysemesteroutline/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesterMappingId}`
      );
    }
    else if (type === "MidTerm Exam Attendance") {
      props.history.push(
        `/pages/academics/faculty/facultymidtermattendance/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesterMappingId}`
      );
    }
    else if (type === "FinalTerm Exam Attendance") {
      props.history.push(
        `/pages/academics/faculty/FacultyFinalTermAttendance/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesterMappingId}`
      );
    }

  };

  return (
    <AcademicFormComponent
      SemesterName="My Courses"
      columns={columns}
      rows={TableList}
      onClickRow={onClickRow}
      formLoader={formLoad}
      tableColorCode={true}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default FacultyCurrentSemester;
