import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import {
  Setup_CRUD_OTP,
  Setup_MasterDetails_All_Dropdowns,
} from "../../utils/Config";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import Swal from "sweetalert2";
import { undergraduateId } from "../../common/SetupMasterEnum";

const ResetPassword = () => {
  const [pageNo, setPageNo] = useState(1);

  const initialSearchFields = {
    OperationID: 0,
    PageNumber: pageNo,
    RowsOfPage: 10,
    AdmRegID: 0,
    Nic_FromB: "",
    Name: "",
    ProgramId: undergraduateId,
    EmailAddress: "",
    Password: "",
  };
  const resetFields = {
    OperationID: 3,
    PageNumber: pageNo,
    RowsOfPage: 10,
    AdmRegID: 0,
    Nic_FromB: "",
    Name: "",
    ProgramId: undergraduateId,
    EmailAddress: "",
    Password: "",
  };
  const changeEmailFields = {
    OperationID: 2,
    PageNumber: pageNo,
    RowsOfPage: 10,
    AdmRegID: 0,
    Nic_FromB: "",
    Name: "",
    ProgramId: undergraduateId,
    EmailAddress: "",
    Password: "",
  };
  const cnicFields = {
    OperationID: 5,
    PageNumber: pageNo,
    RowsOfPage: 10,
    AdmRegID: 0,
    Nic_FromB: "",
    Name: "",
    ProgramId: undergraduateId,
    EmailAddress: "",
    Password: "",
  };

  const {
    SearchFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [resetFormFields, setResetFormFields] = useState(resetFields);
  const [emailFormFields, setEmailFormFields] = useState(changeEmailFields);
  const [cnicFormFields, setCnicFormFields] = useState(cnicFields);
  const [confirmToolTipOpen, setConfirmToolTipOpen] = useState(false);
  const [revertToolTipOpen, setRevertToolTipOpen] = useState(false);
  const [moveToolTipOpen, setMoveToolTipOpen] = useState(false);
  const [rejectToolTipOpen, setRejectToolTipOpen] = useState(false);
  const [trashToolTipOpen, setTrashToolTipOpen] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleFormEmail, setToggleFormEmail] = useState(false);
  const [toggleFormCnic, setToggleFormCnic] = useState(false);



  const toggle = (data) => {
    if (data === "confirm") {
      setConfirmToolTipOpen(!confirmToolTipOpen);
    } else if (data === "revert") {
      setRevertToolTipOpen(!revertToolTipOpen);
    } else if (data === "move") {
      setMoveToolTipOpen(!moveToolTipOpen);
    } else if (data === "reject") {
      setRejectToolTipOpen(!rejectToolTipOpen);
    } else {
      setTrashToolTipOpen(!trashToolTipOpen);
    }
  };
  useEffect(() => {
    getMasterDetailAllDropdown();
    OTP_CRUD(initialSearchFields);
    // OTP_CRUD();
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function OTP_CRUD(payload) {
    Setup_CRUD_OTP(payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            SearchFields: initialSearchFields,
          },
        });
        let data = {
          name: "otprecords",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleInputChange = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value,
    });
  };

  const columns = [
    { field: "RegId", name: "Reg. ID" },
    { field: "CandidateName", name: "Candidates" },
    { field: "Email", name: "Email" },
    { field: "Password", name: "Password" },
    { field: "NIC_FormB", name: "NIC No." },
    // { field: "OTPL", name: "OTP" },
    // { field: "VerificationOTP", name: "Status" },
    { field: "Action", name: "Action" },
  ];

  const onSearch = (e) => {
    e.preventDefault();
    OTP_CRUD(searchFields);
  };

  const updateCRUD_OTP = (payload) => {
    Setup_CRUD_OTP(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              setToggleForm(false)
              setToggleFormEmail(false);
              setToggleFormCnic(false)

              OTP_CRUD(initialSearchFields);
            } else if (results.isConfirmed) {
              setToggleForm(false)
              setToggleFormEmail(false);
              setToggleFormCnic(false)

              OTP_CRUD(initialSearchFields);
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };
  
  const onOTP = (item) => {
    const payload = {
      OperationID: 1,
      PageNumber: pageNo,
      RowsOfPage: 10,
      AdmRegID: item.ADMRegID,
      Nic_FromB: item.NIC_FormB,
      Name: item.CandidateName,
      ProgramId: undergraduateId,
      EmailAddress: item.Email,
      Password: "",
    };

    updateCRUD_OTP(payload);
  };

  const onResetPassword = (item) => {
    setResetFormFields({
      ...resetFormFields,
      OperationID: 3,
      PageNumber: pageNo,
      RowsOfPage: 10,
      AdmRegID: item.ADMRegID,
      Nic_FromB: item.NIC_FormB,
      Name: item.CandidateName,
      ProgramId: undergraduateId,
      EmailAddress: item.Email,
      Password: item.Password,
    });
    setToggleForm(true);
  };

  // after submit reset password 
const onResetPasswordSubmit = (e) => {
  e.preventDefault()
  updateCRUD_OTP(resetFormFields)
}


  const onChangeEmail = (item) => {
    setEmailFormFields({
      ...emailFormFields,
      OperationID: 2,
      PageNumber: pageNo,
      RowsOfPage: 10,
      AdmRegID: item.ADMRegID,
      Nic_FromB: item.NIC_FormB,
      Name: item.CandidateName,
      ProgramId: undergraduateId,
      EmailAddress: item.Email,
      Password: item.Password,
    });
    setToggleFormEmail(true);
  };
  const onChangeEmailSubmit = (e) => {
    e.preventDefault()
    updateCRUD_OTP(emailFormFields)
  }
  const onChangeCnic = (item) => {
    setCnicFormFields({
      ...cnicFormFields,
      OperationID: 5,
      PageNumber: pageNo,
      RowsOfPage: 10,
      AdmRegID: item.ADMRegID,
      Nic_FromB: item.NIC_FormB,
      Name: item.CandidateName,
      ProgramId: undergraduateId,
      EmailAddress: item.Email,
      Password: item.Password,
    });
    setToggleFormCnic(true);
  
  };
  const onCnicSubmit = (e) => {
    e.preventDefault()
    updateCRUD_OTP(cnicFormFields)
  }
  const onDeleteAc = (item) => {
    const payload = {
      OperationID: 4,
      PageNumber: pageNo,
      AdmRegID: item.ADMRegID,
      Nic_FromB: item.NIC_FormB,
      Name: item.CandidateName,
      ProgramId: undergraduateId,
      EmailAddress: item.Email,
      Password: item.Password,
    };
    Swal.fire({
      text:
        'Are you sure you want to Delete Candidate "' +
        item?.CandidateName +
        '"?',
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#24344e",
    }).then((result) => {
      if (result.isConfirmed) {
        updateCRUD_OTP(payload);
      }
    });
  };

  const handleResetPassword = (e) => {
    setResetFormFields({
      ...resetFormFields,
      [e.target.name]: e.target.value
    })
  }
  const handleEmailChange = (e) => {
    setEmailFormFields({
      ...emailFormFields,
      [e.target.name]: e.target.value
    })
  }
  const handleCnicChange = (e) => {
    setCnicFormFields({
      ...cnicFormFields,
      [e.target.name]: e.target.value
    })
  }
  const handleFormCancel = () => {
    setToggleForm(false);
  };
  const handleCnicCancel = () => {
    setToggleFormCnic(false);
  };
  const handleEmailCancel = () => {
    setToggleFormEmail(false);
  };



  const onCancel = () => {
    OTP_CRUD(initialSearchFields);
    setSearchFields(initialSearchFields);
  };


  const handlePagination = (type) => {
    if (type == "Previous") {
      setPageNo(pageNo - 1);
      searchFields.PageNumber = pageNo - 1;
      OTP_CRUD(searchFields);
    } else if (type == "Next") {
      setPageNo(pageNo + 1);
      searchFields.PageNumber = pageNo + 1;
      OTP_CRUD(searchFields);
    }
  };

  return (
    <Container fluid>
      <Card className="mt-3">
        <form onSubmit={onSearch}>
          <CardTitle>Reset Password</CardTitle>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroupInput
                  name="Name"
                  label="Name"
                  onChange={handleInputChange}
                  value={searchFields.Name}
                />
              </Col>
              <Col md="3">
                <FormGroupInput
                  name="EmailAddress"
                  label="Email Address"
                  onChange={handleInputChange}
                  value={searchFields.EmailAddress}
                />
              </Col>
              <Col md="3">
                <FormGroupInput
                  name="Nic_FromB"
                  label="CNIC"
                  onChange={handleInputChange}
                  value={searchFields.Nic_FromB}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Search
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              Reset Password List
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table
            className="adm-app-table"
            bordered
            striped
            responsive
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {TableList?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td>{item.ADMRegID}</td>
                    <td>{item.CandidateName}</td>
                    <td>{item.Email}</td>
                    <td>******</td>
                    <td>{item.NIC_FormB}</td>
                    {/* <td>{item.OTPL}</td>
                    <td>{item.VerificationOTP}</td> */}

                    <td style={{ width: "160px" }}>
                      {/* <Button
                        style={{ lineHeight: "1" }}
                        color="success"
                        className="btnic"
                        size="sm"
                        onClick={() => onOTP(item)}
                        id="confirm"
                      >
                        <i className="fa fa-check-circle-o"> </i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={confirmToolTipOpen}
                        target="confirm"
                        toggle={() => toggle("confirm")}
                      >
                        Resend OTP
                      </Tooltip> */}
                      <Button
                        style={{ lineHeight: "1" }}
                        color="primary"
                        className="btnic"
                        size="sm"
                        onClick={() => onResetPassword(item)}
                        id="revert"
                      >
                        <i className="fa fa-lock"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={revertToolTipOpen}
                        target="revert"
                        toggle={() => toggle("revert")}
                      >
                        Reset Password
                      </Tooltip>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="secondary"
                        className="btnic"
                        size="sm"
                        onClick={() => onChangeEmail(item)}
                        id="move"
                      >
                        <i className="fa fa-envelope-o"> </i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={moveToolTipOpen}
                        target="move"
                        toggle={() => toggle("move")}
                      >
                        Change Email
                      </Tooltip>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="danger"
                        className="btnic"
                        size="sm"
                        onClick={() => onChangeCnic(item)}
                        id="reject"
                      >
                        <i className="fa fa-address-book-o"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={rejectToolTipOpen}
                        target="reject"
                        toggle={() => toggle("reject")}
                      >
                        Change CNIC
                      </Tooltip>
                      {/* <Button
                        style={{ lineHeight: "1" }}
                        color="danger"
                        className="btnic"
                        size="sm"
                        onClick={() => onDeleteAc(item)}
                        id="trash"
                      >
                        <i className="fa fa-trash-o"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={trashToolTipOpen}
                        target="trash"
                        toggle={() => toggle("trash")}
                      >
                        Delete A/C
                      </Tooltip> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <Button
                color="default"
                disabled={
                  SupportingTables?.otprecords?.Table1?.[0]?.Min_ == 0
                    ? true
                    : false
                }
                onClick={(e) => handlePagination("Previous")}
              >
                Previous
              </Button>
              <Button color="default" onClick={(e) => handlePagination("Next")}>
                Next
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <p style={{ fontWeight: "bold", margin: 10 }}>
                Showing {SupportingTables?.otprecords?.Table1?.[0]?.Min_} to{" "}
                {SupportingTables?.otprecords?.Table1?.[0]?.Max_}
              </p>
            </Col>
          </Row>
          <Modal
            isOpen={toggleForm}
            centered
          >
            <ModalHeader
            // toggle={toggle}
            >
              Reset Password
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onResetPasswordSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroupInput 
                    name="Password"
                    label="New Password"
                    value={resetFormFields.Password}
                    onChange={handleResetPassword}
                    required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                  <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button color="default" type="button" onClick={handleFormCancel}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </form>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={toggleFormEmail}
            centered
          >
            <ModalHeader
            // toggle={toggle}
            >
              Change Email
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onChangeEmailSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroupInput 
                    type="email"
                    name="EmailAddress"
                    label="Email"
                    value={emailFormFields.EmailAddress}
                    onChange={handleEmailChange}
                    required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                  <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button color="default" type="button" onClick={handleEmailCancel}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </form>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={toggleFormCnic}
            centered
          >
            <ModalHeader
            // toggle={toggle}
            >
              Change CNIC
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onCnicSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroupInput
                    isNumber="true"
                    name="Nic_FromB"
                    label="Nic_FromB"
                    value={cnicFormFields.Nic_FromB}
                    onChange={handleCnicChange}
                    required
                    maxLength="13"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                  <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button color="default" type="button" onClick={handleCnicCancel}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ResetPassword;
