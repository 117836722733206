import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  AdmFacultyDepartmentID,
  AdmFacultyDepartmentProgramID,
  admissionProgramId,
  campusType,
  Delete,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  SessionStorage,
  undergraduateId,
} from "../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteWithConfirmation,
} from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../EncryptData";
import useSetupDetailList from "../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  TOGGLE_CRUD_FORM_TABLE_LOADING,
} from "../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../utils/Config";
import { UserNetworkInfo } from "../../utils/EncryptedConstants";
import {
  ENROLLMENTOPS,
  SETUP_CANDIDATE_ACTIONS_CONTROL,
} from "../../utils/UrlConstants";

const CandidateAccessControl = () => {
  const initialSearchFields = {
    CandidateScreenAccessControlID: 0,
    ProgramID: undergraduateId,
    CampusID: 0,
    DepartmentID: 0,
    DepartmentProgramID: 0,
    CollageD: 0,
    CollageDepartmentProgramID: 0,
    GroupID: 0,
    PartID: 0,
    CandidateLogin: false,
    PrintAdmitCard: false,
    PrintEnrollmentForm: false,
    PrintEligibilityFrom: false,
    PrintMigrationForm: false,
    PrintOfferLetter: false,
    Reshuffle: false,
    IsActive: 1,
    IsDeleted: 0,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialFormFields = {
    CandidateScreenAccessControlID: 0,
    ProgramID: undergraduateId,
    CampusID: 0,
    DepartmentID: 0,
    DepartmentProgramID: 0,
    CollageD: 0,
    CollageDepartmentProgramID: 0,
    GroupID: 0,
    PartID: 0,
    CandidateLogin: false,
    SlipButton: false,
    PrintAdmissionVoucher: false,
    PrintAdmitCard: false,
    PrintEnrollmentForm: false,
    PrintAdmissionForm: false,
    PrintEligibilityFrom: false,
    PrintMigrationForm: false,
    PrintOfferLetter: false,
    Reshuffle: false,
    IsActive: 1,
    IsDeleted: 0,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const {
    SearchFields,
    FormFields,
    TableList,
    TableLoading,
    SupportingTables,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [campusList] = useSetupDetailList(campusType);
  const [DepartmentList] = useSetupDetailList(facultyDepartmentId);
  const [DepartmentProgramsList] = useSetupDetailList(
    facultyDepartmentProgramId
  );

  const dispatch = useDispatch();
  const [enableButtons, setEnableButtons] = useState(true);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    // getSectionsList();
    getCandidateAccess();
  }, []);

  function getCandidateAccess() {
    dispatch({
      type: TOGGLE_CRUD_FORM_TABLE_LOADING,
      payload: {
        TableLoading: true,
      },
    });
    const payload = {
      OperationId: 0,
      SetupCandidateActionsControl: {
        ...initialSearchFields,
      },
    };
    PostRequest(SETUP_CANDIDATE_ACTIONS_CONTROL, payload)
      .then((res) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        console.error(err);
      });
  }
  const columns = [
    { field: "Program", name: "Program" },
    { field: "Campus", name: "Campus" },
    { field: "Department", name: "Department" },
    { field: "DepartmentProgram", name: "Department Program" },
    { field: "CollageD1", name: "College" },
    { field: "CollageDepartmentProgram", name: "College Program" },
    { field: "Group_", name: "Group" },
    { field: "Part", name: "Part" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Campus"
          name="CampusID"
          onChange={handleSearchChange}
          value={SearchFields?.CampusID}
          list={campusList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    dispatch({
      type: TOGGLE_CRUD_FORM_TABLE_LOADING,
      payload: {
        TableLoading: true,
      },
    });
    const payload = {
      OperationId: 0,
      SetupCandidateActionsControl: {
        ...initialSearchFields,
        ...SearchFields,
      },
    };

    PostRequest(SETUP_CANDIDATE_ACTIONS_CONTROL, payload)
      .then((res) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      OperationId: FormFields?.CandidateScreenAccessControlID > 0 ? 2 : 1,
      SetupCandidateActionsControl: {
        ...initialFormFields,
        ...FormFields,
      },
    };

    PostRequest(SETUP_CANDIDATE_ACTIONS_CONTROL, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
          getCandidateAccess();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    let data = {
      ...FormFields,
      ProgramID: obj?.ProgramID,
      CampusID: obj?.CampusID,
      DepartmentID: obj?.DepartmentID,
      DepartmentProgramID: obj?.DepartmentProgramID,
      CollageD: obj?.CollageD,
      CollageDepartmentProgramID: obj?.CollageDepartmentProgramID,
      GroupID: obj?.GroupID,
      PartID: obj?.PartID,
      CandidateLogin: obj?.CandidateLogin,
      PrintAdmitCard: obj?.PrintAdmitCard,
      PrintEnrollmentForm: obj?.PrintEnrollmentForm,
      PrintEligibilityFrom: obj?.PrintEligibilityFrom,
      PrintMigrationForm: obj?.PrintMigrationForm,
      PrintOfferLetter: obj?.PrintOfferLetter,
      Reshuffle: obj?.Reshuffle,
      CandidateScreenAccessControlID: obj?.CandidateScreenAccessControlID,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        const payload = {
          OperationId: Delete,
          SetupCandidateActionsControl: {
            ...initialFormFields,
            CandidateScreenAccessControlID: obj?.CandidateScreenAccessControlID,
            IsDeleted: 1,
          },
        };

        PostRequest(SETUP_CANDIDATE_ACTIONS_CONTROL, payload)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
              getCandidateAccess();
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getCandidateAccess();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Campus"
          name="CampusID"
          onChange={handleAddChange}
          value={FormFields?.CampusID}
          list={campusList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>

      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="DepartmentID"
          onChange={handleAddChange}
          value={FormFields?.DepartmentID}
          list={DepartmentList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="DepartmentProgramID"
          onChange={handleAddChange}
          value={FormFields?.DepartmentProgramID}
          list={DepartmentProgramsList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={FormFields?.DepartmentID == null ? true : false}
        />
      </Col>
      <hr className="mt-4" />
      <Col xs="12" lg="12" md="12">
        <Row>
          <Col lg="4" md="4" xs="12">
            <FormGroupCheckbox
              label={
                FormFields?.CandidateLogin
                  ? "Candidate Login Closed"
                  : "Candidate Login Open"
              }
              name="CandidateLogin"
              onChange={handleAddChange}
              value={FormFields?.CandidateLogin}
            />
          </Col>
          <Col lg="4" md="4" xs="12">
            <FormGroupCheckbox
              label="Print Admit Card"
              name="PrintAdmitCard"
              onChange={handleAddChange}
              value={FormFields?.PrintAdmitCard}
            />
          </Col>
          <Col lg="4" md="4" xs="12">
            <FormGroupCheckbox
              label="Print Offer Letter"
              name="PrintOfferLetter"
              onChange={handleAddChange}
              value={FormFields?.PrintOfferLetter}
            />
          </Col>
          <Col lg="4" md="4" xs="12">
            <FormGroupCheckbox
              label="Reshuffle"
              name="Reshuffle"
              onChange={handleAddChange}
              value={FormFields?.Reshuffle}
            />
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
  // const customTable = (
  //   <Fragment>
  //     {TableLoading ? (
  //       <LoadingPageInner />
  //     ) : (
  //       <Table bordered striped responsive style={{ width: "100%" }}>
  //         <thead>
  //           <tr>
  //             <th>S.No.</th>
  //             {columns &&
  //               columns.map((column, index) => (
  //                 <th
  //                   // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
  //                   key={index}
  //                 >
  //                   {column.name}
  //                 </th>
  //               ))}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {TableList &&
  //             TableList?.map((item, ind) => (
  //               <tr key={ind}>
  //                 <td>{ind + 1}</td>
  //                 <td>{item.Program}</td>
  //                 <td>{item.Campus}</td>
  //                 <td>{item.Department}</td>
  //                 <td>{item.DepartmentProgram}</td>
  //                 <td>{item.CollageD1}</td>
  //                 <td>{item.CollageDepartmentProgram}</td>
  //                 <td>{item.Group_}</td>
  //                 <td>{item.Part}</td>
  //               </tr>
  //             ))}
  //         </tbody>
  //       </Table>
  //     )}
  //   </Fragment>
  // );
  // const customButton = (
  //   <Fragment>
  //     <Button color="primary" onClick={handleModalSave}>
  //       Process Enrollment
  //     </Button>
  //   </Fragment>
  // );
  return (
    <CrudFormComponent
      formName="Candidate Access Control"
      buttonName="Add"
      modalSize="lg"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      onEdit={onEditRow}

      // formLoader={formLoader}
      // customModal={customModal}
      // customButton={customButton}
    />
  );
};

export default CandidateAccessControl;
