import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Spinner,
} from "reactstrap";

import Academics from "./PostGraduateTabs/Academics";
import Documents from "./PostGraduateTabs/Documents";
import PersonalInfo from "./PostGraduateTabs/PersonalInfo";
import Research from "./PostGraduateTabs/Research";
import Undertaking from "./PostGraduateTabs/Undertaking";
import Approval from "./PostGraduateTabs/Approval";
import EligibiltyCertificate from "./PostGraduateTabs/EligibiltyCertificate";
import MigrationCertificate from "./PostGraduateTabs/MigrationCertificate";
import CheckStatus from "./PostGraduateTabs/CheckStatus";
import GATGeneral from "./PostGraduateTabs/GATGeneral";
import Employment from "./PostGraduateTabs/Employment";
import OfficeUseOnly from "./PostGraduateTabs/OfficeUseOnly";


const PostGraduate = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <Fragment>
      <Nav pills>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            Step 1: Personal Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            Step 2: GAT General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            Step 3: Academics
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={activeTab === "4" ? "active" : ""}
            onClick={() => setActiveTab("4")}
          >
            Research
          </NavLink>
        </NavItem> */}
     
        <NavItem>
          <NavLink
            className={activeTab === "4" ? "active" : ""}
            onClick={() => setActiveTab("4")}
          >
            Step 4: Employment
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "5" ? "active" : ""}
            onClick={() => setActiveTab("5")}
          >
            Step 5: Documents
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "6" ? "active" : ""}
            onClick={() => setActiveTab("6")}
          >
            Step 6: Undertaking
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "7" ? "active" : ""}
            onClick={() => setActiveTab("7")}
          >
            For Office Use Only
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "8" ? "active" : ""}
            onClick={() => setActiveTab("8")}
          >
            Status
          </NavLink>
        </NavItem>
        
      </Nav>
      <TabContent className="tab-content-inner" activeTab={activeTab}>
        <TabPane tabId="1">
          <PersonalInfo
            {...props}
          />
        </TabPane>
        <TabPane tabId="2">
          <GATGeneral
            {...props}
          />
        </TabPane>
        <TabPane tabId="3">
          <Academics
            {...props}
          />
        </TabPane>
        {/* <TabPane tabId="4">
          <Research
            {...props}
          />
        </TabPane> */}
       
        <TabPane tabId="4">
          <Employment
            {...props}
          />
        </TabPane>
        <TabPane tabId="5">
          <Documents
            {...props}
          />
        </TabPane>
        <TabPane tabId="6">
          <Undertaking
            {...props}
          />
        </TabPane>
        <TabPane tabId="7">
          <OfficeUseOnly
            {...props}
          />
        </TabPane>
        <TabPane tabId="8">
          <CheckStatus
            {...props}
          />
        </TabPane>
        
      </TabContent>
    </Fragment>
  );
};
export default PostGraduate;
