import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Insert,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import {
  RESET_FORM_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest, Setup_Setup_FIN_COA } from "../../../utils/Config";
import Account from "./ChartOfAccountTabs/Account";
import CurrentYear from "./ChartOfAccountTabs/CurrentYear";
import { CHARTOFACCOUNT } from "../../../utils/UrlConstants";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";

const initialSearchFields = {
  OperationID: Select,
  CoaID: 0,
  AccNatureID: 0,
  AccCode: "",
  AccName: "",
  ParentCoaID: 0,
  AccNature: "",
  AccTypeID: 0,
  Remarks: "",
  FyID: 0,
  IsActive: true,
  UserID: decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const initialFormFields = {
  OperationID: Select,
  CoaID: 0,
  AccNatureID: 0,
  AccCode: "",
  AccName: "",
  ParentCoaID: 0,
  AccTypeID: 0,
  Remarks: "",
  AccNature: "",
  FyID: 0,
  IsActive: true,
  UserID: decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const ChartOfAccounts = (props) => {
  const { SearchFields, FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [toggle, setToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [operationId, setOperationId] = useState(2);

  const [selectedAccountNature, setSelectedAccountNature] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
    getChartOfAccounts();
  }, []);

  const getChartOfAccounts = () => {
    PostRequest(CHARTOFACCOUNT, initialSearchFields)
      .then((res) => {
        let accountTypeName = {
          name: "accountTypeName",
          value: res?.data?.Table1,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: accountTypeName,
        });
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table.map((x) => ({
              ...x,
              IsActive: x.IsActive ? "Active" : "InActive",
            })),
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "AccID", name: "A/C #" },
    { field: "AccCode", name: "Account Code" },
    { field: "AccName", name: "Account Name" },
    { field: "ParentCoaID", name: "Parent A/C #" },
    { field: "CoaID", name: "Code" },
    { field: "AccNature", name: "Nature" },
    { field: "IsActive", name: "Is Active" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const onEditRow = (obj) => {
    let bindDropDownAccname = { value: obj.AccNatureID, label: obj.AccNature };
    setSelectedAccountNature(bindDropDownAccname);
    setOperationId(3);
    let data = {
      CoaID: obj?.CoaID,
      AccNatureID: obj?.AccNatureID,
      AccCode: obj?.AccCode,
      AccName: obj?.AccName,
      ParentCoaID: obj?.ParentCoaID,
      AccTypeID: obj?.AccTypeID,
      AccType: obj?.AccType,
      Remarks: obj.Remarks,
      IsActive: obj?.IsActive === "Active" ? true : false,
      UserIP: obj?.UserIP,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
    setToggle(true);
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.COADropdown?.Table}
          label="Head of Account"
          name="HeadId"
          fieldId="HeadId"
          fieldName="Head"
          onChange={handleSearchChange}
          value={SearchFields?.HeadId}
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.COADropdown?.Table1?.filter(
            (x) => x.HeadId == SearchFields?.HeadId
          )}
          label="Account"
          name="CoaId"
          fieldId="CoaID"
          fieldName="ACCOUNTS"
          onChange={handleSearchChange}
          value={SearchFields?.CoaId}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupCheckbox
          label=" Is Active"
          name="IsActive"
          value={SearchFields?.IsActive}
          onChange={handleSearchChange}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    Setup_Setup_FIN_COA(SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table.map((x) => ({
              ...x,
              IsActive: x.IsActive ? "Active" : "InActive",
            })),
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
    getChartOfAccounts();
  };

  const onDeleteRow = (obj) => {};

  const submitForm = () => {
    const payload = {
      ...FormFields,
      OperationID: operationId,
      CoaID: operationId === 2 ? 0 : FormFields?.CoaID,
      FyID: 0,
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(CHARTOFACCOUNT, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
          CustomSuccessAlert(res?.data?.Table?.[0]?.MESSAGE);
          setToggle(false);
          setSelectedAccountNature(null);
          getChartOfAccounts();
          setOperationId(2);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.MESSAGE);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClickButton = () => {
    setToggle(true);
  };

  const handleFormCancel = () => {
    setToggle(false);
    dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
    setOperationId(2);
    setSelectedAccountNature(null);
  };

  const customModal = (
    <Modal
      isOpen={toggle}
      centered
      style={{ maxWidth: "1500px", width: "100%" }}
      modalTransition={{ timeout: 10 }}
      backdrop="static"
    >
      <ModalHeader>Chart of Account</ModalHeader>
      <ModalBody
        style={{ minHeight: "600px", maxHeight: "600px", overflowY: "scroll" }}
      >
        <Row>
          <Fragment>
            <Nav pills>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                >
                  Account
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                >
                  Current Year
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              style={{ backgroundColor: "white" }}
              className="tab-content-inner"
              activeTab={activeTab}
            >
              <TabPane style={{ marginTop: 10 }} tabId="1">
                <Account
                  selectedAccountNature={selectedAccountNature}
                  setSelectedAccountNature={setSelectedAccountNature}
                />
              </TabPane>
              <TabPane style={{ marginTop: 10 }} tabId="2">
                <CurrentYear {...props} />
              </TabPane>
            </TabContent>
          </Fragment>
        </Row>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button color="primary" onClick={submitForm}>
            Save
          </Button>
          <Button color="default" onClick={handleFormCancel}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );

  const customButton = (
    <div>
      <Button color="secondary" className="btn" onClick={handleClickButton}>
        Add
      </Button>
    </div>
  );

  return (
    <CrudFormComponent
      formName="Chart of Accounts"
      customButton={customButton}
      tableColumns={columns}
      tableRows={TableList}
      customModal={customModal}
      searchPanel={searchPanel}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default ChartOfAccounts;
