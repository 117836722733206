import {
  intermediateExamId,
  matriculationExamId,
} from "../common/AdmissionEnum";

export const checkUGAcademicsList = (data) => {
  let cond = false;

  if (data.some((x) => x.ExaminationID === matriculationExamId)) {
    if (data.some((x) => x.ExaminationID === intermediateExamId)) {
      cond = true;
    } else {
      cond = false;
    }
  } else {
    cond = false;
  }

  return cond;
};
