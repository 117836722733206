import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, CardTitle, Table } from "reactstrap";
import {
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  CustomWarningMessage,
  WarningWithConfirmation,
} from "../../../../components/Alert";
import AcademicFormComponent from "../../../../components/FormComponents/AcademicFormComponent";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import { decryptData } from "../../../../EncryptData";
import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../../utils/Config";
import {
  CURRENT_SEMESTER,
  CURRENT_SEMESTER_FACULTY_OPS,
  CURRENT_SEMESTER_HOD_OPS,
} from "../../../../utils/UrlConstants";
import Spinner from "react-spinkit";

const HODFacultyExamRecap = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const [formLoad, setFormLoad] = useState(true);
  const [lockField, setLockField] = useState(false);

  const [ischeckByLeck, setIscheckByLeck] = useState();

  const timeoutRef = useRef(null);

  const { id1, id2 } = params;

  const initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    EnrollmentID: 0,
    HodRemarks: "string",
    IsLockByLecturer: true,
    IsLockByHod: true,
  };

  const [
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_HODOps_TBL_,
  ] = useState(initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_);

  const intialFormFields = {
    OperationId: Select,
    ParameterID: Update,
    AcademicYearID: 0,
    SemesterID: 0,
    FacultyDepartmentID: 0,
    FacultyLecturerID: 0,
    CourseSemesterMappingID: 0,
    SetupFacultyDepartmentProgramLecturerID: 0,
    CourseSemesterLecturerMappingId: 0,
    UserID: decryptData("loginId", SessionStorage),
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_: [
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    ],
  };

  useEffect(() => {
    getHODFacultyExamRecap();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getHODFacultyExamRecap = () => {
    const payload = {
      ...intialFormFields,
      CourseSemesterLecturerMappingId: id1,
      CourseSemesterMappingID: id2,
    };
    PostRequest(CURRENT_SEMESTER_HOD_OPS, payload)
      .then((res) => {
        //   debugger
        //   let checkLock = res.data.Table.map((x) => ({
        //     ...x,
        //     IsLockByLecturer :x.IsLockByLecturer
        //   }))
        //  ischeckByLeck(checkLock);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: intialFormFields,
          },
        });
        setEnrolledStudentAcademicRecordDetail_HODOps_TBL_([
          ...res?.data?.Table.map((x) => ({
            EnrolledStudentAcademicRecordDetailID:
              x.EnrolledStudentAcademicRecordDetailID,
            EnrollmentID: x.EnrollmentID,
            HodRemarks: x.HODRemarks,
            IsLockByLecturer: x.IsLockByLecturer,
            IsLockByHod: x.IsLockByHod,
          })),
        ]);
        const payload = res?.data?.Table?.filter((x) => x.IsLockByHod);
        if (payload.length == res?.data?.Table?.length) {
          setLockField(true);
        }
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Name", name: "Name" },
    { field: "MidTermExamMarks", name: "Mid Term Exam Marks" },
    { field: "CPMarks", name: "CP Marks" },
    { field: "FinalExamMarks", name: "Final Exam Marks" },
    { field: "HODRemarks", name: "HOD Remarks" },
    // { field: "IsSaved", name: "Saved" },
    { field: "IsLockByLecturer", name: "Is Lock By Lecturer" },
    // { field: "IsLockByHod", name: "Is Lock By Hod" },
  ];

  const handleInputChange = (e, index, name, type) => {
    e.preventDefault();
    if (type == "Lock") {
      TableList[index][name] = true;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_[index][name] = true;
    } else if (type == "Unlock") {
      if (name === "IsLockByLecturer") {
        WarningWithConfirmation(
          `Are you sure you want to unlock for changes?`
        ).then((result) => {
          if (result.isConfirmed) {
            setLockField(false);

            TableList[index][name] = false;
            dispatch({
              type: SET_INITIAL_CRUD_FORM_STATE,
              payload: {
                List: TableList,
                FormFields: FormFields,
              },
            });
            EnrolledStudentAcademicRecordDetail_HODOps_TBL_[index][
              name
            ] = false;
          }
        });
      } else {
        TableList[index][name] = false;
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: TableList,
            FormFields: FormFields,
          },
        });
        EnrolledStudentAcademicRecordDetail_HODOps_TBL_[index][name] = false;
      }
    } else if (name === "HODRemarks") {
      TableList[index][e.target.name] = e.target.value;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_[index]["HodRemarks"] =
        e.target.value;
    } else {
      TableList[index][e.target.name] = e.target.value;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_[index][e.target.name] =
        e.target.value;
    }
  };

  const handleHodChange = (e, type) => {
    let abc = [...TableList];

    e.preventDefault();
    if (type === "Lock") {
      const payload = abc?.filter((x) => x.IsLockByLecturer == true);
      if (payload.length === abc.length) {
        setLockField(true);
        const data = TableList.map((x) => ({ ...x, IsLockByHod: true }));
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: data,
            FormFields: FormFields,
          },
        });
      } else {
        CustomWarningMessage(
          "Marks of all students should be locked by faculty first"
        );
      }
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    } else {
      const payload = TableList.map((x) => ({ ...x, IsLockByHod: false }));
      setLockField(false);
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: payload,
          FormFields: FormFields,
        },
      });
    }
  };

  const onSave = () => {
    const payload = {
      ...FormFields,
      OperationId: Insert,
      ParameterID: Update,
      CourseSemesterLecturerMappingId: id1,
      CourseSemesterMappingID: id2,
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_:
        EnrolledStudentAcademicRecordDetail_HODOps_TBL_?.map((x) => ({
          ...x,
          IsLockByHod: lockField ? true : x.IsLockByHod,
        })),
    };

    WarningWithConfirmation(
      `Are you sure you want to submit exam details?`
    ).then((result) => {
      if (result.isConfirmed) {
        PostRequest(CURRENT_SEMESTER_HOD_OPS, payload)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const defaultFunction = (e, name) => {
    e.preventDefault();
    if (name === "Faculty") {
      CustomWarningMessage("Unable to lock. Can only be locked by Faculty.");
    } else {
      CustomWarningMessage(
        "You are not allowed to lock until Faculty has locked the marks."
      );
    }
  };

  const customTable = (
    <Fragment>
      <Table>
        <tr>
          <th className="text-right">
            LOCK BY HOD
            <a
              href="#"
              onClick={(e) => handleHodChange(e, lockField ? "Unlock" : "Lock")}
            >
              <i
                style={{ fontSize: "25px" }}
                className={
                  lockField
                    ? "fa fa-lock text-danger"
                    : "fa fa-unlock text-success"
                }
              ></i>
            </a>
          </th>
        </tr>
        <td className="text-right"></td>
      </Table>

      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="text-center">S.No.</th>
              {columns &&
                columns?.map((column, index) => (
                  <th
                    style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    key={index}
                    className="text-center"
                  >
                    {column.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr></tr>

            {TableList?.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{item.Name}</td>
                {item.IsLockByLecturer === false ? (
                  <td>
                    <FormGroupInput
                      name="MidTermExamMarks"
                      value={item?.MidTermExamMarks}
                      isNumber="true"
                      maxLength={3}
                      disabled
                      // onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                ) : (
                  <td>
                    <FormGroupInput
                      name="MidTermExamMarks"
                      value={
                        item?.MidTermExamMarks == ""
                          ? "0"
                          : item?.MidTermExamMarks
                      }
                      isNumber="true"
                      maxLength={3}
                      disabled
                      // onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                )}
                {item.IsLockByLecturer === false ? (
                  <td>
                    <FormGroupInput
                      name="CPMarks"
                      isNumber="true"
                      maxLength={3}
                      disabled
                      // onChange={(e) => handleInputChange(e, index)}
                      value={item.CPMarks}
                    />
                  </td>
                ) : (
                  <td>
                    <FormGroupInput
                      name="CPMarks"
                      isNumber="true"
                      maxLength={3}
                      disabled
                      // onChange={(e) => handleInputChange(e, index)}
                      value={item.CPMarks == "" ? "0" : item?.CPMarks}
                    />
                  </td>
                )}
                {item.IsLockByLecturer === false ? (
                  <td>
                    <FormGroupInput
                      name="FinalExamMarks"
                      isNumber="true"
                      maxLength={3}
                      // onChange={(e) => handleInputChange(e, index)}
                      disabled
                      value={item.FinalExamMarks}
                    />
                  </td>
                ) : (
                  <td>
                    <FormGroupInput
                      name="FinalExamMarks"
                      isNumber="true"
                      maxLength={3}
                      // onChange={(e) => handleInputChange(e, index)}
                      disabled
                      value={
                        item.FinalExamMarks == "" ? "0" : item?.FinalExamMarks
                      }
                    />
                  </td>
                )}
                <td>
                  <FormGroupInput
                    name="HODRemarks"
                    isAlphabetic="true"
                    onChange={(e) => handleInputChange(e, index, "HODRemarks")}
                    value={item.HODRemarks}
                    disabled={!item?.IsLockByHod ? false : true}
                  />
                </td>

                <td className="text-center">
                  {" "}
                  <a
                    href="#"
                    onClick={
                      item.IsLockByLecturer
                        ? (e) =>
                            handleInputChange(
                              e,
                              index,
                              "IsLockByLecturer",
                              "Unlock"
                            )
                        : (e) => defaultFunction(e, "Faculty")
                    }
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className={
                        item.IsLockByLecturer
                          ? "fa fa-lock text-danger"
                          : "fa fa-unlock text-success"
                      }
                    ></i>
                  </a>
                </td>

                {/* <td className="text-center">
                  <a
                    href="#"
                    onClick={
                      item.IsLockByLecturer
                        ? (e) =>
                            handleInputChange(
                              e,
                              index,
                              "IsLockByHod",
                              item.IsLockByHod ? "Unlock" : "Lock"
                            )
                        : (e) => defaultFunction(e, "Hod")
                    }
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className={
                        item.IsLockByHod
                          ? "fa fa-lock text-danger"
                          : "fa fa-unlock text-success"
                      }
                    ></i>
                  </a>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Student Course Marks"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      customTable={customTable}
      onClickSave={onSave}
    />
  );
};

export default HODFacultyExamRecap;
