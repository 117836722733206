import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import { useSelector, useDispatch } from "react-redux";
import { facultyDepartmentId, Search, SessionStorage } from "../../../common/SetupMasterEnum";
import Swal from "sweetalert2";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
  ADM_EligibilityCriteriaDependency,
  PostRequest,
} from "../../../utils/Config";
import { ADMISSION_USER_ID, REG_ID } from "../../../utils/EncryptedConstants";
import { decryptData } from "../../../EncryptData";
import { CustomErrorMessage } from "../../../components/Alert";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../../utils/UrlConstants";

const initialFormFields = {
  AdmDeptChoicePreferenceID: 0,
  AdmissionFacultyTypeId: 0,
  AdmissionFacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  ADMRegID: 0,
  DepartmentID: 0,
  UserIP: "192.168.168",
};
const Preference = (props) => {
  initialFormFields.ADMRegID = decryptData(REG_ID, SessionStorage);
  const dispatch = useDispatch();
  const [editStatus, setEditStatus] = useState(false);
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const preferenceRecords = SupportingTables?.students?.Table3;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const personalInfoData = SupportingTables?.students?.Table1[0];
  const statusData = SupportingTables?.students?.Table6;
  const departmentId =
    SupportingTables?.AdmissionFacultyDepartmentId == undefined
      ? 0
      : SupportingTables?.AdmissionFacultyDepartmentId;
  const [formFields, setFormFields] = useState(initialFormFields);
  const [preferenceData, setPreferenceData] = useState([]);
  const [updateButton, setUpdateButton] = useState(false);

  useEffect(() => {
    setPreferenceData(preferenceRecords);
  }, [preferenceRecords]);
  useEffect(() => {
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 2,
      paremeterID: personalInfoData?.MajorId,
    });
  }, [personalInfoData]);

  const handleInputChange = (event) => {
    if (event.target.name === "AdmissionFacultyDepartmentId") {
      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
        operationID: Search,
        caseID: 3,
        paremeterID: event.target.value,
      });
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
        [event.target.selectedName]:
          event.target.selectedName != undefined && event.target.selectedValue,
      });
    } else {
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
        [event.target.selectedName]:
          event.target.selectedName != undefined && event.target.selectedValue,
      });
    }
  };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };

  const onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency = (
    payload
  ) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID == 1) {
            let data = { name: "MajorIds", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID == 2) {
            let data = { name: "PrefFacultyDepartment", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else {
            let data = {
              name: "PrefFacultyDepartmentProgramId",
              value: res.data,
            };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const columns = [
    { field: "AdmissionFacultyDepartmentName", name: "Department Name" },
    { field: "FacultyDepartmentProgramName", name: "Program Name" },
  ];

  const onAdd = (e) => {
    e.preventDefault();
    setEditStatus(false);
    if (formFields.index > -1) {
      preferenceData[formFields.index] = formFields;
    } else if (
      preferenceData.some(
        (x) =>
          x.FacultyDepartmentProgramId === formFields.FacultyDepartmentProgramId
      )
    ) {
      Swal.fire({
        title: "Notification",
        icon: "warning",
        text: "Cannot Add Same Department Twice",
      });
    } else {
      if (preferenceData.length > 2) {
        Swal.fire({
          title: "Notification",
          icon: "warning",
          text: "Your 3 Choices have already been added",
        });
      } else {
        preferenceData.push({
          ...formFields,
          index: -1,
        });
      }
    }
    setPreferenceData([...preferenceData]);
    setFormFields({
      ...initialFormFields,
    });
  };
  const onEdit = (index, obj) => {
    setFormFields({
      ...obj,
      index: index,
    });
    setEditStatus(true);
  };
  const onDelete = (index) => {
    preferenceData.splice(index, 1);
    setPreferenceData([...preferenceData]);
  };
  const onCancel = () => {
    setFormFields({
      ...initialFormFields,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();
    setUpdateButton(true);
    if (statusData.length != 0) {
      if (statusData[0]?.PersonalInfo == 0 || statusData[0]?.Academics == 0) {
        CustomErrorMessage("Kindly Complete Admission Application Step Wise");
        setUpdateButton(false);
      } else {
        let formData = new FormData();
        Object.keys(basicValues).forEach((d) => {
          formData.append(d, basicValues[d]);
        });
        preferenceData.forEach((element, index) => {
          Object.keys(element).forEach((item) => {
            formData.append(
              `ADMDepartmentChoicePreference_[${index}].${item}`,
              element[item]
            );
          });
        });
        updateAdmission(formData);
      }
    } else {
      CustomErrorMessage("Kindly Complete Admission Application Step Wise");
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          getStudendRecords();
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        setUpdateButton(false);
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: 3479,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
          setUpdateButton(false);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setUpdateButton(false);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Preference Details</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>
              Kindly choose atleast three programs of your preference other than
              your Applied Program
            </strong>
          </div>
          <form onSubmit={onAdd}>
            <Row>
              {/* <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="DepartmentID"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentId &&
                      x.SetupDetailId != departmentId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.DepartmentID}
                  onChange={handleInputChange}
                  nameValue="DepartmentName"
                />
              </Col> */}
              {/* <Col md="3" lg="3">
                <FormGroupSelect
                  label="Admission Faculty Type"
                  name="AdmissionFacultyTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == 1135 &&
                      x.parentid == personalInfoData?.MajorId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.AdmissionFacultyTypeId}
                  onChange={handleInputChange}
                  nameValue="AdmissionFacultyTypeName"
                  required
                />
              </Col> */}

              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="AdmissionFacultyDepartmentId"
                  list={SupportingTables?.PrefFacultyDepartment?.Table}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.AdmissionFacultyDepartmentId}
                  onChange={handleInputChange}
                  nameValue="AdmissionFacultyDepartmentName"
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.PrefFacultyDepartmentProgramId?.Table?.filter((x) => x.SetupDetailId != personalInfoData?.FacultyDepartmentProgramId)}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.FacultyDepartmentProgramId}
                  onChange={handleInputChange}
                  nameValue="FacultyDepartmentProgramName"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    {editStatus === true ? "Edit" : "Add"}
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Preference Records</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={preferenceData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button
                  color="primary"
                  onClick={onUpdate}
                  disabled={
                    statusData[0]?.Status == 1
                      ? true
                      : updateButton === true
                      ? true
                      : false
                  }
                  // disabled={
                  //   statusData[0]?.Status == 1 ? true : false
                  // }
                  // disabled={statusData[0]?.Status == 1 ? true : false}
                >
                  {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Preference;
