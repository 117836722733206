import React, { useState, useEffect, Component } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import { dateFormat } from "../../utils/CommonMethods";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MySelect from "../../components/GeneralComponent/FormMultiSelect";
import { components } from "react-select";

const initialSelectValues = {
  announcementTitle: "",
};
const initialValues = {
  announcementTitle: "",
  announcementCategory: "",
  announcementDescription: "",
  announcementExpiry: "",
  email: [],
  announcementBody: "",
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: "5px" }}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const Announcement = () => {
  const animatedComponents = makeAnimated();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [selectValue, setSelectValue] = useState({
    ...initialSelectValues,
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const handleInputSearch = (event) => {
    setSelectValue({
      ...selectValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleMultiSelect = (selected) => {
    setFormFields({
      ...formFields,
      email: selected,
    });
  };
  const columns = [
    { field: "announcementTitle", name: "Announcement Title" },
    { field: "announcementCategory", name: "Announcement Category" },
    { field: "announcementDate", name: "Announcement Date" },
    { field: "announcementExpiry", name: "Announcement Expiry" },
    { field: "attachment", name: "Attachment" },
  ];
  const rows = [
    {
      announcementTitle: "Senior Farewall",
      announcementCategory: "Alert",
      announcementDate: "06/02/2022",
      announcementExpiry: "16/02/2022",
      attachment: "",
    },
    {
      announcementTitle: "Announcement",
      announcementCategory: "Event",
      announcementDate: "06/02/2022",
      announcementExpiry: "16/02/2022",
      attachment: "",
    },
  ];
  const announcExpiry = (event) => {
    setFormFields({
      ...formFields,
      announcementExpiry: event,
    });
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const announcements = [
    {
      id: "1",
      name: "Alert",
    },
    {
      id: "2",
      name: "Events",
    },
    {
      id: "3",
      name: "Job Posting",
    },
    {
      id: "4",
      name: "News",
    },
  ];
  const options = [
    { id: 1, value: 1, label: "Vice Chancellor | vc@salu.com" },
    { id: 2, value: 2, label: "Student | student@salu.com" },
    { id: 3, value: 3, label: "HOD | hod@salu.com" },
  ];
  return (
    <>
      <Container fluid>
        <Card className="rmv-hover mt-3">
          <CardTitle>Search</CardTitle>
          <CardBody>
            <Row>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Announcement Title"
                  name="announcementTitle"
                  onChange={handleInputSearch}
                  value={selectValue.announcementTitle}
                />
              </Col>
              <Col lg="12" md="12" xs="12" className="text-right">
                <Button color="primary" className="btn">
                  Search
                </Button>
                <Button className="btn" color="default">
                  Cancel
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardTitle>
            <Row>
              <Col lg="6" md="6" className="mt-1">
                Announcement Details
              </Col>
              <Col lg="6" md="6" className="text-right">
                <Button color="primary" className="btn" onClick={toggle}>
                  Create New Announcement
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <CardBody>
            <Row>
              <Col>
                <FormGroupTable
                  columns={columns}
                  rows={rows}
                  hideAction={true}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <Modal
        isOpen={modal}
        centered
        toggle={toggle}
        size="lg"
        modalTransition={{ timeout: 10 }}
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Add Announcement </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="6" md="6" xs="12">
              <FormGroupInput
                label="Announcement Title"
                name="announcementTitle"
                onChange={handleInputChange}
                value={formFields.announcementTitle}
              />
            </Col>
            <Col lg="3" md="6" xs="12">
              <FormGroupSelect
                label="Announcement Category"
                name="announcementCategory"
                onChange={handleInputChange}
                value={formFields.announcementCategory}
                list={announcements}
                fieldId="id"
                fieldName="name"
              />
            </Col>
            <Col lg="3" md="6" xs="12">
              <Label>Announcement Expiry</Label>
              <DatePicker
                selected={formFields.announcementExpiry}
                dateFormat={dateFormat}
                onChange={announcExpiry}
                className="form-control"
              />
            </Col>
            <Col lg="6" md="6" xs="12">
              <FormGroupInput
                type="textarea"
                name="announcementDescription"
                label="Announcement Description"
                onChange={handleInputChange}
                value={formFields.announcementDescription}
              />
            </Col>
            <Col lg="6" md="6" xs="12">
              <Label>Email</Label>
              <MySelect
                options={options}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleMultiSelect}
                allowSelectAll={true}
                value={formFields.email}
              />
            </Col>
            <Col lg="12" md="12" xs="12">
              <Label>Announcement Body</Label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary">Save Announcement</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Announcement;
