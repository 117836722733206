import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Card, CardBody, CardTitle, Col, Input, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { SessionStorage } from "../../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../../EncryptData";
import { Setup_UpdateEmployee } from "../../../../utils/Config";

const Benefits = (props) => {
  const {id} = useParams()
  const EmplId = id == 0
  ? decryptData("EmplId", SessionStorage)
  : decryptData("EmplGetId", SessionStorage);
const LoginId = decryptData("loginId", SessionStorage);
  const FirstName = props?.firstName
  const CNIC = props?.CNIC
  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    EmployeeBenefitsDetail_: [],
  };
  const employeeBenefitsDetail_ = {
    EmployeeId: EmplId,
    EmployeeBenefitMappingId: 0,
    Benefit: "",
    BenefitId: 0,
    // CreatedBy: 0,
    // ModifiedBy: LoginId,
    IsActive: 1,
    UserIP: "192.168.168",
    IsDeleted: 0,
    DocumentName: "",
    DocumentPath: {},
  };
  const formData = props?.data;
  const list = props?.list;
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const [benefitsFormFields, setBenefitsFormFields] = useState({
    ...employeeBenefitsDetail_,
  });
  const [benefitsData, setBenefitsData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const handleInputChange = (event) => {
    let obj = {
      ...benefitsFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setBenefitsFormFields(obj);

  };
  const handleBrowseChange = (event) => {
    setBenefitsFormFields({
      ...benefitsFormFields,
      DocumentPath: event.target.files[0],
      DocumentName: event.target.files[0].name,
      // FileType: event.target.files[0].type
    });
  };
  useEffect(() => {
    setBenefitsData(formData)
  }, [formData]);
  const columns = [{ field: "Benefit", name: "Benefit Name" }, { field: "DocumentName", name: "Attachment" }];

  const onAdd = (e) => {
    e.preventDefault();
    benefitsFormFields.index > -1
      ? (benefitsData[benefitsFormFields.index] = benefitsFormFields)
      : benefitsData.push({
          ...benefitsFormFields,
          index: -1,
        });
        setBenefitsData([...benefitsData]);
    setBenefitsFormFields({
      ...employeeBenefitsDetail_,
    });    
  }
  const onEdit = (index, obj) => {
    setBenefitsFormFields({
      ...obj,
      index: index,
    });
  };
  const onDelete = (index) => {
    benefitsData.splice(index, 1);
    setBenefitsData([...benefitsData]);
  };
  const onCancel = ()=>{
    setBenefitsFormFields({
      ...employeeBenefitsDetail_,
    });
  }
  const onUpdate = async (e) => {
    if (benefitsData.length == 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please Add Benefits Information",
      });
    } else {
    e.preventDefault();
    let formData = new FormData();
    formData.append("UserId", formFields.UserId);
    formData.append("EmployeeId", formFields.EmployeeId);
    formData.append("FirstName", FirstName);
    formData.append("CNIC", CNIC);
    benefitsData.forEach((element, index) => {
      Object.keys(element).forEach((item) => {
        formData.append(
          `EmployeeBenefitsDetail_[${index}].${item}`,
          element[item]
        );
      });
    });
    updateEmployee(formData);
  }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

   const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Benefits Details</CardTitle>
        <CardBody>
          <form onSubmit={onAdd}>
          <Row>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                list={list.BenefitsList}
                name="BenefitId"
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                label="Benefits"
                onChange={handleInputChange}
                nameValue="Benefit"
                value={benefitsFormFields.BenefitId}
                required
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
            <div className="form-group">
                  <label className="form-label">Upload Attachment</label>
                  <Input
                    type="file"
                    name="UploadAttachment"
                    onChange={handleBrowseChange}
                    // required
                    accept=".pdf/,image/jpeg,image/jpg,image/png,text/csv,.docx,.doc,.xls,.txt"
                    id="attachment"
                  />
                </div>
            </Col>
          </Row>
          <Row>
            <Col sm="4"></Col>
            <Col className="text-right" sm="8">
              <Button color="primary">
                Add/Update
              </Button>
              <Button color="default" type="button" onClick={onCancel}>Cancel</Button>
            </Col>
          </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>List of Benefits</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={benefitsData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" onClick={onUpdate}>
                {submitLoad ? (
                    <Spinner color="light" size="sm" />
                  ) : null}
                  Update</Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Benefits;
