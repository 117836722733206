import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import { dateFormat, dateFormatPlaceholder } from "../../common/dateFormatEnum";
import {
  blockDepartmentId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  facultyDepartmentId,
  facultyTypeId,
  Search,
  Select,
  SessionStorage,
} from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
  PostRequest,
  Setup_Admission_AcademicYear,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_SeatType,
} from "../../utils/Config";
import DatePicker from "react-datepicker";
import {
  formatDateFunc,
  formatDateFunction,
  formatDateFunctionByName,
} from "../../functions/DateFormatFunction";
import { decryptData } from "../../EncryptData";
import {
  ADM_AdmissionVoucherDueDateExtend,
  SETUP_ACADEMIC_YEAR,
} from "../../utils/UrlConstants";
import { UserNetworkInfo } from "../../utils/EncryptedConstants";
import FormGroupCheckbox from "../../components/GeneralComponent/FormGroupCheckbox";

const VoucherExtendDate = () => {
  const initialSearchFields = {
    AdmRegID: 0,
    OperationId: 0,
    DueDate: new Date(),
    Cnic: "",
    Name: "",
    PageNumber: 1,
    RowsOfPage: 50,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const initialFormFields = {
    AdmRegID: 0,
    OperationId: 0,
    DueDate: new Date(),
    Cnic: "",
    Name: "",
    PageNumber: 1,
    RowsOfPage: 50,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");

  useEffect(() => {
    getVoucherExtendData();
  }, []);

  const getVoucherExtendData = () => {
    PostRequest(ADM_AdmissionVoucherDueDateExtend, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table?.map((x) => ({
              ...x,
              DueDate2: formatDateFunctionByName(x.DueDate),
              FeePaidDate2: x.FeePaidDate === null ? "-" : formatDateFunctionByName(x.FeePaidDate),
            })),
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "AcademicYear", name: "Academic Year" },
    { field: "CandidateName", name: "Name" },
    { field: "Cnic", name: "Cnic" },
    { field: "ConsumerNumber", name: "Consumer Number" },
    { field: "Amount", name: "Amount" },
    { field: "FeeType", name: "Fee Type" },
    { field: "FeePaidDate2", name: "Fee Paid Date" },
    { field: "DueDate2", name: "Due Date" },
    { field: "Status", name: "Status" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const AllDateSet = (event, type) => {
    setSearchEndDate(event);
    let date = formatDateFunction(event, "-");
    FormFields.DueDate = date;
    let data = {
      name: "DueDate",
      value: FormFields.DueDate,
    };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const searchPanel = (
    <Fragment>
      {/* <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">
            Start Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={searchStartDate}
            onChange={(e) => AllDateSet(e, "StartDateSearch")}
            className="form-control"
            name="StartYearDate"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">
            End Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={searchEndDate}
            onChange={(e) => AllDateSet(e, "EndDateSearch")}
            className="form-control"
            name="EndYearDate"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col> */}
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Cnic"
          name="Cnic"
          required
          onChange={handleSearchChange}
          value={SearchFields?.Cnic}
          isNumber="true"
          maxLength={13}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Name"
          name="Name"
          required
          onChange={handleSearchChange}
          value={SearchFields?.Name}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...initialSearchFields,
      ...SearchFields,
    };

    PostRequest(ADM_AdmissionVoucherDueDateExtend, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table?.map((x) => ({
              ...x,
              DueDate2: formatDateFunctionByName(x.DueDate),
              FeePaidDate2: x.FeePaidDate === null ? "-" : formatDateFunctionByName(x.FeePaidDate),
            })),
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      ...initialFormFields,
      ...FormFields,
      OperationId: FormFields?.AdmRegID > 0 ? 2 : 1,
      AdmRegID: FormFields?.AdmRegID,
      DueDate: FormFields?.DueDate,
      Cnic: "",
      Name: "",
      PageNumber: 1,
      RowsOfPage: 50,
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(ADM_AdmissionVoucherDueDateExtend, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          getVoucherExtendData();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    const data = {
      AdmRegID: obj?.ADMRegID,
      DueDate: obj?.DueDate,
      Cnic: "",
      Name: "",
      PageNumber: 1,
      RowsOfPage: 50,
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    setSearchEndDate(new Date(obj?.DueDate));
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getVoucherExtendData();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <div className="form-group">
          <label className="form-label">
            End Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={searchEndDate}
            onChange={(e) => AllDateSet(e, "DueDate")}
            className="form-control"
            name="DueDate"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Admission Voucher Extend"
      buttonName="Bulk Extend Date"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEditCustom={onEditRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
    />
  );
};

export default VoucherExtendDate;
