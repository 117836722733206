import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table } from "reactstrap";
import {
  AdminApprovRejectId,
  Insert,
  Search,
  Select,
  SessionStorage,
  academicYearId,
  partYearID,
  semesterId,
  undergraduateId,
} from "../../../common/SetupMasterEnum";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import ClassConductReport from "../Reports/HODReports/ClassConductReport";
import {
  ACAD_UPLOAD_ATTENDANCE_FILE_BY_CANDIDATE,
  SUBJECT_ALLOTMENT_REPORT,
} from "../../../utils/UrlConstants";
import { PostRequest } from "../../../utils/Config";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import CCRSubReport from "../Reports/HODReports/CCRSubReport";
import { tConvert } from "../../../functions/DateFormatFunction";
import SubjectAllotmentReport from "../Reports/HODReports/SubjectAllotmentReport";
import Swal from "sweetalert2";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../../../components/Alert";
import { SaveFile } from "../../../utils/SaveFile";

const StudentAbsentApproval = () => {
  const initialFormFields = {
    OperationId: 1,
    ParameterID: 0,
    StudentDetailID: 0,
    CourseSemesterMappingID: 0,
    AttendanceRecordID: 0,
    FilePath: "",
    FileName: "",
    UserID: decryptData("loginId", SessionStorage),
    StatusID: 0,
  };

  const initialSearchFields = {
    OperationId: 0,
    ParameterID: 0,
    StudentDetailID: 0,
    CourseSemesterMappingID: 0,
    AttendanceRecordID: 0,
    FilePath: "",
    FileName: "",
    UserID: decryptData("loginId", SessionStorage),
    StatusID: 0,
  };
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  let ref = useRef(null);
  const dispatch = useDispatch();
  const [ApproveRejectList] = useSetupDetailList(AdminApprovRejectId);

  useEffect(() => {
    // onChange_Select_Department_Program({
    //   operationID: 6,
    //   caseID: 2,
    //   paremeterID: 0,
    // }).then((res) =>
    //   dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    // );
    getStudentApproval();
  }, []);

  function getStudentApproval() {
    let formData = new FormData();
    Object.keys(initialSearchFields).forEach((d) => {
      formData.append(d, initialSearchFields[d]);
    });
    PostRequest(ACAD_UPLOAD_ATTENDANCE_FILE_BY_CANDIDATE, formData)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
          },
        });
        // let departmentName = {
        //   name: "departmentName",
        //   value: res?.data?.Table3,
        // };
        // dispatch({
        //   type: SET_INITIAL_DROPDOWN_FORM_STATE,
        //   payload: departmentName,
        // });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onUpdateApproval = (value, id) => {
    const payload = {
      ...initialFormFields,
      StatusID: value,
      AttendanceRecordID: id,
    };
    let formData = new FormData();
    Object.keys(payload).forEach((d) => {
      formData.append(d, payload[d]);
    });
    PostRequest(ACAD_UPLOAD_ATTENDANCE_FILE_BY_CANDIDATE, formData)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          getStudentApproval();
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "ClassDate", name: "Class" },
    { field: "ChsDue", name: "CHs (Due)" },
    { field: "ChsCond", name: "CHs (Cond)" },
    { field: "StartTime", name: "From" },
    { field: "EndTime", name: "To" },
  ];

  // const handleSearchChange = (e) => {
  //   let data = { name: e.target.name, value: e.target.value };
  //   dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  // };

  const handleInputChange = (e, ind) => {
    WarningWithConfirmation(
      e.target.value === 2420
        ? "Are you sure you want to Approved"
        : "Are you sure you want to Rejected"
    ).then((result) => {
      if (result.isConfirmed) {
        TableList[ind].ApproveRejectStatusID = e.target.value;
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: TableList,
          },
        });
        onUpdateApproval(e.target.value, TableList[ind].AttandanceRecordID);
      }
    });
  };

  // const submitSearch = () => {
  //   const payload = {
  //     ...SearchFields,
  //     OperationId: 1,
  //     ParameterID: 1,
  //     // EmployeeID: 0,
  //     // UserID: decryptData("loginId", SessionStorage),
  //     // UserIP: decryptData(UserNetworkInfo)?.IPv4,
  //   };
  //   PostRequest(SUBJECT_ALLOTMENT_REPORT, payload)
  //     .then((res) => {
  //       dispatch({
  //         type: SET_INITIAL_CRUD_FORM_STATE,
  //         payload: {
  //           List: res?.data?.Table,
  //           FormFields: initialFormFields,
  //           SearchFields: SearchFields,
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // const cancelSearch = () => {
  //   dispatch({
  //     type: RESET_SEARCH_FIELDS,
  //     payload: initialSearchFields,
  //   });
  // };

  // const printChallan = useReactToPrint({
  //   content: () => ref.current,
  // });

  // const customButton = (
  //   <>
  //   {TableList?.length > 0 ? (
  //     <Button color="primary" className="btn" onClick={printChallan}>
  //       Print
  //     </Button>
  //   ) : (null)}

  //     <div style={{ display: "none" }}>
  //       {" "}
  //       <SubjectAllotmentReport ref={ref} />
  //     </div>
  //   </>
  // );

  const customTable = (
    <Table bordered striped responsive style={{ width: "100%" }}>
      <thead>
        <tr className="text-center">
          <th>S.No</th>
          <th>Candidate</th>
          <th>Faculty Program</th>
          <th>Semester</th>
          <th>Part</th>
          <th>Lecturer</th>
          <th>Subject</th>
          <th>Day Date</th>
          <th>Class Date</th>
          <th>File</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {TableList?.length > 0 ? (
          TableList?.map((item, ind) => {
            return (
              <tr className="text-center" key={ind}>
                <td>{ind + 1}</td>
                <td>{item.CandidateName}</td>
                <td>{item.FacultyDepartmentProgram}</td>
                <td>{item.Semester}</td>
                <td>{item.Part}</td>
                <td>{item.Lecturer}</td>
                <td>{item.Subject}</td>
                <td>{item.DayDate}</td>
                <td>{item.ClassDate}</td>
                <td>
                  {" "}
                  <a
                    href="#"
                    className="btn round-cubre btn-info"
                    onClick={() => {
                      SaveFile(item.FilePath, "Student_Request_File");
                    }}
                    
                  >
                    <i className="fa fa-eye" color="primary"></i>
                  </a>
                </td>
                <td>
                  <FormGroupSelect
                    list={ApproveRejectList?.filter(
                      (x) => x.SetupDetailId != 2419
                    )}
                    name="StatusID"
                    value={item.ApproveRejectStatusID}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    onChange={(e) => handleInputChange(e, ind)}
                  />
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={11} className="text-center">
              No Data Available
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  return (
    <>
      <CrudFormComponent
        formName="Student Absent Approval"
        modalSize="lg"
        hideSearchPanel
        // searchSubmit={submitSearch}
        // searchPanel={searchPanel}
        // customButton={customButton}
        customTable={customTable}
        // cancelSearch={cancelSearch}
        tableColumns={columns}
        tableRows={TableList?.Table?.[0]}
        featureList={menuTable?.Table2?.filter(
          (x) => x.MenuId === selectedMenu
        )}
      />
    </>
  );
};

export default StudentAbsentApproval;
