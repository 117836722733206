import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, CardTitle, Table, Tooltip } from "reactstrap";
import {
  Delete,
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../../../../components/Alert";
import AcademicFormComponent from "../../../../components/FormComponents/AcademicFormComponent";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import { decryptData } from "../../../../EncryptData";
import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../../utils/Config";
import {
  CURRENT_SEMESTER_FACULTY_OPS,
  CURRENT_SEMESTER_HOD_OPS,
} from "../../../../utils/UrlConstants";
import Spinner from "react-spinkit";

const HODFacultySemesterOutline = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const timeoutRef = useRef(null);

  const [formLoad, setFormLoad] = useState(true);

  const initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    EnrollmentID: 0,
    HodRemarks: "string",
    IsLockByLecturer: true,
    IsLockByHod: true,
  };

  const [
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_HODOps_TBL_,
  ] = useState(initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_);
  
  const intialFormFields = {
    OperationId: Select,
    ParameterID: Delete,
    AcademicYearID: 0,
    SemesterID: 0,
    FacultyDepartmentID: 0,
    FacultyLecturerID: 0,
    CourseSemesterMappingID: 0,
    SetupFacultyDepartmentProgramLecturerID: 0,
    CourseSemesterLecturerMappingId: 0,
    UserID: decryptData("loginId", SessionStorage),
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_: [
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    ],
  };

  // const intialFormFields = {
  //   OperationId: Select,
  //   ParameterID: Delete,
  //   AcademicYearID: 0,
  //   SemesterID: 0,
  //   FacultyDepartmentID: 0,
  //   FacultyLecturerID: 0,
  //   CourseSemesterMappingID: 0,
  //   SetupFacultyDepartmentProgramLecturerID: 0,
  //   CourseSemesterLecturerMappingId: 0,
  //   UserID: decryptData("loginId", SessionStorage),
  // };

  useEffect(() => {
    getHODFacultySemesterOutline();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getHODFacultySemesterOutline = () => {
    const payload = {
      ...intialFormFields,
      CourseSemesterLecturerMappingId: params?.id1,
      CourseSemesterMappingID: params?.id2,
    };
    PostRequest(CURRENT_SEMESTER_HOD_OPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: FormFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "DayDate", name: "Day" },
    { field: "IsTaken", name: "Lecture Taken" },
    { field: "OutlineDescription", name: "Outline Description" },
  ];

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="text-center">S.No.</th>
              {columns &&
                columns?.map((column, index) => (
                  <th
                    style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    key={index}
                    className="text-center"
                  >
                    {column.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {TableList?.map((item, index) => (
              <tr key={index}>
                <td
                  style={{ width: "5%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {index + 1}
                </td>
                <td
                  style={{ width: "20%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.Courses}
                </td>
                <td
                  style={{ width: "20%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.DayDate}
                </td>
                <td
                  style={{ width: "10%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.IsTaken === true ? (
                    <a
                      href="#"
                      //   onClick={(e) =>
                      //     handleInputChange(e, index, "IsTaken", "Check")
                      //   }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-check-circle-o text-success"
                      ></i>
                    </a>
                  ) : (
                    <a
                      href="#"
                      //   onClick={(e) =>
                      //     handleInputChange(e, index, "IsTaken", "Cross")
                      //   }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-times text-danger"
                      ></i>
                    </a>
                  )}
                </td>
                <td className="text-center">
                  <FormGroupInput
                    name="OutlineDescription"
                    type="textarea"
                    disabled={true}
                    // onChange={(e) => handleInputChange(e, index)}
                    value={item?.OutlineDescription}
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Faculty Semester Outline"
      columns={columns}
      rows={TableList}
      customTable={customTable}
    />
  );
};

export default HODFacultySemesterOutline;
