import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Label } from "reactstrap";
import {
  campusCity,
  campusType,
  department,
  monthId,
  Select,
  SessionStorage,
  yearId,
} from "../../../common/SetupMasterEnum";
import CrudTMSComponent from "../../../components/FormComponents/CrudTMSComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { CalendarSpacing } from "../../../functions/CalendarSpacing";
import { formatDateFunction2 } from "../../../functions/DateFormatFunction";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  TMS_ViewCalendarMonth,
} from "../../../utils/Config";
import SelectDropDown from "react-select";
import makeAnimated from "react-select/animated";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  YearId: 0,
  MonthId: 0,
  EmployeeId: "",
};

const ViewCalendar = () => {
  const animatedComponents = makeAnimated();
  const {
    SearchFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const [formLoad, setFormLoad] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getMasterDetailAllDropdown();
    getEmployeeDropDown();
  }, []);
  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        SearchFields: initialSearchFields,
      },
    });
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: Select,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "ShiftDayTypeName", name: "Sun" },
    { field: "ShiftDayTypeName", name: "Mon" },
    { field: "ShiftDayTypeName", name: "Tue" },
    { field: "ShiftDayTypeName", name: "Wed" },
    { field: "ShiftDayTypeName", name: "Thu" },
    { field: "ShiftDayTypeName", name: "Fri" },
    { field: "ShiftDayTypeName", name: "Sat" },
  ];
  function getEmployeeDropDown() {
    const payload = {
      operationId: Select,
      employeeId: decryptData("EmplId", SessionStorage),
      case: 3,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {
        let data = {
          name: "EmployeeList",
          value: res.data.Table.map((x, index) => ({
            ...x,
            label: x.Name,
            value: index + 1,
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    if (e.target.name == "CampusTypeId") {
      SearchFields.CampusCityId = 1644;
    }
    // else if (e.target.name == "DepartmentId") {
    //   getReportingData(e.target.value);
    // }
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };
  const handleSearchDropDownChange = (e) => {
    let data = { name: "EmployeeId", value: e.Id.toString() };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };
  const handleAddChange = (e) => {};

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Campus Type"
          name="CampusTypeId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          list={SupportingTables?.tables?.filter(
            (x) => x.SetupMasterId == campusType
          )}
          onChange={handleSearchChange}
          value={SearchFields?.CampusTypeId}
          // required
        ></FormGroupSelect>
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.tables?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == SearchFields?.CampusTypeId
          )}
          label="Campus City"
          name="CampusCityId"
          onChange={handleSearchChange}
          value={SearchFields?.CampusCityId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          disabled={!SearchFields?.CampusTypeId}
          // required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.tables?.filter(
            (x) => x.SetupMasterId == department
          )}
          label="Department"
          name="DepartmentId"
          onChange={handleSearchChange}
          value={SearchFields?.DepartmentId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          // required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <Label>Employees</Label>
        <SelectDropDown
          closeMenuOnSelect={true}
          components={animatedComponents}
          onChange={handleSearchDropDownChange}
          options={SupportingTables?.EmployeeList}
        />
      </Col>
      <Col lg="3">
        <Label>
          Month & Year <span className="text-danger">*</span>
        </Label>
        <div className="input-type-group">
          <FormGroupSelect
            name="MonthId"
            onChange={handleSearchChange}
            value={SearchFields?.MonthId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == monthId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
          <FormGroupSelect
            name="YearId"
            onChange={handleSearchChange}
            value={SearchFields?.YearId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == yearId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
        </div>
      </Col>
    </Fragment>
  );
  const submitSearch = () => {
    setFormLoad(true);
    TMS_ViewCalendarMonth(SearchFields)
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  };
  const calendarTable = (
    <div
      className="calendar-view"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {columns?.map((a, i) => (
        <div
          key={i}
          className="table-box-header"
          style={{ width: `${100 / 7}%` }}
        >
          {a.name}
        </div>
      ))}
      {TableList?.length > 0 ? (
        <Fragment>
          {TableList?.sort((a, b) => a.DayNum - b.DayNum).map((item, index) =>
            item.DayNum === "1" ? (
              <>
                {CalendarSpacing(item.Day_Name)}
                <div
                  className={
                    item.Day_Name == "Saturday"
                      ? "table-box Saturday"
                      : "table-box" && item.Day_Name == "Sunday"
                      ? "table-box Sunday"
                      : "table-box"
                  }
                  key={index}
                  style={{ width: `${100 / 7}%` }}
                >
                  <p className="mb-2">
                    <b>{item.DayNum}</b>
                  </p>
                  <p className="mb-0">{item.ShiftDayTypeName}</p>
                  <p>{item.ShiftName}</p>
                  <p>{formatDateFunction2(item.Date)}</p>
                </div>
              </>
            ) : (
              <div
                className={
                  item.Day_Name == "Saturday"
                    ? "table-box Saturday"
                    : "table-box" && item.Day_Name == "Sunday"
                    ? "table-box Sunday"
                    : "table-box"
                }
                key={index}
                style={{ width: `${100 / 7}%` }}
              >
                <p className="mb-2">
                  <b>{item.DayNum}</b>
                </p>
                <p>{item.ShiftDayTypeName}</p>
                <p>{item.ShiftName}</p>
                <p>{formatDateFunction2(item.Date)}</p>
              </div>
            )
          )}
        </Fragment>
      ) : (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            background: "#e9e9e9",
            marginTop: 0,
            padding: 20,
            fontWeight: "bold",
          }}
        >
          No Data Available
        </div>
      )}
    </div>
  );
  const submitForm = (id) => {};

  const onEditRow = (obj) => {};

  const onDeleteRow = (obj) => {};

  return (
    <CrudTMSComponent
      formName="View Calendar"
      hideAction={true}
      hideSerialNumber={true}
      formLoad={formLoad}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      calendarTable={calendarTable}
      // featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default ViewCalendar;
