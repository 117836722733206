import React, { Fragment, useEffect, useState } from "react";

import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import PostGraduate from "./PostGraduate";

import UnderGraduate from "./UnderGraduate";

const PreviewUnderGraduate = (props) => {
  useEffect(() => {
  }, []);

  return (
    <Fragment>
      <Container fluid>
          <UnderGraduate Programid={props.Programid} />
      </Container>
    </Fragment>
  );
};

export default PreviewUnderGraduate;
