import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {
  RESET_FORM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../utils/Config";
import {
  academicYearId,
  Select,
  undergraduateId,
} from "../../common/SetupMasterEnum";
import UGTotalReport from "./UG_Report/UGTotalReport";
import { useReactToPrint } from "react-to-print";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import { ADM_DEPARTMENT_PROGRAM_CANDIDATE_REPORT } from "../../utils/UrlConstants";

const initialFormFields = {
  operationID: Select,
  programID: undergraduateId,
  academicYearID: 0,
};

const UGViewTotalReport = () => {
  const componentReference = useRef();
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables: { UG_Total_Record, MasterDropdown },
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();

  const [viewReport, setViewReport] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
      },
    });
    // getProgramCandidateReport();
  }, []);

  function getProgramCandidateReport() {
    PostRequest(ADM_DEPARTMENT_PROGRAM_CANDIDATE_REPORT, FormFields)
      .then((res) => {
        let data = { name: "UG_Total_Record", value: res.data };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        setViewReport(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handlePrintApplicationForm = useReactToPrint({
    content: () => componentReference.current,
  });

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getProgramCandidateReport();
  };

  const handleSearchCancel = (e) => {
    e.preventDefault();
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
    let data = { name: "UG_Total_Record", value: new Array(0) };
    dispatch({
      type: SET_INITIAL_DROPDOWN_FORM_STATE,
      payload: data,
    });
    setViewReport(false);
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  return (
    // <div className="card-div">
    <Container fluid>
      <Card>
        <CardTitle>
          Search Undergraduate Total Report{" "}
          {UG_Total_Record?.Table4?.[0]?.AcademicYear}
        </CardTitle>
        <CardBody>
          <form onSubmit={handleSearchSubmit}>
            <Row>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={MasterDropdown?.filter(
                    (x) => x.SetupMasterId == academicYearId
                  )}
                  label="Academic Year"
                  name="academicYearID"
                  fieldName="SetupDetailName"
                  fieldId="SetupDetailId"
                  required
                  onChange={handleAddChange}
                  value={FormFields?.academicYearID}
                />
              </Col>
              <Col lg="12" md="12" xs="12" className="text-right">
                <Button color="primary" className="btn" type="submit">
                  Search
                </Button>
                <Button
                  className="btn"
                  color="default"
                  onClick={handleSearchCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      {viewReport && (
        <>
          <Card>
            {/* <CardTitle>Undergraduate Se</CardTitle> */}
            <CardBody>
              <Row>
                <Col lg="6" md="6" xs="12">
                  <p>Undergraduate Resigtered Candidates</p>
                </Col>

                <Col lg="6" md="6" xs="12" className="text-right">
                  <Button
                    onClick={handlePrintApplicationForm}
                    color="primary"
                    className="btn"
                    type="submit"
                    disabled={viewReport === true ? false : true}
                  >
                    Print
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 mt-2">
                      <thead>
                        <tr>
                          <th>Department</th>
                          <th>Programs</th>
                          <th className="text-center">Pending</th>
                          <th className="text-center">Approved</th>
                          <th className="text-center">Total</th>
                          <th className="text-center">Paid</th>
                          <th className="text-center">Un-Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                        {UG_Total_Record?.Table?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.Department}</td>
                              <td>{item.Programs}</td>
                              <td className="text-center">{item.Pendings}</td>
                              <td className="text-center">{item.Approved}</td>
                              <td className="text-center">{item.Total}</td>
                              <td className="text-center">{item.Paid}</td>
                              <td className="text-center">{item.UnPaid}</td>
                            </tr>
                          );
                        })}
                        {UG_Total_Record?.Table1?.map((item, index) => {
                          return (
                            <tr row key={index}>
                              <td></td>
                              <td style={{ fontWeight: "bold" }}>Total</td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.Pendings}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.Approved}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.Total}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.Paid}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.UnPaid}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col lg="6" md="6" xs="12">
                  <p>Undergraduate Seat Secured Candidates</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="table-responsive">
                  <table className="table table-striped mb-0 mt-2">
                      <thead>
                        <tr>
                          {/* <th>S.No.</th> */}
                          <th
                            style={{
                              width: "20%",
                            }}
                          >
                            Degree Program
                          </th>
                          {UG_Total_Record?.Table2 &&
                            UG_Total_Record?.Table2?.map((column, index) => (
                              <th
                                style={{
                                  textAlign: "center",
                                }}
                                key={index}
                              >
                                {column?.abc}
                              </th>
                            ))}
                          <th
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Fees Deposited <br /> <small>(Million)</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {UG_Total_Record?.Table3 &&
                          UG_Total_Record?.Table3?.map((item, ind) => (
                            <tr key={ind}>
                              {/* <td>{ind + 1}</td> */}
                              <td
                                style={{
                                  width: "20%",
                                }}
                              >
                                {item.Program}
                              </td>
                              <td className="text-center">{item.OpenMerit}</td>
                              <td className="text-center">{item.Selffinance}</td>
                              <td className="text-center">{item.MilitaryQuota}</td>
                              <td className="text-center">{item.BalochistanQuota}</td>
                              <td className="text-center">{item.OverseasStudentsQuota}</td>
                              <td className="text-center">{item.DisabledQuota}</td>
                              <td className="text-center">{item.EmployeeQuota}</td>
                              <td className="text-center">{item.SportsQuota}</td>
                              <td className="text-center">{item.FeesDepositedMillion}</td>
                            </tr>
                          ))}
                        {UG_Total_Record?.Table4 && UG_Total_Record?.Table4?.map((item, index) => {
                          return (
                            <tr row key={index}>
                              {/* <td></td> */}
                              <td style={{ fontWeight: "bold" }}>Total</td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalOpenMerit}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalSelffinance}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalMilitaryQuota}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalBalochistanQuota}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalOverseasStudentsQuota}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalDisabledQuota}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalEmployeeQuota}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-center"
                              >
                                {item.TotalSportsQuota}
                              </td>
                              <td
                              className="text-center"
                                style={{ fontWeight: "bold" }}
                              >
                                {item["TotalFeesDeposited(Million)"]}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}

      <div style={{ display: "none" }}>
        <UGTotalReport ref={componentReference} />
        {/* <AdmissionReportPrint ref={componentReference} /> */}
      </div>
    </Container>
    // </div>
  );
};

export default UGViewTotalReport;
