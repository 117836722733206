import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  academicYearId,
  admissionTypeId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  facultyTypeId,
  Insert,
  majorsAdId,
  meritlistId,
  programTypes,
  Search,
  Select,
  selfFinanceMeritList,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteWithConfirmation,
  WarningWithConfirmation,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupButton from "../../../components/GeneralComponent/FormGroupButton";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_SEARCH_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Admission_MeritList,
  ADM_AddManualPreferences,
  ADM_Approved_Reject_SelfFinance,
  ADM_EligibilityCriteriaDependency,
  Approved_Reject_MeritList,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_SeatType,
  PostRequest,
} from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { ADM_ADDMANUALPREFERENCES, ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../../utils/UrlConstants";

const initialSearchFields = {
  OperationId: Search,
  Cnic: "",
  AppliedDepartment: "",
  Program: "",
  AdmDeptChoicePreferenceID: 0,
  AdmRegID: 0,
  AdmissionFacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  IsActive: true,
  Name: "",
  AdmissionFacultyDepartment: "",
  FacultyDepartmentProgram: "",
  CreatedBy: decryptData("loginId", SessionStorage),
  ModifiedBy: decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const initialFormFields = {
  OperationId: Search,
  Cnic: "",
  AppliedDepartment: "",
  Program: "",
  AdmDeptChoicePreferenceID: 0,
  AdmRegID: 0,
  AdmissionFacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  IsActive: true,
  Name: "",
  AdmissionFacultyDepartment: "",
  FacultyDepartmentProgram: "",
  CreatedBy: decryptData("loginId", SessionStorage),
  ModifiedBy: decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const AddManualPreferances = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [manualPreferances, setManualPreferances] = useState([]);
  const [enableAdd, setEnableAdd] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    });
  }, []);

  const onChange_ADM_EligibilityCriteriaDependency = (payload) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID === 2) {
            const data = { name: "Departments", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID === 3) {
            const data = { name: "Programs", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const columns = [
    { field: "ADMRegID", name: "ADM Reg ID" },
    {
      field: "AdmissionFacultyDepartment",
      name: "Admission Faculty Department",
    },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
  ];

  const addColumns = [
    { field: "AdmissionFacultyDepartment", name: "Faculty Department" },
    {
      field: "FacultyDepartmentProgram",
      name: "Faculty Department Program",
    },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Cnic"
          value={SearchFields?.Cnic}
          onChange={handleSearchChange}
          label="Student CNIC"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="ADMRegID"
          value={SearchFields?.ADMRegID}
          onChange={handleSearchChange}
          label="Adm RegId"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Name"
          value={SearchFields?.Name}
          onChange={handleSearchChange}
          label="Name"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="AdmissionFacultyDepartment1"
          value={SearchFields?.AdmissionFacultyDepartment1}
          onChange={handleSearchChange}
          label="Applied Department"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="FacultyDepartmentProgram1"
          value={SearchFields?.FacultyDepartmentProgram1}
          onChange={handleSearchChange}
          label="Program"
          disabled
        />
      </Col>
    </Fragment>
  );

  const handleAddChange = (e) => {
    if (e.target.name === "AdmissionFacultyDepartmentId") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value,
      });
      let data = {
        name: "AdmissionFacultyDepartmentId",
        value: e.target.value,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
      let data1 = { name: "FacultyDepartmentProgramId", value: 0 };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
      let selectedName = {
        name: e.target.selectedName,
        value: e.target.selectedValue,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: selectedName });
    } else if (e.target.name === "FacultyDepartmentProgramId") {
      let selecteValue = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: selecteValue });
      let selectedName = {
        name: e.target.selectedName,
        value: e.target.selectedValue,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: selectedName });
      setEnableAdd(true);
    } else {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
    }
  };

  function getADM_AddManualPreferences(action) {
    // ADM_AddManualPreferences(
    //   action === "ModalSearch" ? FormFields : SearchFields
    // )
    PostRequest(ADM_ADDMANUALPREFERENCES, action === "ModalSearch" ? FormFields : SearchFields)
      .then((res) => {
        console.error(res);
        let data = {
          Cnic:
            SearchFields?.Cnic === "" ? FormFields?.Cnic : SearchFields?.Cnic,
          ADMRegID: res?.data?.Table?.[0]?.ADMRegID,
          Name: res?.data?.Table?.[0]?.Name,
          AdmissionFacultyDepartment1:
            res?.data?.Table?.[0]?.AdmissionFacultyDepartment,
          FacultyDepartmentProgram1:
            res?.data?.Table?.[0]?.FacultyDepartmentProgram,
        };
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: action === "ModalSearch" ? new Array(0) : res?.data?.Table1,
            FormFields: action === "ModalSearch" ? data : initialFormFields,
            SearchFields: action === "ModalSearch" ? initialSearchFields : data,
          },
        });
        setManualPreferances(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function refreshADM_AddManualPreferences(payload) {
    const payload1 = {
      OperationId: Search,
      Cnic: payload?.Cnic,
      AppliedDepartment: "",
      Program: "",
      AdmDeptChoicePreferenceID: 0,
      AdmRegID: 0,
      AdmissionFacultyDepartmentId: 0,
      FacultyDepartmentProgramId: 0,
      IsActive: true,
      Name: "",
      AdmissionFacultyDepartment: "",
      FacultyDepartmentProgram: "",
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    //ADM_AddManualPreferences(payload1)
    PostRequest(ADM_ADDMANUALPREFERENCES, payload1)
      .then((res) => {
        console.error(res);
        let data = {
          Cnic:
            SearchFields?.Cnic === "" ? FormFields?.Cnic : SearchFields?.Cnic,
          ADMRegID: res?.data?.Table?.[0]?.ADMRegID,
          Name: res?.data?.Table?.[0]?.Name,
          AdmissionFacultyDepartment1:
            res?.data?.Table?.[0]?.AdmissionFacultyDepartment,
          FacultyDepartmentProgram1:
            res?.data?.Table?.[0]?.FacultyDepartmentProgram,
        };
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: new Array(0),
            FormFields: data,
            SearchFields: initialSearchFields,
          },
        });
        setManualPreferances(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleClickSearch = () => {
    getADM_AddManualPreferences("ModalSearch");
  };

  const handleClickAdd = (e) => {
    e.preventDefault();
    if (manualPreferances.length < 3) {
      if (
        manualPreferances.some(
          (x) =>
            x.AdmissionFacultyDepartmentId ===
            FormFields?.AdmissionFacultyDepartmentId
        )
      ) {
        CustomErrorMessage("Cannot insert same record twice");
      } else {
        manualPreferances.push({
          ...FormFields,
          index: -1,
        });

        let data = { name: "AdmissionFacultyDepartmentId", value: 0 };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
        let selectedName = {
          name: "AdmissionFacultyDepartment",
          value: "",
        };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: selectedName });
        let data1 = { name: "FacultyDepartmentProgramId", value: 0 };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
        let selectedName1 = {
          name: "FacultyDepartmentProgram",
          value: "",
        };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: selectedName1 });
        onClickAdd();
      }
    } else {
      CustomErrorMessage("Cannot insert more than three choices");
    }

    // setEnableAdd(false);
  };

  const onClickAdd = () => {
    const payload = {
      OperationId: Insert,
      Cnic: FormFields?.Cnic,
      AppliedDepartment: FormFields?.AdmissionFacultyDepartment1,
      Program: FormFields?.FacultyDepartmentProgram1,
      AdmDeptChoicePreferenceID: manualPreferances[0]?.SetupDetailId,
      AdmRegID: FormFields?.ADMRegID,
      AdmissionFacultyDepartmentId: FormFields?.AdmissionFacultyDepartmentId,
      FacultyDepartmentProgramId: FormFields?.FacultyDepartmentProgramId,
      IsActive: true,
      Name: "",
      AdmissionFacultyDepartment: "",
      FacultyDepartmentProgram: "",
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    //  ADM_AddManualPreferences(payload)
    PostRequest(ADM_ADDMANUALPREFERENCES, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table[0]?.Column1);
        } else {
          CustomSuccessAlert(res?.data?.Table[0]?.Column1);
          refreshADM_AddManualPreferences(payload);
          setManualPreferances([...manualPreferances]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
    setManualPreferances([]);
    setEnableAdd(false);
  };

  const onDeleteRowCutom = (obj) => {
    let objValue = manualPreferances[obj];
    // manualPreferances.splice(obj, 1);
    // setManualPreferances([...manualPreferances]);
    onClickDelete(objValue);
  };

  const onClickDelete = (objValue) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        const payload = {
          OperationId: Delete,
          Cnic: FormFields?.Cnic,
          AppliedDepartment: FormFields?.AdmissionFacultyDepartment1,
          Program: FormFields?.FacultyDepartmentProgram1,
          AdmDeptChoicePreferenceID: objValue?.AdmDeptChoicePreferenceID,
          AdmRegID: objValue?.ADMRegID,
          AdmissionFacultyDepartmentId: objValue?.AdmissionFacultyDepartmentId,
          FacultyDepartmentProgramId: objValue?.FacultyDepartmentProgramId,
          IsActive: true,
          Name: "",
          AdmissionFacultyDepartment: "",
          FacultyDepartmentProgram: "",
          CreatedBy: decryptData("loginId", SessionStorage),
          ModifiedBy: decryptData("loginId", SessionStorage),
          UserIP: decryptData(UserNetworkInfo)?.IPv4,
        };
        // ADM_AddManualPreferences(payload)
        PostRequest(ADM_ADDMANUALPREFERENCES, payload)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              CustomSuccessAlert(res?.data?.Table[0]?.Column1);
              reCall(payload);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  function reCall(objValue) {
    const payload = {
      OperationId: Search,
      Cnic: FormFields?.Cnic,
      AppliedDepartment: "",
      Program: "",
      AdmDeptChoicePreferenceID: 0,
      AdmRegID: objValue?.ADMRegID,
      AdmissionFacultyDepartmentId: objValue?.AdmissionFacultyDepartmentId,
      FacultyDepartmentProgramId: objValue?.FacultyDepartmentProgramId,
      IsActive: true,
      Name: "",
      AdmissionFacultyDepartment: "",
      FacultyDepartmentProgram: "",
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    // ADM_AddManualPreferences(payload)
    PostRequest(ADM_ADDMANUALPREFERENCES, payload)
      .then((res) => {
        setManualPreferances(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const formPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="Cnic"
          value={FormFields?.Cnic}
          onChange={handleAddChange}
          label="Student CNIC"
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="ADMRegID"
          value={FormFields?.ADMRegID}
          onChange={handleAddChange}
          label="Adm RegId"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="Name"
          value={FormFields?.Name}
          onChange={handleAddChange}
          label="Name"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="AdmissionFacultyDepartment1"
          value={FormFields?.AdmissionFacultyDepartment1}
          onChange={handleAddChange}
          label="Applied Department"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="FacultyDepartmentProgram1"
          value={FormFields?.FacultyDepartmentProgram1}
          onChange={handleAddChange}
          label="Program"
          disabled
        />
      </Col>
      <Col md="4" lg="4">
        <FormGroupSelect
          label="Faculty Department"
          name="AdmissionFacultyDepartmentId"
          list={SupportingTables?.Departments}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.AdmissionFacultyDepartmentId}
          onChange={handleAddChange}
          nameValue="AdmissionFacultyDepartment"
          required
        />
      </Col>
      <Col md="4" lg="4">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramId"
          list={SupportingTables?.Programs}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.FacultyDepartmentProgramId}
          onChange={handleAddChange}
          nameValue="FacultyDepartmentProgram"
          required
        />
      </Col>
      <Col lg="12" md="3" xs="12" className="text-right">
        <Button className="btn" color="primary" onClick={handleClickSearch}>
          Search
        </Button>
        <Button
          className="btn"
          color="primary"
          onClick={handleClickAdd}
          disabled={enableAdd === true ? false : true}
        >
          Add
        </Button>
      </Col>
      <Row className="mt-4 mb-3">
        <Col>
          <FormGroupTable
            columns={addColumns}
            rows={manualPreferances}
            onDelete={onDeleteRowCutom}
          />
        </Col>
      </Row>
    </Fragment>
  );

  const submitSearch = () => {
    getADM_AddManualPreferences();
  };

  const cancelSearch = () => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
  };

  return (
    <CrudFormComponent
      formName="Add Manual Preferances"
      //buttonName="Generate"
      searchPanel={searchPanel}
      tableColumns={columns}
      tableRows={TableList}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      formPanel={formPanel}
      buttonName="Add"
      modalSize="lg"
      handleCancel={handleCancel}
      // onDelete={onDeleteRow}
      showSaveButton={false}
      //   initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default AddManualPreferances;
