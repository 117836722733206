import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../../common/dateFormatEnum";
import {
  admissionFacultyTypeId,
  admissionTypeId,
  campusCity,
  campusType,
  correspondanceId,
  department,
  districtId,
  domicileProvinceId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  facultyTypeId,
  gender,
  nationalityId,
  programTypes,
  religionId,
  SessionStorage,
  shiftTypeId,
} from "../../../../common/SetupMasterEnum";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import { formatDateFunction } from "../../../../functions/DateFormatFunction";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../../utils/Config";
import { decryptData } from "../../../../EncryptData";
import {
  ADMISSION_USER_ID,
  CNIC_BFORM,
  EMAIL,
  EMPLOYEE_NAME,
  LAST_NAME,
  MIDDLE_NAME,
  MOBILE,
  REG_ID,
} from "../../../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../../redux/actionType/CrudActionTypes";
import { CustomErrorMessage } from "../../../../components/Alert";

const PersonalInfo = (props) => {
  const dispatch = useDispatch();
  const initialFormFields = {
    languageId: 0,
    Year: 0,
    WhatsAppNo: "",
    AlternativeNumber: "",
    LastDegreeFromSalu: "",
    Other: "",
    MajorId: 0,
    ProgramId: props.Programid,
    CampusID: 0,
    CampusCityId: 0,
    ProgramTypeId: 0,
    AdmissionTypeId: 0,
    AdmissionFacultyTypeId: 0,
    AdmissionFacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    ShiftId: 0,
    Name: `${decryptData(EMPLOYEE_NAME, SessionStorage)} ${
      decryptData(MIDDLE_NAME, SessionStorage) === "" ? "" : decryptData(MIDDLE_NAME, SessionStorage)
    } ${decryptData(LAST_NAME, SessionStorage) === "" ? "" : decryptData(LAST_NAME, SessionStorage)}`,
    FatherName: "",
    SurName: "",
    GenderID: 0,
    GuardianName: "",
    Cnic: decryptData(CNIC_BFORM, SessionStorage),
    Picture: "",
    DOB: null,
    PostalAddress: "",
    PermanantAddress: "",
    CorrespondatAddressId: 0,
    NationalityID: 0,
    ReligionID: 0,
    DomicileID: 0,
    DomicileDistricId: 0,
    Ph: "",
    Mobile: decryptData(MOBILE, SessionStorage),
    Email: decryptData(EMAIL, SessionStorage),
    AreEmployeedAnyWhere: 0,
    EmployeedDepartment: "",
    NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase: 0,
    UnderTaking: 0,
    UserIP: "192.168.14.1",
  };

  useEffect(() => {
    let data = {
      name: "AdmissionFacultyDepartmentId",
      value: formFields?.AdmissionFacultyDepartmentId,
    };
    dispatch({
      type: SET_INITIAL_DROPDOWN_FORM_STATE,
      payload: data,
    });
  }, []);
  
  const [fileName, setFileName] = useState("");
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const personalInfoData = SupportingTables?.students?.Table1[0];
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
    ...personalInfoData,
  });
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
    FileName: fileName,
  };
  const [profileImage, setProfileImage] = useState();
  const handleInputChange = (event) => {
    if (event.target.type == "radio") {
      setFormFields({
        ...formFields,
        [event.target.name]: parseInt(event.target.value),
      });
    } else if (event.target.name === "AdmissionFacultyDepartmentId") {
      let data = {
        name: "AdmissionFacultyDepartmentId",
        value: event.target.value,
      };
      dispatch({
        type: SET_INITIAL_DROPDOWN_FORM_STATE,
        payload: data,
      });
    } else if (event.target.name === "DomicileID") {
      let data = {
        name: "DomicileID",
        value: event.target.value,
      };
      dispatch({
        type: SET_INITIAL_DROPDOWN_FORM_STATE,
        payload: data,
      });
    }
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageUpload = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
    setFileName(event.target.files[0].name);
    setFormFields({
      ...formFields,
      Picture: event.target.files[0],
    });
  };
  const AllDateSet = (event, type) => {
    setFormFields({
      ...formFields,
      DOB: formatDateFunction(event, "-"),
    });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    // if(formFields.AdmissionFacultyTypeId == 3504 && formFields.DomicileID != 3603 || formFields.AdmissionFacultyDepartmentId == 3535 && formFields.DomicileID != 3603){
    //   CustomErrorMessage("Candidates other than Sindh Domicile are not eligible to apply in this Program");

    // }
    // else{
    let formData = new FormData();
    Object.keys(basicValues).forEach((d) => {
      formData.append(d, basicValues[d]);
    });
    Object.keys(formFields).forEach((d) => {
      formData.append(`ADMPersonalInformation_.${d}`, formFields[d]);
    });
    updateAdmission(formData);
    // };
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          getStudendRecords();
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        // setSubmitLoad(false);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: 3479,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Fragment>
      <form>
            <Row className="mt-2" style={{ alignItems: "center" }}>
              <Col md="9">
                <Row>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Name"
                      name="Name"
                      value={formFields?.Name}
                      onChange={handleInputChange}
                      isAlphabetic="true"
                      disabled
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Father's Name"
                      name="FatherName"
                      value={formFields?.FatherName}
                      onChange={handleInputChange}
                      isAlphabetic="true"
                      required
                      disabled
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Surname"
                      name="SurName"
                      value={formFields?.SurName}
                      onChange={handleInputChange}
                      isAlphabetic="true"
                      disabled
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupSelect
                      label="Gender"
                      name="GenderID"
                      list={SupportingTables?.tables?.filter(
                        (x) => x.SetupMasterId == gender
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={formFields?.GenderID}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Guardian's Name (Other than Father)"
                      name="GuardianName"
                      value={formFields?.GuardianName}
                      onChange={handleInputChange}
                      isAlphabetic="true"
                      disabled
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="CNIC Number"
                      name="Cnic"
                      value={formFields?.Cnic}
                      onChange={handleInputChange}
                      isNumber="true"
                      maxLength="13"
                      minLength="13"
                      disabled
                    />
                  </Col>
                  <Col lg="4" md="4" xs="12">
                    <div className="form-group">
                      <label className="form-label">
                        Date of Birth<span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={new Date(formFields?.DOB)}
                        onChange={(e) => AllDateSet(e, "DateOfBirth")}
                        className="form-control"
                        name="DOB"
                        // value={formatDateFunc(profileFormFields.DateOfBirth, "/")}
                        required={true}
                        showYearDropdown={true}
                        dateFormat={dateFormat}
                        placeholderText={dateFormatPlaceholder}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md="8" lg="8">
                    <FormGroupInput
                      label="Current Address"
                      name="PostalAddress"
                      value={formFields?.PostalAddress}
                      onChange={handleInputChange}
                      required
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="3" className="text-center">
                <Row>
                  <Col>
                    <div className="profile-info text-center mb-3">
                      <div className="profile-img-wrap">
                        <img
                          src={
                            profileImage !== undefined
                              ? profileImage
                              : formFields?.Picture
                          }
                          className=""
                          width={150}
                          height={150}
                          alt={formFields?.Name}
                          name="image"
                          disabled
                        />
                        {/* <div className="fileupload">
                          <p className="btn-text-edit">
                            <i className="fa fa-pencil"></i>
                          </p>
                          <Input
                            className="upload"
                            type="file"
                            accept="image/*"
                            color="default"
                            name="Picture"
                            onChange={handleImageUpload}
                            disabled
                          />
                        </div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Permanent Address"
                  name="PermanantAddress"
                  value={formFields?.PermanantAddress}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Correspondence Address"
                  name="CorrespondatAddressId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == correspondanceId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.CorrespondatAddressId}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Nationality"
                  name="NationalityID"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == nationalityId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.NationalityID}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Religion"
                  name="ReligionID"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == religionId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.ReligionID}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Domicile Province"
                  name="DomicileID"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == domicileProvinceId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.DomicileID}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Domicile District"
                  name="DomicileDistricId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == districtId 
                    && x.SetupDetailId != 5615
                    && x.SetupDetailId != 5617
                    && x.SetupDetailId != 5618
                    && x.SetupDetailId != 5619
                    && x.SetupDetailId != 5620
                    && x.SetupDetailId != 5621
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.DomicileID === 3603 ? formFields?.DomicileDistricId : 5616}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Residence Number"
                  name="Ph"
                  value={formFields?.Ph}
                  onChange={handleInputChange}
                  isNumber="true"
                  maxLength="25"
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Mobile No"
                  name="Mobile"
                  value={formFields?.Mobile}
                  onChange={handleInputChange}
                  isNumber="true"
                  maxLength="25"
                  required
                  disabled
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Email Address"
                  name="Email"
                  value={formFields?.Email}
                  type="email"
                  onChange={handleInputChange}
                  disabled
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <div className="form-group">
                  <Label>
                    Are You Employed anywhere?
                    <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control" onChange={handleInputChange}>
                    <Input
                      type="radio"
                      value={1}
                      name="AreEmployeedAnyWhere"
                      checked={formFields.AreEmployeedAnyWhere == 1}
                      disabled
                    />
                    Yes
                    <Input
                      type="radio"
                      value={0}
                      name="AreEmployeedAnyWhere"
                      checked={formFields.AreEmployeedAnyWhere == 0}
                      disabled
                    />
                    No
                  </div>
                </div>
              </Col>
              {formFields?.AreEmployeedAnyWhere == 1 ? (
                <Col md="3" lg="3">
                  <FormGroupInput
                    label="Department Name"
                    name="EmployeedDepartment"
                    value={formFields?.EmployeedDepartment}
                    onChange={handleInputChange}
                    required={
                      formFields?.AreEmployeedAnyWhere == 1 ? true : false
                    }
                    disabled
                  />
                </Col>
              ) : null}
              <Col lg="3" md="3" xs="12">
                <div className="form-group">
                  <Label>
                    Self-Finance Base
                    <span className="text-danger">*</span>
                  </Label>
                  <div className="form-control" onChange={handleInputChange}>
                    <Input
                      type="radio"
                      value={1}
                      checked={
                        formFields.NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase ==
                        1
                      }
                      name="NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase"
                      disabled
                    />
                    Yes
                    <Input
                      type="radio"
                      value={0}
                      checked={
                        formFields.NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase ==
                        0
                      }
                      name="NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase"
                      disabled
                    />
                    No
                  </div>
                </div>
                <p style={{ fontSize: "9px" }}>
                  (if not admitted on marit basis, I may be consider On
                  Self-Finance Base)
                </p>
                {/* <p style={{ fontSize: "9px" }}>
                  (if not admitted on marit basis, I may be consider On
                  Self-Finance Base)
                </p> */}
              </Col>
            </Row>
      </form>
    </Fragment>
  );
};

export default PersonalInfo;
