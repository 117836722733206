import React, { Fragment, useState } from "react";
import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col } from "reactstrap";
import Swal from "sweetalert2";
import {
  academicYearId,
  Insert,
  meritlistId,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupButton from "../../../components/GeneralComponent/FormGroupButton";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";

import {
  RESET_FORM_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  ADM_GenerateMeritListOps,
  Approved_Reject_MeritList,
} from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import OpenMeritListReport from "./Report/OpenMeritListReport";
import { useReactToPrint } from "react-to-print";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import MeritListReport from "./Report/MeritListReport";

const GenerateMeritList = () => {
  const initialSearchFields = {
    OperationID: Select,
    Case: 0,
    AcademicYearId: 0,
    CampusID: 0,
    CampusCityID: 0,
    ProgramID: 0,
    ProgramTypeID: 0,
    AdmissionTypeId: 0,
    AdmissionFacultyTypeId: 0,
    AdmissionFacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    MeritListID: 0,
    SeatTypeId: 0,
    SeatQty: 0,
    AdmRegId: 0,
    MeritScore: 0,
    CreatedBy: decryptData("loginId", SessionStorage),
    ModifiedBy: 0,
    UserIP: decryptData(UserNetworkInfo).IPv4,
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [onSearch, setOnSearch] = useState(false);
  const printMeritList = useRef();
  const printOpenMeritList = useRef();

  const handleDownloadMeritList = useReactToPrint({
    content: () => printMeritList.current,
  });

  const handleDownloadOpenMeritList = useReactToPrint({
    content: () => printOpenMeritList.current,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        SearchFields: initialSearchFields,
      },
    });
  }, []);

  const CaseList = [
    {
      id: "1",
      name: "By Applied Program",
    },
    {
      id: "2",
      name: "By Choice Prefrences",
    },
  ];

  const columns = [
    { field: "Cnic", name: "Cnic" },
    { field: "Name", name: "Name" },
    { field: "FatherName", name: "Father Name" },
    { field: "FacultyDepartment", name: "Faculty Department" },
    { field: "Program", name: "Program" },

    { field: "MeritScore", name: "Merit Score" },
    { field: "PreferenceOrder", name: "Preference Order" },
    { field: "Offered", name: "Offered" },
    { field: "Mobile", name: "Mobile" },
  ];

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    setOnSearch(false);
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        SearchFields: initialSearchFields,
      },
    });
  };

  const handleSearchChange = (e) => {
    setOnSearch(false);
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearId"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearId}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Merit List"
          name="MeritListID"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == meritlistId
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.MeritListID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Case Name"
          name="Case"
          list={CaseList}
          fieldName="name"
          fieldId="id"
          onChange={handleSearchChange}
          value={SearchFields?.Case}
        />
      </Col>
    </Fragment>
  );

  const handleGenerateMeritList = () => {
    const payload = {
      OperationID: Update,
      Case: SearchFields?.Case,
      AcademicYearId: SearchFields?.AcademicYearId,
      CampusID: 0,
      CampusCityID: 0,
      ProgramID: 0,
      ProgramTypeID: 0,
      AdmissionTypeId: 0,
      AdmissionFacultyTypeId: 0,
      AdmissionFacultyDepartmentId: 0,
      FacultyDepartmentProgramId: 0,
      MeritListID: SearchFields?.MeritListID,
      SeatTypeId: 0,
      SeatQty: 0,
      AdmRegId: 0,
      MeritScore: 0,
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: 0,
      UserIP: decryptData(UserNetworkInfo).IPv4,
    };
    ADM_GenerateMeritListOps(payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const customButton = (
    <>
      <FormGroupButton
        title="Generate Merit List"
        onClick={handleGenerateMeritList}
        showToolTip={false}
        toolTipTitle="Generate Merit List"
        disabled
        // disabled={
        //   onSearch === true ? (TableList?.length > 0 ? false : true) : true
        // }
      ></FormGroupButton>
      <FormGroupButton
        title="Print"
        disabled={
          onSearch === true ? (TableList?.length > 0 ? false : true) : true
        }
        onClick={SearchFields?.MeritListID === 5630 ?  handleDownloadOpenMeritList : handleDownloadMeritList}
        showToolTip={false}
        toolTipTitle="Print"
      ></FormGroupButton>
      <div style={{ display: "none" }}>
        <MeritListReport ref={printMeritList} />
      </div>
      <div style={{ display: "none" }}>
        <OpenMeritListReport ref={printOpenMeritList} />
      </div>
    </>
  );

  const submitSearch = () => {
    ADM_GenerateMeritListOps(SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data?.Table?.map((item) => ({
              ...item,
              Score: parseFloat(item.Score).toFixed(5),
            })),
            SearchFields: SearchFields,
          },
        });
        setOnSearch(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CrudFormComponent
      formName="Generate Merit List"
      // buttonName="Generate Merit List"
      customButton={customButton}
      searchPanel={searchPanel}
      tableColumns={columns}
      tableRows={TableList}
      hideAction={true}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      //   onConfirm={onApproved}
      //   onRefuse={onReject}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default GenerateMeritList;
