import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import DatePicker from "react-datepicker";
import {
  ADM_EligibilityCriteriaDependency,
  PostRequest,
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_ReportingTo,
  Setup_ShiftTiming,
  Setup_UpdateEmployee,
} from "../../../../utils/Config";
import { formatDateFunction } from "../../../../functions/DateFormatFunction";
import Swal from "sweetalert2";
import {
  adminCase,
  campusCity,
  campusType,
  inchargeCase,
  SessionStorage,
} from "../../../../common/SetupMasterEnum";
import { useParams } from "react-router";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../../common/dateFormatEnum";
import { decryptData } from "../../../../EncryptData";
import { useDispatch, useSelector } from "react-redux";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../../redux/actionType/CrudActionTypes";
import { ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../../../utils/UrlConstants";

const ProfessionalInfo = (props) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();
  const { id } = useParams();
  const EmplId =
    id == 0
      ? decryptData("EmplId", SessionStorage)
      : decryptData("EmplGetId", SessionStorage);
  const LoginId = decryptData("loginId", SessionStorage);
  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    FirstName: "",
    CNIC: "",
    employeeProfile_: [],
  };
  const employeeProfile_ = {
    EmployeeId: EmplId,
    EmployeeCode: "",
    CostCenterId: 0,
    DomainId: 0,
    PreviousEmployeeCode: "",
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    FatherName: "",
    CountryId: 0,
    CityId: 0,
    ReligionId: 0,
    GenderId: 0,
    NationalityID: 0,
    MatrialStatusId: 0,
    DateOfBirth: null,
    CountryOfBirthId: 0,
    PlaceOfBirthId: 0,
    DomicileDistrict: "",
    HighestDegreeid: 0,
    TotalExperienceId: 0,
    CNIC: "",
    CNICIssueDate: "",
    CNICExpiryDate: "",
    PassportNumber: "",
    FilerNonFiler: 0,
    NTN: "",
    Ssn: "",
    PrimaryCellNo: "",
    HomePhoneNo: "",
    WorkEmail: "",
    SecondaryCellNo: "",
    OfficialPhoneNo: "",
    PersonalEmail: "",
    CurrentAddress: "",
    PermanentAddress: "",
    CorrespondenceAddressId: 0,
    SoftwareLoginId: "",
    FileNo: "",
    EmployeeSectorId: 0,
    ProvinceId: 0,
    Actual_DesignationId: 0,
    CampusTypeId: 0,
    CampusCityId: 0,
    // EmployeeTypeId: 0,
    EmployeeCateId: 0,
    UserTypeId: 0,
    facultyTypeId: 0,
    EmployementTypeId: 0,
    EmployementStatusId: 0,
    HiringDepartmentId: 0,
    HiringDesignationId: 0,
    HiringEmployeeGradeId: 0,
    AdditionalChargeId: 0,
    WorkingDesignationId: 0,
    WorkingDepartmentId: 0,
    WorkingGradeId: 0,
    DrawingDesignationId: 0,
    DrawingDepartmentId: 0,
    DrawingGradeId: 0,
    StatusId: 0,
    IdStatusId: 0,
    PayRollTypeId: 0,
    JoiningDate: null,
    ProbotionStartDate: null,
    ProbationEndDate: null,
    ProbotionExtendToDate: null,
    ProbationPeriodDays: 0,
    ContractStartDate: null,
    ContractEndDate: null,
    ConfirmationDate: null,
    RelievingRetirementDate: null,
    TMSShiftId: 0,
    Reportingto: 0,
    DesignationofRA: "",
    CardNumber: 0,
    AccessCardId: 0,
    CollectReasonId: 0,
    AccessCardIssueDate: null,
    AccessCardCollectDate: null,
    PriodEndDate: 0,
    InchargeId: 0,
    NoticePeriodDays: 0,
    Fax: "",
    IsContractual: 0,
    ImageName: "",
    ImageFolderName: "",
    ImagePath: "",
    TmsLeaveId: 0,
    ResignedDate: 0,
    NoOfChildren: 0,
    TotalNoOfLeaves: 0,
    DiseaseDate: null,
    LastworkingDate: null,
    TerminationTypeId: 0,
    OfficialMobileNumber: "",
    ManagerId: 0,
    PersonalityDescription: "",
    OfficeExtension: "",
    LeaveTypeId: 0,
    PayScaleId: 0,
    Remarks: "",
    CadreId: 0,
    IsDeleted: 0,
    IsActive: 1,
    InchargeId: 0,
    HODID: 0,
    // CreatedBy: 0,
    // ModifiedBy: LoginId,
    UserIP: "192.168.12.23",
    FacultyDepartmentID: 0,
    FacultyDepartmentProgramId: 0,
  };
  const formData = props?.data;
  const list = props?.list;
  // const formLoad = props?.formLoad;
  const myList = { tables: props?.resData };
  const [professionalFormFields, setProfessionalFormFields] = useState({
    ...employeeProfile_,
    ...formData,
  });
  const [formFields, setFormFields] = useState({
    ...initialValues,
    EmployeeProfile_: professionalFormFields,
  });
  const [reportingList, setReportingList] = useState([]);
  const [inchargeList, setInchargeList] = useState([]);
  // const [hodList, setHodList] = useState([]);
  // const [inchargeList, setInchargeList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  useEffect(() => {
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    });
    setProfessionalFormFields({ ...employeeProfile_, ...formData });
  }, [formData]);

  useEffect(() => {
    setProfessionalFormFields({ ...employeeProfile_, ...formData });
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 3,
      paremeterID:
        formData?.FacultyDepartmentID === undefined
          ? 0
          : formData?.FacultyDepartmentID,
    });
  }, [formData]);

  const onChange_ADM_EligibilityCriteriaDependency = (payload) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID === 2) {
            const data = { name: "Departments", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID === 3) {
            const data = { name: "Programs", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleInputChange = (event) => {
    if (event.target.name == "HiringDepartmentId") {
      getReportingData(event.target.value);
    } else if (event.target.name === "FacultyDepartmentID") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: event.target.value,
      });
      professionalFormFields.FacultyDepartmentProgramId = 0;
    } else if (event.target.name == "CampusTypeId") {
      professionalFormFields.CampusCityId = 1644;
    } else if (event.target.name == "Reportingto") {
      professionalFormFields.DesignationofRA = event.target.newfield;
    }
    setProfessionalFormFields({
      ...professionalFormFields,
      [event.target.name]: event.target.value,
    });
  };
  function getEmployeeDropDown() {
    const payload = {
      operationId: 1,
      employeeId: decryptData("EmplId", SessionStorage),
      case: id == 1 ? adminCase : inchargeCase,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {
        setInchargeList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const AllDateSet = (event, type) => {
    if (type === "JoiningDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        JoiningDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ProbotionStartDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        ProbotionStartDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ProbationEndDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        ProbationEndDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ProbotionExtendToDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        ProbotionExtendToDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ContractStartDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        ContractStartDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ContractEndDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        ContractEndDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ConfirmationDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        ConfirmationDate: formatDateFunction(event, "-"),
      });
    } else if (type === "RelievingRetirementDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        RelievingRetirementDate: formatDateFunction(event, "-"),
      });
    } else if (type === "DiseaseDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        DiseaseDate: formatDateFunction(event, "-"),
      });
    } else if (type === "LastworkingDate") {
      setProfessionalFormFields({
        ...professionalFormFields,
        LastworkingDate: formatDateFunction(event, "-"),
      });
      // } else if (type === "FamilyMemberDBO") {
      //   let format = formatDateFunction(event, "/");
      //   let age = calculateAge(format);
      //   familyprofileFormFields.Age = age;
      //   setFamilyprofileFormFields({
      //     ...familyprofileFormFields,
      //     FamilyMemberDBO: event,
      //   });
    }
  };

  useEffect(() => {
    getReportingData(professionalFormFields?.HiringDepartmentId);
  }, [professionalFormFields.HiringDepartmentId]);
  useEffect(() => {
    getShiftData();
    getEmployeeDropDown();
  }, [id]);
  function getReportingData(data) {
    Setup_ReportingTo(data)
      .then((res) => {
        setReportingList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getShiftData() {
    Setup_ShiftTiming()
      .then((res) => {
        setShiftList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onUpdate = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("UserId", formFields.UserId);
    formData.append("EmployeeId", formFields.EmployeeId);
    formData.append("FirstName", formFields.FirstName);
    formData.append("CNIC", formFields.CNIC);
    Object.keys(professionalFormFields).forEach((d) => {
      formData.append(`EmployeeProfile_.${d}`, professionalFormFields[d]);
    });
    updateEmployee(formData);
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };
  return (
    <>
      <form onSubmit={onUpdate}>
        <Card className="mt-3">
          <CardTitle>Professional Details</CardTitle>
          <CardBody>
            <Row>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Software Login Id"
                  onChange={handleInputChange}
                  name="SoftwareLoginId"
                  value={professionalFormFields.SoftwareLoginId}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="File No."
                  onChange={handleInputChange}
                  name="FileNo"
                  value={professionalFormFields.FileNo}
                  // required
                  isNumber="true"
                  maxLength="20"
                />
              </Col>
              {/* <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="Role"
                list={roles}
                fieldId="id"
                fieldName="name"
                value={formFields.Role}
                label="Role"
                onChange={handleInputChange}
              />
            </Col> */}
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="EmployeeSectorId"
                  list={list.SectorList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields.EmployeeSectorId}
                  label="Sector"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  list={list.ProvinceList}
                  name="ProvinceId"
                  value={professionalFormFields.ProvinceId}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  label="Province"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Campus Type"
                  list={myList?.tables?.filter(
                    (x) => x.SetupMasterId == campusType
                  )}
                  name="CampusTypeId"
                  value={professionalFormFields.CampusTypeId}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Campus City"
                  list={myList?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == campusCity &&
                      x.parentid == professionalFormFields.CampusTypeId
                  )}
                  name="CampusCityId"
                  value={professionalFormFields.CampusCityId}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  disabled={!professionalFormFields.CampusTypeId}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Business Unit"
                  name="BusinessUnitId"
                  list={list.BusinessUnitList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields.BusinessUnitId}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Department"
                  list={list.DepartmentList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  name="HiringDepartmentId"
                  value={professionalFormFields.HiringDepartmentId}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="CostCenterId"
                  value={professionalFormFields.CostCenterId}
                  label="Cost Center"
                  onChange={handleInputChange}
                  list={list.CostCenterList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  required
                />
              </Col>

              {/* <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="EmployeeCateId"
                  list={list.EmployeeCategoryList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields.EmployeeCateId}
                  label="Employee Category"
                  onChange={handleInputChange}
                  required
                />
              </Col> */}
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="User Type"
                  list={list?.UserTypeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  name="UserTypeId"
                  value={professionalFormFields?.UserTypeId}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Employee Category"
                  list={list.EmployeeTypeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  name="EmployeeCateId"
                  value={professionalFormFields.EmployeeCateId}
                  required
                />
              </Col>

              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDepartmentID"
                  list={SupportingTables?.Departments}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields?.FacultyDepartmentID}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.Programs}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields?.FacultyDepartmentProgramId}
                  onChange={handleInputChange}
                />
              </Col>
              {/* <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Faculty Type"
                  list={list.FacultyTypeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  name="facultyTypeId"
                  value={
                    professionalFormFields.EmployeeCateId !== 2112
                      ? 0
                      : professionalFormFields.facultyTypeId
                  }
                  disabled={
                    professionalFormFields.EmployeeCateId !== 2112
                      ? true
                      : false
                  }
                  required={
                    professionalFormFields.EmployeeCateId !== 2112
                      ? false
                      : true
                  }
                />
              </Col> */}
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="CadreId"
                  list={list.CadreList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={
                    professionalFormFields.EmployeeCateId === 2112
                      ? 0
                      : professionalFormFields.CadreId
                  }
                  label="Cadre"
                  disabled={
                    professionalFormFields.EmployeeCateId === 2112
                      ? true
                      : false
                  }
                  onChange={handleInputChange}
                  required={
                    professionalFormFields.EmployeeCateId === 2112
                      ? false
                      : true
                  }
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Employment Type"
                  name="EmployementTypeId"
                  value={professionalFormFields.EmployementTypeId}
                  list={list.EmploymentTypeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  required
                />
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Designation"
                  list={list.DesignationList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  name="HiringDesignationId"
                  value={professionalFormFields.HiringDesignationId}
                  required
                />
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Additional Charge (If Any)"
                  name="AdditionalChargeId"
                  value={professionalFormFields.AdditionalChargeId}
                  list={list.AdditionalChargesList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Employee Grade"
                  list={list.EmployeeGradeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  name="HiringEmployeeGradeId"
                  value={professionalFormFields.HiringEmployeeGradeId}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Total Number of Leaves"
                  isNumber="true"
                  // fieldId="SetupDetailId"
                  // fieldName="SetupDetailName"
                  // list={list.NumberOfLeavesList}
                  onChange={handleInputChange}
                  value={professionalFormFields?.TotalNoOfLeaves?.toString()}
                  name="TotalNoOfLeaves"
                  maxLength="4"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="StatusId"
                  list={list.StatusList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields.StatusId}
                  label="Status"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="IdStatusId"
                  list={list.IdStatusList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields.IdStatusId}
                  label="ID Status"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  name="EmployementStatusId"
                  list={list.EmploymentStatus}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={professionalFormFields.EmployementStatusId}
                  label="Employment Status"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Payroll Type"
                  list={list.PayrollTypeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  name="PayRollTypeId"
                  value={professionalFormFields.PayRollTypeId}
                  required
                />
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Date Of Joining<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    selected={new Date(professionalFormFields.JoiningDate)}
                    onChange={(e) => AllDateSet(e, "JoiningDate")}
                    className="form-control"
                    name="JoiningDate"
                    // value={formatDateFunc(
                    //   familyprofileFormFields.FamilyMemberDBO,
                    //   "/"
                    // )}
                    required={true}
                    showYearDropdown={true}
                    // isClearable
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Probation Start Date
                    {/* {professionalFormFields.EmployementStatusId == 1970 ? <span className="text-danger">*</span> : null } */}
                  </label>
                  <DatePicker
                    selected={
                      new Date(professionalFormFields.ProbotionStartDate)
                    }
                    onChange={(e) => AllDateSet(e, "ProbotionStartDate")}
                    className="form-control"
                    name="ProbotionStartDate"
                    // required={
                    //   professionalFormFields.EmployementStatusId == 1970
                    //     ? true
                    //     : false
                    // }
                    disabled={
                      professionalFormFields.EmployementStatusId == 1970
                        ? false
                        : true
                    }
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Probation End Date
                    {/* {professionalFormFields.EmployementStatusId == 1970 ? <span className="text-danger">*</span> : null } */}
                  </label>
                  <DatePicker
                    selected={new Date(professionalFormFields.ProbationEndDate)}
                    onChange={(e) => AllDateSet(e, "ProbationEndDate")}
                    className="form-control"
                    name="ProbationEndDate"
                    // required={
                    //   professionalFormFields.EmployementStatusId == 1970
                    //     ? true
                    //     : false
                    // }
                    disabled={
                      professionalFormFields.EmployementStatusId == 1970
                        ? false
                        : true
                    }
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Probation Extend To
                    {/* {professionalFormFields.EmployementStatusId == 1970 ? <span className="text-danger">*</span> : null } */}
                  </label>
                  <DatePicker
                    selected={
                      new Date(professionalFormFields.ProbotionExtendToDate)
                    }
                    onChange={(e) => AllDateSet(e, "ProbotionExtendToDate")}
                    className="form-control"
                    name="ProbotionExtendToDate"
                    // required={
                    //   professionalFormFields.EmployementStatusId == 1970
                    //     ? true
                    //     : false
                    // }
                    disabled={
                      professionalFormFields.EmployementStatusId == 1970
                        ? false
                        : true
                    }
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  name="ProbationPeriodDays"
                  value={professionalFormFields?.ProbationPeriodDays?.toString()}
                  isNumber="true"
                  maxLength="3"
                  label="Probation Days"
                  onChange={handleInputChange}
                  // required={
                  //   professionalFormFields.EmployementStatusId == 1970
                  //     ? true
                  //     : false
                  // }
                  disabled={
                    professionalFormFields.EmployementStatusId == 1970
                      ? false
                      : true
                  }
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Contract Start Date
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <DatePicker
                    selected={
                      new Date(professionalFormFields.ContractStartDate)
                    }
                    onChange={(e) => AllDateSet(e, "ContractStartDate")}
                    className="form-control"
                    name="ContractStartDate"
                    required={
                      professionalFormFields.EmployementStatusId == 1970
                        ? true
                        : false
                    }
                    disabled={
                      professionalFormFields.EmployementStatusId == 1970
                        ? false
                        : true
                    }
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Contract End Date
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <DatePicker
                    selected={new Date(professionalFormFields.ContractEndDate)}
                    onChange={(e) => AllDateSet(e, "ContractEndDate")}
                    className="form-control"
                    name="ContractEndDate"
                    required={
                      professionalFormFields.EmployementStatusId == 1970
                        ? true
                        : false
                    }
                    disabled={
                      professionalFormFields.EmployementStatusId == 1970
                        ? false
                        : true
                    }
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Confirmation Date
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <DatePicker
                    selected={new Date(professionalFormFields.ConfirmationDate)}
                    onChange={(e) => AllDateSet(e, "ConfirmationDate")}
                    className="form-control"
                    name="ConfirmationDate"
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Relieving/Retirement Date
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <DatePicker
                    selected={
                      new Date(professionalFormFields.RelievingRetirementDate)
                    }
                    onChange={(e) => AllDateSet(e, "RelievingRetirementDate")}
                    className="form-control"
                    name="RelievingRetirementDate"
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Deceased Date (If Any)
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <DatePicker
                    selected={new Date(professionalFormFields.DiseaseDate)}
                    onChange={(e) => AllDateSet(e, "DiseaseDate")}
                    className="form-control"
                    name="DiseaseDate"
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    Last Working Date
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <DatePicker
                    selected={new Date(professionalFormFields.LastworkingDate)}
                    onChange={(e) => AllDateSet(e, "LastworkingDate")}
                    className="form-control"
                    name="LastworkingDate"
                    showYearDropdown={true}
                    // isClearable={ professionalFormFields.EmployementStatusId == 1970
                    //   ? false
                    //   : true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  list={shiftList}
                  fieldId="TMSShiftId"
                  fieldName="ShiftName"
                  label="Shift Timing"
                  onChange={handleInputChange}
                  name="TMSShiftId"
                  value={professionalFormFields.TMSShiftId}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Phone Ext"
                  onChange={handleInputChange}
                  name="OfficeExtension"
                  isNumber="true"
                  value={professionalFormFields.OfficeExtension}
                  maxLength="15"
                />
              </Col>

              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Reporting Authority"
                  list={reportingList}
                  fieldId="EmployeeId"
                  fieldName="DisplayName"
                  onChange={handleInputChange}
                  name="Reportingto"
                  value={professionalFormFields.Reportingto}
                  newfield="Designation"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Designation of RA"
                  onChange={handleInputChange}
                  name="DesignationofRA"
                  value={professionalFormFields.DesignationofRA}
                  maxLength="70"
                  disabled
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="HOD"
                  list={reportingList}
                  fieldId="EmployeeId"
                  fieldName="DisplayName"
                  onChange={handleInputChange}
                  name="HODID"
                  value={professionalFormFields.HODID}
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Incharge"
                  onChange={handleInputChange}
                  name="InchargeId"
                  value={professionalFormFields.InchargeId}
                  list={inchargeList}
                  fieldId="Id"
                  fieldName="Name"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardTitle>Working Status</CardTitle>
          <CardBody>
            <Row>
              <Col lg="4" md="4" sm="6" xs="12">
                <FormGroupSelect
                  list={list.DesignationList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="WorkingDesignationId"
                  value={professionalFormFields.WorkingDesignationId}
                  onChange={handleInputChange}
                  label="Designation"
                  required
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="12">
                <FormGroupSelect
                  list={list.DepartmentList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="WorkingDepartmentId"
                  onChange={handleInputChange}
                  value={professionalFormFields.WorkingDepartmentId}
                  label="Department"
                  required
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="12">
                <FormGroupSelect
                  list={list.EmployeeGradeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="WorkingGradeId"
                  onChange={handleInputChange}
                  value={professionalFormFields.WorkingGradeId}
                  label="Employee Grade"
                  required
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardTitle>Drawing Salary</CardTitle>
          <CardBody>
            <Row>
              <Col lg="4" md="4" sm="6" xs="12">
                <FormGroupSelect
                  list={list.DesignationList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="DrawingDesignationId"
                  onChange={handleInputChange}
                  value={professionalFormFields.DrawingDesignationId}
                  label="Designation"
                  required
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="12">
                <FormGroupSelect
                  list={list.DepartmentList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="DrawingDepartmentId"
                  onChange={handleInputChange}
                  value={professionalFormFields.DrawingDepartmentId}
                  label="Department"
                  required
                />
              </Col>
              <Col lg="4" md="4" sm="6" xs="12">
                <FormGroupSelect
                  list={list.EmployeeGradeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  name="DrawingGradeId"
                  onChange={handleInputChange}
                  value={professionalFormFields.DrawingGradeId}
                  label="Employee Grade"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="primary">
                    {submitLoad ? <Spinner color="light" size="sm" /> : null}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </>
  );
};

export default ProfessionalInfo;
