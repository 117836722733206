import React, { forwardRef } from "react";
import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import logoimg from "../../../../assets/img/logo.png";

const SubjectAllotmentReport = forwardRef((props, ref) => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );

  return (
    <div ref={ref} style={{ fontSize: 13, margin: "20px" }}>
      <header style={{ width: "100%" }}>
        <div
          className="d-flex justify-content-center"
          style={{ width: "100%" }}
        >
          <img src={logoimg} height="130" />
          <div style={{ marginTop: "20px", width: "100%" }}>
            <h2
              className="text-left mb-0"
              style={{ fontFamily: "'Balthazar', serif" }}
            >
              THE SHEIKH AYAZ UNIVERSITY, SHIKARPUR
            </h2>
            <div
              className="tbl-row-bgmr d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <h6 className="text-left mgbtm">Revival of Educational glory</h6>
              <h6 className="text-right mgbtm" style={{ fontWeight: "bold" }}>
                OFFICE OF I/C {TableList?.[0]?.FacultyDepartment}
              </h6>
            </div>
            <p className="text-right mb-0">January 21, 2023</p>
            <p className="text-right mb-0">TSAU-CS-2022-0022/</p>
          </div>
        </div>
      </header>
      <div style={{ paddingLeft: "20px" }}>
        <h6 className="bolder text-left">The faculty members,</h6>
        <h6 className="bolder text-left">
          Department of {TableList?.[0]?.FacultyDepartment},
        </h6>
        <h6 className="bolder text-left">The Shaikh Ayaz University,</h6>
        <h6 className="bolder text-left">Shikarpur.</h6>
        <h5 className="bolder text-left underLineText">ALLOTMENT OF COURSES</h5>
        <p style={{ fontSize: "15px", paddingRight: "45px" }}>
          As per competent Authority decision, the classes for new session i.e.,
          1st Semester 2023 will commence from 23rd of January 2023. The faculty
          members of {TableList?.[0]?.FacultyDepartment} Department will bear
          the workload as under: -.
        </p>
      </div>
      <table style={{ width: "100%" }}>
        <thead
          className="tbl-row-border"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <tr>
            <td className="tbl-row-border bolder" >
              S.NO
            </td>
            <td className="tbl-row-border bolder" >
              FACULTY
            </td>
            <td
              className="tbl-row-border bolder text-center"
            >
              Course Code	
            </td>
            <td className="tbl-row-border bolder text-center" >
              COURSE
            </td>
            <td
              className="tbl-row-border bolder text-center"
             
            >
              CHS
            </td>
          </tr>
        </thead>
        <tbody className="tbl-row-border">
          {TableList?.map((index, i) => (
            <tr key={i}>
              
                <td
                  style={{ border: "1px solid black" }}
                  className="text-center"
                >
                  {" "}
                  {i + 1}
                </td>
                <td
                  style={{ border: "1px solid black" }}
                  className="text-center"
                >
                {index.name1}
                </td>
                <td
                  style={{ border: "1px solid black" }}
                  className="text-center"
                >
                {index.Code}
                </td>
                <td
                  style={{ border: "1px solid black" }}
                  className="text-center"
                >
                {index.Name}
                </td>
                <td
                  style={{ border: "1px solid black" }}
                  className="text-center"
                >
                {index.CrHrs}
                </td>
             
            </tr>
          ))}

        
  
        </tbody>
      </table>
      <div>
        <p style={{paddingTop:"10px"}}>
          The Course outline designed by the Higher Education Commission – HEC,
          can be obtained from this link
          https://hec.gov.pk/english/services/universities/RevisedCurricula/Documents/2016-
          2017/COMPUTER-SCIENCE.pdf
        </p>
        <h6 className="bolder text-left" style={{ marginTop: "60px" }}>
          I/C {TableList?.[0]?.FacultyDepartment}
        </h6>
      </div>
      <footer style={{ borderTop: "1px solid black" }}>
        <p className="text-right">Department of {TableList?.[0]?.FacultyDepartment}</p>
      </footer>
    </div>
  );
});

export default SubjectAllotmentReport;
