import React, { forwardRef } from "react";
import { Table } from "reactstrap";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import Logo from "../../assets/img/logo.png";
import { formatDateFunc } from "../../functions/DateFormatFunction";

const ChallanForEnrollment = forwardRef((props, ref) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);

  return (
    // <div className="landscape-page" ref={ref}>
    <div ref={ref}>
    <div style={{ margin: "20px", marginTop: "20px" }}>
      <table>
        <tbody>
          <tr>
            <td>
              <div>
                <Table
                  bordered
                  responsive
                  style={{
                    width: "100%",
                    border: "1px solid",
                    lineHeight: "0.30",
                    fontSize: "11px",
                  }}
                >
                  <tbody>
                    <tr className="text-center">
                      <td colSpan={7}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div className="login-brand mt-1">
                            <QRCode
                              value={`${props?.BPvoucherId?.Table?.[0]?.p_ConsumerNumber}`}
                              size="60"
                            />
                          </div>
                          <div className="login-brand">
                            <img height="70" src={Logo} alt="Logo" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>Student Copy</td>
                    </tr>
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                        colSpan={7}
                      >
                        Bank Challan
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td
                        colSpan={2}
                        style={{
                          width: "35%",
                          textAlign: "center",
                          justifyContent: "center",
                          lineHeight: "10px",
                        }}
                        className="text-center"
                      >
                        Due Date
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          width: "55%",
                          textAlign: "center",
                          fontWeight: "bold",
                          justifyContent: "center",
                          lineHeight: "10px",
                        }}
                      >
                        {
                          // props?.BPvoucherId?.Table?.[0].p_BillingMonth
                          formatDateFunc(
                            props?.BPvoucherId?.Table?.[0].p_DueDate,
                            "-"
                          )
                        }
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          width: "20%",
                          fontSize: 10,
                          textAlign: "center",
                          lineHeight: "10px",
                          justifyContent: "center",
                        }}
                      >
                        No: {props?.BPvoucherId?.Table?.[0]?.p_ConsumerNumber}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        colSpan={7}
                        className="text-center"
                      >
                        Habib Bank Limited
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-center"
                        style={{ alignContent: "center" }}
                        colSpan={7}
                      >
                        <p style={{ marginTop: "18px" }}>
                          Please deposite your fees through HBL connect,{" "}
                        </p>
                        <p> HBL Mobile Banking. </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>Name:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.p_CustomerName}
                        {console.log(props?.BPvoucherId?.Table?.[0]?.p_CustomerName)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>S/O,D/O:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.FatherName}
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>Department:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.DepartmentName}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "30%", verticalAlign: "middle" }}
                        colSpan={2}
                      >
                        Signature:
                      </td>
                      <td colSpan={2}></td>
                      <td
                        style={{ width: "10%", verticalAlign: "middle" }}
                        colSpan={1}
                      >
                        Program:
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          width: "50%",
                          fontSize: 10,
                          lineHeight: "10px",
                        }}
                        colSpan={3}
                      >
                        {
                          props?.BPvoucherId?.Table?.[0]
                            ?.FacultyDepartmentProgram
                        }
                      </td>
                    </tr>
                    <tr style={{ lineHeight: "15px", padding: 10 }}>
                      <td style={{ fontWeight: "bold" }} colSpan={7}>
                        C.N.I.C/I.D Card No:/Admission No:{" "}
                        {props?.BPvoucherId?.Table?.[0]?.Reg_No}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "70%", lineHeight: "10px" }}
                        colSpan={4}
                      >
                        Purpose of Deposit Detalis on reverse:
                      </td>
                      <td style={{ width: "30%" }} colSpan={4}>
                        Rs.
                      </td>
                    </tr>
                    {props?.BPvoucherId?.Table1?.map((x, ind) => (
                      <tr style={{ verticalAlign: "middle" }}>
                        <td
                          colSpan={4}
                          style={{
                            lineHeight:
                              x?.Particular?.length > 17 ? "10px" : "0px",
                          }}
                        >
                          {ind + 1}
                          {")"} {x.Particular}
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {x.Amount}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        style={{ fontWeight: "bold", textAlign: "end" }}
                        colSpan={4}
                      >
                        Total Amount =
                      </td>
                      <td
                        colSpan={4}
                        style={{ fontSize: "9px", fontWeight: "bold" }}
                      >
                        {props?.BPvoucherId?.Table?.[0]?.p_Amount}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "8px",
                          lineHeight: "10px",
                        }}
                        colSpan={7}
                      >
                        Rupees (in words) :{" "}
                        {props?.BPvoucherId?.Table?.[0]?.AmInWords}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <table
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{ textAlign: "left", width: "50px" }}
                        colSpan={2}
                      >
                        <p>Cashier</p>
                      </td>
                      <td
                        style={{ textAlign: "right", width: "50px" }}
                        colSpan={2}
                      >
                        <p>Manager </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>

            <div style={{ margin: "3px" }}></div>

            <td>
              <div>
                <Table
                  bordered
                  responsive
                  style={{
                    width: "100%",
                    border: "1px solid",
                    lineHeight: "0.30",
                    fontSize: "11px",
                  }}
                >
                  <tbody>
                    <tr className="text-center">
                      <td colSpan={7}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div className="login-brand mt-1">
                            <QRCode
                              value={`${props?.BPvoucherId?.Table?.[0]?.p_ConsumerNumber}`}
                              size="60"
                            />
                          </div>
                          <div className="login-brand">
                            <img height="70" src={Logo} alt="Logo" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>Bank Copy</td>
                    </tr>
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                        colSpan={7}
                      >
                        Bank Challan
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td
                        colSpan={2}
                        style={{
                          width: "35%",
                          textAlign: "center",
                          justifyContent: "center",
                          lineHeight: "10px",
                        }}
                        className="text-center"
                      >
                        Due Date
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          width: "55%",
                          textAlign: "center",
                          fontWeight: "bold",
                          justifyContent: "center",
                          lineHeight: "10px",
                        }}
                      >
                        {formatDateFunc(
                          props?.BPvoucherId?.Table?.[0].p_DueDate,
                          "-"
                        )}
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          width: "20%",
                          fontSize: 10,
                          textAlign: "center",
                          lineHeight: "10px",
                          justifyContent: "center",
                        }}
                      >
                        No: {props?.BPvoucherId?.Table?.[0]?.p_ConsumerNumber}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        colSpan={7}
                        className="text-center"
                      >
                        Habib Bank Limited
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center" colSpan={7}>
                        <p style={{ marginTop: "18px" }}>
                          Please deposite your fees through HBL connect,{" "}
                        </p>
                        <p> HBL Mobile Banking. </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>Name:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.p_CustomerName}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>S/O,D/O:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.FatherName}
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>Department:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.DepartmentName}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "30%", verticalAlign: "middle" }}
                        colSpan={2}
                      >
                        Signature:
                      </td>
                      <td colSpan={2}></td>
                      <td
                        style={{ width: "10%", verticalAlign: "middle" }}
                        colSpan={2}
                      >
                        Program:
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          width: "50%",
                          fontSize: 10,
                          lineHeight: "10px",
                        }}
                        colSpan={2}
                      >
                        {
                          props?.BPvoucherId?.Table?.[0]
                            ?.FacultyDepartmentProgram
                        }
                      </td>
                    </tr>
                    <tr style={{ lineHeight: "15px", padding: 10 }}>
                      <td style={{ fontWeight: "bold" }} colSpan={7}>
                        C.N.I.C/I.D Card No:/Admission No:{" "}
                        {props?.BPvoucherId?.Table?.[0]?.Reg_No}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "70%", lineHeight: "10px" }}
                        colSpan={4}
                      >
                        Purpose of Deposit Detalis on reverse:
                      </td>
                      <td style={{ width: "30%" }} colSpan={4}>
                        Rs.
                      </td>
                    </tr>
                    {props?.BPvoucherId?.Table1?.map((x, ind) => (
                      <tr style={{ verticalAlign: "middle" }}>
                        <td
                          colSpan={4}
                          style={{
                            lineHeight:
                              x?.Particular?.length > 17 ? "10px" : "0px",
                          }}
                        >
                          {ind + 1}
                          {")"} {x.Particular}
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {x.Amount}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td
                        style={{ fontWeight: "bold", textAlign: "end" }}
                        colSpan={4}
                      >
                        Total Amount =
                      </td>
                      <td
                        colSpan={4}
                        style={{ fontSize: "9px", fontWeight: "bold" }}
                      >
                        {props?.BPvoucherId?.Table?.[0]?.p_Amount}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "8px",
                          lineHeight: "10px",
                        }}
                        colSpan={7}
                      >
                        Rupees (in words) :{" "}
                        {props?.BPvoucherId?.Table?.[0]?.AmInWords}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <table
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{ textAlign: "left", width: "50px" }}
                        colSpan={2}
                      >
                        <p>Cashier</p>
                      </td>
                      <td
                        style={{ textAlign: "right", width: "50px" }}
                        colSpan={2}
                      >
                        <p>Manager </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>

            <div style={{ margin: "3px" }}></div>

            <td>
              <div>
                <Table
                  bordered
                  responsive
                  style={{
                    width: "100%",
                    border: "1px solid",
                    lineHeight: "0.30",
                    fontSize: "11px",
                  }}
                >
                  <tbody>
                    <tr className="text-center">
                      <td colSpan={7}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div className="login-brand mt-1">
                            <QRCode
                              value={`${props?.BPvoucherId?.Table?.[0]?.p_ConsumerNumber}`}
                              size="60"
                            />
                          </div>
                          <div className="login-brand">
                            <img height="70" src={Logo} alt="Logo" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>University Copy</td>
                    </tr>
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                        colSpan={7}
                      >
                        Bank Challan
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td
                        colSpan={2}
                        style={{
                          width: "35%",
                          textAlign: "center",
                          justifyContent: "center",
                          lineHeight: "10px",
                        }}
                        className="text-center"
                      >
                        Due Date
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          width: "55%",
                          textAlign: "center",
                          fontWeight: "bold",
                          justifyContent: "center",
                          lineHeight: "10px",
                        }}
                      >
                        {formatDateFunc(
                          props?.BPvoucherId?.Table?.[0].p_DueDate,
                          "-"
                        )}
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          width: "20%",
                          fontSize: 10,
                          textAlign: "center",
                          lineHeight: "10px",
                          justifyContent: "center",
                        }}
                      >
                        No: {props?.BPvoucherId?.Table?.[0]?.p_ConsumerNumber}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ fontWeight: "bold" }}
                        colSpan={7}
                        className="text-center"
                      >
                        Habib Bank Limited
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center" colSpan={7}>
                        <p style={{ marginTop: "18px" }}>
                          Please deposite your fees through HBL connect,{" "}
                        </p>
                        <p> HBL Mobile Banking. </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>Name:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.p_CustomerName}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>S/O,D/O:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.FatherName}
                      </td>
                    </tr>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td colSpan={7}>
                        <b style={{ fontWeight: "bold" }}>Department:</b>{" "}
                        {props?.BPvoucherId?.Table?.[0]?.DepartmentName}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "30%", verticalAlign: "middle" }}
                        colSpan={2}
                      >
                        Signature:
                      </td>
                      <td colSpan={2}></td>
                      <td
                        style={{ width: "10%", verticalAlign: "middle" }}
                        colSpan={2}
                      >
                        Program:
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          width: "50%",
                          fontSize: 10,
                          lineHeight: "10px",
                        }}
                        colSpan={2}
                      >
                        {
                          props?.BPvoucherId?.Table?.[0]
                            ?.FacultyDepartmentProgram
                        }
                      </td>
                    </tr>
                    <tr style={{ lineHeight: "15px", padding: 10 }}>
                      <td style={{ fontWeight: "bold" }} colSpan={7}>
                        C.N.I.C/I.D Card No:/Admission No:{" "}
                        {props?.BPvoucherId?.Table?.[0]?.Reg_No}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: "70%", lineHeight: "10px" }}
                        colSpan={4}
                      >
                        Purpose of Deposit Detalis on reverse:
                      </td>
                      <td style={{ width: "30%" }} colSpan={4}>
                        Rs.
                      </td>
                    </tr>
                    {props?.BPvoucherId?.Table1?.map((x, ind) => (
                      <tr style={{ verticalAlign: "middle" }}>
                        <td
                          colSpan={4}
                          style={{
                            lineHeight:
                              x?.Particular?.length > 17 ? "10px" : "0px",
                          }}
                        >
                          {ind + 1}
                          {")"} {x.Particular}
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {x.Amount}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td
                        style={{ fontWeight: "bold", textAlign: "end" }}
                        colSpan={4}
                      >
                        Total Amount =
                      </td>
                      <td
                        colSpan={4}
                        style={{ fontSize: "9px", fontWeight: "bold" }}
                      >
                        {props?.BPvoucherId?.Table?.[0]?.p_Amount}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "8px",
                          lineHeight: "10px",
                        }}
                        colSpan={7}
                      >
                        Rupees (in words) :{" "}
                        {props?.BPvoucherId?.Table?.[0]?.AmInWords}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <table
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{ textAlign: "left", width: "50px" }}
                        colSpan={2}
                      >
                        <p>Cashier</p>
                      </td>
                      <td
                        style={{ textAlign: "right", width: "50px" }}
                        colSpan={2}
                      >
                        <p>Manager </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
});

export default ChallanForEnrollment;
