import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  admissionFacultyTypeId,
  admissionTypeId,
  boardId,
  campusCity,
  campusType,
  examinationId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  majorsAdId,
  programTypes,
  Search,
  SessionStorage,
  totalMarksId,
  undergraduateId,
  yearId,
} from "../../../common/SetupMasterEnum";
import { CustomErrorMessage } from "../../../components/Alert";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../EncryptData";
import { formatDateFunction2 } from "../../../functions/DateFormatFunction";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
  ADM_EligibilityCriteriaDependency,
  PostRequest,
  PostFormRequest,
} from "../../../utils/Config";
import {
  ADMISSION_USER_ID,
  EMPLOYEE_NAME,
  LAST_NAME,
  REG_ID,
  MIDDLE_NAME,
  EMAIL,
  CNIC_BFORM,
  MOBILE,
} from "../../../utils/EncryptedConstants";
import {
  bs1Id,
  mainCampusId,
  mainCityId,
  morningId,
  programTypeId,
} from "../../../common/AdmissionEnum";
import { checkUGAcademicsList } from "../../../functions/checkUGAcademicsList";
import { ADMISSION_CREATEADMISSIONRECORD, ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../../utils/UrlConstants";

const initialFormFields = {
  AdmARID: 0,
  ADMRegID: 0,
  ExaminationID: 0,
  DegreeName: "",
  MajorSubjects: "",
  YearId: 0,
  SeatNo_RollNumber: "",
  ObtainedMarks: "",
  TotalMarks: "",
  BoardID: 0,
  University: "",
  DepartmentName: "",
  DocumentPath: {},
  DocumentPathNew: "",
  FileName: "",
  UserIP: "192.168.14.1",
};

const Academics = (props) => {
  initialFormFields.ADMRegID = decryptData(REG_ID, SessionStorage);
  const dispatch = useDispatch();
  const initialProgramAppliedForFields = {
    languageId: 0,
    Year: 0,
    WhatsAppNo: "",
    AlternativeNumber: "",
    LastDegreeFromSalu: "",
    Other: "",
    ProgramId: props.Programid,
    CampusID: mainCampusId,
    CampusCityId: mainCityId,
    ProgramTypeId: morningId,
    AdmissionTypeId: bs1Id,
    MajorId: 0,
    AdmissionFacultyTypeId: 0,
    AdmissionFacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    ShiftId: 0,
    Name: `${decryptData(EMPLOYEE_NAME, SessionStorage)} ${
      decryptData(MIDDLE_NAME, SessionStorage) === ""
        ? ""
        : decryptData(MIDDLE_NAME, SessionStorage)
    } ${
      decryptData(LAST_NAME, SessionStorage) === ""
        ? ""
        : decryptData(LAST_NAME, SessionStorage)
    }`,
    FatherName: "",
    SurName: "",
    GenderID: 0,
    GuardianName: "",
    Cnic: decryptData(CNIC_BFORM, SessionStorage),
    Picture: "",
    DOB: new Date(),
    PostalAddress: "",
    PermanantAddress: "",
    CorrespondatAddressId: 0,
    NationalityID: 0,
    ReligionID: 0,
    DomicileID: 0,
    DomicileDistricId: 0,
    Ph: "",
    Mobile: decryptData(MOBILE, SessionStorage),
    Email: decryptData(EMAIL, SessionStorage),
    AreEmployeedAnyWhere: 0,
    EmployeedDepartment: "",
    NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase: 0,
    UnderTaking: 0,
    UserIP: "192.168.14.1",
    InterestIn: "",
  };
  const imageInputRef = React.useRef();
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const personalInfoData = SupportingTables?.students?.Table1[0];
  const academicRecords = SupportingTables?.students?.Table2;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState(initialFormFields);
  const [programAppliedFor, setProgramAppliedFor] = useState({
    ...initialProgramAppliedForFields,
    ...personalInfoData,
    CampusID: mainCampusId,
    CampusCityId: mainCityId,
    ProgramTypeId: morningId,
    AdmissionTypeId: bs1Id,
  });
  const [updateButton, setUpdateButton] = useState(false);
  const [academicData, setAcademicData] = useState([]);
  const [editValue, setEditValue] = useState(false);
  const [majorValue, setMajorValue] = useState(personalInfoData?.MajorName);
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleProgramAppliedForChange = (event) => {
    if (event.target.name == "AdmissionTypeId") {
      if (event.target.value != 4012 || event.target.value != 4014) {
        setMajorValue("");
        formFields.MajorSubjects = "";
        formFields.ExaminationID = 0;
      }
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
        MajorId: 0,
        AdmissionFacultyTypeId: 0,
        AdmissionFacultyDepartmentId: 0,
        FacultyDepartmentProgramId: 0,
      });
    } else if (event.target.name == "MajorId") {
      formFields.MajorSubjects = "";
      formFields.ExaminationID = 0;
      if (
        programAppliedFor.AdmissionTypeId == 4012 ||
        programAppliedFor.AdmissionTypeId == 4014
      ) {
        setMajorValue(event.target.selectedValue);
      } else {
        setMajorValue("");
      }
      let data = {
        name: "MajorId",
        value: event.target.value,
      };
      dispatch({
        type: SET_INITIAL_DROPDOWN_FORM_STATE,
        payload: data,
      });

      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
        operationID: Search,
        caseID: 2,
        paremeterID: event.target.value,
      });
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
        AdmissionFacultyTypeId: 0,
        AdmissionFacultyDepartmentId: 0,
        FacultyDepartmentProgramId: 0,
      });
      let data2 = { name: "FacultyDepartmentProgramId", value: [] };
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data2 });
    }
    // else if (event.target.name == "AdmissionFacultyTypeId") {

    //   setProgramAppliedFor({
    //     ...programAppliedFor,
    //     [event.target.name]: event.target.value,
    //     AdmissionFacultyDepartmentId: 0,
    //     FacultyDepartmentProgramId: 0,
    //   });
    // }
    else if (event.target.name == "AdmissionFacultyDepartmentId") {
      if (event.target.value === 4082 || event.target.value === 4096) {
        if (SupportingTables?.DomicileID === 3603) {
          onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
            operationID: Search,
            caseID: 3,
            paremeterID: event.target.value,
          });
          setProgramAppliedFor({
            ...programAppliedFor,
            FacultyDepartmentProgramId: 0,
            [event.target.name]: event.target.value,
          });
        } else {
          CustomErrorMessage(
            "Candidates other than Sindh Domicile are not eligible to apply in this Program"
          );
        }
      } else {
        onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
          operationID: Search,
          caseID: 3,
          paremeterID: event.target.value,
        });
        setProgramAppliedFor({
          ...programAppliedFor,
          FacultyDepartmentProgramId: 0,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
      });
    }
  };

  const onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency = (
    payload
  ) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
        PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID == 1) {
            let data = { name: "MajorIds", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID == 2) {
            let data = { name: "FacultyDepartment", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else {
            let data = { name: "FacultyDepartmentProgramId", value: res.data };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const handleInputChangeSelect = (event) => {
    if (event.target.name === "ExaminationID") {
      if (event.target.value === 3590) {
        setFormFields({
          ...formFields,
          [event.target.name]: event.target.value,
          [event.target.selectedName]:
            event.target.selectedName != undefined &&
            event.target.selectedValue,
          MajorSubjects: majorValue,
        });
      } else {
        setFormFields({
          ...formFields,
          [event.target.name]: event.target.value,
          [event.target.selectedName]:
            event.target.selectedName != undefined &&
            event.target.selectedValue,
          MajorSubjects: "",
        });
      }
    } else {
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
        [event.target.selectedName]:
          event.target.selectedName != undefined && event.target.selectedValue,
      });
    }
  };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  useEffect(() => {
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 1,
      paremeterID: undergraduateId,
    });

    setAcademicData(academicRecords);
  }, [academicRecords]);
  useEffect(() => {
    setProgramAppliedFor({
      ...initialProgramAppliedForFields,
      ...personalInfoData,
      CampusID: mainCampusId,
      CampusCityId: mainCityId,
      ProgramTypeId: morningId,
      AdmissionTypeId: bs1Id,
    });
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 2,
      paremeterID: personalInfoData?.MajorId,
    });
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 3,
      paremeterID: personalInfoData?.AdmissionFacultyDepartmentId,
    });
  }, [personalInfoData]);

  const onAdd = (e) => {
    e.preventDefault();
    e.target["DocumentPath"].value = [];
    if (formFields.index > -1) {
      if (
        parseInt(formFields.ObtainedMarks) > parseInt(formFields.TotalMarks)
      ) {
        CustomErrorMessage("Obtained marks should be less then Total marks");
      } else if (formFields?.ExaminationID == 3590) {
        //inter examination id
        if (academicData.some((x) => x.ExaminationID == 3589)) {
          //f matric examination id present
          let matricId = academicData
            .filter((x) => x.ExaminationID == 3589)
            .map((y) => y.YearId);
          if (matricId[0] < formFields.YearId) {
            academicData[formFields.index] = formFields;
            setEditValue(false);
            setAcademicData([...academicData]);
            setFormFields({
              ...initialFormFields,
            });
          } else {
            CustomErrorMessage(
              "Intermediate or DAE record date has to be greater than the Matriculation record date"
            );
          }
        } else {
          academicData[formFields.index] = formFields;
          setEditValue(false);
          setAcademicData([...academicData]);
          setFormFields({
            ...initialFormFields,
          });
        }
      } else if (formFields?.ExaminationID == 3589) {
        //matric examination id
        if (academicData.some((x) => x.ExaminationID == 3590)) {
          //f intermediate examination id present
          let interId = academicData
            .filter((x) => x.ExaminationID == 3590)
            .map((y) => y.YearId);
          if (interId[0] > formFields.YearId) {
            academicData[formFields.index] = formFields;
            setEditValue(false);
            setAcademicData([...academicData]);
            setFormFields({
              ...initialFormFields,
            });
          } else {
            CustomErrorMessage(
              "Matriculation record date has to be less than the Intermediate or DAE record date"
            );
          }
        } else {
          setEditValue(false);
          academicData[formFields.index] = formFields;
          setAcademicData([...academicData]);
          setFormFields({
            ...initialFormFields,
          });
        }
      } else {
        setEditValue(false);
        academicData[formFields.index] = formFields;
        setAcademicData([...academicData]);
        setFormFields({
          ...initialFormFields,
        });
      }
    } else {
      if (
        parseInt(formFields.ObtainedMarks) > parseInt(formFields.TotalMarks)
      ) {
        CustomErrorMessage("Obtained marks should be less then total marks");
      } else {
        if (
          academicData.some(
            (x) =>
              x.ExaminationID == formFields.ExaminationID &&
              x.ExaminationID != 3703
          )
        ) {
          CustomErrorMessage("Cannot Insert same record multiple times");
        } else {
          if (formFields.ExaminationID === 3590) {
            //intermediateID

            // new Condition

            if (academicData.some((x) => x.ExaminationID === 3589)) {
              //matricID
              let matricId = academicData
                .filter((x) => x.ExaminationID === 3589)
                .map((y) => y.YearId);
              if (formFields.YearId > matricId[0]) {
                academicData.push({
                  ...formFields,
                  index: -1,
                });
                setAcademicData([...academicData]);
                setFormFields({
                  ...initialFormFields,
                });
              } else {
                CustomErrorMessage(
                  "Intermediate or DAE record date has to be greater than the Matriculation record date"
                );
              }
            } else {
              academicData.push({
                ...formFields,
                index: -1,
              });
              setAcademicData([...academicData]);
              setFormFields({
                ...initialFormFields,
              });
            }
          } else if (formFields.ExaminationID === 3589) {
            //matricID
            if (academicData.some((x) => x.ExaminationID === 3590)) {
              //interID
              let interId = academicData
                .filter((x) => x.ExaminationID === 3590)
                .map((y) => y.YearId);
              if (formFields.YearId < interId[0]) {
                academicData.push({
                  ...formFields,
                  index: -1,
                });
                setAcademicData([...academicData]);
                setFormFields({
                  ...initialFormFields,
                });
              } else {
                CustomErrorMessage(
                  "Matriculation record date has to be less than the Intermediate or DAE record date"
                );
              }
            } else {
              academicData.push({
                ...formFields,
                index: -1,
              });
              setAcademicData([...academicData]);
              setFormFields({
                ...initialFormFields,
              });
            }
          } else {
            academicData.push({
              ...formFields,
              index: -1,
            });
            setAcademicData([...academicData]);
            setFormFields({
              ...initialFormFields,
            });
          }
        }
      }
    }
  };

  const onEdit = (index, obj) => {
    setFormFields({
      ...obj,
      index: index,
    });
    setEditValue(true);
  };
  const onDelete = (index) => {
    academicData.splice(index, 1);
    setAcademicData([...academicData]);
  };
  const onCancel = () => {
    document.getElementById("documentPath").value = [];
    setFormFields({
      ...initialFormFields,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();
    // var valueArr = academicData?.map(function (item) {
    //   return item.ExaminationID;
    // });
    // var isDuplicate = valueArr?.some(function (item, idx) {
    //   return valueArr.indexOf(item) != idx;
    // });
    setUpdateButton(true);
    if (statusData[0]?.PersonalInfo == 0) {
      CustomErrorMessage("Kindly Complete Admission Application Step Wise");
      setUpdateButton(false);
    } else if (academicData.length == 0) {
      CustomErrorMessage("Please Add Academic Information");
      setUpdateButton(false);
    }
    // else if (isDuplicate === true) {
    //   CustomErrorMessage("Cannot Insert same record multiple times");
    // }
    else if (checkUGAcademicsList(academicData) == false) {
      CustomErrorMessage(
        "It is mandatory to add matriculation and Intermediate record"
      );
      setUpdateButton(false);
    } else {
      if (programAppliedFor.AdmissionFacultyTypeId === 4435) {
        if (academicData?.some((x) => x.ExaminationID === 3702)) {
          let formData = new FormData();
          Object.keys(basicValues).forEach((d) => {
            formData.append(d, basicValues[d]);
          });
          Object.keys(programAppliedFor).forEach((d) => {
            formData.append(
              `ADMPersonalInformation_.${d}`,
              programAppliedFor[d]
            );
          });
          academicData.forEach((element, index) => {
            Object.keys(element).forEach((item) => {
              formData.append(
                `ADMAcademicRecords_[${index}].${item}`,
                element[item]
              );
            });
          });
          updateAdmission(formData);
        } else {
          CustomErrorMessage(
            "Providing LAT record is mandatory for candidate applying in Law"
          );
          setUpdateButton(false);
        }
      } else {
        let formData = new FormData();
        Object.keys(basicValues).forEach((d) => {
          formData.append(d, basicValues[d]);
        });
        Object.keys(programAppliedFor).forEach((d) => {
          formData.append(`ADMPersonalInformation_.${d}`, programAppliedFor[d]);
        });
        academicData.forEach((element, index) => {
          Object.keys(element).forEach((item) => {
            formData.append(
              `ADMAcademicRecords_[${index}].${item}`,
              element[item]
            );
          });
        });
        updateAdmission(formData);
      }
    }
  };

  const updateAdmission = (payload) => {
    if (statusData.length != 0) {
      // Admision_CreateAdmissionRecord(payload)
      PostFormRequest(ADMISSION_CREATEADMISSIONRECORD, payload)
        .then((res) => {
          // setSubmitLoad(true);
          if (res?.data?.Table[0]?.HasError === 0) {
            // setSubmitLoad(false);
            Swal.fire({
              title: "Success",
              icon: "success",
              text: res?.data?.Table[0]?.MESSAGE,
            }).then((results) => {
              if (results.isDismissed) {
                getStudendRecords();
              } else if (results.isConfirmed) {
                getStudendRecords();
              }
            });
          } else {
            // setSubmitLoad(false);
            Swal.fire({
              title: "Error",
              icon: "error",
              text: res?.data?.Table[0]?.MESSAGE,
            });
            setUpdateButton(false);
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Something Went Wrong",
          });
          setUpdateButton(false);
        });
    } else {
      CustomErrorMessage("Kindly Complete Application Step Wise");
      setUpdateButton(false);
    }
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: 3479,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
          setUpdateButton(false);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setUpdateButton(false);
      });
  };

  const handleDocChange = (event) => {
    setFormFields({
      ...formFields,
      DocumentPathNew: URL.createObjectURL(event.target.files[0]),
      DocumentPath: event.target.files[0],
      FileName: event.target.files[0].name,
    });
  };

  return (
    <Fragment>
      <form id="Form1" onSubmit={onUpdate}></form>
      <form id="Form2" onSubmit={onAdd}></form>
      {/* <form onSubmit={(e) => onAdd(e)}> */}
      <Card className="mt-3">
        <CardTitle>Program Applying For</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>
              Please select Faculty &amp; Department as per Advertisement.
            </strong>
          </div>
          <Row>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Campus Type"
                name="CampusID"
                list={SupportingTables?.tables?.filter(
                  (x) => x.SetupMasterId == campusType
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={programAppliedFor?.CampusID}
                onChange={handleProgramAppliedForChange}
                form="Form1"
                required
                disabled
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Campus City"
                name="CampusCityId"
                list={SupportingTables?.tables?.filter(
                  (x) =>
                    x.SetupMasterId == campusCity &&
                    x.parentid == programAppliedFor?.CampusID
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={programAppliedFor?.CampusCityId}
                onChange={handleProgramAppliedForChange}
                form="Form1"
                required
                disabled
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Program Types"
                name="ProgramTypeId"
                list={SupportingTables?.tables?.filter(
                  (x) =>
                    x.SetupMasterId == programTypes &&
                    // x.SetupDetailId != 3698 &&
                    x.parentid == programAppliedFor?.CampusCityId
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={programAppliedFor?.ProgramTypeId}
                onChange={handleProgramAppliedForChange}
                form="Form1"
                required
                disabled
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Admission Type"
                name="AdmissionTypeId"
                list={SupportingTables?.tables?.filter(
                  (x) =>
                    x.SetupMasterId == admissionTypeId &&
                    x.parentid == programAppliedFor?.ProgramTypeId
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={programAppliedFor?.AdmissionTypeId}
                onChange={handleProgramAppliedForChange}
                form="Form1"
                required
                disabled
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupSelect
                label={`${
                  programAppliedFor?.AdmissionTypeId == 4012 ||
                  programAppliedFor?.AdmissionTypeId == 4014
                    ? "Intermediate Majors"
                    : "Bachelors Majors"
                }`}
                name="MajorId"
                list={SupportingTables?.MajorIds?.Table}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={programAppliedFor?.MajorId}
                onChange={handleProgramAppliedForChange}
                form="Form1"
                required
                disabled={
                  programAppliedFor?.AdmissionTypeId == 0 ? true : false
                }
              />
            </Col>

            <Col md="3" lg="3">
              <FormGroupSelect
                label="Faculty Department"
                name="AdmissionFacultyDepartmentId"
                list={SupportingTables?.FacultyDepartment?.Table}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={programAppliedFor?.AdmissionFacultyDepartmentId}
                onChange={handleProgramAppliedForChange}
                form="Form1"
                required
                disabled={programAppliedFor?.MajorId == 0 ? true : false}
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Faculty Department Program"
                name="FacultyDepartmentProgramId"
                list={SupportingTables?.FacultyDepartmentProgramId?.Table}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={programAppliedFor?.FacultyDepartmentProgramId}
                onChange={handleProgramAppliedForChange}
                form="Form1"
                required
                // disabled={
                //   programAppliedFor?.AdmissionFacultyDepartmentId == null
                // }
                disabled={
                  programAppliedFor?.MajorId == 0 ||
                  programAppliedFor?.AdmissionFacultyDepartmentId == 0
                    ? true
                    : false
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Academic Details</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>
              It is mandatory to provide Matriculation/O levels and
              Intermediate/DAE/A Levels Records. In case you have more than one
              document for O levels, A levels etc then you should upload a
              combined pdf file showing all relevant documents in one file.
              Candidates having less than 50% in Intermdiate/DAE are not
              eligible to apply.
            </strong>
          </div>
          <Row>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Examination"
                name="ExaminationID"
                list={SupportingTables?.tables
                  ?.filter(
                    (x) =>
                      x.SetupMasterId == examinationId &&
                      x.SetupDetailId != 3856 &&
                      x.SetupDetailId != 3852 &&
                      x.SetupDetailId != 3853 &&
                      x.SetupDetailId != 3854 &&
                      x.SetupDetailId != 3855
                  )
                  .sort((a, b) => a.Flex1 - b.Flex1)}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={formFields?.ExaminationID}
                onChange={handleInputChangeSelect}
                nameValue="ExaminationName"
                form="Form2"
                required
              />
            </Col>
            {formFields?.ExaminationID == 3703 ? (
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Degree Name"
                  name="DegreeName"
                  value={formFields?.DegreeName}
                  onChange={handleInputChange}
                  form="Form2"
                  required
                />
              </Col>
            ) : null}
            <Col md="3" lg="3">
              <FormGroupInput
                label="Group/ Major Subjects"
                name="MajorSubjects"
                value={
                  // formFields?.ExaminationID === 3590
                  //   ? majorValue
                  //   :
                  formFields?.MajorSubjects
                }
                onChange={handleInputChange}
                form="Form2"
                disabled={
                  (programAppliedFor?.AdmissionTypeId == 4012 ||
                    programAppliedFor?.AdmissionTypeId == 4014) &&
                  formFields?.ExaminationID === 3590
                    ? true
                    : false
                }
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Year"
                name="YearId"
                list={
                  (programAppliedFor?.AdmissionTypeId == 4012 ||
                    programAppliedFor?.AdmissionTypeId == 4014) &&
                  formFields?.ExaminationID == 3590
                    ? SupportingTables?.tables
                        ?.filter(
                          (x) =>
                            x.SetupMasterId == yearId &&
                            x.SetupDetailId != 2415 &&
                            x.SetupDetailId != 2417 &&
                            x.SetupDetailId != 2416 &&
                            x.SetupDetailId != 2418 &&
                            x.SetupDetailId != 3704 &&
                            x.SetupDetailId != 3705 &&
                            x.SetupDetailId != 3706 &&
                            x.SetupDetailId != 3707 &&
                            x.SetupDetailId != 3708
                        )
                        .sort((a, b) => a.Flex1 - b.Flex1)
                    : (programAppliedFor?.AdmissionTypeId == 4013 ||
                        programAppliedFor?.AdmissionTypeId == 4015 ||
                        programAppliedFor?.AdmissionTypeId == 4016) &&
                      formFields?.ExaminationID == 3590
                    ? SupportingTables?.tables
                        ?.filter(
                          (x) =>
                            x.SetupMasterId == yearId &&
                            x.SetupDetailId != 3711 &&
                            x.SetupDetailId != 3712
                        )
                        .sort((a, b) => a.Flex1 - b.Flex1)
                    : SupportingTables?.tables
                        ?.filter((x) => x.SetupMasterId == yearId)
                        .sort((a, b) => a.Flex1 - b.Flex1)
                }
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={formFields?.YearId}
                onChange={handleInputChangeSelect}
                nameValue="YearName"
                form="Form2"
                required
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupInput
                label="Seat No/ Roll No"
                name="SeatNo_RollNumber"
                value={formFields?.SeatNo_RollNumber}
                onChange={handleInputChange}
                form="Form2"
                required
                isNumber="true"
                maxLength="10"
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupInput
                label="Obtained Marks"
                name="ObtainedMarks"
                value={formFields?.ObtainedMarks}
                onChange={handleInputChange}
                form="Form2"
                isNumber="true"
                required
                maxLength="5"
              />
            </Col>
            {/* {formFields?.ExaminationID == 3591 ? ( */}
            <Col md="3" lg="3">
              <FormGroupInput
                label="Total Marks"
                name="TotalMarks"
                value={formFields?.TotalMarks}
                onChange={handleInputChange}
                form="Form2"
                isNumber="true"
                maxLength="5"
                required
              />
            </Col>
            {/* ) : (
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Total Marks"
                    name="TotalMarksId"
                    list={SupportingTables?.tables?.filter(
                      (x) => x.SetupMasterId == totalMarksId
                    )}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={formFields?.TotalMarksId}
                    onChange={handleInputChangeSelect}
                    nameValue="TotalMarksName"
                  />
                </Col>
              )} */}
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Board"
                name="BoardID"
                list={SupportingTables?.tables?.filter(
                  (x) => x.SetupMasterId == boardId
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={formFields?.BoardID}
                onChange={handleInputChangeSelect}
                form="Form2"
                nameValue="BoardName"
                required={
                  formFields.ExaminationID === 3702 ||
                  formFields.ExaminationID === 3703 ||
                  formFields?.ExaminationID === 3591 ||
                  formFields?.ExaminationID === 3701
                    ? false
                    : true
                }
              />
            </Col>
            <Col md="3" lg="3">
              <FormGroupInput
                label="School/College/Institute/University"
                name="University"
                value={formFields?.University}
                onChange={handleInputChange}
                form="Form2"
                required
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              {/* <FormGroupInput
                  label="Upload Attachment"
                  type="file"
                  name="UploadAttachment"
                  // value={uploadAttach.name}
                  onChange={handleDocChange}
                  required
                /> */}
              <div className="form-group">
                <label className="form-label">
                  Upload Marksheet<span className="text-danger">*</span>
                </label>
                <Input
                  id="documentPath"
                  type="file"
                  name="DocumentPath"
                  onChange={handleDocChange}
                  form="Form2"
                  accept=".pdf/,image/jpeg,image/jpg,image/png,text/csv,.docx,.doc,.xls,.txt"
                  required
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button form="Form2" color="secondary" type="submit">
                  {editValue === true ? "Edit" : "Add"}
                </Button>
                <Button type="button" onClick={onCancel} color="default">
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* </form> */}
      <Card className="mt-3">
        <CardTitle>Academic Records</CardTitle>
        <CardBody>
          <div className="table-responsive">
            <Row>
              <Col>
                <div>
                  <table className="table table-striped mb-0 mt-2">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Examination</th>
                        <th className="text-center">Degree</th>
                        <th className="text-center">Group/ Major Subject</th>
                        <th className="text-center">Year</th>
                        <th className="text-center">Seat No</th>
                        <th className="text-center">Obtained Marks</th>
                        <th className="text-center">Total Marks</th>
                        <th className="text-center">Board</th>
                        <th className="text-center">
                          School/College/Institute/University
                        </th>
                        <th className="text-center">View</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {academicData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.ExaminationName}</td>
                            <td className="text-center">{item.DegreeName}</td>
                            <td className="text-center">
                              {item.MajorSubjects}
                            </td>
                            <td className="text-center">{item.YearName}</td>
                            <td className="text-center">
                              {item.SeatNo_RollNumber}
                            </td>
                            <td className="text-center">
                              {item.ObtainedMarks}
                            </td>
                            <td className="text-center">{item.TotalMarks}</td>
                            <td className="text-center">{item.BoardName}</td>
                            <td className="text-center">{item.University}</td>
                            <td className="text-center">
                              <a
                                className="btn round-cubre btn-info"
                                href={item.DocumentPathNew}
                                // download
                                target="_blank"
                              >
                                <i className="fa fa-eye" color="primary"></i>
                              </a>
                            </td>
                            <td className="text-center">
                              <Button
                                color="primary"
                                className="btnic"
                                size="sm"
                                onClick={() => onEdit(index, item)}
                              >
                                <i className="fa fa-pencil-square-o"></i>
                              </Button>
                              <Button
                                className="btn btnic"
                                color="danger"
                                type="button"
                                onClick={() => onDelete(index)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {academicData.length == 0 && (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        background: "#e9e9e9",
                        marginTop: -5,
                        padding: 20,
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      No Data Available
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <div className="text-right mt-3">
                <Button
                  color="primary"
                  form="Form1"
                  disabled={
                    statusData[0]?.Status == 1
                      ? true
                      : updateButton === true
                      ? true
                      : false
                  }
                  // disabled={statusData[0]?.Status == 1 ? true : false}
                >
                  {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Academics;
