import React, { useEffect, useRef, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Improvement_Suppli,
  Insert,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import {
  ACAD_RECOUNTING_RECHECKING_CASESOPS,
  ACAD_SUPPLI_IMPROVEMENT_OPS,
} from "../../../utils/UrlConstants";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { Button, Col, Container, Row, Table } from "reactstrap";
import useFade from "../../../Hooks/useFade";

const RecountingAndRechecking = () => {
  const [
    recounting_RecheckingApplications_TBL_,
    setrecounting_RecheckingApplications_TBL_,
  ] = useState([]);

  const initialFormFields = {
    operationId: Select,
    studentDetailID: decryptData("EmplId", SessionStorage),
    parameterID: Select,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
    recounting_RecheckingApplications_TBL_: [
      {
        enrolledStudentAcademicRecordDetailID: 0,
      },
    ],
  };

  const { FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  // const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [showSupply, setShowSupply, fadeOutProps] = useFade(false, 700);

  useEffect(() => {
    getRecountingAndRechecking();
  }, []);

  const getRecountingAndRechecking = () => {
    PostRequest(ACAD_RECOUNTING_RECHECKING_CASESOPS, initialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table?.map((x) => ({
              ...x,
              IsChecked: false,
            })),
            FormFields: initialFormFields,
          },
        });
        setShowSupply(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "AcademicYear", name: "AcademicYear" },
    { field: "Course", name: "Course Name" },
    { field: "Marks", name: "Marks" },
    { field: "Semester", name: "Semester" },
    { field: "Selected", name: "Selected Course" },
  ];

  const onCLickApply = () => {
    const patload = {
      operationId: Insert,
      studentDetailID: decryptData("EmplId", SessionStorage),
      parameterID: Select,
      userID: decryptData("loginId", SessionStorage),
      userIP: decryptData(UserNetworkInfo)?.IPv4,
      recounting_RecheckingApplications_TBL_:
        recounting_RecheckingApplications_TBL_,
    };
    PostRequest(ACAD_RECOUNTING_RECHECKING_CASESOPS, patload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getRecountingAndRechecking()
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
        setShowSupply(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClickCheck = (e, index, item) => {
    const { checked } = e.target;
    debugger;
    if (checked === true) {
      let data = {
        enrolledStudentAcademicRecordDetailID:
          item?.EnrolledStudentAcademicRecordDetailID,
      };
      recounting_RecheckingApplications_TBL_.push({ ...data });
      setrecounting_RecheckingApplications_TBL_([
        ...recounting_RecheckingApplications_TBL_,
      ]);
    } else {
      let indexOfMyBucket = recounting_RecheckingApplications_TBL_.findIndex(
        (x) =>
          x.enrolledStudentAcademicRecordDetailID ===
          item.EnrolledStudentAcademicRecordDetailID
      );
      recounting_RecheckingApplications_TBL_.splice(indexOfMyBucket, 1);
      setrecounting_RecheckingApplications_TBL_([
        ...recounting_RecheckingApplications_TBL_,
      ]);
    }
    TableList[index]["IsChecked"] = checked;
  };

  const customTable = (
    <div {...fadeOutProps}>
      <Row className="mb-3">
        <Col lg="12" md="12" xs="12" className="text-right">
          <Button color="primary" onClick={onCLickApply}>
            Apply
          </Button>
        </Col>
      </Row>
      <Table bordered striped responsive style={{ width: "100%" }}>
        <thead>
          <tr className="text-center">
            <th rowSpan={2} colSpan={1}>
              S.No.
            </th>
            {columns?.map((column, index) => (
              <th
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                key={index}
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TableList?.map((item, index) => (
            <tr
              className="text-center"
              style={{ verticalAlign: "middle" }}
              key={index}
            >
              <td>{index + 1}</td>
              <td>{item?.AcademicYear}</td>
              <td>{item?.Course}</td>
              <td>{item?.Marks}</td>
              <td>{item?.Semester}</td>
              <td>
                <input
                  type="checkbox"
                  class="form-check-Input form-check-input"
                  value={item?.IsChecked}
                  onChange={(e) => handleClickCheck(e, index, item)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <AcademicFormComponent
      SemesterName="Regestered Courses Fall 2023"
      customTable={customTable}
      tableColorCode={true}
    />
  );
};

export default RecountingAndRechecking;
