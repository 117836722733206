import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import logoimg from "../../../../assets/img/logo.png";

const AcademicFeeStatusReport = forwardRef((props, ref) => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const {
    SupportingTables: { AcademicFeeStatusList },
  } = useSelector((state) => state.CrudFormReducer);

  const columns = [
    { field: "Program", name: "Program", rowSpan: 2, colSpan: 1 },
    { field: "Part1", name: "Part I", rowSpan: 1, colSpan: 3 },
    { field: "Part2", name: "Part II", rowSpan: 1, colSpan: 3 },
    { field: "Part3", name: "Part III", rowSpan: 1, colSpan: 3 },
    { field: "Part4", name: "Part IV", rowSpan: 1, colSpan: 3 },
    { field: "SubTotal", name: "Sub. Total", rowSpan: 1, colSpan: 3 },
    {
      field: "Financial",
      name: "Financial (millions)",
      rowSpan: 1,
      colSpan: 3,
    },
  ];
  const columns2 = [
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "Deposited", name: "Deposited" },
    { field: "Rem:", name: "Rem:" },
    { field: "Total", name: "TOTAL" },
  ];
  return (
    <div ref={ref} style={{ fontSize: 12, margin: 6 }}>
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={logoimg} height="100" />
                    <h3 style={{ alignContent: "flex-end" }}>
                      SHAIKH AYAZ UNIVERSITY, SHIKARPUR
                    </h3>
                  </div>

                  <div style={{ marginTop: -30, marginBottom: 30 }}>
                    <h6 className="text-center">Fees Status Report</h6>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 10 }}>
            <Table bordered striped responsive style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th rowSpan={2} colSpan={1}>
                    S.No.
                  </th>
                  {columns?.map((column, index) => (
                    <th
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      key={index}
                      rowSpan={column.rowSpan}
                      colSpan={column.colSpan}
                    >
                      {column.name}
                    </th>
                  ))}
                </tr>
                <tr>
                  {columns2?.map((column, index) => (
                    <th
                      style={{
                        backgroundColor: "#a9a6a6",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TableList?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.FacultyDepartmentProgram}</td>
                    <td>{item.P1Enrolled}</td>
                    <td>{item.P1Paid}</td>
                    <td>{item.P1Unpaid}</td>
                    <td>{item.P2Enrolled}</td>
                    <td>{item.P2Paid}</td>
                    <td>{item.P2Unpaid}</td>
                    <td>{item.P3Enrolled}</td>
                    <td>{item.P3Paid}</td>
                    <td>{item.P3Unpaid}</td>
                    <td>{item.P4Enrolled}</td>
                    <td>{item.P4Paid}</td>
                    <td>{item.P4Unpaid}</td>
                    <td>{item.TotalEnrolled}</td>
                    <td>{item.TotalPaid}</td>
                    <td>{item.TotalUnpaid}</td>
                    <td>{item.Deposited}</td>
                    <td>{item.Remaining}</td>
                    <td>{item.Total}</td>
                    {/* <td>{item["Programming Fundamentals Lab"]}</td>
                <td>{item["Programming Fundamentals"]}</td> */}
                  </tr>
                ))}
                <tr rowSpan={2}>
                  <td colSpan={13}></td>

                  <td
                    style={{
                      background: "#a9a6a6",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    TOTAL
                  </td>
                  {AcademicFeeStatusList?.map((item, ind) => (
                    <Fragment key={ind}>
                      <td style={{ background: "#a9a6a6", color: "#fff" }}>
                        {item.Totalenrolled}
                      </td>
                      <td style={{ background: "#a9a6a6", color: "#fff" }}>
                        {item.Totalpaid}
                      </td>
                      <td style={{ background: "#a9a6a6", color: "#fff" }}>
                        {item.Totalunpaid}
                      </td>
                      <td style={{ background: "#a9a6a6", color: "#fff" }}>
                        {item.TotalDeposited}
                      </td>
                      <td style={{ background: "#a9a6a6", color: "#fff" }}>
                        {item.TotalRemaining}
                      </td>
                      <td style={{ background: "#a9a6a6", color: "#fff" }}>
                        {item.SubTotal}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default AcademicFeeStatusReport;
