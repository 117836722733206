import React from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";

const ExamScreen = () => {
  const dispatch = useDispatch();

  return (
    <Container className="mb-3" fluid>
      <h1>ExamScreen</h1>
    </Container>
  );
};

export default ExamScreen;
