import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { SessionStorage } from "../../../../common/SetupMasterEnum";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../../EncryptData";
import { Setup_UpdateEmployee } from "../../../../utils/Config";

const Documents = (props) => {
  const {id} = useParams()
  const EmplId = id == 0
  ? decryptData("EmplId", SessionStorage)
  : decryptData("EmplGetId", SessionStorage);
const LoginId = decryptData("loginId", SessionStorage);
  const FirstName = props?.firstName;
  const CNIC = props?.CNIC;
  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    EmployeeDocumentDetails_: [],
  };
  const employeeDocumentDetails_ = {
    EmployeeId: EmplId,
    EmployeeDocMappingId: 0,
    DocumentType: "",
    DocumentTypeId: 0,
    // CreatedBy: 0,
    IsActive: 1,
    // ModifiedBy: LoginId,
    UserIP: "192.168.168",
    DocumentName: "",
    DocumentPath: {},
    IsDeleted: 0,
    ImagePath: "",
    FileName: "",
    Docpath_New: ""
  };
  const formData = props?.data;
  const list = props?.list;
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const inputRef = useRef(null);
  const [documentFormFields, setDocumentFormFields] = useState({
    ...employeeDocumentDetails_,
  });
  // const [uploadAttach, setUploadAtach] = useState({});
  const [documentData, setDocumentData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [refs, setRefs] = useState();
  const handleInputChange = (event) => {
    setDocumentFormFields({
      ...documentFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    let obj = {
      ...documentFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setDocumentFormFields(obj);
  };
  const handleDocChange = (event) => {
    setDocumentFormFields({
      ...documentFormFields,
      Docpath_New: URL.createObjectURL(event.target.files[0]),
      DocumentPath: event.target.files[0],
      FileName: event.target.files[0].name
    });
  };
  useEffect(() => {
    setDocumentData(formData);
  }, [formData]);

  const onAdd = (e) => {
    e.preventDefault();
    var arr = [];
    arr.push(documentFormFields);
    setDocumentData([...documentData, ...arr]);
    setDocumentFormFields({
      ...employeeDocumentDetails_,
    });
  };
  const onDelete = (index) => {
    documentData.splice(index, 1);
    setDocumentData([...documentData]);
  };
  const onCancel = () => {
    setDocumentFormFields({
      ...employeeDocumentDetails_,
    });
    inputRef.current.value = "";
    // inputRef.current.click();
    documentFormFields.DocumentPath = null;
  };
  const onUpdate = async (e) => {
    if (documentData.length == 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please Add Document Information",
      });
    } else {
      e.preventDefault();
      let formData = new FormData();
      formData.append("UserId", formFields.UserId);
      formData.append("EmployeeId", formFields.EmployeeId);
      formData.append("FirstName", FirstName);
      formData.append("CNIC", CNIC);
      documentData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeDocumentDetails_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateEmployee(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Document Information</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>
              You can attach reports / other documents through this upload
              documents section.
            </strong>
          </div>
          <form onSubmit={onAdd}>
            <Row style={{ alignItems: "flex-end" }}>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  name="DocumentName"
                  value={documentFormFields.DocumentName}
                  label="Document Name"
                  onChange={handleInputChange}
                  required
                  maxLength="200"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Document Type"
                  list={list.DocumentTypeList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChangeSelect}
                  name="DocumentTypeId"
                  value={documentFormFields.DocumentTypeId}
                  nameValue="DocumentType"
                  // required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                {/* <FormGroupInput
                  label="Upload Attachment"
                  type="file"
                  name="UploadAttachment"
                  // value={uploadAttach.name}
                  onChange={handleDocChange}
                  required
                /> */}
                <div className="form-group">
                  <label className="form-label">Upload Attachment<span className="text-danger">*</span></label>
                  <Input
                    type="file"
                    name="UploadAttachment"
                    onChange={handleDocChange}
                    accept=".pdf/,image/jpeg,image/jpg,image/png,text/csv,.docx,.doc,.xls,.txt"
                    required
                    // value={ref}
                    // ref={inputRef }
                    // ref={(ref) => (this.ref = ref)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="4"></Col>
              <Col lg="8" md="8" sm="8" xs="12" className="text-right">
                <Button color="primary" type="submit">
                  Add
                </Button>
                <Button color="default" type="button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Document List</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-striped mb-0 mt-2">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Document Type</th>
                      <th className="text-center">Download</th>
                      <th className="text-center">View</th>
                      <th className="text-center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.DocumentName}</td>
                          <td>{item.DocumentType}</td>
                          <td className="text-center">
                            <a
                              className="btn round-cubre btn-primary"
                              download
                              href={item.Docpath_New}
                            >
                              <i className="fa fa-download"></i>
                            </a>
                          </td>
                          <td className="text-center">
                            <a
                              className="btn round-cubre btn-info"
                              href={item.Docpath_New}
                              // download
                              target="_blank"
                            >
                              <i className="fa fa-eye" color="primary"></i>
                            </a>
                          </td>
                          <td className="text-center">
                            <Button
                              className="btn btnic"
                              color="danger"
                              type="button"
                              onClick={() => onDelete(index)}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12" className="text-right mt-2">
              <Button color="primary" className="btn" onClick={onUpdate}>
                {submitLoad ? <Spinner color="light" size="sm" /> : null}
                Upload
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Documents;
