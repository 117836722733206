import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { admissionProgramId } from "../../common/SetupMasterEnum";
import { CustomErrorMessage } from "../../components/Alert";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";

const initialFormFields = {
  Programs: 3479,
};
const AdmissionProcedure = () => {
  const {
    SearchFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const [formFields, setFormFields] = useState(initialFormFields);
  const handleInputChange = (e) => {
    if (e.target.value == 0) {
      CustomErrorMessage("Please Select Program");
      setFormFields({
        ...formFields,
        [e.target.name]: 3479,
      });
    } else {
      setFormFields({
        ...formFields,
        [e.target.name]: e.target.value,
      });
    }
  };
  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>Admission Procedure</CardTitle>
        <CardBody>
          <Row>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Program"
                name="Programs"
                list={SupportingTables?.MasterDropdown?.filter(
                  (x) => x.SetupMasterId == admissionProgramId
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={formFields.Programs}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Procedure to Apply for Admission</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <div className="eligibilty-journal">
                <ul>
                  {formFields?.Programs == 3479 ? (
                    <li>
                      Application for admission shall be submitted by the
                      eligible candidate through Online Application System from
                      the SAUS Website www.saus.edu.pk.
                    </li>
                  ) : (
                    <li>
                      Application for admission for Postgraduate Program shall
                      be submitted online by the eligible candidate, on
                      www.salu.edu.pk , after completing the online form
                      candidates have to send the printed version of form along
                      with required documents, photos and original challan of
                      Rs. 2,500/- (MS.MPhil) & Rs. 3,000/- (PhD).
                    </li>
                  )}
                  {/* <li>
                    Application for admission for Postgraduate Program shall be
                    submitted online by the eligible candidate, on
                    www.salu.edu.pk , after completing the online form
                    candidates have to send the printed version of form along
                    with required documents, photos and original challan of Rs.
                    2,500/- for MPhil /MS & Rs. 3,000/- for PhD.
                  </li> */}
                  {/* <li>
                    The applicants are required to deposit Rs.2000/- in any
                    branch of the Habib Bank Limited in the favor of “Shaikh
                    Ayaz University Collection Account # 00817900345903”.
                  </li>
                  <li>
                    The receipt of Bank Challan should be uploaded on the Online
                    Application System along with the scanned copies of required
                    documents for verification and scrutiny of data.
                </li> */}
                  <li>
                    In case of foreign candidates, the recommendation and
                    undertaking on the prescribed proforma for the admission and
                    the guarantee for the payment of fees and good behavior
                    should come from the concerned Embassy/High
                    Commission/Consulate General.
                  </li>
                  <li>
                    Every student is required to submit an undertaking on
                    non-judicial stamp paper of Rs.20/= duly attested by an
                    Assistant Commissioner (Concerned) along with admission
                    form.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AdmissionProcedure;
