import React, { useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

const HostelEligibiltyCriteria = () => {
  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>
          General Instructions & Eligibility Criteria for Admission for Allotment Hostels
        </CardTitle>
        <CardBody>
          <Row>
            <Col>
              <div className="eligibilty-journal">
                <ul>
                  <li>
                    Only regular students of University are eligible to apply
                    for hostel accommodation shall fill in the online allotment,
                    form available at official website of the Shah Abdul Latif
                    University, Khairpur (www.salu.edu.pk). The interested
                    candidates are directed to upload all the necessary
                    documents along with of Rs. 200/- deposited at designate HBL
                    branches as a hostel accommodation allotment form processing
                    fee. After the allotment is made by the committee, the
                    student has to pay hostel accommodation fees Rs.12000/-
                    annually through online bank challan to any branch of Habib
                    Bank Limited.
                  </li>
                  <li>
                    The Hostels are allotted to the regular students of the
                    University, subject to the availability of seats in
                    accordance with hostels regulation. Interested students
                    apply for hostel accommodation as and when announced. The
                    hostels allotment Committee allots the hostels against the
                    vacant seats district wise. Hostel authority is not bound to
                    provide the hostel to all applicants. The committee reserves
                    the right to reject any application without assigning any
                    reason.
                  </li>
                  <li>
                    Candidates shall send the printed version of application
                    form along with copy of documents and residence certificate
                    and original challan to the office of the Provost Hostels.
                  </li>
                  <li>
                    As per instructions of Government of Pakistan and Higher
                    Education Commission, every candidate has to write his/her
                    own NIC number available on the CNIC or Form-B.
                  </li>
                  <li>
                    Every candidate shall supply three copies of his/her recent
                    photograph (two passport size and one (1'x 1. ½”), duly
                    attested, along with the hostel accommodation allotment
                    form.
                  </li>
                  <li>
                    Candidates from other provinces can apply on the hostel
                    accommodation.
                  </li>
                  <li>
                    A candidate who has already completed Bachelor's pass Degree
                    is not eligible for hostel accommodation.
                  </li>
                  <li>
                    A candidate who desires to take hostel accommodation in the
                    University be a national of Pakistan. The foreigners may be
                    considered for hostel accommodation only when
                    nominated/recommended by the Ministry of Education, Ministry
                    of Finance & Economic Affairs Division, Government of
                    Pakistan.
                  </li>
                  <li>
                    The candidate shall have to produce the following documents
                    in Photostat copy along with their attested copies:
                    <ul>
                      <li>Photo Copy of applicant NIC. </li>
                      <li>Photo Copy of Father's/Guardian. </li>
                      <li>Photo Copy of Domicile and PRC Certificate. </li>
                      <li>
                        Declaration from parent and Sureties/Guardian and
                        sureties duly attested by the F.C.M of area of residence
                        of the applicant/Residence Certificate{" "}
                      </li>
                      <li>
                        Affidavit on non-judicial stamp paper of 10/-duly
                        attested by the F.C.M.{" "}
                      </li>
                      <li>
                        In case of foreign students, the recommendations and
                        undertakings on the prescribed proforma for the
                        admission and the guarantee for the payment of Hostel
                        dues and good behavior should come from the
                        Embassy/Counslate.
                      </li>
                      <li>Photo Copy of applicant NIC. </li>
                      <li>Incomplete application shall not considered </li>
                    </ul>
                  </li>
                  <li>
                    Vice Chancellor may refuse hostel accommodation to any
                    candidate without mentioning any reason.
                  </li>
                  <li>
                    All the hostels accommodation shall be provisional until
                    approved by the Vice Chancellor.
                  </li>
                  <li>
                    No change of Hostel shall be allowed once the allotment is
                    taken.
                  </li>
                  <li>
                    A student shall do nothing, either in or outside the hostel
                    that may interfere with the orderly administration and
                    discipline or may bring the University and its
                    administration into disrepute.
                  </li>
                  <li>
                    The hostel accommodation of student shall stand cancelled
                    automatically if he/she occupies the University hostel or
                    any other part forcibly or illegally.
                  </li>
                  <li>
                    No student shall be re-allotment without the prior
                    permission of the Vice Chancellor, in case his/her allotment
                    is cancelled due to any reason.
                  </li>
                  <li>
                    No ex-allotter will be given access to the facilities of
                    hostel accommodation
                  </li>
                  <li>
                    Each Hostel accommodation to a one year shall obtain
                    identity card on the prescribed form on payment of the
                    prescribed fee.
                  </li>
                  <li>
                    If identity card is lost or destroyed, a duplicate must be
                    obtained immediately at the payment of Rs.300/=
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default HostelEligibiltyCriteria;
