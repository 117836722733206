import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Spinner,
} from "reactstrap";
import Documents from "./AdditionalProgram/Documents";
import Preferences from "./AdditionalProgram/Preferences";
import QuotaSelection from "./AdditionalProgram/QuotaSelection";


const AdditionalProgram = (props) => {

  const [activeTab, setActiveTab] = useState("1");
  return (
    <Fragment>
      <Nav pills>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
           
          >
            Preferences
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
           Quota Selection
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            Documents
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="tab-content-inner" activeTab={activeTab}>
        <TabPane tabId="1">
          <Preferences
            {...props}
          />
        </TabPane>
        <TabPane tabId="2">
          <QuotaSelection
            {...props}
          />
        </TabPane>
        <TabPane tabId="3">
          <Documents
            {...props}
          />
        </TabPane>
        </TabContent>
    </Fragment>
  );
};
export default AdditionalProgram;
