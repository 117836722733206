import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../common/dateFormatEnum";
import {
  admissionFacultyTypeId,
  admissionTypeId,
  campusCity,
  campusType,
  correspondanceId,
  department,
  districtId,
  domicileProvinceId,
  facultyDepartmentId,
  facultyTypeId,
  gender,
  nationalityId,
  programTypes,
  religionId,
  SessionStorage,
  shiftTypeId,
  yearId,
} from "../../../common/SetupMasterEnum";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { formatDateFunction } from "../../../functions/DateFormatFunction";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../utils/Config";
import { decryptData } from "../../../EncryptData";
import {
  ADMISSION_USER_ID,
  CNIC_BFORM,
  EMAIL,
  EMPLOYEE_NAME,
  LAST_NAME,
  MIDDLE_NAME,
  MOBILE,
  REG_ID,
} from "../../../utils/EncryptedConstants";
import { CustomErrorMessage } from "../../../components/Alert";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";

const PersonalInfo = (props) => {
  const dispatch = useDispatch();
  // const data = {
  //   ADMRegID : 0,
  //   CampusID: 0,
  //   CampusCityId: 0,
  //   ProgramId: 0,
  //   ProgramTypeId: 0,
  //   AdmissionTypeId: 0,
  //   MajorId: 0,
  //   AdmissionFacultyTypeId: 0,
  //   AdmissionFacultyDepartmentId: 0,
  //   FacultyDepartmentProgramId: 0,
  //   Year: 0,
  //   Name: 0,
  //   FatherName: 0,
  //   SurName: 0,
  //   GenderID
  // }
  const initialFormFields = {
    languageId: 0,
    Year: 0,
    WhatsAppNo: "",
    AlternativeNumber: "",
    LastDegreeFromSalu: "",
    Other: "",
    ProgramId: props.Programid,
    CampusID: 0,
    CampusCityId: 0,
    ProgramTypeId: 0,
    AdmissionTypeId: 0,
    MajorId: 0,
    AdmissionFacultyTypeId: 0,
    AdmissionFacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    ShiftId: 0,
    Name: `${decryptData(EMPLOYEE_NAME, SessionStorage)} ${
      decryptData(MIDDLE_NAME, SessionStorage) === "" ? "" : decryptData(MIDDLE_NAME, SessionStorage)
    } ${decryptData(LAST_NAME, SessionStorage) === "" ? "" : decryptData(LAST_NAME, SessionStorage)}`,
    FatherName: "",
    SurName: "",
    GenderID: 0,
    GuardianName: "",
    Cnic: decryptData(CNIC_BFORM, SessionStorage),
    Picture: "",
    DOB: null,
    PostalAddress: "",
    PermanantAddress: "",
    CorrespondatAddressId: 0,
    NationalityID: 0,
    ReligionID: 0,
    DomicileID: 0,
    DomicileDistricId: 0,
    Ph: "",
    Mobile: decryptData(MOBILE, SessionStorage),
    Email: decryptData(EMAIL, SessionStorage),
    AreEmployeedAnyWhere: 0,
    EmployeedDepartment: "",
    NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase: 0,
    UnderTaking: 0,
    UserIP: "192.168.14.1",
    InterestIn: ""
  };
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const personalInfoData = SupportingTables?.students?.Table1[0];
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [fileName, setFileName] = useState("");
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
    ...personalInfoData,
  });
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
    FileName: fileName,
  };
  const [profileImage, setProfileImage] = useState();
  const handleInputChange = (event) => {
    if (event.target.type == "radio") {
      setFormFields({
        ...formFields,
        [event.target.name]: parseInt(event.target.value),
      });
    }
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageUpload = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
    setFileName(event.target.files[0].name);
    setFormFields({
      ...formFields,
      Picture: event.target.files[0],
    });
  };
  const AllDateSet = (event, type) => {
    setFormFields({
      ...formFields,
      DOB: formatDateFunction(event, "-"),
    });
  };
  useEffect(() => {
    setFormFields({
      ...initialFormFields,
      ...personalInfoData,
    });
  }, [personalInfoData]);
  const onUpdate = (e) => {
    e.preventDefault();

    let formData = new FormData();
    Object.keys(basicValues).forEach((d) => {
      formData.append(d, basicValues[d]);
    });
    Object.keys(formFields).forEach((d) => {
      formData.append(`ADMPersonalInformation_.${d}`, formFields[d]);
    });
    updateAdmission(formData);
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          getStudendRecords();
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        // setSubmitLoad(false);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: SupportingTables.ProgramId,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        let data = {
          name: "students",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {}, []);
  return (
    <Fragment>
      <form onSubmit={onUpdate}>
        <Card className="mt-3">
          <CardTitle>Personal Information</CardTitle>
          <CardBody>
            <Row style={{ alignItems: "center" }}>
              <Col md="9">
                <Row>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Full Name"
                      name="Name"
                      value={formFields?.Name}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Father's/Husband's Name"
                      name="FatherName"
                      value={formFields?.FatherName}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupSelect
                      label="Gender"
                      name="GenderID"
                      list={SupportingTables?.tables?.filter(
                        (x) => x.SetupMasterId == gender
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={formFields?.GenderID}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="CNIC Number"
                      name="Cnic"
                      value={formFields?.Cnic}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Mobile No"
                      name="Mobile"
                      value={formFields?.Mobile}
                      onChange={handleInputChange}
                      isNumber="true"
                      maxLength="13"
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupInput
                      label="Alternative Number"
                      name="AlternativeNumber"
                      value={formFields?.AlternativeNumber}
                      onChange={handleInputChange}
                      isNumber="true"
                      maxLength="13"
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupSelect
                      label="(if any) Language"
                      name="LanguageId"
                      list={SupportingTables?.tables?.filter(
                        (x) => x.SetupMasterId == 1112
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={formFields?.LanguageId}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="4" lg="4">
                    <div className="form-group">
                      <label className="form-label">
                        Date Of Birth<span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={new Date(formFields?.DOB)}
                        onChange={(e) => AllDateSet(e, "DateOfBirth")}
                        className="form-control"
                        name="DOB"
                        // value={formatDateFunc(profileFormFields.DateOfBirth, "/")}
                        required={true}
                        showYearDropdown={true}
                        dateFormat={dateFormat}
                        placeholderText={dateFormatPlaceholder}
                      />
                    </div>
                  </Col>
                  <Col md="4" lg="4">
                    <FormGroupSelect
                      label="Domicile Province"
                      name="DomicileID"
                      list={SupportingTables?.tables?.filter(
                        (x) => x.SetupMasterId == domicileProvinceId
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={formFields?.DomicileID}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="3" className="text-center">
                <Row>
                  <Col>
                    <div className="profile-info text-center mb-3">
                      <div className="profile-img-wrap">
                        <img
                          src={
                            profileImage !== undefined
                              ? profileImage
                              : formFields?.Picture
                          }
                          className=""
                          width={150}
                          height={150}
                          alt={formFields?.Name}
                          name="image"
                        />
                        <div className="fileupload">
                          <p className="btn-text-edit">
                            <i className="fa fa-pencil"></i>
                          </p>
                          <Input
                            className="upload"
                            type="file"
                            accept="image/*"
                            color="default"
                            name="Picture"
                            onChange={handleImageUpload}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Domicile District"
                  name="DomicileDistricId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == districtId &&
                      x.SetupDetailId != 5615 &&
                      x.SetupDetailId != 5617 &&
                      x.SetupDetailId != 5618 &&
                      x.SetupDetailId != 5619 &&
                      x.SetupDetailId != 5620 &&
                      x.SetupDetailId != 5621
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={
                    formFields?.DomicileID === 3603
                      ? formFields?.DomicileDistricId
                      : 5616
                  }
                  onChange={handleInputChange}
                  required
                  disabled={formFields?.DomicileID !== 3603 ? true : false}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Last Degree from: SALU"
                  name="LastDegreeFromSalu"
                  value={formFields?.LastDegreeFromSalu}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Other"
                  name="Other"
                  value={formFields?.Other}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Email Address"
                  name="Email"
                  value={formFields?.Email}
                  onChange={handleInputChange}
                  type="email"
                  disabled
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Current Address"
                  name="PostalAddress"
                  value={formFields?.PostalAddress}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Permanent Address"
                  name="PermanantAddress"
                  value={formFields?.PermanantAddress}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Correspondence Address"
                  name="CorrespondatAddressId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == correspondanceId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.CorrespondatAddressId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Whatsapp #"
                  name="WhatsAppNo"
                  value={formFields?.WhatsAppNo}
                  onChange={handleInputChange}
                  isNumber="true"
                  maxLength="13"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={statusData[0]?.Status == 1 ? true : false}
                  >
                    {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment>
  );
};

export default PersonalInfo;
