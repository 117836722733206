import React, { forwardRef, Fragment, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import logoimg from "../../assets/img/HBL-1-logo.png";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import {
  Admission_ADM_UG_VoucherDetails,
  ADM_PrintVoucher,
} from "../../utils/Config";
import { decryptData } from "../../EncryptData";
import { Search, Select, SessionStorage } from "../../common/SetupMasterEnum";
import { REG_ID } from "../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import {
  formatDateFunc,
  formatDateFunctionByName,
} from "../../functions/DateFormatFunction";
import Logo from "../../assets/img/logo.png";

const ChallanForAdmission = forwardRef((props, ref) => {
  const {  SupportingTables: {AdmissionVoucherData} } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   getADM_PrintVoucher();
  // }, []);

  // const getADM_PrintVoucher = () => {
  //   let payload = {
  //     operationID: Select,
  //     // admRegID: 157,
  //     admRegID: decryptData(REG_ID, SessionStorage),
  //     admissionFacultyDepartmentId: 0,
  //     facultyDepartmentProgramId: 0,
  //   };

  //   ADM_PrintVoucher(payload)
  //     .then((res) => {
  //       let ChallanForAdmissionData = {
  //         name: "ChallanForAdmissionData",
  //         value: res?.data,
  //       };
  //       dispatch({
  //         type: SET_INITIAL_DROPDOWN_FORM_STATE,
  //         payload: ChallanForAdmissionData,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  return (
    <div className="landscape-page" ref={ref}>
      <Container fluid>
        <div style={{ margin: "20px", marginTop: "20px" }} className="card-div">
          <table>
            <tbody>
              <tr>
                <td>
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        width: "100%",
                        border: "1px solid",
                        lineHeight: "0.30",
                        fontSize: "11px",
                      }}
                    >
                      <tbody>
                        <tr className="text-center">
                          <td colSpan={7}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div className="login-brand mt-1">
                                <QRCode
                                  value={`${AdmissionVoucherData?.Table?.[0]?.Token}`}
                                  size="60"
                                />
                              </div>
                              <div className="login-brand">
                                <img height="70" src={Logo} alt="Logo" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>Student Copy</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            className="text-center"
                            colSpan={7}
                          >
                            Bank Challan
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td
                            colSpan={2}
                            style={{
                              width: "35%",
                              textAlign: "center",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                            className="text-center"
                          >
                            Last Date of Submission
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              width: "55%",
                              textAlign: "center",
                              fontWeight: "bold",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                          >
                            {formatDateFunc(
                              AdmissionVoucherData
                                ?.Table?.[0]?.DueDate,
                              "-"
                            )}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              width: "20%",
                              fontSize: 10,
                              textAlign: "center",
                              lineHeight: "10px",
                              justifyContent: "center",
                            }}
                          >
                            No:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Token
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            colSpan={7}
                            className="text-center"
                          >
                            Payable through HBL Connect
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Name:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            S/O,D/O:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FatherName
                            }
                          </td>
                        </tr>
                        <tr style={{verticalAlign:'middle'}} >
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Department:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FacultyDept
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%",verticalAlign:'middle'  }} colSpan={2}>
                            Signature:
                          </td>
                          {/* <td colSpan={2}></td> */}
                          <td style={{ width: "10%",verticalAlign:'middle' }} colSpan={2}>
                            Program:
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              width: "50%",
                              fontSize: 10,
                              lineHeight:'10px'
                            }}
                            colSpan={2}
                          >
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FacultyDeptProgram
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            C.N.I.C/I.D Card No:/Admission No:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Cnic
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "70%", lineHeight: "10px" }}
                            colSpan={4}
                          >
                            Purpose of Deposit Detalis on reverse:
                          </td>
                          <td style={{ width: "30%" }} colSpan={4}>
                            Rs.
                          </td>
                        </tr>
                        {AdmissionVoucherData?.Table?.map(
                          (x, ind) => {
                            return (
                              <tr style={{ verticalAlign: "middle" }}>
                                <td
                                  colSpan={4}
                                  style={{
                                    lineHeight:
                                      x?.AdmParticular?.length > 17
                                        ? "10px"
                                        : "0px",
                                  }}
                                >
                                  {ind + 1}) {x.AdmParticular}
                                </td>
                                <td
                                  colSpan={4}
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {x.Merit}
                                </td>
                              </tr>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{ fontWeight: "bold", textAlign: "end" }}
                            colSpan={4}
                          >
                            Total Amount =
                          </td>
                          <td
                            colSpan={4}
                            style={{ fontSize: "9px", fontWeight: "bold" }}
                          >
                            {
                              AdmissionVoucherData
                                ?.Table1?.[0]?.TotalAmount
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "8px",
                              lineHeight: "10px",
                            }}
                            colSpan={7}
                          >
                            Rupees (in words) :{" "}
                            {
                              AdmissionVoucherData
                                ?.Table1?.[0]?.AmountRupees
                            }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                    <table
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "left", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Cashier</p>
                          </td>
                          <td
                            style={{ textAlign: "right", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Manager </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>

                <div style={{ margin: "3px" }}></div>

                <td>
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        width: "100%",
                        border: "1px solid",
                        lineHeight: "0.30",
                        fontSize: "11px",
                      }}
                    >
                      <tbody>
                        <tr className="text-center">
                          <td colSpan={7}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div className="login-brand mt-1">
                                <QRCode
                                  value={`${AdmissionVoucherData?.Table?.[0]?.Token}`}
                                  size="60"
                                />
                              </div>
                              <div className="login-brand">
                                <img height="70" src={Logo} alt="Logo" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>Bank Copy</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            className="text-center"
                            colSpan={7}
                          >
                            Bank Challan
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td
                            colSpan={2}
                            style={{
                              width: "35%",
                              textAlign: "center",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                            className="text-center"
                          >
                            Last Date of Submission
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              width: "55%",
                              textAlign: "center",
                              fontWeight: "bold",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                          >
                            {formatDateFunc(
                              AdmissionVoucherData
                                ?.Table?.[0]?.DueDate,
                              "-"
                            )}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              width: "20%",
                              fontSize: 10,
                              textAlign: "center",
                              lineHeight: "10px",
                              justifyContent: "center",
                            }}
                          >
                            No:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Token
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            colSpan={7}
                            className="text-center"
                          >
                            Payable through HBL Connect
                          </td>
                        </tr>
                        
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Name:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            S/O,D/O:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FatherName
                            }
                          </td>
                        </tr>
                        <tr style={{verticalAlign:'middle'}} >
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Department:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FacultyDept
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%",verticalAlign:'middle'  }} colSpan={2}>
                            Signature:
                          </td>
                          {/* <td colSpan={2}></td> */}
                          <td style={{ width: "10%",verticalAlign:'middle' }} colSpan={2}>
                            Program:
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              width: "50%",
                              fontSize: 10,
                              lineHeight:'10px'
                            }}
                            colSpan={2}
                          >
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FacultyDeptProgram
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            C.N.I.C/I.D Card No:/Admission No:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Cnic
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "70%", lineHeight: "10px" }}
                            colSpan={4}
                          >
                            Purpose of Deposit Detalis on reverse:
                          </td>
                          <td style={{ width: "30%" }} colSpan={4}>
                            Rs.
                          </td>
                        </tr>
                        {AdmissionVoucherData?.Table?.map(
                          (x, ind) => {
                            return (
                              <tr style={{ verticalAlign: "middle" }}>
                                <td
                                  colSpan={4}
                                  style={{
                                    lineHeight:
                                      x?.AdmParticular?.length > 17
                                        ? "10px"
                                        : "0px",
                                  }}
                                >
                                  {ind + 1}) {x.AdmParticular}
                                </td>
                                <td
                                  colSpan={4}
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {x.Merit}
                                </td>
                              </tr>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{ fontWeight: "bold", textAlign: "end" }}
                            colSpan={4}
                          >
                            Total Amount =
                          </td>
                          <td
                            colSpan={4}
                            style={{ fontSize: "9px", fontWeight: "bold" }}
                          >
                            {
                              AdmissionVoucherData
                                ?.Table1?.[0]?.TotalAmount
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "8px",
                              lineHeight: "10px",
                            }}
                            colSpan={7}
                          >
                            Rupees (in words) :{" "}
                            {
                              AdmissionVoucherData
                                ?.Table1?.[0]?.AmountRupees
                            }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                    <table
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "left", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Cashier</p>
                          </td>
                          <td
                            style={{ textAlign: "right", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Manager </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>

                <div style={{ margin: "3px" }}></div>

                <td>
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        width: "100%",
                        border: "1px solid",
                        lineHeight: "0.30",
                        fontSize: "11px",
                      }}
                    >
                      <tbody>
                        <tr className="text-center">
                          <td colSpan={7}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div className="login-brand mt-1">
                                <QRCode
                                  value={`${AdmissionVoucherData?.Table?.[0]?.Token}`}
                                  size="60"
                                />
                              </div>
                              <div className="login-brand">
                                <img height="70" src={Logo} alt="Logo" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>University Copy</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            className="text-center"
                            colSpan={7}
                          >
                            Bank Challan
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td
                            colSpan={2}
                            style={{
                              width: "35%",
                              textAlign: "center",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                            className="text-center"
                          >
                            Last Date of Submission
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              width: "55%",
                              textAlign: "center",
                              fontWeight: "bold",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                          >
                            {formatDateFunc(
                              AdmissionVoucherData
                                ?.Table?.[0]?.DueDate,
                              "-"
                            )}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              width: "20%",
                              fontSize: 10,
                              textAlign: "center",
                              lineHeight: "10px",
                              justifyContent: "center",
                            }}
                          >
                            No:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Token
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            colSpan={7}
                            className="text-center"
                          >
                            Payable through HBL Connect
                          </td>
                        </tr>
                        
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Name:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            S/O,D/O:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FatherName
                            }
                          </td>
                        </tr>
                        <tr style={{verticalAlign:'middle'}} >
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Department:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FacultyDept
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%",verticalAlign:'middle'  }} colSpan={2}>
                            Signature:
                          </td>
                          {/* <td colSpan={2}></td> */}
                          <td style={{ width: "10%",verticalAlign:'middle' }} colSpan={2}>
                            Program:
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              width: "50%",
                              fontSize: 10,
                              lineHeight:'10px'
                            }}
                            colSpan={2}
                          >
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.FacultyDeptProgram
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            C.N.I.C/I.D Card No:/Admission No:{" "}
                            {
                              AdmissionVoucherData
                                ?.Table?.[0]?.Cnic
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "70%", lineHeight: "10px" }}
                            colSpan={4}
                          >
                            Purpose of Deposit Detalis on reverse:
                          </td>
                          <td style={{ width: "30%" }} colSpan={4}>
                            Rs.
                          </td>
                        </tr>
                        {AdmissionVoucherData?.Table?.map(
                          (x, ind) => {
                            return (
                              <tr style={{ verticalAlign: "middle" }}>
                                <td
                                  colSpan={4}
                                  style={{
                                    lineHeight:
                                      x?.AdmParticular?.length > 17
                                        ? "10px"
                                        : "0px",
                                  }}
                                >
                                  {ind + 1}) {x.AdmParticular}
                                </td>
                                <td
                                  colSpan={4}
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {x.Merit}
                                </td>
                              </tr>
                            );
                          }
                        )}
                        <tr>
                          <td
                            style={{ fontWeight: "bold", textAlign: "end" }}
                            colSpan={4}
                          >
                            Total Amount =
                          </td>
                          <td
                            colSpan={4}
                            style={{ fontSize: "9px", fontWeight: "bold" }}
                          >
                            {
                              AdmissionVoucherData
                                ?.Table1?.[0]?.TotalAmount
                            }
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "8px",
                              lineHeight: "10px",
                            }}
                            colSpan={7}
                          >
                            Rupees (in words) :{" "}
                            {
                              AdmissionVoucherData
                                ?.Table1?.[0]?.AmountRupees
                            }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                    <table
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "left", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Cashier</p>
                          </td>
                          <td
                            style={{ textAlign: "right", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Manager </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
});

export default ChallanForAdmission;
