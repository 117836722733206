import React from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

const HostelChecklist = () => {
  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>CHECK LIST OF THE DOCUMENTS ATTACHED WITH THE FORM</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <div className="eligibilty-journal">
                <ul>
                  <li>Photo Copy of applicant NIC. </li>
                  <li>Photo Copy of Father’s/Guardian. </li>
                  <li>Photo Copy of Domicile and PRC Certificate. </li>
                  <li>
                    Declaration from parent and Sureties/Guardian and sureties
                    duly attested by the F.C.M of area of residence of the
                    applicant/Residence Certificate{" "}
                  </li>
                  <li>
                    Affidavit on non-judicial stamp paper of 10/-duly attested
                    by the F.C.M.{" "}
                  </li>
                  <li>
                    In case of foreign students, the recommendations and
                    undertakings on the prescribed proforma for the admission
                    and the guarantee for the payment of Hostel dues and good
                    behavior should come from the Embassy/Counslate.
                  </li>
                  <li>Photo Copy of applicant NIC. </li>
                  <li>Incomplete application shall not considered </li>
                </ul>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default HostelChecklist;
