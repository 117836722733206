import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Table } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_FORM_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import Spinner from "react-spinkit";
import { useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import DegreeProgramLedgerReport from "../Reports/ControllerExamination/DegreeProgramLedgerReport";
import { PostRequest } from "../../../utils/Config";
import { ACAD_EXAM_CONTROLLER_REPORTS } from "../../../utils/UrlConstants";
import {
  academicYearId,
  partYearID,
  Search,
  semesterId,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { decryptData } from "../../../EncryptData";
import GazetteReport from "../Reports/ControllerExamination/GazetteReport";
import { CustomErrorMessage } from "../../../components/Alert";
import AcademicSemesterResultReport from "../Reports/ControllerExamination/AcademicSemesterResultReport";

const AcademicSemesterResult = () => {
  const initialSearchFields = {
    OperationID: 3,
    AcademicYearID: 0,
    SemesterID: 0,
    PartID: 0,
    FacultyDepartmentProgramID: 0,
    FacultyDeptId:0,
    StudentDetailID: 0,
    Cnic: "",
    RegNo: "",
    Name: "",
    UserID: decryptData("loginId", SessionStorage),
  };

  const initialFormFields = {
    OperationID: 3,
    AcademicYearID: 0,
    SemesterID: 0,
    PartID: 0,
    FacultyDepartmentProgramID: 0,
    StudentDetailID: 0,
    FacultyDeptId:0,
    Cnic: "",
    RegNo: "",
    Name: "",
    UserID: decryptData("loginId", SessionStorage),
  };

  const { SearchFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const {
    SupportingTables: { SearchDepartmentStudent },
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);
  const [SemesterList] = useSetupDetailList(semesterId, SearchFields?.PartID);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const printGazette = useRef();

  const [formLoad, setFormLoad] = useState(true);

  useEffect(() => {
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

 
  function tableDataHide() {
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, initialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }


  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartID"
          onChange={handleSearchChange}
          value={SearchFields?.PartID}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="SemesterID"
          onChange={handleSearchChange}
          value={SearchFields?.SemesterID}
          list={SemesterList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.PartID == null}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDeptId"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDeptId}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyDepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.FacultyDeptId == null}
          required
        />
      </Col>
    </Fragment>
  );

  const columns = [
    { field: "Sno", name: "SNo" },
    { field: "Reg_No", name: "Reg#" },
    { field: "NAME", name: "Name" },
    { field: "Part", name: "Part" },
    { field: "Semester", name: "Semester" },
    { field: "FacultyDepartment", name: "Faculty Department" },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
    { field: "status", name: "Status" },
  ];

  const handlePrintReport = useReactToPrint({
    content: () => printGazette.current,
  });


  const viewPassCertificate = (obj) => {
    const payload = {
      ...SearchFields,
    };
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, payload)
      .then((res) => {
        if (res?.data?.Table1?.[0]?.HasError === 0) {
          CustomErrorMessage(res?.data?.Table1?.[0]?.MESSAGE);
        } else {
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: res?.data?.Table,
              FormFields: initialFormFields,
            },
          });
          timeoutRef.current = setTimeout(
            () => handlePrintReport(),
            200
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      OperationID: 3,
      UserID: decryptData("loginId", SessionStorage),
    };
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, payload)
      .then((res) => {
     
        let data = {
          name: "SearchDepartmentStudent",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });

        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
          
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };



  const customTable = (
    <Fragment>
      {TableList.length > 1 ? (
        <Table bordered striped responsive style={{ width: "100%" }}>
        <thead>
            <tr>
              <th>S.No.</th>
              {TableList?.length > 0
                ? Object.keys(TableList?.[0])?.map((column, index) => (
                    <Fragment key={index}>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        key={index + 1}
                      >
                        {column}
                      </th>
                    </Fragment>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {TableList &&
              TableList?.map((value, ind) => (
                <tr key={ind}>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {ind + 1}
                  </td>
                  {Object.keys(TableList[ind])?.map((column, index) => (
                    <Fragment key={index}>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        key={index + 2}
                      >
                        {TableList[ind][column]}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        "No Data Found"
      )}
    </Fragment>
  );

 

  // const customButton = (
  //   <Fragment>
  //     <Button  color="primary" onClick={handlePrintReport} disabled={TableList.length > 0 ? false : true}>
  //       Semester Result Report
  //     </Button>
  //     <div style={{ display: "none" }}>
  //       <AcademicSemesterResultReport ref={printGazette} />
  //     </div>
  //   </Fragment>
  // );


  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    tableDataHide();
  };

  return (
    <CrudFormComponent
      formName="Semester Result Report"
      // customButton={customButton}
      hideAction={true}
      tableColumns={columns}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
    />
  );
};

export default AcademicSemesterResult;
