import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  academicYearId,
  admissionFacultyTypeId,
  admissionProgramId,
  admissionTypeId,
  campusCity,
  campusType,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  facultyTypeId,
  majorsAdId,
  programTypes,
  SessionStorage,
} from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../EncryptData";
import {
  RESET_FORM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import { PostFormRequest, PostRequest } from "../../utils/Config";
import { TYPE } from "../../utils/EncryptedConstants";
import {
  ADMISSION_SAMPLE_PAPER,
  SETUP_MASTER_DETAIL_ALL_DROPDOWN,
} from "../../utils/UrlConstants";

const AdmissionSamplePaper = () => {
  const initialSearchFields = {
    SamplePaperId: 0,
    CampusTypeId: 1284,
    CampusCityId: 1644,
    ProgramId: 3479,
    ProgramTypesId: 4009,
    AdmissionTypeId: 4012,
    MajorId: 4183,
    AdmissionFacultyTypeId: 0,
    FacultyDepartmentId: 4426,
    FacultyDepartmentProgramId: 4743,
    AcademicYearId: 0,
    SamplePaper: "",
    SamplePaperName: "",
    SamplePaperPath: "",
    IsActive: 1,
    IsDeleted: 0,
    UserId: decryptData("loginId", SessionStorage),
  };
  const initialFormFields = {
    SamplePaperId: 0,
    CampusTypeId: 1284,
    CampusCityId: 1644,
    ProgramId: 3479,
    ProgramTypesId: 4009,
    AdmissionTypeId: 4012,
    MajorId: 4183,
    AdmissionFacultyTypeId: 0,
    FacultyDepartmentId: 4426,
    FacultyDepartmentProgramId: 4743,
    AcademicYearId: 0,
    SamplePaper: "",
    SamplePaperName: "",
    SamplePaperPath: "",
    IsActive: 1,
    IsDeleted: 0,
    UserId: decryptData("loginId", SessionStorage),
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    getMasterDetailAllDropdown();
    samplePaperData(initialSearchFields);
  }, []);
  const columns = [
    { field: "AcademicYear", name: "Acadamic Year" },
    // { field: "AdmissionTypeName", name: "Admission Type" },
    // { field: "FacultyDepartmentName", name: "Faculty Department" },
    // {
    //   field: "FacultyDepartmentProgramName",
    //   name: "Faculty Department Program",
    // },
  ];
  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res?.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [formFields, setFormFields] = useState(initialFormFields);
  const [modalToggle, setModalToggle] = useState(false);
  const toggle = () => {
    setModalToggle(!modalToggle);
  };
  const handleInputChange = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddtChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleFileUpload = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.files[0],
      SamplePaperName: e.target.files[0]?.name,
    });
  };

  const onSearch = (e) => {
    e.preventDefault();
    samplePaperData(searchFields);
  };

  function samplePaperData(payload) {
    let formData = new FormData();
    formData.append("OperationID", 1);
    Object.keys(payload).forEach((d) => {
      formData.append(`SamplePaper_.${d}`, payload[d]);
    });
    PostFormRequest(ADMISSION_SAMPLE_PAPER, formData)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function addSamplePaper(payload) {
    let formData = new FormData();
    formData.append("OperationID", 2);
    Object.keys(payload).forEach((d) => {
      formData.append(`SamplePaper_.${d}`, payload[d]);
    });
    PostFormRequest(ADMISSION_SAMPLE_PAPER, formData)
      .then((res) => {
        if (res.data.Table[0].HasError === 0) {
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: res.data.Table,
              FormFields: initialFormFields,
              SearchFields: initialSearchFields,
            },
          });
          CustomSuccessAlert(res.data.Table[0].Message);
          setFormFields({
            ...initialFormFields,
          });
          samplePaperData(initialFormFields);
          toggle();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onUpload = () => {
    setModalToggle(true);
  };
  const onClose = () => {
    toggle();
    setFormFields({
      ...initialFormFields,
    });
  };
  const onCancel = () => {
    setSearchFields({
      ...initialSearchFields,
    });
    samplePaperData(initialSearchFields);
  };
  const onAdd = (e) => {
    e.preventDefault();
    addSamplePaper(formFields);
  };

  const onDownload = (index, data) => {
    window.open(data.SamplePaper);
  };

  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>Sample Papers</CardTitle>
        <CardBody>
          <form onSubmit={onSearch}>
            <Row>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == academicYearId
                  )}
                  label="Academic Year"
                  name="AcademicYearId"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.AcademicYearId}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              {/* <Col md="3" lg="3">
                <FormGroupSelect
                  label="Program"
                  name="ProgramId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == admissionProgramId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.ProgramId}
                  onChange={handleInputChange}
                  required={decryptData(TYPE) == 1 ? false : true}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Campus Type"
                  name="CampusTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == campusType
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.CampusTypeId}
                  onChange={handleInputChange}
                  required={decryptData(TYPE) == 1 ? false : true}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Campus City"
                  name="CampusCityId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == campusCity &&
                      x.parentid == searchFields?.CampusTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.CampusCityId}
                  onChange={handleInputChange}
                  required={decryptData(TYPE) == 1 ? false : true}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Program Types"
                  name="ProgramTypesId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == programTypes &&
                      x.parentid == searchFields?.CampusCityId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.ProgramTypesId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Admission Type"
                  name="AdmissionTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == admissionTypeId &&
                      x.parentid == searchFields?.ProgramTypesId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.AdmissionTypeId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Majors"
                  name="MajorId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == majorsAdId &&
                      x.parentid == searchFields?.AdmissionTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.MajorId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDepartmentId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentId &&
                      x.parentid == searchFields?.MajorId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.FacultyDepartmentId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentProgramId &&
                      x.parentid == searchFields?.FacultyDepartmentId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.FacultyDepartmentProgramId}
                  onChange={handleInputChange}
                />
              </Col> */}
              <Col lg="12" md="12" xs="12" className="text-right">
                <Button color="primary" className="btn">
                  Search
                </Button>
                <Button className="btn" color="default" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>
          <Row>
            <Col>Sample Paper Detail</Col>
            <Col className="text-end">
              {decryptData(TYPE, SessionStorage) == 0 ? (
                <Button color="primary" onClick={onUpload}>
                  Add Sample Paper
                </Button>
              ) : null}
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <FormGroupTable
            columns={columns}
            rows={TableList}
            onDownloadText={onDownload}
          />
        </CardBody>
      </Card>
      <Modal centered isOpen={modalToggle}>
        <ModalHeader>Add Sample Paper</ModalHeader>
        <ModalBody>
          <form onSubmit={onAdd}>
            <Row>
              <Col lg="6" md="6" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == academicYearId
                  )}
                  label="Academic Year"
                  name="AcademicYearId"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.AcademicYearId}
                  onChange={handleAddtChange}
                  required
                />
              </Col>
              {/* <Col md="6" lg="6">
                <FormGroupSelect
                  label="Programs"
                  name="ProgramId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == admissionProgramId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields.ProgramId}
                  onChange={handleAddtChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupSelect
                  label="Campus Type"
                  name="CampusTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == campusType
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields.CampusTypeId}
                  onChange={handleAddtChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupSelect
                  label="Campus City"
                  name="CampusCityId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == campusCity &&
                      x.parentid == formFields?.CampusTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields.CampusCityId}
                  onChange={handleAddtChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupSelect
                  label="Program Types"
                  name="ProgramTypesId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == programTypes &&
                      x.parentid == formFields?.CampusCityId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.ProgramTypesId}
                  onChange={handleAddtChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupSelect
                  label="Admission Type"
                  name="AdmissionTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == admissionTypeId &&
                      x.parentid == formFields?.ProgramTypesId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields.AdmissionTypeId}
                  onChange={handleAddtChange}
                  required
                />
              </Col>

              <Col md="6" lg="6">
                <FormGroupSelect
                  label="Majors"
                  name="MajorId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == majorsAdId &&
                      x.parentid == formFields?.AdmissionTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.MajorId}
                  onChange={handleAddtChange}
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDepartmentId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentId &&
                      x.parentid == formFields?.MajorId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields.FacultyDepartmentId}
                  onChange={handleAddtChange}
                  required
                />
              </Col>
              <Col md="6" lg="6">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentProgramId &&
                      x.parentid == formFields?.FacultyDepartmentId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.FacultyDepartmentProgramId}
                  onChange={handleAddtChange}
                />
              </Col> */}
              <Col md="6" lg="6">
                <div className="form-group">
                  <label className="form-label">
                    Upload Attachment<span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    name="SamplePaperPath"
                    onChange={handleFileUpload}
                    accept=".pdf"
                    required
                    // value={ref}
                    // ref={inputRef }
                    // ref={(ref) => (this.ref = ref)}
                  />
                </div>
              </Col>
              <Col lg="12" md="12" xs="12" className="text-right mt-2">
                <Button color="primary" className="btn" type="submit">
                  Add
                </Button>
                <Button
                  className="btn"
                  color="default"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default AdmissionSamplePaper;
