import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { SessionStorage } from "../../../../common/SetupMasterEnum";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../../EncryptData";
import { Setup_UpdateEmployee } from "../../../../utils/Config";

const BankDetails = (props) => {
  const { id } = useParams();
  const EmplId =
    id == 0
      ? decryptData("EmplId", SessionStorage)
      : decryptData("EmplGetId", SessionStorage);
  const LoginId = decryptData("loginId", SessionStorage);
  const FirstName = props?.firstName;
  const CNIC = props?.CNIC;
  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    EmployeeBankDetails_: [],
  };
  const employeeBankDetails_ = {
    EmployeeId: EmplId,
    EmployeeBankMappingId: 0,
    Bank: "",
    BankId: 0,
    IsActive: 1,
    UserIP: "192.168.168",
    AccountTitle: "",
    AccountNumber: "",
    Iban: "",
    BranchCode: "",
    IsDeleted: 0,
    UserIP: "192.168.168",
    DocumentPath: {},
  };
  const formData = props?.data;
  const list = props?.list;
  const ref = useRef();
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const [bankFormFields, setBankFormFields] = useState({
    ...employeeBankDetails_,
  });
  const [bankData, setBankData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  // const [documentData, setDocumentData] = useState({});
  const handleInputChange = (event) => {
    setBankFormFields({
      ...bankFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    let obj = {
      ...bankFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setBankFormFields(obj);
  };
  const handleUploadChange = (event) => {
    setBankFormFields({
      ...bankFormFields,
      DocumentPath: event.target.files[0],
    });
  };
  useEffect(() => {
    setBankData(formData);
  }, [formData]);
  const columns = [
    { field: "Bank", name: "Bank Name" },
    { field: "AccountNumber", name: "Account Number" },
  ];
  const onAdd = (e) => {
    e.preventDefault();
    bankFormFields.index > -1
      ? (bankData[bankFormFields.index] = bankFormFields)
      : bankData.push({
          ...bankFormFields,
          index: -1,
        });
    setBankData([...bankData]);
    setBankFormFields({
      ...employeeBankDetails_,
    });
  };
  const onEdit = (index, obj) => {
    setBankFormFields({
      ...obj,
      index: index,
    });
  };
  const onDelete = (index) => {
    bankData.splice(index, 1);
    setBankData([...bankData]);
  };
  const onCancel = () => {
    setBankFormFields({
      ...employeeBankDetails_,
    });
  };
  const onUpdate = async (e) => {
    e.preventDefault();
    if (bankData.length == 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please Add Bank Information",
      });
    } else {
      let formData = new FormData();
      formData.append("UserId", formFields.UserId);
      formData.append("EmployeeId", formFields.EmployeeId);
      formData.append("FirstName", FirstName);
      formData.append("CNIC", CNIC);
      bankData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeBankDetails_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateEmployee(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Bank Information</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>Please provide your Bank Account details.</strong>
          </div>
          <form onSubmit={onAdd}>
            <Row>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Bank Name"
                  name="BankId"
                  value={bankFormFields.BankId}
                  list={list.BankList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChangeSelect}
                  nameValue="Bank"
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Account No"
                  name="AccountNumber"
                  value={bankFormFields.AccountNumber}
                  onChange={handleInputChange}
                  required
                  maxLength="100"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">Upload Attachment</label>
                  <Input
                    type="file"
                    name="UploadAttachment"
                    onChange={handleUploadChange}
                    // required
                    accept=".pdf/,image/jpeg,image/jpg,image/png,text/csv,.docx,.doc,.xls,.txt"
                    id="attachment"
                    ref={ref}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="4"></Col>
              <Col className="text-right" sm="8">
                <Button color="primary">Add/Update</Button>
                <Button color="default" type="button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Bank Details</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={bankData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" onClick={onUpdate}>
                  {submitLoad ? <Spinner color="light" size="sm" /> : null}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default BankDetails;
