import React, { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Button, Col } from "reactstrap";
import {
  academicYearId,
  Delete,
  feesTypeId,
  Insert,
  partYearID,
  Search,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  AlreadyExistAlert,
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteAlert,
  DeleteWithConfirmation,
  SuccessAlert,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { GENERATE_VOUCHER_FOR_ACADEMIC_YEAR } from "../../../utils/UrlConstants";
import ChallanForEnrollment from "../../Admissions/ChallanForEnrollment";

const initialSearchFields = {
  OperationId: Search,
  AcademicYearId: 0,
  StudentDetailid: 0,
  RegNo: "",
  FacultyDeptId: 0,
  FacultyDeptProgramId: 0,
  UserId: 0,
  BpVoucherId: 0,
  PartId: 0,
  SemesterId: 0,
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const initialFormFields = {
  OperationId: Search,
  AcademicYearId: 0,
  StudentDetailid: 0,
  RegNo: "",
  FacultyDeptId: 0,
  FacultyDeptProgramId: 0,
  UserId: 0,
  BpVoucherId: 0,
  PartId: 0,
  SemesterId: 0,
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const GenerateVoucherForAcademicYear = () => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const challanForEnrollment = useRef();
  const timeoutRef = useRef(null);

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);
  const [FeesType] = useSetupDetailList(feesTypeId);

  const [voucherId, setVoucherId] = useState(null);

  const dispatch = useDispatch();

  const getVouchers = () => {
    PostRequest(GENERATE_VOUCHER_FOR_ACADEMIC_YEAR, {
      ...initialFormFields,
      OperationId: 5,
    })
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getVouchers();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const columns = [
    { field: "Reg_No", name: "Reg No" },
    { field: "Name", name: "Name" },
    { field: "Part", name: "Part" },
    { field: "DeprtParogram", name: "Department Program" },
    { field: "PaidOn", name: "Paid On" },
    { field: "p_ConsumerNumber", name: "Consumer Number" },
    { field: "p_DueDate", name: "Due Date" },
    { field: "p_Status", name: "Status" },
    { field: "p_TransactionId", name: "Transaction Id" },
    { field: "p_Amount", name: "Ammount" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  // const searchPanel = (
  //   <Fragment>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="AcademicYear"
  //         name="AcademicYearId"
  //         onChange={handleSearchChange}
  //         value={SearchFields?.AcademicYearId}
  //         list={academicYearList}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department"
  //         name="FacultyDeptId"
  //         onChange={async (e) => {
  //           onChange_Select_Department_Program({
  //             operationID: 6,
  //             caseID: 3,
  //             paremeterID: e.target.value,
  //           }).then((res) => {
  //             dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
  //           });
  //           handleSearchChange(e);
  //         }}
  //         value={SearchFields?.FacultyDeptId}
  //         list={SupportingTables?.Departments}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department Program"
  //         name="FacultyDeptProgramId"
  //         onChange={handleSearchChange}
  //         value={SearchFields?.FacultyDeptProgramId}
  //         list={SupportingTables?.Programs}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         disabled={SearchFields?.FacultyDeptId == null}
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Part"
  //         name="PartId"
  //         onChange={handleSearchChange}
  //         value={SearchFields?.PartId}
  //         list={PartYear}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //       />
  //     </Col>

  //   </Fragment>
  // );

  const formPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="AcademicYear"
          name="AcademicYearId"
          onChange={handleAddChange}
          value={FormFields?.AcademicYearId}
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDeptId"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleAddChange(e);
          }}
          value={FormFields?.FacultyDeptId}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDeptProgramId"
          onChange={handleAddChange}
          value={FormFields?.FacultyDeptProgramId}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          disabled={FormFields?.FacultyDeptId == null}
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartId"
          onChange={handleAddChange}
          value={FormFields?.PartId}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
    </Fragment>
  );

  // const submitSearch = () => {
  //   const payload = {
  //     ...SearchFields,
  //     OperationId: Search
  //   };
  //   PostRequest(GENERATE_VOUCHER_FOR_ACADEMIC_YEAR, payload)
  //     .then((res) => {
  //       dispatch({
  //         type: SET_INITIAL_CRUD_FORM_STATE,
  //         payload: {
  //           List: res?.data?.Table,
  //           FormFields: initialFormFields,
  //           SearchFields: SearchFields,
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const submitForm = () => {
    const payload = {
      ...FormFields,
      operationId: Insert,
      AcademicYearId: 0,
      StudentDetailid: 0,
      RegNo: "",
      FacultyDeptId: 0,
      FacultyDeptProgramId: 0,
      UserId: 0,
      BpVoucherId: 0,
      PartId: 0,
      SemesterId: 0,
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(GENERATE_VOUCHER_FOR_ACADEMIC_YEAR, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getVouchers();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
          return;
        }
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const viewEnrollmentChallan = (index, obj) => {
    PostRequest(GENERATE_VOUCHER_FOR_ACADEMIC_YEAR, {
      ...initialFormFields,
      BpVoucherId: obj.BPVoucherId,
      OperationId: 3,
    })
      .then((res) => {
        setVoucherId(res.data);
        timeoutRef.current = setTimeout(
          () => handlePrintChallanForEnrollment(),
          200
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePrintChallanForEnrollment = useReactToPrint({
    content: () => challanForEnrollment.current,
  });

  // const onDeleteRow = (obj) => {
  //   DeleteWithConfirmation().then((result) => {
  //     if (result.isConfirmed) {
  //       PostRequest(GENERATE_VOUCHER_FOR_ACADEMIC_YEAR, {
  //         ...initialFormFields,
  //         OperationId: Delete,
  //         BpVoucherId: obj.BPVoucherId,
  //       })
  //         .then((res) => {
  //           DeleteAlert();
  //           getVouchers();
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     }
  //   });
  // };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getVouchers();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const customButton = (
    <Fragment>
      <Button color="primary" onClick={submitForm}>
        Generate Voucher For Academic Year
      </Button>
    </Fragment>
  );

  return (
    <>
      <CrudFormComponent
        formName="Generate Voucher For Academic Year"
        // buttonName="Add"
        customButton={customButton}
        modalSize="lg"
        tableColumns={columns}
        tableRows={TableList}
        formPanel={formPanel}
        hideSearchPanel={true}
        formSubmit={submitForm}
        // searchPanel={searchPanel}
        // searchSubmit={submitSearch}
        // onEdit={onEditRow}
        // onDelete={onDeleteRow}
        onGenerateVoucher={viewEnrollmentChallan}
        initialFormFields={initialFormFields}
        featureList={menuTable?.Table2?.filter(
          (x) => x.MenuId === selectedMenu
        )}
        cancelSearch={cancelSearch}
        handleCancel={handleCancel}
      />
      <div style={{ display: "none" }}>
        <ChallanForEnrollment
          ref={challanForEnrollment}
          BPvoucherId={voucherId}
        />
      </div>
    </>
  );
};

export default GenerateVoucherForAcademicYear;
