import React, { forwardRef, Fragment } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import logoimg from "../../assets/img/logo.png";
import postgrad from "../../assets/img/post-graduate.png";

const ReceiptForm = forwardRef((props, ref) => {
  return (
    <div style={{ padding: 30 }} ref={ref}>
      <Container fluid>
        <div className="card-div">
          <table>
            <tbody>
              <tr>
                <td>
                  <img src={logoimg} height="70" />
                </td>
                <td>
                  <div className="text-center">
                    <h4 className="mb-0">
                      DIRECTORATE OF POSTGRADUATE STUDIES
                    </h4>
                    <h4 className="mb-0">
                      SHAH ABDUL LATIF UNIVERSITY KHAIRPUR
                    </h4>
                  </div>
                </td>
                <td className="text-right">
                  <img src={postgrad} height="70" />
                </td>
              </tr>
              {/* <tr>
                <td colSpan={3} className="text-right">
                  <h5 className="mt-3 mb-2">
                    Sr. No:
                    <span style={{ textDecoration: "underline" }}>
                      3001
                    </span>
                  </h5>
                </td>
              </tr> */}
              <tr>
                <td colSpan={3} className="text-center">
                  <h5 style={{ textDecoration: "underline" }} className="mb-3 mt-3">
                    RECEIPT
                  </h5>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <p style={{ fontSize: "18px" }}>
                    Received Admission Form MS/MPhil/PhD of Ms./Mr.
                    _________________________________________
                    ______________________________________ S/o
                    D/o__________________________________________________ Department of
                    ____________________________ for the year-2023.
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="text-right">
                  <p className="mt-5" style={{ fontSize: "18px" }}>
                    Signature of receiving Clerk
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
});

export default ReceiptForm;
