import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../common/dateFormatEnum";
import {
  admissionFacultyTypeId,
  admissionTypeId,
  campusCity,
  campusType,
  correspondanceId,
  department,
  domicileProvinceId,
  facultyDepartmentId,
  facultyTypeId,
  gender,
  nationalityId,
  programTypes,
  religionId,
  SessionStorage,
  shiftTypeId,
  yearId,
} from "../../../common/SetupMasterEnum";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { formatDateFunction } from "../../../functions/DateFormatFunction";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../utils/Config";
import { decryptData } from "../../../EncryptData";
import { ADMISSION_USER_ID, REG_ID } from "../../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { CustomErrorMessage } from "../../../components/Alert";

const initialFormFields = {
  ADMGATGeneralId: 0,
  IsActive: 1,
  IsDeleted: 0,
  MPhilGATScore: "",
  PassingDate: null,
  ValidUptoDate: null,
  ADMRegId: 0,
};

const GATGeneral = (props) => {
  initialFormFields.ADMRegId = decryptData(REG_ID, SessionStorage);
  const dispatch = useDispatch();
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const ADMGATGeneralId = SupportingTables?.students?.Table7[0];
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
    ...ADMGATGeneralId,
  });
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  const [profileImage, setProfileImage] = useState();
  const handleInputChange = (event) => {
    if (event.target.type == "radio") {
      setFormFields({
        ...formFields,
        [event.target.name]: parseInt(event.target.value),
      });
    }
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageUpload = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
    setFormFields({
      ...formFields,
      Picture: event.target.files[0],
    });
  };
  const AllDateSet = (event, type) => {
    if (type === "PassingDate") {
      setFormFields({
        ...formFields,
        PassingDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ValidUptoDate") {
      setFormFields({
        ...formFields,
        ValidUptoDate: formatDateFunction(event, "-"),
      });
    }
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (statusData[0]?.PersonalInfo == 0) {
      CustomErrorMessage("Kindly Complete Admission Application Step Wise");
    } else {
      let formData = new FormData();
      Object.keys(basicValues).forEach((d) => {
        formData.append(d, basicValues[d]);
      });
      Object.keys(formFields).forEach((d) => {
        formData.append(`ADMGATGeneral_.${d}`, formFields[d]);
      });
      updateAdmission(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          getStudendRecords();
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        // setSubmitLoad(false);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: SupportingTables.ProgramId,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        let data = {
          name: "students",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Fragment>
      <form onSubmit={onUpdate}>
        <Card className="mt-3">
          <CardTitle>GAT General/Subject Test Result (HEC approved)</CardTitle>
          <CardBody>
            <Row style={{ alignItems: "center" }}>
              <Col md="3" lg="3">
                <FormGroupInput
                  label={`${
                    SupportingTables?.PostgrauateProgramId == 3653 ||
                    SupportingTables?.PostgrauateProgramId == 3654
                      ? "MPhill GAT (General) Score"
                      : "PhD GAT (Subject) Score"
                  }`}
                  name="MPhilGATScore"
                  value={formFields?.MPhilGATScore}
                  onChange={handleInputChange}
                  isNumber="true"
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <div className="form-group">
                  <label className="form-label">Passing Date</label>
                  <DatePicker
                    selected={new Date(formFields?.PassingDate)}
                    dateFormat={dateFormat}
                    minDate={formFields?.PassingDate}
                    onChange={(e) => AllDateSet(e, "PassingDate")}
                    className="form-control"
                    name="PassingDate"
                    placeholderText={dateFormatPlaceholder}
                    required
                  />
                </div>
              </Col>
              <Col lg="3" md="3" xs="12">
                <div className="form-group">
                  <label className="form-label">Valid Upto</label>
                  <DatePicker
                    selected={new Date(formFields?.ValidUptoDate)}
                    dateFormat={dateFormat}
                    minDate={formFields?.ValidUptoDate}
                    onChange={(e) => AllDateSet(e, "ValidUptoDate")}
                    className="form-control"
                    name="ValidUptoDate"
                    placeholderText={dateFormatPlaceholder}
                    required
                  />
                </div>
              </Col>
              {/* <Col md="3" lg="3">
                <FormGroupInput
                  label="Passing Date"
                  name="Email"
                  value={formFields?.Email}
                  onChange={handleInputChange}
                />
              </Col> */}
              {/* <Col md="3" lg="3">
                <FormGroupInput
                  label="Valid upto"
                  name="Ph"
                  value={formFields?.Ph}
                  onChange={handleInputChange}
                />
              </Col> */}
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={statusData[0]?.Status == 1 ? true : false}
                  >
                    {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment>
  );
};

export default GATGeneral;
