import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  admissionFacultyTypeId,
  admissionTypeId,
  boardId,
  campusCity,
  campusType,
  examinationId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  majorsAdId,
  programTypes,
  SessionStorage,
  totalMarksId,
  yearId,
} from "../../../common/SetupMasterEnum";
import { CustomErrorMessage } from "../../../components/Alert";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../EncryptData";
import { formatDateFunction2 } from "../../../functions/DateFormatFunction";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../utils/Config";
import { ADMISSION_USER_ID, CNIC_BFORM, EMAIL, EMPLOYEE_NAME, LAST_NAME, MIDDLE_NAME, MOBILE, REG_ID } from "../../../utils/EncryptedConstants";

const initialFormFields = {
  AdmARID: 0,
  ADMRegID: 0,
  ExaminationID: 0,
  DegreeName: "",
  MajorSubjects: "",
  YearId: 0,
  YOA: 0,
  YOP: 0,
  SeatNo_RollNumber: "",
  ObtainedMarks: "",
  TotalMarks: "",
  BoardID: 0,
  University: "",
  DepartmentName: "",
  UserIP: "192.168.14.1",
};

const Academics = (props) => {
  initialFormFields.ADMRegID = decryptData(REG_ID, SessionStorage);
  const dispatch = useDispatch();
  const initialProgramAppliedForFields = {
    languageId: 0,
    Year: 0,
    WhatsAppNo: "",
    AlternativeNumber: "",
    LastDegreeFromSalu: "",
    Other: "",
    ProgramId: props.Programid,
    CampusID: 0,
    CampusCityId: 0,
    ProgramTypeId: 0,
    AdmissionTypeId: 0,
    MajorId: 0,
    AdmissionFacultyTypeId: 0,
    AdmissionFacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    ShiftId: 0,
    Name: `${decryptData(EMPLOYEE_NAME, SessionStorage)} ${
      decryptData(MIDDLE_NAME, SessionStorage) === "" ? "" : decryptData(MIDDLE_NAME, SessionStorage)
    } ${decryptData(LAST_NAME, SessionStorage) === "" ? "" : decryptData(LAST_NAME, SessionStorage)}`,
    FatherName: "",
    SurName: "",
    GenderID: 0,
    GuardianName: "",
    Cnic: decryptData(CNIC_BFORM, SessionStorage),
    Picture: "",
    DOB: null,
    PostalAddress: "",
    PermanantAddress: "",
    CorrespondatAddressId: 0,
    NationalityID: 0,
    ReligionID: 0,
    DomicileID: 0,
    DomicileDistricId: 0,
    Ph: "",
    Mobile: decryptData(MOBILE, SessionStorage),
    Email: decryptData(EMAIL, SessionStorage),
    AreEmployeedAnyWhere: 0,
    EmployeedDepartment: "",
    NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase: 0,
    UnderTaking: 0,
    UserIP: "192.168.14.1",
    InterestIn: ""
  };
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const personalInfoData = SupportingTables?.students?.Table1[0];
  const academicRecords = SupportingTables?.students?.Table2;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState(initialFormFields);
  const [programAppliedFor, setProgramAppliedFor] = useState({
    ...initialProgramAppliedForFields,
    ...personalInfoData,
  });
  const [academicData, setAcademicData] = useState([]);
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleProgramAppliedForChange = (event) => {
    if(event.target.name == "AdmissionTypeId"){
      if(event.target.value === 5628){
        
        setProgramAppliedFor({
          ...programAppliedFor,
          [event.target.name]: event.target.value,
          MajorId: 5631
        });
      }
      else if(event.target.value === 5629){
        setProgramAppliedFor({
          ...programAppliedFor,
          [event.target.name]: event.target.value,
          MajorId: 5632
        });
      }
      else if(event.target.value === 5630){
        setProgramAppliedFor({
          ...programAppliedFor,
          [event.target.name]: event.target.value,
          MajorId: 5633
        });
      }
      else{
        setProgramAppliedFor({
          ...programAppliedFor,
          [event.target.name]: event.target.value,
        });
      }
      
    }
    else{
      setProgramAppliedFor({
        ...programAppliedFor,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleInputChangeSelect = (event) => {
    
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
        [event.target.selectedName]:
          event.target.selectedName != undefined && event.target.selectedValue,
      });
      };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  useEffect(() => {
    setAcademicData(academicRecords);
  }, [academicRecords]);
  useEffect(() => {
    setProgramAppliedFor({
      ...initialProgramAppliedForFields,
      ...personalInfoData,
      CampusID : 1284,
      CampusCityId : 1644
    });
  }, [personalInfoData]);

  const columns = [
    { field: "ExaminationName", name: "Examination" },
    { field: "DegreeName", name: "Degree" },
    { field: "MajorSubjects", name: "Group/ Major Subjects" },
    { field: "BoardName", name: "Board" },
    { field: "University", name: "University" },
    { field: "YOAName", name: "Year of Admission" },
    { field: "YOPName", name: "Year of Passing" },
    // { field: "SeatNo_RollNumber", name: "Seat No" },
    { field: "ObtainedMarks", name: "Obtained Marks" },
    { field: "TotalMarks", name: "Total Marks" },
    { field: "Grade_Percentage_CGPA", name: "Grade/Percentage/CGPA" },
  ];

  const onAdd = (e) => {
    e.preventDefault();
    formFields.YearId = formFields.YOP;
    if (formFields.index > -1) {
      academicData[formFields.index] = formFields;
      if (
        parseInt(academicData[formFields.index].ObtainedMarks) >
        parseInt(academicData[formFields.index].TotalMarks)
      ) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Obtained marks should be less then Total marks",
        });
      } else {
        setAcademicData([...academicData]);
        setFormFields({
          ...initialFormFields,
        });
      }
    } else {
      if (
        parseInt(formFields.ObtainedMarks) > parseInt(formFields.TotalMarks)
      ) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Obtained marks should be less then Total marks",
        });
      } else {
        academicData.push({
          ...formFields,
          index: -1,
        });
        setAcademicData([...academicData]);
        setFormFields({
          ...initialFormFields,
        });
      }
    }
  };
  const onEdit = (index, obj) => {
    setFormFields({
      ...obj,
      index: index,
    });
  };
  const onDelete = (index) => {
    academicData.splice(index, 1);
    setAcademicData([...academicData]);
  };
  const onCancel = () => {
    setFormFields({
      ...initialFormFields,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();
    if (statusData[0]?.PersonalInfo == 0 || statusData[0]?.GATGeneralId == 0) {
      CustomErrorMessage("Kindly Complete Admission Application Step Wise");
    } else if (academicData.length == 0) {
      Swal.fire({
        title: "Error",
        text: "Please Add Academic Information",
        icon: "error",
      });
    } else {
      let formData = new FormData();
      Object.keys(basicValues).forEach((d) => {
        formData.append(d, basicValues[d]);
      });
      Object.keys(programAppliedFor).forEach((d) => {
        formData.append(`ADMPersonalInformation_.${d}`, programAppliedFor[d]);
      });
      academicData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `ADMAcademicRecords_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateAdmission(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          getStudendRecords();
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
              getStudendRecords();
            } else if (results.isConfirmed) {
              getStudendRecords();
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: SupportingTables.ProgramId,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        let data = {
          name: "students",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Fragment>
          <form onSubmit={onAdd}>
          <Card className="mt-3">
          <CardTitle>Program Applying For</CardTitle>
          <CardBody>
            <div className="alert alert-warning mb-2">
              <strong>
                Please select Faculty &amp; Department as per Advertisement.
              </strong>
            </div>
            <Row>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Campus Type"
                  name="CampusID"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == campusType &&
                    x.SetupDetailId !== 1285
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={programAppliedFor?.CampusID}
                  onChange={handleProgramAppliedForChange}
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Campus City"
                  name="CampusCityId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == campusCity &&
                      x.parentid == programAppliedFor?.CampusID
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={programAppliedFor?.CampusCityId}
                  onChange={handleProgramAppliedForChange}
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Program Types"
                  name="ProgramTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == programTypes &&
                      // x.SetupDetailId != 3698 &&
                      x.parentid == programAppliedFor?.CampusCityId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={programAppliedFor?.ProgramTypeId}
                  onChange={handleProgramAppliedForChange}
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Admission Type"
                  name="AdmissionTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == admissionTypeId &&
                      x.parentid == programAppliedFor?.ProgramTypeId 
                      &&
                      (x.SetupDetailId == 5628 || x.SetupDetailId == 5629 ||
                      x.SetupDetailId == 5630 )
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={programAppliedFor?.AdmissionTypeId}
                  onChange={handleProgramAppliedForChange}
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Admission Faculty Type"
                  name="AdmissionFacultyTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == admissionFacultyTypeId &&
                      x.parentid == programAppliedFor?.MajorId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={programAppliedFor?.AdmissionFacultyTypeId}
                  onChange={handleProgramAppliedForChange}
                  required
                  disabled={
                    programAppliedFor?.AdmissionTypeId == 0 ||
                    programAppliedFor?.MajorId == 0
                      ? true
                      : false
                  }
                />
              </Col>

              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="AdmissionFacultyDepartmentId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentId &&
                      x.parentid == programAppliedFor?.AdmissionFacultyTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={programAppliedFor?.AdmissionFacultyDepartmentId}
                  onChange={handleProgramAppliedForChange}
                  required
                  disabled={
                    programAppliedFor?.AdmissionTypeId == 0 ||
                    programAppliedFor?.MajorId == 0 ||
                    programAppliedFor?.AdmissionFacultyTypeId == 0
                      ? true
                      : false
                  }
                />
              </Col>
              <Col md="3" lg="3">
                  <FormGroupInput
                    label="Field of Interest"
                    name="InterestIn"
                    value={programAppliedFor?.InterestIn}
                    onChange={handleProgramAppliedForChange}
                    required
                  />
                </Col>
            </Row>
          </CardBody>
        </Card>
      <Card className="mt-3">
        <CardTitle>Academic Details</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>
            You should apply in the Program relevant to your Past Education.
            </strong>
          </div>
            <Row>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Examination"
                  name="ExaminationID"
                  list={SupportingTables?.tables
                    ?.filter((x) => x.SetupMasterId == examinationId)
                    .sort((a, b) => a.Flex1 - b.Flex1)}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.ExaminationID}
                  onChange={handleInputChangeSelect}
                  nameValue="ExaminationName"
                  required
                />
              </Col>
              {formFields?.ExaminationID == 3703 ? (
                <Col md="3" lg="3">
                  <FormGroupInput
                    label="Degree Name"
                    name="DegreeName"
                    value={formFields?.DegreeName}
                    onChange={handleInputChange}
                    required
                  />
                </Col>
              ) : null}
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Group/ Major Subjects"
                  name="MajorSubjects"
                  value={formFields?.MajorSubjects}
                  onChange={handleInputChange}
                />
              </Col>
              {/* <Col md="3" lg="3">
                <FormGroupSelect
                  label="Year"
                  name="YearId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == yearId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.YearId}
                  onChange={handleInputChangeSelect}
                  nameValue="YearName"
                  required
                />
              </Col> */}
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Year of Admission"
                  name="YOA"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == yearId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.YOA}
                  onChange={handleInputChangeSelect}
                  nameValue="YOAName"
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Year of Passing"
                  name="YOP"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == yearId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.YOP}
                  onChange={handleInputChangeSelect}
                  nameValue="YOPName"
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Seat No/ Roll No"
                  name="SeatNo_RollNumber"
                  value={formFields?.SeatNo_RollNumber}
                  onChange={handleInputChange}
                  required
                  isNumber="true"
                  maxLength="10"
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Obtained Marks"
                  name="ObtainedMarks"
                  value={formFields?.ObtainedMarks}
                  onChange={handleInputChange}
                  isNumber="true"
                  required
                  maxLength="5"
                />
              </Col>
              {/* {formFields?.ExaminationID == 3591 ? ( */}
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Total Marks"
                  name="TotalMarks"
                  value={formFields?.TotalMarks}
                  onChange={handleInputChange}
                  isNumber="true"
                  maxLength="5"
                  required
                />
              </Col>
              {/* ) : (
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Total Marks"
                    name="TotalMarksId"
                    list={SupportingTables?.tables?.filter(
                      (x) => x.SetupMasterId == totalMarksId
                    )}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={formFields?.TotalMarksId}
                    onChange={handleInputChangeSelect}
                    nameValue="TotalMarksName"
                  />
                </Col>
              )} */}
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Board"
                  name="BoardID"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == boardId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.BoardID}
                  onChange={handleInputChangeSelect}
                  nameValue="BoardName"
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="School/College/University"
                  name="University"
                  value={formFields?.University}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Grade/Percentage/CGPA"
                  name="Grade_Percentage_CGPA"
                  value={formFields?.Grade_Percentage_CGPA}
                  onChange={handleInputChange}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Add/Update
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
        </CardBody>
      </Card>
          </form>
      <Card className="mt-3">
        <CardTitle>Academic Records</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={academicData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button
                  color="primary"
                  onClick={onUpdate}
                  disabled={statusData[0]?.Status == 1 ? true : false}
                >
                  {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Academics;
