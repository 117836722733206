import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import Header from "../../../../assets/img/header-img-report.jpg";

const CumulativeAttendanceReport = forwardRef((props, ref) => {
  const { TableList } = useSelector((state) => state.CrudFormReducer);

  return (
    <div ref={ref} style={{ fontSize: 12, margin: 6 }}>
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ padding: "0px 150px 5px 150px" }}
                      src={Header}
                      width="80%"
                      height="100%"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      <br />
      <br />
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "2.8%" }}></td>
              <td style={{ fontWeight: "bold", fontSize: 14 }}>
                Academic Session: 2023
              </td>
            </tr>
            <tr>
              <td style={{ width: "2.8%" }}></td>
              <td style={{ fontWeight: "bold", fontSize: 14 }}>
                Degree Program: BSCS/ BSIT/ ….
              </td>
            </tr>
            <br />
            <tr>
              <td style={{ width: "2.8%" }}></td>
              <td style={{ fontWeight: "bold", fontSize: 14 }}>
                Semester: 1/2/3/4/5/6/7/8
              </td>
            </tr>
            <tr>
              <td style={{ width: "2.8%" }}></td>
              <td style={{ fontWeight: "bold", fontSize: 14 }}>
                Report From: 01/01/2023 to 01/03/2023
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 10 }}>
            <table
              style={{
                width: "100%",
                border: "1px",
                borderStyle: "solid",
              }}
            >
              <thead>
                <tr
                  style={{
                    borderBottom: "0.5px solid",
                    color: "black",
                    padding: 5,
                  }}
                >
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    S.No.
                  </th>
                  {TableList?.length > 0
                    ? Object.keys(TableList?.[0])?.map((column, index) => (
                        <Fragment key={index}>
                          <th
                            style={{
                              border: "1px solid black",
                              backgroundColor: "#772647",
                              color: "white",
                            }}
                            className="text-center"
                            key={index + 1}
                          >
                            {column}
                          </th>
                        </Fragment>
                      ))
                    : null}
                </tr>
              </thead>
               <tbody>
                {TableList?.length > 0
                  ? TableList?.map((data, i) => (
                      <tr key={i}>
                        <td
                          style={{ border: "1px solid black" }}
                          className="text-center"
                        >
                          {" "}
                          {i + 1}
                        </td>
                        {Object.values(data)?.map((val, index) => {
                          return (
                            <td
                              style={{ border: "1px solid black" }}
                              className="text-center"
                              key={index}
                            >
                              {val}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default CumulativeAttendanceReport;
