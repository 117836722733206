import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  academicYearId,
  Delete,
  Insert,
  meritlistId,
  Search,
  SessionStorage
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_SEARCH_FIELDS
} from "../../../redux/actionType/CrudActionTypes";
import {
  ADM_EligibilityCriteriaDependency,
  ADM_AddManualOPMLSeatAllocation,
  PostRequest
} from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { ADM_ADD_MANUAL_OPML_SEAT_ALLOCATION, ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../../utils/UrlConstants";

const initialSearchFields = {
  OperationID: Search,
  MeritListID: 0,
  AcademicYearId: 0,
  OfferedProgramId: 0,
  AdmissionFacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  Cnic: "",
  AdmRegId: 0,
  CreatedBy: decryptData("loginId", SessionStorage),
  ModifiedBy: decryptData("loginId", SessionStorage),
  UserIp: decryptData(UserNetworkInfo)?.IPv4
};

const initialFormFields = {
  OperationID: Search,
  MeritListID: 0,
  AcademicYearId: 0,
  OfferedProgramId: 0,
  AdmissionFacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  Cnic: "",
  AdmRegId: 0,
  CreatedBy: decryptData("loginId", SessionStorage),
  ModifiedBy: decryptData("loginId", SessionStorage),
  UserIp: decryptData(UserNetworkInfo)?.IPv4
};

const intialSearchTextFields = {
  AdmRegId: 0,
  Name: "",
  AdmissionFacultyDepartment: "",
  FacultyDepartmentProgram: ""
};

const intialFormTextFields = {
  AdmRegID: 0,
  Name: "",
  AdmissionFacultyDepartment: "",
  FacultyDepartmentProgram: ""
};

const ManualOpMlSeatAllocation = () => {
  const {
    SearchFields,
    FormFields,
    SupportingTables,
    TableList
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [textFields, setTextFields] = useState(intialSearchTextFields);
  const [formTextFields, setFormTextFields] = useState(intialFormTextFields);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields
      }
    });
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 2,
      paremeterID: 0
    });
  }, []);

  const columns = [
    { field: "AdmRegId", name: "Adm RegId" },
    {
      field: "Cnic",
      name: "Cnic"
    },
    { field: "Name", name: "Name" },

    {
      field: "AdmissionFacultyDepartment",
      name: "Admission Faculty Department"
    },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
    { field: "OfferDetails", name: "Offer Details" }
  ];

  const onChange_ADM_EligibilityCriteriaDependency = (payload) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID === 2) {
            const data = { name: "Departments", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID === 3) {
            const data = { name: "Programs", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.name === "AdmissionFacultyDepartmentId") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value
      });
      let data = {
        name: "AdmissionFacultyDepartmentId",
        value: e.target.value
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
    } else if (e.target.name === "FacultyDepartmentProgramId") {
      let selecteValue = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: selecteValue });
    } else {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
    }
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          label="Academic Year"
          name="AcademicYearId"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearId}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Cnic"
          value={SearchFields?.Cnic}
          onChange={handleSearchChange}
          label="Student CNIC"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Merit List"
          name="MeritListID"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == meritlistId
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.MeritListID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="AdmRegId"
          value={textFields?.AdmRegId}
          onChange={handleSearchChange}
          label="Adm RegId"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Name"
          value={textFields?.Name}
          onChange={handleSearchChange}
          label="Name"
          disabled
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="AdmissionFacultyDepartment"
          value={textFields?.AdmissionFacultyDepartment}
          onChange={handleSearchChange}
          label="Applied Department"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="FacultyDepartmentProgram"
          value={textFields?.FacultyDepartmentProgram}
          onChange={handleSearchChange}
          label="Program"
          disabled
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    // ADM_AddManualOPMLSeatAllocation(SearchFields)
    PostRequest(ADM_ADD_MANUAL_OPML_SEAT_ALLOCATION, SearchFields)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table[0]?.Message);
        } else {
          let AdmRegID = {
            name: "AdmRegId",
            value: res?.data?.Table?.[0]?.ADMRegid
          };
          dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: AdmRegID });
          setTextFields({
            ...textFields,
            AdmRegId: res?.data?.Table?.[0]?.ADMRegid,
            Name: res?.data?.Table?.[0]?.Name,
            AdmissionFacultyDepartment:
              res?.data?.Table?.[0]?.AdmissionFacultyDepartment,
            FacultyDepartmentProgram:
              res?.data?.Table?.[0]?.FacultyDepartmentProgram
          });
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: res?.data?.Table1,
              FormFields: initialFormFields,
              SearchFields: SearchFields
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddChange = (e) => {
    if (e.target.name === "AdmissionFacultyDepartmentId") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value
      });
      let data = {
        name: "AdmissionFacultyDepartmentId",
        value: e.target.value
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
    } else if (e.target.name === "FacultyDepartmentProgramId") {
      let selecteValue = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: selecteValue });
    } else {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
    }
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields
    });
    setFormTextFields(intialFormTextFields);
  };

  const handleSearchClick = () => {
    // ADM_AddManualOPMLSeatAllocation(FormFields)
    PostRequest(ADM_ADD_MANUAL_OPML_SEAT_ALLOCATION, FormFields)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table[0]?.Message);
        } else {
          let AdmRegID = {
            name: "AdmRegId",
            value: res?.data?.Table?.[0]?.ADMRegid
          };
          dispatch({ type: SET_CRUD_FROM_FIELDS, payload: AdmRegID });
          setFormTextFields({
            ...formTextFields,
            AdmRegID: res?.data?.Table?.[0]?.ADMRegid,
            Name: res?.data?.Table?.[0]?.Name,
            AdmissionFacultyDepartment:
              res?.data?.Table?.[0]?.AdmissionFacultyDepartment,
            FacultyDepartmentProgram:
              res?.data?.Table?.[0]?.FacultyDepartmentProgram
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          label="Academic Year"
          name="AcademicYearId"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleAddChange}
          value={FormFields?.AcademicYearId}
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="Cnic"
          value={FormFields?.Cnic}
          onChange={handleAddChange}
          label="Student CNIC"
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12" className="verticalAllign">
        <Button className="btn" color="primary" onClick={handleSearchClick}>
          Search
        </Button>
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="AdmRegId"
          value={FormFields?.AdmRegId}
          onChange={handleAddChange}
          label="Adm RegId"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="Name"
          value={formTextFields?.Name}
          onChange={handleAddChange}
          label="Name"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="AdmissionFacultyDepartment"
          value={formTextFields?.AdmissionFacultyDepartment}
          onChange={handleAddChange}
          label="Applied Department"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="FacultyDepartmentProgram"
          value={formTextFields?.FacultyDepartmentProgram}
          onChange={handleAddChange}
          label="Program"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Merit List"
          name="MeritListID"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == meritlistId
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleAddChange}
          value={FormFields?.MeritListID}
          required
        />
      </Col>
      <Col md="4" lg="4" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="AdmissionFacultyDepartmentId"
          list={SupportingTables?.Departments}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.AdmissionFacultyDepartmentId}
          onChange={handleAddChange}
          nameValue="AdmissionFacultyDepartment"
          required
        />
      </Col>
      <Col md="4" lg="4" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramId"
          list={SupportingTables?.Programs}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.FacultyDepartmentProgramId}
          onChange={handleAddChange}
          nameValue="FacultyDepartmentProgram"
          required
        />
      </Col>
    </Fragment>
  );

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields
    });
    setTextFields(intialSearchTextFields);
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields
      }
    });
  };

  const submitForm = () => {
    FormFields.OperationID = Insert;
    // ADM_AddManualOPMLSeatAllocation(FormFields)
    PostRequest(ADM_ADD_MANUAL_OPML_SEAT_ALLOCATION, FormFields)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table[0]?.Message);
        } else {
          CustomSuccessAlert(res?.data?.Table[0]?.Message);
        }
        dispatch({
          type: RESET_FORM_FIELDS,
          payload: initialFormFields
        });
        setFormTextFields(intialFormTextFields);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onRevertRow = (obj) => {
    const payload = {
      OperationID: Delete,
      MeritListID: 0,
      AcademicYearId: 0,
      OfferedProgramId: obj?.OfferedProgramId,
      AdmissionFacultyDepartmentId: 0,
      FacultyDepartmentProgramId: 0,
      Cnic: obj?.Cnic,
      AdmRegId: obj?.AdmRegId,
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: decryptData("loginId", SessionStorage),
      UserIp: decryptData(UserNetworkInfo).IPv4
    };
    // ADM_AddManualOPMLSeatAllocation(payload)
    PostRequest(ADM_ADD_MANUAL_OPML_SEAT_ALLOCATION, payload)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table[0]?.Message);
        } else {
          CustomSuccessAlert(res?.data?.Table[0]?.Message);
          submitSearch();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <CrudFormComponent
      formName="Manual Open Merit List Seat Allocation"
      //buttonName="Generate"
      searchPanel={searchPanel}
      tableColumns={columns}
      tableRows={TableList}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      formPanel={formPanel}
      buttonName="Add"
      modalSize="lg"
      handleCancel={handleCancel}
      formSubmit={submitForm}
      onRevert={onRevertRow}
      // onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default ManualOpMlSeatAllocation;
