import React, { forwardRef, Fragment, useEffect } from "react";
import { Container, Table } from "reactstrap";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { formatDateFunc } from "../../../../functions/DateFormatFunction";
import Logo from "../../../../assets/img/logo.png";

const AcademicsChallan = forwardRef((props, ref) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);

  return (
    <div className="landscape-page" ref={ref}>
      <Container fluid>
        <div style={{ margin: "20px", marginTop: "20px" }} className="card-div">
          <table>
            <tbody>
              <tr>
                <td>
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        width: "100%",
                        border: "1px solid",
                        lineHeight: "0.30",
                        fontSize: "11px",
                      }}
                    >
                      <tbody>
                        <tr className="text-center">
                          <td colSpan={7}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div className="login-brand mt-1">
                                <QRCode
                                  value={`${SupportingTables?.studentVoucher?.p_ConsumerNumber}`}
                                  size={60}
                                />
                              </div>
                              <div className="login-brand">
                                <img height="70" src={Logo} alt="Logo" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>Student Copy</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            className="text-center"
                            colSpan={7}
                          >
                            Bank Challan
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td
                            colSpan={2}
                            style={{
                              width: "35%",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                            className="text-center"
                          >
                            Date of Issue
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              width: "55%",
                              textAlign: "center",
                              fontWeight: "bold",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                          >
                            {formatDateFunc(
                              SupportingTables?.studentVoucher?.p_BillingMonth,
                              "-"
                            )}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              width: "20%",
                              fontSize: 10,
                              textAlign: "center",
                              lineHeight: "10px",
                              justifyContent: "center",
                            }}
                          >
                            No:{" "}
                            {SupportingTables?.studentVoucher?.p_ConsumerNumber}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            colSpan={7}
                            className="text-center"
                          >
                            Habib Bank Limited
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="text-center">
                            GRAIN MARKET BRANCH, SHIKARPUR
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center" colSpan={7}>
                            <p>Please Receive and credit to SAU Shikarpur </p>
                            <br />
                            <p>A/C NO:00817900345903 </p>
                            <br />
                            <p>Sign & Stamp of Sectional Head</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Name:{" "}
                            {SupportingTables?.studentVoucher?.p_CustomerName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            S/O,D/O:{" "}
                            {SupportingTables?.studentVoucher?.FatherName}
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Department:{" "}
                            {SupportingTables?.studentVoucher?.DepartmentName}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "30%", verticalAlign: "middle" }}
                            colSpan={2}
                          >
                            Signature:
                          </td>
                          {/* <td colSpan={2}></td> */}
                          <td
                            style={{ width: "10%", verticalAlign: "middle" }}
                            colSpan={2}
                          >
                            Program:
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              width: "50%",
                              fontSize: 10,
                              lineHeight: "10px",
                            }}
                            colSpan={2}
                          >
                            {
                              SupportingTables?.studentVoucher
                                ?.FacultyDepartmentProgram
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            C.N.I.C/I.D Card No:/Admission No:{" "}
                            {SupportingTables?.studentVoucher?.Cnic}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "70%", lineHeight: "10px" }}
                            colSpan={4}
                          >
                            Purpose of Deposit Detalis on reverse:
                          </td>
                          <td style={{ width: "30%" }} colSpan={4}>
                            Rs.
                          </td>
                        </tr>
                        {SupportingTables?.studentParticularDetail?.length > 0
                          ? SupportingTables?.studentParticularDetail?.map(
                              (x, ind) => {
                                return (
                                  <tr
                                    key={ind}
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <td
                                      colSpan={4}
                                      style={{
                                        lineHeight:
                                          x?.AdmParticular?.length > 17
                                            ? "10px"
                                            : "0px",
                                      }}
                                    >
                                      {ind + 1}) {x.Particular}
                                    </td>
                                    <td
                                      colSpan={4}
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {x.Amount}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                        <tr>
                          <td
                            style={{ fontWeight: "bold", textAlign: "end" }}
                            colSpan={4}
                          >
                            Total Amount =
                          </td>
                          <td
                            colSpan={4}
                            style={{ fontSize: "9px", fontWeight: "bold" }}
                          >
                            {SupportingTables?.studentVoucher?.p_Amount}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "8px",
                              lineHeight: "10px",
                            }}
                            colSpan={7}
                          >
                            Rupees (in words) :{" "}
                            {SupportingTables?.studentVoucher?.AmInWords}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                    <table
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "left", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Cashier</p>
                          </td>
                          <td
                            style={{ textAlign: "right", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Manager </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>

                <div style={{ margin: "3px" }}></div>

                <td>
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        width: "100%",
                        border: "1px solid",
                        lineHeight: "0.30",
                        fontSize: "11px",
                      }}
                    >
                      <tbody>
                        <tr className="text-center">
                          <td colSpan={7}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div className="login-brand mt-1">
                                <QRCode
                                  value={`${SupportingTables?.studentVoucher?.p_ConsumerNumber}`}
                                  size={60}
                                />
                              </div>
                              <div className="login-brand">
                                <img height="70" src={Logo} alt="Logo" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>Bank Copy</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            className="text-center"
                            colSpan={7}
                          >
                            Bank Challan
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td
                            colSpan={2}
                            style={{
                              width: "35%",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                            className="text-center"
                          >
                            Date of Issue
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              width: "55%",
                              textAlign: "center",
                              fontWeight: "bold",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                          >
                            {formatDateFunc(
                              SupportingTables?.studentVoucher?.p_BillingMonth,
                              "-"
                            )}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              width: "20%",
                              fontSize: 10,
                              textAlign: "center",
                              lineHeight: "10px",
                              justifyContent: "center",
                            }}
                          >
                            No:{" "}
                            {SupportingTables?.studentVoucher?.p_ConsumerNumber}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            colSpan={7}
                            className="text-center"
                          >
                            Habib Bank Limited
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="text-center">
                            GRAIN MARKET BRANCH, SHIKARPUR
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center" colSpan={7}>
                            <p>Please Receive and credit to SAU Shikarpur </p>
                            <br />
                            <p>A/C NO:00817900345903 </p>
                            <br />
                            <p>Sign & Stamp of Sectional Head</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Name:{" "}
                            {SupportingTables?.studentVoucher?.p_CustomerName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            S/O,D/O:{" "}
                            {SupportingTables?.studentVoucher?.FatherName}
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Department:{" "}
                            {SupportingTables?.studentVoucher?.DepartmentName}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "30%", verticalAlign: "middle" }}
                            colSpan={2}
                          >
                            Signature:
                          </td>
                          {/* <td colSpan={2}></td> */}
                          <td
                            style={{ width: "10%", verticalAlign: "middle" }}
                            colSpan={2}
                          >
                            Program:
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              width: "50%",
                              fontSize: 10,
                              lineHeight: "10px",
                            }}
                            colSpan={2}
                          >
                            {
                              SupportingTables?.studentVoucher
                                ?.FacultyDepartmentProgram
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            C.N.I.C/I.D Card No:/Admission No:{" "}
                            {SupportingTables?.studentVoucher?.Cnic}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "70%", lineHeight: "10px" }}
                            colSpan={4}
                          >
                            Purpose of Deposit Detalis on reverse:
                          </td>
                          <td style={{ width: "30%" }} colSpan={4}>
                            Rs.
                          </td>
                        </tr>
                        {SupportingTables?.studentParticularDetail?.length > 0
                          ? SupportingTables?.studentParticularDetail?.map(
                              (x, ind) => {
                                return (
                                  <tr
                                    key={ind}
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <td
                                      colSpan={4}
                                      style={{
                                        lineHeight:
                                          x?.AdmParticular?.length > 17
                                            ? "10px"
                                            : "0px",
                                      }}
                                    >
                                      {ind + 1}) {x.Particular}
                                    </td>
                                    <td
                                      colSpan={4}
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {x.Amount}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                        <tr>
                          <td
                            style={{ fontWeight: "bold", textAlign: "end" }}
                            colSpan={4}
                          >
                            Total Amount =
                          </td>
                          <td
                            colSpan={4}
                            style={{ fontSize: "9px", fontWeight: "bold" }}
                          >
                            {SupportingTables?.studentVoucher?.p_Amount}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "8px",
                              lineHeight: "10px",
                            }}
                            colSpan={7}
                          >
                            Rupees (in words) :{" "}
                            {SupportingTables?.studentVoucher?.AmInWords}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                    <table
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "left", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Cashier</p>
                          </td>
                          <td
                            style={{ textAlign: "right", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Manager </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>

                <div style={{ margin: "3px" }}></div>

                <td>
                  <div>
                    <Table
                      bordered
                      responsive
                      style={{
                        width: "100%",
                        border: "1px solid",
                        lineHeight: "0.30",
                        fontSize: "11px",
                      }}
                    >
                      <tbody>
                        <tr className="text-center">
                          <td colSpan={7}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div className="login-brand mt-1">
                                <QRCode
                                  value={`${SupportingTables?.studentVoucher?.p_ConsumerNumber}`}
                                  size={60}
                                />
                              </div>
                              <div className="login-brand">
                                <img height="70" src={Logo} alt="Logo" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>University Copy</td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            className="text-center"
                            colSpan={7}
                          >
                            Bank Challan
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td
                            colSpan={2}
                            style={{
                              width: "35%",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                            className="text-center"
                          >
                            Date of Issue
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              width: "55%",
                              textAlign: "center",
                              fontWeight: "bold",
                              justifyContent: "center",
                              lineHeight: "10px",
                            }}
                          >
                            {formatDateFunc(
                              SupportingTables?.studentVoucher?.p_BillingMonth,
                              "-"
                            )}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              width: "20%",
                              fontSize: 10,
                              textAlign: "center",
                              lineHeight: "10px",
                              justifyContent: "center",
                            }}
                          >
                            No:{" "}
                            {SupportingTables?.studentVoucher?.p_ConsumerNumber}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ fontWeight: "bold" }}
                            colSpan={7}
                            className="text-center"
                          >
                            Habib Bank Limited
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="text-center">
                            GRAIN MARKET BRANCH, SHIKARPUR
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center" colSpan={7}>
                            <p>Please Receive and credit to SAU Shikarpur </p>
                            <br />
                            <p>A/C NO:00817900345903 </p>
                            <br />
                            <p>Sign & Stamp of Sectional Head</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Name:{" "}
                            {SupportingTables?.studentVoucher?.p_CustomerName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            S/O,D/O:{" "}
                            {SupportingTables?.studentVoucher?.FatherName}
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "middle" }}>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            Department:{" "}
                            {SupportingTables?.studentVoucher?.DepartmentName}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "30%", verticalAlign: "middle" }}
                            colSpan={2}
                          >
                            Signature:
                          </td>
                          {/* <td colSpan={2}></td> */}
                          <td
                            style={{ width: "10%", verticalAlign: "middle" }}
                            colSpan={2}
                          >
                            Program:
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              width: "50%",
                              fontSize: 10,
                              lineHeight: "10px",
                            }}
                            colSpan={2}
                          >
                            {
                              SupportingTables?.studentVoucher
                                ?.FacultyDepartmentProgram
                            }
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={7}>
                            C.N.I.C/I.D Card No:/Admission No:{" "}
                            {SupportingTables?.studentVoucher?.Cnic}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{ width: "70%", lineHeight: "10px" }}
                            colSpan={4}
                          >
                            Purpose of Deposit Detalis on reverse:
                          </td>
                          <td style={{ width: "30%" }} colSpan={4}>
                            Rs.
                          </td>
                        </tr>
                        {SupportingTables?.studentParticularDetail?.length > 0
                          ? SupportingTables?.studentParticularDetail?.map(
                              (x, ind) => {
                                return (
                                  <tr
                                    key={ind}
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <td
                                      colSpan={4}
                                      style={{
                                        lineHeight:
                                          x?.AdmParticular?.length > 17
                                            ? "10px"
                                            : "0px",
                                      }}
                                    >
                                      {ind + 1}) {x.Particular}
                                    </td>
                                    <td
                                      colSpan={4}
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {x.Amount}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : null}
                        <tr>
                          <td
                            style={{ fontWeight: "bold", textAlign: "end" }}
                            colSpan={4}
                          >
                            Total Amount =
                          </td>
                          <td
                            colSpan={4}
                            style={{ fontSize: "9px", fontWeight: "bold" }}
                          >
                            {SupportingTables?.studentVoucher?.p_Amount}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              fontSize: "8px",
                              lineHeight: "10px",
                            }}
                            colSpan={7}
                          >
                            Rupees (in words) :{" "}
                            {SupportingTables?.studentVoucher?.AmInWords}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                    <table
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "left", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Cashier</p>
                          </td>
                          <td
                            style={{ textAlign: "right", width: "50px" }}
                            colSpan={2}
                          >
                            <p>Manager </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
});

export default AcademicsChallan;
