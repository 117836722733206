import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Insert,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostFormRequest, PostRequest } from "../../../utils/Config";
import {
  ACAD_UPLOAD_ATTENDANCE_FILE_BY_CANDIDATE,
  CURRENT_SEMESTER,
} from "../../../utils/UrlConstants";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import { Button, Input, Table, Tooltip } from "reactstrap";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../../../components/Alert";

const StudentAttandance = () => {
  const initialFormFields = {
    OperationId: 2,
    ParameterID: 0,
    StudentDetailID: 0,
    CourseSemesterMappingID: 0,
    AttendanceRecordID: 0,
    FilePath: "",
    FileName: "",
    UserID: decryptData("loginId", SessionStorage),
    StatusID: 0
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();
  const [formFields, setFormFields] = useState({ ...initialFormFields });
  const [formLoader, setFormLoader] = useState(false);
  const [saveToolTip, setSaveToolTip] = useState(false);

  const timeoutRef = useRef(null);

  useEffect(() => {
    setFormLoader(true);
    getSemesterAttandance();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getSemesterAttandance = () => {
    const payload = {
      operationId: Select,
      studentDetailID: params?.id1,
      courseSemesterMappingID: params?.id2,
      parameterID: Insert,
      userID: 0,
    };
    PostRequest(CURRENT_SEMESTER, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1,
          },
        });
        let studentDetails = {
          name: "StudentDetails",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: studentDetails,
        });
        timeoutRef.current = setTimeout(() => setFormLoader(false), 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDocChange = (e, index) => {
    setFormFields({
      ...formFields,
      FilePath: e.target.files[0],
      FileName: e.target.files[0].name,
    });
    TableList[index]["FilePath"] = e.target.files[0];
    TableList[index]["FileName"] = e.target.files[0].name;
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: TableList,
      },
    });
    // setFacultyLecturerUploadCourseLecturesTimeSlotMapping_FacultyOps_TBL({
    //   FilePath: e.target.files[0],
    //   FileName: e.target.files[0].name,
    // });
  };

  const onSave = (e, item) => {
    debugger
    e.preventDefault();
    formFields.AttendanceRecordID = item.AttandanceRecordID;
    let formData = new FormData();
    Object.keys(formFields).forEach((d) => {
      formData.append(d, formFields[d]);
    });
    WarningWithConfirmation(
      `Are you sure you want to submit course files?`
    ).then((result) => {
      if (result.isConfirmed) {
        PostFormRequest(ACAD_UPLOAD_ATTENDANCE_FILE_BY_CANDIDATE, formData)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              // getFacultyExamRecap();
              CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
              
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const toggle = (data) => {
    if (data === "Save") {
      setSaveToolTip(!saveToolTip);
    }
  };

  const columns = [
    { field: "DayDate", name: "Day Date" },
    { field: "Attandance", name: "Attandance" },
    { field: "Action", name: "Action" },
  ];

  const customTable = (
    <Table
      bordered
      striped
      responsive
      style={{ width: "100%" }}
      className="text-center"
    >
      <thead>
        <tr>
          <th>S.No.</th>
          {columns &&
            columns.map((column, index) => (
              <th
                style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                key={index}
              >
                {column.name}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {TableList?.map((item, index) => (
          <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td className="text-center">{item.DayDate}</td>
            <td className="text-center">{item.Attandance}</td>
            {item.Attandance == "Absent" ? (
              <td style={{ width: "20%" }}>
                <div className="form-group" style={{ display: "flex" }}>
                  <Input
                    id="LectureFile"
                    style={{ width: "85%", marginRight: "12px" }}
                    type="file"
                    name="FilePath"
                    onChange={(e) => handleDocChange(e, index)}
                    accept=".pdf/,image/jpeg,image/jpg,image/png,text/csv,.docx,.doc,.xls,.txt"
                    // required
                  />
                  <Tooltip
                    placement="bottom"
                    isOpen={saveToolTip}
                    target="Confirm"
                    toggle={() => toggle("Save")}
                  >
                    Save Detail
                  </Tooltip>
                  <Button
                    color="success"
                    className="btnic"
                    size="sm"
                    onClick={(e) => onSave(e, item)}
                    id="Confirm"
                    
                    // disabled={Object(item?.FilePath)?.length > 0 ? false : true}
                  >
                    <i className= {`${item.length > 1 ?'fa fa-floppy-o' : 'fa fa-check'}`}></i>
                  </Button>
                </div>
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <Fragment>
      <AcademicFormComponent
        SemesterName="Regestered Courses Fall 2022"
        topColumns={SupportingTables?.StudentDetails}
        // columns={columns}
        // rows={TableList}
        formLoader={formLoader}
        customTable={customTable}
      />
    </Fragment>
  );
};

export default StudentAttandance;
