import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { SessionStorage } from "../../../common/SetupMasterEnum";
import { CustomErrorMessage } from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../utils/Config";
import { ADMISSION_USER_ID, REG_ID } from "../../../utils/EncryptedConstants";

const Undertaking = (props) => {
  const dispatch = useDispatch();
  const initialFormFields = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking: 0,
  };
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
    ...underTakingData,
  });
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.checked == true ? 1 : 0,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();
    if (
      statusData[0]?.PersonalInfo == 0 ||
      statusData[0]?.GATGeneralId == 0 ||
      statusData[0]?.Academics == 0 ||
      statusData[0]?.EmployementId == 0 ||
      statusData[0]?.Documents == 0
    ) {
      CustomErrorMessage("Kindly Complete Admission Application Step Wise");
    } else {
      let formData = new FormData();
      Object.keys(formFields).forEach((d) => {
        formData.append(d, formFields[d]);
      });
      updateAdmission(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          getStudendRecords();
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: SupportingTables.ProgramId,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        let data = {
          name: "students",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Undertaking Details</CardTitle>
        <CardBody>
          <form onSubmit={onUpdate}>
            <Row>
              <Col>
                <div className="form-check-inline mt-3">
                  <label className="form-check-Label form-label">
                    <Input
                      type="checkbox"
                      checked={formFields?.UnderTaking == 1 ? true : false}
                      className="form-check-Input form-check-input"
                      name="UnderTaking"
                      onChange={handleInputChange}
                      value={formFields?.UnderTaking}
                    />
                    <span style={{ fontSize: 15, marginLeft: 8 }}>
                      I{" "}
                      <input
                        type="text"
                        className="bord-under"
                        value={underTakingData?.Name}
                        disabled
                        style={{ textAlign: "center", fontWeight: "600" }}
                      />{" "}
                      S/O - D/O{" "}
                      <input
                        type="text"
                        className="bord-under"
                        value={underTakingData?.FatherName}
                        disabled
                        style={{ textAlign: "center", fontWeight: "600" }}
                      />{" "}
                      hereby undertake that the information given above is
                      correct and complete to the best of my knowledge and that
                      is the case of passing all required tests my admission is
                      provisional and is subject to cancellation if any
                      irregularity is found in my admission form / documents.
                      Further, I undertake that my name is not registered /
                      enrolled as postgraduate student in any field of the study
                      at any other Institute / University. I also undertake to
                      abide by the rules and regulations of the Shah Abdul Latif
                      University, Khairpur.
                    </span>
                    {/* <span style={{ fontSize: 15, marginLeft: 8 }}>
                     {underTakingData?.UnderTakingDetails}
                    </span> */}
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      formFields?.UnderTaking == false ||
                      statusData[0]?.Status == 1
                        ? true
                        : false
                    }
                  >
                    {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Undertaking;
