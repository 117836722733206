import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import logoimg from "../../../../assets/img/header-img-report.jpg";
import { tConvert } from "../../../../functions/DateFormatFunction";

const CCRSubReport = forwardRef((props, ref) => {
  const { TableList } = useSelector((state) => state.CrudFormReducer);

  return (
    <div ref={ref} style={{ fontSize: 15, margin: 6 }}>
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={logoimg} height="100" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row>
        <table style={{ width: "100%", marginLeft: 40, marginTop: 30 }}>
          <tbody>
            <tr>
              <td>
                <div>
                  <div>Academic Year: Data from Active Clicked Record</div>
                </div>
              </td>
              <td>
                <div>
                  <div>Reporting From: xx/xx/xxxx to xx/xx/xxxx</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <div>
                    <p style={{ marginBottom: 2 }}>
                      <strong>Degree Program: BS(CS)/BS(IT)…...</strong>
                    </p>
                    <p style={{ marginBottom: 2 }}>
                      <strong>Semester-I, II ….. VIII</strong>
                    </p>
                    <p style={{ marginBottom: 2 }}>
                      <strong>Course Code:</strong>
                    </p>
                    <p style={{ marginBottom: 2 }}>
                      <strong>Course Title:</strong>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 14 }}>
            <table
              style={{
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      padding: 5,
                      colspan: 3,
                    }}
                  ></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: 5,
                    }}
                  ></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: 5,
                    }}
                  ></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: 5,
                    }}
                  ></th>
                  <th
                    style={{
                      textAlign: "center",
                      backgroundColor: "#833a58",
                      color: "#fff",
                      padding: 5,
                    }}
                    colSpan={2}
                  >
                    Time
                  </th>
                </tr>
                <tr
                  style={{
                    borderBottom: "0.5px solid",
                    color: "black",
                    padding: 5,
                  }}
                >
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    S.No.
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    Date (Conduct){" "}
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    CHs (Due)
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    CHs (Cond:)
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    From
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    To
                  </th>
                </tr>
              </thead>
              <tbody>
                {TableList?.Table?.map((item, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        border: "1px",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        border: "1px",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      {item.ClassDate}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        border: "1px",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      {item.ChsDue}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        border: "1px",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      {item.ChsCond}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        border: "1px",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      {tConvert(item.StartTime)}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        border: "1px",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      {tConvert(item.EndTime)}
                    </td>
                  </tr>
                ))}
                <br />
                <tr style={{ marginTop: 10 }}>
                  <td style={{ border: "0 !important" }}>{""}</td>
                  <td
                    style={{
                      textAlign: "left",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 4,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    Classes Due
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 4,
                    }}
                  >
                    {" "}
                    <strong>21</strong>{" "}
                  </td>
                </tr>
                <tr>
                  <td style={{ borderleft: "unset !important" }}>{""}</td>

                  <td
                    style={{
                      textAlign: "left",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 4,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    Classes Conduted
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 4,
                    }}
                  >
                    {" "}
                    <strong>16</strong>{" "}
                  </td>
                </tr>
                <tr>
                  <td style={{ borderleft: "unset !important" }}> {""}</td>

                  <td
                    style={{
                      textAlign: "left",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 4,
                      backgroundColor: "#833a58",
                      color: "#fff",
                    }}
                  >
                    % Age
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 4,
                    }}
                  >
                    {" "}
                    <strong>Classes Due</strong>{" "}
                  </td>
                </tr>
                <tr>
                  <td> </td>
                </tr>
                <br />

                <tr>
                  <td> </td>
                  <td style={{ fontSize: "18px" }}>chairman</td>
                </tr>
                <tr>
                  <td> </td>
                  <td style={{ fontSize: "18px" }}>"Name of Dept"</td>
                </tr>
              </tbody>
            </table>
            <table style={{ marginTop: 30, marginLeft: 100 }}></table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default CCRSubReport;
