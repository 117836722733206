import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  UncontrolledCollapse,
} from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  allowancesSetupMasterId,
  Delete,
  Insert,
  Search,
  Select,
  setupMasterId,
  Update,
  allowOnlyString,
  benefitsSetupId,
  degreeSetupId,
  citySetupId,
  countrySetupId,
  SessionStorage,
} from "../../../common/SetupMasterEnum";

import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  PostRequest,
  Setup_Journal,
  Setup_MasterDetails_Operation,
} from "../../../utils/Config";

import {
  SuccessAlert,
  DeleteAlert,
  AlreadyExistAlert,
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";

import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import { decryptData } from "../../../EncryptData";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import {
  COMPANY,
  JOURNALS,
  TRANSACTION_TYPE,
} from "../../../utils/UrlConstants";

import logo_image from "../../../assets/img/upload_image.jpg";

const Company = () => {
  const initialSearchFields = {
    OperationID: Select,
    CompanyID: 0,
    ParentCompanyID: 0,
    Company: "",
    IsParent: true,
    Address: "",
    Icon: "",
    Header: "",
    Footer: "",
    Phone1: "",
    Phone2: "",
    RepresentativePhone: "",
    Ntn: "",
    Stn: "",
    IsActive: true,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialFormFields = {
    OperationID: Insert,
    CompanyID: 0,
    ParentCompanyID: 0,
    Company: "",
    IsParent: true,
    Address: "",
    Icon: "",
    Header: "",
    Footer: "",
    Phone1: "",
    Phone2: "",
    RepresentativePhone: "",
    Ntn: "",
    Stn: "",
    IsActive: true,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();

  const { TransType } = SupportingTables;

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
    getCompany();
  }, []);

  function getCompany() {
    PostRequest(COMPANY, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1.map((x) => ({
              ...x,
              IsActive: x.IsActive ? "Active" : "InActive",
            })),
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "Company", name: "Company Name" },
    { field: "Address", name: "Address" },
    { field: "Phone1", name: "Telephone" },
    { field: "Ntn", name: "Ntn No" },
    { field: "Stn", name: "Stn No" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="4" xs="12">
        <FormGroupSelect
          label="Trans Type"
          name="TransTypeID"
          list={TransType}
          fieldId="TransTypeID"
          fieldName="TransType"
          value={SearchFields?.TransTypeID}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="2" md="2" xs="12">
        <FormGroupInput
          label="Journal Code"
          name="JournalCode"
          required
          onChange={handleSearchChange}
          value={SearchFields?.JournalCode}
        />
      </Col>
      <Col lg="2" md="2" xs="12">
        <FormGroupInput
          label="Journal Name"
          name="JournalName"
          required
          onChange={handleSearchChange}
          value={SearchFields?.JournalName}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Associated Journal"
          name="AssociatedJournalID"
          onChange={handleSearchChange}
          value={SearchFields?.AssociatedJournalID}
        />
      </Col>
      <Col lg="2" md="2" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="IsActive"
          value={SearchFields?.IsActive}
          onChange={handleSearchChange}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      OperationID: Search,
    };
    PostRequest(JOURNALS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table.map((x) => ({
              ...x,
              IsActive: x.IsActive ? "Active" : "InActive",
            })),
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      ...FormFields,
      OperationId: id,
      // JournalID: FormFields.JournalID,
      UserID: decryptData("loginId", SessionStorage),
    };

    PostRequest(COMPANY, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data?.Table[0]?.HasError === 0) {
          CustomSuccessAlert(res.data?.Table1[0]?.MESSAGE);
          getCompany();
        } else {
          CustomErrorMessage(res.data?.Table[0]?.MESSAGE);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    let data = {
      TransTypeID: obj?.TransTypeID,
      JournalCode: obj?.JournalCode,
      JournalID: obj?.JournalID,
      JournalName: obj?.JournalName,
      AssociatedJournalID: obj?.AssociatedJournalID,
      IsActive: obj?.IsActive === "Active" ? true : false,
      UserIP: obj?.UserIP,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {};

  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getCompany();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Row>
        <Col lg="9" md="3" sm="9" xs="12">
          <Row>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="Company Name"
                name="Company"
                value={FormFields?.Company}
                onChange={handleAddChange}
                required
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="Header"
                name="Header"
                required
                onChange={handleAddChange}
                value={FormFields?.Header}
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="Footer"
                name="Footer"
                required
                onChange={handleAddChange}
                value={FormFields?.Footer}
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="Representative Phone"
                name="RepresentativePhone"
                required
                onChange={handleAddChange}
                value={FormFields?.RepresentativePhone}
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="Phone One"
                name="Phone1"
                value={FormFields?.Phone1}
                onChange={handleAddChange}
                required
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="Phone Two"
                name="Phone2"
                required
                onChange={handleAddChange}
                value={FormFields?.Phone2}
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="NTN"
                name="Ntn"
                required
                onChange={handleAddChange}
                value={FormFields?.Ntn}
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <FormGroupInput
                label="STN"
                name="Stn"
                required
                onChange={handleAddChange}
                value={FormFields?.Stn}
              />
            </Col>
            <Col lg="2" md="2" xs="12">
              <FormGroupCheckbox
                label=" Is Parent"
                name="IsParent"
                value={FormFields?.IsParent}
                onChange={handleAddChange}
              />
            </Col>
            <Col lg="2" md="2" xs="12">
              <FormGroupCheckbox
                label="Is Active"
                name="IsActive"
                value={FormFields?.IsActive}
                onChange={handleAddChange}
              />
            </Col>
            <Col lg="12" md="12" xs="12">
              <FormGroupInput
                label="Address"
                name="Address"
                type="textarea"
                maxLength={255}
                required
                onChange={handleAddChange}
                value={FormFields?.Address}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="3" md="3" sm="3" xs="12" className="text-center">
          <div className="profile-info text-center mb-2">
            <div className="profile-img-wrap image_layout_upload">
              <img
                src={""}
                className="rounded-square"
                width={160}
                height={160}
                alt="Employee"
                name="icon"
              />
              <div className="fileupload">
                <img src={logo_image} alt="" />
                <Input
                  className="upload"
                  type="file"
                  accept="image/*"
                  color="default"
                  name="icon"
                  onChange={""}
                />
              </div>
            </div>
          </div>

          <div className="mt-0">
            <Button color="primary" className="mt-0" style={{ float: "right" }}>
              Clear
            </Button>
          </div>
        </Col>
      </Row>
      <Row></Row>
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Company"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      modalStyle={{ minWidth: "60vw", width: "60%" }}
    />
  );
};

export default Company;
