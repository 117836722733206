import React, { Fragment } from "react";
import { forwardRef } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import logoimg from "../../../../assets/img/logo.png";
import { Select } from "../../../../common/SetupMasterEnum";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../../redux/actionType/CrudActionTypes";
import { Admission_MeritList } from "../../../../utils/Config";

const MeritListReport = forwardRef((props, ref) => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  return (
    <div ref={ref} className="card-div" style={{ fontSize: 12, padding: 15 }}>
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "10%" }} className="text-center">
                {/* <img src={logoimg} height="90" /> */}
              </td>
              <td style={{ width: "15%" }} className="text-center">
                <img src={logoimg} height="100" />
              </td>

              <td style={{ width: "60%" }}>
                <h3 className="text-center">
                  SHAIKH AYAZ UNIVERSITY, SHIKARPUR
                </h3>
                <h6 className="text-center">
                  Merit List For The Academic Year 2023
                </h6>
              </td>
              <td style={{ width: "20%" }} className="text-center"></td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col>
          <div className="Section1 " style={{ padding: "20px", fontSize: 10 }}>
            <table
              style={{
                width: "100%",
                border: "1px",
                borderStyle: "solid",
              }}
            >
              <thead>
                <tr
                  style={{
                    borderBottom: "0.5px solid",
                    // lineHeight: "30px",
                    color: "white",
                    backgroundColor: "black",
                  }}
                >
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    S.No
                  </th>

                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Cnic
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    FatherName
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    FacultyDepartment
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Program
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Merit Score
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Prefrence Order
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Offered
                  </th>
                </tr>
              </thead>
              <tbody>
                {TableList?.map((x, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom: "0.5px solid",
                      lineHeight: "20px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                        padding: "10px",
                      }}
                    >
                      {x.Cnic}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.Name}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.FatherName}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.FacultyDepartment}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.Program}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.MeritScore}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.PreferenceOrder}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.Offered}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default MeritListReport;
