import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Label } from "reactstrap";
import {
  monthId,
  Select,
  SessionStorage,
  yearId,
} from "../../../common/SetupMasterEnum";
import CrudTMSComponent from "../../../components/FormComponents/CrudTMSComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import { CalendarSpacing } from "../../../functions/CalendarSpacing";
import { formatDateFunction2 } from "../../../functions/DateFormatFunction";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Setup_MasterDetails_All_Dropdowns,
  TMS_ViewCalendarMonth,
} from "../../../utils/Config";

const initialSearchFields = {
  YearId: 0,
  MonthId: 0,
  EmployeeId: "",
};

const ViewCalendar = () => {
  const {
    SearchFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [formLoad, setFormLoad] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getMasterDetailAllDropdown();
  }, []);
  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        SearchFields: {
          ...initialSearchFields,
          EmployeeId: decryptData("EmplId", SessionStorage),
        },
      },
    });
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: Select,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "ShiftDayTypeName", name: "Sun" },
    { field: "ShiftDayTypeName", name: "Mon" },
    { field: "ShiftDayTypeName", name: "Tue" },
    { field: "ShiftDayTypeName", name: "Wed" },
    { field: "ShiftDayTypeName", name: "Thu" },
    { field: "ShiftDayTypeName", name: "Fri" },
    { field: "ShiftDayTypeName", name: "Sat" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3">
        <Label>
          Month & Year <span className="text-danger">*</span>
        </Label>
        <div className="input-type-group">
          <FormGroupSelect
            name="MonthId"
            onChange={handleSearchChange}
            value={SearchFields?.MonthId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == monthId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
          <FormGroupSelect
            name="YearId"
            onChange={handleSearchChange}
            value={SearchFields?.YearId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == yearId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
        </div>
      </Col>
    </Fragment>
  );
  const submitSearch = () => {
    setFormLoad(true);
    TMS_ViewCalendarMonth(SearchFields)
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            // FormFields: initialFormFields,
            SearchFields: {
              ...SearchFields,
              EmployeeId: decryptData("EmplId", SessionStorage),
            },
          },
        });
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  };
  const calendarTable = (
    <div
      className="calendar-view"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {columns?.map((a, i) => (
        <div
          key={i}
          className="table-box-header"
          style={{ width: `${100 / 7}%` }}
        >
          {a.name}
        </div>
      ))}
      {TableList?.length > 0 ? (
        <Fragment>
          {TableList?.sort((a, b) => a.DayNum - b.DayNum).map((item, index) =>
            item.DayNum === "1" ? (
              <>
                {CalendarSpacing(item.Day_Name)}
                <div
                  className={
                    item.Day_Name == "Saturday"
                      ? "table-box Saturday"
                      : "table-box" && item.Day_Name == "Sunday"
                      ? "table-box Sunday"
                      : "table-box"
                  }
                  key={index}
                  style={{ width: `${100 / 7}%` }}
                >
                  <p className="mb-2">
                    <b>{item.DayNum}</b>
                  </p>
                  <p className="mb-0">{item.ShiftDayTypeName}</p>
                  <p>{item.ShiftName}</p>
                  <p>{formatDateFunction2(item.Date)}</p>
                </div>
              </>
            ) : (
              <div
                className={
                  item.Day_Name == "Saturday"
                    ? "table-box Saturday"
                    : "table-box" && item.Day_Name == "Sunday"
                    ? "table-box Sunday"
                    : "table-box"
                }
                key={index}
                style={{ width: `${100 / 7}%` }}
              >
                <p className="mb-2">
                  <b>{item.DayNum}</b>
                </p>
                <p>{item.ShiftDayTypeName}</p>
                <p>{item.ShiftName}</p>
                <p>{formatDateFunction2(item.Date)}</p>
              </div>
            )
          )}
        </Fragment>
      ) : (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            background: "#e9e9e9",
            marginTop: 0,
            padding: 20,
            fontWeight: "bold",
          }}
        >
          No Data Available
        </div>
      )}
    </div>
  );
  const submitForm = (id) => {};

  const onEditRow = (obj) => {};

  const onDeleteRow = (obj) => {};

  return (
    <CrudTMSComponent
      formName="View Calendar"
      hideAction={true}
      hideSerialNumber={true}
      formLoad={formLoad}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      calendarTable={calendarTable}
      // initialFormFields={initialFormFields}
      // featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default ViewCalendar;
