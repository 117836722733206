import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import Spinner from "react-spinkit";
import { useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import AcademicFeeStatusReport from "../Reports/ControllerExamination/AcademicFeeStatusReport";
import { PostRequest } from "../../../utils/Config";
import { ACAD_FEES_STATUS } from "../../../utils/UrlConstants";
import {
  academicYearId,
  partYearID,
  Search,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import { decryptData } from "../../../EncryptData";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { formatDateFunc } from "../../../functions/DateFormatFunction";

const AcademicFeeStatus = () => {
  const { SearchFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const {
    SupportingTables: { AcademicFeeStatusList, AcademicFeeListStatusWise },
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [toggleForm, setToggleForm] = useState(false);
  const initialSearchFields = {
    facultyDepartmentID: 0,
    facultyDepartmentProgramID: 0,
    partID: 0,
    academicYearID: 0,
    statusID: 1,
    feeStatus: "0",
  };

  const StatusFees = [
    {
      SetupDetailId: 2,
      SetupDetailName: "Paid",
    },
    {
      SetupDetailId: 1,
      SetupDetailName: "UnPaid",
    },
  ];

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const printFeesStatusReport = useRef();

  const [formLoad, setFormLoad] = useState(true);

  useEffect(() => {
    getFeesStatus();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

  function getFeesStatus() {
    PostRequest(ACAD_FEES_STATUS, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table?.map((x) => ({
              ...x,
              PaidOn: (x.PaidOn, "-"),
            })),
            SearchFields: initialSearchFields,
          },
        });
        let data = {
          name: "AcademicFeeStatusList",
          value: res?.data?.Table1,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  // const searchPanel = (
  //   <Fragment>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Academic Year"
  //         name="academicYearID"
  //         list={academicYearList}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         onChange={handleSearchChange}
  //         value={SearchFields?.academicYearID}
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Part"
  //         name="partID"
  //         onChange={handleSearchChange}
  //         value={SearchFields?.partID}
  //         list={PartYear}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department"
  //         name="facultyDepartmentID"
  //         onChange={async (e) => {
  //           onChange_Select_Department_Program({
  //             operationID: 6,
  //             caseID: 3,
  //             paremeterID: e.target.value,
  //           }).then((res) => {
  //             dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
  //           });
  //           handleSearchChange(e);
  //         }}
  //         value={SearchFields?.facultyDepartmentID}
  //         list={SupportingTables?.Departments}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department Program"
  //         name="facultyDepartmentProgramID"
  //         onChange={handleSearchChange}
  //         value={SearchFields?.facultyDepartmentProgramID}
  //         list={SupportingTables?.Programs}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         disabled={SearchFields?.facultyDepartmentID == null}
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupSelect
  //         label="Status"
  //         name="statusID"
  //         onChange={handleSearchChange}
  //         value={SearchFields?.statusID}
  //         list={StatusFees}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //       />
  //     </Col>
  //   </Fragment>
  // );

  const columns = [
    { field: "Program", name: "Program", rowSpan: 2, colSpan: 1 },
    { field: "Part1", name: "Part I", rowSpan: 1, colSpan: 3 },
    { field: "Part2", name: "Part II", rowSpan: 1, colSpan: 3 },
    { field: "Part3", name: "Part III", rowSpan: 1, colSpan: 3 },
    { field: "Part4", name: "Part IV", rowSpan: 1, colSpan: 3 },
    { field: "SubTotal", name: "Sub. Total", rowSpan: 1, colSpan: 3 },
    {
      field: "Financial",
      name: "Financial (millions)",
      rowSpan: 1,
      colSpan: 3,
    },
  ];
  const columns2 = [
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "E", name: "E" },
    { field: "P", name: "P" },
    { field: "U", name: "U" },
    { field: "Deposited", name: "Deposited" },
    { field: "Rem:", name: "Rem:" },
    { field: "Total", name: "TOTAL" },
  ];
  const columns3 = [
    { field: "Reg_No", name: "Reg No" },
    { field: "Name", name: "Name" },
    { field: "FatherName", name: "Father Name" },
    { field: "Gender", name: "Gender" },
    { field: "Part", name: "Part"},
    { field: "p_ConsumerNumber", name: "Consumer Number" },
    { field: "FeePaidDate", name: "Paid Date" },
    { field: "p_TransactionId", name: "Transaction Id" },
    { field: "p_Amount", name: "Amount" },
    { field: "Status", name: "Status" },
  ];

  const handlePrintReport = useReactToPrint({
    content: () => printFeesStatusReport.current,
  });

  const customButton = (
    <Fragment>
      <Button color="primary" onClick={handlePrintReport}>
        Print Fee Status Report
      </Button>
      <div style={{ display: "none" }}>
        <AcademicFeeStatusReport ref={printFeesStatusReport} />
      </div>
    </Fragment>
  );

  // const submitSearch = () => {
  //   const payload = {
  //     ...SearchFields,
  //     statusID: SearchFields?.statusID == 2 ? 0 : SearchFields?.statusID,
  //     OperationId: Search,
  //   };

  //   PostRequest(ACAD_FEES_STATUS, payload)
  //     .then((res) => {
  //       dispatch({
  //         type: SET_INITIAL_CRUD_FORM_STATE,
  //         payload: {
  //           List: res?.data?.Table?.map((x) => ({
  //             ...x,
  //             PaidOn: formatDateFunc(x.PaidOn, "-"),
  //           })),
  //           FormFields: initialSearchFields,
  //           SearchFields: SearchFields,
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };
  
  const OnVoucher = (e, programId, part, status) => {
    e.preventDefault();
    setToggleForm(true);
    const payload = {
      facultyDepartmentID: 0,
      facultyDepartmentProgramID: programId,
      partID: part,
      academicYearID: 0,
      statusID: 2,
      feeStatus: status,
    };
    PostRequest(ACAD_FEES_STATUS, payload)
      .then((res) => {
        let data = {
          name: "AcademicFeeListStatusWise",
          value: res?.data?.Table?.map((x) => ({
            ...x,
            FeePaidDate: (x.FeePaidDate, "-"),
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getFeesStatus();
  };

  const onCancel = () => {
    setToggleForm(false);
    let data = {
      name: "AcademicFeeListStatusWise",
      value: [],
    };
    dispatch({
      type: SET_INITIAL_DROPDOWN_FORM_STATE,
      payload: data,
    });
  };

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th rowSpan={2} colSpan={1}>
                S.No.
              </th>
              {columns?.map((column, index) => (
                <th
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  key={index}
                  rowSpan={column.rowSpan}
                  colSpan={column.colSpan}
                >
                  {column.name}
                </th>
              ))}
            </tr>
            <tr>
              {columns2?.map((column, index) => (
                <th
                  style={{
                    backgroundColor: "#a9a6a6",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  key={index}
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TableList?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.FacultyDepartmentProgram}</td>
                <td>
                  {item.P1Enrolled === 0 ? (
                    item.P1Enrolled
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 1, "E")}
                    >
                      {item.P1Enrolled}
                    </a>
                  )}
                </td>
                <td>
                  {item.P1Paid === 0 ? (
                    item.P1Paid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 1, "P")}
                    >
                      {item.P1Paid}
                    </a>
                  )}
                </td>
                <td>
                  {item.P1Unpaid === 0 ? (
                    item.P1Unpaid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 1, "U")}
                    >
                      {item.P1Unpaid}
                    </a>
                  )}
                </td>
                <td>
                  {item.P2Enrolled === 0 ? (
                    item.P2Enrolled
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 2, "E")}
                    >
                      {item.P2Enrolled}
                    </a>
                  )}
                </td>
                <td>
                  {item.P2Paid === 0 ? (
                    item.P2Paid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 2, "P")}
                    >
                      {item.P2Paid}
                    </a>
                  )}
                </td>
                <td>
                  {item.P2Unpaid === 0 ? (
                    item.P2Unpaid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 2, "U")}
                    >
                      {item.P2Unpaid}
                    </a>
                  )}
                </td>
                <td>
                  {item.P3Enrolled === 0 ? (
                    item.P3Enrolled
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 3, "E")}
                    >
                      {item.P3Enrolled}
                    </a>
                  )}
                </td>
                <td>
                  {item.P3Paid === 0 ? (
                    item.P3Paid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 3, "P")}
                    >
                      {item.P3Paid}
                    </a>
                  )}
                </td>
                <td>
                  {item.P3Unpaid === 0 ? (
                    item.P3Unpaid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 3, "U")}
                    >
                      {item.P3Unpaid}
                    </a>
                  )}
                </td>
                <td>
                  {item.P4Enrolled === 0 ? (
                    item.P4Enrolled
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 4, "E")}
                    >
                      {item.P4Enrolled}
                    </a>
                  )}
                </td>
                <td>
                  {item.P4Paid === 0 ? (
                    item.P4Paid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 4, "P")}
                    >
                      {item.P4Paid}
                    </a>
                  )}
                </td>
                <td>
                  {item.P4Unpaid === 0 ? (
                    item.P4Unpaid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 4, "U")}
                    >
                      {item.P4Unpaid}
                    </a>
                  )}
                </td>
                <td>
                  {item.TotalEnrolled === 0 ? (
                    item.TotalEnrolled
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 5, "E")}
                    >
                      {item.TotalEnrolled}
                    </a>
                  )}
                </td>
                <td>
                  {item.TotalPaid === 0 ? (
                    item.TotalPaid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 5, "P")}
                    >
                      {item.TotalPaid}
                    </a>
                  )}
                </td>
                <td>
                  {item.TotalUnpaid === 0 ? (
                    item.TotalUnpaid
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => OnVoucher(e, item.ProgramID, 5, "U")}
                    >
                      {item.TotalUnpaid}
                    </a>
                  )}
                </td>
                <td>{item.Deposited}</td>
                <td>{item.Remaining}</td>
                <td>{item.Total}</td>
                {/* <td>{item["Programming Fundamentals Lab"]}</td>
                <td>{item["Programming Fundamentals"]}</td> */}
              </tr>
            ))}
            <tr rowSpan={2}>
              <td colSpan={13}></td>

              <td
                style={{
                  background: "#a9a6a6",
                  color: "#fff",
                  fontWeight: "600",
                }}
              >
                TOTAL
              </td>
              {AcademicFeeStatusList?.map((item, ind) => (
                <Fragment key={ind}>
                  <td style={{ background: "#a9a6a6", color: "#fff" }}>
                    {item.Totalenrolled}
                  </td>
                  <td style={{ background: "#a9a6a6", color: "#fff" }}>
                    {item.Totalpaid}
                  </td>
                  <td style={{ background: "#a9a6a6", color: "#fff" }}>
                    {item.Totalunpaid}
                  </td>
                  <td style={{ background: "#a9a6a6", color: "#fff" }}>
                    {item.TotalDeposited}
                  </td>
                  <td style={{ background: "#a9a6a6", color: "#fff" }}>
                    {item.TotalRemaining}
                  </td>
                  <td style={{ background: "#a9a6a6", color: "#fff" }}>
                    {item.SubTotal}
                  </td>
                </Fragment>
              ))}
            </tr>
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  const customModal = (
    <Fragment>
      <Modal
        size="xl"
        // style={{ minWidth: "98vw", width: "100%", margin: "2vh" }}
        isOpen={toggleForm}
        centered
        modalTransition={{ timeout: 10 }}
      >
        <ModalHeader>Student Fee Detail List</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table bordered striped responsive style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    {columns3?.map((column, index) => (
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        key={index}
                      >
                        {column.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {AcademicFeeListStatusWise?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.Reg_No}</td>
                      <td>{item.Name}</td>
                      <td>{item.FatherName}</td>
                      <td>{item.Gender}</td>
                      <td style={{ width: '7%'}}>{item.Part}</td>
                      <td>{item.ConsumerNumber}</td>
                      <td>{item.FeePaidDate}</td>
                      <td>{item.TransactionId}</td>
                      <td>{item.Amount}</td>
                      <td>{item.Status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="mt-2"
            >
              <Button color="default" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
  
  return (
    <CrudFormComponent
      formName="Fee Status"
      customButton={customButton}
      hideAction={true}
      tableColumns={columns}
      // tableRows={TableList}
      // searchPanel={searchPanel}
      // searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
      hideSearchPanel
      customModal={customModal}
    />
  );
};

export default AcademicFeeStatus;
