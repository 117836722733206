import React, { Fragment } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import FormGroupInput from '../../../components/GeneralComponent/FormGroupInput'

const ProposedOfInstitutionDepartment = () => {
  return (
    <Fragment>
        <form>
        <Card className="mt-3">
          <CardTitle>Proposed Institutions/Departments</CardTitle>
          <CardBody>
          <div className="alert alert-warning mb-2">
              <strong>
                Name of the Institution(s)/Department(s) at Shah Abdul Latif University, Khairpur where the applicant proposed to work.
              </strong>
            </div>
            <Row>
              <Col md="6" lg="6">
                <FormGroupInput
                  label="Name of Institute/Department"
                  name="FatherName"
                //   value={formFields?.FatherName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button
                    color="primary"
                    type="submit"
                    // disabled={
                    //   formFields?.UnderTaking == false ||
                    //   statusData[0]?.Status == 1
                    //     ? true
                    //     : false
                    // }
                  >
                    {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        </form>
        </Fragment>

  )
}

export default ProposedOfInstitutionDepartment