import React, { Fragment } from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { saveAs } from "file-saver";
import UndergraduateProspectus from "../../../src/assets/docx/Prospectus-2022-1.pdf";
import SemesterFeeStructure from "../../../src/assets/docx/Semester-Fee-Structure-Batch-2024.pdf";
import {SaveFile} from "../../utils/SaveFile";

const initialFormFields = {
  ProgramType: 0,
};

const Prospectus = () => {
  const [formFields, setFormFields] = useState(initialFormFields);

  const handleInputChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };

  const downloadUndergraduateProspectus = () => {
    saveAs(UndergraduateProspectus, "Prospectus-2022-1.pdf");
  };

  const downloadSemesterFeeStructure = () => {
    saveAs(SemesterFeeStructure, "Semester-Fee-Structure-Batch-2024.pdf");
  };
  return (
    <Container fluid>
      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
             Fee Structure Per Semester Batch 2024
            </Col>
          </Row>
        </CardTitle>
      </Card>
      <Card>
        <CardBody>
          <Table bordered striped responsive>
            <tbody>
              {/* <tr>
                <td>Undergraduate Studies</td>
                <td class="text-right">
                  <Button onClick={() => {SaveFile(UndergraduateProspectus,"Prospectus-2022-1.pdf")}}>
                    Download
                  </Button>
                </td>
              </tr> */}
               <tr>
                <td><h5>Fee Structure Per Semester Batch 2024</h5></td>
                <td class="text-right">
                  <Button onClick={() => {SaveFile(SemesterFeeStructure,"Semester-Fee-Structure-Batch-2024.pdf")}}>
                    Download
                  </Button>
                </td>
              </tr>
              {/* <tr>
                <td>Directorate of Postgraduate Studies</td>
                <td class="text-right">
                  <Button>Download</Button>
                </td>
              </tr> */}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Prospectus;
