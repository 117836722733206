import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import DatePicker from "react-datepicker";
import { Setup_UpdateEmployee } from "../../../../utils/Config";
import Swal from "sweetalert2";
import {
  formatDateFunction,
  formatDateFunction2,
} from "../../../../functions/DateFormatFunction";
import { calculateAge } from "../../../../functions/CalculateAgeFunction";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../../common/dateFormatEnum";
import { useParams } from "react-router";
import {
  cityId,
  countryId,
  SessionStorage,
} from "../../../../common/SetupMasterEnum";
import { decryptData } from "../../../../EncryptData";

const PersonalInfo = (props) => {
  const { id } = useParams();
  const EmplId =
    id == 0
      ? decryptData("EmplId", SessionStorage)
      : decryptData("EmplGetId", SessionStorage);
  const LoginId = decryptData("loginId", SessionStorage);
  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    FirstName: "",
    CNIC: "",
    EmployeeProfile_: [],
    EmployeeEmergencyContactDetail_: [],
    EmployeeFamilyDetails_: [],
  };
  const employeeProfile_ = {
    EmployeeId: EmplId,
    EmployeeCode: "",
    CostCenterId: 0,
    DomainId: 0,
    PreviousEmployeeCode: "",
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    FatherName: "",
    CountryId: 0,
    CityId: 0,
    ReligionId: 0,
    GenderId: 0,
    NationalityID: 0,
    MatrialStatusId: 0,
    DateOfBirth: null,
    CountryOfBirthId: 0,
    PlaceOfBirthId: 0,
    DomicileDistrict: "",
    HighestDegreeid: 0,
    TotalExperienceId: 0,
    CNIC: "",
    CNICIssueDate: null,
    CNICExpiryDate: null,
    PassportNumber: "",
    FilerNonFiler: 0,
    NTN: "",
    Ssn: "",
    Age: "",
    PrimaryCellNo: "",
    HomePhoneNo: "",
    WorkEmail: "",
    SecondaryCellNo: "",
    OfficialPhoneNo: "",
    PersonalEmail: "",
    CurrentAddress: "",
    PermanentAddress: "",
    CorrespondenceAddressId: 0,
    SoftwareLoginId: "",
    FileNo: "",
    EmployeeSectorId: 0,
    ProvinceId: 0,
    Actual_DesignationId: 0,
    CampusTypeId: 0,
    CampusCityId: 0,
    // EmployeeTypeId: 0,
    EmployeeCateId: 0,
    facultyTypeId: 0,
    UserTypeId: 0,
    EmployementTypeId: 0,
    EmployementStatusId: 0,
    HiringDepartmentId: 0,
    HiringDesignationId: 0,
    HiringEmployeeGradeId: 0,
    AdditionalChargeId: 0,
    WorkingDesignationId: 0,
    WorkingDepartmentId: 0,
    WorkingGradeId: 0,
    DrawingDesignationId: 0,
    DrawingDepartmentId: 0,
    DrawingGradeId: 0,
    StatusId: 0,
    IdStatusId: 0,
    PayRollTypeId: 0,
    JoiningDate: "",
    ProbotionStartDate: "",
    ProbationEndDate: "",
    ProbotionExtendToDate: "",
    ProbationPeriodDays: 0,
    ContractStartDate: "",
    ContractEndDate: "",
    ConfirmationDate: "",
    RelievingRetirementDate: "",
    TMSShiftId: 0,
    Reportingto: 0,
    DesignationofRA: "",
    CardNumber: 0,
    AccessCardId: 0,
    CollectReasonId: 0,
    AccessCardIssueDate: "",
    AccessCardCollectDate: "",
    PriodEndDate: 0,
    InchargeId: 0,
    NoticePeriodDays: 0,
    Fax: "",
    IsContractual: 0,
    ImageName: "",
    ImageFolderName: "",
    ImagePath: "",
    TmsLeaveId: 0,
    ResignedDate: 0,
    NoOfChildren: 0,
    TotalNoOfLeaves: 0,
    DiseaseDate: "",
    LastworkingDate: "",
    TerminationTypeId: 0,
    NoticePeriodTypeId: 0,
    HODID: 0,
    OfficialMobileNumber: "",
    ManagerId: 0,
    PersonalityDescription: "",
    OfficeExtension: "",
    LeaveTypeId: 0,
    PayScaleId: 0,
    Remarks: "",
    CadreId: 0,
    IsDeleted: 0,
    IsActive: 1,
    InchargeId: 0,
    // CreatedBy: 0,
    // ModifiedBy: LoginId,
    UserIP: "192.168.12.23",
  };
  const employeeEmergencyContactDetail_ = {
    EmployeeId: EmplId,
    EcdId: 0,
    PersonName: "",
    ContactNo: "",
    EmailAddress: "",
    RelationShipId: 0,
    RelationShip: "",
    Address: "",
    IsActive: 1,
    IsDeleted: 0,
    // ModifiedBy: LoginId,
    // CreatedBy: 0,
    UserIP: "192.168.1656",
  };
  const employeeFamilyDetails_ = {
    EmployeeId: EmplId,
    EmployeeFamilyId: 0,
    FamilyMemberName: "",
    RelationShipid: 0,
    RelationShip: "",
    Age: 0,
    FamilyMemberDBO: null,
    FamilyMemberQualificationid: 0,
    FamilyMemberQualification: "",
    FamilyMemberProfessionId: 0,
    FamilyMemberProfession: "",
    CadreId: 0,
    IsActive: 1,
    UserIP: "192.168.1656",
    IsDeleted: 0,
    // ModifiedBy: LoginId,
    // CreatedBy: 0,
  };
  const formData = props?.data;
  const list = props?.list;
  const emergencyData = props?.emergencyData;
  const familyData = props?.familyData;
  const formLoad = props?.formLoad;
  const myList = { tables: props?.resData };
  const [profileFormFields, setProfileFormFields] = useState({
    ...employeeProfile_,
    ...formData,
  });
  const [emergencyprofileFormFields, setEmergencyprofileFormFields] = useState({
    ...employeeEmergencyContactDetail_,
    ...emergencyData,
  });
  const [familyprofileFormFields, setFamilyprofileFormFields] = useState({
    ...employeeFamilyDetails_,
  });

  const [familyMemberData, setFamilyMemberData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);

  useEffect(() => {
    setFamilyMemberData(familyData);
  }, [familyData]);

  useEffect(() => {
    setProfileFormFields({ ...employeeProfile_, ...formData });
  }, [formData]);
  useEffect(() => {
    setEmergencyprofileFormFields({
      ...emergencyprofileFormFields,
      ...emergencyData,
    });
  }, [emergencyData]);
  const [formFields, setFormFields] = useState({
    ...initialValues,
    EmployeeProfile_: profileFormFields,
    EmployeeEmergencyContactDetail_: [emergencyprofileFormFields],
    EmployeeFamilyDetails_: [familyMemberData],
  });
  const [cnicss, setCnicss] = useState(null);
  const handleInputChange = (event) => {
    setProfileFormFields({
      ...profileFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleFamilyChange = (event) => {
    setFamilyprofileFormFields({
      ...familyprofileFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleFamilyChangeSelect = (event) => {
    let obj = {
      ...familyprofileFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setFamilyprofileFormFields(obj);
  };
  const handleEmergencyChange = (event) => {
    setEmergencyprofileFormFields({
      ...emergencyprofileFormFields,
      [event.target.name]: event.target.value,
    });
  };

  const columns = [
    { field: "FamilyMemberName", name: "Name" },
    { field: "RelationShip", name: "Relationship" },
    { field: "Age", name: "Age" },
    { field: "FamilyMemberDBO", name: "DOB" },
    { field: "FamilyMemberQualification", name: "Qualification" },
    { field: "FamilyMemberProfession", name: "Profession" },
  ];

  const AllDateSet = (event, type) => {
    if (type === "DateOfBirth") {
      let format = formatDateFunction(event, "/");
      let age = calculateAge(format);
      profileFormFields.Age = age;
      setProfileFormFields({
        ...profileFormFields,
        DateOfBirth: formatDateFunction(event, "-"),
      });
    } else if (type === "CNICIssueDate") {
      setCnicss(event);
      setProfileFormFields({
        ...profileFormFields,
        CNICIssueDate: formatDateFunction(event, "-"),
      });
    } else if (type === "CNICExpiryDate") {
      setProfileFormFields({
        ...profileFormFields,
        CNICExpiryDate: formatDateFunction(event, "-"),
      });
    } else if (type === "FamilyMemberDBO") {
      let format = formatDateFunction(event, "/");
      let age = calculateAge(format);
      familyprofileFormFields.Age = age;
      setFamilyprofileFormFields({
        ...familyprofileFormFields,
        FamilyMemberDBO: event,
      });
    }
  };
  const onAdd = (e) => {
    e.preventDefault();
    familyprofileFormFields.FamilyMemberDBO = formatDateFunction2(
      familyprofileFormFields.FamilyMemberDBO
    );
    familyprofileFormFields.index > -1
      ? (familyMemberData[familyprofileFormFields.index] =
          familyprofileFormFields)
      : familyMemberData.push({
          ...familyprofileFormFields,
          index: -1,
        });
    setFamilyMemberData([...familyMemberData]);
    setFamilyprofileFormFields({
      ...employeeFamilyDetails_,
    });
  };
  const onEdit = (index, obj) => {
    // obj.FamilyMemberDBO = formatDateFunction(obj.FamilyMemberDBO, "-")
    setFamilyprofileFormFields({
      ...obj,
      FamilyMemberDBO: new Date(obj.FamilyMemberDBO),
      index: index,
    });
  };
  const onDelete = (index) => {
    familyMemberData.splice(index, 1);
    setFamilyMemberData([...familyMemberData]);
  };
  const onCancel = () => {
    setFamilyprofileFormFields({
      ...employeeFamilyDetails_,
    });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (familyMemberData.length == 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please Add Family Member Information",
      });
    } else {
      let formData = new FormData();
      let emerData = [emergencyprofileFormFields];
      formData.append("UserId", formFields.UserId);
      formData.append("EmployeeId", formFields.EmployeeId);
      formData.append("FirstName", formFields.FirstName);
      formData.append("CNIC", formFields.CNIC);
      Object.keys(profileFormFields).forEach((d) => {
        formData.append(`EmployeeProfile_.${d}`, profileFormFields[d]);
      });
      emerData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeEmergencyContactDetail_[${index}].${item}`,
            element[item]
          );
        });
      });
      familyMemberData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeFamilyDetails_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateEmployee(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };
  return (
    <>
      {formLoad ? (
        <div className="form-load">
          <Spinner color="light" size="md" />
        </div>
      ) : null}

      <form id="Form1" onSubmit={onUpdate}></form>
      <form id="Form2" onSubmit={onAdd}></form>
      <Card className="mt-3">
        <CardTitle>Basic Info</CardTitle>
        <CardBody>
          <Row>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                label="First Name"
                name="FirstName"
                onChange={handleInputChange}
                value={profileFormFields.FirstName}
                required
                form="Form1"
                maxLength="50"
                isAlphabetic="true"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                label="Middle Name"
                name="MiddleName"
                value={profileFormFields.MiddleName}
                onChange={handleInputChange}
                form="Form1"
                maxLength="50"
                isAlphabetic="true"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                name="LastName"
                value={profileFormFields.LastName}
                label="Last Name"
                onChange={handleInputChange}
                form="Form1"
                maxLength="50"
                isAlphabetic="true"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                name="FatherName"
                value={profileFormFields.FatherName}
                label="Father/Husband Name"
                onChange={handleInputChange}
                required
                form="Form1"
                maxLength="100"
                isAlphabetic="true"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="ReligionId"
                list={list.ReligionList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={profileFormFields.ReligionId}
                label="Religion"
                onChange={handleInputChange}
                required
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="NationalityID"
                list={list.NationalityList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={profileFormFields.NationalityID}
                label="Nationality"
                onChange={handleInputChange}
                required
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="GenderId"
                list={list.GenderList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={profileFormFields.GenderId}
                label="Gender"
                onChange={handleInputChange}
                required
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="MatrialStatusId"
                list={list.MaritalStatusList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={profileFormFields.MatrialStatusId}
                label="Marital Status"
                onChange={handleInputChange}
                required
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <div className="form-group">
                <label className="form-label">
                  Date Of Birth<span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={new Date(profileFormFields.DateOfBirth)}
                  onChange={(e) => AllDateSet(e, "DateOfBirth")}
                  className="form-control"
                  name="DateOfBirth"
                  // value={formatDateFunc(profileFormFields.DateOfBirth, "/")}
                  required={true}
                  showYearDropdown={true}
                  dateFormat={dateFormat}
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
              {/* <FormGroupDatePicker
                type="date"
                required
                label="Date Of Birth"
                name="DateOfBirth"
                onChange={handleInputChange}
                value={profileFormFields.DateOfBirth}
                placeholder="dd-mm-yyyy"
                // value={profileFormFields.DateOfBirth}
                form="Form1"
              /> */}
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                name="Age"
                value={profileFormFields?.Age?.toString()}
                // isNumber="true"
                label="Age"
                disabled
                // onChange={handleInputChange}
                form="Form1"
                // required
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                label="Country of Birth"
                list={myList?.tables?.filter(
                  (x) => x.SetupMasterId == countryId
                )}
                name="CountryOfBirthId"
                value={profileFormFields.CountryOfBirthId}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                onChange={handleInputChange}
                required
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                label="City of Birth"
                list={myList?.tables?.filter(
                  (x) =>
                    x.SetupMasterId == cityId &&
                    x.parentid == profileFormFields.CountryOfBirthId
                )}
                name="PlaceOfBirthId"
                value={profileFormFields.PlaceOfBirthId}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                onChange={handleInputChange}
                disabled={!profileFormFields.CountryOfBirthId}
                // required
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                name="DomicileDistrict"
                value={profileFormFields.DomicileDistrict}
                label="Domicile District"
                onChange={handleInputChange}
                required
                isAlphabetic="true"
                form="Form1"
                maxLength="100"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="HighestDegreeid"
                list={list.HighestDegreeList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={profileFormFields.HighestDegreeid}
                label="Highest Degree"
                onChange={handleInputChange}
                form="Form1"
              />
            </Col>
            {/* <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupDatePicker
                required
                label="Highest Degree Completion Date"
                name="HighestDegreeid"
                onChange={handleInputChange}
                value={profileFormFields.HighestDegreeid}
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                label="Subject/ Discipline of Highest Degree"
                list={list.SubjectList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                onChange={handleInputChange}
                name="Subject"
                // value={profileFormFields.}
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                label="Name of University of Highest Degree"
                onChange={handleInputChange}
                name="NameUniversity"
                // value={profileFormFields.}
              />
            </Col>
            <Col lg="6" md="6" sm="6" xs="12">
              <FormGroupInput
                label="Thesis of Highest Degree"
                onChange={handleInputChange}
                name="Thesis"
                // value={profileFormFields.th}
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                label="Country of Highest Degree"
                onChange={handleInputChange}
                name="CountryId"
                value={profileFormFields.CountryId}
              />
            </Col>

            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="No. of MS/ M.Phil Students Supervised"
                onChange={handleInputChange}
                name="NoOfMs"
                // value={profileFormFields.n}
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="No. of PhD Students Supervised"
                onChange={handleInputChange}
                name="NoOfPhd"
                // value={profileFormFields.NoOfPhd}
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                label="Area of Expertise/Competencies"
                list={list.AreaList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                onChange={handleInputChange}
                name="AreaOf"
                // value={profileFormFields.}
              />
            </Col>  
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                label="Total Teaching and Research Experience"
                list={totalteaching}
                fieldId="id"
                fieldName="name"
                onChange={handleInputChange}
                name="totalExperience"
                // value={profileFormFields.to}
              />
            </Col> */}
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                label="Total Experience"
                list={list.ExperienceList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                onChange={handleInputChange}
                name="TotalExperienceId"
                value={profileFormFields.TotalExperienceId}
                // required
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="CNIC"
                onChange={handleInputChange}
                name="CNIC"
                value={profileFormFields.CNIC}
                required
                form="Form1"
                maxLength="13"
                minLength="10"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <div className="form-group">
                <label className="form-label">
                  CNIC Issue Date<span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={new Date(profileFormFields.CNICIssueDate)}
                  onChange={(e) => AllDateSet(e, "CNICIssueDate")}
                  className="form-control"
                  name="CNICIssueDate"
                  // value={formatDateFunc(profileFormFields.CNICIssueDate, "/")}
                  required={true}
                  showYearDropdown={true}
                  dateFormat={dateFormat}
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
              {/* <FormGroupDatePicker
                required
                label="CNIC Issue Date"
                name="CNICIssueDate"
                onChange={handleInputChange}
                value={profileFormFields.CNICIssueDate}
                // value={profileFormFields.CNICIssueDate}
                form="Form1"
              /> */}
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <div className="form-group">
                <label className="form-label">
                  CNIC Expiry Date<span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={new Date(profileFormFields.CNICExpiryDate)}
                  onChange={(e) => AllDateSet(e, "CNICExpiryDate")}
                  className="form-control"
                  name="CNICExpiryDate"
                  // value={formatDateFunc(profileFormFields.CNICExpiryDate, "/")}
                  required={true}
                  showYearDropdown={true}
                  // minDate={new Date(profileFormFields.CNICIssueDate)}
                  dateFormat={dateFormat}
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
              {/* <FormGroupDatePicker
                required
                label="CNIC Expiry Date"
                name="CNICExpiryDate"
                onChange={handleInputChange}
                value={profileFormFields.CNICExpiryDate}
                form="Form1"
              /> */}
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                label="Passport Number"
                onChange={handleInputChange}
                value={profileFormFields.PassportNumber}
                name="PassportNumber"
                form="Form1"
                maxLength="15"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="FilerNonFiler"
                value={profileFormFields.FilerNonFiler}
                label="Filer"
                list={list.FilerNonFilterList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                onChange={handleInputChange}
                form="Form1"
                required
              />
            </Col>
            {profileFormFields.FilerNonFiler == 2377 ? (
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  isNumber="true"
                  label="NTN (Filer Only)"
                  onChange={handleInputChange}
                  name="NTN"
                  value={profileFormFields.NTN}
                  form="Form1"
                  maxLength="25"
                  required={
                    profileFormFields.FilerNonFiler == 2377 ? true : false
                  }
                />
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Contact Details</CardTitle>
        <CardBody>
          <Row>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="Primary Cell#"
                onChange={handleInputChange}
                name="PrimaryCellNo"
                value={profileFormFields.PrimaryCellNo}
                form="Form1"
                maxLength="15"
                required
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="Secondary Cell#"
                onChange={handleInputChange}
                name="SecondaryCellNo"
                value={profileFormFields.SecondaryCellNo}
                form="Form1"
                maxLength="15"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="Home Phone#"
                onChange={handleInputChange}
                name="HomePhoneNo"
                value={profileFormFields.HomePhoneNo}
                form="Form1"
                maxLength="15"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="Official Phone#"
                onChange={handleInputChange}
                name="OfficialPhoneNo"
                value={profileFormFields.OfficialPhoneNo}
                form="Form1"
                maxLength="15"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                type="email"
                label="Personal Email"
                onChange={handleInputChange}
                name="PersonalEmail"
                value={profileFormFields.PersonalEmail}
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                type="email"
                label="Official Email"
                onChange={handleInputChange}
                name="WorkEmail"
                value={profileFormFields.WorkEmail}
                form="Form1"
                required
              />
            </Col>

            <Col lg="6" md="6" sm="6" xs="12">
              <FormGroupInput
                label="Current Address"
                onChange={handleInputChange}
                name="CurrentAddress"
                value={profileFormFields.CurrentAddress}
                required
                form="Form1"
                maxLength="200"
              />
            </Col>
            <Col lg="6" md="6" sm="6" xs="12">
              <FormGroupInput
                label="Permanent Address"
                onChange={handleInputChange}
                name="PermanentAddress"
                value={profileFormFields.PermanentAddress}
                required
                form="Form1"
                maxLength="200"
              />
            </Col>
            <Col lg="3" md="3" sm="3" xs="12">
              <FormGroupSelect
                list={list.CorrespondanceList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                label="Correspondence Address"
                onChange={handleInputChange}
                name="CorrespondenceAddressId"
                value={profileFormFields.CorrespondenceAddressId}
                required
                form="Form1"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Emergency Contact Details</CardTitle>
        <CardBody>
          <Row>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                name="PersonName"
                value={emergencyprofileFormFields.PersonName}
                label="Name"
                onChange={handleEmergencyChange}
                required
                form="Form1"
                maxLength="100"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                isNumber="true"
                label="Phone#"
                name="ContactNo"
                value={emergencyprofileFormFields.ContactNo}
                onChange={handleEmergencyChange}
                required
                form="Form1"
                maxLength="15"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                type="email"
                label="Email"
                name="EmailAddress"
                value={emergencyprofileFormFields.EmailAddress}
                onChange={handleEmergencyChange}
                form="Form1"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="RelationShipId"
                list={list.RelationshipList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={emergencyprofileFormFields.RelationShipId}
                label="Relationship"
                onChange={handleEmergencyChange}
                required
                form="Form1"
              />
            </Col>
            <Col lg="12" md="12" sm="6" xs="12">
              <FormGroupInput
                name="Address"
                value={emergencyprofileFormFields.Address}
                label="Address"
                onChange={handleEmergencyChange}
                form="Form1"
                maxLength="200"
                // required
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Family Member Details</CardTitle>
        <CardBody>
          {/* <form onSubmit={onAdd}> */}
          <Row>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                name="FamilyMemberName"
                value={familyprofileFormFields.FamilyMemberName}
                label="Name"
                onChange={handleFamilyChange}
                isAlphabetic="true"
                required={true}
                form="Form2"
                maxLength="100"
              />
            </Col>

            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                label="Qualification"
                list={list.QualificationList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                name="FamilyMemberQualificationid"
                value={familyprofileFormFields.FamilyMemberQualificationid}
                onChange={handleFamilyChangeSelect}
                nameValue="FamilyMemberQualification"
                // required={true}
                form="Form2"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="RelationShipid"
                list={list.RelationshipList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={familyprofileFormFields.RelationShipid}
                label="Relationship"
                onChange={handleFamilyChangeSelect}
                nameValue="RelationShip"
                required
                form="Form2"
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <div className="form-group">
                <label className="form-label">
                  Date Of Birth<span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={familyprofileFormFields.FamilyMemberDBO}
                  onChange={(e) => AllDateSet(e, "FamilyMemberDBO")}
                  className="form-control"
                  name="FamilyMemberDBO"
                  required={true}
                  showYearDropdown={true}
                  // isClearable
                  placeholderText={dateFormatPlaceholder}
                  dateFormat={dateFormat}
                />
              </div>
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupInput
                name="Age"
                value={familyprofileFormFields.Age}
                label="Age"
                onChange={handleFamilyChange}
                disabled
                // required
              />
            </Col>
            <Col lg="3" md="3" sm="6" xs="12">
              <FormGroupSelect
                name="FamilyMemberProfessionId"
                list={list.ProfessionList}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={familyprofileFormFields.FamilyMemberProfessionId}
                label="Profession"
                onChange={handleFamilyChangeSelect}
                nameValue="FamilyMemberProfession"
                form="Form2"
                // required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="secondary" form="Form2" type="submit">
                  Add/Update
                </Button>
                <Button type="button" onClick={onCancel} color="default">
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
          {/* </form> */}
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Family Member List</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={familyMemberData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" type="submit" form="Form1">
                  {submitLoad ? <Spinner color="light" size="sm" /> : null}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default PersonalInfo;
