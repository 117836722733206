import React, { Fragment } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import FormGroupInput from '../../../components/GeneralComponent/FormGroupInput'

const UndertakingBForm = () => {
  return (
    <Fragment>
        <form>
        <Card className="mt-3">
          <CardTitle>Research Co-Supervisor</CardTitle>
          <CardBody>
            <Row>
              <Col md="9" lg="9">
                <FormGroupInput
                  label="Justification"
                  name="FatherName"
                //   value={formFields?.FatherName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button
                    color="primary"
                    type="submit"
                    // disabled={
                    //   formFields?.UnderTaking == false ||
                    //   statusData[0]?.Status == 1
                    //     ? true
                    //     : false
                    // }
                  >
                    {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        </form>
        </Fragment>

  )
}

export default UndertakingBForm