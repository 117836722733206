import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Insert,
  Select,
  SessionStorage,
} from "../../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../../EncryptData";
import {
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../../utils/Config";
import {
  CURRENT_SEMESTER_HOD_OPS,
} from "../../../../utils/UrlConstants";

const HODFacultySchedule = () => {

  const initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    EnrollmentID: 0,
    HodRemarks: "string",
    IsLockByLecturer: true,
    IsLockByHod: true,
  };

  const [
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_HODOps_TBL_,
  ] = useState(initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_);
  
  const intialFormFields = {
    OperationId: Select,
    ParameterID: Insert,
    AcademicYearID: 0,
    SemesterID: 0,
    FacultyDepartmentID: 0,
    FacultyLecturerID: 0,
    CourseSemesterMappingID: 0,
    SetupFacultyDepartmentProgramLecturerID: 0,
    CourseSemesterLecturerMappingId: 0,
    UserID: decryptData("loginId", SessionStorage),
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_: [
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    ],
  };

  const {
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const [formLoader, setFormLoader] = useState(true);

  const timeoutRef = useRef(null);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: intialFormFields,
      },
    });
    getFacultySchedule();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getFacultySchedule = () => {
    const payload = {
      ...intialFormFields,
      CourseSemesterLecturerMappingId: params?.id1,
      CourseSemesterMappingID: params?.id2,
    };
    PostRequest(CURRENT_SEMESTER_HOD_OPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: intialFormFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoader(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "LocName", name: "Location Name" },
    { field: "SectionName", name: "Section" },
    { field: "DayDate", name: "Date" },
    { field: "TimeSlot", name: "Time Slot" },
  ];

  return (
    <AcademicFormComponent
      SemesterName="Faculty Schedule"
      columns={columns}
      rows={TableList}
      formLoader={formLoader}
    />
  );
};

export default HODFacultySchedule;
