import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Search,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { CURRENT_SEMESTER } from "../../../utils/UrlConstants";
import { Col, Row, Table } from "reactstrap";

const CurrentWeekSchedule = (props) => {
  const { SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [formLoader, setFormLoader] = useState(true);

  const timeoutRef = useRef(null);

  useEffect(() => {
    getCurrentSemester();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getCurrentSemester = () => {
    const payload = {
      operationId: Search,
      studentDetailID: decryptData("EmplId", SessionStorage),
      courseSemesterMappingID: 0,
      parameterID: Search,
      userID: 0,
    };
    PostRequest(CURRENT_SEMESTER, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1,
          },
        });
        let studentDetails = {
          name: "StudentDetails",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: studentDetails,
        });
        timeoutRef.current = setTimeout(() => setFormLoader(false), 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "loc", name: "Location" },
    { field: "CourseName", name: "Course Name" },
    { field: "SecName", name: "Section Name" },
    { field: "FacEmployee", name: "Faculty" },
    { field: "ClassDate", name: "Date" },
    { field: "StartTime", name: "Start Time" },
    { field: "EndTime", name: "End Time" },
    { field: "Schedule", name: "Status" },
  ];

  const customTable = (
    <Row>
      <Col>
        <Table bordered responsive striped>
          <thead>
            <tr>
              {columns &&
                columns?.map((column, index) => (
                  <th
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    key={index}
                  >
                    {column?.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {TableList &&
              TableList?.map((column, ind) => (
                <tr  key={ind}>
                  <td>{column?.loc}</td>
                  <td>{column?.CourseName}</td>
                  <td>{column?.SecName}</td>
                  <td>{column?.FacEmployee}</td>
                  <td>{column?.ClassDate}</td>
                  <td>{column?.StartTime}</td>
                  <td>{column?.EndTime}</td>
                  <td style={{ color: "green"}}>{column?.Schedule}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );

  return (
    <AcademicFormComponent
      SemesterName="Regestered Courses Fall 2022"
      topColumns={SupportingTables?.StudentDetails}
      tableColorCode={true}
      formLoader={formLoader}
      customTable={customTable}
    />
  );
};

export default CurrentWeekSchedule;
