import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Label, Row } from "reactstrap";
import {
  academicYearId,
  Delete,
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  AlreadyExistAlert,
  CustomSuccessAlert,
  CustomWarningMessage,
  DeleteAlert,
  DeleteWithConfirmation,
  SuccessAlert,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";

import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  SET_MODAL_TOGGLE_FOR_ACTION,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { SETUP_ATTENDANCE_CRITERIA } from "../../../utils/UrlConstants";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { useState } from "react";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";

const AttandanceCriteria = () => {
  const [pageNo, setPageNo] = useState(1);
  const [paginationList, setPaginationList] = useState(1);
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const initialSearchFields = {
    OperationID: Select,
    PageNumber: pageNo,
    RowsOfPage: 50,
    SetupAttandanceCriteriaID: 0,
    AcademicYearID: 0,
    EligibilePercentage: 0,
    CPTotalMarks: 0,
    OtherMarks: 0,
    AttendanceMarks: 0,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
    FacultyDepartmentID: 0
  };
  const initialFormFields = {
    OperationID: Insert,
    PageNumber: 0,
    RowsOfPage: 0,
    SetupAttandanceCriteriaID: 0,
    AcademicYearID: 0,
    EligibilePercentage: 0,
    CPTotalMarks: 0,
    OtherMarks: 0,
    AttendanceMarks: 0,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
    FacultyDepartmentID: 0
  };
  useEffect(() => {
    get_SetupAttendanceCriteria();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
  }, []);
  const [academicYearList] = useSetupDetailList(academicYearId);
  const [modalToggle, setModalToggle] = useState(false);

  const dispatch = useDispatch();

  function get_SetupAttendanceCriteria() {
    PostRequest(SETUP_ATTENDANCE_CRITERIA, initialSearchFields)
      .then((res) => {
        setPaginationList(res?.data?.Table1);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const columns = [
    { field: "AcademicYear", name: "Academic Year" },
    { field: "Facultydepartment", name: "Faculty Department" },
    { field: "EligibilePercentage", name: "Eligibile Percentage" },
    { field: "Facultydepartment", name: "Faculty Department" },
    { field: "OtherMarks", name: "Other Marks" },
    { field: "AttendanceMarks", name: "Attendance Marks" },
    { field: "CPTotalMarks", name: "CP Total Marks" },

  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const handlePagination = (type) => {
    if (type == "Previous") {
      setPageNo(pageNo - 1);
      SearchFields.PageNumber = pageNo - 1;
      submitSearch();
    } else if (type == "Next") {
      setPageNo(pageNo + 1);
      SearchFields.PageNumber = pageNo + 1;
      submitSearch();
    }
  };
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDepartmentID"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDepartmentID}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      {/* <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramId"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyDepartmentProgramId}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          disabled={SearchFields?.FacultyDepartmentID == null}
        />
      </Col> */}
    </Fragment>
  );
  const customPagination = (
    <Fragment>
      {TableList?.length > 0 ? (
        <Fragment>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <Button
                color="default"
                disabled={paginationList?.[0]?.Min_ == 0 ? true : false}
                onClick={(e) => handlePagination("Previous")}
              >
                <i
                  className="fa fa-chevron-left"
                  style={{ paddingRight: "7px" }}
                ></i>
                Previous
              </Button>
              <Button
                disabled={
                  paginationList?.[0]?.Total < paginationList?.[0]?.Max_
                    ? true
                    : false
                }
                color="secondary"
                onClick={(e) => handlePagination("Next")}
              >
                Next
                <i
                  className="fa fa-chevron-right"
                  style={{ paddingLeft: "7px" }}
                ></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <p style={{ fontWeight: "bold", margin: 10 }}>
                Showing {paginationList?.[0]?.Min_} to{" "}
                {paginationList?.[0]?.Max_} of {paginationList?.[0]?.Total}{" "}
                Records
              </p>
            </Col>
          </Row>
        </Fragment>
      ) : null}
    </Fragment>
  );
  const submitSearch = () => {
    PostRequest(SETUP_ATTENDANCE_CRITERIA, {
      ...initialSearchFields,
      ...SearchFields,
    })
      .then((res) => {
        setPaginationList(res?.data?.Table1);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = { ...initialFormFields, ...FormFields };
    const sumOtherAtt =
      Number(payload?.OtherMarks) + Number(payload?.AttendanceMarks);
    const cpMarks = Number(payload?.CpTotalMarks);
    if (
      parseInt(payload.OtherMarks) + parseInt(payload.AttendanceMarks) >
      parseInt(payload.CPTotalMarks)
    ) {
      dispatch({
        type: SET_MODAL_TOGGLE_FOR_ACTION,
        payload: {
          ModalToggle: true,
        },
      });
      CustomWarningMessage("Number is greater than Class Participation Marks");
    } else {
      dispatch({
        type: SET_MODAL_TOGGLE_FOR_ACTION,
        payload: {
          ModalToggle: false,
        },
      });
      PostRequest(SETUP_ATTENDANCE_CRITERIA, {
        ...payload,
        OperationID: payload?.SetupAttandanceCriteriaID > 0 ? Update : Insert,
      })
        .then((res) => {
          if (res?.data?.Table[0]?.HasError === 0) {
            SuccessAlert();
            get_SetupAttendanceCriteria();
          } else {
            AlreadyExistAlert();
            return;
          }
          dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onEditRow = (obj) => {
    let data = {
      OperationID: 0,
      PageNumber: 0,
      RowsOfPage: 0,
      SetupAttandanceCriteriaID: obj?.SetupAttandanceCriteriaID,
      AcademicYearID: obj?.AcademicYearID,
      EligibilePercentage: obj?.EligibilePercentage,
      CPTotalMarks: obj?.CPTotalMarks,
      OtherMarks: obj?.OtherMarks,
      AttendanceMarks: obj?.AttendanceMarks,
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
      FacultyDepartmentID: obj?.FacultyDepartmentID
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        let data = {
          ...FormFields,
          OperationID: Delete,
          SetupAttandanceCriteriaID: obj?.SetupAttandanceCriteriaID,
          AcademicYearID: obj?.AcademicYearID,
          EligibilePercentage: obj?.EligibilePercentage,
        };
        PostRequest(SETUP_ATTENDANCE_CRITERIA, data)
          .then((res) => {
            dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
            if (res?.data?.Table[0].HasError === 0) {
              CustomSuccessAlert(res?.data?.Table[0].Message);
              get_SetupAttendanceCriteria();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    get_SetupAttendanceCriteria();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          onChange={handleAddChange}
          value={FormFields?.AcademicYearID}
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDepartmentID"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleAddChange(e);
          }}
          value={FormFields?.FacultyDepartmentID}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      {/* <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramId"
          onChange={handleAddChange}
          value={SearchFields?.FacultyDepartmentProgramId}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          disabled={
                    SearchFields?.FacultyDepartmentID == 0 ? true : false
                  }        />
          disabled={SearchFields?.FacultyDepartmentID == null}
        />
      </Col> */}
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Eligible Percentage"
          name="EligibilePercentage"
          value={FormFields?.EligibilePercentage}
          onChange={handleAddChange}
          required
          isFloat="true"
          // isNumber="true"
        />
      </Col>
      {/* <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Mid Term Marks"
          name="EligibilePercentage"
          value={FormFields?.EligibilePercentage}
          onChange={handleAddChange}
          required
          isFloat="true"
          // isNumber="true"
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Final Term Marks"
          name="EligibilePercentage"
          value={FormFields?.EligibilePercentage}
          onChange={handleAddChange}
          required
          isFloat="true"
          // isNumber="true"
        />
      </Col> */}
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="CP Total Marks"
          name="CPTotalMarks"
          value={FormFields?.CPTotalMarks}
          onChange={handleAddChange}
          required
          isFloat="true"
          // isNumber="true"
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Other Marks"
          name="OtherMarks"
          value={FormFields?.OtherMarks}
          onChange={handleAddChange}
          required
          isFloat="true"
          // isNumber="true"
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Attendance Marks"
          name="AttendanceMarks"
          value={FormFields?.AttendanceMarks}
          onChange={handleAddChange}
          required
          isFloat="true"
          // isNumber="true"
        />
      </Col>
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Attendance Criteria"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      modalSize="md"
      customPagination={customPagination}
    />
  );
};
export default AttandanceCriteria;
