import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Spinner from "react-spinkit";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import {
  Search,
  SessionStorage,
  academicYearId,
} from "../../../common/SetupMasterEnum";
import { decryptData } from "../../../EncryptData";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Acad_StudentDetailOps,
  PostFormRequest,
  PostRequest,
} from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import IDCard from "../../Admissions/ID_Card/IDCard";
import {
  ACAD_SETUP_TIME_SLOTS,
  ACAD_STUDENT_DETAIL_OPS,
} from "../../../utils/UrlConstants";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteWithConfirmation,
  WarningWithConfirmation,
} from "../../../components/Alert";

const StudentList = (props) => {
  const initialSearchFields = {
    OperationId: 1,
    StudentDetailID: 0,
    Reg_No: 0,
    MajorId: 0,
    AdmissionFacultyTypeId: 0,
    AdmissionFacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    InterestIn: "",
    Year: 0,
    Name: "",
    FatherName: "",
    SurName: "",
    GenderID: 0,
    GuardianName: "",
    Cnic: "",
    DOB: "02-02-2022",
    PostalAddress: "",
    PermanantAddress: "",
    NationalityID: 0,
    ReligionID: 0,
    DomicileID: 0,
    CorrespondatAddressId: 0,
    DomicileDistricId: 0,
    Ph: "",
    Mobile: "",
    Email: "",
    AlternativeNumber: "", // Newly Added
    LanguageId: 0, // Newly Added
    Other: "",
    WhatsAppNo: "",
    AreEmployeedAnyWhere: false,
    Picture: "",
    LastDegreeFromSAUS: "",
    IsActive: true,
    CreatedBy: decryptData("loginId", SessionStorage),
    ModifiedBy: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
    FileName: "",
    AcademicYearId: 0,
    SeatTypeId: 0,
  };
  const {
    SearchFields,
    SupportingTables: { studentList },
  } = useSelector((state) => state.CrudFormReducer);
  const componentRef = useRef();
  const challanForEnrollment = useRef();
  const timeoutRef = useRef(null);
  const [formLoader, setFormLoader] = useState(true);
  const dispatch = useDispatch();

  const handlePrintChallanForEnrollment = useReactToPrint({
    content: () => challanForEnrollment.current,
  });

  const columns = [
    { field: "Reg_No", name: "Reg No" },
    {
      field: "AcademicYear",
      name: "Academic Year",
    },
    {
      field: "Name",
      name: "Name",
    },
    {
      field: "FatherName",
      name: "Father Name",
    },
    {
      field: "Cnic",
      name: "Cnic",
    },
    {
      field: "Email",
      name: "Email",
    },
    {
      field: "AdmissionFacultyType",
      name: "Faculty Type",
    },
    {
      field: "Action",
      name: "Action",
    },
  ];

  useEffect(() => {
    let formData = new FormData();
    Object.keys(initialSearchFields).forEach((d) => {
      formData.append(d, initialSearchFields[d]);
    });
    getStudentList(formData);
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [academicYearList] = useSetupDetailList(academicYearId);
  const printIDCard = () => {
    timeoutRef.current = setTimeout(() => handlePrint(), 200);
  };
  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  function getStudentList(payload) {
    PostRequest(ACAD_STUDENT_DETAIL_OPS, payload)
      .then((res) => {
        let data = {
          name: "studentList",
          value: res?.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        setFormLoader(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onAddEdit = (id) => {
    props.history.push(`/pages/academics/enrollment/studentdetails/${id}`);
  };
  const submitSearch = () => {
    const payload = {
      ...initialSearchFields,
      ...SearchFields,
    };
    let formData = new FormData();
    Object.keys(payload).forEach((d) => {
      formData.append(d, payload[d]);
    });

    PostRequest(ACAD_STUDENT_DETAIL_OPS, formData)
      .then((res) => {
        let data = {
          name: "studentList",
          value: res?.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        setFormLoader(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    let formData = new FormData();
    Object.keys(initialSearchFields).forEach((d) => {
      formData.append(d, initialSearchFields[d]);
    });
    getStudentList(formData);
  };
  const onCancelAdmission = (item) => {
    WarningWithConfirmation("Are you sure you want to Delete this account").then((result) => {
      if (result?.isConfirmed) {
        const payload = {
          ...initialSearchFields,
          OperationId: 6,
          StudentDetailID: item?.StudentDetailID,
          Cnic: item?.Cnic,
        };
        let formData = new FormData();
        Object.keys(payload).forEach((d) => {
          formData.append(d, payload[d]);
        });
        PostRequest(ACAD_STUDENT_DETAIL_OPS, formData).then((res) => {
          if (res.data.Table[0].HasError === 0) {
            CustomSuccessAlert(res.data.Table[0].Message);
            let formData = new FormData();
            Object.keys(initialSearchFields).forEach((d) => {
              formData.append(d, initialSearchFields[d]);
            });
            getStudentList(formData);
          } else {
            CustomErrorMessage(res?.data?.Table[0]?.Message);
          }
        });
      }
    });
  };

  return (
    <Container fluid>
      <Card className="mt-4">
        <CardTitle>
          <Row>
            <Col>
              <h6>Search Student List</h6>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Row>
            <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Academic Year"
                name="AcademicYearId"
                list={academicYearList}
                fieldName="SetupDetailName"
                fieldId="SetupDetailId"
                onChange={handleSearchChange}
                value={SearchFields?.AcademicYearId}
              />
            </Col>
            <Col lg="3" md="3" xs="12">
              <FormGroupInput
                label="Name"
                name="Name"
                onChange={handleSearchChange}
                value={SearchFields?.Name}
              />
            </Col>
            <Col lg="3" md="3" xs="12">
              <FormGroupInput
                label="CNIC"
                name="Cnic"
                onChange={handleSearchChange}
                value={SearchFields?.Cnic}
              />
            </Col>
          </Row>

          <Col className="text-end">
            <Button onClick={submitSearch}>Search</Button>
            <Button color="default" onClick={cancelSearch}>
              Cancel
            </Button>
          </Col>
        </CardBody>
      </Card>
      <Card className="mt-4">
        <CardTitle>
          <Row>
            <Col>
              <h6>Student List</h6>
            </Col>
            <Col className="text-end">
              <Button color="primary" onClick={printIDCard}>
                Print ID Card
              </Button>
              <Button color="primary" onClick={() => onAddEdit("new")}>
                Add
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          {formLoader === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner className="spinner-custom" name="rotating-plane" />
            </div>
          ) : (
            <Table bordered striped responsive style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>S.No.</th>
                  {columns &&
                    columns.map((column, index) => (
                      <th key={index}>{column.name}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <Fragment>
                  {studentList?.Table?.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td>{ind + 1}</td>
                        <td>{item.Reg_No}</td>
                        <td style={{ width: "7%" }}>{item.AcademicYear}</td>
                        <td>{item.Name}</td>
                        <td>{item.FatherName}</td>
                        <td>{item.Cnic}</td>
                        <td>{item.Email}</td>
                        <td style={{ width: "10%" }}>
                          {item.AdmissionFacultyType}
                        </td>
                        <td>
                          <div className="d-flex">
                            <Button
                              color="primary"
                              className="btnic"
                              size="sm"
                              onClick={() => onAddEdit(item?.StudentDetailID)}
                            >
                              <i className="fa fa-pencil-square-o"></i>
                            </Button>
                            <Button
                              color="danger"
                              className="btnic"
                              size="sm"
                              onClick={() => onCancelAdmission(item)}
                            >
                              <i className="fa fa-trash-o"></i>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      <div style={{ display: "none" }}>
        <IDCard ref={componentRef} />
      </div>
    </Container>
  );
};

export default StudentList;
