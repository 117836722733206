import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Table } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import Spinner from "react-spinkit";
import { useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { PostRequest } from "../../../utils/Config";
import {
  ACAD_HOD_REPORTS_OPS,
  ACAD_REMUNERATION_ASSIGNMENT,
} from "../../../utils/UrlConstants";
import {
  academicYearId,
  partYearID,
  Search,
  semesterId,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import DPLedgerReport from "../Reports/HODReports/DPLedgerReport";
import { decryptData } from "../../../EncryptData";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import RemunerationAssignmentBillReport from "../Reports/HODReports/RemunerationAssignmentBillReport";

const initialSearchFields = {
  OperationId: 1,
  ParameterID: 1,
  AcademicYearID: 0,
  PartID: 0,
  SemesterID: 0,
  FacultyDepartmentID: 0,
  EmployeeID: decryptData("EmplId", SessionStorage),
  UserID: decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const RemunerationAssignmentBill = () => {
  const { SearchFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);
  const [SemesterList] = useSetupDetailList(semesterId, SearchFields?.PartID);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const printDPLedgerReport = useRef();

  const [formLoad, setFormLoad] = useState(false);

  useEffect(() => {
    getRemunerationAssgn();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    // return () => clearTimeout(timeoutRef.current);
  }, []);

  function getRemunerationAssgn() {
    setFormLoad(true);
    PostRequest(ACAD_REMUNERATION_ASSIGNMENT, initialSearchFields)
      .then((res) => {
        setFormLoad(false);
        let departmentName = {
          name: "departmentName",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: departmentName,
        });
        // dispatch({
        //   type: SET_INITIAL_CRUD_FORM_STATE,
        //   payload: {
        //     List: res?.data?.Table1,
        //     SearchFields: SearchFields,
        //   },
        // });
        // timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="academicYearID"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.academicYearID}
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartID"
          onChange={handleSearchChange}
          value={SearchFields?.PartID}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="SemesterID"
          onChange={handleSearchChange}
          value={SearchFields?.SemesterID}
          list={SemesterList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.PartID == null}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDepartmentID"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDepartmentID}
          list={SupportingTables?.departmentName}
          fieldName="Dept"
          fieldId="DeptID"
        />
      </Col>
    </Fragment>
  );

  const columns = [
    { field: "Rate", name: "Rate (Rs.)" },
    { field: "Class", name: "Class" },
    { field: "Dept", name: "Dept." },
    { field: "Session", name: "Session" },
    { field: "Part", name: "Part" },
    { field: "Subject", name: "Subject" },
    { field: "NoOfStd", name: "No. of Std." },
    { field: "FreshImpFa", name: "Fresh / Imp / Failure" },
    { field: "ProjectRs", name: "Project Rs.300/=" },
    { field: "Amount", name: "Amount" },
    { field: "VVoce", name: "V.Voce Rs.80/=" },
  ];

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>S.No.</th>
              {columns &&
                columns.map((column, index) => (
                  <th
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    key={index}
                  >
                    {column.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {TableList?.length > 0
              ? TableList?.map((item, i) => (
                  <tr key={i}>
                    <td> {i + 1}</td>
                    <td>{item.Amount}</td>
                    <td>{item.DeptProg}</td>
                    <td>{item.FactDept}</td>
                    <td>{item.AcademicYear}</td>
                    <td>{item.Part}</td>
                    <td>{item.Course}</td>
                    <td>{item.NoOfStudents}</td>
                    <td>Fresh</td>
                    <td>300</td>
                    <td>{item.Amount}</td>
                    <td>80</td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  // Another way to dynamically get the values of an array without knowing key

  // TableList?.map((x, i) => (
  //   <tr>
  //     <td>{i + 1}</td>
  //     {Object.keys(x)?.map((column, index) => {
  //       return <td key={index + 2}>{x[column]}</td>;
  //     })}
  //   </tr>
  // ))

  const handlePrintReport = useReactToPrint({
    content: () => printDPLedgerReport.current,
  });

  const customButton = (
    <Fragment>
      {TableList?.length > 0 ? (
        <Button color="primary" onClick={handlePrintReport}>
          Print Remuneration Assignment Bill Report
        </Button>
      ) : null}
      <div style={{ display: "none" }}>
        <RemunerationAssignmentBillReport ref={printDPLedgerReport} />
      </div>
    </Fragment>
  );

  const submitSearch = () => {
    setFormLoad(true);
    let payload = {
      OperationId: 1,
      ParameterID: 1,
      AcademicYearID: SearchFields?.AcademicYearID,
      SemesterID: SearchFields?.SemesterID,
      FacultyDepartmentID: SearchFields?.FacultyDepartmentID,
      EmployeeID: decryptData("EmplId", SessionStorage),
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(ACAD_REMUNERATION_ASSIGNMENT, payload)
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1,
            SearchFields: SearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
  };

  return (
    <CrudFormComponent
      formName="Remuneration Assignment Bill"
      customButton={customButton}
      hideAction={true}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
    />
  );
};

export default RemunerationAssignmentBill;
