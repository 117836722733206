import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import CrudTMSComponent from "../../../components/FormComponents/CrudTMSComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";
import DatePicker from "react-datepicker";
import {
  RESET_FORM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  TMS_Leave_Applied_Operations,
} from "../../../utils/Config";
import {
  employeeCase,
  leaveType,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  formatDateFunction1,
} from "../../../functions/DateFormatFunction";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";

const initialValues = {
  periodFrom: "",
  periodTo: "",
  employeeName: "",
  leaveType: "",
  days: "",
  reasonofLeave: "",
};

const initialFormFields = {
  EmployeeId: 0,
  LeaveType: 0,
  PeriodFrom: "",
  PeriodTo: "",
  Days: 0,
  ReasonOfLeave: "",
  // LeaveWithoutPay: 0,
};

const initialBalances = {
  CasualLeavesRemain: 0,
  EarnedLeavesRemain: 0,
  HajjLeavesRemain: 0,
  LeaveWithOutPayLeavesRemain: 0,
  MaternityLeavesRemain: 0,
  MedicalRemain: 0,
  OfficialRemain: 0,
  SpecialLeavesRemain: 0,
  UrmrahLeaves: 0,
};

const ApplyLeave = () => {
  const {
    FormFields,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [employeeId, setEmployeeId] = useState(0);

  useEffect(() => {
    getDropDown();
    getEmployeeDropDown();
    getLeaveBalances();
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        FormFields: initialFormFields,
      },
    });
  }, []);

  const [dropDownList, setDropDownList] = useState([]);
  const [employeeDropDownList, setEmployeeDropDownList] = useState([]);
  const [leaveBalaces, setLeaveBalances] = useState(initialBalances);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [formFields, setFormFields] = useState({
    ...initialValues,
  });

  function getLeaveBalances() {
    const payload = {
      operationId: Select,
      leaveAppliedId: 0,
      employeeId: decryptData("EmplId", SessionStorage),
      leaveStatusId: 0,
      employeeReason: "",
      leaveTypeId: 0,
      isFlexiDeduct: true,
      fromDate: "2022-07-19T16:08:01.562Z",
      toDate: "2022-07-19T16:08:01.562Z",
      duration: 0,
      offset: 0,
      noOfDays: 0,
      halfDayValue: 0,
      halfDayDeductionAgainstLeaveID: 0,
      inchargeId: 0,
      inchargeReason: "string",
      withoutPay: 0,
      createdDate: "2022-07-19T16:08:01.562Z",
      createdBy: 0,
      isActive: true,
      modifiedDate: "2022-07-19T16:08:01.562Z",
      modifiedBy: 0,
      userIP: "string",
    };
    
    TMS_Leave_Applied_Operations(payload)
      .then((res) => {
        setLeaveBalances({
          ...initialBalances,
          CasualLeavesRemain: res.data.Table[0].CasualLeavesRemain,
          EarnedLeavesRemain: res.data.Table[0].EarnedLeavesRemain,
          HajjLeavesRemain: res.data.Table[0].HajjLeavesRemain,
          LeaveWithOutPayLeavesRemain:
            res.data.Table[0].LeaveWithOutPayLeavesRemain,
          MaternityLeavesRemain: res.data.Table[0].MaternityLeavesRemain,
          MedicalRemain: res.data.Table[0].MedicalRemain,
          OfficialRemain: res.data.Table[0].OfficialRemain,
          SpecialLeavesRemain: res.data.Table[0].SpecialLeavesRemain,
          UrmrahLeaves: res.data.Table[0].UrmrahLeaves,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getDropDown() {
    const payload = {
      operationId: Select,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
      
        setDropDownList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getEmployeeDropDown() {
    const payload = {
      operationId: Select,
      employeeId: decryptData("EmplId", SessionStorage),
      case: employeeCase,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {
        setEmployeeId(res.data.Table[0].Id);
        setEmployeeDropDownList(res.data.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleInputChange = (e) => {

    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const AllDateSet = (event, type) => {
 
    if (type === "PeriodFrom") {
      setFromDate(event);
      let date = formatDateFunction1(event, "-");
      FormFields.PeriodFrom = date;
      let data1 = {
        name: "PeriodFrom",
        value: FormFields.PeriodFrom,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "PeriodTo") {
      setToDate(event);
      let date = formatDateFunction1(event, "-");
      FormFields.PeriodTo = date;
      let data1 = {
        name: "PeriodTo",
        value: FormFields.PeriodTo,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
      FormFields.Days = countDays;
      let data = { name: "Days", value: FormFields.Days };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });

    }
  };

  var countDays;
  const diffDays = (date, otherDate) =>
    Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24) + 1);
  countDays = diffDays(new Date(fromDate), new Date(toDate));

  useEffect(() => {

    let data = { name: "Days", value: countDays };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  }, [countDays]);

  const applyLeaveFor = (
    <Fragment>
      <Row>
        <Col lg="6" md="6" xs="12">
          <FormGroupSelect
            list={employeeDropDownList}
            fieldId="Id"
            fieldName="Name"
            label="Employee Name"
            name="EmployeeId"
            onChange={handleInputChange}
            value={employeeId}
            disabled
          />
        </Col>
        <Col lg="6" md="6" xs="12">
          <FormGroupSelect
            list={dropDownList.filter((x) => x.SetupMasterId == leaveType)}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            label="Leave Type"
            name="LeaveType"
            onChange={handleInputChange}
            value={FormFields?.LeaveType}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg="4" md="4" xs="12">
          <div className="form-group">
            <label className="form-label">Period From</label>
            <DatePicker
              selected={fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "PeriodFrom")}
              className="form-control"
              name="PeriodFrom"
              // name="periodFrom"
              placeholderText={dateFormatPlaceholder}
              filterDate={(date) => date.getDay() !== 6 && date.getDay() !== 0}
              maxDate={FormFields?.PeriodTo}
              time
              required
            />
          </div>
        </Col>
        <Col lg="4" md="4" xs="12">
          <div className="form-group">
            <label className="form-label">Period To</label>
            <DatePicker
              selected={toDate}
              dateFormat={dateFormat}
              minDate={FormFields?.PeriodFrom}
              onChange={(e) => AllDateSet(e, "PeriodTo")}
              className="form-control"
              name="PeriodTo"
              placeholderText={dateFormatPlaceholder}
              filterDate={(date) => date.getDay() !== 6 && date.getDay() !== 0}
              required
            />
          </div>
        </Col>
        <Col lg="4" md="4" xs="12">
          <FormGroupInput
            label="Days"
            name="Days"
            onChange={handleInputChange}
            value={
              FormFields?.PeriodFrom > FormFields?.PeriodTo ? 0 : countDays
            }
            disabled
          />
        </Col>
        <Col lg="12" md="12" xs="12">
          <FormGroupInput
            label="Reason of Leave"
            name="ReasonOfLeave"
            type="textarea"
            onChange={handleInputChange}
            value={FormFields?.ReasonOfLeave}
            required
          />
        </Col>
      </Row>
    </Fragment>
  );

  const leaveStatus = (
    <Fragment>
      <Row>
        <Col lg="6" md="6" xs="12">
          <strong>Leave Status</strong>
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          <strong>Total Availlable</strong>
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Casual Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.CasualLeavesRemain}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Medical Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.MedicalRemain}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Earned Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.EarnedLeavesRemain}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Official Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.OfficialRemain}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Special Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.SpecialLeavesRemain}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Maternity Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.MaternityLeavesRemain}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Hajj Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.HajjLeavesRemain}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Urmrah Leaves
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.UrmrahLeaves}
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="12">
          Leave WithOut Pay
        </Col>
        <Col lg="6" md="6" xs="12" className="text-right">
          {leaveBalaces.LeaveWithOutPayLeavesRemain}
        </Col>
      </Row>
    </Fragment>
  );

  const submitLeaveCancel = () => {
    setFromDate("");
    setToDate("");
    dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
  };

  const submitApplyLeave = (id) => {
    const payload = {
      operationId: id,
      leaveAppliedId: 0,
      employeeId: employeeId,
      leaveStatusId: 0,
      employeeReason: FormFields?.ReasonOfLeave,
      leaveTypeId: FormFields?.LeaveType,
      isFlexiDeduct: true,
      fromDate: FormFields?.PeriodFrom,
      toDate: FormFields?.PeriodTo,
      duration: 0,
      offset: 0,
      noOfDays: FormFields?.Days,
      halfDayValue: 0,
      halfDayDeductionAgainstLeaveID: 0,
      inchargeId: 0,
      inchargeReason: "string",
      withoutPay: 0,
      createdDate: "2022-07-19T16:08:01.562Z",
      createdBy: decryptData("loginId", SessionStorage),
      isActive: true,
      modifiedDate: "2022-07-19T16:08:01.562Z",
      modifiedBy: 0,
      userIP: "string",
    };

    TMS_Leave_Applied_Operations(payload)
      .then((res) => {

     
        if (res.data.Table[0].HasError == 0) {
          dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
          setFromDate("");
          setToDate("");
          let msg = res.data.Table[0].Message;
          CustomSuccessAlert(msg);
        } else {
          let msg = res.data.Table[0].Message;
          CustomErrorMessage(msg);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CrudTMSComponent
      formName="Apply For Leave"
      applyLeaveFor={applyLeaveFor}
      leaveStatus={leaveStatus}
      applyLeave={submitApplyLeave}
      cancelLeave={submitLeaveCancel}
      initialFormFields={initialFormFields}
    />
  );
};

export default ApplyLeave;
