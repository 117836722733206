import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import {
  admissionFacultyTypeId,
  admissionProgramId,
  admissionTypeId,
  campusCity,
  campusType,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  Insert,
  majorsAdId,
  programTypes,
  Search,
  Select,
  SessionStorage,
  typeOfSeatId,
  undergraduateId,
} from "../../common/SetupMasterEnum";
import {
  PostRequest,
} from "../../utils/Config";
import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import Swal from "sweetalert2";
import PreviewUnderGraduate from "./AdmissionAppicationPreview/PreviewUnderGraduate";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import { decryptData } from "../../EncryptData";
import { REG_ID } from "../../utils/EncryptedConstants";
import ReactSelect from "react-select";
import { ADMISSION_CANDIDATELIST, ADMISSION_UPDATE_FEE_CHALLAN, ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../utils/UrlConstants";

const AdmissionFeeChallan = () => {
  const [pageNo, setPageNo] = useState(1);
  const initialSearchFields = {
    OperationId: 0,
    CampusTypeId: 1284,
    CampusCityId: 1644,
    ProgramsId: 3479,
    ProgramTypesId: 4009,
    AdmissionTypeId: 4012,
    MajorId: 0,
    AdmissionFacultyTypeId: 0,
    FacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    FeeStatusId: 0,
    Candidate: "0",
    PageNumber: pageNo,
    RowsOfPage: 10,
  };
  const initialFormFields = {
    ADMRegID: 0,
    Remarks: "",
    DocumentPath: "",
  };
  const initialAdmissionFields = {
    FeeStatusId: 0,
  };

  const initialObj = {
    operationId: 0,
    campusTypeID: 0,
    campusCityID: 0,
    programsID: 0,
    programTypeId: 0,
    admissionTypeID: 0,
    majorId: 0,
    admissionFacultyTypeID: 0,
    facultyDepartmentID: 0,
    feeStatusID: 0,
    admRegid: 0,
    PageNumber: pageNo,
    RowsOfPage: 10,
    remarks: "string",
    userId: 0,
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();

  const [admissionFields, setAdmissionFields] = useState(
    initialAdmissionFields
  );

  const [toggleForm, setToggleForm] = useState(false);
  const [toggleFormSubmit, setToggleFormSubmit] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
    getRegisteredRecords(initialObj);
    getCandidateRecords();
    onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
      operationID: Search,
      caseID: 1,
      paremeterID: undergraduateId,
    });
  }, []);

  function getRegisteredRecords(payload) {
    //Admission_UpdateFeeChallan(payload)
    PostRequest(ADMISSION_UPDATE_FEE_CHALLAN, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        let data = {
          name: "records",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    if (e.target.name === "MajorId") {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
      let data1 = { name: "FacultyDepartmentId", value: 0 };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
      let data2 = { name: "FacultyDepartmentProgramId", value: 0 };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data2 });
      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
        operationID: Search,
        caseID: 2,
        paremeterID: e.target.value,
      });
    } else if (e.target.name === "FacultyDepartmentId") {
      onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency({
        operationID: Search,
        caseID: 3,
        paremeterID: e.target.value,
      });
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
      let data1 = { name: "FacultyDepartmentProgramId", value: 0 };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    } else {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
    }
  };

  const onChangeFacultyDepartmentId_ADM_EligibilityCriteriaDependency = (
    payload
  ) => {
    // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
      .then((res) => {
        if (payload.caseID == 1) {
          let data = { name: "MajorIds", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        } else if (payload.caseID == 2) {
          let data = { name: "FacultyDepartment", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        } else {
          let data = { name: "FacultyDepartmentProgramId", value: res.data };
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const handleAdmissionChange = (e, index) => {
    let obj = TableList[index];
    obj = {
      ...obj,
      [e.target.name]: e.target.value,
    };
    TableList[index] = obj;
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: TableList });
  };
  const columns = [
    { field: "RegId", name: "Reg. ID" },
    { field: "AppId", name: "App. ID" },
    { field: "CNICPassportNo", name: "CNIC/B-Form/Passport No" },
    { field: "Applicant", name: "Candidate Name" },
    { field: "CampusCityName", name: "Campus City" },
    { field: "Program", name: "Program" },
    { field: "AdmissionTypeId", name: "Admission Type" },
    { field: "Department", name: "Applied Dept." },
    { field: "Department", name: "Facult Dept. Program" },
    { field: "FeeStatus", name: "Fee Status" },

    { field: "Remarks", name: "Remarks" },
    { field: "Action", name: "Action" },
  ];

  const onSearch = (e) => {
    e.preventDefault();
    let data = {
      operationId: 0,
      campusTypeID: SearchFields?.CampusTypeId,
      campusCityID: SearchFields?.CampusCityId,
      programsID: SearchFields?.ProgramId,
      programTypeId: SearchFields?.ProgramTypesId,
      admissionTypeID: SearchFields?.AdmissionTypeId,
      majorId: SearchFields?.MajorId,
      admissionFacultyTypeID: SearchFields?.AdmissionFacultyTypeId,
      facultyDepartmentID: SearchFields?.FacultyDepartmentId,
      facultyDepartmentProgramId: SearchFields?.FacultyDepartmentProgramId,
      feeStatusID: SearchFields?.FeeStatusId,
      candidate: SearchFields?.Candidate,
      admRegid: 0,
      remarks: "string",
      userId: decryptData("loginId", SessionStorage).toString(),
      PageNumber: 1,
      RowsOfPage: 10,
    };
    // Admission_UpdateFeeChallan(data)
    PostRequest(ADMISSION_UPDATE_FEE_CHALLAN, data)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEdit = (item) => {
    let data = {
      ADMRegID: item.ADMRegID,
      Remarks: item.Remarks,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
    setToggleFormSubmit(true);
  };
  const onConfirm = (item) => {
    let data = {
      operationId: Select,
      campusTypeID: 0,
      campusCityID: 0,
      programsID: 0,

      admissionTypeID: 0,
      majorId: 0,
      admissionFacultyTypeID: 0,
      facultyDepartmentID: 0,
      facultyDepartmentProgramId: 0,
      feeStatusID: item.FeeStatusId,
      admRegid: item.ADMRegID,
      remarks: "string",
      userId: decryptData("loginId", SessionStorage),
    };
    //Admission_UpdateFeeChallan(data)
    PostRequest(ADMISSION_UPDATE_FEE_CHALLAN, data)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
          getRegisteredRecords(initialObj);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePrivewClick = (e, item) => {
    e.preventDefault();
    let data = {
      DocumentPath: item.DocumentPath,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
    setToggleForm(true);
  };
  const handleFormCancel = () => {
    setToggleForm(false);
  };
  const handleFormSubmitCancel = () => {
    setToggleFormSubmit(false);
  };
  const onSubmit = (e, item) => {
    e.preventDefault();
    let data = {
      operationId: Insert,
      campusTypeID: 0,
      campusCityID: 0,
      programsID: 0,
      programTypeId: 0,
      admissionTypeID: 0,
      majorId: 0,
      admissionFacultyTypeID: 0,
      facultyDepartmentID: 0,
      facultyDepartmentProgramId: 0,
      feeStatusID: 0,
      admRegid: FormFields.ADMRegID,
      remarks: FormFields?.Remarks,
      userId: decryptData("loginId", SessionStorage),
    };
    // Admission_UpdateFeeChallan(data)
    PostRequest(ADMISSION_UPDATE_FEE_CHALLAN, data)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HassError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
          const onRemarksPayload = {
            operationId: 0,
            campusTypeID: SearchFields?.CampusTypeId,
            campusCityID: SearchFields?.CampusCityId,
            programsID: SearchFields?.ProgramId,
            programTypeId: SearchFields?.ProgramTypesId,
            admissionTypeID: SearchFields?.AdmissionTypeId,
            majorId: SearchFields?.MajorId,
            admissionFacultyTypeID: SearchFields?.AdmissionFacultyTypeId,
            facultyDepartmentID: SearchFields?.FacultyDepartmentId,
            facultyDepartmentProgramId:
              SearchFields?.FacultyDepartmentProgramId,
            feeStatusID: SearchFields?.FeeStatusId,
            candidate: SearchFields?.Candidate,
            admRegid: 0,
            remarks: "string",
            userId: decryptData("loginId", SessionStorage),
            PageNumber: pageNo,
            RowsOfPage: 10,
          };
          getRegisteredRecords(onRemarksPayload);
          setToggleFormSubmit(false);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
        // dispatch({
        //   type: SET_INITIAL_CRUD_FORM_STATE,
        //   payload: {
        //     List: res.data.Table,
        //     FormFields: initialFormFields,
        //     SearchFields: initialSearchFields,
        //   },
        // });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function getCandidateRecords() {
    // Admission_CandidateList()
    PostRequest(ADMISSION_CANDIDATELIST)
      .then((res) => {
        let data = {
          name: "candidates",
          value: res.data.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onCancel = () => {
    setSelectedOption(null);
    getRegisteredRecords(initialObj);
  };
  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    // const result = event.Id;
    const result = event.map((obj) => JSON.stringify(obj.value)).join(",");
    SearchFields.Candidate = result;
  };
  const handlePagination = (type) => {
    if (type == "Previous") {
      setPageNo(pageNo - 1);
      SearchFields.PageNumber = pageNo - 1;
      getRegisteredRecords(SearchFields);
    } else if (type == "Next") {
      setPageNo(pageNo + 1);
      SearchFields.PageNumber = pageNo + 1;
      getRegisteredRecords(SearchFields);
    }
  };

  return (
    <Container fluid>
      <Card className="mt-3">
        <form onSubmit={onSearch}>
          <CardTitle>Fee Status</CardTitle>
          <CardBody>
            <Row>
              <Col lg="3" md="3" xs="12">
                <Label>Candidate Name</Label>
                <ReactSelect
                  closeMenuOnSelect={false}
                  // components={animatedComponents}
                  onChange={handleInputChangeSelect}
                  isMulti={true}
                  options={SupportingTables?.candidates}
                  value={selectedOption}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Majors"
                  name="MajorId"
                  list={SupportingTables?.MajorIds?.Table}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={SearchFields?.MajorId}
                  onChange={handleSearchChange}
                />
              </Col>

              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDepartmentId"
                  list={SupportingTables?.FacultyDepartment?.Table}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={SearchFields?.FacultyDepartmentId}
                  onChange={handleSearchChange}
                  // required
                  disabled={SearchFields?.MajorId == 0 ? true : false}
                  // disabled={
                  //   programAppliedFor?.AdmissionTypeId == 0 ||
                  //   programAppliedFor?.MajorId == 0 ||
                  //   programAppliedFor?.AdmissionFacultyTypeId == 0
                  //     ? true
                  //     : false
                  // }
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.FacultyDepartmentProgramId?.Table}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={SearchFields?.FacultyDepartmentProgramId}
                  onChange={handleSearchChange}
                  // required
                  disabled={
                    SearchFields?.FacultyDepartmentId == 0 ? true : false
                  }
                  // disabled={
                  //   programAppliedFor?.AdmissionTypeId == 0 ||
                  //   programAppliedFor?.MajorId == 0 ||
                  //   programAppliedFor?.AdmissionFacultyTypeId == 0 ||
                  //   programAppliedFor?.AdmissionFacultyDepartmentId == 0
                  //     ? true
                  //     : false
                  // }
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Fee Status"
                  name="FeeStatusId"
                  list={SupportingTables?.MasterDropdown?.filter(
                    (x) => x.SetupMasterId == 1095
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={SearchFields?.FeeStatusId}
                  onChange={handleSearchChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Search
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              Fee Status List
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table bordered striped responsive style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Preview</th>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {TableList?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td className="text-center">
                      <Button
                        style={{ lineHeight: "1" }}
                        color="secondary"
                        className="btnic"
                        size="sm"
                        onClick={(e) => handlePrivewClick(e, item)}
                      >
                        <i className="fa fa-eye" color="primary"></i>
                      </Button>
                    </td>
                    <td>{ind + 1}</td>
                    <td>{item.ADMRegID}</td>
                    <td>{item.FormApplicationID}</td>
                    <td>{item.Cnic}</td>
                    <td>{item.Name}</td>
                    <td>{item.CampusCityName}</td>
                    <td>{item.ProgramName}</td>
                    <td>{item.AdmissionTypeName}</td>
                    <td>{item.AdmissionFacultyDepartmentName}</td>
                    <td>{item.FacultyDepartmentProgramName}</td>
                    <td style={{ width: "140px" }}>
                      <FormGroupSelect
                        name="FeeStatusId"
                        list={SupportingTables?.MasterDropdown?.filter(
                          (x) => x.SetupMasterId == 1095
                        )}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={item.FeeStatusId}
                        onChange={(e) => handleAdmissionChange(e, ind)}
                      />
                    </td>
                    <td style={{ width: "140px" }}>
                      <Label>{item.Remarks}</Label>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="warning"
                        className="btnic"
                        size="sm"
                        onClick={() => onEdit(item)}
                        id="edit"
                      >
                        <i className="fa fa-pencil"> </i>
                      </Button>
                    </td>

                    <td>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="secondary"
                        className="btnic"
                        size="sm"
                        onClick={() => onConfirm(item)}
                        id="confirm"
                        disabled={item.FeeStatusId === 0 ? true : false}
                      >
                        <i className="fa fa-send"> </i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <Button
                color="default"
                disabled={
                  SupportingTables?.records?.Table1?.[0]?.Min_ == 0
                    ? true
                    : false
                }
                onClick={(e) => handlePagination("Previous")}
              >
                Previous
              </Button>
              <Button
                disabled={
                  SupportingTables?.records?.Table2?.[0]?.TotalCount <
                  SupportingTables?.records?.Table1?.[0]?.Max_
                    ? true
                    : false
                }
                color="default"
                onClick={(e) => handlePagination("Next")}
              >
                Next
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <p style={{ fontWeight: "bold", margin: 10 }}>
                Showing {SupportingTables?.records?.Table1?.[0]?.Min_} to{" "}
                {SupportingTables?.records?.Table1?.[0]?.Max_} of{" "}
                {SupportingTables?.records?.Table1?.[0]?.TotalCount} Records
              </p>
              {/* <p style={{ fontWeight: "bold", margin: 10 }}>
                Showing {SupportingTables?.records?.Table1?.[0]?.Min_} to{" "}
                {SupportingTables?.records?.Table1?.[0]?.Max_}
              </p> */}
            </Col>
          </Row>
          <Modal
            isOpen={toggleForm}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
            // backdrop="static"
            size="xl"
          >
            <ModalHeader
            // toggle={toggle}
            >
              Challan Preview
            </ModalHeader>
            <ModalBody>
              <form>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 20,
                    }}
                  >
                    <img src={FormFields?.DocumentPath} height="400" />
                  </div>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="default" onClick={handleFormCancel}>
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={toggleFormSubmit}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
          >
            <ModalHeader
            // toggle={toggle}
            >
              Add Remarks
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroupInput
                      name="Remarks"
                      maxLength="150"
                      required
                      onChange={handleAddChange}
                      value={FormFields?.Remarks}
                    />
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button
                      color="default"
                      type="button"
                      onClick={handleFormSubmitCancel}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AdmissionFeeChallan;
