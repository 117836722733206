import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  academicYearId,
  admissionTypeId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  facultyTypeId,
  Insert,
  majorsAdId,
  meritlistId,
  programTypes,
  quotaId,
  Search,
  Select,
  selfFinanceMeritList,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupButton from "../../../components/GeneralComponent/FormGroupButton";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_SEARCH_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Admission_MeritList,
  ADM_AddManualPreferences,
  ADM_Approved_Reject_SelfFinance,
  ADM_EligibilityCriteriaDependency,
  Approved_Reject_MeritList,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_SeatType,
  ADM_ManualSeatConsideration,
} from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";

const initialSearchFields = {
  OperationId: Search,
  Cnic: "",
  IsSelfFinance: false,
  Academicyearid: 0,
  Isquota: false,
  QuotaId: 0,
  AdmRegID: 0,
  ModifiedBy: decryptData("loginId", SessionStorage),
};

const initialFormFields = {
  OperationId: Search,
  Cnic: "",
  IsSelfFinance: false,
  Academicyearid: 0,
  Isquota: false,
  QuotaId: 0,
  AdmRegID: 0,
  ModifiedBy: decryptData("loginId", SessionStorage),
};

const intialSearchTextFields = {
  AdmRegID: 0,
  Name: "",
  AdmissionFacultyDepartment: "",
  FacultyDepartmentProgram: "",
};

const intialFormTextFields = {
  AdmRegID: 0,
  Name: "",
  AdmissionFacultyDepartment: "",
  FacultyDepartmentProgram: "",
};

const ManualSeatConsideration = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [textFields, setTextFields] = useState(intialSearchTextFields);
  const [formTextFields, setFormTextFields] = useState(intialFormTextFields);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
  }, []);

  const columns = [
    { field: "AdmRegID", name: "ADM Reg ID" },
    {
      field: "AdmissionFacultyDepartment",
      name: "Admission Faculty Department",
    },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          label="Academic Year"
          name="Academicyearid"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.Academicyearid}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Cnic"
          value={SearchFields?.Cnic}
          onChange={handleSearchChange}
          label="Student CNIC"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="AdmRegID"
          value={textFields?.AdmRegID}
          onChange={handleSearchChange}
          label="Adm RegId"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Name"
          value={textFields?.Name}
          onChange={handleSearchChange}
          label="Name"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="AdmissionFacultyDepartment"
          value={textFields?.AdmissionFacultyDepartment}
          onChange={handleSearchChange}
          label="Applied Department"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="FacultyDepartmentProgram"
          value={textFields?.FacultyDepartmentProgram}
          onChange={handleSearchChange}
          label="Program"
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == quotaId
          )}
          label="Quota"
          name="QuotaId"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.QuotaId}
          disabled
          // disabled={SearchFields?.IsSelfFinance === true ? true : false}
        />
      </Col>
      <Col lg="1" md="1" xs="12">
        <FormGroupCheckbox
          label="Is Quota"
          name="Isquota"
          onChange={handleSearchChange}
          value={SearchFields?.Isquota}
          disabled
          // disabled={SearchFields?.IsSelfFinance === true ? true : false}
        />
      </Col>
      <Col lg="1" md="1" xs="12">
        <FormGroupCheckbox
          label="Is Self Finance"
          name="IsSelfFinance"
          onChange={handleSearchChange}
          value={SearchFields?.IsSelfFinance}
          disabled
          // disabled={SearchFields?.Isquota === true ? true : false}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    ADM_ManualSeatConsideration(SearchFields)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        } else {
          let AdmRegID = {
            name: "AdmRegID",
            value: res?.data?.Table?.[0]?.ADMRegID,
          };
          dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: AdmRegID });
          let IsSelfFinance = {
            name: "IsSelfFinance",
            value: res?.data?.Table?.[0]?.IsSelfFinance === 1 ? true : false,
          };
          dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: IsSelfFinance });

          let Isquota = {
            name: "Isquota",
            value: res?.data?.Table?.[0]?.Isquota === 1 ? true : false,
          };
          dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: Isquota });
          let QuotaId = {
            name: "QuotaId",
            value: res?.data?.Table?.[0]?.QuotaId,
          };
          dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: QuotaId });
          setTextFields({
            ...textFields,
            AdmRegID: res?.data?.Table?.[0]?.ADMRegID,
            Name: res?.data?.Table?.[0]?.Name,
            AdmissionFacultyDepartment:
              res?.data?.Table?.[0]?.AdmissionFacultyDepartment,
            FacultyDepartmentProgram:
              res?.data?.Table?.[0]?.FacultyDepartmentProgram,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddChange = (e) => {
    let data;
    let data1;
    let data2;

    if (e.target.name === "QuotaId") {
      if (e.target.value === 3602) {
        data = { name: e.target.name, value: e.target.value };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
        data1 = { name: "IsSelfFinance", value: true };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
        data2 = { name: "Isquota", value: false };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data2 });
      } else {
        data = { name: e.target.name, value: e.target.value };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
        data1 = { name: "IsSelfFinance", value: false };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
        data2 = { name: "Isquota", value: true };
        dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data2 });
      }
    } else {
      data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
    }
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
    setFormTextFields(intialFormTextFields);
  };

  const handleSearchClick = () => {
    ADM_ManualSeatConsideration(FormFields)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        } else {
          let AdmRegID = {
            name: "AdmRegID",
            value: res?.data?.Table?.[0]?.ADMRegID,
          };
          dispatch({ type: SET_CRUD_FROM_FIELDS, payload: AdmRegID });
          let IsSelfFinance = {
            name: "IsSelfFinance",
            value: res?.data?.Table?.[0]?.IsSelfFinance === 1 ? true : false,
          };
          dispatch({ type: SET_CRUD_FROM_FIELDS, payload: IsSelfFinance });

          let Isquota = {
            name: "Isquota",
            value: res?.data?.Table?.[0]?.Isquota === 1 ? true : false,
          };
          dispatch({ type: SET_CRUD_FROM_FIELDS, payload: Isquota });
          let QuotaId = {
            name: "QuotaId",
            value: res?.data?.Table?.[0]?.QuotaId,
          };
          dispatch({ type: SET_CRUD_FROM_FIELDS, payload: QuotaId });
          setFormTextFields({
            ...formTextFields,
            AdmRegID: res?.data?.Table?.[0]?.ADMRegID,
            Name: res?.data?.Table?.[0]?.Name,
            AdmissionFacultyDepartment:
              res?.data?.Table?.[0]?.AdmissionFacultyDepartment,
            FacultyDepartmentProgram:
              res?.data?.Table?.[0]?.FacultyDepartmentProgram,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          label="Academic Year"
          name="Academicyearid"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleAddChange}
          value={FormFields?.Academicyearid}
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="Cnic"
          value={FormFields?.Cnic}
          onChange={handleAddChange}
          label="Student CNIC"
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12" className="verticalAllign">
        <Button className="btn" color="primary" onClick={handleSearchClick}>
          Search
        </Button>
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="AdmRegID"
          value={FormFields?.AdmRegID}
          onChange={handleAddChange}
          label="Adm RegId"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="Name"
          value={formTextFields?.Name}
          onChange={handleAddChange}
          label="Name"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="AdmissionFacultyDepartment"
          value={formTextFields?.AdmissionFacultyDepartment}
          onChange={handleAddChange}
          label="Applied Department"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupInput
          name="FacultyDepartmentProgram"
          value={formTextFields?.FacultyDepartmentProgram}
          onChange={handleAddChange}
          label="Program"
          disabled
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == quotaId
          )}
          label="Quota"
          name="QuotaId"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleAddChange}
          value={FormFields?.QuotaId}
        />
      </Col>
      <Col lg="2" md="2" xs="12">
        <FormGroupCheckbox
          label="Is Quota"
          name="Isquota"
          onChange={handleAddChange}
          value={FormFields?.QuotaId === 3602 ? 0 : 1}
          disabled
          required
        />
      </Col>
      <Col lg="2" md="2" xs="12">
        <FormGroupCheckbox
          label="Is Self Finance"
          name="IsSelfFinance"
          onChange={handleAddChange}
          value={FormFields?.QuotaId === 3602 ? 1 : 0}
          disabled
          required
        />
      </Col>
    </Fragment>
  );

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    setTextFields(intialSearchTextFields);
  };

  const submitForm = () => {
    debugger;
    FormFields.OperationId = Update;
    ADM_ManualSeatConsideration(FormFields)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
          setFormTextFields(intialFormTextFields);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CrudFormComponent
      formName="Manual Seat Consideration"
      //buttonName="Generate"
      searchPanel={searchPanel}
      // tableColumns={columns}
      tableRows={TableList}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      formPanel={formPanel}
      buttonName="Add"
      modalSize="lg"
      handleCancel={handleCancel}
      formSubmit={submitForm}
      buttonWithoutList={true}
      buttonWithoutListDescription="Add Manual Seat Consideration"
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default ManualSeatConsideration;
