import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import { Delete, SessionStorage } from "../../../../common/SetupMasterEnum";
import { PostFormRequest } from "../../../../utils/Config";
import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  SET_MULTI_CRUD_FORM_FIELD,
} from "../../../../redux/actionType/CrudActionTypes";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../../components/Alert";
import { decryptData } from "../../../../EncryptData";
import ReactSelect from "react-select";
import { STUDENT_LOGIN_OPS } from "../../../../utils/UrlConstants";
import { onChange_Select_Department_Program } from "../../../../functions/generalFunctions";

const AcademicFeeChallan = () => {
  const [pageNo, setPageNo] = useState(1);

  const initialFormFields = {
    OperationId: 0,
    StudentDetailId: 0,
    BPVoucherId: 0,
    Remarks: "",
    DocumentPath: "",
    UserId: decryptData("loginId", SessionStorage),
    PageNumber: pageNo,
    RowsOfPage: 10,
    CampusTypeID: 1284,
    CampusCityID: 1644,
    FacultyDepartmentID: 0,
    FacultyDepartmentProgramId: 0,
    FeeStatusID: 0,
  };

  const initialSeachFields = {
    OperationId: 0,
    StudentDetailId: 0,
    BPVoucherId: 0,
    Remarks: "",
    DocumentPath: "",
    UserId: decryptData("loginId", SessionStorage),
    PageNumber: pageNo,
    RowsOfPage: 10,
    CampusTypeID: 1284,
    CampusCityID: 1644,
    FacultyDepartmentID: 0,
    FacultyDepartmentProgramId: 0,
    FeeStatusID: 0,
  };

  const { SearchFields, FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const dispatch = useDispatch();

  const [toggleForm, setToggleForm] = useState(false);
  const [toggleFormSubmit, setToggleFormSubmit] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: initialFormFields,
        SearchFields: initialSeachFields,
      },
    });
    getRegisteredRecords(initialSeachFields);
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
  }, []);

  function getRegisteredRecords(payload) {
    let formData = new FormData();
    Object.keys(payload).forEach((d) => {
      formData.append(d, payload[d]);
    });
    PostFormRequest(STUDENT_LOGIN_OPS, formData)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSeachFields,
          },
        });
        let data = {
          name: "candidates",
          value: res.data.Table.map((x, index) => ({
            ...x,
            label: x.Name,
            value: x.StudentDetailID,
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        let FromTo = {
          name: "FromTo",
          value: res?.data?.Table1,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: FromTo,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const handleAdmissionChange = (e, index) => {
    let obj = TableList[index];
    obj = {
      ...obj,
      StatusTypeID: e.target.value,
    };
    TableList[index] = obj;
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: TableList,
        FormFields: initialFormFields,
        SearchFields: SearchFields,
      },
    });
  };

  const columns = [
    { field: "Reg_No", name: "Reg_No" },
    { field: "Cnic", name: "Cnic" },
    { field: "Name", name: "Name" },
    { field: "FatherName", name: "FatherName" },
    { field: "DomicileDistric", name: "DomicileDistric" },
    { field: "AcademicYear", name: "AcademicYear" },
    { field: "SeatType", name: "SeatType" },
    { field: "FacultyDepartmentProgram", name: "FacultyDepartmentProgram" },
    { field: "AdmissionFacultyDepartment", name: "AdmissionFacultyDepartment" },
    { field: "StatusTypeID", name: "StatusTypeID" },
    { field: "Remarks", name: "Remarks" },
    { field: "Action", name: "Action" },
  ];

  const onSearch = (e) => {
    e.preventDefault();
    dispatch({
      type: SET_MULTI_CRUD_FORM_FIELD,
      payload: {
        OperationId: 0,
        StudentDetailId: 0,
        BPVoucherId: 0,
        Remarks: "",
        DocumentPath: "",
        UserId: decryptData("loginId", SessionStorage),
        PageNumber: pageNo,
        RowsOfPage: 10,
        CampusTypeID: 1284,
        CampusCityID: 1644,
        FacultyDepartmentID: SearchFields?.FacultyDepartmentID,
        FacultyDepartmentProgramId: SearchFields?.FacultyDepartmentProgramId,
        FeeStatusID: SearchFields?.FeeStatusID,
      },
    });
    let payload = {
      OperationId: 0,
      StudentDetailId: 0,
      BPVoucherId: 0,
      Remarks: 0,
      DocumentPath: "",
      UserId: decryptData("loginId", SessionStorage),
      PageNumber: pageNo,
      RowsOfPage: 10,
      CampusTypeID: 1284,
      CampusCityID: 1644,
      FacultyDepartmentID: SearchFields?.FacultyDepartmentID,
      FacultyDepartmentProgramId: SearchFields?.FacultyDepartmentProgramId,
      FeeStatusID: SearchFields?.FeeStatusID,
    };
    let formData = new FormData();
    Object.keys(payload).forEach((d) => {
      formData.append(d, payload[d]);
    });
    PostFormRequest(STUDENT_LOGIN_OPS, formData)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEdit = (item) => {
    dispatch({
      type: SET_MULTI_CRUD_FORM_FIELD,
      payload: {
        Remarks: item.Remarks,
        BPVoucherId: item.BPVoucherId,
        StudentDetailId: item.StudentDetailID,
        FeeStatusID: item.StatusTypeID,
      },
    });
    setToggleFormSubmit(true);
  };

  const onConfirm = (item) => {
    FormFields.OperationId = Delete;
    FormFields.BPVoucherId = item?.BPVoucherId;
    FormFields.FeeStatusID = item?.StatusTypeID;
    FormFields.StudentDetailId = item?.StudentDetailID;
    FormFields.Remarks = item?.Remarks;
    FormFields.FacultyDepartmentID = item?.AdmissionFacultyDepartmentId;
    FormFields.FacultyDepartmentProgramId = item?.FacultyDepartmentProgramId;
    FormFields.DocumentPath = item?.DocumentPath;
    let formData = new FormData();
    Object.keys(FormFields).forEach((d) => {
      formData.append(d, FormFields[d]);
    });
    PostFormRequest(STUDENT_LOGIN_OPS, formData)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getRegisteredRecords(SearchFields);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePrivewClick = (e, item) => {
    e.preventDefault();
    let data = {
      DocumentPath: item.DocumentPath,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
    setToggleForm(true);
  };
  const handleFormCancel = () => {
    setToggleForm(false);
  };
  const handleFormSubmitCancel = () => {
    setToggleFormSubmit(false);
  };
  const onSubmit = (e, item) => {
    e.preventDefault();
    FormFields.OperationId = Delete;
    let formData = new FormData();
    Object.keys(FormFields).forEach((d) => {
      formData.append(d, FormFields[d]);
    });
    PostFormRequest(STUDENT_LOGIN_OPS, formData)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getRegisteredRecords(SearchFields);
          setToggleFormSubmit(false);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onCancel = () => {
    setSelectedOption(null);
    // getRegisteredRecords(initialObj);
  };

  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    // // const result = event.Id;
    // const result = event.map((obj) => JSON.stringify(obj.value)).join(",");
    // SearchFields.Candidate = result;
  };

  const handlePagination = (type) => {
    if (type == "Previous") {
      setPageNo(pageNo - 1);
      SearchFields.PageNumber = pageNo - 1;
      getRegisteredRecords(SearchFields);
    } else if (type == "Next") {
      setPageNo(pageNo + 1);
      SearchFields.PageNumber = pageNo + 1;
      getRegisteredRecords(SearchFields);
    }
  };

  return (
    <Container fluid>
      <Card className="mt-3">
        <form onSubmit={onSearch}>
          <CardTitle>Fee Status</CardTitle>
          <CardBody>
            <Row>
              <Col lg="3" md="3" xs="12">
                <Label>Candidate Name</Label>
                <ReactSelect
                  closeMenuOnSelect={false}
                  // components={animatedComponents}
                  onChange={handleInputChangeSelect}
                  // isMulti={true}
                  options={SupportingTables?.candidates}
                  value={selectedOption}
                />
              </Col>

              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDeptId"
                  onChange={async (e) => {
                    onChange_Select_Department_Program({
                      operationID: 6,
                      caseID: 3,
                      paremeterID: e.target.value,
                    }).then((res) => {
                      dispatch({
                        type: SET_INITIAL_DROPDOWN_FORM_STATE,
                        payload: res,
                      });
                    });
                    handleSearchChange(e);
                  }}
                  value={SearchFields?.FacultyDeptId}
                  list={SupportingTables?.Departments}
                  fieldName="SetupDetailName"
                  fieldId="SetupDetailId"
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDeptProgramId"
                  onChange={handleSearchChange}
                  value={SearchFields?.FacultyDeptProgramId}
                  list={SupportingTables?.Programs}
                  fieldName="SetupDetailName"
                  fieldId="SetupDetailId"
                  disabled={SearchFields?.FacultyDeptId == null}
                />
              </Col>

              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Fee Status"
                  name="FeeStatusID"
                  list={SupportingTables?.MasterDropdown?.filter(
                    (x) => x.SetupMasterId == 1095
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={SearchFields?.FeeStatusID}
                  onChange={handleSearchChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Search
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              Fee Status List
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table bordered striped responsive style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Preview</th>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {TableList?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td className="text-center">
                      <Button
                        style={{ lineHeight: "1" }}
                        color="secondary"
                        className="btnic"
                        size="sm"
                        onClick={(e) => handlePrivewClick(e, item)}
                      >
                        <i className="fa fa-eye" color="primary"></i>
                      </Button>
                    </td>
                    <td>{ind + 1}</td>
                    <td>{item.Reg_No}</td>
                    <td>{item.Cnic}</td>
                    <td>{item.Name}</td>
                    <td>{item.FatherName}</td>
                    <td>{item.DomicileDistric}</td>
                    <td>{item.AcademicYear}</td>
                    <td>{item.SeatType}</td>
                    <td>{item.AdmissionFacultyDepartment}</td>
                    <td>{item.FacultyDepartmentProgram}</td>
                    <td style={{ width: "140px" }}>
                      <FormGroupSelect
                        name="FeeStatusID"
                        list={SupportingTables?.MasterDropdown?.filter(
                          (x) => x.SetupMasterId == 1095
                        )}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={item.StatusTypeID}
                        onChange={(e) => handleAdmissionChange(e, ind)}
                      />
                    </td>
                    <td style={{ width: "140px" }}>
                      <Label>{item.Remarks}</Label>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="warning"
                        className="btnic"
                        size="sm"
                        onClick={() => onEdit(item)}
                        id="edit"
                      >
                        <i className="fa fa-pencil"> </i>
                      </Button>
                    </td>

                    <td>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="secondary"
                        className="btnic"
                        size="sm"
                        onClick={() => onConfirm(item)}
                        id="confirm"
                        // disabled={item.FeeStatusId === 0 ? true : false}
                      >
                        <i className="fa fa-send"> </i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <Button
                color="default"
                style={{
                  backgroundColor: "#772647",
                  color: "white",
                  borderRadius: 5,
                }}
                // disabled={
                //   SupportingTables?.FromTo?.[0]?.Min_ == 0
                //     ? true
                //     : false
                // }
                onClick={(e) => handlePagination("Previous")}
              >
                Previous
              </Button>
              <Button
                // disabled={
                //   SupportingTables?.FromTo?.[0]?.TotalCount <
                //   SupportingTables?.records?.Table1?.[0]?.Max_
                //     ? true
                //     : false
                // }
                style={{
                  backgroundColor: "#772647",
                  color: "white",
                  borderRadius: 5,
                }}
                color="default"
                onClick={(e) => handlePagination("Next")}
              >
                Next
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <p style={{ fontWeight: "bold", margin: 10 }}>
                Showing {SupportingTables?.FromTo?.[0]?.Min_ + 1} to{" "}
                {SupportingTables?.FromTo?.[0]?.Max_}
                {/* of{" "} {SupportingTables?.FromTo?.[0]?.TotalCount} Records  */}
              </p>
              {/* <p style={{ fontWeight: "bold", margin: 10 }}>
                  Showing {SupportingTables?.records?.Table1?.[0]?.Min_} to{" "}
                  {SupportingTables?.records?.Table1?.[0]?.Max_}
                </p> */}
            </Col>
          </Row>
          <Modal
            isOpen={toggleForm}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
            // backdrop="static"
            size="xl"
          >
            <ModalHeader
            // toggle={toggle}
            >
              Challan Preview
            </ModalHeader>
            <ModalBody>
              <form>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: 20,
                    }}
                  >
                    <img src={FormFields?.DocumentPath} height="400" />
                  </div>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="default" onClick={handleFormCancel}>
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={toggleFormSubmit}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
          >
            <ModalHeader
            // toggle={toggle}
            >
              Add Remarks
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroupInput
                      name="Remarks"
                      maxLength="150"
                      required
                      onChange={handleAddChange}
                      value={FormFields?.Remarks}
                    />
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button
                      color="default"
                      type="button"
                      onClick={handleFormSubmitCancel}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AcademicFeeChallan;
