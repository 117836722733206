//Setup CLass Location
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import {
  Delete,
  Search,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteWithConfirmation,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import { getUserIPInfo } from "../../../functions/generalFunctions";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import {
  ACAD_SETUP_ASSIGNMENT_RATE,
  SETUP_LOCATION,
} from "../../../utils/UrlConstants";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { decryptData } from "../../../EncryptData";

const SetupAssignmentRate = () => {
  const initialSearchFields = {
    OperationID: 1,
    SetupAssignmentID: 0,
    AssignmentName: "",
    Rate: 0,
    CreatedBy: decryptData("loginId", SessionStorage),
    ModifiedBy: 0,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialFormFields = {
    OperationID: 2,
    SetupAssignmentID: 0,
    AssignmentName: "",
    Rate: 0,
    CreatedBy: decryptData("loginId", SessionStorage),
    ModifiedBy: 0,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const { SearchFields, FormFields, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    getAssignmentRate();
  }, []);

  const getAssignmentRate = () => {
    PostRequest(ACAD_SETUP_ASSIGNMENT_RATE, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "AssignmentName", name: "Assignment" },
    { field: "Rate", name: "Rate" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Assignment"
          name="AssignmentName"
          maxLength="150"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AssignmentName}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    PostRequest(ACAD_SETUP_ASSIGNMENT_RATE, {
      ...initialSearchFields,
      ...SearchFields,
    })
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      ...FormFields,
      OperationID: id,
    };
    PostRequest(ACAD_SETUP_ASSIGNMENT_RATE, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          getAssignmentRate();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    let data = {
      ...FormFields,
      SetupAssignmentID: obj.SetupAssignmentID,
      AssignmentName: obj.AssignmentName,
      Rate: obj.Rate,
      ModifiedBy: decryptData("loginId", SessionStorage),
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        let data = {
          ...FormFields,
          SetupAssignmentID: obj.SetupAssignmentID,
          AssignmentName: obj.AssignmentName,
          Rate: obj.Rate,
          ModifiedBy: decryptData("loginId", SessionStorage),
          OperationId: Delete,
        };
        PostRequest(ACAD_SETUP_ASSIGNMENT_RATE, data)
          .then((res) => {
            if (res.data.Table[0].HasError === 0) {
              CustomSuccessAlert(res.data.Table[0].Message);
              getAssignmentRate();
            } else {
              CustomErrorMessage(res.data.Table[0].Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getAssignmentRate();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Assignment"
          name="AssignmentName"
          maxLength="150"
          required
          onChange={handleAddChange}
          value={FormFields?.AssignmentName}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Rate"
          name="Rate"
          isFloat={true}
          required
          onChange={handleAddChange}
          value={FormFields?.Rate}
        />
      </Col>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Assignment Rate"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
    />
  );
};

export default SetupAssignmentRate;
