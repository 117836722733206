import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Label,
  Row,
  Table,
} from "reactstrap";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";

const initialValues = {
  month: "2",
  year: "1",
};
const PaySlip = () => {
  const [isShow, isNotShow] = useState(false);
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const year = [
    {
      id: "1",
      name: "2022",
    },
    {
      id: "2",
      name: "2021",
    },
    {
      id: "3",
      name: "2019",
    },
    {
      id: "4",
      name: "2018",
    },
    {
      id: "5",
      name: "2017",
    },
    {
      id: "6",
      name: "2016",
    },
  ];

  const month = [
    {
      id: "1",
      name: "January",
    },
    {
      id: "2",
      name: "February",
    },
    {
      id: "3",
      name: "March",
    },
    {
      id: "4",
      name: "April",
    },
    {
      id: "5",
      name: "May",
    },
    {
      id: "6",
      name: "June",
    },
    {
      id: "7",
      name: "July",
    },
    {
      id: "8",
      name: "August",
    },
    {
      id: "9",
      name: "September",
    },
    {
      id: "10",
      name: "October",
    },
    {
      id: "11",
      name: "November",
    },
    {
      id: "12",
      name: "December",
    },
  ];
  const viewReport = () => {
    if (formFields.year === "1" && formFields.month === "2") {
      isNotShow(!isShow);
    }
  };

  return (
    <Container fluid>
      <Card className="mt-3">
        <CardBody>
          <Row>
            <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Select Year"
                list={year}
                fieldId="id"
                fieldName="name"
                name="year"
                onChange={handleInputChange}
                value={formFields.year}
              />
            </Col>
            <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Select Month"
                list={month}
                fieldId="id"
                fieldName="name"
                name="month"
                onChange={handleInputChange}
                value={formFields.month}
              />
            </Col>

            <Col lg="6" md="6" xs="12">
              <Button color="primary" onClick={viewReport} className="mt-4">
                View Payslip
              </Button>
            </Col>
          </Row>
          {isShow ? (
            <>
              <hr />
              <Row>
                <Col lg="12" md="12" sm="12" xs="12" className="text-center">
                  <div className="text-center payslip-text">
                    <Button className="mb-2" color="success">
                      Export <i className="fa fa-cloud-download"></i>
                    </Button>
                    <h2>SHAH ABDUL LATIF UNIVERSITY, KHAIRPUR</h2>
                    <h2>MONTHLY PAYSLIP</h2>
                    <h3>Feburary 2022</h3>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mb-3">
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>Employee Code </strong>
                  </Label>
                  <span className="badge badge-info">00116</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>Employee Name</strong>
                  </Label>
                  <span className="badge">Dr. Abdul Hussain Shar</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>Designation</strong>
                  </Label>
                  <span className="badge">Professor</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>Faculty</strong>
                  </Label>
                  <span className="badge">Faculty Of Science</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>Department</strong>
                  </Label>
                  <span className="badge">Microbiology Department</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>Grade</strong>
                  </Label>
                  <span className="badge">BPS-21</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>I.D Card No:</strong>
                  </Label>
                  <span className="badge">452066668932</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>Bank A/C #</strong>
                  </Label>
                  <span className="badge">120800344333</span>
                </Col>
                <Col lg="3" md="3" sm="3" xs="12">
                  <Label className="col-12 mb-0">
                    <strong>N.T Number</strong>
                  </Label>
                  <span className="badge">0000</span>
                </Col>
              </Row>
              <hr />
              <Row className="mt-3">
                <Col lg="4" md="4" xs="12">
                  <h6>Payments</h6>
                  <Table bordered className="payslip-table">
                    <thead>
                      <tr>
                        <th style={{ width: "263px" }}> </th>
                        <th style={{ textAlign: "right" }}>Amount Rs</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>House Rent Allowance</td>
                        <td style={{ textAlign: "right" }}>17,469</td>
                      </tr>
                      <tr>
                        <td>Medical Allowance</td>
                        <td style={{ textAlign: "right" }}>6,446</td>
                      </tr>
                      <tr>
                        <td>Conveyance Allowance</td>
                        <td style={{ textAlign: "right" }}>5,000</td>
                      </tr>
                      <tr>
                        <td>Entertainment allowance</td>
                        <td style={{ textAlign: "right" }}>700</td>
                      </tr>
                      <tr>
                        <td>Senior Post Allowance</td>
                        <td style={{ textAlign: "right" }}>1,350</td>
                      </tr>
                      <tr>
                        <td>Qualification Allowance</td>
                        <td style={{ textAlign: "right" }}>25,000</td>
                      </tr>
                      <tr>
                        <td>Orderly Allowance</td>
                        <td style={{ textAlign: "right" }}>25,000</td>
                      </tr>
                      <tr>
                        <td>Telephone Reimbursement</td>
                        <td style={{ textAlign: "right" }}>2,000</td>
                      </tr>
                      <tr>
                        <td>Adhoc Relief Allow-2016@10%</td>
                        <td style={{ textAlign: "right" }}>11,358</td>
                      </tr>
                      <tr>
                        <td>Adhoc Relief Allow-2017@15%</td>
                        <td style={{ textAlign: "right" }}>24,258</td>
                      </tr>
                      <tr>
                        <td>Adhoc Relief Allow-2015@2.5%</td>
                        <td style={{ textAlign: "right" }}>2,225</td>
                      </tr>
                      <tr>
                        <td>Adhoc Relief Allow-2018@10% </td>
                        <td style={{ textAlign: "right" }}>16,172</td>
                      </tr>
                      <tr>
                        <td>Adhoc Relief Allow-2019@15% </td>
                        <td style={{ textAlign: "right" }}>24,258</td>
                      </tr>
                      <tr>
                        <td>Adhoc Allowance-2020@5%/10% </td>
                        <td style={{ textAlign: "right" }}>8,336</td>
                      </tr>
                      <tr>
                        <td>Adhoc Relief Allowance-2021/20%</td>
                        <td style={{ textAlign: "right" }}>32,344</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total of Payments:</strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>363,636</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>

                <Col lg="4" md="4" xs="12">
                  <h6>Deductions</h6>
                  <Table bordered className="payslip-table">
                    <thead>
                      <tr>
                        <th style={{ width: "262px" }}> </th>
                        <th style={{ textAlign: "right" }}>Amount Rs</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Rev: Stamp</td>
                        <td style={{ textAlign: "right" }}>2</td>
                      </tr>
                      <tr>
                        <td>Endownent Fund</td>
                        <td style={{ textAlign: "right" }}>100</td>
                      </tr>
                      <tr>
                        <td>SALUTS Welfare Fee</td>
                        <td style={{ textAlign: "right" }}>2,200</td>
                      </tr>
                      <tr>
                        <td>SALUTS Sub Free</td>
                        <td style={{ textAlign: "right" }}>200</td>
                      </tr>
                      <tr>
                        <td>Group Insurance</td>
                        <td style={{ textAlign: "right" }}>1,935</td>
                      </tr>
                      <tr>
                        <td>G.P Fund</td>
                        <td style={{ textAlign: "right" }}>8,940</td>
                      </tr>
                      <tr>
                        <td>Income Tax</td>
                        <td style={{ textAlign: "right" }}>32,233</td>
                      </tr>
                      <tr>
                        <td>Total of Deductions:</td>
                        <td style={{ textAlign: "right" }}>
                          <strong>45,610</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>

                <Col lg="4" md="4" xs="12">
                  <h6>&nbsp;</h6>
                  <Table className="payslip-table">
                    <thead>
                      <tr>
                        <th>G.P. Fund Advance</th>
                        <th style={{ textAlign: "right" }}> Repaid Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>NET PAY</strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong>Rs. 318,026.00</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default PaySlip;
