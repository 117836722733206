import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import DatePicker from "react-datepicker";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../../utils/CommonMethods";
import { Setup_UpdateEmployee } from "../../../../utils/Config";
import { formatDateFunction2 } from "../../../../functions/DateFormatFunction";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { decryptData } from "../../../../EncryptData";
import { SessionStorage } from "../../../../common/SetupMasterEnum";

const WorkExperience = (props) => {
  const { id } = useParams();
  const EmplId =
  id == 0
  ? decryptData("EmplId", SessionStorage)
  : decryptData("EmplGetId", SessionStorage);
const LoginId = decryptData("loginId", SessionStorage);
  const FirstName = props?.firstName;
  const CNIC = props?.CNIC;

  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    EmployeeProfessionalExperienceDetails_: [],
  };
  const employeeProfessionalExperienceDetails_ = {
    EmployeeId: EmplId,
    EpxId: 0,
    CompanyName: "",
    ComapnyPhoneNumber: "",
    ComapnyEmailAddress: "",
    Designation: "",
    CompanyCityId: 0,
    Last_Drawn_Salary_PKR: "0",
    FromDate: null,
    ToDate: null,
    ReasonOfLeaving: "",
    JobResponsibilities: "",
    CompanyAddress: "",
    IsActive: 1,
    IsDeleted: 0,
    // CreateBy: 0,
    // ModifiedBy: LoginId,
    UserIP: "192.168.168",
  };
  const formData = props?.data;
  const list = props?.list;
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const [workFormFields, setWorkFormFields] = useState({
    ...employeeProfessionalExperienceDetails_,
  });
  const [submitLoad, setSubmitLoad] = useState(false);
  const handleInputChange = (event) => {
    setWorkFormFields({
      ...workFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    let obj = {
      ...workFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setWorkFormFields(obj);
  };

  const [workExpData, setWorkExpData] = useState([]);
  useEffect(() => {
    setWorkExpData(formData);
  }, [formData]);
  const columns = [
    { field: "CompanyName", name: "Name of Company" },
    { field: "FromDate", name: "From Date" },
    { field: "ToDate", name: "To Date" },
    { field: "Designation", name: "Designation" },
    { field: "JobResponsibilities", name: "Job Responsibilities" },
    { field: "Last_Drawn_Salary_PKR", name: "Last Drawn Salary (PKR)" },
    { field: "ComapnyPhoneNumber", name: "Company Phone#" },
    { field: "CompanyAddress", name: "Company Address" },
    { field: "CompanyCity", name: "Company Location" },
    { field: "ReasonOfLeaving", name: "Reason for Leaving" },
  ];
  const onAdd = (e) => {
    e.preventDefault();
    workFormFields.FromDate = formatDateFunction2(
      workFormFields.FromDate
    );
    workFormFields.ToDate = formatDateFunction2(
      workFormFields.ToDate
    );
    workFormFields.index > -1
      ? (workExpData[workFormFields.index] = workFormFields)
      : workExpData.push({
          ...workFormFields,
          index: -1,
        });
    setWorkExpData([...workExpData]);
    setWorkFormFields({
      ...employeeProfessionalExperienceDetails_,
    });
  };
  const onEdit = (index, obj) => {
    // obj.FromDate = formatDateFunction(obj.FromDate, "-")
    // obj.ToDate = formatDateFunction(obj.ToDate, "-")
    setWorkFormFields({
      ...obj,
      FromDate: new Date(obj.FromDate),
      ToDate: new Date(obj.ToDate),
      index: index,
    });
  };
  const onDelete = (index) => {
    workExpData.splice(index, 1);
    setWorkExpData([...workExpData]);
  };
  const onCancel = () => {
    setWorkFormFields({
      ...employeeProfessionalExperienceDetails_,
    });
  };
  const AllDateSet = (event, type) => {
    if (type === "FromDate") {
      setWorkFormFields({
        ...workFormFields,
        FromDate: event,
      });
    } else if (type === "ToDate") {
      setWorkFormFields({
        ...workFormFields,
        ToDate: event,
      });
    }
  };
  const onUpdate = (e) => {
    e.preventDefault();
    if (workExpData.length == 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please Add Work Experience Information",
      });
    } else {
      let formData = new FormData();
      formData.append("UserId", formFields.UserId);
      formData.append("EmployeeId", formFields.EmployeeId);
      formData.append("FirstName", FirstName);
      formData.append("CNIC", CNIC);
      workExpData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeProfessionalExperienceDetails_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateEmployee(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };

  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Work Experience Details</CardTitle>
        <CardBody>
          <form onSubmit={onAdd}>
            <Row>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  name="CompanyName"
                  value={workFormFields.CompanyName}
                  label="Name of Company"
                  onChange={handleInputChange}
                  required
                  maxLength="500"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  name="ComapnyPhoneNumber"
                  value={workFormFields.ComapnyPhoneNumber}
                  label="Company Phone#"
                  isNumber="true"
                  onChange={handleInputChange}
                  // required
                  maxLength="50"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Job Responsibilities"
                  name="JobResponsibilities"
                  value={workFormFields.JobResponsibilities}
                  onChange={handleInputChange}
                  // required
                  maxLength="500"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Designation"
                  name="Designation"
                  value={workFormFields.Designation}
                  onChange={handleInputChange}
                  required
                  maxLength="200"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Company location"
                  name="CompanyCityId"
                  list={list.CountryList}
                  value={workFormFields.CompanyCityId}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChangeSelect}
                  nameValue="CompanyCity"
                  required
                />
              </Col>
              <Col lg="6" md="6" sm="6" xs="12">
                <FormGroupInput
                  label="Address"
                  name="CompanyAddress"
                  value={workFormFields.CompanyAddress}
                  onChange={handleInputChange}
                  // required
                  maxLength="500"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Last Drawn Salary (PKR)"
                  name="Last_Drawn_Salary_PKR"
                  value={workFormFields.Last_Drawn_Salary_PKR}
                  isNumber="true"
                  onChange={handleInputChange}
                  // required
                  maxLength="8"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    From Date<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    selected={workFormFields.FromDate}
                    onChange={(e) => AllDateSet(e, "FromDate")}
                    className="form-control"
                    name="FromDate"
                    required
                    showYearDropdown={true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                  />
                </div>
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <div className="form-group">
                  <label className="form-label">
                    To Date<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    selected={workFormFields.ToDate}
                    onChange={(e) => AllDateSet(e, "ToDate")}
                    className="form-control"
                    name="ToDate"
                    required
                    showYearDropdown={true}
                    placeholderText={dateFormatPlaceholder}
                    dateFormat={dateFormat}
                    minDate={workFormFields.FromDate}
                  />
                </div>
              </Col>
              <Col lg="6" md="6" sm="6" xs="12">
                <FormGroupInput
                  label="Reason for Leaving"
                  name="ReasonOfLeaving"
                  value={workFormFields.ReasonOfLeaving}
                  onChange={handleInputChange}
                  // required
                  maxLength="500"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="4"></Col>
              <Col className="text-right" sm="8">
                <Button color="primary">Add/Update</Button>
                <Button color="default" type="button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>List of Work Experience</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={workExpData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" onClick={onUpdate}>
                  {submitLoad ? <Spinner color="light" size="sm" /> : null}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default WorkExperience;
