import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, SessionStorage } from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { CURRENT_SEMESTER } from "../../../utils/UrlConstants";

const CurrentSemester = (props) => {
  const { SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [formLoader, setFormLoader] = useState(true);

  const timeoutRef = useRef(null);

  useEffect(() => {
    getCurrentSemester();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getCurrentSemester = () => {
    const payload = {
      operationId: Select,
      studentDetailID: decryptData("EmplId", SessionStorage),
      courseSemesterMappingID: 0,
      parameterID: Select,
      userID: 0,
    };
    PostRequest(CURRENT_SEMESTER, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1?.map((x) => ({
              ...x,
              Outline: "Outline",
              Attandance: "Attandance",
              Files: "Files",
              Recap: "Recap",
            })),
          },
        });
        let studentDetails = {
          name: "StudentDetails",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: studentDetails,
        });
        timeoutRef.current = setTimeout(() => setFormLoader(false), 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Course Name" },
    { field: "Outline", name: "Outline" },
    { field: "Attandance", name: "Attendance" },
    { field: "Files", name: "Files" },
    { field: "Recap", name: "Recap" },
  ];

  const onClickRow = (type, index, obj) => {
    if (type === "Attandance") {
      props.history.push(
        `/pages/academics/student/studentattandance/${obj?.StudentDetailID}/${obj?.CourseSemesteMappingID}`
      );
    } else if (type === "Recap") {
      props.history.push(
        `/pages/academics/student/studentcourse/${obj?.StudentDetailID}/${obj?.CourseSemesteMappingID}`
      );
    } else if (type === "Outline") {
      props.history.push(
        `/pages/academics/student/studentcourseoutline/${obj?.StudentDetailID}/${obj?.CourseSemesteMappingID}`
      );
    } else if (type === "Files") {
      props.history.push(
        `/pages/academics/student/studentfiles/${obj?.StudentDetailID}/${obj?.CourseSemesteMappingID}`
      );
    }
  };

  return (
    <AcademicFormComponent
      SemesterName="Regestered Courses Fall 2022"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      onClickRow={onClickRow}
      tableColorCode={true}
      formLoader={formLoader}
    />
  );
};

export default CurrentSemester;
