
export const bsfouryearId = 5661;

export const mainCampusId = 1284;
export const mainCityId = 1644;
export const morningId = 4009;
export const bs1Id = 4012;

export const matriculationExamId = 3589;
export const intermediateExamId = 3590;

