export const BASE_URL_SETUP = "http://192.168.61.32:8191/API/Setup";

// Testing
// export const BASE_URL = "http://localhost:42751/api";

// TestingShabhi
// export const BASE_URL = "http://localhost:42751/api";

// Old Prooduction
// export const BASE_URL = "http://192.168.61.32:42751/api";

// Latest
// export const BASE_URL ="http://192.168.61.32:42755/api"

// UAT
// export const BASE_URL = "http://124.29.235.8:42760/api";

// Production
export const BASE_URL = "https://apiadm.saus.edu.pk/api";

export const BASE_URL_IMG = "http://192.168.61.32:42751/";