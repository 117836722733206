import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
} from "reactstrap";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import DatePicker from "react-datepicker";
import { dateFormat, dateFormatPlaceholder } from "../../../utils/CommonMethods";

const initialValues = {
  fromDate: "",
  toDate: "",
  name: "",
};
const TimeInOutApproval = () => {
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const columns = [
    { field: "employees", name: "Employees" },
    { field: "timeOffType", name: "Time Off Type" },
    { field: "description", name: "Description" },
    { field: "startDate", name: "Start Date" },
    { field: "endDate", name: "End Date" },
    { field: "duration", name: "Duration" },
    { field: "status", name: "Status" },
  ];
  const rows = [
    {
      employees: "Sharlena Rahode",
      timeOffType: "Sick Time Off",
      description: "Second Dentist Appointment",
      startDate: "1995-05-06",
      endDate: "1995-05-06",
      duration: "2 Days",
      status: "To Approve",
    },
    {
      employees: "Sharlena Rahode",
      timeOffType: "Sick Time Off",
      description: "Second Dentist Appointment",
      startDate: "1995-05-06",
      endDate: "1995-05-06",
      duration: "2 Days",
      status: "To Approve",
    },
  ];
  const onConfirm = () => {};
  const onRefuse = () => {};
  const AllDateSet = (event, type) => {
    if (type === "fromDate") {
      setFormFields({
        ...formFields,
        fromDate: event,
      });
    } else if (type === "toDate") {
      setFormFields({
        ...formFields,
        toDate: event,
      });
    }
  };
  return (
    <Container fluid>
      <Card className="rmv-hover mt-3">
        <CardTitle>Time In/Out Request</CardTitle>
        <CardBody>
            <Row>
            <Col lg='3' md='3' sm='6' xs='12'>
              <div className='form-group'>
                <label className='form-label'>From Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
            </Col>
            <Col lg='3' md='3' sm='6' xs='12'>
              <div className='form-group'>
                <label className='form-label'>To Date</label>
                <DatePicker
                  selected={formFields.toDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "toDate")}
                  className='form-control'
                  name='toDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
            </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupInput
                  label="Name"
                  name="name"
                  onChange={handleInputChange}
                  value={formFields.name}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12" className="text-right">
                <Button color="primary" className="btn">
                  Search
                </Button>
                <Button className="btn" color="default">
                  Cancel
                </Button>
              </Col>
            </Row>
        </CardBody>
      </Card>
      <Card>
          <CardTitle>Time In/Out Request List</CardTitle>
        <CardBody>
           <Row>
              <Col>
                <FormGroupTable
                  columns={columns}
                  rows={rows}
                  onConfirm={onConfirm}
                  onRefuse={onRefuse}
                />
              </Col>
            </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default TimeInOutApproval;
