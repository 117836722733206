import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import {
  academicYearId,
  AdmAdmissionTypeID,
  admissionProgramId,
  admissionTypeId,
  Delete,
  examinationId,
  facultyTypeId,
  NewYearID,
  Search,
  Select,
  SessionStorage,
  undergraduateId,
  yearId,
} from "../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteWithConfirmation,
} from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
  TOGGLE_CRUD_FORM_TABLE_LOADING,
} from "../../redux/actionType/CrudActionTypes";
import {
  PostRequest,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_Course,
  Setup_Setup_ADM_SeatType,
} from "../../utils/Config";
import { UserNetworkInfo } from "../../utils/EncryptedConstants";
import Swal from "sweetalert2";
import {
  SETUP_ADM_COURSE,
  SETUP_YEAR_DEGREE_MAPPING,
} from "../../utils/UrlConstants";
import useSetupDetailList from "../../Hooks/useSetupDetailList";
import FormMultiSelect from "../../components/GeneralComponent/FormMultiSelect";

const YearDegreeMapping = () => {
  const initialSearchFields = {
    OperationID: 0,
    CaseID: 0,
    // AcademicYearID: 0,
    ParameterID: 0,
    YearMappingID: 0,
    ProgramID: 0,
    AdmissionTypeID: 0,
    DegreeID: 0,
    YearID: "",
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const initialFormFields = {
    OperationID: 1,
    CaseID: 0,
    // AcademicYearID: 0,
    ParameterID: 0,
    YearMappingID: 0,
    ProgramID: undergraduateId,
    AdmissionTypeID: 0,
    DegreeID: 0,
    YearID: "",
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [AcademicYearList] = useSetupDetailList(academicYearId);
  const [ProgramList] = useSetupDetailList(admissionProgramId);
  const [AdmissionTypeList] = useSetupDetailList(admissionTypeId);
  const [ExaminationList] = useSetupDetailList(examinationId);
  const [YearList] = useSetupDetailList(yearId);
  const NewYearList = YearList?.map((x) => ({
    ...x,
    label: x.SetupDetailName,
    value: x.SetupDetailId,
  }));
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getYearDegreeMapping();
  }, []);

  const getYearDegreeMapping = () => {
    dispatch({
      type: TOGGLE_CRUD_FORM_TABLE_LOADING,
      payload: {
        TableLoading: true,
      },
    });
    PostRequest(SETUP_YEAR_DEGREE_MAPPING, initialSearchFields)
      .then((res) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        console.error(err);
      });
  };

  const columns = [
    // { field: "AcademicYearName", name: "Academic Year" },
    { field: "ProgramName", name: "Program" },
    { field: "AdmissionTypeName", name: "Admission Type" },
    { field: "DegreeName", name: "Degree" },
    { field: "YearName", name: "Year" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    const result = event.map((obj) => JSON.stringify(obj.value)).join(",");
    let data = { name: "YearID", value: result };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Program"
          name="ProgramID"
          list={ProgramList}
          value={SearchFields?.ProgramID}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          disabled
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Admission Type"
          name="AdmissionTypeID"
          list={AdmissionTypeList}
          value={SearchFields?.AdmissionTypeID}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Degree"
          name="DegreeID"
          list={ExaminationList?.filter((x) => x.SetupDetailId != 3703)}
          value={SearchFields?.DegreeID}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    dispatch({
      type: TOGGLE_CRUD_FORM_TABLE_LOADING,
      payload: {
        TableLoading: true,
      },
    });
    const payload = {
      ...initialSearchFields,
      ...SearchFields,
    };
    PostRequest(SETUP_YEAR_DEGREE_MAPPING, payload)
      .then((res) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: TOGGLE_CRUD_FORM_TABLE_LOADING,
          payload: {
            TableLoading: false,
          },
        });
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      ...initialFormFields,
      ...FormFields,
    };
    PostRequest(SETUP_YEAR_DEGREE_MAPPING, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          getYearDegreeMapping();
          setSelectedOption([]);
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    let data = {
      // AcademicYearID: obj?.AcademicYearID,
      ProgramID: obj?.ProgramID,
      DegreeID: obj?.DegreeID,
      YearID: obj?.YearID.toString(),
      YearMappingID: obj?.YearMappingID,
      AdmissionTypeID: obj?.AdmissionTypeID,
    };
    const event = {
      label: obj?.YearName,
      value: obj?.YearID,
    };
    setSelectedOption(event);
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        let data = {
          OperationID: Delete,
          // AcademicYearID: obj?.AcademicYearID,
          ProgramID: obj?.ProgramID,
          DegreeID: obj?.DegreeID,
          YearID: obj?.YearID,
          YearMappingID: obj?.YearMappingID,
          AdmissionTypeID: obj?.AdmissionTypeID,
        };
        PostRequest(SETUP_YEAR_DEGREE_MAPPING, data)
          .then((res) => {
            if (res.data.Table[0].HasError === 0) {
              CustomSuccessAlert(res.data.Table[0].Message);
              getYearDegreeMapping();
            } else {
              CustomErrorMessage(res.data.Table[0].Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getYearDegreeMapping();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
    setSelectedOption([]);
  };

  const formPanel = (
    <Fragment>
      {/* <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          list={AcademicYearList}
          value={FormFields?.AcademicYearID}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          required
        />
      </Col> */}
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Program"
          name="ProgramID"
          list={ProgramList?.filter((x) => x.SetupDetailId != 6661)}
          value={FormFields?.ProgramID}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Admission Type"
          name="AdmissionTypeID"
          list={AdmissionTypeList}
          value={FormFields?.AdmissionTypeID}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Degree"
          name="DegreeID"
          list={ExaminationList?.filter((x) => x.SetupDetailId != 3703)}
          value={FormFields?.DegreeID}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12" className="mb-3">
        <FormMultiSelect
          label="Year"
          closeMenuOnSelect={false}
          hideSelectedOptions={true}
          isMulti={true}
          options={NewYearList}
          value={selectedOption}
          onChange={handleInputChangeSelect}
          allowSelectAll={true}
        />
      </Col>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Year Degree Mapping"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      modalSize="lg"
    />
  );
};

export default YearDegreeMapping;
