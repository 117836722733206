import React, { useState } from "react";
import { Card, CardBody, Col, Container, Label, Row, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import Academics from "./EmployeeTabs/Academics";
import BankDetails from "./EmployeeTabs/BankDetails";
import Documents from "./EmployeeTabs/Documents";
import PersonalInfo from "./EmployeeTabs/PersonalInfo";
import ProfessionalInfo from "./EmployeeTabs/ProfessionalInfo";
import Reference from "./EmployeeTabs/Reference";
import WorkExperience from "./EmployeeTabs/WorkExperience";

import EmpImg from "../../../assets/img/emp-img1.jpg";

const Profile = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <Container fluid>
      <Row>
        <Col sm='12'>
          <Card className="mt-3">
            <CardBody>
                <Row>
                  <Col lg='3' md='3' sm='3' xs='12' className="text-center">
                    <img src={EmpImg} className="rounded-circle" width={120} height={120} alt='Employee' />
                   
                  </Col>
                  <Col lg='9' md='3' sm='9' xs='12'>
                    <Row>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Employee Code</strong></Label>
                        <span className="badge badge-info">385464</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Employee Name</strong></Label>
                        <span className="badge">Yasir</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Gender</strong></Label>
                        <span className="badge">Male</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>NIC No</strong></Label>
                        <span className="badge">4230115216562</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Date of Birth</strong></Label>
                        <span className="badge">Jun 13, 1998</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Date of Joining</strong></Label>
                        <span className="badge">Jun 13, 1998</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Official Email</strong></Label>
                        <span className="badge">yasir.shakoor@sybrid.com</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Grade</strong></Label>
                        <span className="badge">BPS-17</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                      
                        <Label className='col-12 mb-0'><strong>Department</strong></Label>
                        <span className="badge">IT</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>Designation</strong></Label>
                        <span className="badge">Professor</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                      <Label className='col-12 mb-0'><strong>Reports To</strong></Label>
                        <span className="badge">Ahmed Raza</span>
                      </Col>
                      <Col lg='4' md='4' sm='4' xs='12'>
                        <Label className='col-12 mb-0'><strong>User Status</strong></Label>
                        <span className="badge badge-success">Active</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
                <Nav pills>
                  <NavItem>
                    <NavLink className={activeTab === "1" ? "active" : ""} onClick={() => setActiveTab("1")}>
                      Personal Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === "2" ? "active" : ""} onClick={() => setActiveTab("2")}>
                      Professional Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === "3" ? "active" : ""} onClick={() => setActiveTab("3")}>
                      Academics
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === "4" ? "active" : ""} onClick={() => setActiveTab("4")}>
                      Work Experience
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === "5" ? "active" : ""} onClick={() => setActiveTab("5")}>
                      References
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === "6" ? "active" : ""} onClick={() => setActiveTab("6")}>
                      Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === "7" ? "active" : ""} onClick={() => setActiveTab("7")}>
                      Bank Details
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className='tab-content-inner' activeTab={activeTab}>
                  <TabPane tabId='1'>
                    <PersonalInfo {...props} />
                  </TabPane>
                  <TabPane tabId='2'>
                    <ProfessionalInfo {...props} />
                  </TabPane>
                  <TabPane tabId='3'>
                    <Academics {...props} />
                  </TabPane>
                  <TabPane tabId='4'>
                    <WorkExperience {...props} />
                  </TabPane>
                  <TabPane tabId='5'>
                    <Reference {...props} />
                  </TabPane>
                  <TabPane tabId='6'>
                    <Documents {...props} />
                  </TabPane>
                  <TabPane tabId='7'>
                    <BankDetails {...props} />
                  </TabPane>
                </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
