import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Label, Spinner} from "reactstrap";
import {
  campusCity,
  campusType,
  department,
  monthId,
  SessionStorage,
  yearId,
} from "../../../common/SetupMasterEnum";
import CrudTMSComponent from "../../../components/FormComponents/CrudTMSComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { CalendarSpacing } from "../../../functions/CalendarSpacing";
import {
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  TMS_GetDayTypeList,
  TMS_UpdateCalendar,
  TMS_ViewCalendarMonth,
} from "../../../utils/Config";
import Select from "react-select";
import Swal from "sweetalert2";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  YearId: 0,
  MonthId: 0,
  EmployeeId: ""
};

const InchargeUpdateCalendar = () => {
  const {
    SearchFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [formLoad, setFormLoad] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitLoad, setSubmitLoad] = useState(false);
  const dispatch = useDispatch();
  const [shiftList, setShiftList] = useState([]);

  useEffect(() => {
    getMasterDetailAllDropdown();
    getShiftData();
    getEmployeeDropDown()
  }, []);
  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        SearchFields: initialSearchFields,
      },
    });
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getEmployeeDropDown() {
    const payload = {
      operationId: 1,
      employeeId: decryptData("EmplId", SessionStorage),
      case: 3,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {

        let data = {
          name: "EmployeeList",
          value: res.data.Table.map((x, index) => ({
            ...x,
            label: x.Name,
            value: index + 1,
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }


  const columns = [
    { field: "ShiftDayTypeName", name: "Sun" },
    { field: "ShiftDayTypeName", name: "Mon" },
    { field: "ShiftDayTypeName", name: "Tue" },
    { field: "ShiftDayTypeName", name: "Wed" },
    { field: "ShiftDayTypeName", name: "Thu" },
    { field: "ShiftDayTypeName", name: "Fri" },
    { field: "ShiftDayTypeName", name: "Sat" },
  ];
  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    const result = event.map((obj) => JSON.stringify(obj.Id)).join(",");
    SearchFields.EmployeeId = result;
 
  };
  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    if (e.target.name == "CampusTypeId") {
      SearchFields.CampusCityId = 1644;
    }

    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e, index) => {
    let obj = TableList[index];
    obj = {
      ...obj,
      [e.target.name]: e.target.value,
    };

    TableList[index] = obj;
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: TableList });
  };
  function getShiftData() {
    TMS_GetDayTypeList()
      .then((res) => {
        setShiftList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Campus Type"
          name="CampusTypeId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          list={SupportingTables?.tables?.filter(
            (x) => x.SetupMasterId == campusType
          )}
          onChange={handleSearchChange}
          value={SearchFields?.CampusTypeId}
          // required
        ></FormGroupSelect>
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.tables?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == SearchFields?.CampusTypeId
          )}
          label="Campus City"
          name="CampusCityId"
          onChange={handleSearchChange}
          value={SearchFields?.CampusCityId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          disabled={!SearchFields?.CampusTypeId}
          // required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.tables?.filter(
            (x) => x.SetupMasterId == department
          )}
          label="Department"
          name="DepartmentId"
          onChange={handleSearchChange}
          value={SearchFields?.DepartmentId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          // required
        />
      </Col>
      <Col lg="3">
        <Label>
          Month & Year <span className="text-danger">*</span>
        </Label>
        <div className="input-type-group">
          <FormGroupSelect
            name="MonthId"
            onChange={handleSearchChange}
            value={SearchFields?.MonthId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == monthId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
          <FormGroupSelect
            name="YearId"
            onChange={handleSearchChange}
            value={SearchFields?.YearId}
            list={SupportingTables?.tables?.filter(
              (x) => x.SetupMasterId == yearId
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            required
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <Label>Employees</Label>
        <Select
          closeMenuOnSelect={false}
          onChange={handleInputChangeSelect}
          isMulti={true}
          options={SupportingTables.EmployeeList}
          value={selectedOption}
        />
      </Col>
    </Fragment>
  );
  const submitSearch = () => {
    setFormLoad(true);
    TMS_ViewCalendarMonth(SearchFields)
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  };
  const submitForm = () => {
    let result = {};
    TableList.forEach(
      (obj) =>
        (result = {
          ...result,
          [`DaY${obj.DayNum}`]: obj.ShiftDayTypeId,
        })
    );
    result = {
      ...result,
      Year: SearchFields.YearId,
      Month: SearchFields.MonthId,
      EmployeeID: SearchFields.EmployeeId,
      IsActive: 1,
      UserIp: "192.106866.26",
      UserId: decryptData("loginId", SessionStorage)
    };
    setSubmitLoad(true);
    TMS_UpdateCalendar(result)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const calendarTable = (
    <Fragment>
    <div
      className="calendar-view"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {columns?.map((a, i) => (
        <div
          key={i}
          className="table-box-header"
          style={{ width: `${100 / 7}%` }}
        >
          {a.name}
        </div>
      ))}
      {TableList?.sort((a, b) => a.DayNum - b.DayNum).map((item, index) =>
        item.DayNum === "1" ? (
          <>
            {CalendarSpacing(item.Day_Name)}
            <div
              className={
                item.Day_Name == "Saturday"
                  ? "table-box Saturday"
                  : "table-box" && item.Day_Name == "Sunday"
                  ? "table-box Sunday"
                  : "table-box"
              }
              key={index}
              style={{ width: `${100 / 7}%` }}
            >
              <p className="text-center mb-2"><b>{item.DayNum}</b></p>
              <FormGroupSelect
                name="ShiftDayTypeId"
                fieldId="ShiftDayTypeId"
                fieldName="ShiftDayTypeName"
                onChange={(e) => handleAddChange(e, index)}
                value={item.ShiftDayTypeId}
                list={shiftList}
              />
            </div>
          </>
        ) : (
          <div
            className={
              item.Day_Name == "Saturday"
                ? "table-box Saturday"
                : "table-box" && item.Day_Name == "Sunday"
                ? "table-box Sunday"
                : "table-box"
            }
            key={index}
            style={{ width: `${100 / 7}%` }}
          >
           <p className="text-center mb-2"><b>{item.DayNum}</b></p>
            {/* <p>{item.ShiftDayTypeName}</p> */}
            <FormGroupSelect
              name="ShiftDayTypeId"
              fieldId="ShiftDayTypeId"
              fieldName="ShiftDayTypeName"
              onChange={(e) => handleAddChange(e, index)}
              value={item.ShiftDayTypeId}
              list={shiftList}
            />
          </div>
        )
      )}
    </div>
    {TableList?.length > 0 ? (
        <div className="calenadat-btn">
          <Button onClick={submitForm}>
            {submitLoad ? (
              <div className="form-load">
                <Spinner color="light" size="sm" />
              </div>
            ) : null}
            Update
          </Button>
        </div>
      ) : null}
    </Fragment>
  );

  const onEditRow = (obj) => {};

  const onDeleteRow = (obj) => {};

  return (
    <CrudTMSComponent
      formName="View Calendar"
      hideAction={true}
      hideSerialNumber={true}
      formLoad={formLoad}
      searchPanel={searchPanel}
      submitLoad={submitLoad}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      calendarTable={calendarTable}
      // featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default InchargeUpdateCalendar;
