import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import {
  admissionProgramId,
  examTypeID,
  programs,
  SessionStorage,
} from "../../common/SetupMasterEnum";
import DatePicker from "react-datepicker";
import {
  Admission_Exam,
  Admission_Scheduler,
  Setup_MasterDetails_All_Dropdowns,
} from "../../utils/Config";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import Swal from "sweetalert2";
import { dateFormat, dateFormatPlaceholder } from "../../common/dateFormatEnum";
import {
  formatDateFunc,
  formatDateFunction,
  formatDateFunction1,
} from "../../functions/DateFormatFunction";
import FormGroupCheckbox from "../../components/GeneralComponent/FormGroupCheckbox";
import { decryptData } from "../../EncryptData";

const initialSearchFields = {
  OperationId: 1,
  AdmissionScheduleMasterId: 0,
  ProgramId: 0,
  ExamTypeId: 0,
  ExamDate: "",
  ExtendorNot: 0,
  ExtendedDate: "",
  ExtendedDate: "",
  IsActive: 0,
  IsDeleted: 0,
  UserID: 0,
};
const initialFormFields = {
  OperationId: 2,
  AdmissionScheduleMasterId: 0,
  ProgramId: 0,
  ExamTypeId: 0,
  ExamDate: "",
  ExtendorNot: 0,
  ExtendedDate: "",
  ExtendedDate: "",
  IsActive: 1,
  IsDeleted: 0,
  UserID: 0,
};
const initialExamFields = {
  ExamName: "",
  UserId: 0,
};

const AdmissionScheduler = () => {
  initialSearchFields.UserID = decryptData("loginId", SessionStorage);
  initialFormFields.UserID = decryptData("loginId", SessionStorage);
  initialExamFields.UserId = decryptData("loginId", SessionStorage);
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [formFields, setFormFields] = useState(initialFormFields);
  const [examFields, setExamFields] = useState(initialExamFields);
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleFormSubmit, setToggleFormSubmit] = useState(false);
  const [toggleExam, setToggleExam] = useState(false);
  const [extDate, setExtDate] = useState("");
  const [examDate, setExamDate] = useState("");
  const [admissionScData, setAdmissionScData] = useState([]);

  useEffect(() => {
    getMasterDetailAllDropdown();
    getAdmissionScheduleRecords(initialSearchFields);
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getAdmissionScheduleRecords(payload) {
    Admission_Scheduler(payload)
      .then((res) => {
        setAdmissionScData(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getAdmissionScheduleRecordsSubmit(payload) {
    Admission_Scheduler(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              setToggleForm(false);
              setToggleFormSubmit(false);
              getAdmissionScheduleRecords(initialSearchFields);
              setFormFields({
                ...initialFormFields,
              });
              setExtDate("");
              setExamDate("");
            } else if (results.isConfirmed) {
              setToggleForm(false);
              setToggleFormSubmit(false);
              getAdmissionScheduleRecords(initialSearchFields);
              setFormFields({
                ...initialFormFields,
              });
              setExtDate("");
              setExamDate("");
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  }
  function createExam(payload) {
    Admission_Exam(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              setToggleExam(false);
              setExamFields({
                ...initialExamFields,
              });
              getMasterDetailAllDropdown();
            } else if (results.isConfirmed) {
              setToggleExam(false);
              setExamFields({
                ...initialExamFields,
              });
              getMasterDetailAllDropdown();
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  }
  const handleInputChange = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleExamChange = (e) => {
    setExamFields({
      ...examFields,
      [e.target.name]: e.target.value,
    });
  };
  const AllDateSet = (event, type) => {
    if (type === "ExtendedDate") {
      setExtDate(event);
      let date = formatDateFunction1(event, "-");
      formFields.ExtendedDate = date;
    } else if (type === "ExamDate") {
      setExamDate(event);
      let date = formatDateFunction1(event, "-");
      formFields.ExamDate = date;
    }
  };
  const handleGridChange = (e, index) => {
    let obj = admissionScData[index];
    obj = {
      ...obj,
      [e.target.name]: e.target.value,
    };

    admissionScData[index] = obj;

    if (obj.ExtendorNot == 1) {
      setToggleForm(true);
      setFormFields({
        ...formFields,
        AdmissionScheduleMasterId: obj.AdmissionScheduleMasterId,
        ExamDate: obj.ExamDate,
        ExamTypeId: obj.ExamTypeId,
        // ExtendedDate: obj.ExtendedDate,
        ExtendorNot: obj.ExtendorNot,
        OldDate: obj.OldDate,
        ProgramId: obj.ProgramId,
      });
    }
  };
  const onUpdate = (e) => {
    e.preventDefault();
    getAdmissionScheduleRecordsSubmit(formFields);
  };
  const columns = [
    { field: "ProgramId", name: "Program" },
    { field: "ExamTypeId", name: "Exam Type" },
    { field: "ExamDate", name: "Exam Date" },
    { field: "OldDate", name: "Old Date" },
    { field: "ExtendorNot", name: "Extend To" },
  ];

  const onSearch = (e) => {
    e.preventDefault();
    getAdmissionScheduleRecords(searchFields);
  };

  const handleFormCancel = () => {
    setToggleForm(false);
    getAdmissionScheduleRecords(initialSearchFields);
  };
  const handleFormSubmitCancel = () => {
    setToggleFormSubmit(false);
  };
  const handleFormExamCancel = () => {
    setToggleExam(false);
  };

  const onCancel = () => {
    getAdmissionScheduleRecords(initialSearchFields);
    setSearchFields(initialSearchFields);
  };
  const onSubmit = () => {
    setToggleFormSubmit(true);
  };
  const SubmitData = (e) => {
    e.preventDefault();
    getAdmissionScheduleRecordsSubmit(formFields);
  };
  const onAddExamType = (e) => {
    e.preventDefault();
    setToggleExam(true);
  };
  const SubmitExamData = (e) => {
    e.preventDefault();
    createExam(examFields);
  };
  return (
    <Container fluid>
      <Card className="mt-3">
        <form onSubmit={onSearch}>
          <CardTitle>Admission Scheduler</CardTitle>
          <CardBody>
            <Row>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Exam Type"
                  name="ExamTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == examTypeID
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.ExamTypeId}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Search
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              Admission Scheduler List
            </Col>
            <Col lg="6" md="3" xs="12" className="text-end">
              <Button color="primary" onClick={onSubmit}>
                Add
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table
            className="adm-app-table"
            bordered
            striped
            responsive
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {admissionScData?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td>{item.ProgramName}</td>
                    <td>{item.ExamTypeName}</td>
                    <td>{formatDateFunc(item.ExamDate, "-")}</td>
                    <td>{formatDateFunc(item.OldDate, "-")}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.ExtendorNot}
                        name="ExtendorNot"
                        onChange={(e) =>
                          handleGridChange(
                            {
                              target: {
                                name: e.target.name,
                                value: e.target.checked == true ? 1 : 0,
                              },
                            },
                            ind
                          )
                        }
                        value={item.ExtendorNot}
                        disabled={item.ExtendorNot == 1 ? true : false}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Modal
            isOpen={toggleForm}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
            // backdrop="static"
          >
            <ModalHeader
            // toggle={toggle}
            >
              Extend To
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onUpdate}>
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Extend To</label>
                      <DatePicker
                        selected={extDate}
                        dateFormat={dateFormat}
                        onChange={(e) => AllDateSet(e, "ExtendedDate")}
                        className="form-control"
                        name="ExtendedDate"
                        // name="periodFrom"
                        placeholderText={dateFormatPlaceholder}
                        //   filterDate={(date) => date.getDay() !== 6 && date.getDay() !== 0}
                        //   maxDate={formFields?.PeriodTo}
                        required
                      />
                    </div>
                    {/* <FormGroupInput
                      type="date"
                      name="ExtendedDate"
                      onChange={handleAddChange}
                      value={formFields?.ExtendedDate}
                    /> */}
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button color="default" onClick={handleFormCancel}>
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={toggleFormSubmit}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
            // backdrop="static"
          >
            <ModalHeader
            // toggle={toggle}
            >
              Add Admission
            </ModalHeader>
            <ModalBody>
              <form onSubmit={SubmitData}>
                <Row>
                  <Col md="6">
                    <FormGroupSelect
                      label="Program"
                      name="ProgramId"
                      list={SupportingTables?.tables?.filter(
                        (x) => x.SetupMasterId == admissionProgramId
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={formFields?.ProgramId}
                      onChange={handleAddChange}
                      required
                    />
                  </Col>
                  <Col md="6">
                    <FormGroupSelect
                      label="Exam Type"
                      name="ExamTypeId"
                      list={SupportingTables?.tables?.filter(
                        (x) => x.SetupMasterId == examTypeID
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={formFields?.ExamTypeId}
                      onChange={handleAddChange}
                      required
                    />
                    <p
                      style={{ fontSize: "12px", marginBottom: "0px" }}
                      className="text-right"
                    >
                      {" "}
                      <a href="#" onClick={onAddExamType}>
                        Add Exam Type
                      </a>
                    </p>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Exam Date</label>
                      <DatePicker
                        selected={examDate}
                        dateFormat={dateFormat}
                        onChange={(e) => AllDateSet(e, "ExamDate")}
                        className="form-control"
                        name="ExamDate"
                        // name="periodFrom"
                        placeholderText={dateFormatPlaceholder}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button color="default" onClick={handleFormSubmitCancel}>
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={toggleExam}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
            // backdrop="static"
          >
            <ModalHeader
            // toggle={toggle}
            >
              Add Exam Type
            </ModalHeader>
            <ModalBody>
              <form onSubmit={SubmitExamData}>
                <Row>
                  <Col md="6">
                    <FormGroupInput
                      label="Exam"
                      name="ExamName"
                      value={examFields?.ExamName}
                      onChange={handleExamChange}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button color="default" onClick={handleFormExamCancel}>
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AdmissionScheduler;
