import React, { Fragment, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  academicYearId,
  admissionFacultyTypeId,
  admissionProgramId,
  admissionTypeId,
  campusCity,
  campusType,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  Insert,
  majorsAdId,
  programs,
  programTypes,
  quotaId,
  Search,
  Select,
  SessionStorage,
  typeOfSeatId,
} from "../../common/SetupMasterEnum";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import { formatDateFunction } from "../../functions/DateFormatFunction";
import {
  RESET_FORM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import {
  Admission_OMR_Record,
  ADM_EligibilityCriteriaDependency,
  Setup_Admission_Assign_Block,
  Setup_MasterDetails_All_Dropdowns,
  PostRequest,
} from "../../utils/Config";
import CampusType from "../Setup/CampusType";
import { useReactToPrint } from "react-to-print";
import TestSlip from "./TestSlip";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import Swal from "sweetalert2";
// import DataTableJquery from "../../components/GeneralComponent/DataTableJquery";
import { decryptData } from "../../EncryptData";
import { undergraduateId } from "../../common/SetupMasterEnum";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import { UserNetworkInfo } from "../../utils/EncryptedConstants";
import { ADMISSION_OMR_RECORD, ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../utils/UrlConstants";



const AdmissionTest = (props) => {
  const initialSearchFields = {
    OperationId: Select,
    AcademicYearID: 0,
    Cnic: "",
    AdmRegId: 0,
    AdmEntryTestId: 0,
    SetupBlockId: 0,
    FacultyDepartmentID: 0,
    FacultyDepartmentProgramId: 0,
    FileName: "ss",
    FilePath: "ss",
    FileNameTemp: "",
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const componentReference = useRef();
  const {
    SearchFields,
    TableLoading,
    FormFields,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    admissionORMRecords(initialSearchFields);
    getMasterDetailAllDropdown();
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    });
  }, []);
  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onChange_ADM_EligibilityCriteriaDependency = (payload) => {
    if (payload.paremeterID != undefined) {
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
      // ADM_EligibilityCriteriaDependency(payload)
        .then((res) => {
          if (payload.caseID === 2) {
            const data = { name: "Departments", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID === 3) {
            const data = { name: "Programs", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [toggleExam, setToggleExam] = useState(false);
  const [toggleOpenList, setToggleOpenList] = useState(false);
  const handleInputChange = (e) => {
    if (e.target.name === "FacultyDepartmentID") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value,
      });
      setSearchFields({
        ...searchFields,
        [e.target.name]: e.target.value,
        FacultyDepartmentProgramId: 0,
      });
    } else {
      setSearchFields({
        ...searchFields,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleFileUpload = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.files[0],
      FileName: e.target.files[0]?.name,
    });
  };
  const onSearch = (e) => {
    e.preventDefault();
    // setSearchFields({
    //   ...searchFields,
    //   OperationId: Search,
    // });
    let payload = {
      OperationId: Search,
      AcademicYearID: searchFields?.AcademicYearID,
      Cnic: searchFields?.Cnic == undefined ? "" : searchFields?.Cnic,
      AdmRegId: searchFields?.AdmRegId,
      AdmEntryTestId: searchFields?.AdmEntryTestId,
      SetupBlockId: 0,
      FacultyDepartmentID: searchFields?.FacultyDepartmentID,
      FacultyDepartmentProgramId: searchFields?.FacultyDepartmentProgramId,
      FileName: "ss",
      FilePath: "ss",
      FileNameTemp: "",
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    admissionORMRecords(payload);
  };
  function admissionORMRecords(payload) {
    let formData = new FormData();
    Object.keys(payload).forEach((d) => {
      formData.append(`${d}`, payload[d]);
    });
    // Admission_OMR_Record(formData)
    PostRequest(ADMISSION_OMR_RECORD, formData)
      .then((res) => {
        if (payload.OperationId == 2 || payload.OperationId == 3) {
          // setTestData(res.data);
          //   window.location.href={res.data}
          window.open(res.data);
        } else if (payload.OperationId == 1) {
          // dispatch({ type: RESET_FORM_FIELDS, payload: initialSearchFields });
          // setTestData(res.data)
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: res.data.Table,
              // FormFields: initialFormFields,
              SearchFields: initialSearchFields,
            },
          });
        } else if (payload.OperationId == 4) {
          let data = {
            name: "OpenList",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
        } else if (payload.OperationId == 5) {
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: res.data.Table,
              // FormFields: initialFormFields,
              SearchFields: SearchFields,
            },
          });
        } else {
          if (res?.data?.Table[0]?.HasError == 0) {
            CustomSuccessAlert(res?.data?.Table[0]?.Message);
            admissionORMRecords(searchFields);
            // dispatch({
            //   type: SET_INITIAL_CRUD_FORM_STATE,
            //   payload: {
            //     List: res.data.Table,
            //     // FormFields: initialFormFields,
            //     SearchFields: initialSearchFields,
            //   },
            // });
            setToggleExam(false);
          } else {
            CustomErrorMessage(res?.data?.Table[0]?.Message);
            // dispatch({ type: RESET_FORM_FIELDS, payload: initialSearchFields });
          }
          setSearchFields({
            ...searchFields,
            FileName: "ss",
            FilePath: "ss",
          });
        }
        // setShowTable(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onImgUpload = () => {
    setToggleExam(true);
  };
  const handleFormCancel = () => {
    setToggleExam(false);
  };
  /**
   * Request Call Function
   * @param {*} payload
   */
  const onExtract = () => {
    requestCall({
      ...searchFields,
      OperationId: 2,
      CampusId: 0,
      CampusCityId: 0,
      ProgramId: undergraduateId,
      FacultyDepartmentId: 0,
    });
  };
  const onUpload = (e) => {
    e.preventDefault();
    document.getElementById("documentPath").value = [];
    requestCall({
      ...searchFields,
      OperationId: 0,
    });
  };
  const onDownload = (e) => {
    e.preventDefault();
    requestCall({
      ...searchFields,
      OperationId: 3,
      CampusId: 0,
      CampusCityId: 0,
      ProgramId: undergraduateId,
      FacultyDepartmentId: 0,
    });
  };
  const onDownloadCandidate = (e) => {
    e.preventDefault();
    requestCall({
      ...searchFields,
      OperationId: 5,
      CampusId: 0,
      CampusCityId: 0,
      ProgramId: undergraduateId,
      FacultyDepartmentId: 0,
    });
  };
  const onPrint = useReactToPrint({
    content: () => componentReference.current,
    data: TableList?.Table,
  });
  const requestCall = (payload) => {
    admissionORMRecords(payload);
  };

  const onCancel = () => {
    setSearchFields({ ...initialSearchFields });
    admissionORMRecords(initialSearchFields);
    // dispatch({
    //   type: SET_INITIAL_CRUD_FORM_STATE,
    //   payload: {
    //     List: [],
    //     SearchFields: initialSearchFields,
    //   },
    // });
  };
  const onOpenMerit = () => {
    requestCall({
      ...searchFields,
      OperationId: 4,
      CampusId: 0,
      CampusCityId: 0,
      ProgramId: undergraduateId,
      FacultyDepartmentId: 0,
    });
    setToggleOpenList(true);
  };
  const onAssignBlock = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be assign block",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        assignBlockCrud();
      }
    });
  };

  function assignBlockCrud() {
    const payload = {
      OperationId: Insert,
      ExamId: 1847,
      UserId: decryptData("loginId", SessionStorage),
    };
    Setup_Admission_Assign_Block(payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
          admissionORMRecords(searchFields);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // 1847

  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>Test</CardTitle>
        <CardBody>
          <form onSubmit={onSearch}>
            <Row>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.MasterDropdown?.filter(
                    (x) => x.SetupMasterId == academicYearId
                  )}
                  label="Academic Year"
                  name="AcademicYearID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  value={searchFields?.AcademicYearID}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="CNIC"
                  name="Cnic"
                  isNumber="true"
                  value={searchFields?.Cnic}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  // list={SupportingTables?.MasterDropdown?.filter(
                  //   (x) => x.SetupMasterId == academicYearId
                  // )}
                  label="Block"
                  name="SetupBlockId"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChange}
                  value={searchFields?.SetupBlockId}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDepartmentID"
                  list={SupportingTables?.Departments}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.FacultyDepartmentID}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.Programs}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.FacultyDepartmentProgramId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg="12" md="12" xs="12" className="text-right mt-2">
                <Button color="primary" className="btn" type="submit">
                  Search
                </Button>
                <Button
                  className="btn"
                  color="default"
                  onClick={onCancel}
                  type="button"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>
          <Row>
            <Col>Test Detail</Col>
            <Col className="text-right ">
              {TableList?.length > 0 ? (
                <Fragment>
                  <Button
                    color="primary"
                    className="btn"
                    onClick={onAssignBlock}
                  >
                    Assign Block
                  </Button>
                  {/* <Button color="primary" onClick={onExtract}>
                  Extract
                  <i
                    style={{ fontSize: "17px", marginLeft: "5px" }}
                    className="fa fa-spinner"
                  ></i>
                </Button> */}

                  <Button color="secondary" onClick={onImgUpload}>
                    Upload
                  </Button>
                  {/* <Button color="danger" onClick={onDownloadCandidate}>
                    Download Candidate Record
                  </Button> */}
                  <Button color="danger" onClick={onDownload}>
                    {/* Download Sample Sheet */}
                    Download Candidate Record
                  </Button>
                </Fragment>
              ) : null}
              {/* {TableList[0]?.Status == 1 ? */}
              {/* <Button color="success" onClick={onOpenMerit}>
                Generate Open List
              </Button> */}
              {/* : null } */}
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Row>
            <Col>
              {/* <DataTableJquery columns={columns} rows={TableList} /> */}
              <Table bordered responsive striped>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Test Id</th>
                    {/* <th>Registration Id</th> */}
                    {/* <th>Admission Id</th> */}
                    <th>Name</th>
                    <th>CNIC</th>
       
                    {/* <th>Application Id</th> */}

                    {/* <th>Program</th> */}
                    <th>Department</th>
                    <th>Program</th>
                    <th>Block </th>
                    <th>Score</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {TableList?.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td>{ind + 1}</td>
                        <td>{item.ADMEntryTestId}</td>
                        {/* <td>{item.ADMRegid}</td> */}
                        <td>{item.Name}</td>
                        <td>{item.Cnic}</td>
                    
                        {/* <td>{item.FormApplicationID}</td> */}

                        {/* <td>{item.ProgramName}</td> */}
                        <td>{item.FacultyDepartment}</td>
                        <td>{item.FacultyDepartmentProgram}</td>
                        <td>{item.BlockName}</td>
                        <td>{item.TestScore}</td>
                        <td>
                          {item.Status === 0 ? (
                            <i
                              style={{ fontSize: "25px" }}
                              className="fa fa-unlock text-success"
                            ></i>
                          ) : (
                            <i
                              style={{ fontSize: "25px" }}
                              className="fa fa-lock text-danger"
                            ></i>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {/* {TableList && TableList?.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    background: "#e9e9e9",
                    marginTop: -30,
                    padding: 20,
                    fontWeight: "bold",
                  }}
                >
                  No Data Available
                </div>
              )} */}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div style={{ display: "none" }}>
        <TestSlip {...props} ref={componentReference} />
        {/* <AdmissionReportPrint ref={componentReference} /> */}
      </div>

      <Modal
        isOpen={toggleExam}
        centered
        // toggle={toggle}
        modalTransition={{ timeout: 10 }}
        // backdrop="static"
      >
        <ModalHeader
        // toggle={toggle}
        >
          Upload
        </ModalHeader>
        <ModalBody>
          <form onSubmit={onUpload}>
            <Row>
              <Col md="12">
                <Input
                  id="documentPath"
                  type="file"
                  name="FilePath"
                  onChange={handleFileUpload}
                  accept=".xls,.xlsx"
                  required
                  // value={searchFields.FilePath}
                />
              </Col>
            </Row>
            <Row>
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="mt-2"
              >
                <Button color="primary" type="submit">
                  Submit
                </Button>
                <Button
                  color="default"
                  onClick={handleFormCancel}
                  type="button"
                >
                  Close
                </Button>
              </div>
            </Row>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={toggleOpenList}
        centered
        // toggle={toggle}
        modalTransition={{ timeout: 10 }}
        // backdrop="static"
      >
        <ModalHeader
        // toggle={toggle}
        >
          Upload
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table bordered responsive striped>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {/* <th>Test Type</th> */}
                    <th>Registration Id</th>
                    <th>Admission Id</th>
                    <th>Application Id</th>
                    <th>Test Id</th>
                    <th>Program</th>
                    <th>Admission Type</th>
                    <th>Admission Faculty Type</th>
                    <th>Score</th>
                    {/* <th>Test Date</th> */}
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {SupportingTables?.OpenList?.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td>{ind + 1}</td>
                        {/* <td>{item.ADMEntryTestId}</td> */}
                        <td>{item.ADMRegid}</td>
                        <td>{item.AdmissionId}</td>
                        <td>{item.FormApplicationID}</td>
                        <td>{item.TestID}</td>
                        <td>{item.ProgramName}</td>
                        <td>{item.AdmissionTypeName}</td>
                        <td>{item.AdmissionFacultyTypeName}</td>
                        <td>{item.TestScore}</td>
                        {/* <td>{formatDateFunction(item.ExamDate, "-")}</td> */}
                        {/* <td>Action away ga</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {SupportingTables?.OpenList &&
                SupportingTables?.OpenList?.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      background: "#e9e9e9",
                      marginTop: -30,
                      padding: 20,
                      fontWeight: "bold",
                    }}
                  >
                    No Data Available
                  </div>
                )}
            </Col>
          </Row>
          <Row>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="mt-2"
            >
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button
                color="default"
                // onClick={handleFormCancel}
                type="button"
              >
                Close
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default AdmissionTest;
