import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import logoimg from "../../../../assets/img/logo.png";

const PassCertificateReport = forwardRef((props, ref) => {
  const { TableList, SupportingTables } = useSelector((state) => state.CrudFormReducer);

  return (
    <>
    <div
      ref={ref}
      className="text-center"
      style={{
        fontSize: 12,
        margin: "90px 10px 10px 10px",
        padding: "10px 30px",
      }}
    >
      <br />
      
      <p>
        This is to certify that having completed the prescribed course for
        (08) eight semesters during the Academic Session {new Date().getFullYear()}.
      </p>
      <br />
      <h5>{SupportingTables?.PassCertificateStudent?.Table1?.[0]?.NAME}</h5>
      <br />
      <p>
        bearing Roll No. <b>{SupportingTables?.PassCertificateStudent?.Table1?.[0]?.Reg_No}</b> is declared to have passed the
        Examination of {SupportingTables?.PassCertificateStudent?.Table?.[0]?.AdmissionFacultyDepartment} (4 Years) in the Department of {SupportingTables?.PassCertificateStudent?.Table?.[0]?.FacultyDepartmentProgram}
      </p>
      <br />
      <p>
        Area of Specialization: <b>{SupportingTables?.PassCertificateStudent?.Table?.[0]?.AdmissionFacultyDepartment}</b>
      </p>
      <br />
      <p>
        He/She has secured{" "}
        <span className="tbl-row-border">
          {SupportingTables?.PassCertificateStudent?.Table1?.[0]?.CGPA} CGPA
        </span>{" "}
        <span className="tbl-row-border">
          {SupportingTables?.PassCertificateStudent?.Table1?.[0]?.Grade} Grade
        </span>
      </p>
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>Prepared by ____________</td>
          </tr>
          <br />
          <tr>
            <td colSpan={2}>Checked by ____________</td>
          </tr>
          <br />
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <p>{Date()}</p>
        <p>Controller Examination</p>
      </div>
      <p className="border-top2">
        <br />
        <span style={{ borderBottom: "0.5px solid black" }}>NOTE:</span> The
        University reserves the right of issuing any correction in the result,
        if any mistake is detected later on.
      </p>

    </div>
  </>
  );
});

export default PassCertificateReport;


