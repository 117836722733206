import React, { Fragment } from "react";
import { useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

const Instruction = () => {
  return (
    <Fragment>
      <Container fluid>
        <Card className="mt-3">
          <CardTitle>Instructions</CardTitle>
          <CardBody>
            <Row>
              <Col>
                <div className=" instruction">
                  <p>
                    The menu on the left-hand-side provides links to each part
                    of the application. Here is a brief description of each
                    option.
                  </p>
                  <div className="">
                    <h6 className="underline-heading">Candidate Status</h6>
                    <p>
                      This section basically provides information about the
                      overall status of the candidate starting from filling the
                      Admission Form until the test is conducted and admission
                      is confirmed or rejected.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">Edit Application (UG)</h6>
                    <p>
                      This is the actual form that you must complete and submit.
                      There are about five sections in the application form,
                      which must be completed (depending on the program
                      requirements you are applying for) before the application
                      can be submitted to the Admission Office.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">Checklist</h6>
                    <p>
                      Lists all documents that you must submit to the Admission
                      Office before the deadline date mentioned in admission
                      schedule as well as details about application processing
                      fee.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">Instructions</h6>
                    <p>
                      The current page you are viewing. Provides a quick getting
                      started guide.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">Admission Schedule</h6>
                    <p>
                      This page provides the complete Admission Schedule
                      mentioning all the important details related to the
                      complete Admission process from start to end.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">
                      Eligibility Criteria-General
                    </h6>
                    <p>
                      Lists general eligibility criteria for all the programs.
                      You should go through this section to determine if you
                      meet the required criteria.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">
                      Eligibility Criteria-Program Wise
                    </h6>
                    <p>
                      Provides information whether you are eligible or
                      ineligible to take admission in certain department of a
                      certain program considering your past education.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading"> Admission Procedure</h6>
                    <p>
                      This page provides you information on how to apply for
                      admission in Shaikh Ayaz University Shikarpur.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">Prospectus</h6>
                    <p>
                      This page provides you to view prospectus of the Programs
                      offered in Shaikh Ayaz University Shikarpur for the current
                      year.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">Sample Papers</h6>
                    <p>
                      This page provides you to view sample test papers of the
                      departments offered in Shaikh Ayaz University Shikarpur.
                    </p>
                  </div>
                  <div className="">
                    <h6 className="underline-heading">Fee Structure</h6>
                    <p>
                      This page provides you to view fee structure for the
                      specific department offered in Shaikh Ayaz University Shikarpur
                      for the current Year.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default Instruction;
