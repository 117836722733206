import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Search,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  TMS_TMS_Employee_Leave_History,
} from "../../../utils/Config";
import { CustomErrorMessage } from "../../../components/Alert";
import {
  formatDateFunction1,
  formatDateFunction2,
} from "../../../functions/DateFormatFunction";
import { useState } from "react";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  PeriodFrom: "",
  PeriodTo: "",
};
const initialFormFields = { SetupDetailName: "" };

const LeaveHistory = () => {
  const {
    SearchFields,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        SearchFields: initialSearchFields,
      },
    });
    getLeaveHistory();
  }, []);

  const animatedComponents = makeAnimated();

  const getLeaveHistory = () => {
    const payload = {
      operationId: Select,
      leaveAppliedId: 0,
      employeeId: decryptData("EmplId", SessionStorage),
      fromDate: new Date(),
      toDate: new Date(),
      createdBy: 0,
      modifiedBy: 0,
      userIP: "string",
    };
   
    TMS_TMS_Employee_Leave_History(payload)
      .then((res) => {

        let data = res.data.Table.map((x) => ({
          ...x,
          FromDate: formatDateFunction2(x.FromDate),
          ToDate: formatDateFunction2(x.ToDate),
          CreatedDate: formatDateFunction2(x.CreatedDate),
        }));

        setFromDate("");
        setToDate("");
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: data,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "CreatedDate", name: "Applied Date" },
    { field: "FromDate", name: "From Date" },
    { field: "ToDate", name: "To Date" },
    { field: "SetupDetailName", name: "Leave Type" },
    { field: "NoOfDays", name: "No Of Days" },
    { field: "EmployeeReason", name: "Reason Of Leave" },
    { field: "SetupDetailName1", name: "Status" },
  ];

  const AllDateSet = (event, type) => {

    if (type === "PeriodFrom") {
      setFromDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodFrom = date;
      let data1 = {
        name: "PeriodFrom",
        value: SearchFields?.PeriodFrom,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    } else if (type === "PeriodTo") {
      setToDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodTo = date;
      let data1 = {
        name: "PeriodTo",
        value: SearchFields?.PeriodTo,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    }
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select From Date</label>
          <DatePicker
            selected={fromDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodFrom")}
            className="form-control"
            name="PeriodFrom"
            placeholderText={dateFormatPlaceholder}
            required
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select To Date</label>
          <DatePicker
            selected={toDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodTo")}
            className="form-control"
            name="PeriodTo"
            placeholderText={dateFormatPlaceholder}
            required
          />
        </div>
      </Col>
    </Fragment>
  );

  const cancelSearch = () => {

    setFromDate("");
    setToDate("");
  
    getLeaveHistory();
  };

  const submitSearch = () => {
    const payload = {
      operationId: Search,
      leaveAppliedId: 0,
      employeeId: decryptData("EmplId", SessionStorage),
      fromDate: SearchFields?.PeriodFrom,
      toDate: SearchFields?.PeriodTo,
      createdBy: 0,
      modifiedBy: 0,
      userIP: "string",
    };

    TMS_TMS_Employee_Leave_History(payload)
      .then((res) => {

        if (res.data.Table.length === 0) {
          CustomErrorMessage("No Record Found");
        } else {
          setFromDate("");
          setToDate("");
          let data = res.data.Table.map((x) => ({
            ...x,
            FromDate: formatDateFunction2(x.FromDate),
            ToDate: formatDateFunction2(x.ToDate),
            CreatedDate: formatDateFunction2(x.CreatedDate),
          }));

          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: data,
              FormFields: initialFormFields,
              SearchFields: initialSearchFields,
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CrudFormComponent
      formName="Leave History"
      hideAction={true}
      newButton={false}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default LeaveHistory;
