import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { Select, SessionStorage } from "../../../common/SetupMasterEnum";
import { decryptData } from "../../../EncryptData";
import {
  TMS_EmployeeWebSignInOut,
  TMS_GetWebSignInOutAccess,
} from "../../../utils/Config";

const WebSignInOut = () => {

  const FormValues = {
    OperationId: Select,
    AttDateTime: "2022-08-05T15:39:54.160Z",
    EmployeeId: decryptData("EmplId", SessionStorage),
    EmployeeCode: 0,
    ReaderAddress: "",
    MachineVerifyMode: "",
    CreatedBy: decryptData("loginId", SessionStorage),
    ModifiedBy: 0,
    UserIP: "192.168.85.5",
    
    
    
  };
  const [access, setAccess] = useState();
  const [message, setMessage] = useState("");
  useEffect(() => {
    getWebSignInOutAccess(decryptData("EmplId", SessionStorage));
  }, []);
  function employeeSignInOutActivity(payload) {
    TMS_EmployeeWebSignInOut(payload)
      .then((res) => {
        if (res?.data?.Table[0]?.Error == 0) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.Column1,
          });
        } else {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.Column1,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getWebSignInOutAccess(payload) {
    TMS_GetWebSignInOutAccess(payload)
      .then((res) => {
        setAccess(res?.data?.Table[0]?.haserror);
        setMessage(res?.data?.Table[0]?.Column1);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onSubmit = (e) => {
    e.preventDefault();
    employeeSignInOutActivity(FormValues);
  };
  return (
    <Container fluid>
      <Row>
        <Col className="text-center mt-2">
          {access == 0 ? (
            <Button onClick={onSubmit}>Punch Attendance</Button>
          ) : (
            <h5 className="text-center">{message}</h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default WebSignInOut;
