import React, { Fragment } from "react";
import { useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../utils/Config";
import { decryptData } from "../../../EncryptData";
import { ADMISSION_USER_ID, REG_ID } from "../../../utils/EncryptedConstants";
import { useEffect } from "react";
import { CustomErrorMessage } from "../../../components/Alert";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { SessionStorage } from "../../../common/SetupMasterEnum";

const CheckStatus = (props) => {
  useEffect(() => {
    getStudendRecords();
  }, []);

  const dispatch = useDispatch();
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const statusData = SupportingTables?.students?.Table6;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const [updateButton, setUpdateButton] = useState(false);
  const columns = [
    { field: "PersonalInfoStatus", name: "Personal Info" },
    { field: "AcademicsStatus", name: "Academics" },
    { field: "PreferenceStatus", name: " Preferences (Optional)" },

    { field: "DocumentsStatus", name: "Documents" },
    { field: "UndertakingStatus", name: "Undertaking" },
  ];

  const rows = [
    {
      PersonalInfoStatus: "Incomplete",
      AcademicsStatus: "Incomplete",
      PreferenceStatus: "Incomplete",
      UndertakingStatus: "Incomplete",
      DocumentsStatus: "Incomplete",
    },
  ];
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
    FinalSubmit: 1,
  };
  const onUpdate = (e) => {
    e.preventDefault();
    setUpdateButton(true);
    Swal.fire({
      text: "Kindly verify all the information entered. You will not be allowed to change any information once you submit the application.",
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#24344e",
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData();
        Object.keys(basicValues).forEach((d) => {
          formData.append(d, basicValues[d]);
        });

        updateAdmission(formData);
      } else {
        setUpdateButton(false);
      }
    });
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.Message,
          }).then((results) => {
            if (results.isDismissed) {
              getStudendRecords();
            } else if (results.isConfirmed) {
              getStudendRecords();
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.Message,
          });
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        setUpdateButton(false);
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: 3479,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
          setUpdateButton(false);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setUpdateButton(false);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Status Information</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>
              Before the application can be submitted, all steps listed below
              must appear as Ticked.
            </strong>
          </div>
          <Row>
            <Col>
              {statusData.length === 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped mb-0 mt-2">
                    <thead>
                      <tr>
                        <th className="text-center">Personal Info</th>
                        <th className="text-center">Academics</th>
                        <th className="text-center">Preferences (Optional)</th>
                        <th className="text-center">Documents</th>
                        <th className="text-center">Undertaking</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-danger text-center">
                          <i class="fa fa-times"></i>
                        </td>
                        <td className="text-danger text-center">
                          <i class="fa fa-times"></i>
                        </td>
                        <td className="text-danger text-center">
                          <i class="fa fa-times"></i>
                        </td>
                        <td className="text-danger text-center">
                          <i class="fa fa-times"></i>
                        </td>
                        <td className="text-danger text-center">
                          <i class="fa fa-times"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-striped mb-0 mt-2">
                    <thead>
                      <tr>
                        <th className="text-center">Personal Info</th>
                        <th className="text-center">Academics</th>
                        <th className="text-center">Preferences (Optional)</th>
                        <th className="text-center">Documents</th>
                        <th className="text-center">Undertaking</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statusData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className={
                                item.PersonalInfo == 1
                                  ? "text-center text-success"
                                  : "text-center text-danger"
                              }
                            >
                              {item.PersonalInfo == 1 ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-times"></i>
                              )}
                            </td>
                            <td
                              className={
                                item.Academics == 1
                                  ? "text-center text-success"
                                  : "text-center text-danger"
                              }
                            >
                              {item.Academics == 1 ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-times"></i>
                              )}
                            </td>
                            <td
                              className={
                                item.Preference == 1
                                  ? "text-center text-success"
                                  : "text-center text-danger"
                              }
                            >
                              {item.Preference == 1 ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-times"></i>
                              )}
                            </td>
                            <td
                              className={
                                item.Documents == 1
                                  ? "text-center text-success"
                                  : "text-center text-danger"
                              }
                            >
                              {item.Documents == 1 ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-times"></i>
                              )}
                            </td>

                            <td
                              className={
                                item.Undertaking == 1
                                  ? "text-center text-success"
                                  : "text-center text-danger"
                              }
                            >
                              {item.Undertaking == 1 ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-times"></i>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </Col>
          </Row>
          {/* {statusData[0]?.PersonalInfo == 1 &&
          statusData[0]?.Academics == 1 &&
          statusData[0]?.Preference == 1 &&
          statusData[0]?.Undertaking == 1 &&
          statusData[0]?.Documents == 1 ? ( */}
          <Row>
            <Col className="text-center mt-3">
              <Button
                disabled={
                  statusData[0]?.PersonalInfo == 1 &&
                  statusData[0]?.Academics == 1 &&
                  statusData[0]?.Preference == 1 &&
                  statusData[0]?.Undertaking == 1 &&
                  statusData[0]?.Documents == 1 &&
                  statusData[0]?.Status == 0
                    ? updateButton === true
                      ? true
                      : false
                    : true
                }
                onClick={onUpdate}
              >
                {statusData[0]?.Status == 1
                  ? "Submitted"
                  : "Finalize And Submit"}
              </Button>
            </Col>
          </Row>
          {/* ) : null} */}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CheckStatus;
