import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import FormGroupButton from "../../../components/GeneralComponent/FormGroupButton";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";

import propTypes from "prop-types";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/CrudActionTypes";
import { useEffect } from "react";
import useFade from "../../../Hooks/useFade";

const CrudTMSComponent = (props) => {
  const {
    searchPanel,
    formPanel,
    tableColumns,
    formName,
    newButton = true,
    buttonName,
    updateButtonName,
    updateControl,
    searchSubmit,
    cancelSearch,
    formSubmit,
    tableRows,
    formLoad,
    onEdit,
    onDelete,
    onConfirm,
    onRefuse,
    initialSearchFields,
    initialFormFields,
    featureList = [],
    handleCancel,
    customTable,
    hideAction,
    hideSerialNumber,
    applyLeaveFor,
    applyLeave,
    cancelLeave,
    leaveStatus,
    calendarTable,
  } = props;

  const dispatch = useDispatch();
  const { TableList } = useSelector((state) => state.CrudFormReducer);

  const [operationId, setOperationId] = useState(2);

  const [showSupply, setShowSupply, fadeOutProps] = useFade(false, 700);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchSubmit();
  };

  const handleApplyLeave = (e) => {
    e.preventDefault();

    applyLeave(operationId);
  };
  const handleApplyLeaveCancel = () => {
    cancelLeave();
  };

  const onConfirmRow = (index) => {
    let obj = TableList[index];
    onConfirm(obj);
  };

  const onRefuseRow = (index) => {
    let obj = TableList[index];
    onRefuse(obj);
  };

  useEffect(() => {
    setShowSupply(true);
  }, []);

  return (
    <Fragment>
      {showSupply && (
        <Container {...fadeOutProps} fluid>
          <Card className="mt-3">
            <CardTitle>{formName}</CardTitle>
            <CardBody>
              <form onSubmit={handleSearchSubmit}>
                {searchPanel && (
                  <Row>
                    {searchPanel}
                    {/* <Row className="mt-2"> */}
                    <Col lg="12" md="12" xs="12" className="text-right">
                      <Button color="primary" className="btn" type="submit">
                        Get Records
                      </Button>
                    </Col>
                    {/* </Row> */}
                  </Row>
                )}
              </form>
            </CardBody>
            <CardBody>
              {formLoad ? (
                <div className="form-load">
                  <Spinner color="light" size="md" />
                </div>
              ) : null}
              <Row>{calendarTable && calendarTable}</Row>
              <Row>
                {!customTable && tableColumns && (
                  <Col>
                    <FormGroupTable
                      hideAction={hideAction}
                      hideSerialNumber={hideSerialNumber}
                      columns={tableColumns}
                      rows={tableRows && tableRows}
                      onConfirm={onConfirmRow}
                      onRefuse={onRefuseRow}
                    />
                  </Col>
                )}
                {applyLeaveFor && leaveStatus && (
                  <Fragment>
                    {/* <form> */}
                    <Col lg="8" md="8" xs="12">
                      <form onSubmit={handleApplyLeave}>
                        {applyLeaveFor}
                        <Row>
                          <Col
                            lg="12"
                            md="12"
                            xs="12"
                            className="text-end mt-2"
                          >
                            <Button
                              color="primary"
                              className="btn"
                              type="submit"
                            >
                              Submit
                            </Button>
                            <Button
                              color="danger"
                              onClick={handleApplyLeaveCancel}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </Col>
                    {/* </form> */}
                    {leaveStatus && (
                      <Col lg="4" md="4" xs="12">
                        <Card>
                          <CardBody>{leaveStatus}</CardBody>
                        </Card>
                      </Col>
                    )}
                  </Fragment>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
    </Fragment>
  );
};

CrudTMSComponent.propTypes = {
  searchPanel: propTypes.element,
  formPanel: propTypes.element,
  tableColumns: propTypes.array,
  formName: propTypes.string,
  newButton: propTypes.bool,
  buttonName: propTypes.string,
  updateButtonName: propTypes.string,
  updateControl: propTypes.func,
  searchSubmit: propTypes.func,
  formSubmit: propTypes.func,
  tableRows: propTypes.array,
  onEdit: propTypes.func,
  onDelete: propTypes.func,
  onConfirm: propTypes.func,
  onRefuse: propTypes.func,
  initialSearchFields: propTypes.object,
  initialFormFields: propTypes.object,
  featureList: propTypes.array,
  handleCancel: propTypes.func,
  customTable: propTypes.element,
  hideAction: propTypes.bool,
  hideSerialNumber: propTypes.bool,
  applyLeaveFor: propTypes.element,
  applyLeave: propTypes.func,
  cancelLeave: propTypes.func,
  leaveStatus: propTypes.element,
  calendarTable: propTypes.element,
};

export default CrudTMSComponent;
