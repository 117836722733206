import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Alert, Table } from "reactstrap";
import {
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  CustomWarningMessage,
  WarningWithConfirmation,
} from "../../../components/Alert";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { CURRENT_SEMESTER_FACULTY_OPS } from "../../../utils/UrlConstants";
import Spinner from "react-spinkit";

const FacultyExamRecap = () => {
  const { FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const [formLoad, setFormLoad] = useState(true);

  const timeoutRef = useRef(null);

  const intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    enrollmentID: 0,
    MidTermExamMarks: 0,
    FinalExamMarks: 0,
    CpMarks: 0,
    OtherMarks: 0,
    TotalMarks: 0,
    IsSaved: true,
    IsLockByLecturer: true,
  };

  const intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_ = {
    FacultyLecturerCourseOutlineTimeSlotMappingID: 0,
    CourseSemesterLocationTimeSlotMappingID: 0,
    OutlineDescription: "",
    IsTaken: false,
    IsCompleted: false,
  };

  const [
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
  ] = useState(intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_);

  const [
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    setFacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
  ] = useState(
    intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_
  );

  const intialFormFields = {
    operationId: Select,
    parameterID: Update,
    employeeID: decryptData("EmplId", SessionStorage),
    courseSemesterLecturerMappingId: 0,
    courseSemesterMappingID: 0,
    userID: 0,
    userIP: "string",
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_: [
      enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    ],
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_: [
      facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    ],
  };

  useEffect(() => {
    getFacultyExamRecap();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getFacultyExamRecap = () => {
    const payload = {
      ...intialFormFields,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
    };
    PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
      .then((res) => {
        const filtered = res;
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: intialFormFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Name", name: "Name" },
    { field: "FatherName", name: "Father Name" },
    { field: "MidTermExamMarks", name: "Mid Term Marks" },
    { field: "OtherMarks", name: "Other Marks/ CP Marks" },
    { field: "attendanceMarks", name: "Attendance Marks" },
    { field: "CPMarks", name: "Total CP Marks" },
    { field: "FinalExamMarks", name: "Final Term Marks" },
    { field: "TotalMarks", name: "Total Marks" },

    // { field: "FinalExamMarks", name: "Total Marks" },
    // { field: "FinalExamMarks", name: "CGPA" },
    // { field: "FinalExamMarks", name: "Grade" },

    // { field: "IsSaved", name: "Saved" },
    { field: "IsPresentInMidtermExam", name: "Mid Term Attendance" },
    { field: "IsPresentInFinalExam", name: "Final Term Attendance " },
    { field: "IsLockByLecturer", name: "Locked by Lecturer" },
    { field: "HODRemarks", name: "HOD Remarks" },
    { field: "IsLockByHod", name: "Lock by Hod" },
    { field: "IsLockByExamController", name: "Lock by Exam Controller" },
  ];

  const handleInputChange = (e, index, name, type) => {
    e.preventDefault();
    if (type == "Lock") {
      TableList[index][name] = true;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    } else if (type == "Unlock") {
      TableList[index][name] = false;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    } else {
      TableList[index][e.target.name] = e.target.value;

      let otherMarks = TableList[index]["OtherMarks"];

      let cpMarksTOtal =
        +TableList[index]["OtherMarks"] + +TableList[index]["attendanceMarks"];
      TableList[index]["CPMarks"] = cpMarksTOtal;

      let finalMarks =
        +TableList[index]["MidTermExamMarks"] +
        +TableList[index]["CPMarks"] +
        +TableList[index]["FinalExamMarks"];
      TableList[index]["TotalMarks"] = finalMarks;

      if (TableList[index]["MidTermExamMarks"] > 30) {
        TableList[index]["MidTermExamMarks"] = 0;
        CustomErrorMessage("Mid Term Marks Should be less than 30");
      } else if (TableList[index]["OtherMarks"] > 15) {
        TableList[index]["OtherMarks"] = 0;
        CustomErrorMessage("Other Marks Should be less than 15");
      } else if (TableList[index]["FinalExamMarks"] > 50) {
        TableList[index]["FinalExamMarks"] = 0;
        CustomErrorMessage("Final Term Marks Should be less than 50");
      }
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList?.map((x) => ({
            ...x,
            OtherMarks: x.OtherMarks === null ? 0 : parseFloat(x.OtherMarks),
            TotalMarks: x.TotalMarks === null ? 0 : parseFloat(x.TotalMarks),
          })),
          FormFields: FormFields,
        },
      });
    }
  };

  const onSave = () => {
    const payload = {
      ...FormFields,
      operationId: Insert,
      parameterID: Update,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
      enrolledStudentAcademicRecordDetail_FacultyOps_TBL_: TableList,
    };
    WarningWithConfirmation(
      `Are you sure you want to submit exam details?`
    ).then((result) => {
      if (result.isConfirmed) {
        PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };
  const WarningModal = (e) => {
    e.preventDefault();
    CustomWarningMessage("Unlock Operation can only be performed by HOD.");
  };

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="text-center">S.No.</th>
              {columns &&
                columns?.map((column, index) => (
                  <th
                    //style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    key={index}
                    className="text-center"
                  >
                    {column.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {TableList?.map((item, index) => (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td
                  style={{ width: "15%", fontWeight: "600", color: "#772647" }}
                  className="text-center"
                >
                  {item.Name}
                </td>
                <td
                  style={{ width: "12%", fontWeight: "600", color: "#772647" }}
                  className="text-center"
                >
                  {item.FatherName}
                </td>
                {/* {item.IsLockByLecturer === false ? ()
                 } */}
                <td style={{ width: "5%" }}>
                  {item.IsLockByLecturer === true ? (
                    <FormGroupInput
                      name="MidTermExamMarks"
                      value={item?.MidTermExamMarks}
                      isFloat="true"
                      maxLength={2}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                  ) : item.IsPresentInMidtermExam === true ? (
                    <FormGroupInput
                      name="MidTermExamMarks"
                      value={item?.MidTermExamMarks}
                      isFloat="true"
                      maxLength={2}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled
                    />
                  ) : (
                    <FormGroupInput
                      name="MidTermExamMarks"
                      value={item?.MidTermExamMarks}
                      isFloat="true"
                      maxLength={2}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  )}
                </td>

                <td style={{ width: "5%" }}>
                  {item.IsLockByLecturer === true ? (
                    <FormGroupInput
                      name="OtherMarks"
                      field="OtherMarks"
                      isFloat="true"
                      maxLength={2}
                      disabled
                      value={item?.OtherMarks}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : item.IsLockByHod === true ? (
                    <FormGroupInput
                      name="OtherMarks"
                      field="OtherMarks"
                      isFloat="true"
                      maxLength={2}
                      disabled
                      value={item?.OtherMarks}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ) : (
                    <FormGroupInput
                      name="OtherMarks"
                      field="OtherMarks"
                      isFloat="true"
                      maxLength={2}
                      value={item?.OtherMarks}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  )}
                </td>

                <td style={{ width: "5%" }}>
                  {item.attendanceMarks === true ? (
                    <FormGroupInput
                      name="attendanceMarks"
                      isFloat="true"
                      maxLength={4}
                      onChange={(e) => handleInputChange(e, index)}
                      value={item.attendanceMarks}
                      disabled
                    />
                  ) : (
                    <FormGroupInput
                      name="attendanceMarks"
                      isFloat="true"
                      maxLength={4}
                      onChange={(e) => handleInputChange(e, index)}
                      value={item.attendanceMarks}
                      disabled
                    />
                  )}
                </td>

                <td style={{ width: "5%" }}>
                  {item.IsLockByLecturer === true ? (
                    <FormGroupInput
                      name="CPMarks"
                      isFloat="true"
                      maxLength={4}
                      List={item.CPMarks}
                      onChange={(e) => handleInputChange(e, index)}
                      value={item.CPMarks}
                      disabled
                    />
                  ) : (
                    <FormGroupInput
                      name="CPMarks"
                      // isFloat="true"
                      maxLength={4}
                      onChange={(e) => handleInputChange(e, index)}
                      value={item.CPMarks}
                      disabled
                    />
                  )}
                </td>

                <td style={{ width: "5%" }}>
                  {item.IsLockByLecturer === true ? (
                    <FormGroupInput
                      name="FinalExamMarks"
                      isFloat="true"
                      maxLength={2}
                      onChange={(e) => handleInputChange(e, index)}
                      value={item.FinalExamMarks}
                      disabled
                    />
                  ) : item.IsPresentInFinalExam === true ? (
                    <FormGroupInput
                      name="FinalExamMarks"
                      isFloat="true"
                      maxLength={2}
                      onChange={(e) => handleInputChange(e, index)}
                      value={item.FinalExamMarks}
                      disabled
                    />
                  ) : (
                    <FormGroupInput
                      name="FinalExamMarks"
                      isFloat="true"
                      maxLength={2}
                      onChange={(e) => handleInputChange(e, index)}
                      value={item.FinalExamMarks}
                    />
                  )}
                </td>

                <td style={{ width: "5%" }}>
                  <FormGroupInput
                    name="TotalMarks"
                    isFloat="true"
                    maxLength={2}
                    onChange={(e) => handleInputChange(e, index)}
                    value={item.TotalMarks}
                    disabled
                  />
                </td>

                {/* <td style={{ width: "7%" }} className="text-center">
                  {" "}
                  {item.IsSaved === false ? (
                    <a
                      href="#"
                      onClick={(e) =>
                        handleInputChange(e, index, "IsSaved", "Lock")
                      }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-unlock text-success"
                      ></i>
                    </a>
                  ) : (
                    <a
                      href="#"
                      onClick={(e) =>
                        handleInputChange(e, index, "IsSaved", "Unlock")
                      }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-lock text-danger"
                      ></i>
                    </a>
                  )}
                </td> */}
                <td className="text-center">
                  {" "}
                  {item.IsPresentInMidtermExam === false ? (
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-check text-success"
                    ></i>
                  ) : (
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-times text-danger"
                    ></i>
                  )}
                </td>

                <td className="text-center">
                  {" "}
                  {item.IsPresentInFinalExam === false ? (
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-check text-success"
                    ></i>
                  ) : (
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-times text-danger"
                    ></i>
                  )}
                </td>

                <td className="text-center">
                  {" "}
                  <a
                    href="#"
                    onClick={
                      !item.IsSavedByLecturer
                        ? (e) =>
                            handleInputChange(
                              e,
                              index,
                              "IsLockByLecturer",
                              item.IsLockByLecturer ? "Unlock" : "Lock"
                            )
                        : (e) => WarningModal(e)
                    }
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className={
                        item.IsLockByLecturer || item.IsSavedByLecturer
                          ? "fa fa-lock text-danger"
                          : "fa fa-unlock text-success"
                      }
                    ></i>
                  </a>
                </td>

                <td style={{ width: "250px" }}>
                  <FormGroupInput
                    name="HODRemarks"
                    isFloat="true"
                    maxLength={4}
                    disabled
                    // onChange={(e) => handleInputChange(e, index)}
                    value={item.HODRemarks}
                  />
                </td>

                <td className="text-center">
                  {" "}
                  {item.IsLockByHod === false ? (
                    <a
                    // href="#"
                    // onClick={(e) =>
                    //   handleInputChange(e, index, "IsLockByHod", "Lock")
                    // }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-unlock text-success"
                      ></i>
                    </a>
                  ) : (
                    <a
                    // href="#"
                    // onClick={(e) =>
                    //   handleInputChange(
                    //     e,
                    //     index,
                    //     "IsLockByHod",
                    //     "Unlock"
                    //   )
                    // }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-lock text-danger"
                      ></i>
                    </a>
                  )}
                </td>

                <td className="text-center">
                  {" "}
                  {item.IsLockByExamController === false ? (
                    <a
                    // href="#"
                    // onClick={(e) =>
                    //   handleInputChange(e, index, "IsLockByExamController", "Lock")
                    // }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-unlock text-success"
                      ></i>
                    </a>
                  ) : (
                    <a
                    // href="#"
                    // onClick={(e) =>
                    //   handleInputChange(
                    //     e,
                    //     index,
                    //     "IsLockByExamController",
                    //     "Unlock"
                    //   )
                    // }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-lock text-danger"
                      ></i>
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Student Course Marks"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      customTable={customTable}
      onClickSave={onSave}
    />
  );
};

export default FacultyExamRecap;
