import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "reactstrap";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../../redux/actionType/CrudActionTypes";
import AcademicFormComponent from "../../../../components/FormComponents/AcademicFormComponent";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../../utils/Config";
import { CURRENT_SEMESTER_HOD_OPS } from "../../../../utils/UrlConstants";
import {
  academicYearId,
  partYearID,
  Select,
  semesterId,
  SessionStorage,
} from "../../../../common/SetupMasterEnum";
import useSetupDetailList from "../../../../Hooks/useSetupDetailList";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../../EncryptData";

const HODOperations = (props) => {
  const initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    EnrollmentID: 0,
    HodRemarks: "string",
    IsLockByLecturer: true,
    IsLockByHod: true,
  };

  const [
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_HODOps_TBL_,
  ] = useState(initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_);

  const initialSearchFields = {
    OperationId: Select,
    ParameterID: Select,
    AcademicYearID: 0,
    SemesterID: 0,
    FacultyDepartmentID: 0,
    FacultyLecturerID: 0,
    CourseSemesterMappingID: 0,
    SetupFacultyDepartmentProgramLecturerID: 0,
    CourseSemesterLecturerMappingId: 0,
    UserID: decryptData("loginId", SessionStorage),
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_: [
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    ],
  };

  const { SearchFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [partID, setPartID] = useState();
  const [academicYearList] = useSetupDetailList(academicYearId);
  const [partList] = useSetupDetailList(partYearID);
  const [semesterList] = useSetupDetailList(semesterId, partID);

  const timeoutRef = useRef(null);

  const [formLoad, setFormLoad] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        SearchFields: initialSearchFields,
      },
    });
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getHODOperations = () => {
    setFormLoad(true);
    PostRequest(CURRENT_SEMESTER_HOD_OPS, SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: new Array(0),
            SearchFields: SearchFields,
          },
        });
        const SearchedList = {
          name: "HODSearchedList",
          value: res?.data?.Table?.map((x) => ({
            ...x,
            Outline: "Outline",
            ClassSchedule: "Class Schedule",
            Files: "Files",
            Recap: "Recap",
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: SearchedList,
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Course Name" },
    { field: "EmployeeName", name: "Employee Name" },
    { field: "Program", name: "Program" },
    { field: "Semester", name: "Semester" },
    { field: "Outline", name: "Outline" },
    { field: "ClassSchedule", name: "Class Schedule" },
    { field: "Files", name: "Files" },
    { field: "Recap", name: "Recap" },
  ];

  const onClickRow = (type, index, obj) => {
    if (type === "Class Schedule") {
      props.history.push(
        `/pages/academics/faculty/hodfacultyschedule/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesteMappingID}`
      );
    } else if (type === "Recap") {
      props.history.push(
        `/pages/academics/faculty/hodfacultyexamrecap/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesteMappingID}`
      );
    } else if (type === "Outline") {
      props.history.push(
        `/pages/academics/faculty/hodfacultysemesteroutline/${obj?.CourseSemesterLecturerMappingId}/${obj?.CourseSemesteMappingID}`
      );
    }
  };

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={academicYearList}
          label="Academic Year"
          name="AcademicYearID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.AcademicYearID}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={partList}
          label="Part"
          name="partID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={partID}
          onChange={(e) => setPartID(e.target.value)}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={semesterList}
          label="Semester"
          name="SemesterID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.SemesterID}
          onChange={handleSearchChange}
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    getHODOperations();
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    setPartID();
    getHODOperations();
  };

  return (
    <AcademicFormComponent
      SemesterName="Faculty Courses"
      formName="HOD Operations"
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      columns={columns}
      rows={SupportingTables?.HODSearchedList}
      onClickRow={onClickRow}
      formLoader={formLoad}
      tableColorCode={true}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default HODOperations;
