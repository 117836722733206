import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Table,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";
import DatePicker from "react-datepicker";
import {
  Select,
  Search,
  adjustmentReasons,
  Insert,
  pendingStatus,
  SessionStorage,
} from "../../../common/SetupMasterEnum";

import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_SEARCH_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Setup_MasterDetails_All_Dropdowns,
  TMS_TMS_Employee_DailyActivity_In_Out_Report,
  TMS_TMS_Employee_TimeAdjustmentRequestt,
} from "../../../utils/Config";

import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import {
  formatDateFunc,
  formatDateFunction1,
  getOnlyTime,
} from "../../../functions/DateFormatFunction";
import { EMPLOYEE_NAME } from "../../../utils/EncryptedConstants";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  EmployeeCategory: "",
  GenderType: "",
  IsActive: false,
  PeriodFrom: "",
  PeriodTo: ""
};
const initialFormFields = {
  TimeRequested: "",
  AllDates: "",
  ShiftTimings: "",
  Time: "",
  Reason: 0,
  Comment: "",
};

const InOrOutTime = (props) => {
  const {
    SearchFields,
    FormFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [time, setTime] = useState("");

  const [inOrOut, setInOrOut] = useState(0);
  const [perviousDate, setPreviousDate] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    var date = new Date();
    var dt = new Date(date);
    dt.setDate(dt.getDate() - 2);
    setPreviousDate(formatDateFunc(dt, "-"));
    getTimeInOut();
    getDropDown();
  }, []);

  function getTimeInOut() {
    const payload = {
      operatiopnId: Select,
      employeeId: decryptData("EmplId", SessionStorage),
      fromdate: "",
      toDate: "",
      case: true,
    };
    TMS_TMS_Employee_DailyActivity_In_Out_Report(payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getDropDown() {
    const payload = {
      operationId: Select,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "SuportingTable",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const submitSearch = () => {
    const payload = {
      operatiopnId: Search,
      employeeId: decryptData("EmplId", SessionStorage),
      fromdate: SearchFields?.PeriodFrom,
      toDate: SearchFields?.PeriodTo,
      case: true,
    };
    TMS_TMS_Employee_DailyActivity_In_Out_Report(payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {};

  const cancelSearch = () => {
    setFromDate("");
    setToDate("");
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getTimeInOut();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
    getTimeInOut();
  };

  const columns = [
    { field: "TimeIn", name: "Time In" },
    { field: "TimeOut", name: "Time Out" },
    { field: "ShiftTimings", name: "ShiftTimings" },
  ];

  const onEditInOutRow = (e, index, item, type) => {
    e.preventDefault();
    let obj = TableList[index];

    if (type === "TimeIn") {
      setInOrOut(0);
      let data = {
        AllDates: obj.AllDates,
        ShiftTimings: obj.ShiftTimings,
        Time: obj.TimeIn,
      };
      dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
    } else {
      setInOrOut(1);
      let data = {
        AllDates: obj.AllDates,
        ShiftTimings: obj.ShiftTimings,
        Time: obj.TimeOut,
      };
      dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
    }
    toggle();
  };

  const customTable = (
    <Table bordered striped responsive style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>S.No.</th>
          {columns &&
            columns.map((column, index) => (
              <th
                style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                key={index}
              >
                {column.name}
              </th>
            ))}
        </tr>
      </thead>
      {TableList?.map((item, index) => (
        <tbody>
          <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
              {item.TimeIn}
              {formatDateFunc(item.TimeIn, "-") > perviousDate &&
              item.ShiftTimings != "N/A" ? (
                <a href="">
                  {" "}
                  <i
                    className="fa fa-pencil p-0"
                    onClick={(e) => onEditInOutRow(e, index, item, "TimeIn")}
                  ></i>
                </a>
              ) : null}
            </td>
            <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
              {item.TimeOut}
              {formatDateFunc(item.TimeIn, "-") > perviousDate &&
              item.ShiftTimings != "N/A" ? (
                <a href="">
                  {" "}
                  <i
                    className="fa fa-pencil p-0"
                    onClick={(e) => onEditInOutRow(e, index, item, "TimeOut")}
                  ></i>
                </a>
              ) : null}
            </td>
            <td style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
              {item.ShiftTimings}
            </td>
          </tr>
        </tbody>
      ))}
    </Table>
  );

  const AllDateSet = (event, type) => {

    if (type === "PeriodFrom") {
      setFromDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodFrom = date;
      let data1 = {
        name: "PeriodFrom",
        value: SearchFields?.PeriodFrom,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "PeriodTo") {
      setToDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodTo = date;
      let data1 = {
        name: "PeriodTo",
        value: SearchFields?.PeriodTo,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "TimeRequested") {
      setTime(event);
      let date = getOnlyTime(event, ":");
      FormFields.TimeRequested = date;
      let data1 = {
        name: "TimeRequested",
        value: FormFields?.TimeRequested,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    }
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const handleCancelToggle = () => {
    toggle();
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
    setTime("");
  };

  // substring(0, obj.TimeOut.indexOf(' '))

  const handleSaveModal = () => {
    saveTimeAdjustmentRequest();
  };

  function saveTimeAdjustmentRequest() {
    let orignailDateTime = FormFields.Time;
    let extractedDate = formatDateFunction1(orignailDateTime, "-");
    let concatinatedDateTime = extractedDate.concat(
      "T",
      FormFields.TimeRequested
    );

    if (FormFields?.Reason && FormFields?.TimeRequested != "") {
      const payload = {
        operationId: Insert,
        timeAdjustmentRequestId: 0,
        dailyActivityId: 0,
        employeeId: decryptData("EmplId", SessionStorage),
        employeeComment: FormFields?.Comments,
        isTimeOutRequest: inOrOut === 0 ? false : true,
        originalDateTime: FormFields?.Time,
        requestedDateTime: concatinatedDateTime,
        fromDate: "2022-07-28T19:42:39.079Z",
        toDate: "2022-07-28T19:42:39.079Z",
        approvarId: 0,
        approverComment: "",
        statusId: pendingStatus,
        adjustmentReasonId: FormFields?.Reason,
        imagePath: "",
        createdBy: 1,
        modifiedBy: 0,
        isActive: true,
        userIP: "string",
      };

      TMS_TMS_Employee_TimeAdjustmentRequestt(payload)
        .then((res) => {
          if (res.data.Table1[0].HassError === 0) {
            dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
            setTime("");
            toggle();
            CustomSuccessAlert(res.data.Table1[0].Message);
          } else {
            CustomErrorMessage(res.data.Table1[0].Message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      CustomErrorMessage(
        "Please Fill Reason and Requested Time For Adjustment"
      );
    }
  }

  const customModal = (
    <Modal
      isOpen={modal}
      size="lg"
      centered
      toggle={toggle}
      modalTransition={{ timeout: 10 }}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Discrepancy Request</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg="12" md="12" xs="12">
            <Table bordered striped responsive>
              <tbody>
                <tr>
                  <td>Request Number</td>
                  <td>
                    <FormGroup>
                      <Input
                        disabled
                        type="text"
                        className="form-control"
                        style={{ width: "51%" }}
                      />
                    </FormGroup>
                  </td>
                </tr>

                <tr>
                  <td>Request Creation Time</td>
                  <td>
                    <Input
                      disabled
                      type="text"
                      className="form-control"
                      style={{ width: "51%" }}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Employee Name</td>
                  <td>{decryptData(EMPLOYEE_NAME, SessionStorage)}</td>
                </tr>

                <tr>
                  <td>Original Time</td>
                  <td>{formatDateFunc(FormFields?.Time, "/")}</td>
                </tr>

                <tr>
                  <td>Requested Time</td>
                  <td>
                    <Input
                      type="text"
                      // value="22-02-2022"
                      className="form-control"
                      style={{ width: "51%" }}
                      value={formatDateFunc(FormFields?.Time, "/")}
                    />
                    <div style={{ marginTop: "7px", display: "flex" }}>
                      <div className="form-group">
                        <label className="form-label">Time</label>
                        <DatePicker
                          selected={time}
                          onChange={(date) => AllDateSet(date, "TimeRequested")}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeFormat="HH:mm"
                          dateFormat="hh:mm:ss"
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Current Status</td>
                  <td>Not Send</td>
                </tr>

                <tr>
                  <td>Reason</td>
                  <td>
                    <Col>
                      <FormGroupSelect
                        list={SupportingTables?.SuportingTable?.filter(
                          (x) => x.SetupMasterId == adjustmentReasons
                        )}
                        name="Reason"
                        onChange={handleAddChange}
                        value={FormFields?.Reason}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                      />
                    </Col>
                  </td>
                </tr>

                <tr>
                  <td>Comments</td>
                  <td>
                    <textarea
                      className="form-contreol"
                      rows="3"
                      name="Comments"
                      onChange={handleAddChange}
                      value={FormFields?.Comments}
                      required
                      style={{
                        width: "100%",
                        border: "1px solid #ebebeb",
                        background: "#f9f9f9",
                      }}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>Managed On</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Managed By</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Manager Comments</td>
                  <td>
                    <textarea
                      className="form-contreol"
                      rows="3"
                      disabled
                      style={{
                        width: "100%",
                        border: "1px solid #ebebeb",
                        background: "#f9f9f9",
                      }}
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td>Day End Processed</td>
                  <td>False</td>
                </tr>

                <tr>
                  <td>Processed On</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveModal}>
          Save
        </Button>
        <Button color="default" onClick={handleCancelToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  const searchPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <div className="form-group">
          <label className="form-label">Period From</label>
          <DatePicker
            selected={fromDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodFrom")}
            className="form-control"
            name="PeriodFrom"
            placeholderText={dateFormatPlaceholder}
            filterDate={(date) => date.getDay() !== 6 && date.getDay() !== 0}
            maxDate={toDate}
            time
          />
        </div>
      </Col>
      <Col lg="4" md="4" xs="12">
        <div className="form-group">
          <label className="form-label">Period To</label>
          <DatePicker
            selected={toDate}
            dateFormat={dateFormat}
            minDate={fromDate}
            onChange={(e) => AllDateSet(e, "PeriodTo")}
            className="form-control"
            name="PeriodTo"
            placeholderText={dateFormatPlaceholder}
            filterDate={(date) => date.getDay() !== 6 && date.getDay() !== 0}
          />
        </div>
      </Col>
    </Fragment>
  );

  return (
    <Fragment>
      <CrudFormComponent
        formName="Discrepancy Request"
        // buttonName="Add"
        hideAction={false}
        tableColumns={columns}
        tableRows={TableList}
        searchPanel={searchPanel}
        formSubmit={submitForm}
        searchSubmit={submitSearch}
        initialFormFields={initialFormFields}
        featureList={menuTable?.Table2?.filter(
          (x) => x.MenuId === selectedMenu
        )}
        cancelSearch={cancelSearch}
        handleCancel={handleCancel}
        customTable={customTable}
        customModal={customModal}
      />
    </Fragment>
  );
};

export default InOrOutTime;
