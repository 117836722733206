// export const formatDateFunction =(date) => {
//     var d = new Date(date),
//         month = '' + (d.getMonth() + 1),
//         day = '' + d.getDate(),
//         year = d.getFullYear();

//     if (month.length < 2) 
//         month = '0' + month;
//     if (day.length < 2) 
//         day = '0' + day;

//     return [year, month, day].join('-');
// }
export const dateFormat = "dd/MM/yyyy"
export const dateFormatPlaceholder = "dd/mm/yyyy"

export const timeFormatPlaceholder = "12:00 AM"

