import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { dateFormat, dateFormatPlaceholder } from "../../common/dateFormatEnum";
import {
  academicYearId,
  campusCity,
  campusType,
  descriptionId,
  Select,
  SessionStorage,
} from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert, DeleteWithConfirmation } from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import {
  formatDateFunction,
  formatDateFunctionByName,
} from "../../functions/DateFormatFunction";
import DatePicker from "react-datepicker";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
  Admission_Description,
  Admission_Schedule_Records,
  PostRequest,
  Setup_MasterDetails_All_Dropdowns,
} from "../../utils/Config";
import Swal from "sweetalert2";
import { decryptData } from "../../EncryptData";
import { ADMISSION_DESCRIPTION, ADMISSION_SCHEDULE_RECORDS, SETUP_MASTER_DETAIL_ALL_DROPDOWN } from "../../utils/UrlConstants";

const initialSearchFields = {
  OperationId: 0,
  AcademicYearId: 0,
  CampusTypeId: 0,
  CampusCityId: 0,
  AdmissionSchedule_: {},
  ProgramId: 0,
};
const initialFormFields = {
  AdmissionScheduleId: 0,
  AcademicYearId: 0,
  UniversityId: 0,
  CampusTypeId: 0,
  CampusCityId: 0,
  StartDate: new Date(),
  EndDate: new Date(),
  Extendornot: 0,
  OldDate: new Date(),
  Remarks: "",
  DescriptionId: 0,
  IsActive: 0,
  IsDeleted: 0,
  UsedId: 0,
};
const initialDescFields = {
  Description_: "",
  UserId: 0,
};

const ImportantDates = () => {
  initialDescFields.UserId = decryptData("loginId", SessionStorage);
  initialSearchFields.ProgramId = 3479;
  const { SearchFields, FormFields, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [descFields, setDescFields] = useState(initialDescFields);
  const [dropdownList, setDropDownList] = useState([]);
  const [toggleDesc, setToggleDesc] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);

  useEffect(() => {
    getAdmissionScheduleRecords();
    getDropDown();
  }, []);

  const getAdmissionScheduleRecords = () => {
    PostRequest(ADMISSION_SCHEDULE_RECORDS, initialSearchFields)
    
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function getDropDown() {
    const payload = {
      operationId: Select,
    };

    PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
    
      .then((res) => {
        setDropDownList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "AcademicYearName", name: "Academic Year" },
    { field: "Description", name: "Description" },
    { field: "CampusTypeName", name: "Campus Type" },
    { field: "CampusCityName", name: "Campus City" },
    { field: "StartDate", name: "Start Date" },
    { field: "EndDate", name: "End Date" },
    // { field: "OldDate", name: "Old Date" },
    { field: "Remarks", name: "Remarks" },
    { field: "Extendornot", name: "Extend To" },
    { field: "Action", name: "Action" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const AllDateSet = (event, type) => {
    if (type === "StartDate") {
      let date = formatDateFunction(event, "-");
      FormFields.StartDate = date;
      let data1 = {
        name: "StartDate",
        value: FormFields.StartDate,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "EndDate") {
      let date = formatDateFunction(event, "-");
      FormFields.EndDate = date;
      let data1 = {
        name: "EndDate",
        value: FormFields.EndDate,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "OldDate") {
      let date = formatDateFunction(event, "-");
      FormFields.OldDate = date;
      let data1 = {
        name: "OldDate",
        value: FormFields.OldDate,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    }
  };
  const handleGridChange = (e, index) => {
    let obj = TableList[index];
    obj = {
      ...obj,
      [e.target.name]: e.target.value,
    };

    TableList[index] = obj;

    if (obj.Extendornot == 1) {
      dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: obj });
      setToggleForm(true);
    }
  };
  const handleDescFields = (e) => {
    setDescFields({
      ...descFields,
      [e.target.name]: e.target.value,
    });
  };
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == academicYearId)}
          label="Academic Year"
          name="AcademicYearId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.AcademicYearId}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == campusType)}
          label="Campus Type"
          name="CampusTypeId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.CampusTypeId}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == SearchFields?.CampusTypeId
          )}
          label="Campus City"
          name="CampusCityId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.CampusCityId}
          onChange={handleSearchChange}
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      OperationId: 0,
      AcademicYearId: SearchFields?.AcademicYearId,
      CampusTypeId: SearchFields?.CampusTypeId,
      CampusCityId: SearchFields?.CampusCityId,
      ProgramId: 3479,
      AdmissionSchedule_: {},
    };
    PostRequest(ADMISSION_SCHEDULE_RECORDS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function createDescription(payload) {
    PostRequest(ADMISSION_DESCRIPTION, payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              setToggleDesc(false);
              setDescFields({
                ...initialDescFields,
              });
              getDropDown();
            } else if (results.isConfirmed) {
              setToggleDesc(false);
              setDescFields({
                ...initialDescFields,
              });
              getDropDown();
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  }
  const submitForm = (id) => {
    const payload = {
      OperationId: 1,
      AcademicYearId: 0,
      CampusTypeId: 0,
      CampusCityId: 0,
      ProgramId: 3479,
      AdmissionSchedule_: {
        AdmissionScheduleId: 0,
        AcademicYearId: FormFields?.AcademicYearId,
        UniversityId: FormFields?.UniversityId,
        CampusTypeId: FormFields?.CampusTypeId,
        CampusCityId: FormFields?.CampusCityId,
        StartDate: FormFields?.StartDate,
        EndDate: FormFields?.EndDate,
        Extendornot: 0,
        OldDate: null,
        Remarks: FormFields?.Remarks,
        DescriptionId: FormFields?.DescriptionId,
        IsActive: 1,
        IsDeleted: 0,
        UsedId: decryptData("loginId", SessionStorage),
      },
    };

    // const newPayload = {
    //   OperationId: 2,
    //   SetupMarks_: payload,
    // };
    PostRequest(ADMISSION_SCHEDULE_RECORDS, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          getAdmissionScheduleRecords();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        const data = {
          OperationId: 1,
          AcademicYearId: 0,
          CampusTypeId: 0,
          CampusCityId: 0,
          ProgramId: 3479,
          AdmissionSchedule_: {
            AdmissionScheduleId: obj?.AdmissionScheduleId,
            AcademicYearId: obj?.AcademicYearId,
            UniversityId: obj?.UniversityId,
            CampusTypeId: obj?.CampusTypeId,
            CampusCityId: obj?.CampusCityId,
            StartDate: obj?.StartDate,
            EndDate: obj?.EndDate,
            Extendornot: 0,
            OldDate: obj?.OldDate,
            Remarks: obj?.Remarks,
            DescriptionId: obj?.DescriptionId,
            IsActive: 0,
            IsDeleted: 0,
            UsedId: decryptData("loginId", SessionStorage),
          },
        };
        PostRequest(ADMISSION_SCHEDULE_RECORDS, data)
          .then((res) => {
            if (res.data.Table[0].HasError === 0) {
              CustomSuccessAlert(res.data.Table[0].Message);
              getAdmissionScheduleRecords();
            } else {
              CustomErrorMessage(res.data.Table[0].Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getAdmissionScheduleRecords();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };
  const onAddDescription = (e) => {
    e.preventDefault();
    setToggleDesc(true);
  };
  const submitDesc = (e) => {
    e.preventDefault();
    createDescription(descFields);
  };
  const handleCancelDesc = () => {
    setToggleDesc(false);
    setDescFields({
      ...initialDescFields,
    });
  };
  const handleCancelDate = () => {
    setToggleForm(false);
    getAdmissionScheduleRecords();
  };
  const onUpdateDate = () => {
    const data = {
      OperationId: 1,
      AcademicYearId: 0,
      CampusTypeId: 0,
      CampusCityId: 0,
      ProgramId: 3479,
      AdmissionSchedule_: {
        AdmissionScheduleId: FormFields?.AdmissionScheduleId,
        AcademicYearId: FormFields?.AcademicYearId,
        UniversityId: FormFields?.UniversityId,
        CampusTypeId: FormFields?.CampusTypeId,
        CampusCityId: FormFields?.CampusCityId,
        StartDate: FormFields?.StartDate,
        EndDate: FormFields?.EndDate,
        Extendornot: FormFields?.Extendornot,
        OldDate: FormFields?.OldDate,
        Remarks: FormFields?.Remarks,
        DescriptionId: FormFields?.DescriptionId,
        IsActive: 1,
        IsDeleted: 0,
        UsedId: decryptData("loginId", SessionStorage),
      },
    };
    PostRequest(ADMISSION_SCHEDULE_RECORDS, data)
    
      .then((res) => {
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          getAdmissionScheduleRecords();
          setToggleForm(false);
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
          setToggleForm(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == academicYearId)}
          label="Academic Year"
          name="AcademicYearId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.AcademicYearId}
          onChange={handleAddChange}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == campusType)}
          label="Campus Type"
          name="CampusTypeId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.CampusTypeId}
          onChange={handleAddChange}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == FormFields?.CampusTypeId
          )}
          label="Campus City"
          name="CampusCityId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.CampusCityId}
          onChange={handleAddChange}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <div className="form-group">
          <label className="form-label">
            Start Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={new Date(FormFields?.StartDate)}
            onChange={(e) => AllDateSet(e, "StartDate")}
            className="form-control"
            name="StartDate"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="6" md="6" xs="12">
        <div className="form-group">
          <label className="form-label">
            End Date<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={new Date(FormFields?.EndDate)}
            onChange={(e) => AllDateSet(e, "EndDate")}
            className="form-control"
            name="EndDate"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == descriptionId)}
          label="Description"
          name="DescriptionId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.DescriptionId}
          onChange={handleAddChange}
          required
        />
        <p
          style={{ fontSize: "12px", marginBottom: "0px" }}
          className="text-right"
        >
          <a href="#" onClick={onAddDescription}>
            Add Description
          </a>
        </p>
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Remarks"
          name="Remarks"
          value={FormFields?.Remarks}
          onChange={handleAddChange}
          required
        />
      </Col>
      <Modal
        isOpen={toggleDesc}
        centered
        modalTransition={{ timeout: 10 }}
        // backdrop="static"
      >
        <ModalHeader>Add Description</ModalHeader>
        <ModalBody>
          <form>
            <Row>
              <Col md="6">
                <FormGroupInput
                  label="Description"
                  name="Description_"
                  value={descFields?.Description_}
                  onChange={handleDescFields}
                  required
                />
              </Col>
            </Row>
            <Row>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button color="primary" type="button" onClick={submitDesc}>
                  Submit
                </Button>
                <Button color="default" onClick={handleCancelDesc}>
                  Close
                </Button>
              </div>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
  const customTable = (
    <Table bordered striped responsive style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>S.No.</th>
          {columns &&
            columns.map((column, index) => (
              <th
                style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                key={index}
              >
                {column.name}
              </th>
            ))}
        </tr>
      </thead>

      <tbody>
        {TableList?.map((item, index) => (
          <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td>{item.AcademicYearName}</td>
            <td>{item.Description}</td>
            <td>{item.CampusTypeName}</td>
            <td>{item.CampusCityName}</td>
            <td>{formatDateFunctionByName(item.StartDate, " ", "short")}</td>
            <td>{formatDateFunctionByName(item.EndDate, " ", "short")}</td>
            {/* <td>{item.OldDate}</td> */}
            <td>{item.Remarks}</td>
            <td>
              {menuTable?.Table2.filter((x) => x.MenuId === selectedMenu).some(
                (x) => x.FeatureId === 2
              ) ? (
                <input
                  type="checkbox"
                  checked={item.Extendornot}
                  name="Extendornot"
                  onChange={(e) =>
                    handleGridChange(
                      {
                        target: {
                          name: e.target.name,
                          value: e.target.checked == true ? 1 : 0,
                        },
                      },
                      index
                    )
                  }
                  value={item.Extendornot}
                  disabled={item.Extendornot == 1 ? true : false}
                />
              ) : null}
            </td>
            <td>
              {menuTable?.Table2.filter((x) => x.MenuId === selectedMenu).some(
                (x) => x.FeatureId === 3
              ) ? (
                <Button
                  color="danger"
                  className="btnic"
                  size="sm"
                  onClick={() => onDeleteRow(item)}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
  const customModal = (
    <Modal
      isOpen={toggleForm}
      centered
      // toggle={toggle}
      modalTransition={{ timeout: 10 }}
      // backdrop="static"
    >
      <ModalHeader
      // toggle={toggle}
      >
        Extend To
      </ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <Col md="6">
              <div className="form-group">
                <label className="form-label">Extend To</label>
                <DatePicker
                  selected={new Date(FormFields?.OldDate)}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "OldDate")}
                  className="form-control"
                  name="OldDate"
                  placeholderText={dateFormatPlaceholder}
                  required
                  showYearDropdown={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button color="primary" type="button" onClick={onUpdateDate}>
                Submit
              </Button>
              <Button color="default" onClick={handleCancelDate}>
                Close
              </Button>
            </div>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
  return (
    <CrudFormComponent
      formName="Admission Schedule"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      customTable={customTable}
      customModal={customModal}
    />
  );
};

export default ImportantDates;
