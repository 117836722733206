import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import {
    admissionFacultyTypeId,
    admissionProgramId,
  admissionTypeId,
  blockDepartmentId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  examStatusId,
  facultyDepartmentId,
  facultyTypeId,
  Search,
  Select,
  SessionStorage,
} from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
    Admission_InterviewScreening,
  PostRequest,
  Setup_Admission_Blocks,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_SeatType,
} from "../../utils/Config";
import { SETUP_MASTER_DETAIL_ALL_DROPDOWN } from "../../utils/UrlConstants";

const initialSearchFields = {
  OperationId: 1,
  RegId: 0,
  StatusId: 0,
  InterviewId: 0,
  ProgramId: 0,
  AdmissionTypeId: 0,
  FacultyId: 0,
  FacultyDepId: 0,
  InterviewMarks: 0,
  Remarks: "",
  IsActive: 1,
  IsDeleted: 0,
  UserId: 0,
};
const initialFormFields = {
  RegId: 0,
  StatusId: 0,
  InterviewId: 0,
  ProgramId: 0,
  AdmissionTypeId: 0,
  FacultyId: 0,
  FacultyDepId: 0,
  InterviewMarks: 0,
  Remarks: "",
};

const InterviewScreening = () => {
  initialSearchFields.UserId = decryptData("loginId", SessionStorage);
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [dropdownList, setDropDownList] = useState([]);

  useEffect(() => {
    getInterViewScreeningData();
    getDropDown();
  }, []);

  const getInterViewScreeningData = () => {
    Admission_InterviewScreening(initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function getDropDown() {
    const payload = {
      operationId: Select,
    };

    // Setup_MasterDetails_All_Dropdowns(payload)
      PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
      .then((res) => {
        setDropDownList(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "ADMRegID", name: "RegId" },
    { field: "ProgramName", name: "Program" },
    { field: "AdmissionTypeName", name: "Admission Type" },
    { field: "AdmissionFacultyTypeName", name: "Admission Faculty Type" },
    { field: "AdmissionFacultyDepartmentName", name: "Admission Faculty Department" },
    { field: "InterviewMarks", name: "Interview Marks" },
    { field: "TotalMarks", name: "Total Marks" },
    { field: "Remarks", name: "Remarks" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == examStatusId)}
          label="Status"
          name="StatusId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.StatusId}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) =>
              x.SetupMasterId == admissionProgramId )}
          label="Program"
          name="ProgramId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.ProgramId}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) => x.SetupMasterId == admissionTypeId
          )}
          label="Admission Type"
          name="AdmissionTypeId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.AdmissionTypeId}
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) => x.SetupMasterId == admissionFacultyTypeId
          )}
          label="Faculty Type"
          name="FacultyId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.FacultyId}
          onChange={handleSearchChange}
        //   required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) => x.SetupMasterId == facultyDepartmentId
          )}
          label="Faculty Department"
          name="FacultyDepId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.FacultyDepId}
          onChange={handleSearchChange}
        //   required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Interview Marks"
          name="InterviewMarks"
          value={SearchFields?.InterviewMarks}
          onChange={handleSearchChange}
          isNumber="true"
        //   required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {

    const payload = {
      OperationId: 1,
      InterviewId: 0,
      StatusId: SearchFields?.StatusId,
      ProgramId: SearchFields?.ProgramId,
      AdmissionTypeId: SearchFields?.AdmissionTypeId,
      FacultyId: SearchFields?.FacultyId,
      FacultyDepId: SearchFields?.FacultyDepId,
      InterviewMarks: SearchFields?.InterviewMarks,
      Remarks: SearchFields?.Remarks,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    Admission_InterviewScreening(payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {

    const payload = {
      OperationId: 2,
      RegId: FormFields.RegId,
      InterviewId: FormFields?.InterviewId,
      StatusId: FormFields?.StatusId,
      ProgramId: FormFields?.ProgramId,
      AdmissionTypeId: FormFields?.AdmissionTypeId,
      FacultyId: FormFields?.FacultyId,
      FacultyDepId: FormFields?.FacultyDepId,
      InterviewMarks: FormFields?.InterviewMarks,
      TotalMarks: FormFields?.TotalMarks,
      Remarks: FormFields?.Remarks,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };
    if(FormFields?.InterviewMarks > FormFields?.TotalMarks){
        CustomErrorMessage("Make sure interview marks less then total marks");
    }
    else{
    Admission_InterviewScreening(payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].Haserror === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          getInterViewScreeningData();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }
  };

  const onEditRow = (obj) => {
    const data = {
      OperationId: 2,
      InterviewId: obj.InterviewId,
      RegId: obj.ADMRegID,
      Name: obj.Name,
      StatusId: obj.StatusId,
      ProgramId: obj.ProgramId,
      AdmissionTypeId: obj.AdmissionTypeId,
      FacultyId: obj.FacultyId,
      FacultyDepId: obj.FacultyDepId,
      InterviewMarks: obj.InterviewMarks,
      TotalMarks: obj.TotalMarks,
      Remarks: obj.Remarks,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };


  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getInterViewScreeningData();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="RegId"
          name="RegId"
          disabled
          isNumber="true"
          onChange={handleAddChange}
          value={FormFields?.RegId}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Name"
          name="Name"
          disabled
          onChange={handleAddChange}
          value={FormFields?.Name}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Interview Marks"
          name="InterviewMarks"
          maxLength="150"
          required
          isNumber="true"
          onChange={handleAddChange}
          value={FormFields?.InterviewMarks}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Total Marks"
          name="TotalMarks"
          disabled
          onChange={handleAddChange}
          value={FormFields?.TotalMarks}
        />
      </Col>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Interview"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      hideSubmitButton={true}
    />
  );
};

export default InterviewScreening;
