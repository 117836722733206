import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import {
  admissionProgramId,
  admissionTypeId,
} from "../../common/SetupMasterEnum";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../utils/Config";
import { SETUP_MASTER_DETAIL_ALL_DROPDOWN } from "../../utils/UrlConstants";

const initialFormFields = {
  ProgramType: 0,
  AdmissionType: 0,
};
const EligibilityCriteria = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState(initialFormFields);
  const handleInputChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    getMasterDetailAllDropdown();
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "Departments", name: "Departments" },
    { field: "PreMedical", name: "Pre-Medical" },
    { field: "PreEngineering", name: "Pre-Engineering" },
    { field: "Commerce", name: "Commerce" },
    { field: "Arts", name: "Arts" },
    { field: "Dae", name: "DAE" },
  ];
  const rows = [
    {
      Departments: "Business Administration",
      PreMedical: "Eligible",
      PreEngineering: "Eligible",
      Commerce: "Eligible",
      Arts: "Eligible",
      Dae: "Eligible",
    },
    {
      Departments: "Commerce",
      PreMedical: "Eligible",
      PreEngineering: "Eligible",
      Commerce: "Eligible",
      Arts: "Eligible",
      Dae: "Eligible",
    },
    {
      Departments: "Computer Science",
      PreMedical: "Eligible",
      PreEngineering: "Eligible",
      Commerce: "Eligible",
      Arts: "Not-Eligible",
      Dae: "Eligible",
    },
    {
      Departments: "English",
      PreMedical: "Eligible",
      PreEngineering: "Eligible",
      Commerce: "Eligible",
      Arts: "Eligible",
      Dae: "Eligible",
    },
    {
      Departments: "Information Technology",
      PreMedical: "Eligible",
      PreEngineering: "Eligible",
      Commerce: "Eligible",
      Arts: "Not-Eligible",
      Dae: "Eligible",
    },
    {
      Departments: "Mathematics",
      PreMedical: "Not-Eligible",
      PreEngineering: "Eligible",
      Commerce: "Not-Eligible",
      Arts: "Not-Eligible",
      Dae: "Not-Eligible",
    },
    {
      Departments: "Teacher Education",
      PreMedical: "Eligible",
      PreEngineering: "Eligible",
      Commerce: "Eligible",
      Arts: "Eligible",
      Dae: "Eligible",
    },
  ];
  const columns2 = [
    { field: "Departments", name: "Departments/Institute" },
    { field: "Required", name: "Required" },
    { field: "Degree", name: "Degree" },
  ];
  const rows2 = [
    {
      Departments: "Archaeology",
      Required: "B.Sc (Pass Part-II)",
      Degree: "Archaeology with Anthropology/ Botany/ Biochemistry/ ",
    },
    {
      Departments: "Biochemistry",
      Required: "B.Sc (Pass Part-II)",
      Degree:
        "1- Biochemistry or Chemistry with Zoology or Botany. 2- Any one of the following subjects: i-Functional Math. ii-Pure Math. iii-Statistics. iv-Microbiology. v- Geography.",
    },
    {
      Departments: "Botany",
      Required: "B.Sc (Pass Part-II)",
      Degree:
        "Botany with  Zoology/Chemistry/ Functional Math/Biochemistry/ Microbiology",
    },
    {
      Departments: "Business Administration (1.5 year)",
      Required:
        "16 years Business education are eligible to apply for MBA 1.5years ",
      Degree: "",
    },
    {
      Departments: "Business Administration (3.5 year)",
      Required:
        "Two Years Degree in Science/Arts/Commerce/Business Admin. B.E/M.B.B.S/B. Pharmacy.",
      Degree: "",
    },
    {
      Departments: "Chemistry",
      Required: "B.Sc (Pass Part-II)",
      Degree: "Chemistry with Physics/Mathematics",
    },
    { Departments: "Commerce", Required: "B.Sc (Pass Part-II)", Degree: "" },
    {
      Departments: "Economics",
      Required: "Two Years Degree in Science/Arts/Commerce ",
      Degree: "",
    },
    {
      Departments: "English Language & Literature",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Gender Studies",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Geography",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Institute of Islamic Studies",
      Required: "Two Years Degree in Science/Arts/Commerce ",
      Degree: "",
    },
    {
      Departments: "International Relation",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Peace & Conflict Studies",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Mathematics",
      Required: "B.Sc (Pass Part-II)",
      Degree: "Pure Mathematics",
    },
    {
      Departments: "Media & Communication Studies",
      Required: "Two Years Degree in Science/Arts/Commerce ",
      Degree: "",
    },
    {
      Departments: "Microbiology",
      Required: "B.Sc/ (Part-II)",
      Degree:
        "Microbiology  with Zoology /Chemistry/ Botany/Functional Math/Biochemistry",
    },
    {
      Departments: "Physics & Electronics",
      Required: "B.Sc/ (Part-II)",
      Degree: "Physics with Pure Math/Functional Math/ Statistics/Chemistry",
    },
    {
      Departments: "Political Science",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Pakistan Studies",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Public Administration",
      Required: "Two Years Degree in Science/Arts/Commerce ",
      Degree: "",
    },
    {
      Departments: "Sindhi",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },

    {
      Departments: "Statistics",
      Required: "B.Sc (Pass Part-II), B.Com (Pass Part-II)",
      Degree:
        "1) Physics, Pure Math. 2) Physics, Pure Math with Statistics, 3) Functional Math with Statistics, Chemistry",
    },
    {
      Departments: "Special Education",
      Required: "Two Years Degree in Science/Arts/Commerce",
      Degree: "",
    },
    {
      Departments: "Urdu",
      Required: "Two Years Degree in Science/Arts/Commerce ",
      Degree: "",
    },
    {
      Departments: "Zoology",
      Required: "B.Sc (Pass Part-II) ",
      Degree:
        "Zoology with Chemistry/ Botany/Functional Math/Biochemistry/ Microbiology",
    },
  ];
  return (
    <Fragment>
      <Container fluid>
        <Card className="mt-3">
          <CardTitle>Eligibility Criteria - Program Wise</CardTitle>
          <CardBody>
            <Row>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Program"
                  name="ProgramType"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == admissionProgramId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields.ProgramType}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Admission Type"
                  name="AdmissionType"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == admissionTypeId && x.parentid == 4009
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields.AdmissionType}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardTitle>
            For {formFields.AdmissionType === 4013 ? "BS-III" : null}
            {formFields.AdmissionType === 4015 ? "MBA 1.5" : null}
            {formFields.AdmissionType === 4016 ? "MBA 3.5" : null}
            {formFields.AdmissionType === 4012 ? "BS-I" : null}
            {formFields.AdmissionType === 4014 ? "BBA-I" : null} Programs
          </CardTitle>
          <CardBody>
            <Row>
              <Col>
                {formFields.AdmissionType === 4012 || formFields.AdmissionType === 4014 ? (
                  <FormGroupTable
                    columns={columns}
                    rows={rows}
                    hideAction={true}
                    showEligiblityColor={true}
                  />
                ) : null}

                {formFields.AdmissionType === 4013 || formFields.AdmissionType === 4015 || formFields.AdmissionType === 4016  ? (
                  <FormGroupTable
                    columns={columns2}
                    rows={rows2}
                    hideAction={true}
                  />
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default EligibilityCriteria;
