import axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import { documentType, SessionStorage } from "../../../../common/SetupMasterEnum";
import { Admision_CreateAdmissionRecord } from "../../../../utils/Config";
import { decryptData } from "../../../../EncryptData";
import {
  ADMISSION_USER_ID,
  REG_ID,
} from "../../../../utils/EncryptedConstants";
import { SaveFile } from "../../../../utils/SaveFile";

const initialFormFields = {
  ADMDocId: 0,
  ADMRegID: 1,
  DocumentName: "",
  DocumentTypeID: 0,
  DocumentTypeName: "",
  DocumentPath: {},
  DocumentPathNew: "",
  UserIP: "192.168.168",
  FileName: "",
};

const Documents = (props) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const documentRecords = SupportingTables?.students?.Table5;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
  });
  const inputRef = useRef(null);
  const [documentData, setDocumentData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    });
  };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  const handleDocChange = (event) => {
    setFormFields({
      ...formFields,
      DocumentPathNew: URL.createObjectURL(event.target.files[0]),
      DocumentPath: event.target.files[0],
      FileName: event.target.files[0].name,
    });
  };
  useEffect(() => {
    setDocumentData(documentRecords);
  }, [documentRecords]);

  const onAdd = (e) => {
    e.preventDefault();
    documentData.push(formFields);
    setDocumentData([...documentData]);
    setFormFields({
      ...initialFormFields,
    });
  };
  const onDelete = (index) => {
    documentData.splice(index, 1);
    setDocumentData([...documentData]);
  };
  const onCancel = () => {
    setFormFields({
      ...initialFormFields,
    });
    inputRef.current.value = "";
    // inputRef.current.click();
    formFields.DocumentPath = null;
  };
  const onUpdate = (e) => {
    e.preventDefault();
    let formData = new FormData();
    Object.keys(basicValues).forEach((d) => {
      formData.append(d, basicValues[d]);
    });
    documentData.forEach((element, index) => {
      Object.keys(element).forEach((item) => {
        formData.append(`AdmissionDocument_[${index}].${item}`, element[item]);
      });
    });
    updateAdmission(formData);
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };
  return (
    <Fragment>
      {/* <Card className="mt-3">
        <CardTitle>Document List</CardTitle>
        <CardBody> */}
      <Row>
        <Col>
          <div className="table-responsive">
            <table className="table table-striped mb-0 mt-3">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Document Type</th>
                  <th className="text-center">Download</th>
                  <th className="text-center">View</th>
                </tr>
              </thead>
              <tbody>
                {documentData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.DocumentName}</td>
                      <td>{item.DocumentTypeName}</td>
                      <td className="text-center">
                        <i
                          onClick={() => {
                            SaveFile(item.DocumentPathNew, "Document_Image");
                          }}
                          className="fa fa-download"
                        ></i>
                      </td>
                      <td className="text-center">
                        <a
                          className="btn round-cubre btn-info"
                          href={item.DocumentPathNew}
                          // download
                          target="_blank"
                        >
                          <i className="fa fa-eye" color="primary"></i>
                        </a>
                      </td>
                      {/* <td className="text-center">
                            <Button
                              className="btn btnic"
                              color="danger"
                              type="button"
                              onClick={() => onDelete(index)}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      {/* <Row>
            <Col lg="12" md="12" sm="12" xs="12" className="text-right mt-2">
              <Button color="primary" className="btn" onClick={onUpdate}>
                {submitLoad ? <Spinner color="light" size="sm" /> : null}
                Upload
              </Button>
            </Col>
          </Row> */}
      {/* </CardBody> */}
      {/* </Card> */}
    </Fragment>
  );
};

export default Documents;
