import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Spinner,
} from "reactstrap";

import Academics from "./EmployeeTabs/Academics";
import BankDetails from "./EmployeeTabs/BankDetails";
import Documents from "./EmployeeTabs/Documents";
import PersonalInfo from "./EmployeeTabs/PersonalInfo";
import ProfessionalInfo from "./EmployeeTabs/ProfessionalInfo";
import Reference from "./EmployeeTabs/Reference";
import WorkExperience from "./EmployeeTabs/WorkExperience";

import EmpImg from "../../../assets/img/emp-img1.jpg";
import {
  Setup_GetEmployeeProfileById,
  Setup_MasterDetails_All_Dropdowns,
  Setup_UpdateEmployeeProfilePicture,
  Setup_UpdateProfileImage,
} from "../../../utils/Config";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { useDispatch } from "react-redux";
import {
  additionalChargesId,
  allowancesSetupMasterId,
  areaId,
  areaOfExpertiesSetupId,
  bankId,
  benefitsSetupId,
  businessUnit,
  cadreId,
  campusCity,
  campusType,
  cityId,
  correspondanceId,
  costCenter,
  countryId,
  degreeId,
  degreeStatusId,
  department,
  designation,
  documentType,
  employeeCategorySetupMasterId,
  employeeGradeId,
  employeeStatus,
  employmentStatus,
  employmentStatusId,
  employmentTypeId,
  experienceId,
  facultyTypeId,
  filerNonFilterId,
  gender,
  grade,
  idStatus,
  majorSubjectId,
  maritalStatus,
  nationalityId,
  payrollTypeId,
  professionId,
  provinceId,
  qualificationId,
  relationshipId,
  religionId,
  sectorId,
  Select,
  SessionStorage,
  setupMasterId,
  shiftTypeId,
  status,
  userTypeId,
} from "../../../common/SetupMasterEnum";
import Benefits from "./EmployeeTabs/Benefits";
import {
  formatDateFunc,
  formatDateFunction,
} from "../../../functions/DateFormatFunction";
import { BASE_URL_IMG } from "../../../utils/Api";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {};

const initialFormFields = {
  EmployeeId: 0,
  ImageFolderName: "",
  UploadAttachment: {},
};
const initialSelectionList = {
  MasterList: {},
  ProfileInfoList: {},
  EmergencyContactList: {},
  FamilyMemberList: [],
  // ProfessionalInfoList: {},
  AcademicList: [],
  ResearchList: [],
  WorkExperienceList: [],
  ReferenceList: [],
  DocumentList: [],
  BankDetailList: [],
  BenefitList: [],
};
const initialDropdownList = {
  ReligionList: [],
  GenderList: [],
  NationalityList: [],
  MaritalStatusList: [],
  CountryList: [],
  HighestDegreeList: [],
  SubjectList: [],
  AreaList: [],
  ExperienceList: [],
  QualificationList: [],
  RelationshipList: [],
  SectorList: [],
  ProvinceList: [],
  CampusTypeList: [],
  CampusCityList: [],
  BusinessUnitList: [],
  CostCenterList: [],
  DepartmentList: [],
  EmployeeCategoryList: [],
  EmployeeTypeList: [],
  CadreList: [],
  EmploymentTypeList: [],
  DesignationList: [],
  AdditionalChargesList: [],
  EmployeeGradeList: [],
  NumberOfLeavesList: [],
  EmploymentStatus: [],
  StatusList: [],
  IdStatusList: [],
  PayrollTypeList: [],
  ShiftTimingList: [],
  ReportingToList: [],
  DegreeStatusList: [],
  CityList: [],
  BankList: [],
  CorrespondanceList: [],
  FilerNonFilterList: [],
  ProfessionList: [],
  DocumentTypeList: [],
  FacultyTypeList: [],
  UserTypeList: [],
  BenefitsList: [],
};
const Profile = (props) => {
  const { id } = useParams();
  let emplLoginId = decryptData("EmplId", SessionStorage);
  let emplId = decryptData("EmplGetId", SessionStorage);
  const [activeTab, setActiveTab] = useState("1");
  const [selectionList, setSelectionList] = useState(initialSelectionList);
  const [dropdownList, setDropdownList] = useState(initialDropdownList);
  const [firstName, setFirstName] = useState();
  const [CNIC, setCNIC] = useState();
  const [formLoad, setFormLoad] = useState(false);
  const [resData, setResData] = useState([]);
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
    EmployeeId: id == 1 ? emplId : emplLoginId,
  });
  const [profileImage, setProfileImage] = useState();
  useEffect(() => {
    getEmployeeById();
    getMasterDetailAllDropdown();
  }, [id]);
  const dispatch = useDispatch();
  function getEmployeeById() {
    setFormLoad(true);
    Setup_GetEmployeeProfileById(id == 1 ? emplId : emplLoginId)
      .then((res) => {
        setFormLoad(true);

        if (res.data !== null) {
          setFormLoad(false);
          setFirstName(res?.data?.Table1[0]?.FirstName);
          setCNIC(res?.data?.Table1[0]?.CNIC);
          setSelectionList({
            ...selectionList,
            MasterList: res?.data?.Table[0],
            ProfileInfoList: {
              ...res?.data?.Table1[0],
              ...res?.data?.Table4[0],
            },
            EmergencyContactList: res?.data?.Table2[0],
            FamilyMemberList: res?.data?.Table3,
            // ProfessionalInfoList: res?.data?.Table4[0],
            AcademicList: res?.data?.Table5,
            ResearchList: res?.data?.Table6,
            WorkExperienceList: res?.data?.Table7,
            ReferenceList: res?.data?.Table8,
            DocumentList: res?.data?.Table9,
            BankDetailList: res?.data?.Table10,
            BenefitList: res?.data?.Table11,
          });
        } else {
          setFormLoad(false);
        }
        // dispatch({
        //   type: SET_INITIAL_CRUD_FORM_STATE,
        //   payload: {
        //     List: res.data.Table,
        //     FormFields: initialFormFields,
        //     SearchFields: initialSearchFields,
        //   },
        // });
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  }

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: Select,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        setResData(res.data);
        setDropdownList({
          ...dropdownList,
          ReligionList: res.data.filter(
            (item) => item.SetupMasterId == religionId
          ),
          GenderList: res.data.filter((item) => item.SetupMasterId == gender),
          NationalityList: res.data.filter(
            (item) => item.SetupMasterId == nationalityId
          ),
          MaritalStatusList: res.data.filter(
            (item) => item.SetupMasterId == maritalStatus
          ),
          CountryList: res.data.filter(
            (item) => item.SetupMasterId == countryId
          ),
          HighestDegreeList: res.data.filter(
            (item) => item.SetupMasterId == degreeId
          ),
          SubjectList: res.data.filter(
            (item) => item.SetupMasterId == majorSubjectId
          ),
          AreaList: res.data.filter(
            (item) => item.SetupMasterId == areaOfExpertiesSetupId
          ),
          ExperienceList: res.data.filter(
            (item) => item.SetupMasterId == experienceId
          ),
          QualificationList: res.data.filter(
            (item) => item.SetupMasterId == qualificationId
          ),
          RelationshipList: res.data.filter(
            (item) => item.SetupMasterId == relationshipId
          ),
          SectorList: res.data.filter((item) => item.SetupMasterId == sectorId),
          ProvinceList: res.data.filter(
            (item) => item.SetupMasterId == provinceId
          ),
          CampusTypeList: res.data.filter(
            (item) => item.SetupMasterId == campusType
          ),
          CampusCityList: res.data.filter(
            (item) => item.SetupMasterId == campusCity
          ),
          BusinessUnitList: res.data.filter(
            (item) => item.SetupMasterId == businessUnit
          ),
          CostCenterList: res.data.filter(
            (item) => item.SetupMasterId == costCenter
          ),
          DepartmentList: res.data.filter(
            (item) => item.SetupMasterId == department
          ),
          EmployeeCategoryList: res.data.filter(
            (item) => item.SetupMasterId == employeeCategorySetupMasterId
          ),
          EmployeeTypeList: res.data.filter(
            (item) => item.SetupMasterId == employeeCategorySetupMasterId
          ),
          CadreList: res.data.filter((item) => item.SetupMasterId == cadreId),
          //
          EmploymentTypeList: res.data.filter(
            (item) => item.SetupMasterId == employmentTypeId
          ),
          DesignationList: res.data.filter(
            (item) => item.SetupMasterId == designation
          ),
          AdditionalChargesList: res.data.filter(
            (item) => item.SetupMasterId == additionalChargesId
          ),
          EmployeeGradeList: res.data.filter(
            (item) => item.SetupMasterId == employeeGradeId
          ),
          //
          NumberOfLeavesList: res.data.filter(
            (item) => item.SetupMasterId == employeeGradeId
          ),
          EmploymentStatus: res.data.filter(
            (item) => item.SetupMasterId == employmentStatusId
          ),
          StatusList: res.data.filter((item) => item.SetupMasterId == status),
          //
          IdStatusList: res.data.filter(
            (item) => item.SetupMasterId == idStatus
          ),
          PayrollTypeList: res.data.filter(
            (item) => item.SetupMasterId == payrollTypeId
          ),
          ShiftTimingList: res.data.filter(
            (item) => item.SetupMasterId == shiftTypeId
          ),
          ReportingToList: res.data.filter(
            (item) => item.SetupMasterId == shiftTypeId
          ),
          DegreeStatusList: res.data.filter(
            (item) => item.SetupMasterId == degreeStatusId
          ),
          CityList: res.data.filter((item) => item.SetupMasterId == cityId),
          BankList: res.data.filter((item) => item.SetupMasterId == bankId),
          CorrespondanceList: res.data.filter(
            (item) => item.SetupMasterId == correspondanceId
          ),
          FilerNonFilterList: res.data.filter(
            (item) => item.SetupMasterId == filerNonFilterId
          ),
          ProfessionList: res.data.filter(
            (item) => item.SetupMasterId == professionId
          ),
          DocumentTypeList: res.data.filter(
            (item) => item.SetupMasterId == documentType
          ),
          FacultyTypeList: res.data.filter(
            (item) => item.SetupMasterId == facultyTypeId
          ),
          UserTypeList: res.data.filter(
            (item) => item.SetupMasterId == userTypeId
          ),
          BenefitsList: res.data.filter(
            (item) => item.SetupMasterId == benefitsSetupId
          ),
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleImageUpload = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
    // setUploadAtach(event.target.files[0]);
    setFormFields({
      ...formFields,
      // ImagePath: URL.createObjectURL(event.target.files[0]),
      ImageFolderName: selectionList?.MasterList?.ImageFolderName,
      UploadAttachment: event.target.files[0],
    });
  };
  const onUpdate = async (e) => {
    // if (documentData.length == 0) {
    //   Swal.fire({
    //     title: "Error",
    //     icon: "error",
    //     text: "Please Add Document Information",
    //   });
    // } else {
    e.preventDefault();
    let formData = new FormData();
    // formData.append("EmployeeId", formFields.EmployeeId);
    // formData.append("Foldername", formFields.Foldername);
    // formData.append("UploadAttachment", formFields.UploadAttachment);
    Object.keys(formFields).forEach((d) => {
      formData.append(`EmployeeProfile_.${d}`, formFields[d]);
    });
    updateEmployee(formData);
    // }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    // setSubmitLoad(true);
    Setup_UpdateEmployeeProfilePicture(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              getEmployeeById();
            } else if (results.isConfirmed) {
              getEmployeeById();
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        // setSubmitLoad(false);
        console.error(err);
      });
  };
  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <Card className="mt-3">
            <CardBody>
              <Row>
                {formLoad ? (
                  <div className="form-load">
                    <Spinner color="light" size="md" /> 
                  </div>
                ) : null}
                <Col lg="3" md="3" sm="3" xs="12" className="text-center">
                  <div className="profile-info text-center mb-3">
                    <div className="profile-img-wrap">
                      <img
                        src={
                          profileImage !== undefined
                            ? profileImage
                            : selectionList?.MasterList?.ImagePath_new
                        }
                        className="rounded-circle"
                        width={120}
                        height={120}
                        alt="Employee"
                        name="image"
                      />
                      <div className="fileupload">
                        <p className="btn-text-edit">
                          <i className="fa fa-pencil"></i>
                        </p>
                        <Input
                          className="upload"
                          type="file"
                          accept="image/*"
                          color="default"
                          name="UploadAttachment"
                          onChange={handleImageUpload}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-1">
                    {/* <div className="form-group">
                  <Input
                    type="file"
                    name="UploadAttachment"
                    onChange={handleImageUpload}
                    required
                  />
                </div> */}
                    <Button color="primary" className="mt-0" onClick={onUpdate}>
                      Upload
                    </Button>
                  </div>
                </Col>
                
                <Col lg="9" md="3" sm="9" xs="12">
                  <Row>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Employee Code</strong>
                      </Label>
                      <span className="badge badge-info">
                        {selectionList?.MasterList?.EmployeeCode}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Employee Name</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.EmployeeName}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Gender</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.Gender}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>CNIC</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.CNIC}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Date of Birth</strong>
                      </Label>
                      <span className="badge">
                        {formatDateFunc(
                          selectionList?.MasterList?.DateOfBirth,
                          "-"
                        )}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Date of Joining</strong>
                      </Label>
                      <span className="badge">
                        {formatDateFunc(
                          selectionList?.MasterList?.JoiningDate,
                          "-"
                        )}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Official Email</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.OfficialEmail}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Grade</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.Grade}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Department</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.CurrentDepartment}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Designation</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.HiringDesignation}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>Reports To</strong>
                      </Label>
                      <span className="badge">
                        {selectionList?.MasterList?.ReportTo}
                      </span>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>User Status</strong>
                      </Label>
                      <span className="badge badge-success">
                        {selectionList?.MasterList?.Status}
                      </span>
                    </Col>
                    {/* <Col lg="4" md="4" sm="4" xs="12">
                      <Label className="col-12 mb-0">
                        <strong>User Status</strong>
                      </Label>
                      <span className="badge badge-success">
                        {selectionList?.MasterList?.Status}
                      </span>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Nav pills>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    Personal Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    Professional Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" ? "active" : ""}
                    onClick={() => setActiveTab("3")}
                  >
                    Academics
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "4" ? "active" : ""}
                    onClick={() => setActiveTab("4")}
                  >
                    Work Experience
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "5" ? "active" : ""}
                    onClick={() => setActiveTab("5")}
                  >
                    References
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "6" ? "active" : ""}
                    onClick={() => setActiveTab("6")}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "7" ? "active" : ""}
                    onClick={() => setActiveTab("7")}
                  >
                    Bank Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "8" ? "active" : ""}
                    onClick={() => setActiveTab("8")}
                  >
                    Benefits
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent className="tab-content-inner" activeTab={activeTab}>
                <TabPane tabId="1">
                  <PersonalInfo
                    {...props}
                    data={selectionList.ProfileInfoList}
                    emergencyData={selectionList.EmergencyContactList}
                    familyData={selectionList.FamilyMemberList}
                    list={dropdownList}
                    formLoad={formLoad}
                    resData={resData}
                    // setSelectionList={setSelectionList}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <ProfessionalInfo
                    {...props}
                    data={selectionList.ProfileInfoList}
                    list={dropdownList}
                    formLoad={formLoad}
                    resData={resData}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <Academics
                    {...props}
                    data={selectionList.AcademicList}
                    researchData={selectionList.ResearchList}
                    list={dropdownList}
                    firstName={firstName}
                    CNIC={CNIC}
                    formLoad={formLoad}
                  />
                </TabPane>
                <TabPane tabId="4">
                  <WorkExperience
                    {...props}
                    data={selectionList.WorkExperienceList}
                    list={dropdownList}
                    firstName={firstName}
                    CNIC={CNIC}
                    formLoad={formLoad}
                  />
                </TabPane>
                <TabPane tabId="5">
                  <Reference
                    {...props}
                    data={selectionList.ReferenceList}
                    list={dropdownList}
                    firstName={firstName}
                    CNIC={CNIC}
                    formLoad={formLoad}
                  />
                </TabPane>
                <TabPane tabId="6">
                  <Documents
                    {...props}
                    data={selectionList.DocumentList}
                    list={dropdownList}
                    firstName={firstName}
                    CNIC={CNIC}
                    formLoad={formLoad}
                  />
                </TabPane>
                <TabPane tabId="7">
                  <BankDetails
                    {...props}
                    data={selectionList.BankDetailList}
                    list={dropdownList}
                    firstName={firstName}
                    CNIC={CNIC}
                    formLoad={formLoad}
                  />
                </TabPane>
                <TabPane tabId="8">
                  <Benefits
                    {...props}
                    data={selectionList.BenefitList}
                    list={dropdownList}
                    firstName={firstName}
                    CNIC={CNIC}
                    formLoad={formLoad}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
