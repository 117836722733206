import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FormGroupButton from "../../../components/GeneralComponent/FormGroupButton";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";

import propTypes from "prop-types";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/CrudActionTypes";
import { useEffect } from "react";
import useFade from "../../../Hooks/useFade";

const CrudFormComponent = (props) => {
  const {
    searchPanel,
    formPanel,
    tableColumns,
    formName,
    newButton = true,
    buttonName,
    updateButtonName,
    updateControl,
    searchSubmit,
    cancelSearch,
    formSubmit,
    tableRows,
    onEdit,
    onEditCustom,
    onDelete,
    onConfirm,
    onRefuse,
    onRevert,
    onRejoin,
    onActive,
    onGenerateVoucher,
    featureList = [],
    handleCancel,
    customTable,
    hideAction,
    customModal,
    customModalSecond,
    hideSubmitButton,
    customButton,
    modalSize,
    showSaveButton,
    buttonWithoutList,
    buttonWithoutListDescription,
    modalStyle,
    customPagination,
    hideSearchPanel,
    processButtonName,
  } = props;

  const dispatch = useDispatch();
  const { TableList, ModalToggle } = useSelector(
    (state) => state.CrudFormReducer
  );
  const [toggleForm, setToggleForm] = useState(false);
  const [operationId, setOperationId] = useState(2);
  const [showSupply, setShowSupply, fadeOutProps] = useFade(false, 700);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchSubmit();
  };

  const handleUpdate = () => {
    updateControl();
  };

  const handleSearchCancel = (e) => {
    e.preventDefault();
    cancelSearch && cancelSearch();
  };

  const handleFormSubmit = (e) => {
    // e.preventDefault();
    // setOperationId(2);
    // formSubmit(operationId);
    // dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
    // setToggleForm(false);
    e.preventDefault();
    setOperationId(2);
    formSubmit(operationId);
    if (ModalToggle) {
    } else {
      setToggleForm(false);
    }
  };

  const handleFormCancel = () => {
    setToggleForm(false);
    setOperationId(2);
    // dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
    handleCancel && handleCancel();
  };

  const onEditRow = (index, obj) => {
    onEdit(obj, index);
    setOperationId(3);
    setToggleForm(true);
  };

  const onDeleteRow = (index, obj) => {
    onDelete(obj, index);
  };

  const onConfirmRow = (index) => {
    let obj = TableList[index];
    onConfirm(obj);
  };
  const onEditCustomRow = (index) => {
    let obj = TableList[index];
    onEditCustom(obj);
    setToggleForm(true);
  };

  const onGenerateVoucherRow = (index) => {
    let obj = TableList[index];
    onGenerateVoucher(obj);
  };

  const onRefuseRow = (index) => {
    let obj = TableList[index];
    onRefuse(obj);
  };

  const onRevertRow = (index) => {
    let obj = TableList[index];
    onRevert(obj);
  };

  const onReJoinRow = (index) => {
    let obj = TableList[index];
    onRejoin(obj);
  };
  const onActiveRow = (index) => {
    let obj = TableList[index];
    onActive(obj);
  };

  useEffect(() => {
    setShowSupply(true);
  }, []);

  return (
    <Fragment>
      {showSupply && (
        <Container {...fadeOutProps} fluid>
          {featureList.some((x) => x.FeatureId === 6) ? (
            <Fragment>
              {!hideSearchPanel && (
                <Card className="mt-3">
                  <CardTitle>Search {formName}</CardTitle>
                  <CardBody>
                    <form onSubmit={handleSearchSubmit}>
                      {searchPanel && (
                        <Row>
                          {searchPanel}
                          <Col lg="12" md="12" xs="12" className="text-right">
                            <Button
                              color="primary"
                              className="btn"
                              type="submit"
                            >
                              Search
                            </Button>
                            {updateButtonName && (
                              <Button
                                color="primary"
                                className="btn"
                                onClick={handleUpdate}
                              >
                                {updateButtonName}
                              </Button>
                            )}
                            <Button
                              className="btn"
                              color="default"
                              onClick={handleSearchCancel}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </form>
                  </CardBody>
                </Card>
              )}
            </Fragment>
          ) : null}
          {tableColumns && (
            <Card>
              <CardTitle>
                <Row>
                  {featureList.some((x) => x.FeatureId === 4) ? (
                    <Col lg="6" md="3" xs="12">
                      {formName} List
                    </Col>
                  ) : (
                    ""
                  )}
                  {!hideSubmitButton && (
                    <Col lg="6" md="3" xs="12" className="text-right">
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {customButton && customButton}
                        {
                          buttonName && newButton && (
                            featureList.some((x) => x.FeatureId === 1) && (
                            <FormGroupButton
                              title={buttonName}
                              onClick={() => setToggleForm(true)}
                              id="add-btn"
                              //   isOpen={tooltip}
                              //   toggle={() => setToolTip(!tooltip)}
                              showToolTip={false}
                              toolTipTitle="Add"
                              showIcon={true}
                            ></FormGroupButton>
                          )
                          )
                        }
                      </div>
                    </Col>
                  )}
                </Row>
              </CardTitle>

              <CardBody {...fadeOutProps}>
                {featureList.some((x) => x.FeatureId === 4) ? (
                  <Row>
                    <Col>
                      {!customTable && tableColumns && (
                        <Fragment>
                          <FormGroupTable
                            columns={tableColumns && tableColumns}
                            rows={tableRows && tableRows}
                            onRejoin={onRejoin && onReJoinRow}
                            onActive={onActive && onActiveRow}
                            onEdit={
                              onEdit &&
                              featureList.some((x) => x.FeatureId === 2)
                                ? onEditRow
                                : null
                            }
                            onEditCustom={
                              onEditCustom &&
                              featureList.some((x) => x.FeatureId === 2)
                                ? onEditCustomRow
                                : null
                            }
                            onRevert={onRevert && onRevertRow}
                            onDelete={
                              onDelete &&
                              featureList.some((x) => x.FeatureId === 3)
                                ? onDeleteRow
                                : null
                            }
                            onConfirm={onConfirm && onConfirmRow}
                            onRefuse={onRefuse && onRefuseRow}
                            onGenerateVoucher={
                              onGenerateVoucher && onGenerateVoucherRow
                            }
                            hideAction={hideAction}
                          />
                          {customPagination && customPagination}
                        </Fragment>
                      )}
                      {customTable && customTable}
                    </Col>
                  </Row>
                ) : (
                  <Col lg="6" md="3" xs="12">
                    No Data Found
                  </Col>
                )}
              </CardBody>
            </Card>
          )}

          {buttonWithoutList && (
            <Card>
              <CardTitle>
                <Row>
                  <Col lg="6" md="3" xs="12">
                    {buttonWithoutListDescription} List
                  </Col>
                  <Col lg="6" md="3" xs="12" className="text-right">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      {customButton && customButton}
                      {buttonName && newButton && (
                        // featureList.some((x) => x.FeatureId === 1) && (
                        <FormGroupButton
                          title={buttonName}
                          onClick={() => setToggleForm(true)}
                          id="add-btn"
                          //   isOpen={tooltip}
                          //   toggle={() => setToolTip(!tooltip)}
                          showToolTip={false}
                          toolTipTitle="Add"
                          showIcon={true}
                        ></FormGroupButton>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardTitle>
            </Card>
          )}

          {customModal && customModal}
          {customModalSecond && customModalSecond}
          {buttonName && (
            <Modal
              isOpen={toggleForm}
              centered
              size={modalSize}
              modalTransition={{ timeout: 10 }}
              style={modalStyle}
            >
              <ModalHeader>Add/Edit {formName}</ModalHeader>
              <ModalBody>
                <form onSubmit={handleFormSubmit}>
                  {formPanel && (
                    <Row>
                      {formPanel}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {showSaveButton === false ? null : (
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        )}
                        {processButtonName && (
                          <Button color="primary" type="submit">
                            {processButtonName}
                          </Button>
                        )}

                        <Button color="default" onClick={handleFormCancel}>
                          Cancel
                        </Button>
                      </div>
                    </Row>
                  )}
                </form>
              </ModalBody>
            </Modal>
          )}
        </Container>
      )}
    </Fragment>
  );
};

CrudFormComponent.propTypes = {
  searchPanel: propTypes.element,
  formPanel: propTypes.element,
  tableColumns: propTypes.array,
  formName: propTypes.string,
  newButton: propTypes.bool,
  buttonName: propTypes.string,
  updateButtonName: propTypes.string,
  updateControl: propTypes.func,
  searchSubmit: propTypes.func,
  formSubmit: propTypes.func,
  tableRows: propTypes.array,
  onEdit: propTypes.func,
  onDelete: propTypes.func,
  onConfirm: propTypes.func,
  onRefuse: propTypes.func,
  onRevert: propTypes.func,
  onRejoin: propTypes.func,
  onActive: propTypes.func,
  onGenerateVoucher: propTypes.func,
  initialSearchFields: propTypes.object,
  initialFormFields: propTypes.object,
  featureList: propTypes.array,
  handleCancel: propTypes.func,
  cancelSearch: propTypes.func,
  customTable: propTypes.element,
  hideAction: propTypes.bool,
  customModal: propTypes.element,
  customModalSecond: propTypes.element,
  hideSubmitButton: propTypes.bool,
  customButton: propTypes.element,
  modalSize: propTypes.string,
  showSaveButton: propTypes.bool,
  buttonWithoutList: propTypes.bool,
  buttonWithoutListDescription: propTypes.string,
  customPagination: propTypes.element,
  hideSearchPanel: propTypes.bool,
  processButtonName: propTypes.string,
  modalStyle: propTypes.string,
};

export default CrudFormComponent;
