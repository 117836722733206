import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import { Fragment } from "react";
import { Label } from "reactstrap";
import { components } from "react-select";

export const Options = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props?.isSelected}
          onChange={() => null}
          style={{ marginRight: "5px" }}
        />
        <label>{props?.label}</label>
      </components.Option>
    </div>
  );
};

export const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props?.data?.label}</span>
  </components.MultiValue>
);
const FormMultiSelect = (props) => {
  if (props?.allowSelectAll) {
    return (
      <Fragment>
        <Label>
          {props?.label}
          <span className="text-danger">*</span>
        </Label>
        <ReactSelect
          {...props}
          isDisabled={props?.options?.length > 0 ? false : true}
          options={[props?.allOption,
            ...props?.options,
          ]}
          onChange={(selected) => {
            if (
              selected !== null &&
              selected?.length > 0 &&
              selected[selected?.length - 1]?.value === props?.allOption?.value
            ) {
              return props.onChange(props?.options);
            }
            return props.onChange(selected);
          }}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Label>
        {props?.label}
        <span className="text-danger">*</span>
      </Label>
      <ReactSelect {...props} />
    </Fragment>
  );
};

FormMultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

FormMultiSelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default FormMultiSelect;
