import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Table } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_FORM_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import Spinner from "react-spinkit";
import { useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { PostRequest } from "../../../utils/Config";
import { ACAD_EXAM_CONTROLLER_REPORTS } from "../../../utils/UrlConstants";
import {
  academicYearId,
  partYearID,
  Search,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import { decryptData } from "../../../EncryptData";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import PassCertificateReport from "../Reports/ControllerExamination/PassCertificateReport";
import { CustomErrorMessage } from "../../../components/Alert";

const PassCertificate = () => {
  const initialSearchFields = {
    OperationID: 2,
    AcademicYearID: 0,
    SemesterID: 0,
    PartID: 0,
    FacultyDepartmentProgramID: 0,
    StudentDetailID: 0,
    FacultyDeptId:0,
    Cnic: "",
    RegNo: "",
    Name: "",
    UserID: decryptData("loginId", SessionStorage), 
  };

  const initialFormFields = {
    OperationID: 2,
    AcademicYearID: 0,
    SemesterID: 0,
    PartID: 0,
    FacultyDepartmentProgramID: 0,
    StudentDetailID: 0,
    FacultyDeptId:0,
    Cnic: "",
    RegNo: "",
    Name: "",
    UserID: decryptData("loginId", SessionStorage),
  };

  const { SearchFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const {
    SupportingTables: { PassCertificateStudent },
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);
  const [voucherId, setVoucherId] = useState(null);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const ref = useRef();

  const [formLoad, setFormLoad] = useState(true);

  useEffect(() => {
    getPassCertificate();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const handlePassCertificateReport = useReactToPrint({
    content: () => ref.current,
  });

  // function getDegreeProgramLedger() {
  //   PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, initialSearchFields)
  //     .then((res) => {
  //       dispatch({
  //         type: SET_INITIAL_CRUD_FORM_STATE,
  //         payload: {
  //           List: res?.data?.Table,
  //           SearchFields: SearchFields,
  //         },
  //       });
  //       timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }

  function getPassCertificate() {
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, initialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
        />
      </Col>
      {/* <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="partID"
          onChange={handleSearchChange}
          value={SearchFields?.partID}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col> */}
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDeptId"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDeptId}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyDepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.FacultyDeptId == null}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Name"
          name="Name"
          onChange={handleSearchChange}
          value={SearchFields?.Name}
          isAlphabetic="true"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Cnic"
          name="Cnic"
          onChange={handleSearchChange}
          value={SearchFields?.Cnic}
          isNumber="true"
          minLength="13"
          maxLength="13"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Registration No"
          name="RegNo"
          onChange={handleSearchChange}
          value={SearchFields?.RegNo}
        />
      </Col>
    </Fragment>
  );

  const columns = [
    { field: "Sno", name: "SNo" },
    { field: "AcademicYear", name: "AcademicYear" },
    { field: "Name", name: "Name" },
    { field: "FatherName", name: "Father Name" },
    { field: "Cnic", name: "Cnic" },
    { field: "Reg_No", name: "Registartion Number" },
    {
      field: "AdmissionFacultyDepartment",
      name: "Admission Faculty Department",
    },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
  ];

  const viewPassCertificate = (obj) => {
    const payload = {
            ...initialFormFields,
      OperationID: 2,
      StudentDetailID: obj.StudentDetailID,
      AcademicYearId: obj.AcademicYearId,
      FacultyDepartmentProgramId: obj.FacultyDepartmentProgramId,
    };
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, payload)
      .then((res) => {
        if (res?.data?.Table1?.[0]?.HasError === 0) {
          CustomErrorMessage(res?.data?.Table1?.[0]?.MESSAGE);
        } else {
          setVoucherId(res?.data?.Table1);
          let data = {
            name: "PassCertificateStudent",
            value: res?.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          timeoutRef.current = setTimeout(
            () => handlePassCertificateReport(),
            200
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const customButton = (
  //   <Fragment>
  //     <Button color="primary" onClick={handlePrintReport}>
  //       Print Degree Program Ledger Report
  //     </Button>
  //     <div style={{ display: "none" }}>
  //       <DegreeProgramLedgerReport ref={printDPLedgerReport} />
  //     </div>
  //   </Fragment>
  // );

  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      OperationID: 2,
      FacultyDeptId: 0,
    };
    PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getPassCertificate();
  };

  const customTable = (
    <Fragment>
      {TableList.length > 0 ? (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr className="text-center">
              {/* <th>Preview</th> */}
              {columns &&
                columns.map((column, index) => (
                  <th key={index}>{column.name}</th>
                ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {TableList?.map((item, ind) => {
              return (
                <tr className="text-center" key={ind}>
                  {/* <td className="text-center">
                <a
                  //   href={item.DocumentPath}
                  target="_blank"
                  className="btn-eye btnic btn-secondary"
                >
                  <i className="fa fa-eye"></i>
                </a>
              </td> */}
                  <td>
                    {" "}
                    <td>{ind + 1}</td>
                  </td>
                  <td>{item.AcademicYear}</td>
                  <td>{item.Name}</td>
                  <td>{item.FatherName}</td>
                  <td>{item.Cnic}</td>
                  <td>{item.Reg_No}</td>
                  <td>{item.FacultyDepartmentProgram}</td>
                  <td>{item.AdmissionFacultyDepartment}</td>
                  <td className="text-center">
                    <Button
                      color="success"
                      className="btnic"
                      size="sm"
                      onClick={() => viewPassCertificate(item, ind)}
                    >
                      <i className="fa fa-file"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        "No Data Found"
      )}

      <div style={{ display: "none" }}>
        <PassCertificateReport ref={ref} StudentDetailID={voucherId} />
      </div>
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Pass Certificate"
      hideAction={true}
      tableColumns={columns}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
    />
  );
};

export default PassCertificate;
