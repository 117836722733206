import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container, Nav } from "reactstrap";
import Logo from "../assets/img/logo.png";
import Profile from "../assets/img/noprofilepic.png";
import { isLogin, logout } from "../utils";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";
import { fetchToggle } from "../redux/actions/toggleAction";
import { decryptData } from "../EncryptData";
import { LOGIN_TYPE, TYPE } from "../utils/EncryptedConstants";
import { SessionStorage } from "../common/SetupMasterEnum";
import LogoutModal from "./LogoutModal";
import { useRef } from "react";

const Header = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [isToggleOn, isToggleOf] = useState(true);
  const [isActive, setActive] = useState(false);
  const [idleModal, setIdleModal] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  let idleTimeout = 1000 * 60 * 10;
  let idleLogout = 1000 * 60 * 10;
  const idleEvent = useRef(null);
  let idleLogoutEvent;

  /**
   * Add any other events listeners here
   */
  const events = ["mousemove", "click", "keypress"];
  const sessionTimeout = () => {
    if (!!idleEvent.current) clearTimeout(idleEvent.current);
    if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);
    idleEvent.current = setTimeout(() => {
      setIdleModal(true);
    }, idleTimeout);
    idleLogoutEvent = setTimeout(() => logOut, idleLogout);
  };

  const isToggle = (e) => {
    e.preventDefault();
    isToggleOf(!isToggleOn);
    dispatch(fetchToggle(isToggleOn));
  };

  const toggleMenu = (e) => {
    e.preventDefault();
    setActive(!isActive);
  };
  function logOut(e) {
    e.preventDefault();
    let loginType = decryptData(LOGIN_TYPE, SessionStorage);
    if (loginType === "1") {
      logout();
      history.push("/login");
    } else if (loginType === "2") {
      logout();
      history.push("/admissionlogin");
    }
  }
  const changeState = () => {
    setActive(false);
  };

  useEffect(() => {
    const loginId = sessionStorage.getItem("loginId");
    if (!loginId) {
      logOut();
    }
    return () => {};
  }, []);
  useEffect(() => {
    window
      .matchMedia("(max-width: 800px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    if (matches) {
      isToggleOf(false);
      dispatch(fetchToggle(true));
    } else {
      isToggleOf(true);
      dispatch(fetchToggle(false));
    }
  }, [matches]);

  useEffect(() => {
    for (let e in events) {
      window.addEventListener(events[e], sessionTimeout);
    }
    return () => {
      for (let e in events) {
        window.removeEventListener(events[e], sessionTimeout);
      }
    };
  }, []);

  function SignOut(e) {
    e.preventDefault();

    let loginType = decryptData(LOGIN_TYPE, SessionStorage);
    if (loginType === "1") {
      logout();
      history.push("/login");
    } else if (loginType === "2") {
      logout();
      history.push("/admissionlogin");
    }
  }
  return (
    <>
      <div className="mainHeader">
        <Nav className="navbar navbar-inverse navbar-fixed-top ">
          <Container fluid className="nav-wrap p-0">
            <div className="mobile-only-brand pull-left">
              <div className="nav-header pull-left">
                <div className="logo-wrap">
                  {decryptData(LOGIN_TYPE, SessionStorage) == "1" ? (
                    <Link to="/pages/dashboard">
                      <img className="logo-img" src={Logo} alt="Logo" />
                    </Link>
                  ) : decryptData(TYPE, SessionStorage) != 1 ? (
                    <Link to="/pages/admission/dashboard">
                      <img className="logo-img" src={Logo} alt="Logo" />
                    </Link>
                  ) : (
                    <Link to="/pages/admission/instruction">
                      <img className="logo-img" src={Logo} alt="Logo" />
                    </Link>
                  )}
                </div>
              </div>
              <a
                href="#"
                id="toggle_nav_btn"
                onClick={isToggle}
                className="toggle-left-nav-btn inline-block ml-20 pull-left"
              >
                <i className="ti ti-align-left"></i>
              </a>
            </div>
            <div id="mobile_only_nav" className="mobile-only-nav pull-right">
              <ul className="nav navbar-right top-nav pull-right">
                <li className="dropdown auth-drp">
                  <a
                    href="#"
                    className=" pr-0 profile-logout"
                    data-toggle="dropdown"
                    onClick={toggleMenu}
                  >
                    <span className="user-auth-name inline-block">
                      {/* <span id="lblLoginStatus">HRMS</span> */}
                      <span className="ti ti-angle-down"></span>
                    </span>
                    <img
                      src={Profile}
                      alt="user_auth"
                      className="user-auth-img img-circle"
                    />
                    <span className="user-online-status"></span>
                  </a>
                  {isActive ? (
                    <ul className="dropdown-menu user-auth-dropdown show">
                      <li>
                        <Link to="/pages/changepassword" onClick={changeState}>
                          <i className="ti ti-pencil-alt text-warning"></i>
                          Change Password
                        </Link>
                      </li>
                      <li className="divider"></li>
                      <li>
                        <a href="#" onClick={(e) => logOut(e)}>
                          <i className="ti ti-power-off text-danger"></i>
                          <span>Log Out</span>
                        </a>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </Container>
        </Nav>
      </div>
      <Sidebar isToggle={isToggleOn} />
      {isLogin() && <LogoutModal idleModal={idleModal} signOut={SignOut} />}
    </>
  );
};

export default Header;
