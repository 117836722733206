import React, { Fragment, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table
} from "reactstrap";
import {
  admissionFacultyTypeId,
  admissionProgramId,
  admissionTypeId,
  campusCity,
  campusType,
  examinationId,
  examTypeID,
  facultyDepartmentId,
  facultyTypeId,
  Insert,
  programTypes,
  SessionStorage
} from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../EncryptData";
import {
  RESET_FORM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE
} from "../../redux/actionType/CrudActionTypes";
import {
  Admission_OMR_Record,
  Admission_SamplePaper,
  Setup_Admission_Assign_Block,
  Setup_MasterDetails_All_Dropdowns
} from "../../utils/Config";
import { TYPE } from "../../utils/EncryptedConstants";
import { useReactToPrint } from "react-to-print";
import TestSlip from "./TestSlip";

const AssignBlock = () => {
  const componentReference = useRef();
  const initialSearchFields = {
    OperationId: 1,
    ExamId: 0,
    UserId: decryptData("loginId", SessionStorage)
  };

  const { SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    getMasterDetailAllDropdown();
    assignBlockCrud(initialSearchFields);
  }, []);

  const [searchFields, setSearchFields] = useState({
    ...initialSearchFields,
    OperationId: 2
  });

  const columns = [
    { field: "ADMRegid", name: "Reg ID" },
    { field: "BlockName", name: "Block Name" },
    { field: "FacultyDepartment", name: "Faculty Department" },
    { field: "TestID", name: "Test ID" },
    {
      field: "TestScore",
      name: "Test Score Faculty Department"
    }
  ];
  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // function admissionORMRecords() {
  //   const payload = {
  //     OperationId: 1,
  //     CampusId: 0,
  //     CampusCityId: 0,
  //     ProgramId: 0,
  //     FacultyDepartmentId: 0,
  //     AdmissionTypeId: 0,
  //     ProgramTypeId: 0,
  //     AdmissionFacultyTypeId: 0,
  //     SeatTypeId: 0,
  //     QuotaTypeId: 0,
  //     FileName: "ss",
  //     FilePath: "ss",
  //     FileNameTemp: "",
  //   };
  //   let formData = new FormData();
  //   Object.keys(payload).forEach((d) => {
  //     formData.append(`${d}`, payload[d]);
  //   });
  //   Admission_OMR_Record(formData)
  //     .then((res) => {
  //       let data = {
  //         name: "assignblocks",
  //         value: res?.data?.Table,
  //       };
  //       dispatch({
  //         type: SET_INITIAL_DROPDOWN_FORM_STATE,
  //         payload: data,
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }

  const handleInputChange = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value
    });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setSearchFields({
      ...searchFields,
      OperationId: Insert
    });
    assignBlockCrud(searchFields);
  };

  const onPrint = useReactToPrint({
    content: () => componentReference.current
  });

  function assignBlockCrud(payload) {
    Setup_Admission_Assign_Block(payload)
      .then((res) => {
        if (payload.OperationId == 2) {
          if (res.data.Table[0].HasError === 0) {
            dispatch({
              type: SET_INITIAL_CRUD_FORM_STATE,
              payload: {
                List: res?.data?.Table,
                SearchFields: initialSearchFields
              }
            });
            CustomSuccessAlert(res.data.Table[0].Message);
            setSearchFields({
              ...initialSearchFields
            });
            assignBlockCrud(initialSearchFields);
          } else {
            CustomErrorMessage(res.data.Table[0].Message);
          }
        } else {
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: res?.data?.Table,
              SearchFields: initialSearchFields
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>Assign Block</CardTitle>
        <CardBody>
          <form onSubmit={onSearch}>
            <Row style={{ alignItems: "flex-end" }}>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Exam Type"
                  name="ExamId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == examTypeID
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.ExamId}
                  onChange={handleInputChange}
                  required
                  // required={decryptData(TYPE) == 1 ? false : true}
                />
              </Col>
              {/* {decryptData(TYPE, SessionStorage) == 0 ? ( */}
                <Col md="3" lg="3">
                  <Button color="primary" className="btn">
                    Assign
                  </Button>
                </Col>
              {/* // ) : null} */}
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>
          <Row style={{ alignItems: "center" }}>
            <Col>Block Detail</Col>
            {/* <Col className="text-end">
              {TableList?.Table?.length > 0 ? (
                <Button color="success" onClick={onPrint}>
                  Print
                </Button>
              ) : null}
            </Col> */}
          </Row>
        </CardTitle>
        <CardBody>
          <FormGroupTable
            columns={columns}
            rows={TableList}
            hideAction={true}
          />
        </CardBody>
      </Card>
      <div style={{ display: "none" }}>
        <TestSlip ref={componentReference} />
        {/* <AdmissionReportPrint ref={componentReference} /> */}
      </div>
    </Container>
  );
};

export default AssignBlock;
