import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";

const initialValues = {
  email: "",
};
const AddEmail = () => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const leaveColumns = [
    { field: "name", name: "Name" },
    { field: "email", name: "Email" },
  ];
  const leaveRows = [
    {
      name: "Salu",
      email: "salu@gmail.com",
    },
    {
        name: "Salu",
        email: "salu@gmail.com",
      },
  ];
  const onEdit = () => {};
  const onDelete = () => {};
  return (
    <Container fluid>
      <Card className=" mt-3">
        <CardTitle>Search</CardTitle>
        <CardBody>
            <Row>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Email"
                  name="email"
                  onChange={handleInputChange}
                  value={formFields.campus}
                />
              </Col>
              <Col lg="12" md="12" xs="12" className="text-right">
                  <Button color="primary">
                    Search
                  </Button>
                  <Button color="default">
                    Cancel
                  </Button>
              </Col>
            </Row>
        </CardBody>
      </Card>
      <Card>
      <CardTitle>
          <Row>
            <Col lg='6' md='3' xs='12'>
            Email Details
            </Col>
            <Col lg='6' md='3' xs='12' className='text-right'>
              <Button color='primary' className='btn' onClick={toggle}>
                Add <i className='fa fa-plus'></i>
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
            <Row>
              <Col>
                <FormGroupTable
                  columns={leaveColumns}
                  rows={leaveRows}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              </Col>
            </Row>
        </CardBody>
      </Card>

      <Modal isOpen={modal} centered toggle={toggle} modalTransition={{ timeout: 10 }}>
      <ModalHeader toggle={toggle}>Add/Edit Email</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg='6' md='6' xs='12'>
            <FormGroupInput label='Name' name='name' onChange={handleInputChange} value={formFields.name} />
          </Col>
          <Col lg='6' md='6' xs='12'>
          <FormGroupInput label='Email' name='email' onChange={handleInputChange} value={formFields.email} />
        </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='primary'>Save</Button>
        <Button color='default' onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
    </Container>
  );
};

export default AddEmail;