import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { PostRequest } from "../../../utils/Config";
import AttandanceScreen from "./AttandanceScreen";
import ExamScreen from "./ExamScreen";
import { ACADEMIC_RECORD } from "../../../utils/UrlConstants";
import { SessionStorage } from "../../../common/SetupMasterEnum";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import Spinner from "react-spinkit";

const initialFields = {
  OperationID: 0,
  AttandanceRecordID: 0,
  CourseSemesterLocationTimeSlotMappingID: null,
  EnrollmentID: null,
  IsPresent: false,
  IsSaved: false,
  IsLocked: false,
  UserId: decryptData("loginId", SessionStorage),
  ClassDate: null,
  LecturerId: 0,
  CourseId: 0,
  AcademicYearId: 0,
  AttandanceRecord_: [],
};

const FacultyCourses = (props) => {
  const dispatch = useDispatch();

  const [toggleForm, setToggleForm] = useState(false);
  const [onloading, setOnLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [list, setList] = useState([]);
  const [timeslotList, setTimeslotList] = useState([]);
  const [isTakenId, setIsTakenId] = useState(0);
  const [attandanceList, setAttandanceList] = useState([]);
  const [courseId, setCourseId] = useState(null);

  const [onClickButton, setOnClickButton] = useState("");

  useEffect(() => {
    getLectureCourseList();
  }, [courseId]);
  useEffect(() => {
    setIsTakenId(isTakenId)
  }, [isTakenId]);

  function getLectureCourseList() {
    PostRequest(ACADEMIC_RECORD, {
      ...initialFields,
      OperationID: 6,
      LecturerId: decryptData("EmplId", SessionStorage),
    }).then((res) => {
      setList(res?.data?.Table);
      setOnLoading(false);
    });
  }

  const handleClickClose = () => {
    setToggleForm(false);
  };

  const handleClickSubmit = () => {
    if (onClickButton === "Attandance") {
      let detailList = attandanceList?.map((x) => ({
        AttandanceRecordID: x.AttandanceRecordID,
        CourseSemesterLocationTimeSlotMappingID:
          x.CourseSemesterLocationTimeSlotMappingID,
        EnrollmentID: x.EnrollmentID,
        IsPresent: x.IsPresent,
        IsSaved: false,
        IsLocked: false,
        IsActive: false,
        CreatedBy: decryptData("loginId", SessionStorage),
        ModifiedBy: decryptData("loginId", SessionStorage),
        UserIP: decryptData(UserNetworkInfo)?.IPv4,
      }));
      PostRequest(ACADEMIC_RECORD, {
        ...initialFields,
        OperationID: 2,
        CourseSemesterLocationTimeSlotMappingID:
          attandanceList?.[0]?.CourseSemesterLocationTimeSlotMappingID,
        AttandanceRecord_: detailList,
      })
        .then((res) => {
          if (res?.data?.Table?.[0]?.HasError === 0) {
            CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
            reCall();
            setToggleForm(false);
          } else {
            CustomErrorMessage(res?.data?.Table?.[0]?.Message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
    }
  };

  const handleClickAttandanceButton = (x, type) => {
    console.log(type)
    debugger
   setOnClickButton(type);
    if (type === "Attandance") {
      setSelectedCourse(x);
      PostRequest(ACADEMIC_RECORD, {
        ...initialFields,
        OperationID: 7,
        LecturerId: decryptData("EmplId", SessionStorage),
        CourseId: x?.CourseId,
      }).then((res) => {
        setTimeslotList(res?.data?.Table);
        setOnLoading(false);
        setToggleForm(!toggleForm);
      });
    }
  };

  const handleClick = () => {
    return (
      <AttandanceScreen
        lecturerId={decryptData("EmplId", SessionStorage)}
        course={selectedCourse}
        timeslotList={timeslotList}
        attandanceList={attandanceList}
        setAttandanceList={setAttandanceList}
        setCourseId={setCourseId}
        setIsTakenId={setIsTakenId}
      />
    );
  };

  const reCall = () => {
    const payload = {
      ...initialFields,
      OperationID: 8,
      AcademicYearId: selectedCourse.academicYearId,
      LecturerId: decryptData("EmplId", SessionStorage),
      CourseId: selectedCourse.CourseId,
      CourseSemesterLocationTimeSlotMappingID: courseId,
    };
    PostRequest(ACADEMIC_RECORD, payload)
      .then((res) => {
        setAttandanceList(res.data.Table);
        setOnLoading(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Fragment>
      <Container fluid>
        <Card className="mt-3">
          <CardBody>
            <Row>
              <Col>
                <br />
                {onloading === false ? (
                  <Table bordered responsive striped>
                    <thead>
                      <tr className="text-center">
                        <th>S.No</th>
                        <th>Course Code</th>
                        <th>Course Name</th>
                        <th>Semester</th>
                        <th colSpan={2}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list?.length > 0 ? (
                        list?.map((x, i) => (
                          <tr className="text-center" key={i}>
                            <td>{i + 1}</td>
                            <td>{x.CourseCode}</td>
                            <td>{x.CourseName}</td>
                            <td>{x.SemesterName}</td>
                            <td>
                              <Button
                                style={{
                                  background: "lightgrey",
                                  border: "none",
                                  color: "black",
                                  padding: "5px 15px",
                                  lineHeight: 1.5,
                                }}
                                onClick={() => {
                                  handleClickAttandanceButton(x, "Attandance");
                                }}
                              >
                                Attendance
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={5}>
                            No Data Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner className="spinner-custom" name="rotating-plane" />
                  </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          isOpen={toggleForm}
          centered
          modalTransition={{ timeout: 10 }}
          style={{ minWidth: "90vw", width: "100%" }}
        >
          <ModalBody style={{ backgroundColor: "rgb(252 245 229 / 59%)" }}>
            <form>
              <div
                style={{
                  minHeight: "42vw",
                  height: "42%",
                  maxHeight: "42vw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {onClickButton === "Exam" && <ExamScreen />}
                {
                  onClickButton === "Attandance" && handleClick()
                  // (

                  // <AttandanceScreen
                  //   lecturerId={decryptData("EmplId", SessionStorage)}
                  //   course={selectedCourse}
                  //   timeslotList={timeslotList}
                  //   attandanceList={attandanceList}
                  //   setAttandanceList={setAttandanceList}
                  // />
                  // )
                }
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: 20,
                      }}
                    >
                      <Button onClick={handleClickClose}>Back</Button>
                      <Button
                        color="primary"
                        onClick={handleClickSubmit}
                        disabled={+isTakenId === 1 ? true : false}
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default FacultyCourses;
