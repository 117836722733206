import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { dateFormat, dateFormatPlaceholder } from "../../utils/CommonMethods";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";

const initialValues = {
  name: "",
  fatherName: "",
  surname: "",
  designation: "",
  gradeBps: "",
  department: "",
  dateOfFirstAppointment: "",
  inBps: "",
  highestQualification: "",
  totalService: "",
  cnic: "",
  cellNumber: "",
  whatsapp: "",
  email: "",

  nameOfTraining:"",
  institutionName:"",

  bps16reportingName:"",

  bps16remarks:"",
};

const ACRBPS16AndAbove = () => {

  const [formFields, setFormFields] = useState({
    ...initialValues,
  });

  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const bps16concernedcolumns = [
    { field: "name", name: "Name" },
    { field: "fatherName", name: "Father Name" },
    { field: "surname", name: "Surname" },
    { field: "designation", name: "Designation" },
    { field: "gradeBps", name: "GradeBPS" },
    { field: "department", name: "Department" },
    { field: "dateOfFirstAppointment", name: "Date Of First Appointment" },
    { field: "inBps", name: "In BPS" },
    { field: "highestQualification", name: "Highest Qualification" },
    { field: "totalService", name: "Total Service" },
    { field: "cnic", name: "CNIC" },
    { field: "cellNumber", name: "Cell Number" },
    { field: "whatsapp", name: "WhatsApp" },
    { field: "email", name: "Email" },
  ];
  const bps16concernedrows = [
    { name: "Liaquat", fatherName: "Khan", surname: "Farman", designation: "Lecturer", gradeBps: "BPS-17", department: "Technology", 
    dateOfFirstAppointment: "10-01-2008", inBps: "BPS-10", highestQualification: "Graduation", totalService: "5 years", cnic: "43201-1213331-4", cellNumber: "0312-1214444",
    whatsapp: "0312401344", email: "Khan@gmail.com"},

    { name: "Ali", fatherName: "Rehan", surname: "Rehman", designation: "Deen", gradeBps: "BPS-15", department: "Technology", 
    dateOfFirstAppointment: "01-11-2010", inBps: "BPS-5", highestQualification: "Master's", totalService: "1 year", cnic: "41201-9200211-5", cellNumber: "0322-1213333",
    whatsapp: "0321331311", email: "Rehan@gmail.com"},
  ];

  const reportingPeriodcolumns = [
    { field: "nameOfTraining", name: "Name Of Training/Course Attended" },
    { field: "fromDate", name: "From Date" },
    { field: "toDate", name: "toDate" },
    { field: "institutionName", name: "Institution Name" },
  ];

  const reportingPeriodrows = [
    {
      nameOfTraining: "Teaching",
      fromDate: "22-11-2020",
      toDate: "22-12-2020",
      institutionName: "Lecturer",
    },
    {
      nameOfTraining: "Teaching",
      fromDate: "10-05-2020",
      toDate: "15-06-2020",
      institutionName: "Professor",
    },
  ];

  const reportperiodcolumns = [
    { field: "fromDate", name: "From Date" },
    { field: "toDate", name: "To Date" },
  ];

  const reportperiodrows = [
    {
      fromDate: "11-01-2010",
      toDate: "17-06-2012",
    },
    {
      fromDate: "21-01-2021",
      toDate: "22-12-2021",
    },
  ];


  const AllDateSet = (event, type) => {
    if (type === "fromDate") {
      setFormFields({
        ...formFields,
        fromDate: event,
      });
    } else if (type === "toDate") {
      setFormFields({
        ...formFields,
        toDate: event,
      });
    }
  };

  const onEdit = () => { };
  const onDelete = () => { };

  return (
    <Container fluid>
    <h6 className="m-0 pt-3 pb-2">Annual Confidential Report For Officer (BPS 6 & Above)</h6>

    <Card>
    <CardTitle>Report for the Period</CardTitle>
    <CardBody>
    <Row>
    <Col lg='3' md='3' xs='12'>
    <div className='form-group'>
      <label className='form-label'>From Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
    </Col>
    <Col lg='3' md='3' xs='12'>
    <div className='form-group'>
      <label className='form-label'>To Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
    </Col>
    </Row>

    <Row className="pb-3 pt-3">
    <Col lg="12" md="12" sm="12" className="text-right">
    <Button color="primary">Save</Button>
    </Col>
    </Row>
    
    <Row>
    <Col lg='12' md='12' xs='12'>
        <FormGroupTable
          columns={reportperiodcolumns}
          rows={reportperiodrows}
          onEdit={onEdit}
          onDelete={onDelete}
        />
    </Col>
  </Row>
    <p className="m-0 pt-3"><strong>Note:</strong> This report is to be written by the Reporting Officer for each calender year and is to be initiated in the first week of December, unless it is written at any other time of the transfer of the Reporting Officer</p>
   
    </CardBody>
    </Card>

    
    <Card>
    <CardTitle>Important Instructions:</CardTitle>
    <CardBody>
    <Row>
    <Col lg="12" md="12" sm="12">
    <ul className="ps-3">
    <li>If the Countersigning Officer differs with the grading or remarks given by the Reporting Officer, he should score it out and give his own grading in red ink.</li>
    <li>Annual Confidential Report (ACR) is the most important record for the assessment of a Teacher. At the same time quality of ACR is the most important record for the assessment of a Teacher. At the same time quality of ACR is the most important record for the assessment of a Teacher. At the same time quality of ACR is a measure of the competence of the Reporting Officer and Countersigning Officer. It is, therefore, essential that utmost care be exercised by Reporting officer and Countersigning Officer.</li>
    <li>Reporting Officer is expected to counsel the teacher being reported upon about this weak point(s) and advise him how to improve. Adverse Remarks should normally be recorded when the teacher fails to improve despite counseling.</li>
    <li>The ACR from should be filled in a neat and tidy manner. Cuttings/erasiures should be avoided and must be initialed, where made..</li>
    <li>The reporting Officer should be
    <ul className="ps-4">
    <li>As objective as possible</li>
    <li>Whenever the appraisal, it must be substantiated.</li>
    <li>Clear and direct, not ambiguous or evasive in his remarks</li>
    </ul>
    </li>
    <li>Time schedule for completion of ACR writing
    <ul className="ps-4">
    <li>Officer Concerned after filling Part-I, will submit prescribed ACR form to the Reporting Officer up to 1st November</li>
    <li>The Countersigning Officer should record his remarks by the end of the 1st Week of December.</li>
    </ul>
    </li>
    <li>Vague impression based on inadequate knowledge or isolated incident should be avoided.</li>
    </ul>
    </Col>
    </Row>
    </CardBody>
    </Card>

    <Card>
    <CardTitle>Part-I (To be filled by the Concerned)</CardTitle>
    <CardBody>
      <Row>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Name' name='name' onChange={handleInputChange} value={formFields.name} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Father Name' name='fatherName' onChange={handleInputChange} value={formFields.fatherName} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Surname' name='surname' onChange={handleInputChange} value={formFields.surname} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Designation' name='designation' onChange={handleInputChange} value={formFields.designation} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Grade BPS' name='gradeBps' onChange={handleInputChange} value={formFields.gradeBps} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Department' name='department' onChange={handleInputChange} value={formFields.department} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <div className='form-group'>
        <label className='form-label'>Date Of First Appointment</label>
        <DatePicker
          selected={formFields.fromDate}
          dateFormat={dateFormat}
          onChange={(e) => AllDateSet(e, "fromDate")}
          className='form-control'
          name='fromDate'
          placeholderText={dateFormatPlaceholder}
        />
       </div>
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='In BPS' name='inBps' onChange={handleInputChange} value={formFields.inBps} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Highest Qualification' name='highestQualification' onChange={handleInputChange} value={formFields.highestQualification} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <div className='form-group'>
        <label className='form-label'>Date Of Birth</label>
        <DatePicker
          selected={formFields.fromDate}
          dateFormat={dateFormat}
          onChange={(e) => AllDateSet(e, "fromDate")}
          className='form-control'
          name='fromDate'
          placeholderText={dateFormatPlaceholder}
        />
      </div> </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Total Service' name='totalService' onChange={handleInputChange} value={formFields.totalService} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='CNIC' name='cnic' onChange={handleInputChange} value={formFields.cnic} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Cell Number' name='cellNumber' onChange={handleInputChange} value={formFields.cellNumber} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='WhatsApp' name='whatsapp' onChange={handleInputChange} value={formFields.whatsapp} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Email' name='email' onChange={handleInputChange} value={formFields.email} />
      </Col>
      </Row>

      <Row>
      <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
      <Button color="primary">Save</Button>
      </Col>
    </Row>

      <Row>
      <Col lg='12' md='12' xs='12'>
          <FormGroupTable
            columns={bps16concernedcolumns}
            rows={bps16concernedrows}
            onEdit={onEdit}
            onDelete={onDelete}
          />
      </Col>
      </Row>
    </CardBody>
  </Card>

    <Card>
    <CardTitle>Training received during the reporting period</CardTitle>
    <CardBody>
    <Row>
     <Col lg='3' md='3' xs='12'>
       <FormGroupInput label='Name Of Training/Course Attended' name='nameOfTraining' onChange={handleInputChange} value={formFields.nameOfTraining} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <div className='form-group'>
      <label className='form-label'>From Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
      </Col>
      <Col lg='3' md='3' xs='12'>
      <div className='form-group'>
      <label className='form-label'>To Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
       </Col>
      <Col lg='3' md='3' xs='12'>
       <FormGroupInput label='Institution Name' name='institutionName' onChange={handleInputChange} value={formFields.institutionName} />
      </Col>
    </Row>
    <Row>
    <Col lg='12' md='12' xs='12' className="text-right pt-2">
    <Button color="primary">Save</Button>
    </Col>
    </Row>
    <Row className="pt-3">
    <Col lg='12' md='12' xs='12'>
    <FormGroupTable
      columns={reportingPeriodcolumns}
      rows={reportingPeriodrows}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  </Col>
    </Row>
    </CardBody>
    </Card>

    <Card>
      <CardTitle>Part-II (To Be Filled By The initiating/Reporting Officer)</CardTitle>
      <CardBody>
    <Row>
      <Col lg="12" md="12" sm="12">
      <Table bordered className="text-center">
      <tbody>
      <tr>
      <td>Excellent/Highly Accepted</td>
      <td>Good/Accepted</td>
      <td>Average/Minimally Accepted</td>
      <td>Needs Improvement</td>
      <td>Unsatisfactory</td>
      </tr>
      <tr>
      <td>A1</td>
      <td>A</td>
      <td>B</td>
      <td>C</td>
      <td>D</td>
      </tr>
      </tbody>
      </Table>
      </Col>
      </Row>

      <Row>
      <Col lg="12" md="12" sm="12">
      <Table bordered style={{width:"100%"}} className="align-middle">
      <thead>
      <tr>
      <th rowSpan={2} className="text-center align-middle" style={{width:"5%"}}>S.No.</th>
      <th rowSpan={2} className="text-center align-middle" style={{width:"50%"}}>Factor</th>
      <th colSpan={5} className="text-center">Performance</th>
      <th rowSpan={2} className="text-center align-middle" style={{width:"20%"}}>Remarks</th>
      </tr>
      <tr>
      <th className="text-center align-middle">A1</th>
      <th className="text-center align-middle">A</th>
      <th className="text-center align-middle">B</th>
      <th className="text-center align-middle">C</th>
      <th className="text-center align-middle">D</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      <td className="text-center align-middle">01</td>
      <td>Ability to Guide: Cooperative and caring to colleagues and subordinates</td>
      <td className="text-center align-middle"><Input name="ability" type="radio" /></td>
      <td className="text-center align-middle"><Input name="ability" type="radio" /></td>
      <td className="text-center align-middle"><Input name="ability" type="radio" /></td>
      <td className="text-center align-middle"><Input name="ability" type="radio" /></td>
      <td className="text-center align-middle"><Input name="ability" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">02</td>
      <td>Analytical and Logical Reasoning: Able to handle and interpret data, identify key issues, resolve problems and drive solutions</td>
      <td className="text-center align-middle"><Input name="analytical" type="radio" /></td>
      <td className="text-center align-middle"><Input name="analytical" type="radio" /></td>
      <td className="text-center align-middle"><Input name="analytical" type="radio" /></td>
      <td className="text-center align-middle"><Input name="analytical" type="radio" /></td>
      <td className="text-center align-middle"><Input name="analytical" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
     </tr>
      <tr>
      <td className="text-center align-middle">03</td>
      <td>Attendance and Puntuality</td>
      <td className="text-center align-middle"><Input name="attendance" type="radio" /></td>
      <td className="text-center align-middle"><Input name="attendance" type="radio" /></td>
      <td className="text-center align-middle"><Input name="attendance" type="radio" /></td>
      <td className="text-center align-middle"><Input name="attendance" type="radio" /></td>
      <td className="text-center align-middle"><Input name="attendance" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">04</td>
      <td>Communication Skills: Oral, Written and Listening</td>
      <td className="text-center align-middle"><Input name="communication" type="radio" /></td>
      <td className="text-center align-middle"><Input name="communication" type="radio" /></td>
      <td className="text-center align-middle"><Input name="communication" type="radio" /></td>
      <td className="text-center align-middle"><Input name="communication" type="radio" /></td>
      <td className="text-center align-middle"><Input name="communication" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">05</td>
      <td>Confidence and will power: Confident and able to operate independently</td>
      <td className="text-center align-middle"><Input name="confidence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="confidence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="confidence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="confidence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="confidence" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">06</td>
      <td>Decision making: Takes effective, timely and accurate decisions</td>
      <td className="text-center align-middle"><Input name="decision" type="radio" /></td>
      <td className="text-center align-middle"><Input name="decision" type="radio" /></td>
      <td className="text-center align-middle"><Input name="decision" type="radio" /></td>
      <td className="text-center align-middle"><Input name="decision" type="radio" /></td>
      <td className="text-center align-middle"><Input name="decision" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">07</td>
      <td>Diligence: Careful and Vigilant</td>
      <td className="text-center align-middle"><Input name="diligence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="diligence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="diligence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="diligence" type="radio" /></td>
      <td className="text-center align-middle"><Input name="diligence" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">08</td>
      <td>Foresight: Anticipates problems and plans ahead</td>
      <td className="text-center align-middle"><Input name="foresight" type="radio" /></td>
      <td className="text-center align-middle"><Input name="foresight" type="radio" /></td>
      <td className="text-center align-middle"><Input name="foresight" type="radio" /></td>
      <td className="text-center align-middle"><Input name="foresight" type="radio" /></td>
      <td className="text-center align-middle"><Input name="foresight" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">09</td>
      <td>IT Skills: Meets latest standards of working. Well proficient in computer</td>
      <td className="text-center align-middle"><Input name="itskills" type="radio" /></td>
      <td className="text-center align-middle"><Input name="itskills" type="radio" /></td>
      <td className="text-center align-middle"><Input name="itskills" type="radio" /></td>
      <td className="text-center align-middle"><Input name="itskills" type="radio" /></td>
      <td className="text-center align-middle"><Input name="itskills" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">10</td>
      <td>Leadership: Motivates employees and builds teams</td>
      <td className="text-center align-middle"><Input name="leadership" type="radio" /></td>
      <td className="text-center align-middle"><Input name="leadership" type="radio" /></td>
      <td className="text-center align-middle"><Input name="leadership" type="radio" /></td>
      <td className="text-center align-middle"><Input name="leadership" type="radio" /></td>
      <td className="text-center align-middle"><Input name="leadership" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">11</td>
      <td>Organizational skills: Maintain files and information well to allow quick retrieval</td>
      <td className="text-center align-middle"><Input name="organizational" type="radio" /></td>
      <td className="text-center align-middle"><Input name="organizational" type="radio" /></td>
      <td className="text-center align-middle"><Input name="organizational" type="radio" /></td>
      <td className="text-center align-middle"><Input name="organizational" type="radio" /></td>
      <td className="text-center align-middle"><Input name="organizational" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">12</td>
      <td>Planning and Organizing: Plans ahead and demonstrates good organization</td>
      <td className="text-center align-middle"><Input name="planning" type="radio" /></td>
      <td className="text-center align-middle"><Input name="planning" type="radio" /></td>
      <td className="text-center align-middle"><Input name="planning" type="radio" /></td>
      <td className="text-center align-middle"><Input name="planning" type="radio" /></td>
      <td className="text-center align-middle"><Input name="planning" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">13</td>
      <td>Professional Integrity: Honest to his job and having strong moral principles</td>
      <td className="text-center align-middle"><Input name="professionalintegrity" type="radio" /></td>
      <td className="text-center align-middle"><Input name="professionalintegrity" type="radio" /></td>
      <td className="text-center align-middle"><Input name="professionalintegrity" type="radio" /></td>
      <td className="text-center align-middle"><Input name="professionalintegrity" type="radio" /></td>
      <td className="text-center align-middle"><Input name="professionalintegrity" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">14</td>
      <td>Prioritization: Performs tasks in order of importance</td>
      <td className="text-center align-middle"><Input name="prioritization" type="radio" /></td>
      <td className="text-center align-middle"><Input name="prioritization" type="radio" /></td>
      <td className="text-center align-middle"><Input name="prioritization" type="radio" /></td>
      <td className="text-center align-middle"><Input name="prioritization" type="radio" /></td>
      <td className="text-center align-middle"><Input name="prioritization" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">15</td>
      <td>Relevant knowledge: Having enough knowledge and skills required for the job</td>
      <td className="text-center align-middle"><Input name="relevant" type="radio" /></td>
      <td className="text-center align-middle"><Input name="relevant" type="radio" /></td>
      <td className="text-center align-middle"><Input name="relevant" type="radio" /></td>
      <td className="text-center align-middle"><Input name="relevant" type="radio" /></td>
      <td className="text-center align-middle"><Input name="relevant" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">16</td>
      <td>Responsibilities and commitment: Always ready to take responsibility even in complicated situation</td>
      <td className="text-center align-middle"><Input name="responsibilities" type="radio" /></td>
      <td className="text-center align-middle"><Input name="responsibilities" type="radio" /></td>
      <td className="text-center align-middle"><Input name="responsibilities" type="radio" /></td>
      <td className="text-center align-middle"><Input name="responsibilities" type="radio" /></td>
      <td className="text-center align-middle"><Input name="responsibilities" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">17</td>
      <td>Team work: Motivate other and cooperative. Creates an effective working environment</td>
      <td className="text-center align-middle"><Input name="teamwork" type="radio" /></td>
      <td className="text-center align-middle"><Input name="teamwork" type="radio" /></td>
      <td className="text-center align-middle"><Input name="teamwork" type="radio" /></td>
      <td className="text-center align-middle"><Input name="teamwork" type="radio" /></td>
      <td className="text-center align-middle"><Input name="teamwork" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">18</td>
      <td>Temperament: Remain calm in stress and difficult situation</td>
      <td className="text-center align-middle"><Input name="temperament" type="radio" /></td>
      <td className="text-center align-middle"><Input name="temperament" type="radio" /></td>
      <td className="text-center align-middle"><Input name="temperament" type="radio" /></td>
      <td className="text-center align-middle"><Input name="temperament" type="radio" /></td>
      <td className="text-center align-middle"><Input name="temperament" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">19</td>
      <td>Time management: Efficient in time management</td>
      <td className="text-center align-middle"><Input name="timemanagement" type="radio" /></td>
      <td className="text-center align-middle"><Input name="timemanagement" type="radio" /></td>
      <td className="text-center align-middle"><Input name="timemanagement" type="radio" /></td>
      <td className="text-center align-middle"><Input name="timemanagement" type="radio" /></td>
      <td className="text-center align-middle"><Input name="timemanagement" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>
      <tr>
      <td className="text-center align-middle">20</td>
      <td>Willingness to learn: Ability to appreciate other's points and guidance</td>
      <td className="text-center align-middle"><Input name="willingness" type="radio" /></td>
      <td className="text-center align-middle"><Input name="willingness" type="radio" /></td>
      <td className="text-center align-middle"><Input name="willingness" type="radio" /></td>
      <td className="text-center align-middle"><Input name="willingness" type="radio" /></td>
      <td className="text-center align-middle"><Input name="willingness" type="radio" /></td>
      <td className="text-center align-middle"><FormGroupInput name='bps16remarks' onChange={handleInputChange} value={formFields.bps16remarks} /></td>
      </tr>

      </tbody>
      </Table>
      </Col>
      </Row>
   
      <Row className="pt-2">
        <Col lg="12" md="12" sm="12">
        <h6 className="pb-1">Note: Reporting officer to initial in the appropriate box.</h6>
        <Table bordered className="text-center">
        <tbody>
        <tr>
        <td>Excellent/Highly Accepted</td>
        <td>Good/Accepted</td>
        <td>Average/Minimally Accepted</td>
        <td>Needs Improvement</td>
        <td>Unsatisfactory</td>
        </tr>
        <tr>
        <td>&nbsp;</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tr>
        </tbody>
        </Table>
        </Col>
        </Row>
        <Row>
        <Col lg='12' md='12' xs='12' className="text-right pb-0">
          <Button color="primary">Save</Button>
        </Col>
      </Row>
        </CardBody>
      </Card>

      <Card>
      <CardTitle>Overall Rating  </CardTitle>
      <CardBody>
      <Row>
        <Col lg="12" md="12" sm="12">
        <Table bordered style={{width:"100%"}}>
        <tbody>
        <tr>
        <td style={{width:"40%"}}>Potential for growth and Development</td>
        <td>
        <label>Yes</label>
        <Input  
        name="growth"
        type="radio"/>
        </td>
        <td>
        <label>No</label>
        <Input     
        name="growth"
        type="radio"/>
        </td>
        </tr>
       
        <tr>
        <td style={{width:"40%"}}>Is he/she fit for higher level Responsibilities?</td>
        <td>	
        <label>Yes</label>
        <Input  
        name="level"
        type="radio"/>
        </td>
        <td>
        <label>No</label>
        <Input     
        name="level"
        type="radio"/>
        </td>
        </tr>
  
        <tr>
        <td style={{width:"40%"}}>Does he/she require any additional training?</td>
        <td>
        <label>Yes</label>
        <Input  
        name="require"
        type="radio"/>
        </td>
        <td>
        <label>No</label>
        <Input     
        name="require"
        type="radio"/>
        </td>
        </tr>
  
        <tr>
        <td style={{width:"40%"}}>If yes, nature and type of training</td>
        <td colSpan={2}>
        <Input  
        type="text"/>
        </td>
        
        </tr>
        </tbody>
        </Table>
        </Col>
        </Row>
        <Row>
        <Col lg='12' md='12' xs='12' className="text-right pb-0">
          <Button color="primary">Save</Button>
        </Col>
      </Row>
        </CardBody>
        </Card>

        <Card>
        <CardTitle>
        Reporting Officer (HOD)
        </CardTitle>
        <CardBody>
        <Row>
       
        <Col lg='12' md='12' xs='12'>
        <FormGroup>
        <Label>Remarks: (If any)</Label>
        <Input
            type="textarea"
            rows={6}
            className="faculty-textarea"
        />
    </FormGroup>
      </Col>
      </Row>
      <Row>
       <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Name' name='bps16reportingName' onChange={handleInputChange} value={formFields.bps16reportingName} />
        </Col>
        <Col lg='3' md='3' xs='12'>
        <div className='form-group'>
        <label className='form-label'> Date </label>
        <DatePicker
          selected={formFields.fromDate}
          dateFormat={dateFormat}
          onChange={(e) => AllDateSet(e, "fromDate")}
          className='form-control'
          name='fromDate'
          placeholderText={dateFormatPlaceholder}
        />
      </div>   
       </Col>
      </Row>
      <Row>
        <Col lg='12' md='12' xs='12' className="text-right">
          <Button color="primary">Save</Button>
        </Col>
      </Row>
        </CardBody>
        </Card>
      <h6>Part-III </h6>
        <Card>
        <CardTitle>
      Countersigning Authority
        </CardTitle>
        <CardBody>
        <Row>
        <Col lg='12' md='12' xs='12'>
        <FormGroup>
        <label>I agree with the above assessment</label>
        <Input  
        name="evaluation"
        type="radio" className="me-3"/>
       
        <label>I disagree with the above assessment</label>
        <Input  
        name="evaluation"
        type="radio"/>
        </FormGroup>
        </Col>
        <Col lg='12' md='12' xs='12'>
        <FormGroup>
        <Label>If disagrees with Reporting Officer, give reasons:</Label>
        <Input
            type="textarea"
            rows={6}
            className="faculty-textarea"
        />
    </FormGroup>
      </Col>
      </Row>
      <Row>
        <Col lg='12' md='12' xs='12' className="text-right">
          <Button color="primary">Save</Button>
        </Col>
      </Row>
        </CardBody>
        </Card>

        <Card>
        <CardTitle>
      Second Countersigning Authority
        </CardTitle>
        <CardBody>
        <Row>
        <Col lg='12' md='12' xs='12'>
        <FormGroup>
        <label>I agree with the above assessment</label>
        <Input  
        name="evaluation"
        type="radio" className="me-3"/>
       
        <label>I disagree with the above assessment</label>
        <Input  
        name="evaluation"
        type="radio"/>
        </FormGroup>
        </Col>
        <Col lg='12' md='12' xs='12'>
        <FormGroup>
        <Label>If disagrees with Reporting Officer and countersigning officer, give reasons:</Label>
        <Input
            type="textarea"
            rows={6}
            className="faculty-textarea"
        />
    </FormGroup>
      </Col>
      </Row>
      <Row>
        <Col lg='12' md='12' xs='12' className="text-right">
          <Button color="primary">Save</Button>
        </Col>
      </Row>
        </CardBody>
        </Card>


    </Container>
  );
};

export default ACRBPS16AndAbove