import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Row,
  Progress,
} from "reactstrap";
import { Doughnut, Bar } from "react-chartjs-2";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import { Insert, Select, Update, yearId } from "../../common/SetupMasterEnum";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../utils/Config";
import { ADMISSION_ADM_DASHBOARD } from "../../utils/UrlConstants";

const initialObj = {
  operationID: Select,
  admRegID: 0,
  fromYear: 0,
  toYear: 0,
  createdBy: 0,
  modifiedBy: 0,
  isActive: true,
  userIp: "string",
};

const initialSearchFields = {
  operationID: Update,
  admRegID: 0,
  fromYear: 0,
  toYear: 0,
  createdBy: 0,
  modifiedBy: 0,
  isActive: true,
  userIp: "string",
};

const AdmissionDashboard = (props) => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    getAdmissionDashboard(initialObj);
  }, []);
  const dashboardList = [
    { Count: 450, Name: "Total Registrations" },
    { Count: 450, Name: "Total Applications" },
    { Count: 150, Name: "Complete Applications" },
    { Count: 130, Name: "Applications in Progress" },
    { Count: 120, Name: "Approved Applications" },
    { Count: 50, Name: "Rejected Applications" },
    { Count: 80, Name: "Pending Applications" },
    { Count: 500, Name: "Eligible Candidates" },
    { Count: 105000, Name: "Fees Collection" },
    { Count: 30, Name: "Open Merit Candidates" },
    { Count: 50, Name: "Reserve Category Candidates" },
    { Count: 1, Name: "Self Finance Candidates" },
  ];
  const [searchFields, setSearchFields] = useState(initialObj);

  const barChartData = {
    labels: ["Undergraduate", "Post Graduate"],
    datasets: [
      {
        label: "Fee Generation in Rupees",
        backgroundColor: ["#B21F00", "#C9DE00", "#ccc"],
        data: [
          SupportingTables?.DashboardProgramWiseCount?.[0]?.UndergraduateCount,
          SupportingTables?.DashboardProgramWiseCount?.[0]?.PostgraduateCount,
        ],
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: "left",
      },
    },
  };

  const pieChartData = {
    labels: [
      // SupportingTables?.DashboardFacultyWiseCount?.[0]?.ArtsLanguagesName,
      // SupportingTables?.DashboardFacultyWiseCount?.[0]?.ManagementScienceName,
      // SupportingTables?.DashboardFacultyWiseCount?.[0]?.SocialSciencesName,
      // SupportingTables?.DashboardFacultyWiseCount?.[0]?.PhysicalSciencesName,
      // SupportingTables?.DashboardFacultyWiseCount?.[0]?.LawName,
      // SupportingTables?.DashboardFacultyWiseCount?.[0]?.EducationName,
      // SupportingTables?.DashboardFacultyWiseCount?.[0]?.NaturalSciencesName
      "Arts & Language",
      "Management Sciences",
      "Social Sciences",
      "Physical Sciences",
      "Law",
      "Education",
      "Natural Sciences",
    ],
    datasets: [
      {
        label: "Employee Breakdown",
        backgroundColor: [
          "#B21F00",
          "#C9DE00",
          "#2FDE00",
          "#00A6B4",
          "#325cd8",
          "#8632f1",
          "#ff39acd1",
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#325cd8",
          "#8632f1",
          "#ff39acd1",
        ],
        data: [
          SupportingTables?.DashboardFacultyWiseCount?.[0]?.ArtsLanguages,
          SupportingTables?.DashboardFacultyWiseCount?.[0]?.ManagementScience,
          SupportingTables?.DashboardFacultyWiseCount?.[0]?.SocialSciences,
          SupportingTables?.DashboardFacultyWiseCount?.[0]?.PhysicalSciences,
          SupportingTables?.DashboardFacultyWiseCount?.[0]?.Law,
          SupportingTables?.DashboardFacultyWiseCount?.[0]?.Education,
          SupportingTables?.DashboardFacultyWiseCount?.[0]?.NaturalSciences,
        ],
      },
    ],
  };
  const list = [
    { id: 1, name: "2018-2019" },
    { id: 2, name: "2020-2021" },
    { id: 3, name: "2022-2023" },
  ];
  const handleInputChange = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };
  const submitSearch = (e) => {
    e.preventDefault();
    let data = {
      operationID: Insert,
      admRegID: 0,
      fromYear: searchFields?.fromYear,
      toYear: searchFields?.toYear,
      createdBy: 0,
      modifiedBy: 0,
      isActive: true,
      userIp: "string",
    };
    PostRequest(ADMISSION_ADM_DASHBOARD, data)
      .then((res) => {
        let data = {
          name: "DashboardProgramWiseCount",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const submitFacultyWiseSearch = (e) => {
    e.preventDefault();
    PostRequest(ADMISSION_ADM_DASHBOARD, SearchFields)
      .then((res) => {
        let data = {
          name: "DashboardFacultyWiseCount",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getAdmissionDashboard = (data) => {
    PostRequest(ADMISSION_ADM_DASHBOARD, data)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Container fluid className="pt-2">
      <Row>
        {/* {dashboardList?.map((item, ind) => {
          return ( */}
        <Col lmd="3" lg="3">
          <Card className={`card-s1`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.ApprovedApplicationsName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.ApprovedApplications}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s2`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.CompleteApplicationsName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.CompletedApplications}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s3`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.FeesCollectionName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.FeesCollection}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s4`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.OpenMeritCandidatesName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.OpenMeritCandidates}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s5`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.PendingApplicationsName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.PendingApplications}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s6`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.RejectedApplicationsName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.RejectedApplications}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s7`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.ReserveCategoryCandidatesName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.ReserveCategoryCandidates}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s8`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.SelfFinanceCandidatesName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.SelfFinanceCandidates}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s9`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.TotalApplicationsName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.TotalApplications}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lmd="3" lg="3">
          <Card className={`card-s1`}>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="3" lg="3" xs="12">
                  <p className="adm-icons">
                    <i className="fa fa-user-circle-o"></i>
                  </p>
                </Col>
                <Col md="9" lg="9" xs="12">
                  <p className="card-number mb-0 pb-0">
                    {TableList[0]?.TotalRegistrationsName}
                  </p>
                  <p className="card-total mb-0">
                    {TableList[0]?.TotalRegistration}
                  </p>
                  {/* <Progress
                        color="secondary"
                        animated
                        value={item.Count / 10}
                        className="my-2"
                        style={{
                          height: "6px",
                        }}
                      /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        {/* );
        })} */}
      </Row>
      <Row>
        <Col lg="5" md="5" xs="12">
          <Card>
            <CardTitle>Program Wise Admission Fee Revenue Generation</CardTitle>
            <CardBody>
              <form onSubmit={submitSearch}>
                <Row style={{ alignItems: "flex-end" }}>
                  <Col lg="4" md="4" xs="12">
                    <FormGroupSelect
                      label="From Year"
                      list={SupportingTables?.MasterDropdown?.filter(
                        (x) => x.SetupMasterId == yearId
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      onChange={handleInputChange}
                      name="fromYear"
                      value={searchFields?.fromYear}
                      required
                    />
                  </Col>
                  <Col lg="4" md="4" xs="12">
                    <FormGroupSelect
                      label="From Year"
                      list={SupportingTables?.MasterDropdown?.filter(
                        (x) => x.SetupMasterId == yearId
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      onChange={handleInputChange}
                      name="toYear"
                      value={searchFields?.toYear}
                      required
                    />
                  </Col>
                  <Col lg="4" md="4" xs="12" className="text-end">
                    <Button type="submit" color="primary">
                      Search
                    </Button>
                  </Col>
                </Row>
              </form>
              <div className="barchart-payroll">
                <Bar data={barChartData} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7" md="7" xs="12">
          <Card>
            <CardTitle>Applications Applied Faculty Wise </CardTitle>
            <CardBody>
              <form onSubmit={submitFacultyWiseSearch}>
                <Row>
                  <Col lg="4" md="4" xs="12">
                    <FormGroupSelect
                      label="From Year"
                      list={SupportingTables?.MasterDropdown?.filter(
                        (x) => x.SetupMasterId == yearId
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      onChange={handleSearchChange}
                      name="fromYear"
                      value={SearchFields?.fromYear}
                      required
                    />
                  </Col>
                  <Col lg="4" md="4" xs="12">
                    <FormGroupSelect
                      label="From Year"
                      list={SupportingTables?.MasterDropdown?.filter(
                        (x) => x.SetupMasterId == yearId
                      )}
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      onChange={handleSearchChange}
                      name="toYear"
                      value={SearchFields?.toYear}
                      required
                    />
                  </Col>
                  <Col lg="4" md="4" xs="12" className="text-end mt-4">
                    <Button type="submit" color="primary">
                      Search
                    </Button>
                  </Col>
                </Row>
              </form>
              <div className="piechart-admission">
                <Doughnut data={pieChartData} options={options} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmissionDashboard;
