import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Swal from "sweetalert2";
import "../assets/css/login.css";
import { SessionStorage } from "../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  ErrorWithConfirmation,
  WarningWithConfirmation,
} from "../components/Alert";
import LoginFormComponent from "../components/FormComponents/LoginFormComponent/loginIndex";
import FormGroupInput from "../components/GeneralComponent/FormGroupInput";
import { encryptData } from "../EncryptData";
import {
  LOG_IN,
  LOG_OUT,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  RESET_FORM_FIELDS,
} from "../redux/actionType/AuthType";
import { Admission_ADM_Registration, PostRequest } from "../utils/Config";

import {
  ADMISSION_USER_ID,
  CNIC_BFORM,
  EMAIL,
  EMPLOYEE_NAME,
  LAST_NAME,
  LOGIN_TYPE,
  MENU_TABLE,
  MIDDLE_NAME,
  MOBILE,
  REG_ID,
  TYPE,
} from "../utils/EncryptedConstants";
import { ADMISSION_ADM_REGISTRATION } from "../utils/UrlConstants";

const initialFormFields = {
  operationID: 5,
  FirstName: "",
  MiddleName: "",
  LastName: "",
  NIC_FormB: "",
  Mobile: "",
  Email: "",
  Password: "",
  RepeatPassword: "",
  isActive: true,
  createdBy: 1,
  modifiedBy: 1,
  userIP: "::1",
};

const initialSearchFields = {
  NIC_FormB: "",
  otp: 0,
};

const NewUserLogin = (props) => {
  const { FormFields, SearchFields, loginId, menuTable } = useSelector(
    (state) => state.AuthReducer
  );

  const dispatch = useDispatch();

  const [verificationCodeModal, setVerificationCodeModal] = useState(false);

  useEffect(() => {
    dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
  }, []);

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const admissionSubmit = () => {
    Admission_ADM_Registration(FormFields)
    // PostRequest(ADMISSION_ADM_REGISTRATION, FormFields)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 1) {
          CustomErrorMessage(res?.data?.Table[0]?.Column1);
        } else if (res?.data?.Table[0]?.HasError === 2) {
          let data = { name: "NIC_FormB", value: res?.data?.Table[0]?.CNIC };
          dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
          ErrorWithConfirmation(res?.data?.Table?.[0]?.MESSAGE).then(
            (result) => {
              if (result.isConfirmed) {
                setVerificationCodeModal(true);
              }
            }
          );
          setVerificationCodeModal(true);
        } else {
          const type = res?.data?.Table3[0]?.Type;
          const cnic_BForm = res?.data?.Table3[0]?.NIC_FormB;
          const candidateName = res?.data?.Table3[0]?.FirstName;
          const middleName = res?.data?.Table3[0]?.MiddileName;
          const lastName = res?.data?.Table3[0]?.LastName;
          const email = res?.data?.Table3[0]?.Email;
          const mobile = res?.data?.Table3[0]?.Mobile;
          const regId = res?.data?.Table3[0]?.ADMRegID;
          const admissionUserId = res?.data?.Table3[0].UserId;
          const menuTables = {
            Table1: res.data?.Table1,
            Table2: res.data?.Table2,
          };
          let data = {
            loginId: res?.data?.Table3[0]?.UserId,
            token: null,
            expiry: null,
            menuTable: { Table1: res.data?.Table1, Table2: res.data?.Table2 },
          };
          dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
          dispatch({ type: LOG_IN, payload: data });
          encryptData(
            res?.data?.Table3[0]?.UserId,
            "loginId",
            SessionStorage
          );
          encryptData(type, TYPE, SessionStorage);
          encryptData(cnic_BForm, CNIC_BFORM, SessionStorage);
          encryptData(candidateName, EMPLOYEE_NAME, SessionStorage);
          encryptData(middleName, MIDDLE_NAME, SessionStorage);
          encryptData(lastName, LAST_NAME, SessionStorage);
          encryptData(email, EMAIL, SessionStorage);
          encryptData(mobile, MOBILE, SessionStorage);
          encryptData(menuTables, MENU_TABLE, SessionStorage);
          encryptData("2", LOGIN_TYPE, SessionStorage);
          encryptData(regId, REG_ID, SessionStorage);
          encryptData(admissionUserId, ADMISSION_USER_ID, SessionStorage);
          props.history.push("/pages/admission/instruction");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleVerifyCode = () => {
    let payload = {
      operationID: 1,
      userID: 0,
      otp: SearchFields?.otp,
      FirstName: "",
      MiddleName: "",
      LastName: "",
      NIC_FormB: SearchFields?.NIC_FormB,
      Mobile: "",
      Email: "",
      Password: "",
      RepeatPassword: "",
      isActive: true,
      createdBy: 1,
      modifiedBy: 1,
      userIP: "::1",
    };
     Admission_ADM_Registration(payload)
    // PostRequest(ADMISSION_ADM_REGISTRATION, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
          setVerificationCodeModal(false);
          const type = res?.data?.Table3[0]?.Type;
          const cnic_BForm = res?.data?.Table3[0]?.NIC_FormB;
          const candidateName = res?.data?.Table3[0]?.FirstName;
          const middleName = res?.data?.Table3[0]?.MiddileName;
          const lastName = res?.data?.Table3[0]?.LastName;
          const email = res?.data?.Table3[0]?.Email;
          const mobile = res?.data?.Table3[0]?.Mobile;
          const regId = res?.data?.Table3[0]?.ADMRegID;
          const admissionUserId = res?.data?.Table3[0].UserId;
          const menuTables = {
            Table1: res.data?.Table1,
            Table2: res.data?.Table2,
          };
          let data = {
            loginId: res?.data?.Table3[0]?.UserId,
            token: null,
            expiry: null,
            menuTable: { Table1: res.data?.Table1, Table2: res.data?.Table2 },
          };
          dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
          dispatch({ type: LOG_IN, payload: data });
          encryptData(
            res?.data?.Table3[0]?.UserId,
            "loginId",
            SessionStorage
          );
          encryptData(type, TYPE, SessionStorage);
          encryptData(cnic_BForm, CNIC_BFORM, SessionStorage);
          encryptData(candidateName, EMPLOYEE_NAME, SessionStorage);
          encryptData(middleName, MIDDLE_NAME, SessionStorage);
          encryptData(lastName, LAST_NAME, SessionStorage);
          encryptData(email, EMAIL, SessionStorage);
          encryptData(mobile, MOBILE, SessionStorage);
          encryptData(menuTables, MENU_TABLE, SessionStorage);
          encryptData("2", LOGIN_TYPE, SessionStorage);
          encryptData(regId, REG_ID, SessionStorage);
          encryptData(admissionUserId, ADMISSION_USER_ID, SessionStorage);
          props.history.push("/pages/admission/instruction");
          let VerificationCode = { name: "VerificationCode", value: "" };
          dispatch({ type: SET_CRUD_FROM_FIELDS, payload: VerificationCode });
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCloseModal = () => {
    setVerificationCodeModal(false);
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
  };

  const fieldsPanel = (
    <Fragment>
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-mobile"
        label="NIC or B-Form"
        name="NIC_FormB"
        required
        isNumber="true"
        maxLength={13}
        onChange={handleAddChange}
        value={FormFields?.NIC_FormB}
      />
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-lock"
        type="password"
        label="Password"
        name="Password"
        required
        onChange={handleAddChange}
        value={FormFields?.Password}
      />

      <Modal
        isOpen={verificationCodeModal}
        centered
        // toggle={toggle}
        modalTransition={{ timeout: 10 }}
        // backdrop="static"
      >
        <ModalHeader
        // toggle={toggle}
        >
          Verification Code
        </ModalHeader>
        <ModalBody>
          <Row>
            <FormGroupInput
              isIcon={true}
              label="Verification Code"
              name="otp"
              onChange={handleSearchChange}
              value={SearchFields?.otp}
              isNumber="true"
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button color="primary" onClick={handleVerifyCode}>
                Submit
              </Button>
              <Button color="primary" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );

  return (
    <LoginFormComponent
      fieldsPanel={fieldsPanel}
      admissionSubmit={admissionSubmit}
      initialFormFields={initialFormFields}
      loginType="New"
      buttonName="Login"
      // formName="Employee"
      // buttonName="Create New Employee"
      // modalSize="lg"
    />
  );
};

export default NewUserLogin;
