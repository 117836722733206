import React, { forwardRef, Fragment } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import logoimg from "../../assets/img/logo.png";

const AdmitCard = forwardRef((props, ref) => {
  return (
    <div style={{padding:30}} ref={ref}>
      <Container fluid>
        <div className="card-div">
          <table>
            <tbody>
              <tr>
                <td className="text-center">
                  <img src={logoimg} height="90" />
                </td>
                <td>
                  <div className="text-center adm-sl">
                    <h4>SHAH ABDUL LATIF UNIVERSITY</h4>
                    <h4 className="pre-admi">
                      PRE-ADMISSION TEST FOR BECHELOR (PART-I) - 2022
                    </h4>
                    <p>ADMIT CARD</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colSpan="4"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  ARCHAEOLOGY
                </td>
              </tr>
            </tbody>
          </table>
          <Row>
            <Col lg="6" className="text-center offset-md-3">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "20%",
                      }}
                      colSpan="1"
                    >
                      TEST ID#
                    </td>
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "50%",
                      }}
                      colSpan="3"
                    >
                      1111211
                    </td>
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "20%",
                      }}
                      colSpan="1"
                      rowSpan="5"
                    >
                      <img src={logoimg} height="90" />
                    </td>
                  </tr>
                  <tr
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "20%",
                      }}
                      colSpan="1"
                    >
                      FULL NAME
                    </td>
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "50%",
                      }}
                      colSpan="3"
                    >
                      ZARFAN
                    </td>
                  </tr>
                  <tr
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "20%",
                      }}
                      colSpan="1"
                    >
                      FATHER'S NAME
                    </td>
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "50%",
                      }}
                      colSpan="3"
                    >
                      GHULAM NABI
                    </td>
                  </tr>
                  <tr
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "20%",
                      }}
                      colSpan="1"
                    >
                      CNIC
                    </td>
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "50%",
                      }}
                      colSpan="3"
                    >
                      42301-7984362-3
                    </td>
                  </tr>
                  <tr
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "20%",
                      }}
                      colSpan="1"
                    >
                      BLOCK
                    </td>
                    <td
                      style={{
                        border: "1px",
                        borderStyle: "solid",
                        width: "50%",
                      }}
                      colSpan="3"
                    >
                      45
                    </td>
                  </tr>
                  <tr style={{ height: "20px" }}></tr>
                  <tr style={{ height: "20px" }}></tr>
                  <tr className="text-center">
                    <td colSpan="2">_____________________</td>
                    <td colSpan="2">_____________________</td>
                  </tr>
                  <tr>
                    <td colSpan="2">Candidate's Signature</td>
                    <td colSpan="2">Authorized Person's Signature </td>
                  </tr>
                  <tr style={{ height: "10px" }}></tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <table>
            <tbody>
              <tr>
                <td
                  colSpan="11"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "black",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  DATE AND TIME: Jan 25,2022 10:00 am Venue:Shah Abdul Latif
                  University (Main Campus) Khairpur
                </td>
              </tr>
              <tr style={{ height: "10px" }}></tr>
              <tr>
                <td
                  colSpan="4"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Important Instructions for the test day
                </td>
              </tr>
              <tr>
                {/* <td style={{width:"5%"}}  colSpan="2"></td> */}
                <td colSpan="2">1. Reporting Time is 9:30 AM.</td>
              </tr>
              <tr>
                {/* <td style={{width:"5%"}}  colSpan="2"></td> */}
                <td colSpan="2">
                  2. No one is allowed in the test without this slip. Retain
                  copy of this slip.
                </td>
              </tr>
              <tr>
                {/* <td style={{width:"5%"}}  colSpan="2"></td> */}
                <td colSpan="2">3. Please bring your original CNIC.</td>
              </tr>
              <tr>
                {/* <td style={{width:"5%"}}  colSpan="2"></td> */}
                <td colSpan="2">
                  4. You are required to be present in your respective block 30
                  minuts before the test time.
                </td>
              </tr>
              <tr>
                {/* <td style={{width:"5%"}}  colSpan="2"></td> */}
                <td colSpan="2">
                  5. Mobile phones, calculator, handbook and purses are not
                  allowed in the premises of test center.
                </td>
              </tr>
              <tr>
                {/* <td style={{width:"5%"}}  colSpan="2"></td> */}
                <td colSpan="2">
                  6. Follow SOP'S strictly, Please wear Face Mask
                  and keep the social distance.
                </td>
              </tr>
              <tr style={{ height: "10px" }}></tr>
              <tr>
                {/* <td style={{width:"5%"}}  colSpan="2"></td> */}
                <td colSpan="2">
                  Note: Clipboard, pencil will be provided at the time of Test.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
});

export default AdmitCard;
