import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../assets/css/login.css";
import { SessionStorage } from "../common/SetupMasterEnum";
import { CustomErrorMessage } from "../components/Alert";
import LoginFormComponent from "../components/FormComponents/LoginFormComponent/loginIndex";
import FormGroupInput from "../components/GeneralComponent/FormGroupInput";
import { decryptData, encryptData } from "../EncryptData";
import {
  LOG_IN,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
} from "../redux/actionType/AuthType";
import { PostRequest, SecuritySetup_Authenticate } from "../utils/Config";

import {
  EMPLOYEE_DESIGNATION,
  EMPLOYEE_NAME,
  LOGIN_TYPE,
  MENU_TABLE,
  RESETPASSWORD,
  TYPE,
  UserNetworkInfo,
} from "../utils/EncryptedConstants";
import { LOGIN } from "../utils/UrlConstants";

// import packageJson from "../../package.json"; //'../package.json';
const initialFormFields = { loginId: "", loginPswd: "" };

const Login = (props) => {
  const { FormFields, loginId, menuTable } = useSelector(
    (state) => state.AuthReducer
  );

  const dispatch = useDispatch();
  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const submitForm = (userType) => {
    const payload = {
      type: userType,
      loginId: FormFields?.loginId,
      password: FormFields?.loginPswd,
      userIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(LOGIN, payload)
      // SecuritySetup_Authenticate(FormFields?.loginId, FormFields?.loginPswd)
      .then((res) => {
        if (res?.data?.Table[0]?.INCORRECTPASSWORD === 1) {
          CustomErrorMessage("Username or Password Incorrect");
        } else if (res?.data?.Table[0]?.IsLocked === 1) {
          CustomErrorMessage(
            "User Locked, Please contact to you'r Administrator"
          );
        } else if (res?.data?.Table[0]?.UserNotFound === 3) {
          CustomErrorMessage("User Not Found");
        } else {
          const userId = res?.data?.Table[0]?.userid;
          const employeeId = res?.data?.Table3[0]?.EmployeeId;
          const resetPassword = res?.data?.Table4[0]?.ResetPassword;
          const employeeName = res?.data?.Table5[0]?.EmployeeName;
          const employeeDesign = res?.data?.Table6[0]?.EmployeeDesignation;
          const type = res?.data?.Table7[0]?.Type;
          // const imagePath = res?.data?.Table8[0]?.ImagePath;
          
          const menuTables = {
            Table1: res.data?.Table1,
            Table2: res.data?.Table2,
          };
          let data = {
            loginId: userId,
            token: null,
            expiry: null,
            menuTable: { Table1: res.data?.Table1, Table2: res.data?.Table2 },
          };
          dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
          dispatch({ type: LOG_IN, payload: data });
          encryptData(type, TYPE, SessionStorage);
          encryptData(menuTables, MENU_TABLE, SessionStorage);
          encryptData(employeeName, EMPLOYEE_NAME, SessionStorage);
          encryptData(employeeDesign, EMPLOYEE_DESIGNATION, SessionStorage);
          encryptData(resetPassword, RESETPASSWORD, SessionStorage);
          encryptData("1", LOGIN_TYPE, SessionStorage);
          encryptData(userId, "loginId", SessionStorage);
          encryptData(employeeId, "EmplId", SessionStorage);
          // encryptData(imagePath, "Image", SessionStorage)
          if (userType === "1") {
            // props.history.push("/pages/academics/student/studentstatus");
            props.history.push(`/pages/academics/student/studentprofile`);
          } else {
            props.history.push("/pages/dashboard");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fieldsPanel = (
    <Fragment>
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-mobile"
        label="CNIC / Reg#"
        name="loginId"
        placeholder="Enter CNIC / Reg#"
        onChange={handleAddChange}
        value={FormFields?.loginId}
        required
      />
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-lock"
        label="Password"
        name="loginPswd"
        placeholder="Enter Password"
        type="password"
        onChange={handleAddChange}
        value={FormFields?.loginPswd}
        required
      />
    </Fragment>
  );

  const fieldEmployeePanel = (
    <Fragment>
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-mobile"
        label="Login ID"
        name="loginId"
        placeholder="Enter Login ID"
        onChange={handleAddChange}
        value={FormFields?.loginId}
        required
      />
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-lock"
        label="Password"
        name="loginPswd"
        placeholder="Enter Password"
        type="password"
        onChange={handleAddChange}
        value={FormFields?.loginPswd}
        required
      />
    </Fragment>
  );

  return (
    <LoginFormComponent
      fieldsPanel={fieldsPanel}
      fieldEmployeePanel={fieldEmployeePanel}
      formSubmit={submitForm}
      initialFormFields={initialFormFields}
      loginType="Existing"
    />
  );
};

export default Login;
