import React, { forwardRef, Fragment, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import logoimg from "../../assets/img/HBL-1-logo.png";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { Admission_ADM_UG_VoucherDetails } from "../../utils/Config";
import { decryptData } from "../../EncryptData";
import { Search, SessionStorage } from "../../common/SetupMasterEnum";
import { REG_ID } from "../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import { formatDateFunc } from "../../functions/DateFormatFunction";
import Logo from "../../assets/img/logo.png";

const AdmissionChallan = forwardRef((props, ref) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    getCadidateChallan();
  }, []);

  const getCadidateChallan = () => {
    let payload = {
      operationId: Search,
      admregId: decryptData(REG_ID, SessionStorage),
    };

    Admission_ADM_UG_VoucherDetails(payload)
      .then((res) => {
        let FeeChallanData = {
          name: "FeeChallanData",
          value: res?.data?.Table[0],
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: FeeChallanData,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="landscape-page" ref={ref}>
      {/* <Container fluid> */}
      <div style={{ margin: "10px", marginTop: "1px" }} className="card-div">
        <table>
          <tbody>
            <tr>
              <td>
                <div>
                  <Table
                    bordered
                    responsive
                    style={{
                      width: "100%",
                      border: "1px solid",
                      lineHeight: "0.30",
                      fontSize: "10px",
                    }}
                  >
                    <tbody>
                      <tr className="text-center">
                        <td colSpan={7}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <div className="login-brand mt-1">
                              <QRCode
                                value={`${SupportingTables?.FeeChallanData?.Challan}`}
                                size="60"
                              />
                            </div>
                            <div className="login-brand">
                              <img height="70" src={Logo} alt="Logo" />
                              {/* <img src={logoimg} height="70" /> */}
                            </div>
                          </div>
                        </td>
                        {/* <td colSpan={4}>
                            <div className="login-brand mt-2">
                              <img src={logoimg} height="70" />
                            </div>
                          </td> */}
                      </tr>
                      <tr>
                        <td colSpan={7}>Bank Copy</td>
                      </tr>
                      <tr>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                          colSpan={7}
                        >
                          Bank Challan
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            justifyContent: "center",
                            paddingBottom: "2px",
                          }}
                          className="text-center"
                        >
                          Date of Issue
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            width: "50%",
                            textAlign: "center",
                            fontWeight: "bold",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {formatDateFunc(
                            SupportingTables?.FeeChallanData?.DateOfIssue,
                            "-"
                          )}
                        </td>
                        <td
                          colSpan={3}
                          rowSpan={2}
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            textAlign: "center",
                            lineHeight: "10px",
                            justifyContent: "center",
                          }}
                        >
                          No: {SupportingTables?.FeeChallanData?.Challan}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            justifyContent: "center",
                            paddingBottom: "2px",
                          }}
                          className="text-center"
                        >
                          Due Date
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            width: "50%",
                            textAlign: "center",
                            fontWeight: "bold",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {formatDateFunc(
                            SupportingTables?.FeeChallanData?.DueDate,
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center" colSpan={7}>
                          <p style={{ marginTop: "18px", fontSize: "12px" }}>
                            Please deposite your fees through HBL connect,{" "}
                          </p>
                          <p> HBL Mobile Banking. </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          Name: {SupportingTables?.FeeChallanData?.Name}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          S/O,D/O:{" "}
                          {SupportingTables?.FeeChallanData?.FatherName}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          Department:{" "}
                          {SupportingTables?.FeeChallanData?.Department}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }} colSpan={2}>
                          Signature:
                        </td>
                        {/* <td colSpan={2}></td> */}
                        <td style={{ width: "10%" }} colSpan={2}>
                          Program:
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            width: "50%",
                            fontSize: 10,
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          {SupportingTables?.FeeChallanData?.Program}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          C.N.I.C/I.D Card No:/Admission No:{" "}
                          {SupportingTables?.FeeChallanData?.Cnic}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={4}> Form Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          {/* <div > */}
                          {
                            SupportingTables?.FeeChallanData
                              ?.ApplicationProcessingFee
                          }
                          {/* </div> */}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}> Admission Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={4}> Late Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{ fontWeight: "bold", textAlign: "end" }}
                          colSpan={4}
                        >
                          Total Amount =
                        </td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          {
                            SupportingTables?.FeeChallanData
                              ?.ApplicationProcessingFee
                          }
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ fontWeight: "bold", fontSize: "10px" }}
                          colSpan={7}
                        >
                          Rupees (in words) :{" "}
                          {SupportingTables?.FeeChallanData?.AmInWords}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <table
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{ textAlign: "left", width: "50px" }}
                          colSpan={2}
                        >
                          <p style={{ marginTop: "5px", marginBottom: "0" }}>
                            Cashier
                          </p>
                        </td>
                        <td
                          style={{ textAlign: "right", width: "50px" }}
                          colSpan={2}
                        >
                          <p style={{ marginTop: "5px", marginBottom: "0" }}>
                            Manager{" "}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>

              <div style={{ margin: "3px" }}></div>

              <td>
                <div>
                  <Table
                    bordered
                    responsive
                    style={{
                      width: "100%",
                      border: "1px solid",
                      lineHeight: "0.30",
                      fontSize: "10px",
                    }}
                  >
                    <tbody>
                      <tr className="text-center">
                        <td colSpan={7}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <div className="login-brand mt-1">
                              <QRCode
                                value={`${SupportingTables?.FeeChallanData?.Challan}`}
                                size="60"
                              />
                            </div>
                            <div className="login-brand">
                              <img height="70" src={Logo} alt="Logo" />
                              {/* <img src={logoimg} height="70" /> */}
                            </div>
                          </div>
                        </td>
                        {/* <td colSpan={4}>
                            <div className="login-brand mt-2">
                              <img src={logoimg} height="70" />
                            </div>
                          </td> */}
                      </tr>
                      <tr>
                        <td colSpan={7}>University Copy</td>
                      </tr>
                      <tr>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                          colSpan={7}
                        >
                          Bank Challan
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            justifyContent: "center",
                            paddingBottom: "2px",
                          }}
                          className="text-center"
                        >
                          Date of Issue
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            width: "50%",
                            textAlign: "center",
                            fontWeight: "bold",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {formatDateFunc(
                            SupportingTables?.FeeChallanData?.DateOfIssue,
                            "-"
                          )}
                        </td>
                        <td
                          colSpan={3}
                          rowSpan={2}
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            textAlign: "center",
                            lineHeight: "10px",
                            justifyContent: "center",
                          }}
                        >
                          No: {SupportingTables?.FeeChallanData?.Challan}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            justifyContent: "center",
                            paddingBottom: "2px",
                          }}
                          className="text-center"
                        >
                          Due Date
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            width: "50%",
                            textAlign: "center",
                            fontWeight: "bold",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {formatDateFunc(
                            SupportingTables?.FeeChallanData?.DueDate,
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-center" colSpan={7}>
                          <p style={{ marginTop: "18px", fontSize: "12px" }}>
                            Please deposite your fees through HBL connect,{" "}
                          </p>
                          <p> HBL Mobile Banking. </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          Name: {SupportingTables?.FeeChallanData?.Name}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          S/O,D/O:{" "}
                          {SupportingTables?.FeeChallanData?.FatherName}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          Department:{" "}
                          {SupportingTables?.FeeChallanData?.Department}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }} colSpan={2}>
                          Signature:
                        </td>
                        {/* <td colSpan={2}></td> */}
                        <td style={{ width: "10%" }} colSpan={2}>
                          Program:
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            width: "50%",
                            fontSize: 11,
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          {SupportingTables?.FeeChallanData?.Program}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          C.N.I.C/I.D Card No:/Admission No:{" "}
                          {SupportingTables?.FeeChallanData?.Cnic}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={4}> Form Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          {/* <div > */}
                          {
                            SupportingTables?.FeeChallanData
                              ?.ApplicationProcessingFee
                          }
                          {/* </div> */}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}> Admission Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={4}> Late Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{ fontWeight: "bold", textAlign: "end" }}
                          colSpan={4}
                        >
                          Total Amount =
                        </td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          {
                            SupportingTables?.FeeChallanData
                              ?.ApplicationProcessingFee
                          }
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ fontWeight: "bold", fontSize: "10px" }}
                          colSpan={7}
                        >
                          Rupees (in words) :{" "}
                          {SupportingTables?.FeeChallanData?.AmInWords}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <table
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{ textAlign: "left", width: "50px" }}
                          colSpan={2}
                        >
                          <p style={{ marginTop: "5px", marginBottom: "0" }}>
                            Cashier
                          </p>
                        </td>
                        <td
                          style={{ textAlign: "right", width: "50px" }}
                          colSpan={2}
                        >
                          <p style={{ marginTop: "5px", marginBottom: "0" }}>
                            Manager{" "}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>

              <div style={{ margin: "3px" }}></div>

              <td>
                <div>
                  <Table
                    bordered
                    responsive
                    style={{
                      width: "100%",
                      border: "1px solid",
                      lineHeight: "0.30",
                      fontSize: "10px",
                    }}
                  >
                    <tbody>
                      <tr className="text-center">
                        <td colSpan={7}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <div className="login-brand mt-1">
                              <QRCode
                                value={`${SupportingTables?.FeeChallanData?.Challan}`}
                                size="60"
                              />
                            </div>
                            <div className="login-brand">
                              <img height="70" src={Logo} alt="Logo" />
                              {/* <img src={logoimg} height="70" /> */}
                            </div>
                          </div>
                        </td>
                        {/* <td colSpan={4}>
                            <div className="login-brand mt-2">
                              <img src={logoimg} height="70" />
                            </div>
                          </td> */}
                      </tr>
                      <tr>
                        <td colSpan={7}>Student Copy</td>
                      </tr>
                      <tr>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                          colSpan={7}
                        >
                          Bank Challan
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            justifyContent: "center",
                            paddingBottom: "2px",
                          }}
                          className="text-center"
                        >
                          Date of Issue
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            width: "50%",
                            textAlign: "center",
                            fontWeight: "bold",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {formatDateFunc(
                            SupportingTables?.FeeChallanData?.DateOfIssue,
                            "-"
                          )}
                        </td>
                        <td
                          colSpan={3}
                          rowSpan={2}
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            textAlign: "center",
                            lineHeight: "10px",
                            justifyContent: "center",
                          }}
                        >
                          No: {SupportingTables?.FeeChallanData?.Challan}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            width: "20%",
                            textAlign: "center",
                            justifyContent: "center",
                            paddingBottom: "2px",
                          }}
                          className="text-center"
                        >
                          Due Date
                        </td>
                        <td
                          colSpan={3}
                          style={{
                            width: "50%",
                            textAlign: "center",
                            fontWeight: "bold",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          {formatDateFunc(
                            SupportingTables?.FeeChallanData?.DueDate,
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-center" colSpan={7}>
                          <p style={{ marginTop: "18px", fontSize: "12px" }}>
                            Please deposite your fees through HBL connect,{" "}
                          </p>
                          <p> HBL Mobile Banking. </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          Name: {SupportingTables?.FeeChallanData?.Name}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          S/O,D/O:{" "}
                          {SupportingTables?.FeeChallanData?.FatherName}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          Department:{" "}
                          {SupportingTables?.FeeChallanData?.Department}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "10%" }} colSpan={2}>
                          Signature:
                        </td>
                        {/* <td colSpan={2}></td> */}
                        <td style={{ width: "10%" }} colSpan={2}>
                          Program:
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            width: "50%",
                            fontSize: 10,
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          {SupportingTables?.FeeChallanData?.Program}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }} colSpan={7}>
                          C.N.I.C/I.D Card No:/Admission No:{" "}
                          {SupportingTables?.FeeChallanData?.Cnic}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={4}> Form Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          {/* <div > */}
                          {
                            SupportingTables?.FeeChallanData
                              ?.ApplicationProcessingFee
                          }
                          {/* </div> */}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}> Admission Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={4}> Late Fee</td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{ fontWeight: "bold", textAlign: "end" }}
                          colSpan={4}
                        >
                          Total Amount =
                        </td>
                        <td
                          colSpan={4}
                          style={{ fontSize: "10px", fontWeight: "bold" }}
                        >
                          {
                            SupportingTables?.FeeChallanData
                              ?.ApplicationProcessingFee
                          }
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ fontWeight: "bold", fontSize: "10px" }}
                          colSpan={7}
                        >
                          Rupees (in words) :{" "}
                          {SupportingTables?.FeeChallanData?.AmInWords}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <table
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{ textAlign: "left", width: "50px" }}
                          colSpan={2}
                        >
                          <p style={{ marginTop: "5px", marginBottom: "0" }}>
                            Cashier
                          </p>
                        </td>
                        <td
                          style={{ textAlign: "right", width: "50px" }}
                          colSpan={2}
                        >
                          <p style={{ marginTop: "5px", marginBottom: "0" }}>
                            Manager{" "}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* </Container> */}
    </div>
  );
});

export default AdmissionChallan;

{
  /* <Col lg="3" md="3" xs="12">
<div>
  <Table
    bordered
    responsive
    style={{ width: "100%", border: "1px solid" }}
  >
    <tbody>
      <tr>
        <td colSpan={4}>University Copy</td>
      </tr>
      <tr className="text-center">
        <td colSpan={4}>
          <div className="login-brand">
            <img src={logoimg} height="70" />
          </div>
          <div style={{ fontWeight: "bold" }}>BANK CHALLAN</div>
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="text-center">
          Date of Issue / Print
        </td>
        <td colSpan={2} style={{ width: "50%" }}></td>
      </tr>
      <tr>
        <td colSpan={2} className="text-center">
          Due Date
        </td>
        <td colSpan={2} style={{ width: "50%" }}></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={2}>
          Date of Payment
        </td>
        <td style={{ fontWeight: "bold" }}>Challan #</td>
        <td style={{ width: "25%" }}></td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={4}>Please Received and Credit to SALU</td>
      </tr>
      <tr>
        <td colSpan={4} style={{ fontWeight: "bold" }}>
          A/C No: 00427991829703
        </td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={2}>Name:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>F/Name:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Department:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Contact#:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Address:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>C.N.I.C/I.D Card No:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={3}>Application Processing Fee</td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={3}>Admission Fee</td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={3}>Late Fee:</td>
        <td></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={3}>
          Total Amount
        </td>
        <td></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={3}>
          Rupees (in words)
        </td>
        <td></td>
      </tr>
    </tbody>
  </Table>
  <Row className="mt-5 mb-4">
    <Col lg="6" md="6" xs="12">
      <div>
        <p>Depositor's Signature</p>
      </div>
    </Col>
    <Col lg="6" md="6" xs="12">
      <div className="text-right">
        <p>Authorized Signature</p>
      </div>
    </Col>
  </Row>
</div>
</Col>
<Col lg="3" md="3" xs="12">
<div>
  <Table
    bordered
    responsive
    style={{ width: "100%", border: "1px solid" }}
  >
    <tbody>
      <tr>
        <td colSpan={4}>Admin Copy</td>
      </tr>
      <tr className="text-center">
        <td colSpan={4}>
          <div className="login-brand">
            <img src={logoimg} height="70" />
          </div>
          <div style={{ fontWeight: "bold" }}>BANK CHALLAN</div>
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="text-center">
          Date of Issue / Print
        </td>
        <td colSpan={2} style={{ width: "50%" }}></td>
      </tr>
      <tr>
        <td colSpan={2} className="text-center">
          Due Date
        </td>
        <td colSpan={2} style={{ width: "50%" }}></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={2}>
          Date of Payment
        </td>
        <td style={{ fontWeight: "bold" }}>Challan #</td>
        <td style={{ width: "25%" }}></td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={4}>Please Received and Credit to SALU</td>
      </tr>
      <tr>
        <td colSpan={4} style={{ fontWeight: "bold" }}>
          A/C No: 00427991829703
        </td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={2}>Name:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>F/Name:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Department:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Contact#:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Address:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>C.N.I.C/I.D Card No:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={3}>Application Processing Fee</td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={3}>Admission Fee</td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={3}>Late Fee:</td>
        <td></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={3}>
          Total Amount
        </td>
        <td></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={3}>
          Rupees (in words)
        </td>
        <td></td>
      </tr>
    </tbody>
  </Table>
  <Row className="mt-5 mb-4">
    <Col lg="6" md="6" xs="12">
      <div>
        <p>Depositor's Signature</p>
      </div>
    </Col>
    <Col lg="6" md="6" xs="12">
      <div className="text-right">
        <p>Authorized Signature</p>
      </div>
    </Col>
  </Row>
</div>
</Col>
<Col lg="3" md="3" xs="12">
<div>
  <Table
    bordered
    responsive
    style={{ width: "100%", border: "1px solid" }}
  >
    <tbody>
      <tr>
        <td colSpan={4}>Student Copy</td>
      </tr>
      <tr className="text-center">
        <td colSpan={4}>
          <div className="login-brand">
            <img src={logoimg} height="70" />
          </div>
          <div style={{ fontWeight: "bold" }}>BANK CHALLAN</div>
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="text-center">
          Date of Issue / Print
        </td>
        <td colSpan={2} style={{ width: "50%" }}></td>
      </tr>
      <tr>
        <td colSpan={2} className="text-center">
          Due Date
        </td>
        <td colSpan={2} style={{ width: "50%" }}></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={2}>
          Date of Payment
        </td>
        <td style={{ fontWeight: "bold" }}>Challan #</td>
        <td style={{ width: "25%" }}></td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={4}>Please Received and Credit to SALU</td>
      </tr>
      <tr>
        <td colSpan={4} style={{ fontWeight: "bold" }}>
          A/C No: 00427991829703
        </td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={2}>Name:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>F/Name:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Department:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Contact#:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>Address:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={2}>C.N.I.C/I.D Card No:</td>
        <td colSpan={2}></td>
      </tr>
      <tr>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={3}>Application Processing Fee</td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={3}>Admission Fee</td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={3}>Late Fee:</td>
        <td></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={3}>
          Total Amount
        </td>
        <td></td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold" }} colSpan={3}>
          Rupees (in words)
        </td>
        <td></td>
      </tr>
    </tbody>
  </Table>
  <Row className="mt-5 mb-4">
    <Col lg="6" md="6" xs="12">
      <div>
        <p>Depositor's Signature</p>
      </div>
    </Col>
    <Col lg="6" md="6" xs="12">
      <div className="text-right">
        <p>Authorized Signature</p>
      </div>
    </Col>
  </Row>
</div>
</Col> */
}
