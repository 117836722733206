import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  TabPane,
  TabContent,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import Progressbar from "../../../components/CircleProgressComponent/Progressbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Progress } from "reactstrap";
import Scrollbars from "react-custom-scrollbars";
import { TMS_Attendance_Stats } from "../../../utils/Config";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../EncryptData";
import { SessionStorage } from "../../../common/SetupMasterEnum";

const initialSelectionList = {
  AttendanceList: [],
  TimeHistory: [],
  TodayActivity: [],
  timeSheet: [],
  StatsList: [],
  Holidays: [],
  RecentLeave: [],
};
const AttendanceStats = () => {
  useEffect(() => {
    getAttendanceStatsData();
  }, []);

  const [activeTab, setActiveTab] = useState("1");
  const [dropdownList, setDropdownList] = useState(initialSelectionList);

  function getAttendanceStatsData() {
    TMS_Attendance_Stats(decryptData("EmplId", SessionStorage))
      .then((res) => {
        setDropdownList({
          ...dropdownList,
          AttendanceList: res?.data?.Table,
          TimeHistory: res?.data?.Table1,
          TodayActivity: res?.data?.Table2,
          timeSheet: res?.data?.Table3,
          StatsList: res?.data?.Table4,
          Holidays: res?.data?.Table5,
          RecentLeave: res?.data?.Table6,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const columns = [
    { field: "DateData", name: "Date" },
    { field: "ShiftStartTime", name: "Shift Time" },
    { field: "PunchIn", name: "Punch In" },
    { field: "PunchOut", name: "Punch Out" },
    { field: "Total_Hours", name: "Worked Hours" },
    { field: "LessHours", name: "Excess/Less Hours" },
  ];
  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <Col>
            <Row>
              {dropdownList.TimeHistory?.map((item, ind) => {
                return (
                  <Col lg="4" md="4" xs="12" key={ind}>
                    <Card className="mt-1">
                      <CardBody>
                        <Row style={{ display: "flex", alignItems: "center" }}>
                          <Col lg="6" md="6" xs="12">
                            <p className="m-0">{item.TotalCount}</p>
                          </Col>
                          <Col lg="6" md="6" xs="12" className="text-right">
                            <h2 className={`color-${item.Color}`}>
                              {item.Countof}
                            </h2>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="4" xs="12">
            <Card className="rmv-hover mt-1">
              <CardBody>
                <h6>
                  <span className="color-skyblue"> Timesheet </span>{" "}
                  {dropdownList.timeSheet[0]?.DateDataCon}
                </h6>
                <div
                  className="mt-2"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px",
                    marginBottom: "6px",
                  }}
                >
                  <h6 className="m-0">Punch In at</h6>
                  <p className="m-0">{dropdownList.timeSheet[0]?.PunchIn}</p>
                </div>

                <div
                  className="process bar pt-3 pb-1"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Progressbar
                    diff={dropdownList.timeSheet[0]?.DiffrenecHrs}
                    total={dropdownList.timeSheet[0]?.Total_Hours}
                  />
                </div>

                <hr />
                <Row>
                  <Col lg="12" md="12" xs="12" className="text-center">
                    <h6 className="m-0">Overtime</h6>
                    <p>{dropdownList.timeSheet[0]?.OverTime}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="4" xs="12">
            <Card className="mt-1">
              <CardBody>
                <h6>Statistics</h6>
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px",
                    marginBottom: "6px",
                  }}
                >
                  <Row>
                    <Col lg="6"> Today</Col>
                    <Col lg="6" className="text-end">
                      {dropdownList.StatsList[0]?.TodayDiff} /{" "}
                      {dropdownList.StatsList[0]?.Today} hrs
                    </Col>
                  </Row>

                  <Progress value={80} style={{ height: "8px" }} color="info" />
                </div>

                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px",
                    marginBottom: "6px",
                  }}
                >
                  <Row>
                    <Col lg="6"> This Week</Col>
                    <Col lg="6" className="text-end">
                      {dropdownList.StatsList[0]?.WeeklyDiff} /{" "}
                      {dropdownList.StatsList[0]?.Weekly_Hours} hrs
                    </Col>
                  </Row>
                  <Progress
                    value={70}
                    style={{ height: "8px" }}
                    color="success"
                  />
                </div>

                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px",
                    marginBottom: "6px",
                  }}
                >
                  <Row>
                    <Col lg="6"> This Month</Col>
                    <Col lg="6" className="text-end">
                      {dropdownList.StatsList[0]?.MonthlyDiff} /{" "}
                      {dropdownList.StatsList[0]?.Monthly_Hours} hrs
                    </Col>
                  </Row>
                  <Progress
                    value={40}
                    style={{ height: "8px" }}
                    color="warning"
                  />
                </div>

                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px",
                    marginBottom: "6px",
                  }}
                >
                  <Row>
                    <Col lg="6"> Remaining</Col>
                    <Col lg="6" className="text-end">
                      {dropdownList.StatsList[0]?.MonthlyDiff} /{" "}
                      {dropdownList.StatsList[0]?.Monthly_Hours} hrs
                    </Col>
                  </Row>
                  <Progress
                    value={50}
                    style={{ height: "8px" }}
                    color="danger"
                  />
                </div>

                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px",
                  }}
                >
                  <Row>
                    <Col lg="6"> Overtime</Col>
                    <Col lg="6" className="text-end">
                      {dropdownList.StatsList[0]?.Monthly_Hours} hrs
                    </Col>
                  </Row>
                  <Progress
                    value={10}
                    style={{ height: "8px" }}
                    color="default"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="4" xs="12">
            <Card className="mt-1">
              <CardBody className="">
                <Scrollbars style={{ height: "302px" }}>
                  <h6>Today’s Activity</h6>
                  <div className="mb-2">
                    <p className="m-0">
                      <strong>Punch In at</strong>
                    </p>
                    <span>{dropdownList.TodayActivity[0]?.PunchIn}</span>
                  </div>
                  <div className="mb-2">
                    <p className="m-0">
                      <strong>Punch Out at</strong>
                    </p>
                    <span>{dropdownList.TodayActivity[0]?.PunchOut}</span>
                  </div>
                </Scrollbars>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="8" md="8" xs="12">
            <Card className="mt-1">
              <CardBody>
                <h6>Attendance List</h6>
                <FormGroupTable
                  columns={columns}
                  rows={dropdownList.AttendanceList}
                  hideAction={true}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="4" xs="12">
            <Card className="rmv-hover mt-1">
              <CardBody>
                <Nav pills>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => setActiveTab("1")}
                    >
                      Holidays
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => setActiveTab("2")}
                    >
                      Recent Leaves
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  className="tab-content-inner stats"
                  activeTab={activeTab}
                >
                  <TabPane tabId="1">
                    {dropdownList.Holidays.map((item, ind) => {
                      return (
                        <div
                          key={ind}
                          className="pt-2 pb-2"
                          style={{ borderBottom: "1px solid #ccc" }}
                        >
                          <p className="m-0">{item.Date_}</p>
                          <span className="badge badge-info">
                            {item.ShiftDayTypeName}
                          </span>
                        </div>
                      );
                    })}
                  </TabPane>
                  <TabPane tabId="2">
                    {dropdownList.RecentLeave.map((item, ind) => {
                      return (
                        <div
                          key={ind}
                          className="pt-2 pb-2"
                          style={{ borderBottom: "1px solid #ccc" }}
                        >
                          <p className="m-0">{item.Date_}</p>
                          <span className="badge badge-info">{item.ShiftDayTypeName}</span>
                        </div>
                      );
                    })}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AttendanceStats;
