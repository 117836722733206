import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupCheckbox from "../../../../components/GeneralComponent/FormGroupCheckbox";
import { decryptData } from "../../../../EncryptData";
import { Select, SessionStorage } from "../../../../common/SetupMasterEnum";
import { UserNetworkInfo } from "../../../../utils/EncryptedConstants";
import {
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
} from "../../../../redux/actionType/CrudActionTypes";

const OtherInfo = (props) => {

  const { FormFields, SupportingTables,  SupportingTables: {TaxData} } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { partyTaxSection_ = [], setpartyTaxSection_ = () => {}, FormId } = props;

  useEffect(() => {
    setpartyTaxSection_({...partyTaxSection_, ...TaxData?.[0]})
  },[TaxData?.[0]]);
 
  const handleAddChange = (e) => {
    setpartyTaxSection_({
      ...partyTaxSection_,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            label="Ntn :"
            name="NTN"
            maxLength={30}
            value={partyTaxSection_?.NTN}
            onChange={handleAddChange}
            required
            form={FormId}
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            label="Gst #:"
            name="GST"
            maxLength={30}
            value={partyTaxSection_?.GST}
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="2" md="2" xs="12">
          <FormGroupCheckbox
            label="FILER"
            name="IsFiler"
            value={partyTaxSection_?.IsFiler}
            onChange={handleAddChange}
          />
        </Col>
        <Col lg="2" md="2" xs="12">
          <FormGroupCheckbox
            label="GST Exempted"
            name="IsGSTExempt"
            value={partyTaxSection_?.IsGSTExempt}
            onChange={handleAddChange}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default OtherInfo;
