import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Spinner from "react-spinkit";
import propTypes from "prop-types";
import { Fragment } from "react";
import useFade from "../../../Hooks/useFade";
import { useEffect } from "react";

import custom from "../../../assets/css/custom.css"

const AcademicFormComponent = (props) => {
  const {
    featureList,
    SemesterName,
    topColumns,
    columns,
    rows,
    formName,
    searchPanel,
    searchSubmit,
    cancelSearch,
    hideSerialNumber,
    onClickRow,
    customTable,
    formLoader,
    onClickSave,
    tableColorCode,
  } = props;

  const dispatch = useDispatch();
  const { TableList } = useSelector((state) => state.CrudFormReducer);

  

  const [showSupply, setShowSupply, fadeOutProps] = useFade(false, 700);

  const handleClickRow = (e, type, index, obj) => {
    e.preventDefault();
    onClickRow(type, index, obj);
  };

  const handleClickSave = (e) => {
    e.preventDefault();
    onClickSave();
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchSubmit();
  };

  const handleSearchCancel = (e) => {
    e.preventDefault();
    cancelSearch && cancelSearch();
  };

  useEffect(() => {
    setShowSupply(true);
  }, []);

  return (
    <Fragment>
      {showSupply && (
        <Container {...fadeOutProps} fluid>
          {searchPanel && (
            <Fragment>
              {/* {featureList.some((x) => x.FeatureId === 6) ? ( */}
              <Card className="mt-3">
                <CardTitle>Search {formName}</CardTitle>
                <CardBody>
                  <form onSubmit={handleSearchSubmit}>
                    <Row>
                      {searchPanel}
                      <Col lg="12" md="12" xs="12" className="text-right">
                        <Button color="primary" className="btn" type="submit">
                          Search
                        </Button>
                        <Button
                          className="btn"
                          color="default"
                          onClick={handleSearchCancel}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
              {/* ) : null} */}
            </Fragment>
          )}
          <Card className="mt-3">
            <CardTitle
              style={{
                backgroundColor: "#772647",
                color: "white",
                textAlign: "center",
                borderTopRightRadius:"14px",
                borderTopLeftRadius :"14px"
              }}
            >
              {SemesterName}
            </CardTitle>
            <CardBody>
              <Row>
                <Col>
                  {topColumns?.length > 0 && (
                    <Table style={{ marginBottom: 16 }} bordered responsive>
                      <tbody>
                        <tr>
                          {topColumns &&
                            Object.keys(topColumns[0])?.map((column, index) => (
                              <Fragment key={index}>
                                <td
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    fontWeight: "bold",
                                  }}
                                  key={index + 1}
                                >
                                  {column}
                                </td>
                                <td
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                  }}
                                  key={index + 2}
                                >
                                  {topColumns[0][column]}
                                </td>
                              </Fragment>
                            ))}
                        </tr>
                      </tbody>
                    </Table>
                  )}

                  {/* <br /> */}
                  {!customTable && (
                    <Fragment>
                      {formLoader ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            className="spinner-custom"
                            name="rotating-plane"
                          />
                        </div>
                      ) : (
                        <Table bordered responsive striped>
                          <thead>
                            <tr>
                              {hideSerialNumber === true ? null : (
                                <th>S.No.</th>
                              )}
                              {columns &&
                                columns?.map((column, index) => (
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                    key={index}
                                  >
                                    {column?.name}
                                  </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody>
                            {rows?.length > 0 &&
                              rows?.map((row, index) => (
                                <tr key={index} style={custom}>
                                  {hideSerialNumber === true ? null : (
                                    <td
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        color: tableColorCode
                                          ? "#4473c5"
                                          : "#000",
                                      }}
                                    >
                                      {index + 1}
                                    </td>
                                  )}
                                  {columns &&
                                    columns?.map((column, ind) => (
                                      <td
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                        }}
                                        key={ind}
                                      >
                                        <a
                                          className={
                                            tableColorCode
                                              ? "achorColorBlue"
                                              : "achorColorBlack"
                                          }
                                          href=""
                                          onClick={(e) =>
                                            handleClickRow(
                                              e,
                                              row[column?.field],
                                              index,
                                              row
                                            )
                                          }
                                        >
                                          {column?.render
                                            ? column?.render
                                            : row[column?.field]}
                                        </a>
                                      </td>
                                    ))}
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      )}
                    </Fragment>
                  )}
                  {customTable && customTable}
                </Col>
              </Row>
              {onClickSave && (
                <Row>
                  <Col lg="12" md="12" xs="12" className="text-right">
                    <Button
                      className="text-right mt-3"
                      onClick={handleClickSave}
                      color="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
    </Fragment>
  );
};

AcademicFormComponent.propTypes = {
  featureList: propTypes.array,
  SemesterName: propTypes.string,
  topColumns: propTypes.array,
  columns: propTypes.array,
  rows: propTypes.array,
  formName: propTypes.string,
  searchPanel: propTypes.element,
  searchSubmit: propTypes.func,
  cancelSearch: propTypes.func,
  hideSerialNumber: propTypes.bool,
  onClickRow: propTypes.func,
  customTable: propTypes.element,
  formLoader: propTypes.bool,
  onClickSave: propTypes.func,
  tableColorCode: propTypes.bool,
};

export default AcademicFormComponent;
