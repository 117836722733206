import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Spinner,
} from "reactstrap";

import Academics from "./PostGraduateTabs/Academics";
import Documents from "./PostGraduateTabs/Documents";
import PersonalInfo from "./PostGraduateTabs/PersonalInfo";
import Research from "./PostGraduateTabs/Research";
import Undertaking from "./PostGraduateTabs/Undertaking";
import Approval from "./PostGraduateTabs/Approval";
import EligibiltyCertificate from "./PostGraduateTabs/EligibiltyCertificate";
import MigrationCertificate from "./PostGraduateTabs/MigrationCertificate";
import CheckStatus from "./PostGraduateTabs/CheckStatus";
import GATGeneral from "./PostGraduateTabs/GATGeneral";
import Employment from "./PostGraduateTabs/Employment";
import OfficeUseOnly from "./PostGraduateTabs/OfficeUseOnly";
import ResearchTopic from "./FormBPostGraduateTabs/ResearchTopic";
import CoSupervisor from "./FormBPostGraduateTabs/CoSupervisor";
import UndertakingBForm from "./FormBPostGraduateTabs/UndertakingBForm";
import ProposedOfInstitutionDepartment from "./FormBPostGraduateTabs/ProposedOfInstitutionDepartment";


const FormBPostGraduate = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  return (
    <Fragment>
      <Nav pills>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            Step 1: Research Topic
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            Step 2: Co-Supervisor
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            Step 3: Undertaking
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "4" ? "active" : ""}
            onClick={() => setActiveTab("4")}
          >
            Step 4: Proposed Institution/Department
          </NavLink>
        </NavItem>
        
      </Nav>
      <TabContent className="tab-content-inner" activeTab={activeTab}>
        <TabPane tabId="1">
          <ResearchTopic
            {...props}
          />
        </TabPane>
        <TabPane tabId="2">
          <CoSupervisor
            {...props}
          />
        </TabPane>
        <TabPane tabId="3">
          <UndertakingBForm
            {...props}
          />
        </TabPane>
        <TabPane tabId="4">
          <ProposedOfInstitutionDepartment
            {...props}
          />
        </TabPane>
        
      </TabContent>
    </Fragment>
  );
};
export default FormBPostGraduate;
