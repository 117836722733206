import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { SessionStorage } from "../../common/SetupMasterEnum";
import { decryptData } from "../../EncryptData";
import {
  formatDateFunc,
  formatDateFunction2,
} from "../../functions/DateFormatFunction";
import {
  EMPLOYEE_DESIGNATION,
  EMPLOYEE_NAME,
  LAST_NAME,
  MIDDLE_NAME,
  TYPE,
} from "../../utils/EncryptedConstants";

const Welcome = () => {
  const newDate = new Date();
  const formatDate = formatDateFunction2(newDate, " ", "long");
  return (
    <Row>
      <Col>
        <Card className="welcome-notes text-white mb-2">
          <CardBody>
            {decryptData(TYPE, SessionStorage) === 2 ? (
              <>
                <h6>Welcome {decryptData(EMPLOYEE_NAME, SessionStorage)}</h6>
                <p className="mb-0 font-size-small">{formatDate}</p>
                <p className="mb-0 font-size-small">
                  Designation:{" "}
                  <span>
                    {decryptData(EMPLOYEE_DESIGNATION, SessionStorage)}
                  </span>
                </p>
              </>
            ) : (
              <>
                {decryptData(TYPE, SessionStorage) === 1 ? (
                  <>
                    <h6>
                      Welcome {decryptData(EMPLOYEE_NAME, SessionStorage)}{" "}
                      {decryptData(MIDDLE_NAME, SessionStorage)}{" "}
                      {decryptData(LAST_NAME, SessionStorage)}
                    </h6>
                    <p className="mb-0 font-size-small">{formatDate}</p>
                    <p className="mb-0 font-size-small">
                      Department:{" "}
                      <span>
                        {decryptData(EMPLOYEE_DESIGNATION, SessionStorage)}
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <h6>
                      Welcome {decryptData(EMPLOYEE_NAME, SessionStorage)}{" "}
                      {decryptData(MIDDLE_NAME, SessionStorage)}{" "}
                      {decryptData(LAST_NAME, SessionStorage)}
                    </h6>
                    <p className="mb-0 font-size-small">{formatDate}</p>
                  </>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Welcome;
