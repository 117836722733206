import React, { forwardRef } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import Logo from "../../../assets/img/IDCard/saus-logo.png";
import Avatar from "../../../assets/img/IDCard/avatar.jpg";
import BackgroundImageFront from "../../../assets/img/IDCard/student-id-card-front.jpg";
import BackgroundImageBack from "../../../assets/img/IDCard/student-id-card-back.jpg";
import Profile from "../../../assets/img/noprofilepic.png";
import Signature from "../../../assets/img/IDCard/signature.png";
import { dataList } from "./dataList";
import { useEffect } from "react";
import { useState } from "react";
import { PostRequest } from "../../../utils/Config";
import { PRINT_IDCARD } from "../../../utils//UrlConstants";
import { Search } from "../../../common/SetupMasterEnum";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";

const IDCard = forwardRef((props, ref) => {
  const {
    TableList,
    SupportingTables: { studentList },
  } = useSelector((state) => state.CrudFormReducer);
  const [arrayLength, setArrayLength] = useState([]);

  const dispatch = useDispatch();
  const initialFormFields = {
    operationId: Search,
    cnic: "0",
    facultyDepartmendId: 0,
    facultyDepartmendProgramID: 0,
  };
  useEffect(() => {

    setTimeout(() => getIDCards2(), 500);
  }, [studentList]);

  function countAllSpace(str) {
    const position = [];

    let idx = str.indexOf(" ");

    while (idx !== -1) {
      position.push(idx);
      idx = str.indexOf(" ", idx + 1);
    }

    return position.length;
  }

  function addSpace(str) {
    return str.split("").join(" ");
  }
  const getIDCards2 = () => {
    const rows = [...Array(Math.ceil(studentList?.Table1?.length / 4))];
    const totalRows = rows?.map((row, ind) =>
      studentList?.Table1?.slice(ind * 4, ind * 4 + 4)
    );

    setArrayLength(totalRows);
  };
  // const getIDCards = () => {
  //   const payload = {
  //     operationId: Search,
  //     Cnic: "0",
  //     facultyDepartmendId: 0,
  //     facultyDepartmendProgramID: 0,
  //   };
  //   PostRequest(PRINT_IDCARD, payload)
  //     .then((res) => {
  //       const rows = [...Array(Math.ceil(res?.data?.Table?.length / 4))];

  //       // chunk the products into the array of rows
  //       const productRows = rows?.map((row, idx) =>
  //         res?.data?.Table?.slice(idx * 4, idx * 4 + 4)
  //       );
  //       setArrayLength(productRows);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const IdCardList = ({ items }) => {
    return (
      <table
        style={{
          idth: "100%",
          borderCollapse: "collapse",
          margin: "0px",
          padding: "0px",
        }}
      >
        <tbody>
          <tr>
            {items?.map((item, index) => (
              <td
                key={index}
                // style={{ padding: "50px 30px" }}
                style={{ padding: "60px 100px 60px 100px" }}
              >
                <table
                  style={{
                    width: "662px",
                    height: "1075px",
                    margin: "0 auto",
                    backgroundColor: "#ffffff",
                    backgroundImage: `url(${BackgroundImageFront})`,
                    backgroundSize: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          height: "150px",
                          padding: "20px 60px 0px",
                          verticalAlign: "top",
                        }}
                      >
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "0px 0px",
                                  verticalAlign: "top",
                                }}
                              >
                                <img
                                  style={{ height: "190px" }}
                                  src={Logo}
                                  alt="SAUS"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: "300px",
                          padding: "20px 60px",
                          textAlign: "center",
                          verticalAlign: "top",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            width: "300px",
                            height: "300px",
                            border: "4px solid #4ae7fc",
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                        >
                          {item?.Picture === "" ? (<img
                            style={{ width: "300px", height: "300px" }}
                            src={Profile}
                            alt="SAUS"
                          />) :
                          (<img
                            style={{ width: "300px", height: "300px" }}
                            src={item?.Picture}
                            alt="SAUS"
                          />)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: "140px",
                          padding: "20px 60px",
                          verticalAlign: "top",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        style={{ padding: "20px 60px", verticalAlign: "top" }}
                      >
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "10px 20px 10px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#ffffff",
                                  verticalAlign: "top",
                                }}
                              >
                                Name:
                              </td>
                              <td
                                style={{
                                  padding: "5px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#ffffff",
                                  verticalAlign: "top",
                                }}
                              >
                                {item?.Name}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "10px 20px 10px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#ffffff",
                                  verticalAlign: "top",
                                }}
                              >
                                Registration#:
                              </td>
                              <td
                                style={{
                                  padding: "5px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#ffffff",
                                  verticalAlign: "top",
                                }}
                              >
                                {item?.Reg_No}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "10px 20px 10px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#ffffff",
                                  verticalAlign: "top",
                                }}
                              >
                                Validity:
                              </td>
                              <td
                                style={{
                                  padding: "5px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#ffffff",
                                  verticalAlign: "top",
                                }}
                              >
                                {item?.Validity}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: "662px",
                    height: "1075px",
                    margin: "0 auto",
                    backgroundColor: "#ffffff",
                    backgroundImage: `url(${BackgroundImageBack})`,
                    backgroundSize: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          height: "80px",
                          padding: "20px 60px",
                          verticalAlign: "top",
                        }}
                      ></td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: "520px",
                          padding: "20px 60px",
                          verticalAlign: "top",
                        }}
                      >
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "10px 20px 10px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  verticalAlign: "top",
                                }}
                              >
                                CNIC#:
                              </td>
                              <td
                                style={{
                                  padding: "5px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  verticalAlign: "top",
                                }}
                              >
                                {item?.Cnic}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "10px 20px 10px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  verticalAlign: "top",
                                }}
                              >
                                Fathers Name:
                              </td>
                              <td
                                style={{
                                  padding: "5px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  verticalAlign: "top",
                                }}
                              >
                                {item?.FatherName}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "10px 20px 10px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  verticalAlign: "top",
                                }}
                              >
                                Address:
                              </td>
                              <td
                                style={{
                                  padding: "5px 0px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "35px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  verticalAlign: "top",
                                }}
                              >
                                {item?.PermanantAddress}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "10px 60px 0px ",
                          verticalAlign: "top",
                        }}
                      >
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "0px 20px",
                                  fontFamily: "Open Sans sans-serif",
                                  fontSize: "25px",
                                  fontWeight: "600",
                                  color: "#ffffff",
                                  verticalAlign: "middle",
                                }}
                              >
                                Issuing Authority
                                <br />
                                The Shaikh Ayaz University Shikarpur
                              </td>
                              <td
                                style={{
                                  width: "200px",
                                  padding: "0px 20px",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <span
                                  style={{
                                    display: "block",
                                    height: "120px",
                                    backgroundColor: "#ffffff",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <img
                                    style={{ height: "120px" }}
                                    src={Signature}
                                    alt="signature"
                                  />
                                </span>
                                <span
                                  style={{
                                    display: "block ",
                                    borderTop: "1px solid",
                                    padding: "4px 2px 0px",
                                    fontFamily: "Open Sans sans-serif",
                                    fontWeight: "600",
                                    fontSize: "23px",
                                    color: "#ffffff",
                                  }}
                                >
                                  Registrar Signature
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "10px 60px 0px",
                          fontFamily: "Open Sans sans-serif",
                          fontSize: "25px",
                          fontWeight: "600",
                          textAlign: "center",
                          color: "#ffffff",
                          verticalAlign: "top",
                        }}
                      >
                        The Card is property of The Shaikh aYaz University, it
                        is for identification purpose only. if found please
                        return to the shaikh ayaz university.
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: "30px",
                          padding: "20px 60px",
                          verticalAlign: "top",
                        }}
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div
      ref={ref}
      style={{
        margin: "0px",
        padding: "0px",
      }}
    >
      {arrayLength?.map((x, i) => (
        <IdCardList items={x} key={i} />
      ))}
    </div>
  );
});

export default IDCard;
