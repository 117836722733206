import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";

const initialSearchFields = {
  ProgramNameID: 0,
  TermNoID: 0,
  SubjectNameID: 0,
  SectionID: 0,
  TestItemID: 0,
  MaxMarks: "",
  Weightage: "",
};

const AssignTestMarks = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const columns = [
    { field: "RollNo", name: "Roll No" },
    { field: "Name", name: "Name" },
    { field: "Section", name: "Section" },
    { field: "Marks/Grades", name: "Marks/Grades" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Program Name"
          name="ProgramNameID"
          required
          onChange={handleSearchChange}
          value={SearchFields?.ProgramNameID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Term No"
          name="TermNoID"
          required
          onChange={handleSearchChange}
          value={SearchFields?.TermNoID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Subject Name"
          name="SubjectNameID"
          required
          onChange={handleSearchChange}
          value={SearchFields?.SubjectNameID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Section"
          name="SectionID"
          required
          onChange={handleSearchChange}
          value={SearchFields?.SectionID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Test Item"
          name="TestItemID"
          required
          onChange={handleSearchChange}
          value={SearchFields?.TestItemID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Max Marks"
          name="MaxMarks"
          required
          onChange={handleSearchChange}
          value={SearchFields?.MaxMarks}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Weightage % "
          name="Weightage"
          required
          onChange={handleSearchChange}
          value={SearchFields?.Weightage}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
  };

  const onEditRow = (obj) => {
  };

  const onDeleteRow = (obj) => {
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
  };

  return (
    <CrudFormComponent
      formName="Assign Test Marks"
      buttonName="Add"
      // hideAction={false}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
    />
  );
};

export default AssignTestMarks;
