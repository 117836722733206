import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Input, Label, Table } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import Spinner from "react-spinkit";
import { useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import AttendanceLedgerReport from "../Reports/ControllerExamination/AttendanceLedgerReport";
import { PostRequest } from "../../../utils/Config";
import {
  ACAD_EXAM_CONTROLLER_REPORTS_OPS,
  ACAD_STUDENT_ATTENDANCE_REPORT,
} from "../../../utils/UrlConstants";
import {
  academicYearId,
  partYearID,
  Search,
  semesterId,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import StudentAttendanceReport from "../Reports/HODReports/StudentAttendanceReport";
import { decryptData } from "../../../EncryptData";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";

// const initialSearchFields = {
//   operationId: Search,
//   parameterID: Update,
//   academicYearID: 5623,
//   partID: 3726,
//   semesterID: 0,
//   facultyDepartmentProgramID: 4768,
//   userID: 0,
//   userIP: 0,
// };

const StudentAttendance = () => {
  const initialSearchFields = {
    OperationId: 1,
    AcademicYear: 0,
    SemesterID: 0,
    PartID: 0,
    Name: "",
    RegNo: "",
    Fromdate: "2020-08-18T13:44:35.764Z",
    Todate: "2025-08-18T13:44:35.764Z",
    UserID: decryptData("loginId", SessionStorage),
  };

  const initialFormFields = {
    OperationId: 0,
    AcademicYear: 0,
    SemesterID: 0,
    PartID: 0,
    Name: "",
    RegNo: "",
    Fromdate: "",
    Todate: "",
    UserID: decryptData("loginId", SessionStorage),
  };

  const { SearchFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [SemesterList] = useSetupDetailList(semesterId, SearchFields?.PartID);

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  const printStudentAttendanceReport = useRef();

  const [formLoad, setFormLoad] = useState(true);

  useEffect(() => {
    getAttendanceLedger();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

  function getAttendanceLedger() {
    PostRequest(ACAD_STUDENT_ATTENDANCE_REPORT, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYear"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYear}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartID"
          onChange={handleSearchChange}
          value={SearchFields?.PartID}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="SemesterID"
          onChange={handleSearchChange}
          value={SearchFields?.SemesterID}
          list={SemesterList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.PartID == null}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Name"
          name="Name"
          onChange={handleSearchChange}
          value={SearchFields?.Name}
          isAlphabetic="true"
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Registration No"
          name="RegNo"
          onChange={handleSearchChange}
          value={SearchFields?.RegNo}
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <Label>
          From Date
          <span className="text-danger">*</span>
        </Label>
        <Input
          name="Fromdate"
          type="date"
          onChange={handleSearchChange}
          value={SearchFields?.Fromdate}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <Label>
          To Date
          <span className="text-danger">*</span>
        </Label>
        <Input
          name="Todate"
          type="date"
          onChange={handleSearchChange}
          value={SearchFields?.Todate}
        />
      </Col>
    </Fragment>
  );

  const columns = [
    { field: "NAME", name: "NAME" },
    { field: "Course", name: "Course" },
  ];

  const customTable = (
    <Fragment>
      {TableList.length > 1 ? (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>S.No.</th>
              {TableList?.length > 0
                ? Object.keys(TableList?.[0])?.map((column, index) => (
                    <Fragment key={index}>
                      <th
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        key={index + 1}
                      >
                        {column}
                      </th>
                    </Fragment>
                  ))
                : null}
            </tr>
          </thead>
          <tbody>
            {TableList &&
              TableList?.map((value, ind) => (
                <tr key={ind}>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {ind + 1}
                  </td>
                  {Object.keys(TableList[ind])?.map((column, index) => (
                    <Fragment key={index}>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          color: TableList[2][column] < 75.0 ? "red" : "Black",
                        }}
                        key={index + 2}
                      >
                        {TableList[ind][column] && TableList[ind][column] > 100
                          ? "100"
                          : TableList[ind][column]}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        "No Data Found"
      )}
    </Fragment>
  );

  const handlePrintReport = useReactToPrint({
    content: () => printStudentAttendanceReport.current,
  });

  const customButton = (
    <Fragment>
      <Button
        disabled={TableList?.length > 0 ? false : true}
        color="primary"
        onClick={handlePrintReport}
      >
        Student Attendance Report
      </Button>
      <div style={{ display: "none" }}>
        {/* <AttendanceLedgerReport ref={printStudentAttendanceReport} /> */}
        <StudentAttendanceReport ref={printStudentAttendanceReport} />
      </div>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...initialSearchFields,
      ...SearchFields,
      OperationId: 1,
    };
    PostRequest(ACAD_STUDENT_ATTENDANCE_REPORT, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: initialSearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getAttendanceLedger();
  };

  return (
    <CrudFormComponent
      formName="Student Attendance Report"
      customButton={customButton}
      hideAction={true}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
    />
  );
};

export default StudentAttendance;
