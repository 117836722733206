import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const LogoutModal = (props) => {
  const [modalState, setModalState] = useState(0);
  useEffect(() => {
    modalState !== 0 && sessionStorage.removeItem("loginId");
    setModalState(modalState + 1);
  }, [props.idleModal]);
  return (
    <>
      {
        <Modal
          centered
          // modalTransition={{ timeout: 10 }}
          isOpen={props.idleModal}
          // toggle={() => setIdleModal(false)}
        >
          <ModalHeader>Session Expire Warning</ModalHeader>
          <ModalBody>Your Session is Expired</ModalBody>
          <ModalFooter>
            <Button color="info" onClick={(e) => props.signOut(e)}>
              Logout
            </Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default LogoutModal;
