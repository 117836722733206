import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { SessionStorage } from "../../common/SetupMasterEnum";
import { CustomErrorMessage } from "../../components/Alert";
import { decryptData } from "../../EncryptData";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import {
  Admission_GetStudendAdmissionRecord,
  Setup_MasterDetails_All_Dropdowns,
} from "../../utils/Config";
import { REG_ID } from "../../utils/EncryptedConstants";
import UnderGraduate from "./UnderGraduate";

const AdmissionForm = () => {
  const dispatch = useDispatch();
  const [displayPage, setDisplayPage] = useState(false);
  const programId = 3479;
  useEffect(() => {
    getMasterDetailAllDropdown();
    getStudendRecords();
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getStudendRecords() {
    
    Admission_GetStudendAdmissionRecord(decryptData(REG_ID, SessionStorage), 3479)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
          setDisplayPage(false);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          setDisplayPage(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Fragment>
      <Container fluid>
        <Card className="mt-3">
          <CardBody>
            <Row>
              <Col>
                {displayPage === true ? (
                  <UnderGraduate Programid={programId} />
                ) : null}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AdmissionForm;
