import React, { Fragment, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Search, Select, SessionStorage } from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../EncryptData";
import {
  Admission_ADMUploadFeeChallan,
  Admission_ADM_CandidateStatus,
  ADM_ADM_OfferedPrograms,
  ADM_PrintTestSlip,
  ADM_PrintVoucher,
  PostRequest,
} from "../../utils/Config";
import {
  ADMISSION_USER_ID,
  REG_ID,
  UserNetworkInfo,
} from "../../utils/EncryptedConstants";
import { useReactToPrint } from "react-to-print";
import AdmissionChallan from "./AdmissionChallan";
import AdmissionReportPrint from "../Admissions/AdmissionReportPrint";
import AdmitCard from "./AdmitCard";
import PostgraduateAdmissionForm from "./PostgraduateAdmissionForm";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import GeneralLedgerReport from "../Finance/Reports/GeneralLedgerReport";
import ReceivablesAndPayblesReport from "../Finance/Reports/ReceivablesAndPayablesReport";
import ReceiptForm from "./ReceiptForm";
import TestSlip from "./TestSlip";
import PrintOfferLetter from "./PrintOfferLetter";
import ChallanForAdmisison from "../Admissions/ChallanForAdmission";
import IDCard from "./ID_Card/IDCard";
import {
  ADMISSION_GET_REGISTER_RECORDS,
  ADM_PRINT_VOUCHER,
} from "../../utils/UrlConstants";

const initialFormFields = {
  OperationID: 2,
  ADMRegID: decryptData(REG_ID, SessionStorage),
  FeeTypeID: 3643,
  FileName: {},
  DocumentPath: "string",
  PaymentStatusID: 3519,
  CreatedBy: decryptData(REG_ID, SessionStorage),
  ModifiedBy: decryptData(REG_ID, SessionStorage),
  IsActive: true,
  UserIP: "string",
};

const candidateStatusFields = {
  ADMRegID: 0,
  ADMType: "",
  ADMYear: "",
  ApplicationID: "",
  ApplicationStatus: "",
  Campus: "",
  CampusCity: "",
  Department: "",
  DocsToPrint: "",
  Faculty: "",
  InterViewStatus: "",
  OfficeComment: "",
  Program: "",
  ProgramType: "",
  Qouta: "",
  Seattype: "",
  TestID: "",
  TestStatus: "",
  ViewChallan: "",
  WhatIsNext: "",
  FeeStatus: "",
  FeeStatusCheckerComment: "",
  ChallanRemarks: "",
  Paid_upaid: "",
  FinalSubmit: "",
  AppliedProgram: "",
  ProgramId: 0,
};

const AdmissionCandidateStatus = (props) => {
  const { SupportingTables, SupportingTables: {AdmissionVoucherData} } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const componentReference = useRef();
  const anotherRefrence = useRef();
  const undergraduateRefrence = useRef();
  const receiptForm = useRef();
  const generateTestSlip = useRef();
  const printOfferLetter = useRef();
  const challanForAdmission = useRef();

  const handlePrintChallanForAdmission = useReactToPrint({
    content: () => challanForAdmission.current,
  });

  const handlePrintOfferLetter = useReactToPrint({
    content: () => printOfferLetter.current,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGenerateTestSlip = useReactToPrint({
    content: () => generateTestSlip.current,
  });

  const handlePrintApplicationForm = useReactToPrint({
    content: () => componentReference.current,
  });
  const handlePrintPostgraduateApplicationForm = useReactToPrint({
    content: () => anotherRefrence.current,
  });

  const handleUndergraduateApplicationForm = useReactToPrint({
    content: () => undergraduateRefrence.current,
  });
  const handleReceiptForm = useReactToPrint({
    content: () => receiptForm.current,
  });

  useEffect(() => {
    getCadidateStatus();
    getTestSlipData();
    getOfferLetter();
    getADM_PrintVoucher();
  }, []);
  const [formFields, setFormFields] = useState(initialFormFields);
  const [viewChallan, setViewChallan] = useState({});
  const [candidateStatus, setCandidateStatus] = useState(candidateStatusFields);
  const [uploadFeeChallanButton, setUploadFeeChallanButton] = useState(true);
  const [isSlip, setIsSlip] = useState(false);

  const getCadidateStatus = () => {
    let data = {
      operationID: Select,
      admRegID: decryptData(REG_ID, SessionStorage),
      createdBy: 0,
      modifiedBy: 0,
      isActive: true,
      userIp: "string",
    };
    Admission_ADM_CandidateStatus(data)
      .then((res) => {
        setViewChallan(res?.data?.Table?.[0]?.ViewChallan);
        let ApplicationId = {
          name: "ApplicationId",
          value: res?.data?.Table?.[0]?.ApplicationID,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: ApplicationId,
        });
        let TokenId = {
          name: "TokenId",
          value: res.data.Table1?.[0].TokenId,
        };
        dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: TokenId });
        setCandidateStatus({
          ...candidateStatus,
          ADMRegID: res?.data?.Table?.[0]?.ADMRegID,
          ADMType: res?.data?.Table?.[0]?.ADMType,
          ADMYear: res?.data?.Table?.[0]?.ADMYear,
          ApplicationID: res?.data?.Table?.[0]?.ApplicationID,
          ApplicationStatus: res?.data?.Table?.[0]?.ApplicationStatus,
          Campus: res?.data?.Table?.[0]?.Campus,
          CampusCity: res?.data?.Table?.[0]?.CampusCity,
          Department: res?.data?.Table?.[0]?.Department,
          // DocsToPrint: res.data.Table[0].DocsToPrint,
          Faculty: res?.data?.Table?.[0]?.Faculty,
          InterViewStatus: res?.data?.Table?.[0]?.InterViewStatus,
          OfficeComment: res?.data?.Table[0]?.OfficeComment,
          Program: res?.data?.Table?.[0]?.Program,
          ProgramType: res?.data?.Table[0]?.ProgramType,
          Qouta: res?.data?.Table?.[0]?.Qouta,
          Seattype: res?.data?.Table[0]?.Seattype,
          TestID: res?.data?.Table?.[0]?.TestID,
          TestStatus: res?.data?.Table?.[0]?.TestStatus,
          ChallanRemarks: res?.data?.Table?.[0]?.ChallanRemarks,
          Paid_upaid: res?.data?.Table?.[0]?.Paid_upaid,
          AppliedProgram: res?.data?.Table?.[0]?.AppliedProgram,
          ViewChallan: res?.data?.Table?.[0]?.ViewChallan,
          DocsToPrint: res?.data?.Table1?.[0]?.DocsToPrint,
          // WhatIsNext: res.data.Table[0].WhatIsNext,
          FinalSubmit: res?.data?.Table1?.[0]?.FinalSubmit,
          ProgramId: res?.data?.Table?.[0]?.ProgramId,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleInputChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageUpload = (event) => {
    setFormFields({
      ...formFields,
      FileName: event.target.files[0],
    });
    // if (Object.keys(event.target.files[0]) !== []) {
    //   setUploadFeeChallanButton(false);
    // }
  };

  const uploadChallan = () => {
    document.getElementById("documentPath").value = [];
    let formData = new FormData();
    Object.keys(formFields).forEach((d) => {
      formData.append(`${d}`, formFields[d]);
    });
    sendFeeChallan(formData);
  };

  const sendFeeChallan = (payload) => {
    Admission_ADMUploadFeeChallan(payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
          getCadidateStatus();
          setUploadFeeChallanButton(true);
          setViewChallan({});
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getADM_PrintVoucher = () => {
    const payload = {
      OperationID: 1,
      ADMRegID: decryptData(REG_ID, SessionStorage),
      AdmissionFacultyDepartmentId: 0,
      FacultyDepartmentProgramId: 0,
    };
    PostRequest(ADM_PRINT_VOUCHER, payload)
      .then((res) => {
        let data = { name: "AdmissionVoucherData", value: res?.data };
        dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getTestSlipData = () => {
    const payload = {
      OperationID: Search,
      // AdmRegID: 127,
      AdmRegID: decryptData(REG_ID, SessionStorage),
    };
    ADM_PrintTestSlip(payload)
      .then((res) => {
        if (res?.data?.Table?.length > 0) {
          setIsSlip(true);
        } else {
          setIsSlip(false);
        }
        let data = { name: "TestSlipRecord", value: res?.data?.Table?.[0] };
        dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getOfferLetter = () => {
    const payload = {
      OperationID: Select,
      // AdmRegID: 127,
      AdmRegID: decryptData(REG_ID, SessionStorage),
    };
    ADM_ADM_OfferedPrograms(payload)
      .then((res) => {
        let data = { name: "OfferLetterData", value: res?.data?.Table };
        dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container fluid>
      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              Candidate Status
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table bordered striped responsive>
            <tbody>
              <tr>
                <td style={{width: '50%'}}>Registration ID</td>
                <td style={{width: '50%'}}>{decryptData(REG_ID, SessionStorage)}</td>
              </tr>
              <tr>
                <td>Campus Type</td>
                <td>
                  {candidateStatus?.Campus == ""
                    ? "-"
                    : candidateStatus?.Campus}
                </td>
              </tr>
              <tr>
                <td>Campus City</td>
                <td>
                  {candidateStatus?.CampusCity == ""
                    ? "-"
                    : candidateStatus?.CampusCity}
                </td>
              </tr>
              <tr>
                <td>Program Type</td>
                <td>
                  {candidateStatus?.ProgramType == ""
                    ? "-"
                    : candidateStatus?.ProgramType}
                </td>
              </tr>
              <tr>
                <td>Program</td>
                <td>
                  {candidateStatus?.Program == ""
                    ? "-"
                    : candidateStatus?.Program}
                </td>
              </tr>
              <tr>
                <td>Admission Type</td>
                <td>
                  {candidateStatus?.ADMType == ""
                    ? "-"
                    : candidateStatus?.ADMType}
                </td>
              </tr>
              <tr>
                <td>Faculty Type</td>

                <td>
                  N/A
                  {/* {" "}
                  {candidateStatus.Faculty == ""
                    ? "-"
                    : candidateStatus.Faculty} */}
                </td>
              </tr>
              <tr>
                <td>Applied Department</td>
                <td>
                  {candidateStatus?.Department == ""
                    ? "-"
                    : candidateStatus?.Department}
                </td>
              </tr>
              <tr>
                <td>Applied Program</td>
                <td>
                  {candidateStatus?.AppliedProgram == ""
                    ? "-"
                    : candidateStatus?.AppliedProgram}
                </td>
              </tr>
              <tr>
                <td>Seat Type</td>
                <td>
                  {candidateStatus?.Seattype == ""
                    ? "-"
                    : candidateStatus?.Seattype}
                </td>
              </tr>
              <tr>
                <td>Quota Type</td>
                <td>
                  {candidateStatus?.Qouta == "" ? "-" : candidateStatus?.Qouta}
                </td>
              </tr>
              <tr>
                <td>Application ID</td>
                <td>
                  {candidateStatus?.ApplicationID == ""
                    ? "-"
                    : candidateStatus?.ApplicationID}
                </td>
              </tr>
              <tr>
                <td>Application Status</td>
                <td>
                  {candidateStatus?.ApplicationStatus == ""
                    ? "Not Submitted"
                    : candidateStatus?.ApplicationStatus}
                </td>
              </tr>
              <tr>
                <td>Form Fee Status</td>
                <td>
                  {candidateStatus?.Paid_upaid == ""
                    ? "-"
                    : candidateStatus?.Paid_upaid}
                </td>
              </tr>
              <tr>
                <td>Fee Status Checker Comments</td>
                <td>
                  {candidateStatus?.ChallanRemarks == ""
                    ? "-"
                    : candidateStatus?.ChallanRemarks}
                </td>
              </tr>
              <tr>
                <td>Admission Office Comments</td>
                <td>
                  {candidateStatus?.OfficeComment == ""
                    ? "-"
                    : candidateStatus?.OfficeComment}
                </td>
              </tr>
              <tr>
                <td>Admission Year</td>
                <td>
                  {candidateStatus?.ADMYear == ""
                    ? "-"
                    : candidateStatus?.ADMYear}
                </td>
              </tr>
              <tr>
                <td>Offer Letter And Admission Challan</td>
                <td>
                  <div>
                    <Button
                      // disabled={handlePrintOfferLetter}
                      onClick={handlePrintOfferLetter}
                      className="mt-1  mb-1"
                      disabled={
                        SupportingTables?.OfferLetterData?.[0]?.IsEnabled == 0
                          ? false
                          : true
                      }
                    >
                      Print Offer Letter
                    </Button>
                    <Button
                      // disabled={handlePrintOfferLetter}
                      onClick={handlePrintChallanForAdmission}
                      className="mt-1  mb-1"
                      disabled={
                        AdmissionVoucherData?.Table?.[0]
                          ?.IsEnabled == 0
                          ? false
                          : true
                      }
                    >
                      Print Admission Challan
                    </Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Upload scan/photo of paid fee challan or Pay Online (Through
                  HBL Konnect)
                </td>
                <td>
                  <div>
                    {/* <Input
                      className="mt-1 mb-1"
                      type="file"
                      id="documentPath"
                      accept="image/*"
                      color="default"
                      name="FileName"
                      onChange={handleImageUpload}
                      disabled={
                        candidateStatus?.FinalSubmit === 1 ? false : true
                      }
                    />
                    <Button
                      disabled={uploadFeeChallanButton}
                      onClick={uploadChallan}
                      className="mt-1  mb-1"
                    >
                      Upload Fee Challan or Online Payment Receipt
                    </Button> */}
                    <Button
                      onClick={handlePrint}
                      disabled
                      className="mt-1 mb-1 "
                    >
                      Print Fee Challan
                    </Button>

                    {/* <Button className="mt-1 mb-1 " disabled>
                      Pay Online Now
                    </Button> */}
                  </div>
                </td>
              </tr>
              <tr>
                <td>View Uploaded Fee Challan</td>
                <td>
                  <a
                    href={
                      candidateStatus?.ViewChallan === "0"
                        ? null
                        : candidateStatus?.ViewChallan
                    }
                    target="_blank"
                    className="btn btnic btn-secondary"
                  >
                    <i className="fa fa-eye" color="primary"></i>
                  </a>
                  {/* <Button
                    style={{ lineHeight: "1" }}
                    color="secondary"
                    className="btnic"
                    size="sm"
                    disabled={
                      candidateStatus?.ViewChallan === "0" ? true : false
                    }
                  >
                    <i className="fa fa-eye" color="primary"></i>
                  </Button> */}
                </td>
              </tr>
              <tr>
                <td>Test ID</td>
                <td>
                  -
                  {/* {candidateStatus.TestID == "" ? "-" : candidateStatus.TestID} */}
                </td>
              </tr>
              <tr>
                <td>Test Status</td>
                <td>
                  -
                  {/* {candidateStatus.TestStatus == ""
                    ? "-"
                    : candidateStatus.TestStatus} */}
                </td>
              </tr>
              <tr>
                <td>Interview Status</td>
                <td>
                  {candidateStatus?.InterViewStatus == ""
                    ? "-"
                    : candidateStatus?.InterViewStatus}
                </td>
              </tr>
              <tr>
                <td>Documents to Print</td>
                <td>
                  <Button
                    onClick={handleUndergraduateApplicationForm}
                    className="mt-1  mb-1"
                    disabled={candidateStatus?.FinalSubmit == 0 ? true : false}
                  >
                    Print Application Form
                  </Button>
                  {isSlip ? (
                    <Button
                      onClick={handleGenerateTestSlip}
                      className="mt-1  mb-1"
                      // disabled={candidateStatus?.FinalSubmit == 0 ? true : false}
                    >
                      Generate Admit Slip
                    </Button>
                  ) : null}
                  {/* <Button
                    className="mt-1  mb-1"
                    onClick={handlePrintPostgraduateApplicationForm}
                  >
                    Print Paid Fee Challan
                  </Button> */}
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card className="text-center">
        <CardTitle>
          Want to apply in additional Programs?{" "}
          <Button disabled>Click Here</Button>
        </CardTitle>
      </Card>
      <div style={{ display: "none" }}>
        <ChallanForAdmisison ref={challanForAdmission} />
        <AdmissionChallan ref={componentRef} />
        <AdmitCard ref={componentReference} />
        <PostgraduateAdmissionForm ref={anotherRefrence} />
        {/* <GeneralLedger ref={undergraduateRefrence} /> */}
        <AdmissionReportPrint ref={undergraduateRefrence} />
        <ReceiptForm ref={receiptForm} />
        <TestSlip ref={generateTestSlip} />
        <PrintOfferLetter ref={printOfferLetter} />
      </div>
    </Container>
  );
};

export default AdmissionCandidateStatus;
