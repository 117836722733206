import React, { Fragment, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Button, Col } from "reactstrap";
import {
  academicYearId,
  admissionTypeId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  facultyTypeId,
  featureList,
  Insert,
  majorsAdId,
  meritlistId,
  programTypes,
  Search,
  seatTypeId,
  Select,
  SessionStorage,
  Update,
} from "../../common/SetupMasterEnum";

import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupButton from "../../components/GeneralComponent/FormGroupButton";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../EncryptData";

import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import { ADM_SecureAdmissionSeatsFacultyWise, PostRequest } from "../../utils/Config";
import SecureAdmissionSeatFacultyWiseReport from "./AdmissionReports/SecureAdmissionSeatFacultyWiseReport";
import { ADM_SECURE_ADMISSION_SEATS_FACULTY_WISE } from "../../utils/UrlConstants";

const SecureAdmissionSeatFacultyWise = () => {
  const initialSearchFields = {
    OperationID: Select,
    EmployeeID: decryptData("EmplId", SessionStorage),
    AcademicYearID: 0,
    AdmRegID: 0,
    Cnic: "",
    SeatTypeID: 0,
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [enableButton, setEnableButton] = useState(false);
  const printList = useRef();

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        SearchFields: initialSearchFields,
      },
    });
  }, []);

  const get_ADM_SecureAdmissionSeatsFacultyWise = () => {
    // ADM_SecureAdmissionSeatsFacultyWise(SearchFields)
    PostRequest(ADM_SECURE_ADMISSION_SEATS_FACULTY_WISE, SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1,
            SearchFields: SearchFields,
          },
        });
        setEnableButton(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Cnic", name: "Cnic" },
    { field: "Name", name: "Name" },
    { field: "Deparment", name: "Deparment" },
    { field: "Program", name: "Program" },
    { field: "Reg_No", name: "Reg No" },
    { field: "SeatType", name: "Seat Type" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId === seatTypeId
          )}
          label="Seat Type"
          name="SeatTypeID"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.SeatTypeID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Cnic"
          value={SearchFields?.Cnic}
          onChange={handleSearchChange}
          label="Student CNIC"
          isNumber="true"
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    get_ADM_SecureAdmissionSeatsFacultyWise();
  };

  const cancelSearch = () => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        SearchFields: initialSearchFields,
      },
    });
    setEnableButton(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printList.current,
  });

  const customButton = (
    <Fragment>
      <FormGroupButton
        title="Print"
        onClick={handlePrint}
        disabled={!enableButton}
      ></FormGroupButton>
      <div style={{ display: "none" }}>
        <SecureAdmissionSeatFacultyWiseReport ref={printList} />
      </div>
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Secure Admission Seat Faculty Wise"
      //buttonName="Generate"
      searchPanel={searchPanel}
      tableColumns={enableButton === true ? columns : null}
      tableRows={TableList}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      hideAction={true}
      //   initialFormFields={initialFormFields}
      customButton={customButton}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default SecureAdmissionSeatFacultyWise;
