import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  balanceTypeId,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  TMS_Incharge_WebSignInOut,
} from "../../../utils/Config";
import Select from "react-select";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../common/dateFormatEnum";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { decryptData } from "../../../EncryptData";

const initialSearchValues = {
  EmployeeWebSignId: 0,
  EmployeeId: '',
  IsLimited: 0,
  LimitedFrom: null,
  LimitedTo: null,
  IsActive: 0,
  UserID: 0,
  UserIP: "192.168.164.23",
  SiteId: 0,
};
const InchargeWebSignInOut = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [dropdownList, setDropdownList] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchFields, setSearchFields] = useState({
    ...initialSearchValues,
    UserID: decryptData("EmplId", SessionStorage),
  });
  useEffect(() => {
    getMasterDetailAllDropdown();
    getEmployeeDropDown();
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        setDropdownList({ tables: res.data });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getEmployeeDropDown() {
    const payload = {
      operationId: 1,
      employeeId: decryptData("EmplId", SessionStorage),
      case: 3,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {
        setEmployeeList(
          res?.data?.Table.map((x, index) => ({
            ...x,
            label: x.Name,
            value: index + 1,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    if(searchFields.IsActive == 1){
      searchFields.LimitedFrom = null
      searchFields.LimitedTo = null
      searchFields.IsLimited = 0
    }
    else if(searchFields.IsLimited == 2454){
      searchFields.LimitedFrom = null
      searchFields.LimitedTo = null
    }
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    const result = event.map((obj) => JSON.stringify(obj.Id)).join(",");
    searchFields.EmployeeId = result;
  };
  const AllDateSet = (event, type) => {
    if (type === "LimitedFrom") {
      setSearchFields({
        ...searchFields,
        LimitedFrom: event,
      });
    } else if (type === "LimitedTo") {
      setSearchFields({
        ...searchFields,
        LimitedTo: event,
      });
    }
  };
  const assignTimeInOut = (payload) => {
    TMS_Incharge_WebSignInOut(payload)
      .then((res) => {
        if(res?.data?.Table[0]?.haserror == 0){
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.Message
          })
          setSearchFields({
            ...initialSearchValues,
            UserID: decryptData("EmplId", SessionStorage),
          })
        }
        else{
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.Message
          })
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    assignTimeInOut([searchFields]);
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="mt-3">
            <CardTitle>Assign Web Sign In-Out</CardTitle>
            <CardBody>
              <form onSubmit={onSubmit}>
                <Row>
                  <Col lg="3" md="3" xs="12">
                    <Label>Employees</Label>
                    <Select
                      closeMenuOnSelect={false}
                      onChange={handleInputChangeSelect}
                      isMulti={true}
                      options={employeeList}
                      value={selectedOption}
                    />
                  </Col>
                  <Col lg="3" md="3" xs="12">
                    <div className="form-group">
                      <Label>
                        Web Sign In-Out
                        <span className="text-danger">*</span>
                      </Label>
                      <div
                        className="form-control"
                        onChange={handleSearchChange}
                      >
                        <Input type="radio" value={1} name="IsActive" />
                        Yes
                        <Input type="radio" value={0} name="IsActive" />
                        No
                      </div>
                    </div>
                  </Col>
                  {searchFields.IsActive == 1 ?
                  <Col lg="3" md="3" xs="12">
                    <FormGroupSelect
                      label="Balance Type"
                      name="IsLimited"
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      list={dropdownList?.tables?.filter(
                        (x) => x.SetupMasterId == balanceTypeId
                      )}
                      onChange={handleSearchChange}
                      value={searchFields.IsLimited}
                    />
                  </Col>
                  : null }
                  {searchFields.IsLimited !== 2454 && searchFields.IsActive == 1 ? (
                    <Fragment>
                      <Col lg="3" md="3" sm="6" xs="12">
                        <div className="form-group">
                          <label className="form-label">
                            From Date<span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            selected={searchFields.LimitedFrom}
                            onChange={(e) => AllDateSet(e, "LimitedFrom")}
                            className="form-control"
                            name="LimitedFrom"
                            required={true}
                            showYearDropdown={true}
                            dateFormat={dateFormat}
                            placeholderText={dateFormatPlaceholder}
                          />
                        </div>
                      </Col>
                      <Col lg="3" md="3" sm="6" xs="12">
                        <div className="form-group">
                          <label className="form-label">
                            To Date<span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            selected={searchFields.LimitedTo}
                            onChange={(e) => AllDateSet(e, "LimitedTo")}
                            className="form-control"
                            name="LimitedTo"
                            required={true}
                            showYearDropdown={true}
                            dateFormat={dateFormat}
                            placeholderText={dateFormatPlaceholder}
                          />
                        </div>
                      </Col>
                    </Fragment>
                  ) : null}
                </Row>
                <Row>
                  <Col className="text-right mt-2" sm="12">
                    <Button color="primary" type="submit">
                      Assign
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InchargeWebSignInOut;
