import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { CustomErrorMessage } from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import {
  Admission_GetStudendAdmissionRecord,
  Setup_MasterDetails_All_Dropdowns,
} from "../../../utils/Config";
import { REG_ID } from "../../../utils/EncryptedConstants";
import StudentProfileTabs from "./StudentProfileTabs/StudentProfileTabs";

const PreviousSemesterCourses = () => {
  const dispatch = useDispatch();
  useEffect(() => {}, []);

  return (
    <Fragment>
      <Container fluid>
        <Card className="mt-3">
          <CardTitle
            style={{
              backgroundColor: "#2b2a9c",
              color: "white",
              textAlign: "center",
            }}
          >
            Courses Registered in Previous Semester
          </CardTitle>
          <CardBody>
            <Row>
              <Col>
                <Table bordered responsive>
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Student</td>
                      <td>Talha Waheed Junejo - 2284160</td>
                      <td style={{ fontWeight: "bold" }}>Section</td>
                      <td>MPM 1 C</td>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <Table bordered responsive striped>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Course Name</th>
                      <th>With CLass</th>
                      <th>Outline</th>
                      <th>Attandance</th>
                      <th>Files</th>
                      <th>Recap</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td style={{ color: "#4473c5" }}>
                        PM5104 Cost and Financial Mangement for Project
                        Management
                      </td>
                      <td style={{ fontWeight: "bold" }}>MPM 1 C</td>
                      <td style={{ color: "#4473c5" }}>Outline</td>
                      <td style={{ color: "#4473c5" }}>Attandance</td>
                      <td style={{ color: "#4473c5" }}>Files</td>
                      <td style={{ color: "#4473c5" }}>Recap</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default PreviousSemesterCourses;
