import React, { forwardRef } from "react";
import { Table } from "reactstrap";
import { useSelector } from "react-redux";

const ClassConductReport = forwardRef((props, ref) => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );

  return (
    <div ref={ref} style={{ display: "flex" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          <div style={{ margin: "0 20px", padding: " 0 25px" }}>
            <table
              style={{
                width: "100%",
                // backgroundColor: "#fff",
              }}
            >
              <tbody>
                {" "}
                <tr className="text-center">
                <td colSpan={1} style={{ fontWeight: "bold", fontSize: 13 }}>
                    From: {SearchFields?.FromDate}
                  </td>
                  <td colSpan={7} style={{ fontWeight: "bold", fontSize: 18 }}>
                    Class Conduct Report
                  </td>
                  <td colSpan={1} style={{ fontWeight: "bold", fontSize: 13 }}>
                    To: {SearchFields?.ToDate}
                  </td>
                </tr>
                <br />
                {/* <tr>
                  <td colSpan={1} style={{ fontWeight: "bold", fontSize: 13 }}>
                    Reporting Period
                  </td>

                  <td colSpan={1} style={{ fontWeight: "bold", fontSize: 13 }}>
                    From: Date
                  </td>

                  <td
                    colSpan={1}
                    style={{ fontWeight: "bold", fontSize: 13 }}
                  ></td>
                  <td colSpan={1} style={{ fontWeight: "bold", fontSize: 13 }}>
                    To: Date
                  </td>

                  <td
                    colSpan={5}
                    style={{ fontWeight: "bold", fontSize: 13 }}
                  ></td>
                </tr>
                <tr>
                  <td colSpan={1} style={{ fontWeight: "bold", fontSize: 13 }}>
                    Faculty: Miss Rabail Aisha
                  </td>
                  <td colSpan={4} style={{ fontWeight: "bold", fontSize: 13 }}>
                    Academic Session
                  </td>
                  <td
                    colSpan={6}
                    style={{ fontWeight: "bold", fontSize: 13 }}
                  ></td>
                </tr>
                <tr>
                  <td colSpan={1} style={{ fontWeight: "bold", fontSize: 13 }}>
                    Department: Auto(HOD login)
                  </td>
                  <td colSpan={4} style={{ fontWeight: "bold", fontSize: 13 }}>
                    Degree Program
                  </td>
                  <td
                    colSpan={6}
                    style={{ fontWeight: "bold", fontSize: 13 }}
                  ></td>
                </tr> */}
              </tbody>
            </table>
            <br />
            <br />
            <table
              style={{
                width: "100%",
                fontSize: 13,
              }}
            >
              <tbody>
                <tr
                  style={{
                    borderBottom: "0.5px solid",
                    color: "black",
                    padding: 5,
                    fontSize: 11,
                  }}
                >
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                      fontSize: 11,
                    }}
                    className="text-center"
                  >
                    S.NO
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Class
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Semester
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Course Code
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    Course Title
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    CHs (Due)
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                      width: "15%",
                    }}
                    className="text-center"
                  >
                    CHs (Cond.)
                  </th>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                      width: "15%",
                    }}
                    className="text-center"
                  >
                    %age
                  </th>
                </tr>

                {TableList?.Table1?.length > 0
                  ? TableList?.Table1?.map((x, ind) => {
                      return (
                        <tr key={ind}>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {ind + 1}
                          </td>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {x.FacultyDepartmentProgramShortForm}
                          </td>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {x.Semester}
                          </td>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {x.Code}
                          </td>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {x.CrTitle}
                          </td>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {x.DueChrs}
                          </td>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {x.CondChrs}
                          </td>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-center"
                          >
                            {x.Avg}
                          </td>
                        </tr>
                      );
                    })
                  : null}

                <tr className="text-center" style={{ backgroundColor: "#772647", color:"white" }}>
                  <td
                    colSpan={5}
                    style={{ border: "1px solid black" }}
                    className="text-center"
                  >
                    Total
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {TableList?.Table2?.[0]?.TotalDueChrs}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {TableList?.Table2?.[0]?.TotalCondChrs}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {TableList?.Table2?.[0]?.Avgg}
                  </td>
                </tr>

                <tr className="text-center" style={{ backgroundColor: "#fff" }}>
                  <td colSpan={5} style={{ border: "1px solid black" }}>
                    Rate per Hour
                  </td>
                  <td colSpan={7} style={{ border: "1px solid black" }}>
                    {TableList?.Table3?.[0]?.RATE}
                  </td>
                </tr>

                <tr className="text-center" style={{ backgroundColor: "#fff" }}>
                  <td colSpan={5} style={{ border: "1px solid black" }}>
                    Total Amount
                  </td>
                  <td colSpan={7} style={{ border: "1px solid black" }}>
                    {TableList?.Table4?.[0]?.TotalAmount}
                  </td>
                </tr>
                <tr className="text-center" style={{ backgroundColor: "#fff" }}>
                  <td colSpan={5} style={{ border: "1px solid black" }}>
                    In Words:
                  </td>
                  <td colSpan={7} style={{ border: "1px solid black" }}>
                    {TableList?.Table6?.[0]?.InWords}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            {/* <table style={{ width: "100%", fontSize: 13 }}>
              <tbody>
                <tr>
                  <td>
                    <strong>Note: </strong>
                    Each record will act as a active link while clicked,
                    generate sub report for that particular subject only,
                    showing all the entries from class attendance datewise.
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    Provide Setup for Rate Per Hour, its according to
                    Qualification MS Rs.1200 and PHd Rs.1500, While Creating HR
                    record "Rate Per hour" can be settled up (Role of Registrar)
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </tbody>
      </table>
    </div>
  );
});

export default ClassConductReport;
