import React, { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Button, Col } from "reactstrap";
import {
  academicYearId,
  Delete,
  feesTypeId,
  Insert,
  partYearID,
  Search,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  AlreadyExistAlert,
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteAlert,
  DeleteWithConfirmation,
  SuccessAlert,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { EXAM_CONTROLLER_STUDENT_TRANSCRIPT } from "../../../utils/UrlConstants";
import ChallanForEnrollment from "../../Admissions/ChallanForEnrollment";
import StudentTranscriptReport from "../Reports/ControllerExamination/StudentTranscriptReport";



const StudentTranscript = () => {

  const initialSearchFields = {
    OperationID: Search,
    AcademicYearID: 0,
    SemesterID: 0,
    PartID: 0,
    FacultyDepartmentProgramID: 0,
    FacultyDeptId:0,
    StudentDetailID: 0,
    Cnic: "",
    RegNo: "",
    Name: "",
    UserID: decryptData("loginId", SessionStorage),
  };
  
  const initialFormFields = {
    OperationID: Search,
    AcademicYearID: 0,
    SemesterID: 0,
    PartID: 0,
    FacultyDeptId:0,
    FacultyDepartmentProgramID: 0,
    StudentDetailID: 0,
    Cnic: "",
    RegNo: "",
    Name: "",
    UserID: decryptData("loginId", SessionStorage),
  };

  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );

  const {
    SupportingTables: { 
      SemesterOne, SemesterOneSum ,SemesterTwo , SemesterTwoSum, SemesterThree, SemesterThreeSum, 
      SemesterFour, SemesterFourSum, SemesterFive, SemesterFiverSum , SemesterSix, SemesterSixSum, 
      SemesterSeven, SemesterSevenSum, SemesterEight, SemesterEightSum, TotalSum, StudentRecord
    },
  } = useSelector((state) => state.CrudFormReducer);

  const {
    SupportingTables: { SearchStudentTranscript11 },
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const challanForEnrollment = useRef();
  const timeoutRef = useRef(null);
  const [academicYearList] = useSetupDetailList(academicYearId);
  const [voucherId, setVoucherId] = useState(null);
  const dispatch = useDispatch();

  const getVouchers = () => {
    PostRequest(EXAM_CONTROLLER_STUDENT_TRANSCRIPT, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            // List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getVouchers();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const columns = [
    { field: "Reg_No", name: "Reg No" },
    { field: "Name", name: "Name" },
    { field: "FatherName", name: "Father Name" },
    { field: "AdmissionFacultyDepartment", name: "Faculty Department" },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  // const handleAddChange = (e) => {
  //   let data = { name: e.target.name, value: e.target.value };
  //   dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  // };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="AcademicYear"
          name="AcademicYearID"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDeptId"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDeptId}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyDepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.FacultyDeptId == null}
          required
        />
      </Col>
      {/* <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartId"
          onChange={handleSearchChange}
          value={SearchFields?.PartId}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col> */}
    </Fragment>
  );


  // const formPanel = (
  //   <Fragment>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="AcademicYear"
  //         name="AcademicYearID"
  //         onChange={handleAddChange}
  //         value={FormFields?.AcademicYearID}
  //         list={academicYearList}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department"
  //         name="FacultyDeptId"
  //         onChange={async (e) => {
  //           onChange_Select_Department_Program({
  //             operationID: 6,
  //             caseID: 3,
  //             paremeterID: e.target.value,
  //           }).then((res) => {
  //             dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
  //           });
  //           handleAddChange(e);
  //         }}
  //         value={FormFields?.FacultyDeptId}
  //         list={SupportingTables?.Departments}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department Program"
  //         name="FacultyDepartmentProgramID"
  //         onChange={handleAddChange}
  //         value={FormFields?.FacultyDepartmentProgramID}
  //         list={SupportingTables?.Programs}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         disabled={FormFields?.FacultyDeptId == null}
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Part"
  //         name="PartId"
  //         onChange={handleAddChange}
  //         value={FormFields?.PartId}
  //         list={PartYear}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //       />
  //     </Col>
  //   </Fragment>
  // );


  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      OperationID: Search,
    };
    PostRequest(EXAM_CONTROLLER_STUDENT_TRANSCRIPT, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  

  // const submitForm = () => {
  //   const payload = {
  //     ...FormFields,
  //     operationId: Insert,
  //     AcademicYearId: 0,
  //     StudentDetailid: 0,
  //     RegNo: "",
  //     FacultyDeptId: 0,
  //     FacultyDeptProgramId: 0,
  //     UserId: 0,
  //     BpVoucherId: 0,
  //     PartId: 0,
  //     SemesterId: 0,
  //     UserIP: decryptData(UserNetworkInfo)?.IPv4,
  //   };
  //   PostRequest(EXAM_CONTROLLER_STUDENT_TRANSCRIPT, payload)
  //     .then((res) => {
  //       if (res?.data?.Table?.[0]?.HasError === 0) {
  //         CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
  //         getVouchers();
  //       } else {
  //         CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
  //         return;
  //       }
  //       dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };


  const viewEnrollmentChallan = (obj) => {
    const payload ={
      OperationID: 6,
      StudentDetailID: obj.StudentDetailID,
      AcademicYearID:obj.AcademicYearId,
      FacultyDepartmentProgramID:obj.FacultyDepartmentProgramId,
      
    }
    PostRequest(EXAM_CONTROLLER_STUDENT_TRANSCRIPT, payload)
      .then((res) => {
        setVoucherId(res.data);

        let abc = {
          name: "abc",
          value: res?.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: abc,
        });
        //semester One
        let data = {
          name: "SemesterOne",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        
        let data1 = {
          name: "SemesterOneSum",
          value: res?.data?.Table1,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data1,
        });
        
        //semester Two
        let data2 = {
          name: "SemesterTwo",
          value: res?.data?.Table2,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data2,
        });
        
        let data3 = {
          name: "SemesterTwoSum",
          value: res?.data?.Table3,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data3,
        });

        //semester Three
        let data4 = {
          name: "SemesterThree",
          value: res?.data?.Table4,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data4,
        });
        let data5 = {
          name: "SemesterThreeSum",
          value: res?.data?.Table5,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data5,
        });

        //semester four
        let data6 = {
          name: "SemesterFour",
          value: res?.data?.Table6,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data6,
        });
        let data7 = {
          name: "SemesterFourSum",
          value: res?.data?.Table7,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data7,
        });

        //semester five
        let data8 = {
          name: "SemesterFive",
          value: res?.data?.Table8,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data8,
        });
        let data9 = {
          name: "SemesterFiveSum",
          value: res?.data?.Table9,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data9,
        });

        //semester six
        let data10 = {
          name: "SemesterSix",
          value: res?.data?.Table10,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data10,
        });
        let data11 = {
          name: "SemesterSixSum",
          value: res?.data?.Table11,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data11,
        });

        //semester seven
        let data12 = {
          name: "SemesterSeven",
          value: res?.data?.Table12,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data12,
        });
        let data13 = {
          name: "SemesterSevenSum",
          value: res?.data?.Table13,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data13,
        });

        //semester eight
        let data14 = {
          name: "SemesterEight",
          value: res?.data?.Table14,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data14,
        });
        let data15 = {
          name: "SemesterEightSum",
          value: res?.data?.Table15,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data15,
        });

        //TotalSum
        let data16 = {
          name: "TotalSum",
          value: res?.data?.Table16,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data16,
        });

         //StudentRecord
         let data17 = {
          name: "StudentRecord",
          value: res?.data?.Table17,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data17,
        });
        
        timeoutRef.current = setTimeout(
          () => handlePrintChallanForEnrollment(),
          200
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };


  // const viewPassCertificate = (obj) => {
  //   const payload = {
  //           ...initialFormFields,
  //     OperationID: 2,
  //     StudentDetailID: obj.StudentDetailID,
  //     AcademicYearId: obj.AcademicYearId,
  //     FacultyDepartmentProgramId: obj.FacultyDepartmentProgramId,
  //   };
  //   PostRequest(ACAD_EXAM_CONTROLLER_REPORTS, payload)
  //     .then((res) => {
  //       if (res?.data?.Table1?.[0]?.HasError === 0) {
  //         CustomErrorMessage(res?.data?.Table1?.[0]?.MESSAGE);
  //       } else {
  //         setVoucherId(res?.data?.Table1);
  //         let data = {
  //           name: "PassCertificateStudent",
  //           value: res?.data,
  //         };
  //         dispatch({
  //           type: SET_INITIAL_DROPDOWN_FORM_STATE,
  //           payload: data,
  //         });
  //         timeoutRef.current = setTimeout(
  //           () => handlePassCertificateReport(),
  //           200
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };


  const handlePrintChallanForEnrollment = useReactToPrint({
    content: () => challanForEnrollment.current,
  });

  // const onDeleteRow = (obj) => {
  //   DeleteWithConfirmation().then((result) => {
  //     if (result.isConfirmed) {
  //       PostRequest(EXAM_CONTROLLER_STUDENT_TRANSCRIPT, {
  //         ...initialFormFields,
  //         OperationId: Delete,
  //         BpVoucherId: obj.BPVoucherId,
  //       })
  //         .then((res) => {
  //           DeleteAlert();
  //           getVouchers();
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     }
  //   });
  // };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getVouchers();
  };

  // const handleCancel = () => {
  //   dispatch({
  //     type: RESET_FORM_FIELDS,
  //     payload: initialFormFields,
  //   });
  // };

  // const customButton = (
  //   <Fragment>
  //     <Button color="primary" onClick={submitForm}>
  //       Generate Voucher
  //     </Button>
  //   </Fragment>
  // );


  return (
    <>
      <CrudFormComponent
        formName="Student Transcript Report"
        // buttonName="Add"
        // customButton={customButton}
        modalSize="lg"
        tableColumns={columns}
        tableRows={TableList}
        // formPanel={formPanel}
        // hideSearchPanel={true}
        // formSubmit={submitForm}
        searchPanel={searchPanel}
        searchSubmit={submitSearch}
        // onEdit={onEditRow}
        // onDelete={onDeleteRow}
        onGenerateVoucher={viewEnrollmentChallan}
        initialFormFields={initialFormFields}
        featureList={menuTable?.Table2?.filter(
          (x) => x.MenuId === selectedMenu
        )}
        cancelSearch={cancelSearch}
        // handleCancel={handleCancel}
      />
      <div style={{ display: "none" }}>
        <StudentTranscriptReport
          ref={challanForEnrollment}
          StudentDetailID={voucherId}
        />
      </div>
    </>
  );
};

export default StudentTranscript;
