import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { dateFormat, dateFormatPlaceholder } from "../../common/dateFormatEnum";
import {
  admissionProgramId,
  admissionTypeId,
  blockDepartmentId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  examinationId,
  examTypeID,
  facultyDepartmentId,
  facultyTypeId,
  floorId,
  hostelId,
  hostelTypeId,
  programs,
  roomTypeId,
  Search,
  Select,
  SessionStorage,
} from "../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert, DeleteWithConfirmation } from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
  RESET_SEARCH_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
  Setup_Admission_Blocks,
  Hostel_MangeHostel,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_SeatType,
  Setup_CreateSetupDetail,
  Hostel_HostelRoom,
  PostRequest,
} from "../../utils/Config";
import DatePicker from "react-datepicker";
import { formatDateFunction } from "../../functions/DateFormatFunction";
import { decryptData } from "../../EncryptData";
import { SETUP_MASTER_DETAIL_ALL_DROPDOWN } from "../../utils/UrlConstants";

const initialSearchFields = {
  OperationId: 0,
  RoomId: 0,
  RoomCatId: 0,
  RoomName_Number: "",
  BedTotal: 0,
  HostelId: 0,
  FloorId: 0,
  Cost: 0,
  EffectiveFrom: new Date(),
  EffectiveTo: new Date(),
  // CostPerBed: 0,
  Note: "",
  IsActive: 1,
  IsDeleted: 0,
  UserId: 0,
};
const initialFormFields = {
  OperationId: 1,
  RoomId: 0,
  RoomCatId: 0,
  RoomName_Number: "",
  BedTotal: 0,
  HostelId: 0,
  FloorId: 0,
  Cost: 0,
  EffectiveFrom: null,
  EffectiveTo: null,
  // CostPerBed: 0,
  Note: "",
  IsActive: 0,
  IsDeleted: 0,
  UserId: 0,
};
const initialSetupFields = {
  MasterId: 0,
  Detail: "",
  ParentId: 0,
  UserId: 0,
};

const RoomCategory = () => {
  initialSetupFields.UserId = decryptData("loginId", SessionStorage);
  initialSetupFields.MasterId = 1143;
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [dropdownList, setDropDownList] = useState([]);
  const [toggleForm, setToggleForm] = useState(false);
  const [setupFields, setSetupFields] = useState(initialSetupFields);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  useEffect(() => {
    hostelRoomData();
    getDropDown();
  }, []);

  const hostelRoomData = () => {
    Hostel_HostelRoom(initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function getDropDown() {
    const payload = {
      operationId: Select,
    };

    // Setup_MasterDetails_All_Dropdowns(payload)
    PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
    .then((res) => {
        setDropDownList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const columns = [
    { field: "HostelName", name: "Hostel Name" },
    { field: "RoomTypename", name: "Room Type" },
    { field: "FloorName", name: "Floor" },
    { field: "RoomName_Number", name: "Room#" },
    { field: "Cost", name: "Cost" },
    { field: "EffectiveFrom", name: "Effective From" },
    { field: "EffectiveTo", name: "RoomEffective To" },
    // { field: "CostPerBed", name: "Cost Per Bed" },
    { field: "BedTotal", name: "Total Bed" },
    { field: "Note", name: "Note" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const handleSetupChange = (e) => {
    setSetupFields({
      ...setupFields,
      [e.target.name]: e.target.value,
    });
  };
  const AllDateSet = (event, type) => {
    if (type === "FromDateSearch") {
      setSearchFromDate(event);
      let date = formatDateFunction(event, "-");
      SearchFields.EffectiveFrom = date;
      let data1 = {
        name: "EffectiveFrom",
        value: SearchFields.EffectiveFrom,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "ToDateSearch") {
      setSearchToDate(event);
      let date = formatDateFunction(event, "-");
      SearchFields.EffectiveTo = date;
      let data1 = {
        name: "EffectiveTo",
        value: SearchFields.EffectiveTo,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "FromDate") {
      let date = formatDateFunction(event, "-");
      FormFields.EffectiveFrom = event;
      let data1 = {
        name: "EffectiveFrom",
        value: FormFields.EffectiveFrom,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "ToDate") {
      let date = formatDateFunction(event, "-");
      FormFields.EffectiveTo = event;
      let data1 = {
        name: "EffectiveTo",
        value: FormFields.EffectiveTo,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    }
  };
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == hostelId)}
          label="Hostel"
          name="HostelId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.HostelId}
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == floorId)}
          label="Floor"
          name="FloorId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.FloorId}
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == roomTypeId)}
          label="Room Category"
          name="RoomCatId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.RoomCatId}
          onChange={handleSearchChange}
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Room#"
          name="RoomName_Number"
          onChange={handleSearchChange}
          value={SearchFields?.RoomName_Number}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Cost"
          name="Cost"
          onChange={handleSearchChange}
          value={SearchFields?.Cost}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">
            Effective From<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={searchFromDate}
            onChange={(e) => AllDateSet(e, "FromDateSearch")}
            className="form-control"
            name="EffectiveFrom"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">
            Effective To<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={searchToDate}
            onChange={(e) => AllDateSet(e, "ToDateSearch")}
            className="form-control"
            name="EffectiveTo"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      OperationId: 0,
      RoomId: 0,
      RoomCatId: SearchFields?.RoomCatId,
      FloorId: SearchFields?.FloorId,
      RoomName_Number: SearchFields?.RoomName_Number,
      BedTotal: SearchFields?.BedTotal,
      HostelId: SearchFields?.HostelId,
      // CostPerBed: SearchFields?.CostPerBed,
      Note: SearchFields?.Note,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    Hostel_HostelRoom(payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  function createSetup(payload) {
    Setup_CreateSetupDetail(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              setToggleForm(false);
              setSetupFields({
                ...initialSetupFields,
              });
              getDropDown();
            } else if (results.isConfirmed) {
              setToggleForm(false);
              setSetupFields({
                ...initialSetupFields,
              });
              getDropDown();
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  }
  const submitForm = (id) => {
    const payload = {
      OperationId: 1,
      RoomId: FormFields?.RoomId,
      RoomCatId: FormFields?.RoomCatId,
      FloorId: FormFields?.FloorId,
      RoomName_Number: FormFields?.RoomName_Number,
      BedTotal: FormFields?.BedTotal,
      HostelId: FormFields?.HostelId,
      Cost: FormFields?.Cost,
      EffectiveFrom: FormFields?.EffectiveFrom,
      EffectiveTo: FormFields?.EffectiveTo,
      // CostPerBed: FormFields?.CostPerBed,
      Note: FormFields?.Note,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };
    Hostel_HostelRoom(payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          hostelRoomData();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    const data = {
      OperationId: 1,
      RoomId: obj?.RoomId,
      RoomCatId: obj?.RoomCatId,
      FloorId: obj?.FloorId,
      RoomName_Number: obj?.RoomName_Number,
      BedTotal: obj?.BedTotal,
      HostelId: obj?.HostelId,
      Cost: obj?.Cost,
      EffectiveFrom: new Date(obj?.EffectiveFrom),
      EffectiveTo: new Date(obj?.EffectiveTo),
      // CostPerBed: obj?.CostPerBed,
      Note: obj?.Note,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        const data = {
          OperationId: 1,
          RoomId: obj?.RoomId,
          RoomCatId: obj?.RoomCatId,
          FloorId: obj?.FloorId,
          RoomName_Number: obj?.RoomName_Number,
          BedTotal: obj?.BedTotal,
          HostelId: obj?.HostelId,
          Cost: obj?.Cost,
          EffectiveFrom: obj?.EffectiveFrom,
          EffectiveTo: obj?.EffectiveTo,
          // CostPerBed: obj?.CostPerBed,
          Note: obj?.Note,
          IsActive: 0,
          IsDeleted: 0,
          UserId: decryptData("loginId", SessionStorage),
        };

        Hostel_HostelRoom(data)
          .then((res) => {
            if (res.data.Table[0].HasError === 0) {
              CustomSuccessAlert(res.data.Table[0].Message);
              hostelRoomData();
            } else {
              CustomErrorMessage(res.data.Table[0].Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
    hostelRoomData();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const onAddSetup = (e) => {
    e.preventDefault();
    setToggleForm(true);
  };
  const handleCancelSetup = () => {
    setToggleForm(false);
    hostelRoomData();
  };
  const submitSetup = (e) => {
    e.preventDefault();
    createSetup(setupFields);
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == hostelId)}
          label="Hostel"
          name="HostelId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.HostelId}
          onChange={handleAddChange}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == floorId)}
          label="Floor"
          name="FloorId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.FloorId}
          onChange={handleAddChange}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == roomTypeId)}
          label="Room Type"
          name="RoomCatId"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={FormFields?.RoomCatId}
          onChange={handleAddChange}
        />
        {/* <p
          style={{ fontSize: "12px", marginBottom: "0px" }}
          className="text-right"
        >
          <a href="#" onClick={(e) => onAddSetup(e)}>
            Add Room Category
          </a>
        </p> */}
      </Col>

      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Room#"
          name="RoomName_Number"
          onChange={handleAddChange}
          value={FormFields?.RoomName_Number}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Total Bed"
          name="BedTotal"
          onChange={handleAddChange}
          value={FormFields?.BedTotal}
          isNumber="true"
          maxLength="2"
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Note"
          name="Note"
          onChange={handleAddChange}
          value={FormFields?.Note}
        />
      </Col>
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Hostel Room"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
    />
  );
};

export default RoomCategory;
