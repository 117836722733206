import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import {
  allowancesSetupMasterId,
  Delete,
  Insert,
  Search,
  Select,
  setupMasterId,
  Update,
  allowOnlyString,
  benefitsSetupId,
  degreeSetupId,
  citySetupId,
  countrySetupId,
  academicYearId,
  seatTypeId,
} from "../../common/SetupMasterEnum";

import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_SEARCH_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
  ADM_AdmissionSecureStudent,
  ADM_EligibilityCriteriaDependency,
  PostRequest,
  Setup_MasterDetails_Operation,
} from "../../utils/Config";
import { ADM_ADMISSION_SECURE_STUDENT, ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../utils/UrlConstants";

const initialSearchFields = {
  OperationID: Select,
  AcademicYeaID: 0,
  FacultyDepartmentID: 0,
  FacultyDepartmentProgramID: 0,
  SeatTypeID: 0,
};

const SelectedCandidates = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    });
    getAdmissionSecureStudent();
  }, []);

  function getAdmissionSecureStudent() {
    const payload = {
      operationID: Select,
      academicYeaID: 0,
      facultyDepartmentID: 0,
      facultyDepartmentProgramID: 0,
      seatTypeID: 0,
    };
    // ADM_AdmissionSecureStudent(payload)
    PostRequest(ADM_ADMISSION_SECURE_STUDENT, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "RegNo", name: "Reg No" },
    { field: "Candiate", name: "Candiate" },
    { field: "AcademicYear", name: "Academic Year" },
    { field: "MeritList", name: "Merit List" },
    { field: "Faculty", name: "Faculty" },
    { field: "Program", name: "Program" },
  ];

  const handleSearchChange = (e) => {
    if (e.target.name === "FacultyDepartmentID") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value,
      });
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
      let data2 = { name: "FacultyDepartmentProgramID", value: 0 };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data2 });
    } else {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
    }
  };

  const onChange_ADM_EligibilityCriteriaDependency = (payload) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID === 2) {
            const data = { name: "Departments", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID === 3) {
            const data = { name: "Programs", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          label="Academic Year"
          name="AcademicYeaID"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYeaID}
        />
      </Col>
      <Col md="3" lg="3">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDepartmentID"
          list={SupportingTables?.Departments}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.FacultyDepartmentID}
          onChange={handleSearchChange}
          required
          // disabled={SearchFields?.MajorId == 0 ? true : false}
        />
      </Col>
      <Col md="3" lg="3">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramID"
          list={SupportingTables?.Programs}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          value={SearchFields?.FacultyDepartmentProgramID}
          onChange={handleSearchChange}
          required
          disabled={SearchFields?.FacultyDepartmentID == 0 ? true : false}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId === seatTypeId
          )}
          label="Seat Type"
          name="SeatTypeID"
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.SeatTypeID}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      operationID: Search,
      academicYeaID: SearchFields?.AcademicYeaID,
      facultyDepartmentID: SearchFields?.FacultyDepartmentID,
      facultyDepartmentProgramID: SearchFields?.FacultyDepartmentProgramID,
      seatTypeID: SearchFields?.SeatTypeID,
    };
    // ADM_AdmissionSecureStudent(payload)
    PostRequest(ADM_ADMISSION_SECURE_STUDENT, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getAdmissionSecureStudent();
  };

  return (
    <CrudFormComponent
      formName="Selected Candidates"
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      hideAction={true}
    />
  );
};

export default SelectedCandidates;
