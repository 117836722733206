import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { CustomErrorMessage } from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import {
  Admission_GetStudendAdmissionRecord,
  Setup_MasterDetails_All_Dropdowns,
} from "../../../utils/Config";
import { REG_ID } from "../../../utils/EncryptedConstants";
import StudentProfileTabs from "./StudentProfileTabs/StudentProfileTabs";

const ExamSchedule = () => {
  const dispatch = useDispatch();
  useEffect(() => {}, []);

  return (
    <Fragment>
      <Container fluid>
        <Card className="mt-3">
          <CardTitle
            style={{
              backgroundColor: "rgb(119, 38, 71)",
              color: "white",
              textAlign: "center",
            }}
          >
            Exam Schedule
          </CardTitle>
          <CardBody>
            <Row>
              <Col>
                <Table bordered responsive>
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Student Name</td>
                      <td>Talha Waheed Junejo</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        Registration Number
                      </td>
                      <td>2284160</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Semester</td>
                      <td>Fall - 2022</td>
                    </tr>
                  </tbody>
                </Table>
                {/* <br /> */}
                <Table bordered responsive striped>
                  <tbody>
                    <tr className="text-center">
                      <td
                        style={{
                          fontSize: 18,
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        <p
                          className="blink_me"
                          style={{ marginBottom: "0rem" }}
                        >
                          BBA, BSAF, BABS, BSE Program
                        </p>
                      </td>
                    </tr>
                    <tr className="text-center">
                      <td
                        style={{
                          fontSize: 16,
                          color: "#4473c5",
                          fontWeight: "bold",
                        }}
                      >
                        BBA, BSAF, BABS, BSE Final Exam Schedule for Summer 2022
                      </td>
                    </tr>
                    <tr className="text-center">
                      <td
                        style={{
                          fontSize: 18,
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        <p
                          className="blink_me"
                          style={{ marginBottom: "0rem" }}
                        >
                          {" "}
                          BEME Programs
                        </p>
                      </td>
                    </tr>
                    <tr className="text-center">
                      <td
                        style={{
                          fontSize: 16,
                          color: "#4473c5",
                          fontWeight: "bold",
                        }}
                      >
                        BEME Programs Final Term Exams Schedule for Summer 2022
                        (1st Sept 2022)
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ExamSchedule;
