//import {fetchData} from 'utils/Api.js'

import Dashboard from "../pages/Dashboard";
import Departments from "../pages/HRMS/Employee/Departments";
import Employees from "../pages/HRMS/Employee/Employees";
import PaySlip from "../pages/HRMS/Employee/PaySlip";
import Probations from "../pages/HRMS/Employee/Probations";
import Profile from "../pages/HRMS/Employee/Profile";
import UpdateProfile from "../pages/HRMS/Employee/UpdateProfile";
import NoPageFound from "../pages/NoPageFound";
import Allowances from "../pages/Setup/Allowances";
import LeaveDefination from "../pages/Setup/LeaveDefination";
import Cadre from "../pages/Setup/Cadre";
import CostCenter from "../pages/Finance/Maintenance/CostCenter";
import Designation from "../pages/Setup/Designation";
import Division from "../pages/Setup/Division";
import EmployeeCategory from "../pages/Setup/EmployeeCategory";
import Status from "../pages/Setup/Status";
import EmployeeType from "../pages/Setup/EmployeeType";
import Grade from "../pages/Setup/Grade";
import HiringCriteria from "../pages/Setup/HiringCriteria";
import PayrollType from "../pages/Setup/PayrollType";
import Roles from "../pages/Setup/Roles";
import Users from "../pages/Setup/Users";
import Department from "../pages/Setup/Department";
// import Courses from "../pages/Setup/Courses";
import Programs from "../pages/Setup/Programs";
import Degree from "../pages/Setup/Degree";
import Faculties from "../pages/Setup/Faculties";
import Subject from "../pages/Setup/Subject";
import CourseNumber from "../pages/Setup/CourseNumber";
import CreditHours from "../pages/Setup/CreditHours";
import ClassTimeSlots from "../pages/Setup/ClassTimeSlots";
import ProgramTypes from "../pages/Setup/ProgramTypes";
import Menu from "../pages/Setup/Menu";
import AccessControl from "../pages/Setup/AccessControl";
import ApplyLeave from "../pages/TMS/EmployeeActivity/ApplyLeave";
import LeaveApproval from "../pages/TMS/EmployeeActivity/LeaveApproval";
import LeaveHistory from "../pages/TMS/EmployeeActivity/LeaveHistory";
import ShiftScheduler from "../pages/TMS/EmployeeActivity/ShiftScheduler";
import TimeAdjustment from "../pages/TMS/EmployeeActivity/TimeAdjustment";
import UpdateCalendar from "../pages/TMS/EmployeeActivity/UpdateCalendar";
import ViewCalendar from "../pages/TMS/EmployeeActivity/ViewCalendar";
import SignInOut from "../pages/TMS/EmployeeActivity/WebSignInOut";
import Contracts from "../pages/Payroll/Contracts";
import PayslipStatus from "../pages/Payroll/PayslipStatus";
import InOrOutTime from "../pages/TMS/EmployeeActivity/InOrOutTime";
import ChangePassword from "../pages/ChangePassword";
import AttendanceStats from "../pages/TMS/EmployeeActivity/AttendanceStats";
import AddEmail from "../pages/Announcement/AddEmail";
import Announcement from "../pages/Announcement/Announcement";
import TimeInOutApproval from "../pages/HRMS/Employee/TimeInOutApproval";
import CampusType from "../pages/Setup/CampusType";
import Group from "../pages/Setup/Group";
import EmailGroup from "../pages/Setup/EmailGroup";
import EmployeeStatus from "../pages/Setup/Status";
import EmploymentStatus from "../pages/Setup/EmploymentStatus";
import Location from "../pages/Setup/Location";
import Section from "../pages/Setup/Section";

import PayrollStats from "../pages/Payroll/PayrollStats";
import ACRFaculty from "../pages/Workflow/ACRFaculty";
import ACRBPS1To4 from "../pages/Workflow/ACRBPS1To4";
import ACRBPS5To15 from "../pages/Workflow/ACRBPS5To15";
import ACRBPS16AndAbove from "../pages/Workflow/ACRBPS16AndAbove";

//InchargeComponent
import InchargeViewCalendar from "../pages/TMS/Incharge/InchargeViewCalendar";
import InchargeUpdateCalendar from "../pages/TMS/Incharge/InchargeUpdateCalendar";
import InchargeInOutCalendar from "../pages/TMS/Incharge/InchargeViewCalendar";
import InchargeApplyLeave from "../pages/TMS/Incharge/InchargeApplyLeave";
import InchargeLeaveApproval from "../pages/TMS/Incharge/InchargeLeaveApproval";
import InchargeLeaveHistory from "../pages/TMS/Incharge/InchargeLeaveHistory";
import InchargeTimeAdjustment from "../pages/TMS/Incharge/InchargeTimeAdjustment";
import InchargeInOrOutTime from "../pages/TMS/Incharge/InchargeInOrOutTime";
import ManualAttendance from "../pages/TMS/EmployeeActivity/ManualAttendace";
import InchargeShiftScheduler from "../pages/TMS/Incharge/InchargeShiftScheduler";
import InchargeAttendanceStats from "../pages/TMS/Incharge/InchargeAttendanceStats";
import InchargeWebSignInOut from "../pages/TMS/Incharge/InchargeWebSignInOut";
import WebSignInOut from "../pages/TMS/EmployeeActivity/WebSignInOut";
import ResetPassword from "../pages/ResetPasswordModal";
import Benefits from "../pages/Setup/Benefits";
import AdditionalCharges from "../pages/Setup/AdditionalCharge";
import Majors from "../pages/Setup/Majors";
import AreaofExpertiseCompetencies from "../pages/Setup/AreaofExpertiseCompetencies";
import City from "../pages/Setup/City";
import AdmissionForm from "../pages/Admissions/AdmissionForm";
import Instruction from "../pages/Admissions/Instruction";
import EligibilityCriteria from "../pages/Admissions/EligibilityCriteria";
import ImportantDates from "../pages/Admissions/ImportantDates";
import VoucherExtendDate from "../pages/Admissions/VoucherExtendDate";
import Prospectus from "../pages/Admissions/Prospectus";
import AdmissionApplication from "../pages/Admissions/AdmissionApplication";
import AdmissionSamplePaper from "../pages/Admissions/AdmissionSamplePaper";
import AdmissionFeeChallan from "../pages/Admissions/AdmissionFeeChallan";
import AdmissionCandidateStatus from "../pages/Admissions/AdmissionCandidateStatus";
import DocumentCheckList from "../pages/Admissions/DocumentCheckList";
import FeeStructure from "../pages/Admissions/FeeStructure";
import AdmissionFeeStructure from "../pages/Setup/FeeStructure";
import AdmissionDashboard from "../pages/Admissions/AdmissionDashboard";
import CandidateForgotPassword from "../pages/CandidateForgotPassword";
import EligibilityCriteriaGeneral from "../pages/Admissions/EligibilityCriteriaGeneral";
import AdmissionSlip from "../pages/Admissions/AdmitCard";
import AdmissionChallan from "../pages/Admissions/AdmissionChallan";
import AdmissionReportPrint from "../pages/Admissions/AdmissionReportPrint";
import AdmitCard from "../pages/Admissions/AdmitCard";
import SeatType from "../pages/Setup/SeatType";
import AdmissionProcedure from "../pages/Admissions/AdmissionProcedure";
import CourseMapping from "../pages/Setup/CourseMapping";
import PostgraduateAdmissionForm from "../pages/Admissions/PostgraduateAdmissionForm";
import AdmissionTest from "../pages/Admissions/AdmissionTest";
// import ChartOfAccounts from "../pages/Setup/ChartOfAccounts";
import Blocks from "../pages/Setup/Blocks";
import DepartmentForFeeStructure from "../pages/Setup/DepartmentForFeeStructure";
import AssignBlock from "../pages/Admissions/AssignBlock";
import ScoreCriteria from "../pages/Setup/ScoreCriteria";
import SemesterFeeStructure from "../pages/Setup/SemesterFeeStructure";
import ResetPasswordUG from "../pages/Admissions/ResetPassword";

//Finance Imports
import PaymentVoucher from "../pages/Finance/Transactions/PaymentVoucher";
import CandidatesStatusReport from "../pages/Admissions/CandidatesStatusReport";
import SetupMarks from "../pages/Setup/SetupMarks";
import ReceiptVoucher from "../pages/Finance/Transactions/ReceiptVoucher";
import InterviewScreening from "../pages/Admissions/InterviewScreening";
import AdmissionScheduler from "../pages/Admissions/AdmissionScheduler";
import GeneralLedger from "../pages/Finance/GeneralLegder";
import AdmissionEligibilty from "../pages/Setup/AdmissionEligiblity";
import GeneralLedgerReport from "../pages/Finance/Reports/GeneralLedgerReport";
import ReceivablesAndPayablesReport from "../pages/Finance/Reports/ReceivablesAndPayablesReport";
import CashbookReport from "../pages/Finance/Reports/CashbookReport";
import CreateInvoice from "../pages/Finance/CreateInvoice";
import Journals from "../pages/Finance/Maintenance/Journals";
import ChartOfAccounts from "../pages/Finance/Maintenance/ChartOfAccount";
import AccountNature from "../pages/Finance/Maintenance/AccountNature";
import TransactionType from "../pages/Finance/Maintenance/TransactionType";
import CustomerSetup from "../pages/Finance/Maintenance/CustomerSetup";
import VendorSetup from "../pages/Finance/Maintenance/VendorSetup";
import JournalVouchers from "../pages/Finance/Transactions/JournalVouchers";
import Company from "../pages/Finance/Maintenance/Company";
import SaleInvoiceVouchers from "../pages/Finance/Transactions/SaleInvoiceVouchers";

//Finance Import Ended

import AcademicYear from "../pages/Setup/AcademicYear";
// import Merit_List from "../pages/Admissions/Mertit_List";
import ManageHostel from "../pages/Hostel/ManageHostel";
import HostelRoom from "../pages/Hostel/HostelRoom";
import HostelInstruction from "../pages/Hostel/HostelInstruction";
import RoomCategory from "../pages/Hostel/RoomCategory";
import AdditionalProgram from "../pages/Admissions/AdditionalProgram";
import HostelChecklist from "../pages/Hostel/HostelChecklist";
import HostelEligibiltyCriteria from "../pages/Hostel/HostelEligibilityCriteria";
import AdmissionFormPostgraduate from "../pages/Admissions/AdmissionFormPostGraduate";
import HostelApplications from "../pages/Hostel/HostelApplications";
import AdmissionFormB from "../pages/Admissions/AdmissionFormB";
import UGTotalReport from "../pages/Admissions/UG_Report/UGTotalReport";
import UGViewTotalReport from "../pages/Admissions/UGViewTotalReport";
import SelectedCandidates from "../pages/Admissions/SelectedCandidates";
import PrintOfferLetter from "../pages/Admissions/PrintOfferLetter";
import GenerateMeritList from "../pages/Admissions/MeritList/GenerateMeritList";
import MeritScores from "../pages/Setup/MeritScores";
import ApproveRejectMeritList from "../pages/Admissions/MeritList/ApproveRejectMeritList";
import MeritParameters from "../pages/Setup/MeritParameters";
import TestTimeDetail from "../pages/Setup/TestTimeDetail";
import PrintBulkAdmitSlip from "../pages/Setup/PrintBulkAdmitSlip";
import MeritListReport from "../pages/Admissions/MeritList/Report/MeritListReport";
import RegisteredCandidatesDegreeProgram from "../pages/Admissions/RegisteredCandidatesDegreeProgram";
import RegisteredCandidatesDegreeProgramReport from "../pages/Admissions/AdmissionReports/RegisteredCandidatesDegreeProgramReport";
import SelfFinanceOperations from "../pages/Admissions/MeritList/SelfFinanceOperations";
import UpdateEmployeeProfile from "../pages/Admissions/UpdateEmployeeProfile";
import AddManualPreferances from "../pages/Admissions/MeritList/AddManualPreferances";
import ManualSeatConsideration from "../pages/Admissions/MeritList/ManualSeatConsideration";
import Lecturers from "../pages/Setup/AcademicSetups/Lecturers";
import ManualOpMlSeatAllocation from "../pages/Admissions/MeritList/ManualOpMlSeatAllocation";
import SecureAdmissionSeatFacultyWise from "../pages/Admissions/SecureAdmissionSeatFacultyWise";
import IDCard from "../pages/Admissions/ID_Card/IDCard";
import TimeSlot from "../pages/Setup/TimeSlot";
import AcademicsFacultyDepartmentProgramCourse from "../pages/Setup/AcademicSetups/AcademicsFacultyDepartmentProgramCourse";
import Course from "../pages/Setup/AcademicSetups/Course";
import DegreeParameters from "../pages/Setup/AcademicSetups/DegreeParameters";
import CourseSemFacDeptProgLectMapping from "../pages/Setup/AcademicSetups/CourseSemFacDeptProgLectMapping";
import FactoryDeptProgLectCourseScoreCriteria from "../pages/Setup/AcademicSetups/FactoryDeptProgLectCourseScoreCriteria";

//Academics import
//Students imports
import CurrentSemester from "../pages/Academics/StudentPortal/CurrentSemester";
import CurrentWeekSchedule from "../pages/Academics/StudentPortal/CurrentWeekSchedule";
import ExamSchedule from "../pages/Academics/StudentPortal/ExamSchedule";
import PreviousSemesterCourses from "../pages/Academics/StudentPortal/PreviousSemesterCourses";
import SemesterResult from "../pages/Academics/StudentPortal/SemesterResult";
import StudentProfile from "../pages/Academics/StudentPortal/StudentProfile";
import ChangeProfile from "../pages/Academics/StudentPortal/ChangeProfile";

//Faculty imports

import AssignTestMarks from "../pages/Academics/FacultyPortal/AssignTestMarks";
import CourseSemesterMapping from "../pages/Setup/AcademicSetups/CourseSemesterMapping";

import EnrollmentOps from "../pages/Academics/FacultyPortal/EnrollmentOps";
import CourseSemesterLocationTimeSlotMapping from "../pages/Setup/AcademicSetups/CourseSemesterLocationTimeSlotMapping";
import SetupFacultyDepartmentProgramLecturer from "../pages/Setup/AcademicSetups/SetupFacultyDepartmentProgramLecturer";
import FacultyDepartmentProgramExamsMarksCriteriaParameters from "../pages/Setup/AcademicSetups/FacultyDepartmentProgramExamsMarksCriteriaParameters";
import FacultyDepartmentProgramExams from "../pages/Setup/AcademicSetups/FacultyDepartmentProgramExams";
import FacultyDepartmentProgramExamsScheduleMapping from "../pages/Setup/AcademicSetups/FacultyDepartmentProgramExamsScheduleMapping";
import FacultyCourses from "../pages/Academics/FacultyPortal/FacultyCourses";
import StudentDetails from "../pages/Academics/Enrollment/StudentDetails";
import EnrolledStudentAcademicRecord from "../pages/Academics/StudentPortal/EnrolledStudentAcademicRecord";
import FacultyDepartmentProgramCourseMapping from "../pages/Setup/AcademicSetups/FacultyDepartmentProgramCourseMapping";
import StudentList from "../pages/Academics/Enrollment/StudentList";
import GenerateVoucher from "../pages/Academics/Enrollment/GenerateVoucher";
import GenerateVoucherForAcademicYear from "../pages/Academics/Enrollment/GenerateVoucherForAcademicYear";
import CourseSemesterLectureMapping from "../pages/Setup/AcademicSetups/CourseSemesterLectureMapping";
import StudentAttandance from "../pages/Academics/StudentPortal/StudentAttandance";
import StudentCourse from "../pages/Academics/StudentPortal/StudentCourse";
import ExamScreen from "../pages/Academics/FacultyPortal/ExamScreen";
import FacultyCurrentSemester from "../pages/Academics/FacultyPortal/FacultyCurrentSemester";
import FacultySchedule from "../pages/Academics/FacultyPortal/FacultySchedule";
import FacultyExamRecap from "../pages/Academics/FacultyPortal/FacultyExamRecap";
import FacultyMidTermAttendance from "../pages/Academics/FacultyPortal/FacultyMidTermAttendance";
import FacultyFinalTermAttendance from "../pages/Academics/FacultyPortal/FacultyFinalTermAttendance";
import FacultySemesterOutline from "../pages/Academics/FacultyPortal/FacultySemesterOutline";
import StudentCourseOutline from "../pages/Academics/StudentPortal/StudentCourseOutline";
import MidTermAttendance from "../pages/Academics/StudentPortal/MidTermAttendance";
import HODOperations from "../pages/Academics/HODPortal/HODOperations/HODOperations";
import HODFacultySemesterOutline from "../pages/Academics/HODPortal/HODOperations/HODFacultySemesterOutline";
import HODFacultySchedule from "../pages/Academics/HODPortal/HODOperations/HODFacultySchedule";
import HODFacultyExamRecap from "../pages/Academics/HODPortal/HODOperations/HODFacultyExamRecap";
import StudentStatus from "../pages/Academics/StudentPortal/StudentStatus";
import AcademicFeeChallan from "../pages/Academics/HODPortal/HODOperations/AcademicFeeChallan";
import FacultyFiles from "../pages/Academics/FacultyPortal/FacultyFiles";
import AttandanceCriteria from "../pages/Academics/Setup/AttandanceCriteria";
import DegreeProgramLedger from "../pages/Academics/ExaminationController/DegreeProgramLedger";
import DegreeProgramLedgerReport from "../pages/Academics/Reports/ControllerExamination/DegreeProgramLedgerReport";
import Vouchers from "../pages/Academics/StudentPortal/Vouchers";
import HomeScreen from "../pages/Finance/HomeScreen";
import ChallanForEnrollment from "../pages/Admissions/ChallanForEnrollment";
import HODDegreeProgramLedger from "../pages/Academics/HODPortal/HODDegreeProgramLedger";
import RemunerationAssignmentBill from "../pages/Academics/HODPortal/RemunerationAssignmentBill";
import DPLedgerReport from "../pages/Academics/Reports/HODReports/DPLedgerReport";
import HODCummulativeAttendance from "../pages/Academics/HODPortal/HODCummulativeAttendance";
import CumulativeAttendanceReport from "../pages/Academics/Reports/HODReports/CumulativeAttendanceReport";
import SemesterResultReport from "../pages/Academics/Reports/StudentReports/SemesterResultReport";
import ClassConduct from "../pages/Academics/HODPortal/ClassConduct";
import SubjectAllotment from "../pages/Academics/HODPortal/SubjectAllotment";
import SubjectAllotmentReport from "../pages/Academics/Reports/HODReports/SubjectAllotmentReport";
import StudentAbsentApproval from "../pages/Academics/HODPortal/StudentAbsentApproval";
import PassCertificate from "../pages/Academics/ExaminationController/PassCertificate";
import ClassConductReport from "../pages/Academics/Reports/HODReports/ClassConductReport";
import SubClassConduct from "../pages/Academics/HODPortal/SubClassConduct";
import VisitingFacultyPerHourRate from "../pages/Setup/AcademicSetups/VisitingFacultyPerHourRate";
import SetupCreditHours from "../pages/Setup/AcademicSetups/SetupCreditHours";
import CCRReport from "../pages/Academics/HODPortal/CCRReport";
import CCRSubReport from "../pages/Academics/Reports/HODReports/CCRSubReport";
import FacultyDepartmentTransfer from "../pages/Academics/HODPortal/FacultyDepartmentTransfer";
import AcademicFeeStatus from "../pages/Academics/ExaminationController/AcademicFeeStatus";
import AcademicFeeStatusReport from "../pages/Academics/Reports/ControllerExamination/AcademicFeeStatusReport";
import AcademicsInProgress from "../pages/Academics/AcademicsInProgress";
import SuppleImprovement from "../pages/Academics/StudentPortal/SuppleImprovement";
import SupplyImprovementFeeStructure from "../pages/Setup/AcademicSetups/SupplyImprovementFeeStructure";
import ExaminationControllerProcess from "../pages/Academics/ExaminationController/ExaminationControllerProcess";
import StudentFiles from "../pages/Academics/StudentPortal/StudentFiles";
import DebitNote from "../pages/Finance/Transactions/DebitNote";
import CreditNote from "../pages/Finance/Transactions/CreditNote";
import PurchaseInvoiceVoucher from "../pages/Finance/Transactions/PurchaseInvoiceVoucher";
import Gazette from "../pages/Academics/ExaminationController/Gazette";
import GazetteReport from "../pages/Academics/Reports/ControllerExamination/GazetteReport";
import AcademicSemesterResult from "../pages/Academics/ExaminationController/AcademicSemesterResult";
import AcademicSemesterResultReport from "../pages/Academics/Reports/ControllerExamination/AcademicSemesterResultReport";
import StudentAttendance from "../pages/Academics/ExaminationController/StudentAttendance";
import StudentAttendanceReport from "../pages/Academics/Reports/HODReports/StudentAttendanceReport";
import SetupDateOfAnnouncement from "../pages/Setup/AcademicSetups/SetupDateOfAnnouncement";
import TermBackSemester from "../pages/Academics/Enrollment/TermBackSemester";
import RenumerationTaskAssignment from "../pages/Setup/AcademicSetups/RenumerationTaskAssignment";
import SetupAssignmentRate from "../pages/Setup/AcademicSetups/SetupAssignmentRate";
import SetupFacultyRate from "../pages/Academics/Setup/SetupFacultyRate";
import SupplementaryAndImprovement from "../pages/Academics/StudentPortal/SupplementaryAndImprovement";
import YearDegreeMapping from "../pages/Setup/YearDegreeMapping";
import StudentTranscript from "../pages/Academics/ExaminationController/StudentTranscript";
// import StudentTranscriptReport from "../pages/Academics/Reports/ControllerExamination";
import CandidateAccessControl from "../pages/Setup/CandidateAccessControl";
import SetupCommitee from "../pages/Academics/HODPortal/SetupCommitee";
import DepartmentCommiteeMember from "../pages/Academics/HODPortal/DepartmentCommiteeMember";
import DepartmentCommitteeMember from "../pages/Academics/HODPortal/DepartmentCommiteeMember";
import RecountingAndRechecking from "../pages/Academics/StudentPortal/RecountingAndRechecking";
import CommitteeRecountingAndRechecking from "../pages/Academics/FacultyPortal/CommitteeRecountingAndRechecking";
import AdmissionStatistics from "../pages/Admissions/AdmissionStatistics";

var AdminRoutes = [
  // Setup Routes
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-single-02",
    component: Dashboard,
    layout: "/pages",
  },
  {
    path: "/menu",
    name: "Menu",
    icon: "nc-icon nc-single-02",
    component: Menu,
    layout: "/pages/setup",
  },
  {
    path: "/accesscontrol",
    name: "AccessControl",
    icon: "nc-icon nc-single-02",
    component: AccessControl,
    layout: "/pages/setup",
  },
  {
    path: "/roles",
    name: "Roles",
    icon: "nc-icon nc-single-02",
    component: Roles,
    layout: "/pages/setup",
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-single-02",
    component: Users,
    layout: "/pages/setup",
  },
  {
    path: "/allowances",
    name: "Allowances",
    icon: "nc-icon nc-single-02",
    component: Allowances,
    layout: "/pages/setup",
  },
  {
    path: "/leavedefination",
    name: "Leave Defination",
    icon: "nc-icon nc-single-02",
    component: LeaveDefination,
    layout: "/pages/setup",
  },
  {
    path: "/cadre",
    name: "Cadre",
    icon: "nc-icon nc-single-02",
    component: Cadre,
    layout: "/pages/setup",
  },
  {
    path: "/costcenter",
    name: "CostCenter",
    icon: "nc-icon nc-single-02",
    component: CostCenter,
    layout: "/pages/finance/maintain",
  },
  {
    path: "/designation",
    name: "Designation",
    icon: "nc-icon nc-single-02",
    component: Designation,
    layout: "/pages/setup",
  },
  {
    path: "/division",
    name: "Division",
    icon: "nc-icon nc-single-02",
    component: Division,
    layout: "/pages/setup",
  },
  {
    path: "/employeecategory",
    name: "EmployeeCategory",
    icon: "nc-icon nc-single-02",
    component: EmployeeCategory,
    layout: "/pages/setup",
  },
  {
    path: "/status",
    name: "Status",
    icon: "nc-icon nc-single-02",
    component: Status,
    layout: "/pages/setup",
  },
  {
    path: "/employeetype",
    name: "EmployeeType",
    icon: "nc-icon nc-single-02",
    component: EmployeeType,
    layout: "/pages/setup",
  },
  {
    path: "/grade",
    name: "Grade",
    icon: "nc-icon nc-single-02",
    component: Grade,
    layout: "/pages/setup",
  },
  {
    path: "/hiringcriteria",
    name: "HiringCriteria",
    icon: "nc-icon nc-single-02",
    component: HiringCriteria,
    layout: "/pages/setup",
  },
  {
    path: "/home",
    name: "HiringCriteria",
    icon: "nc-icon nc-single-02",
    component: HomeScreen,
    layout: "/pages/finance",
  },
  {
    path: "/payrolltype",
    name: "PayrollType",
    icon: "nc-icon nc-single-02",
    component: PayrollType,
    layout: "/pages/setup",
  },
  {
    path: "/department",
    name: "Department",
    icon: "nc-icon nc-single-02",
    component: Department,
    layout: "/pages/setup",
  },
  {
    path: "/departmentforfeestructure",
    name: "Department For Fee Structure",
    icon: "nc-icon nc-single-02",
    component: DepartmentForFeeStructure,
    layout: "/pages/setup",
  },
  {
    path: "/campustype",
    name: "Campus Type",
    icon: "nc-icon nc-single-02",
    component: CampusType,
    layout: "/pages/setup",
  },
  {
    path: "/groups",
    name: "Group",
    icon: "nc-icon nc-single-02",
    component: Group,
    layout: "/pages/setup",
  },
  {
    path: "/emailgroups",
    name: "Email Group",
    icon: "nc-icon nc-single-02",
    component: EmailGroup,
    layout: "/pages/setup",
  },
  {
    path: "/employmentstatus",
    name: "Employment Status",
    icon: "nc-icon nc-single-02",
    component: EmploymentStatus,
    layout: "/pages/setup",
  },
  {
    path: "/programs",
    name: "Programs",
    icon: "nc-icon nc-single-02",
    component: Programs,
    layout: "/pages/setup",
  },
  {
    path: "/degree",
    name: "Degree",
    icon: "nc-icon nc-single-02",
    component: Degree,
    layout: "/pages/setup",
  },
  {
    path: "/faculties",
    name: "Faculties",
    icon: "nc-icon nc-single-02",
    component: Faculties,
    layout: "/pages/setup",
  },
  {
    path: "/subject",
    name: "Subject",
    icon: "nc-icon nc-single-02",
    component: Subject,
    layout: "/pages/setup",
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "nc-icon nc-single-02",
    component: Course,
    layout: "/pages/setup",
  },
  {
    path: "/coursemapping",
    name: "Course Mapping",
    icon: "nc-icon nc-single-02",
    component: CourseMapping,
    layout: "/pages/setup",
  },
  {
    path: "/coursenumber",
    name: "Course Number",
    icon: "nc-icon nc-single-02",
    component: CourseNumber,
    layout: "/pages/setup",
  },
  {
    path: "/credithours",
    name: "Credit Hours",
    icon: "nc-icon nc-single-02",
    component: CreditHours,
    layout: "/pages/setup",
  },
  {
    path: "/classtimeslots",
    name: "Class Time Slots",
    icon: "nc-icon nc-single-02",
    component: ClassTimeSlots,
    layout: "/pages/setup",
  },
  {
    path: "/programtypes",
    name: "Program Types",
    icon: "nc-icon nc-single-02",
    component: ProgramTypes,
    layout: "/pages/setup",
  },

  {
    path: "/city",
    name: "City Of Birth",
    icon: "nc-icon nc-single-02",
    component: City,
    layout: "/pages/setup",
  },

  {
    path: "/benefits",
    name: "Benefits",
    icon: "nc-icon nc-single-02",
    component: Benefits,
    layout: "/pages/setup",
  },

  {
    path: "/additionalcharges",
    name: "Additional Charges",
    icon: "nc-icon nc-single-02",
    component: AdditionalCharges,
    layout: "/pages/setup",
  },

  {
    path: "/majors",
    name: "Majors",
    icon: "nc-icon nc-single-02",
    component: Majors,
    layout: "/pages/setup",
  },

  {
    path: "/areaofexpertisecompetencies",
    name: "Area of Expertise/Competencies",
    icon: "nc-icon nc-single-02",
    component: AreaofExpertiseCompetencies,
    layout: "/pages/setup",
  },
  {
    path: "/feestructure",
    icon: "fa fa-download",
    component: FeeStructure,
    layout: "/pages/setup",
  },
  {
    path: "/seattype",
    icon: "fa fa-download",
    component: SeatType,
    layout: "/pages/setup",
  },
  {
    path: "/blocks",
    icon: "fa fa-download",
    component: Blocks,
    layout: "/pages/setup",
  },
  {
    path: "/assignblock",
    icon: "fa fa-download",
    component: AssignBlock,
    layout: "/pages/setup",
  },
  {
    path: "/scorecriteria",
    icon: "fa fa-download",
    component: ScoreCriteria,
    layout: "/pages/setup",
  },
  {
    path: "/semesterfeestructure",
    icon: "fa fa-download",
    component: SemesterFeeStructure,
    layout: "/pages/setup",
  },
  {
    path: "/marks",
    icon: "fa fa-download",
    component: SetupMarks,
    layout: "/pages/setup",
  },
  {
    path: "/amissioneligibilty",
    icon: "fa fa-download",
    component: AdmissionEligibilty,
    layout: "/pages/setup",
  },
  {
    path: "/academicyear",
    icon: "fa fa-download",
    component: AcademicYear,
    layout: "/pages/setup",
  },
  {
    path: "/location",
    icon: "fa fa-download",
    component: Location,
    layout: "/pages/setup",
  },
  {
    path: "/section",
    icon: "fa fa-download",
    component: Section,
    layout: "/pages/setup",
  },
  {
    path: "/meritscore",
    icon: "fa fa-download",
    component: MeritScores,
    layout: "/pages/setup",
  },
  {
    path: "/meritparameters",
    icon: "fa fa-download",
    component: MeritParameters,
    layout: "/pages/setup",
  },
  {
    path: "/testtimedetails",
    icon: "fa fa-download",
    component: TestTimeDetail,
    layout: "/pages/setup",
  },
  {
    path: "/printbulkadmitslip",
    icon: "fa fa-download",
    component: PrintBulkAdmitSlip,
    layout: "/pages/setup",
  },
  {
    path: "/timeslots",
    icon: "fa fa-download",
    component: TimeSlot,
    layout: "/pages/setup",
  },
  {
    path: "/degreeparameters",
    icon: "fa fa-download",
    component: DegreeParameters,
    layout: "/pages/setup",
  },
  {
    path: "/coursesemfacdeptproglectmapping",
    icon: "fa fa-download",
    component: CourseSemFacDeptProgLectMapping,
    layout: "/pages/setup",
  },
  {
    path: "/facultydepartmentprogramcourse",
    icon: "fa fa-download",
    component: FacultyDepartmentProgramCourseMapping,
    layout: "/pages/setup",
  },
  {
    path: "/factorydeptproglectcoursescorecriteria",
    icon: "fa fa-download",
    component: FactoryDeptProgLectCourseScoreCriteria,
    layout: "/pages/setup",
  },
  {
    path: "/coursesemestermapping",
    icon: "fa fa-download",
    component: CourseSemesterMapping,
    layout: "/pages/setup",
  },
  {
    path: "/coursesemesterlecturemapping",
    icon: "fa fa-download",
    component: CourseSemesterLectureMapping,
    layout: "/pages/setup",
  },
  {
    path: "/coursesemesterlocationtimeslotmapping",
    icon: "fa fa-download",
    component: CourseSemesterLocationTimeSlotMapping,
    layout: "/pages/setup",
  },
  {
    path: "/setupfacultydepartmentprogramlecturer",
    icon: "fa fa-download",
    component: SetupFacultyDepartmentProgramLecturer,
    layout: "/pages/setup",
  },
  {
    path: "/facultydepartmentprogramexamsmarkscriteriaparameters",
    icon: "fa fa-download",
    component: FacultyDepartmentProgramExamsMarksCriteriaParameters,
    layout: "/pages/setup",
  },
  // {
  //   path: "/setupfacultydepartmentprogramexams",
  //   icon: "fa fa-download",
  //   component: FacultyDepartmentProgramExam,
  //   layout: "/pages/setup"
  // },
  {
    path: "/facultydepartmentprogramexams",
    icon: "fa fa-download",
    component: FacultyDepartmentProgramExams,
    layout: "/pages/setup",
  },

  {
    path: "/facultydepartmentprogramexamsschedulemapping",
    icon: "fa fa-download",
    component: FacultyDepartmentProgramExamsScheduleMapping,
    layout: "/pages/setup",
  },

  {
    path: "/setupdateofannouncement",
    icon: "fa fa-download",
    component: SetupDateOfAnnouncement,
    layout: "/pages/setup",
  },
  {
    path: "/yeardegreemapping",
    name: "Merit Report",
    component: YearDegreeMapping,
    layout: "/pages/setup",
  },

  {
    path: "/candidateaccesscontrol",
    name: "Candidate Access Control",
    component: CandidateAccessControl,
    layout: "/pages/setup",
  },
  // {
  //   path: "/courses",
  //   icon: "fa fa-download",
  //   component: Course,
  //   layout: "/pages/setup"
  // },

  //Finance Routes

  {
    path: "/chartofaccounts",
    icon: "fa fa-download",
    component: ChartOfAccounts,
    layout: "/pages/finance/maintenance",
  },

  // {
  //   path: "/chartofaccounts",
  //   icon: "fa fa-download",
  //   component: ChartOfAccounts,
  //   layout: "/pages/setup",
  // },

  //   HRMS Routes
  {
    path: "/departments",
    name: "Departments",
    icon: "nc-icon nc-single-02",
    component: Departments,
    layout: "/pages/hrms",
  },
  {
    path: "/probation",
    name: "Probation",
    icon: "nc-icon nc-single-02",
    component: Probations,
    layout: "/pages/hrms",
  },
  {
    path: "/payslip",
    name: "Payslip",
    icon: "nc-icon nc-single-02",
    component: PaySlip,
    layout: "/pages/hrms",
  },
  {
    path: "/profile/:id",
    name: "Profile",
    icon: "nc-icon nc-single-02",
    component: Profile,
    layout: "/pages/hrms",
  },
  {
    path: "/updateprofile",
    name: "Update Profile",
    icon: "nc-icon nc-single-02",
    component: UpdateProfile,
    layout: "/pages/hrms",
  },
  {
    path: "/employees",
    name: "Employees",
    icon: "nc-icon nc-single-02",
    component: Employees,
    layout: "/pages/hrms",
  },
  {
    path: "/timeinoutapproval",
    name: "Time In Out Approval",
    icon: "nc-icon nc-single-02",
    component: TimeInOutApproval,
    layout: "/pages/hrms",
  },

  //   Tms Routes
  {
    path: "/applyleave",
    name: "Apply Leave",
    icon: "nc-icon nc-single-02",
    component: ApplyLeave,
    layout: "/pages/tms",
  },
  {
    path: "/attendancestats",
    name: "Attendance Stats",
    icon: "nc-icon nc-single-02",
    component: AttendanceStats,
    layout: "/pages/tms",
  },
  {
    path: "/inorouttime",
    name: "In Or Out Time",
    icon: "nc-icon nc-single-02",
    component: InOrOutTime,
    layout: "/pages/tms",
  },
  {
    path: "/leaveapproval",
    name: "Leave Approval",
    icon: "nc-icon nc-single-02",
    component: LeaveApproval,
    layout: "/pages/tms",
  },
  {
    path: "/leavehistory",
    name: "Leave History",
    icon: "nc-icon nc-single-02",
    component: LeaveHistory,
    layout: "/pages/tms",
  },
  // {
  //   path: "/shiftscheduler",
  //   name: "Shift Scheduler",
  //   icon: "nc-icon nc-single-02",
  //   component: ShiftScheduler,
  //   layout: "/pages/tms",
  // },
  {
    path: "/timeadjustment",
    name: "Time Adjustment",
    icon: "nc-icon nc-single-02",
    component: TimeAdjustment,
    layout: "/pages/tms",
  },
  // {
  //   path: "/updatecalendar",
  //   name: "Update Calendar",
  //   icon: "nc-icon nc-single-02",
  //   component: UpdateCalendar,
  //   layout: "/pages/tms",
  // },
  {
    path: "/viewcalendar",
    name: "View Calendar",
    icon: "nc-icon nc-single-02",
    component: ViewCalendar,
    layout: "/pages/tms",
  },
  {
    path: "/websigninout",
    name: "Sign In Out",
    icon: "nc-icon nc-single-02",
    component: WebSignInOut,
    layout: "/pages/tms",
  },
  {
    path: "/manualattendance",
    name: "Manual Attendace",
    icon: "nc-icon nc-single-02",
    component: ManualAttendance,
    layout: "/pages/tms",
  },

  //TMS Incharge Routes
  {
    path: "/viewcalender",
    name: "View Calender",
    icon: "nc-icon nc-single-02",
    component: InchargeViewCalendar,
    layout: "/pages/tms/incharge",
  },

  {
    path: "/updatecalender",
    name: "Update Calender",
    icon: "nc-icon nc-single-02",
    component: InchargeUpdateCalendar,
    layout: "/pages/tms/incharge",
  },
  {
    path: "/applyleave",
    name: "Apply Leave",
    icon: "nc-icon nc-single-02",
    component: InchargeApplyLeave,
    layout: "/pages/tms/incharge",
  },
  {
    path: "/leaveapproval",
    name: "Leave Approval",
    icon: "nc-icon nc-single-02",
    component: InchargeLeaveApproval,
    layout: "/pages/tms/incharge",
  },
  {
    path: "/leavehistory",
    name: "Leave History",
    icon: "nc-icon nc-single-02",
    component: InchargeLeaveHistory,
    layout: "/pages/tms/incharge",
  },
  {
    path: "/timeadjustment",
    name: "Time Adjustment",
    icon: "nc-icon nc-single-02",
    component: InchargeTimeAdjustment,
    layout: "/pages/tms/incharge",
  },
  {
    path: "/attendancestats",
    name: "Attendance Stats",
    icon: "nc-icon nc-single-02",
    component: InchargeAttendanceStats,
    layout: "/pages/tms/incharge",
  },

  {
    path: "/inouttiming",
    name: "Attendance Stats",
    icon: "nc-icon nc-single-02",
    component: InchargeInOrOutTime,
    layout: "/pages/tms/incharge",
  },
  {
    path: "/shiftscheduler",
    name: "Shift Scheduler",
    icon: "nc-icon nc-single-02",
    component: InchargeShiftScheduler,
    layout: "/pages/tms/incharge",
  },
  {
    path: "/assignwebsigninout",
    name: "Shift Scheduler",
    icon: "nc-icon nc-single-02",
    component: InchargeWebSignInOut,
    layout: "/pages/tms/incharge",
  },

  //   Payroll Routes
  {
    path: "/contracts",
    name: "Contracts",
    icon: "nc-icon nc-single-02",
    component: Contracts,
    layout: "/pages/payroll",
  },
  {
    path: "/payslipstatus",
    name: "Payslip Status",
    icon: "fa fa-download",
    component: PayslipStatus,
    layout: "/pages/payroll",
  },
  {
    path: "/payrollstats",
    name: "Payroll Stats",
    icon: "fa fa-download",
    component: PayrollStats,
    layout: "/pages/payroll",
  },
  {
    path: "/addemail",
    name: "Add Email",
    icon: "fa fa-download",
    component: AddEmail,
    layout: "/pages/announcement",
  },
  {
    path: "/announcement",
    name: "Announcement",
    icon: "fa fa-download",
    component: Announcement,
    layout: "/pages/announcement",
  },

  //   Workflow Routes
  {
    path: "/acrfaculty",
    name: "ACR Faculty",
    icon: "fa fa-download",
    component: ACRFaculty,
    layout: "/pages/workflow",
  },
  {
    path: "/acrbps1to4",
    name: "ACRBPS1To4",
    icon: "fa fa-download",
    component: ACRBPS1To4,
    layout: "/pages/workflow",
  },
  {
    path: "/acrbps5to15",
    name: "ACRBPS5To15",
    icon: "fa fa-download",
    component: ACRBPS5To15,
    layout: "/pages/workflow",
  },
  {
    path: "/acrbps16andabove",
    name: "ACRBPS5To15",
    icon: "fa fa-download",
    component: ACRBPS16AndAbove,
    layout: "/pages/workflow",
  },

  //Admision
  {
    path: "/admissionformundergraduate",
    icon: "fa fa-download",
    component: AdmissionForm,
    layout: "/pages/admission",
  },
  {
    path: "/admissionformpostgraduate",
    icon: "fa fa-download",
    component: AdmissionFormPostgraduate,
    layout: "/pages/admission",
  },
  {
    path: "/admissionformb",
    icon: "fa fa-download",
    component: AdmissionFormB,
    layout: "/pages/admission",
  },
  {
    path: "/postgraduateadmissionform",
    icon: "fa fa-download",
    component: PostgraduateAdmissionForm,
    layout: "/pages/admission",
  },
  {
    path: "/AdmitCard",
    icon: "fa fa-download",
    component: AdmitCard,
    layout: "/pages/admission",
  },
  {
    path: "/feestructure",
    icon: "fa fa-download",
    component: AdmissionFeeStructure,
    layout: "/pages/admission",
  },
  {
    path: "/importantdates",
    icon: "fa fa-download",
    component: ImportantDates,
    layout: "/pages/admission",
  },
  {
    path: "/voucherextenddate",
    icon: "fa fa-download",
    component: VoucherExtendDate,
    layout: "/pages/admission",
  },
  {
    path: "/prospectus",
    icon: "fa fa-download",
    component: Prospectus,
    layout: "/pages/admission",
  },
  {
    path: "/instruction",
    icon: "fa fa-download",
    component: Instruction,
    layout: "/pages/admission",
  },
  {
    path: "/eligibiltycriteria",
    icon: "fa fa-download",
    component: EligibilityCriteria,
    layout: "/pages/admission",
  },
  {
    path: "/eligibiltycriteriageneral",
    icon: "fa fa-download",
    component: EligibilityCriteriaGeneral,
    layout: "/pages/admission",
  },
  {
    path: "/admissionprocedure",
    icon: "fa fa-download",
    component: AdmissionProcedure,
    layout: "/pages/admission",
  },
  {
    path: "/admissionapplication",
    icon: "fa fa-download",
    component: AdmissionApplication,
    layout: "/pages/admission",
  },
  {
    path: "/admissionsamplepaper",
    icon: "fa fa-download",
    component: AdmissionSamplePaper,
    layout: "/pages/admission",
  },
  {
    path: "/admissionreportprint",
    icon: "fa fa-download",
    component: AdmissionReportPrint,
    layout: "/pages/admission",
  },
  {
    path: "/admissionfeechallan",
    icon: "fa fa-download",
    component: AdmissionFeeChallan,
    layout: "/pages/admission",
  },
  {
    path: "/admissioncandidatestatus",
    icon: "fa fa-download",
    component: AdmissionCandidateStatus,
    layout: "/pages/admission",
  },
  {
    path: "/documentchecklist",
    icon: "fa fa-download",
    component: DocumentCheckList,
    layout: "/pages/admission",
  },
  {
    path: "/dashboard",
    icon: "fa fa-download",
    component: AdmissionDashboard,
    layout: "/pages/admission",
  },
  {
    path: "/challan",
    icon: "fa fa-download",
    component: AdmissionChallan,
    layout: "/pages/admission",
  },
  {
    path: "/admissionslip",
    icon: "fa fa-download",
    component: AdmissionSlip,
    layout: "/pages/admission",
  },
  {
    path: "/admissiontest",
    icon: "fa fa-download",
    component: AdmissionTest,
    layout: "/pages/admission",
  },
  {
    path: "/candidatestatusreport",
    icon: "fa fa-download",
    component: CandidatesStatusReport,
    layout: "/pages/admission",
  },
  {
    path: "/interviewscreening",
    icon: "fa fa-download",
    component: InterviewScreening,
    layout: "/pages/admission",
  },
  {
    path: "/admissionscheduler",
    icon: "fa fa-download",
    component: AdmissionScheduler,
    layout: "/pages/admission",
  },
  // {
  //   path: "/meritlist",
  //   icon: "fa fa-download",
  //   component: MeritList,
  //   layout: "/pages/admission",
  // },
  {
    path: "/additionalprogram",
    icon: "fa fa-download",
    component: AdditionalProgram,
    layout: "/pages/admission",
  },
  {
    path: "/resetpassword",
    icon: "fa fa-download",
    component: ResetPasswordUG,
    layout: "/pages/admission",
  },
  {
    path: "/ugviewtotalreport",
    icon: "fa fa-download",
    component: UGViewTotalReport,
    layout: "/pages/admission",
  },

  {
    path: "/ugtotalreport",
    icon: "fa fa-download",
    component: UGTotalReport,
    layout: "/pages/admission",
  },
  
  {
    path: "/admissionstatistics",
    icon: "fa fa-download",
    component: AdmissionStatistics,
    layout: "/pages/admission",
  },

  // {
  //   path: "/OpenMeritList",
  //   name: "No Page Found",
  //   component: OpenMeritList,
  //   layout: "/pages/Admissions/MeritList",
  // },

  {
    path: "/manualopenmeritlistseatallocation",
    name: "No Page Found",
    component: ManualOpMlSeatAllocation,
    layout: "/pages/admission/meritlist",
  },

  {
    path: "/manualseatconsideration",
    name: "No Page Found",
    component: ManualSeatConsideration,
    layout: "/pages/admission/meritlist",
  },

  {
    path: "/addmanualpreferances",
    name: "No Page Found",
    component: AddManualPreferances,
    layout: "/pages/admission/meritlist",
  },
  {
    path: "/generatemeritlist",
    name: "No Page Found",
    component: GenerateMeritList,
    layout: "/pages/admission/meritlist",
  },
  {
    path: "/approverejectmeritlist",
    name: "No Page Found",
    component: ApproveRejectMeritList,
    layout: "/pages/admission/meritlist",
  },
  {
    path: "/selffinanceoperations",
    name: "No Page Found",
    component: SelfFinanceOperations,
    layout: "/pages/admission/meritlist",
  },
  {
    path: "/meritreport",
    name: "Merit Report",
    component: MeritListReport,
    layout: "/pages/admission/meritlist",
  },
  {
    path: "/registeredcandidatesdegreeprogram",
    name: "Registered Candidates Degree Program",
    component: RegisteredCandidatesDegreeProgram,
    layout: "/pages/admission",
  },
  {
    path: "/RegisteredCandidatesDegreeProgramReport",
    name: "Registered Candidates Degree Program",
    component: RegisteredCandidatesDegreeProgramReport,
    layout: "/pages/admission",
  },
  {
    path: "/updateEmployeeProfile",
    name: "Update Employee Profile",
    component: UpdateEmployeeProfile,
    layout: "/pages/admission",
  },

  // {
  //   path: "/Merit_List",
  //   name: "No Page Found",
  //   component: Merit_List,
  //   layout: "/pages/Admissions/MeritList",
  // },

  // {
  //   path: "/generatemeritlist",
  //   name: "No Page Found",
  //   component: Generate_MeritList,
  //   layout: "/pages/admission",
  // },

  // {
  //   path: "/secondmeritList",
  //   name: "No Page Found",
  //   component: SecondMeritList,
  //   layout: "/pages/admission",
  // },

  // {
  //   path: "/secondgeneratemeritList",
  //   name: "No Page Found",
  //   component: SecondGenerateMeritList,
  //   layout: "/pages/admission",
  // },

  {
    path: "/selectedcandidates",
    name: "Selected Candidates",
    component: SelectedCandidates,
    layout: "/pages/admission",
  },

  {
    path: "/printofferletter",
    name: "Print Offer Letter",
    component: PrintOfferLetter,
    layout: "/pages/admission",
  },
  {
    path: "/secureadmissionseatfacultywise",
    name: "Secure Admission Seat Faculty Wise",
    component: SecureAdmissionSeatFacultyWise,
    layout: "/pages/admission",
  },

  {
    path: "/idcard",
    name: "IDCard",
    component: IDCard,
    layout: "/pages/admission",
  },

  //Finance Routes

  {
    path: "/paymentvouchers",
    icon: "fa fa-download",
    component: PaymentVoucher,
    layout: "/pages/finance/transactions",
  },
  {
    path: "/receiptvouchers",
    icon: "fa fa-download",
    component: ReceiptVoucher,
    layout: "/pages/finance/transactions",
  },
  {
    path: "/generalledgerreport",
    icon: "fa fa-download",
    component: GeneralLedgerReport,
    layout: "/pages/finance",
  },
  {
    path: "/generalledger",
    icon: "fa fa-download",
    component: GeneralLedger,
    layout: "/pages/finance",
  },
  {
    path: "/receivablesandpayablesreport",
    icon: "fa fa-download",
    component: ReceivablesAndPayablesReport,
    layout: "/pages/finance",
  },
  {
    path: "/cashbookreport",
    icon: "fa fa-download",
    component: CashbookReport,
    layout: "/pages/finance",
  },
  {
    path: "/createinvoice",
    icon: "fa fa-download",
    component: CreateInvoice,
    layout: "/pages/finance",
  },
  {
    path: "/journals",
    icon: "fa fa-download",
    component: Journals,
    layout: "/pages/finance/maintenance",
  },
  {
    path: "/accountnature",
    icon: "fa fa-download",
    component: AccountNature,
    layout: "/pages/finance/maintenance",
  },
  {
    path: "/transactiontype",
    icon: "fa fa-download",
    component: TransactionType,
    layout: "/pages/finance/maintenance",
  },
  {
    path: "/customer",
    icon: "fa fa-download",
    component: CustomerSetup,
    layout: "/pages/finance/maintenance",
  },
  {
    path: "/vendor",
    icon: "fa fa-download",
    component: VendorSetup,
    layout: "/pages/finance/maintenance",
  },

  {
    path: "/journalvoucher",
    icon: "fa fa-download",
    component: JournalVouchers,
    layout: "/pages/finance/transactions",
  },
  {
    path: "/purchaseinvoicevoucher",
    icon: "fa fa-download",
    component: PurchaseInvoiceVoucher,
    layout: "/pages/finance/transactions",
  },
  {
    path: "/debitnote",
    icon: "fa fa-download",
    component: DebitNote,
    layout: "/pages/finance/transactions",
  },
  {
    path: "/creditnote",
    icon: "fa fa-download",
    component: CreditNote,
    layout: "/pages/finance/transactions",
  },
  {
    path: "/company",
    icon: "fa fa-download",
    component: Company,
    layout: "/pages/finance/maintenance",
  },
  {
    path: "/saleinvoicevouchers",
    icon: "fa fa-download",
    component: SaleInvoiceVouchers,
    layout: "/pages/finance/transactions",
  },
  //

  {
    path: "/changepassword",
    name: "Change Password",
    icon: "fa fa-download",
    component: ChangePassword,
    layout: "/pages",
  },
  {
    path: "/resetpassword",
    name: "Change Password",
    icon: "fa fa-download",
    component: ResetPassword,
    layout: "/pages",
  },
  {
    path: "/enrolledstudentacademicrecord",
    name: "Student Enrollment",
    icon: "fa fa-download",
    component: EnrolledStudentAcademicRecord,
    layout: "/pages/academics/enrollment",
  },

  // \ Academics Setups
  // Student Imports
  {
    path: "/studentattandance/:id1/:id2",
    name: "Current Semester",
    icon: "fa fa-download",
    component: StudentAttandance,
    layout: "/pages/academics/student",
  },
  {
    path: "/studentcourse/:id1/:id2",
    name: "Current Semester",
    icon: "fa fa-download",
    component: StudentCourse,
    layout: "/pages/academics/student",
  },
  {
    path: "/studentcourseoutline/:id1/:id2",
    icon: "fa fa-download",
    component: StudentCourseOutline,
    layout: "/pages/academics/student",
  },
  // {
  //   path: "/midtermattendance/:id1/:id2",
  //   icon: "fa fa-download",
  //   component: MidTermAttendance,
  //   layout: "/pages/academics/faculty",
  // },
  // {
  //   path: "/finaltermattendance/:id1/:id2",
  //   icon: "fa fa-download",
  //   component: StudentCourseOutline,
  //   layout: "/pages/academics/student",
  // },
  {
    path: "/studentfiles/:id1/:id2",
    icon: "fa fa-download",
    component: StudentFiles,
    layout: "/pages/academics/student",
  },
  {
    path: "/currentsemester",
    name: "Current Semester",
    icon: "fa fa-download",
    component: CurrentSemester,
    layout: "/pages/academics/student",
  },
  {
    path: "/currentweekschedule",
    name: "Current Week Schedule",
    icon: "fa fa-download",
    component: CurrentWeekSchedule,
    layout: "/pages/academics/student",
  },
  {
    path: "/examschedule",
    name: "Exam Schedule",
    icon: "fa fa-download",
    component: ExamSchedule,
    layout: "/pages/academics/student",
  },
  {
    path: "/previoussemestercourses",
    name: "Previous Semester Courses",
    icon: "fa fa-download",
    component: PreviousSemesterCourses,
    layout: "/pages/academics/student",
  },
  {
    path: "/challanforenrollment",
    name: "Previous Semester Courses",
    icon: "fa fa-download",
    component: ChallanForEnrollment,
    layout: "/pages/academics/student",
  },
  {
    path: "/semesterresult",
    name: "Semester Result",
    icon: "fa fa-download",
    component: SemesterResult,
    layout: "/pages/academics/student",
  },
  {
    path: "/studentprofile",
    name: "Student Profile",
    icon: "fa fa-download",
    component: StudentProfile,
    layout: "/pages/academics/student",
  },
  {
    path: "/studentstatus",
    name: "Student Status",
    icon: "fa fa-download",
    component: StudentStatus,
    layout: "/pages/academics/student",
  },
  {
    path: "/vouchers",
    name: "Current Semester",
    icon: "fa fa-download",
    component: Vouchers,
    layout: "/pages/academics/students",
  },

  {
    path: "/suppleimprovement",
    name: "Supple Improvement",
    icon: "fa fa-download",
    component: SuppleImprovement,
    layout: "/pages/academics/students",
  },
  {
    path: "/supplementaryandimprovement",
    name: "Supplementary And Improvement",
    icon: "fa fa-download",
    component: SupplementaryAndImprovement,
    layout: "/pages/academics/students",
  },
  {
    path: "/recountingandrechecking",
    name: "Recounting And Rechecking",
    icon: "fa fa-download",
    component: RecountingAndRechecking,
    layout: "/pages/academics/students",
  },
  {
    path: "/committeerecountingandrechecking",
    name: "Recounting And Rechecking",
    icon: "fa fa-download",
    component: CommitteeRecountingAndRechecking,
    layout: "/pages/academics/faculty",
  },

  // academics setup

  {
    path: "/attendancecriteria",
    name: "Attendance Criteria",
    icon: "fa fa-download",
    component: AttandanceCriteria,
    layout: "/pages/academics/setup",
  },
  {
    path: "/visitingfacultyperhourrate",
    name: "Attendance Criteria",
    icon: "fa fa-download",
    component: VisitingFacultyPerHourRate,
    layout: "/pages/academics/setup",
  },
  {
    path: "/renumerationtaskassignment",
    icon: "fa fa-download",
    component: RenumerationTaskAssignment,
    layout: "/pages/setup",
  },
  {
    path: "/setupcredithours",
    name: "Attendance Criteria",
    icon: "fa fa-download",
    component: SetupCreditHours,
    layout: "/pages/academics/setup",
  },
  {
    path: "/setupfacultyrate",
    name: "Attendance Criteria",
    icon: "fa fa-download",
    component: SetupFacultyRate,
    layout: "/pages/academics/setup",
  },

  //Faulty imports

  {
    path: "/assigntestmarks",
    name: "Assign Test Marks",
    icon: "fa fa-download",
    component: AssignTestMarks,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/examscreen",
    name: "Assign Test Marks",
    icon: "fa fa-download",
    component: ExamScreen,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultycurrentsemester",
    name: "Assign Test Marks",
    icon: "fa fa-download",
    component: FacultyCurrentSemester,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultyschedule/:id1/:id2",
    icon: "fa fa-download",
    component: FacultySchedule,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultyexamrecap/:id1/:id2",
    icon: "fa fa-download",
    component: FacultyExamRecap,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultymidtermattendance/:id1/:id2",
    icon: "fa fa-download",
    component: FacultyMidTermAttendance,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultyfinaltermattendance/:id1/:id2",
    icon: "fa fa-download",
    component: FacultyFinalTermAttendance,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultysemesteroutline/:id1/:id2",
    icon: "fa fa-download",
    component: FacultySemesterOutline,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultyfiles/:id1/:id2",
    icon: "fa fa-download",
    component: FacultyFiles,
    layout: "/pages/academics/faculty",
  },

  //HOD Imports

  {
    path: "/hodoperations",
    icon: "fa fa-download",
    component: HODOperations,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/hodfacultysemesteroutline/:id1/:id2",
    icon: "fa fa-download",
    component: HODFacultySemesterOutline,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/hodfacultyschedule/:id1/:id2",
    icon: "fa fa-download",
    component: HODFacultySchedule,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/hodfacultyexamrecap/:id1/:id2",
    icon: "fa fa-download",
    component: HODFacultyExamRecap,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/academicfeechallan",
    icon: "fa fa-download",
    component: AcademicFeeChallan,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/hoddegreeprogramledger",
    icon: "fa fa-download",
    component: HODDegreeProgramLedger,
    layout: "/pages/academics/hod",
  },
  {
    path: "/ccrreport",
    icon: "fa fa-download",
    component: CCRReport,
    layout: "/pages/academics/hod",
  },
  {
    path: "/remunerationassigmentbill",
    icon: "fa fa-download",
    component: RemunerationAssignmentBill,
    layout: "/pages/academics/hod",
  },

  // new hod page added
  {
    path: "/facultydepartmenttransfer",
    icon: "fa fa-download",
    component: FacultyDepartmentTransfer,
    layout: "/pages/academics/hod",
  },
  {
    path: "/hoddpledgerreport",
    icon: "fa fa-download",
    component: DPLedgerReport,
    layout: "/pages/academics/reports",
  },
  {
    path: "/hodcumulativeattendance",
    icon: "fa fa-download",
    component: HODCummulativeAttendance,
    layout: "/pages/academics/hod",
  },
  {
    path: "/hodcumulativeattendancereport",
    icon: "fa fa-download",
    component: CumulativeAttendanceReport,
    layout: "/pages/academics/reports",
  },

  {
    path: "/classconduct",
    icon: "fa fa-download",
    component: ClassConduct,
    layout: "/pages/academics/hod",
  },
  {
    path: "/subjectallotment",
    icon: "fa fa-download",
    component: SubjectAllotment,
    layout: "/pages/academics/hod",
  },
  {
    path: "/studentabsentapproval",
    icon: "fa fa-download",
    component: StudentAbsentApproval,
    layout: "/pages/academics/hod",
  },
  {
    path: "/classconductreport",
    icon: "fa fa-download",
    component: ClassConductReport,
    layout: "/pages/academics/reports",
  },

  {
    path: "/subclassconduct",
    icon: "fa fa-download",
    component: SubClassConduct,
    layout: "/pages/academics/hod",
  },

  {
    path: "/setupcommitee",
    icon: "fa fa-download",
    component: SetupCommitee,
    layout: "/pages/academics/hod",
  },
  {
    path: "/departmentcommitteemember",
    icon: "fa fa-download",
    component: DepartmentCommitteeMember,
    layout: "/pages/academics/hod",
  },
  // {
  //   path: "/hodcumulativeattendancereport",
  //   icon: "fa fa-download",
  //   component: CumulativeAttendanceReport,
  //   layout: "/pages/academics/reports",
  // },

  

  //Academic Reports
  {
    path: "/degreeprogramledger",
    icon: "fa fa-download",
    component: DegreeProgramLedger,
    layout: "/pages/academics/reports",
  },
  {
    path: "/passcertificate",
    icon: "fa fa-download",
    component: PassCertificate,
    layout: "/pages/academics/reports",
  },
  {
    path: "/gazette",
    icon: "fa fa-download",
    component: Gazette,
    layout: "/pages/academics/reports",
  },
  {
    path: "/gazettereport",
    icon: "fa fa-download",
    component: GazetteReport,
    layout: "/pages/academics/reports",
  },
  {
    path: "/studenttranscript",
    name: "Student Transcript",
    icon: "fa fa-download",
    component: StudentTranscript,
    layout: "/pages/academics/reports",
  },
  // {
  //   path: "/studenttranscriptreport",
  //   icon: "fa fa-download",
  //   component: GazetteReport,
  //   layout: "/pages/academics/reports",
  // },
  {
    path: "/academicsemesterresult",
    icon: "fa fa-download",
    component: AcademicSemesterResult,
    layout: "/pages/academics/reports",
  },
  {
    path: "/academicaemesterresultreport",
    icon: "fa fa-download",
    component: AcademicSemesterResultReport,
    layout: "/pages/academics/reports",
  },
  {
    path: "/degreeprogramledgerreport",
    icon: "fa fa-download",
    component: DegreeProgramLedgerReport,
    layout: "/pages/academics/reports",
  },

  {
    path: "/studentattendance",
    icon: "fa fa-download",
    component: StudentAttendance,
    layout: "/pages/academics/reports",
  },
  {
    path: "/studentattendancereport",
    icon: "fa fa-download",
    component: StudentAttendanceReport,
    layout: "/pages/academics/reports",
  },

  {
    path: "/feestatus",
    icon: "fa fa-download",
    component: AcademicFeeStatus,
    layout: "/pages/academics/reports",
  },
  {
    path: "/feestatusreport",
    icon: "fa fa-download",
    component: AcademicFeeStatusReport,
    layout: "/pages/academics/reports",
  },

  //General Academics Imports
  {
    path: "/enrollmentops",
    name: "Enrollmen tOps",
    icon: "fa fa-download",
    component: EnrollmentOps,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/facultycourses",
    name: "Enrollmen tOps",
    icon: "fa fa-download",
    component: FacultyCourses,
    layout: "/pages/academics/faculty",
  },
  {
    path: "/academicsinprogress",
    name: "Academics In Progress",
    icon: "fa fa-download",
    component: AcademicsInProgress,
    layout: "/pages/academics/hod",
  },
  {
    path: "/supplyimprovementfeestructure",
    name: "Academics In Progress",
    icon: "fa fa-download",
    component: SupplyImprovementFeeStructure,
    layout: "/pages/academics/setup",
  },
  {
    path: "/assignmentrate",
    name: "Academics In Progress",
    icon: "fa fa-download",
    component: SetupAssignmentRate,
    layout: "/pages/academics/setup",
  },

  {
    path: "/examinationcontrollerprocess",
    name: "Academics In Progress",
    icon: "fa fa-download",
    component: ExaminationControllerProcess,
    layout: "/pages/academics/examinationcontroller",
  },

  //Enrollment
  {
    path: "/studentlist",
    name: "Enrollmen tOps",
    icon: "fa fa-download",
    component: StudentList,
    layout: "/pages/academics/enrollment",
  },
  {
    path: "/studentdetails/:id",
    name: "Enrollmen tOps",
    icon: "fa fa-download",
    component: StudentDetails,
    layout: "/pages/academics/enrollment",
  },

  {
    path: "/generatevoucher",
    name: "Generate Voucher",
    icon: "fa fa-download",
    component: GenerateVoucher,
    layout: "/pages/academics/enrollment",
  },

  {
    path: "/generatevoucherforacademicyear",
    name: "Generate Voucher Of Next Year",
    icon: "fa fa-download",
    component: GenerateVoucherForAcademicYear,
    layout: "/pages/academics/enrollment",
  },

 
  {
    path: "/termbacksemester",
    icon: "fa fa-download",
    component: TermBackSemester,
    layout: "/pages/academics/enrollment",
  },
  //End Academics imports

  //Student Reports
  {
    path: "/semesterresultreport",
    name: "Semester Result Report",
    icon: "fa fa-download",
    component: SemesterResultReport,
    layout: "/pages/academics/student",
  },

  {
    path: "/lecturers",
    name: "No Page Found",
    component: Lecturers,
    layout: "/pages/setup",
  },

  //Hostel
  {
    path: "/managehostel",
    icon: "fa fa-download",
    component: ManageHostel,
    layout: "/pages/hostel",
  },
  {
    path: "/hostelroom",
    icon: "fa fa-download",
    component: HostelRoom,
    layout: "/pages/hostel",
  },
  {
    path: "/hostelinstruction",
    icon: "fa fa-download",
    component: HostelInstruction,
    layout: "/pages/hostel",
  },
  {
    path: "/roomcategory",
    icon: "fa fa-download",
    component: RoomCategory,
    layout: "/pages/hostel",
  },
  {
    path: "/hostelchecklist",
    icon: "fa fa-download",
    component: HostelChecklist,
    layout: "/pages/hostel",
  },
  {
    path: "/hosteleligibilityCriteria",
    icon: "fa fa-download",
    component: HostelEligibiltyCriteria,
    layout: "/pages/hostel",
  },
  {
    path: "/hostelapplications",
    icon: "fa fa-download",
    component: HostelApplications,
    layout: "/pages/hostel",
  },

  {
    path: "/nopagefound",
    name: "No Page Found",
    component: NoPageFound,
    layout: "/pages",
  },
];

export default AdminRoutes;
