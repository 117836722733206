import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Button, Col, Input, Row, Table } from "reactstrap";
import {
  Insert,
  Search,
  Select,
  SessionStorage,
  academicYearId,
  partYearID,
  semesterId,
  undergraduateId,
} from "../../../common/SetupMasterEnum";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import ClassConductReport from "../Reports/HODReports/ClassConductReport";
import { SUBJECT_ALLOTMENT_REPORT } from "../../../utils/UrlConstants";
import { PostRequest } from "../../../utils/Config";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import CCRSubReport from "../Reports/HODReports/CCRSubReport";
import { tConvert } from "../../../functions/DateFormatFunction";
import SubjectAllotmentReport from "../Reports/HODReports/SubjectAllotmentReport";

const SubjectAllotment = () => {
  const initialFormFields = {
    OperationId: Select,
    ParameterID: 1,
    AcademicYearID: 0,
    PartID: 0,
    SemesterID: 0,
    FacultyDepartmentProgramID: 0,
    EmployeeID: 0,
    UserID: decryptData("loginId", SessionStorage),
    LecturerID: 0,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialSearchFields = {
    OperationId: Select,
    ParameterID: 1,
    AcademicYearID: 0,
    PartID: 0,
    SemesterID: 0,
    FacultyDepartmentProgramID: 0,
    EmployeeID: 0,
    UserID: decryptData("loginId", SessionStorage),
    LecturerID: 0,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  let ref = useRef(null);
  const dispatch = useDispatch();
  const [academicYear] = useSetupDetailList(academicYearId);
  const [semesterList] = useSetupDetailList(semesterId, SearchFields?.PartID);
  const [partList] = useSetupDetailList(partYearID);

  useEffect(() => {
    // onChange_Select_Department_Program({
    //   operationID: 6,
    //   caseID: 2,
    //   paremeterID: 0,
    // }).then((res) =>
    //   dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    // );
    getClassConductReport();
  }, []);

  function getClassConductReport() {
    PostRequest(SUBJECT_ALLOTMENT_REPORT, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table?.[0],
            // FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        // let departmentName = {
        //   name: "departmentName",
        //   value: res?.data?.Table3,
        // };
        // dispatch({
        //   type: SET_INITIAL_DROPDOWN_FORM_STATE,
        //   payload: departmentName,
        // });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "ClassDate", name: "Class" },
    { field: "ChsDue", name: "CHs (Due)" },
    { field: "ChsCond", name: "CHs (Cond)" },
    { field: "StartTime", name: "From" },
    { field: "EndTime", name: "To" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  // const handleAddChange = (e) => {
  //   let data = { name: e.target.name, value: e.target.value };
  //   dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  // };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          list={academicYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
        />
      </Col>
       <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartID"
          list={partList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.PartID}
          required
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="SemesterID"
          list={semesterList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.SemesterID}
          disabled={SearchFields?.PartID == 0}
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      OperationId: 1,
      ParameterID: 1,
      // EmployeeID: 0,
      // UserID: decryptData("loginId", SessionStorage),
      // UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(SUBJECT_ALLOTMENT_REPORT, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
  };

  const printChallan = useReactToPrint({
    content: () => ref.current,
  });

  const customButton = (
    <>
    {TableList?.length > 0 ? (
      <Button color="primary" className="btn" onClick={printChallan}>
        Print
      </Button>
    ) : (null)}
    
      <div style={{ display: "none" }}>
        {" "}
        <SubjectAllotmentReport ref={ref} />
      </div>
    </>
  );

  const customTable = (
    <Table bordered striped responsive style={{ width: "100%" }}>
      <thead>
        <tr className="text-center">
          <th>S.No</th>
          <th>Faculty</th>
          <th>Course Code</th>
          <th>Course</th>
          <th>CHs</th>
        </tr>
      </thead>
      <tbody>
        {TableList?.length > 0 ? (
          TableList?.map((item, ind) => {
            return (
              <tr className="text-center" key={ind}>
                <td>{ind + 1}</td>
                <td>{item.name1}</td>
                <td>{item.Code}</td>
                <td>{item.Name}</td>
                <td>{item.CrHrs}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={6} className="text-center">
              No Data Available
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  return (
    <>
      <CrudFormComponent
        formName="Subject Allotment Report"
        modalSize="lg"
        searchSubmit={submitSearch}
        searchPanel={searchPanel}
        customButton={customButton}
        customTable={customTable}
        cancelSearch={cancelSearch}
        tableColumns={columns}
        tableRows={TableList?.Table?.[0]}
        featureList={menuTable?.Table2?.filter(
          (x) => x.MenuId === selectedMenu
        )}
      />
    </>
  );
};

export default SubjectAllotment;
