import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Delete,
  Search,
  Select,
  SessionStorage,
  campusCity,
} from "../../../common/SetupMasterEnum";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  RESET_FORM_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import {
  ACAD_SETUPCOMMITEE,
  SETUP_ACADEMIC_YEAR,
} from "../../../utils/UrlConstants";
import { PostRequest } from "../../../utils/Config";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { Fragment } from "react";
import { Col } from "reactstrap";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";

const SetupCommitee = () => {
  const initialFormFields = {
    operationId: Select,
    setupCommitteeID: 0,
    employeeID: decryptData("EmplId", SessionStorage),
    academicyearID: 0,
    campusCityID: campusCity,
    facultyDepartmentProgramID: 0,
    committeeName: "",
    isActive: true,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialSearchFields = {
    operationId: Search,
    setupCommitteeID: 0,
    employeeID: decryptData("EmplId", SessionStorage),
    academicyearID: 0,
    campusCityID: campusCity,
    facultyDepartmentProgramID: 0,
    committeeName: "",
    isActive: true,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const intialAcademicYearFields = {
    OperationId: 0,
    StartYearDate: null,
    EndYearDate: null,
    Description: "",
    IsActive: 1,
    UserId: 0,
  };

  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [academicYearList, setAcademicYearList] = useState();
  const [departmentList, setDepartmentList] = useState();

  useEffect(() => {
    getSetupCommitee();
    getAcademicYear();
  }, []);

  function getAcademicYear() {
    PostRequest(SETUP_ACADEMIC_YEAR, intialAcademicYearFields)
      .then((res) => {
        setAcademicYearList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getSetupCommitee() {
    PostRequest(ACAD_SETUPCOMMITEE, initialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table?.map((x) => ({
              ...x,
              IsActive: x.IsActive === true ? "Active" : "In Active",
            })),
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        setDepartmentList(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "Name", name: "Name" },
    { field: "CommitteeName", name: "Committee Name" },
    { field: "IsActive", name: "Active" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="academicyearID"
          list={academicYearList}
          fieldName="AcademicYearDetail"
          fieldId="AcademicYearDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.academicyearID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="facultyDepartmentProgramID"
          list={departmentList}
          fieldName="Dept"
          fieldId="DeptID"
          onChange={handleSearchChange}
          value={SearchFields?.facultyDepartmentProgramID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Commitee Name"
          name="committeeName"
          onChange={handleSearchChange}
          value={SearchFields?.committeeName}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="isActive"
          onChange={handleSearchChange}
          value={SearchFields?.isActive}
          required
        />
      </Col>
    </Fragment>
  );

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="academicyearID"
          list={academicYearList}
          fieldName="AcademicYearDetail"
          fieldId="AcademicYearDetailId"
          onChange={handleAddChange}
          value={FormFields?.academicyearID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="facultyDepartmentProgramID"
          list={departmentList}
          fieldName="Dept"
          fieldId="DeptID"
          onChange={handleAddChange}
          value={FormFields?.facultyDepartmentProgramID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Commitee Name"
          name="committeeName"
          onChange={handleAddChange}
          value={FormFields?.committeeName}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="isActive"
          onChange={handleAddChange}
          value={FormFields?.isActive}
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    PostRequest(ACAD_SETUPCOMMITEE, SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
        setDepartmentList(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
  };

  const submitForm = (id) => {
    const payload = {
      operationId: id,
      setupCommitteeID: FormFields?.setupCommitteeID,
      employeeID: decryptData("EmplId", SessionStorage),
      academicyearID: FormFields?.academicyearID,
      campusCityID: campusCity,
      facultyDepartmentProgramID: FormFields?.facultyDepartmentProgramID,
      committeeName: FormFields?.committeeName,
      isActive: FormFields?.isActive,
      userIP: decryptData(UserNetworkInfo)?.IPv4,
      UserId: decryptData("loginId", SessionStorage),
    };
    PostRequest(ACAD_SETUPCOMMITEE, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getSetupCommitee();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    const data = {
      setupCommitteeID: obj?.SetupCommitteeID,
      academicyearID: obj?.AcademicyearID,
      campusCityID: obj?.CampusCityID,
      facultyDepartmentProgramID: obj?.FacultyDepartmentProgramID,
      committeeName: obj?.CommitteeName,
      isActive: obj?.IsActive == "Active" ? true : false,
    };

    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    console.log(obj);
    const payload = {
      operationId: Delete,
      setupCommitteeID: obj?.SetupCommitteeID,
      employeeID: obj?.EmployeeID,
      academicyearID: obj?.AcademicyearID,
      campusCityID: obj?.CampusCityID,
      facultyDepartmentProgramID: obj?.FacultyDepartmentProgramID,
      committeeName: obj?.CommitteeName,
      isActive: false,
      userIP: decryptData(UserNetworkInfo)?.IPv4,
      UserId: decryptData("loginId", SessionStorage),
    };
    PostRequest(ACAD_SETUPCOMMITEE, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getSetupCommitee();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CrudFormComponent
      formName="Setup Commitee"
      modalSize="lg"
      buttonName="Add"
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      searchPanel={searchPanel}
      formPanel={formPanel}
      cancelSearch={cancelSearch}
      tableColumns={columns}
      tableRows={TableList}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default SetupCommitee;
