import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
// import logoimg from "../../../../assets/img/logo.png";
import Header from "../../../../assets/img/header-img-report.jpg";

const DPLedgerReport = forwardRef((props, ref) => {
  const { TableList } = useSelector((state) => state.CrudFormReducer);
  return (
    <div ref={ref} style={{ fontSize: 12, margin: 6 }}>
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ padding: "0px 150px 5px 150px" }}
                      src={Header}
                      width="80%"
                      height="100%"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 10 }}>
            <table
              style={{
                width: "100%",
                border: "1px solid black",
              }}
              bordered
              striped
              responsive
            >
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#772647",
                      color: "white",
                    }}
                    className="text-center"
                  >
                    S.No.
                  </th>
                  {TableList?.length > 0
                    ? Object.keys(TableList?.[0])?.map((column, index) => (
                        <Fragment key={index}>
                          <th
                            style={{
                              border: "1px solid black",
                              backgroundColor: "#772647",
                              color: "white",
                            }}
                            className="text-center"
                            key={index + 1}
                          >
                            {column}
                          </th>
                        </Fragment>
                      ))
                    : null}
                </tr>
              </thead>
              <tbody>
                {TableList?.length > 0
                  ? TableList?.map((data, i) => (
                      <tr key={i}>
                        <td
                          style={{ border: "1px solid black" }}
                          className="text-center"
                        >
                          {" "}
                          {i + 1}
                        </td>
                        {Object.values(data)?.map((val, index) => {
                          return (
                            <td
                              style={{ border: "1px solid black" }}
                              className="text-center"
                              key={index}
                            >
                              {val}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default DPLedgerReport;

{
  /* <table
  style={{
    width: "100%",
    border: "1px solid black",
  }}
>
  <thead>
    <tr>
      <th
        style={{ border: "1px solid black" }}
        rowSpan="2"
        className="text-center"
      >
        {" "}
        Sno{" "}
      </th>
      <th
        style={{ border: "1px solid black" }}
        rowSpan="2"
        className="text-center"
      >
        {" "}
        Roll No{" "}
      </th>
      <th
        style={{ border: "1px solid black" }}
        rowSpan="2"
        className="text-center"
      >
        {" "}
        Status{" "}
      </th>
      <th
        style={{ border: "1px solid black" }}
        rowSpan="2"
        className="text-center"
      >
        {" "}
        Name In Full{" "}
      </th>
      <th
        style={{ border: "1px solid black" }}
        colspan="6"
        className="cellBorderOfTable text-center"
      >
        Name
      </th>
      <th
        className="text-center"
        rowSpan="2"
        style={{
          border: "1px solid black",
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
        }}
      >
        Total Marks Obt. (600)
      </th>
      <th
        className="text-center"
        rowSpan="2"
        style={{
          border: "1px solid black",
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
        }}
      >
        Total GP
      </th>
      <th
        className="text-center"
        rowSpan="2"
        style={{
          border: "1px solid black",
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
        }}
      >
        Total CGPA
      </th>
      <th
        style={{ border: "1px solid black" }}
        className="text-center"
        rowSpan="2"
      >
        PASS / FAIL
      </th>
      <th
        style={{ border: "1px solid black" }}
        className="text-center"
        rowSpan="2"
      >
        REMARKS
      </th>
    </tr>
    <tr>
      <th
        style={{ border: "1px solid black" }}
        className="text-center"
        colspan="2"
      >
        Name
      </th>
      <th
        style={{ border: "1px solid black" }}
        className="text-center"
        colspan="2"
      >
        Name
      </th>
      <th
        style={{ border: "1px solid black" }}
        className="text-center"
        colspan="2"
      >
        Name
      </th>
    </tr>
  </thead>
</table> */
}
