import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import "../assets/css/login.css";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../components/Alert";
import LoginFormComponent from "../components/FormComponents/LoginFormComponent/loginIndex";
import FormGroupInput from "../components/GeneralComponent/FormGroupInput";
import { decryptData } from "../EncryptData";
import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
} from "../redux/actionType/AuthType";
import { Admission_ADM_Registration, PostRequest } from "../utils/Config";

import { UserNetworkInfo } from "../utils/EncryptedConstants";
import PasswordStrengthMeter from "../functions/PasswordStrengthMeter";
import { Email_Regex, SessionStorage } from "../common/SetupMasterEnum";
import { ADMISSION_ADM_REGISTRATION } from "../utils/UrlConstants";

const initialFormFields = {
  operationID: 3,
  userID: 0,
  otp: 0,
  FirstName: "",
  MiddleName: "",
  LastName: "",
  NIC_FormB: "",
  Mobile: "",
  Email: "",
  Password: "",
  RepeatPassword: "",
  isActive: true,
  createdBy: 1,
  modifiedBy: 1,
  userIP: decryptData(UserNetworkInfo, SessionStorage)?.IPv4.toString(),
};

const NewUserRegistration = (props) => {
  const { FormFields } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
  }, []);

  // const [hasError, setHasError] = useState(false);
  // const [verificationCodeModal, setVerificationCodeModal] = useState(false);

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const admissionSubmit = () => {
    if (FormFields.Password === FormFields.RepeatPassword) {
      //For Verification Code

      WarningWithConfirmation(
        `Are you sure you want to Register with the entered Details?`
      ).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            ...FormFields,
            operationID: 0,
          };
           Admission_ADM_Registration(payload)
          // PostRequest(ADMISSION_ADM_REGISTRATION, payload)
            .then((res) => {
              if (res?.data?.Table?.[0]?.HasError === 1) {
                CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
              } else {
                CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
                props.history.push("/admissionlogin");
              }
            })
            .catch((err) => {
              console.error(err);
            });
          // props.history.push("/admissionlogin");
        }
      });
    } else {
      CustomErrorMessage("Password and Repeat Password should be same");
    }
  };

  // const VerifyEmail = () => {
  //   if (FormFields?.NIC_FormB.length == 13) {
  //     if (Email_Regex.test(FormFields?.Email)) {
  //       let payload = {
  //         operationID: 0,
  //         userID: 0,
  //         otp: FormFields?.otp,
  //         FirstName: FormFields?.FirstName,
  //         MiddleName: FormFields?.MiddleName,
  //         LastName: FormFields?.LastName,
  //         NIC_FormB: FormFields?.NIC_FormB,
  //         Mobile: FormFields?.Mobile,
  //         Email: FormFields?.Email,
  //         Password: FormFields?.Password,
  //         RepeatPassword: FormFields?.RepeatPassword,
  //         isActive: true,
  //         createdBy: 1,
  //         modifiedBy: 1,
  //         userIP: decryptData(UserNetworkInfo, SessionStorage)?.IPv4.toString(),
  //       };

  //       Admission_ADM_Registration(payload)
  //         .then((res) => {
  //           if (res?.data?.Table?.[0]?.HasError === 0) {
  //             setVerificationCodeModal(true);
  //           } else if (res?.data?.Table?.[0]?.HasError == 2) {
  //             CustomErrorMessage(res?.data?.Table?.[0]?.Message);
  //             let data = { name: "Email", value: res?.data?.Table?.[0]?.EMAIL };
  //             dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  //             setHasError(true);
  //           } else {
  //             CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
  //           }
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     } else {
  //       CustomErrorMessage("Email domain not correct");
  //     }
  //   } else {
  //     CustomErrorMessage("CNIC/B-Form number will atleast be of 13 digits");
  //   }
  // };

  // const verificationCode = () => {
  //   let payload = {
  //     operationID: 1,
  //     userID: 0,
  //     otp: FormFields?.otp,
  //     FirstName: FormFields?.FirstName,
  //     MiddleName: FormFields?.MiddleName,
  //     LastName: FormFields?.LastName,
  //     NIC_FormB: FormFields?.NIC_FormB,
  //     Mobile: FormFields?.Mobile,
  //     Email: FormFields?.Email,
  //     Password: FormFields?.Password,
  //     RepeatPassword: FormFields?.RepeatPassword,
  //     isActive: true,
  //     createdBy: 1,
  //     modifiedBy: 1,
  //     userIP: decryptData(UserNetworkInfo, SessionStorage)?.IPv4.toString(),
  //   };
  //   Admission_ADM_Registration(payload)
  //     .then((res) => {
  //       if (res?.data?.Table?.[0]?.HasError === 0) {
  //         CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
  //         setVerificationCodeModal(false);
  //         let data = { name: "VerificationCode", value: "" };
  //         dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  //         setHasError(true);
  //         // props.history.push("/admissionlogin");
  //       } else {
  //         CustomErrorMessage(res?.data?.Table?.[0]?.Message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // const handleVerificationCode = (e) => {
  //   e.preventDefault();
  //   VerifyEmail();
  // };

  // const handleVerifyCode = () => {
  //   verificationCode();
  //   setVerificationCodeModal(true);
  // };

  // const admissionSubmitAgain = (e) => {
  //   e.preventDefault();
  //   FormFields.operationID = 10;

  //   Admission_ADM_Registration(FormFields)
  //     .then((res) => {
  //       if (res?.data?.Table?.[0]?.HasError === 0) {
  //         CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
  //         let data = { name: "otp", value: 0 };
  //         dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  //       } else {
  //         CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  //   // props.history.push("/admissionlogin");
  // };

  const fieldNewRegistration = (
    <Fragment>
      {/* <form id="Form1" onSubmit={handleVerificationCode}></form> */}
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-id-badge"
        label="NIC or B-Form ( Kindly enter your own CNIC ) "
        name="NIC_FormB"
        required
        isNumber="true"
        minLength="13"
        maxLength="13"
        onChange={handleAddChange}
        value={FormFields?.NIC_FormB}
        // disabled={hasError == true ? true : false}
      />
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-envelope-o"
        label="Email Address ( Enter your own
          email address that will be used for
          future consideration ) "
        type="email"
        name="Email"
        required
        onChange={handleAddChange}
        value={FormFields?.Email}
        // disabled={hasError == true ? true : false}
      />
      {/* <div style={{ marginBottom: -10, marginTop: -7 }} className="text-right">
        <Button
          className="Link-Button"
          size="sm"
          color="link"
          type="button"
          onClick={handleVerificationCode}
          disabled={FormFields?.Email?.length === 0 ? true : false}
        >
          Verify Email
        </Button>
      </div> */}

      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-user"
        label="First Name"
        name="FirstName"
        isAlphabetic="true"
        required
        onChange={handleAddChange}
        value={FormFields?.FirstName}
        // disabled={hasError == false ? true : false}
      />
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-user"
        label="Middle Name"
        name="MiddleName"
        isAlphabetic="true"
        onChange={handleAddChange}
        value={FormFields?.MiddleName}
        // disabled={hasError == false ? true : false}
      />
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-user"
        label="Last Name"
        name="LastName"
        isAlphabetic="true"
        onChange={handleAddChange}
        value={FormFields?.LastName}
        // disabled={hasError == false ? true : false}
      />

      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-phone"
        label="Mobile Number"
        name="Mobile"
        required
        minLength="11"
        maxLength="11"
        isNumber="true"
        onChange={handleAddChange}
        value={FormFields?.Mobile}
        // disabled={hasError == false ? true : false}
      />

      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-lock"
        label="Password"
        name="Password"
        type="password"
        minLength="10"
        required
        onChange={handleAddChange}
        value={FormFields?.Password}
        // disabled={hasError == false ? true : false}
      />
      {FormFields?.Password?.length > 0 ? (
        <PasswordStrengthMeter Password={FormFields?.Password} />
      ) : null}
      <FormGroupInput
        isIcon={true}
        iconClass="fa fa-lock"
        label="Repeat Password"
        name="RepeatPassword"
        type="password"
        minLength="10"
        required
        onChange={handleAddChange}
        value={FormFields?.RepeatPassword}
        // disabled={hasError == false ? true : false}
      />
      {/* <Modal
        isOpen={verificationCodeModal}
        centered
        // toggle={toggle}
        modalTransition={{ timeout: 10 }}
        // backdrop="static"
      >
        <ModalHeader
        // toggle={toggle}
        >
          Verification Code (Received on Email)
        </ModalHeader>
        <ModalBody>
          <Row>
            <FormGroupInput
              isIcon={true}
              label="Verification Code"
              name="otp"
              onChange={handleAddChange}
              value={FormFields?.otp}
              isNumber="true"
            />
            <Col md="6">
              <p style={{ fontSize: "11px" }}>
                <a href="" onClick={admissionSubmitAgain}>
                  Send Verification Code Again
                </a>
              </p>
            </Col>
            <Col md="6">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  disabled={FormFields?.otp === 0 ? true : false}
                  color="primary"
                  onClick={handleVerifyCode}
                >
                  Submit
                </Button>
                <Button
                  color="default"
                  onClick={() => setVerificationCodeModal(false)}
                >
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal> */}
    </Fragment>
  );

  return (
    <LoginFormComponent
      // fieldsPanel={fieldsPanel}
      // disableButton={hasError == false ? true : false}
      fieldNewRegistration={fieldNewRegistration}
      admissionSubmit={admissionSubmit}
      initialFormFields={initialFormFields}
      loginType="New"
      buttonName="Register"
    />
  );
};

export default NewUserRegistration;
