import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  Search,
  Select,
  SessionStorage,
  academicYearId,
  partYearID,
  semesterId,
} from "../../../common/SetupMasterEnum";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import ClassConductReport from "../Reports/HODReports/ClassConductReport";
import { CLASS_CONDUCTREPORT } from "../../../utils/UrlConstants";
import { PostRequest } from "../../../utils/Config";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import DatePicker from "react-datepicker";

import { type } from "@testing-library/user-event/dist/type";
import { formatDateFunction1 } from "../../../functions/DateFormatFunction";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";

const ClassConduct = () => {
  const initialFormFields = {
    OperationId: Select,
    ParameterID: Select,
    AcademicYearID: 0,
    PartID: 0,
    SemesterID: 0,
    FacultyDepartmentId: 0,
    FacultyDepartmentProgramID: 0,
    LecturerID: 0,
    FromDate: "2000-01-01",
    ToDate: "2030-01-01",
    EmployeeID: 0,
    SetupCourseID: 0,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialSearchFields = {
    OperationId: Select,
    ParameterID: Select,
    AcademicYearID: 0,
    PartID: 0,
    SemesterID: 0,
    FacultyDepartmentId: 0,
    FacultyDepartmentProgramID: 0,
    LecturerID: 0,
    FromDate: "2000-01-01",
    ToDate: "2030-01-01",
    EmployeeID: 0,
    SetupCourseID: 0,
    UserID: decryptData("loginId", SessionStorage),
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const {
    SearchFields,
    TableList,
    SupportingTables,
    SupportingTables: { departmentName, lecturerName },
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  let ref = useRef(null);
  const dispatch = useDispatch();
  const [academicYear] = useSetupDetailList(academicYearId);
  const [semesterList] = useSetupDetailList(semesterId, SearchFields?.PartID);
  const [partList] = useSetupDetailList(lecturerName);
  const [toggleForm, setToggleForm] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { SubClassConduct, SubClassConductTotal, SubClassConductAmmount } =
    SupportingTables;
  const timeoutRef = useRef(null);

  useEffect(() => {
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    getClassConductReport();
  }, []);

  function getClassConductReport() {
    PostRequest(CLASS_CONDUCTREPORT, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            // List: res?.data,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        let departmentName = {
          name: "departmentName",
          value: res?.data?.Table5,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: departmentName,
        });
        let lecturerName = {
          name: "lecturerName",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: lecturerName,
        });
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: {
              ...initialSearchFields,
              FacultyDepartmentId: res?.data?.Table3?.[0]?.DeptID,
            },
            SearchFields: initialSearchFields,
          },
        });
      })

      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [{ field: "ClassDate", name: "Class" }];

  const handleSearchChange = (e) => {
  
    let data 
    data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
    data = { name: "EmployeeID", value: +e.target.newfield };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const AllDateSet = (event, type) => {
    if (type === "FromDate") {
      setFromDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.FromDate = date;
      let data1 = {
        name: "FromDate",
        value: SearchFields.FromDate,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    } else {
      setToDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.ToDate = date;
      let data1 = {
        name: "ToDate",
        value: SearchFields.ToDate,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    }
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          list={academicYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
        />
      </Col>

      {/* <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartID"
          list={partList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.PartID}
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="SemesterID"
          list={semesterList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.SemesterID}
          disabled={SearchFields?.PartID == 0}
        />
      </Col> */}

      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDepartmentId"
          // onChange={handleSearchChange}
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDepartmentId}
          list={SupportingTables?.departmentName}
          disabled={SearchFields?.FacultyDepartmentId > 0 ? true : false}
          fieldName="Dept"
          fieldId="DeptID"
          required
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyDepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          // disabled={SearchFields?.FacultyDepartmentId == null}
        />
      </Col>

      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Lecturer"
          name="LecturerID"
          list={SupportingTables?.lecturerName}
          fieldName="name"
          fieldId="EmployeeId"
          onChange={handleSearchChange}
          value={SearchFields?.EmployeeId}
          newfield="EmployeeId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">From Data</label>
          <DatePicker
            selected={fromDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "FromDate")}
            className="form-control"
            name="FromDate"
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">To Date</label>
          <DatePicker
            selected={toDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "ToDate")}
            className="form-control"
            name="ToDate"
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...initialSearchFields,
      ...SearchFields,
      OperationId: Search,
      ParameterID: Select,
       
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(CLASS_CONDUCTREPORT, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getClassConductReport();
  };

  const getSubReport = (e, item) => {
    e.preventDefault();
    const payload = {
      ...SearchFields,
      OperationId: 1,
      ParameterID: 2,
      SetupCourseID: item?.SetupCourseID,
      // AcademicYearID: item?.AcademicYearID,
      // PartID: item?.PartID,
      // SemesterID: item?.SemesterID,
      // FacultyDepartmentId: item?.FacultyDepartmentId,
      // FacultyDepartmentProgramID: item?.FacultyDepartmentProgramID,
      // LecturerID: item?.LecturerID,
      // FromDate: item?.FromDate,
      // ToDate: item?.ToDate,
      // EmployeeID: 0,
      // SetupCourseID: item?.SetupCourseID,
      // UserID: decryptData("loginId", SessionStorage),
      // UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(CLASS_CONDUCTREPORT, payload)
      .then((res) => {
        timeoutRef.current = setTimeout(() => setToggleForm(true), 300);
        let data = {
          name: "SubClassConduct",
          value: res?.data?.Table1,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        let data1 = {
          name: "SubClassConductTotal",
          value: res?.data?.Table2,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data1,
        });
        let data2 = {
          name: "SubClassConductAmmount",
          value: res?.data?.Table3,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data2,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onCancel = () => {
    setToggleForm(false);
    // let data = {
    //   name: "",
    //   value: [],
    // };
    // dispatch({
    //   type: SET_INITIAL_DROPDOWN_FORM_STATE,
    //   payload: data,
    // });
  };

  const printChallan = useReactToPrint({
    content: () => ref.current,
  });

  const customButton = (
    <>
      {TableList?.Table?.length > 0 ? (
        <Button color="primary" className="btn" onClick={printChallan}>
          Print
        </Button>
      ) : null}
      <div style={{ display: "none" }}>
        {" "}
        <ClassConductReport ref={ref} />
      </div>
    </>
  );

  const customTable = (
    <>
      <Table className="table-bordered table-striped admission-table">
        <thead>
          <tr>
            <th rowSpan="3">S.no</th>
            <th rowSpan="3">Class</th>
            <th rowSpan="3">Semester</th>
            <th rowSpan="3">Course Code</th>
            <th rowSpan="3">Course Title</th>
            <th rowSpan="3">CHs (Due)</th>
            <th rowSpan="3">CHs (Cond)</th>
            <th rowSpan="3">Percentage</th>
          </tr>
        </thead>
        <tbody>
          {TableList?.Table1?.length > 0 ? (
            TableList?.Table1?.map((x, ind) => {
              return (
                <tr key={ind}>
                  <td className="text-center">{ind + 1}</td>
                  <td className="text-center">
                    {x.FacultyDepartmentProgramShortForm}
                  </td>
                  <td className="text-center">{x.Semester}</td>
                  <td className="text-center">{x.Code}</td>
                  <td className="text-center">
                    <a href="" onClick={(e) => getSubReport(e, x)}>
                      {x.CrTitle}
                    </a>
                  </td>
                  <td className="text-center">{ Math.round(x.DueChrs * 100) / 100}</td>
                  <td className="text-center">{ Math.round(x.CondChrs * 100) / 100}</td>
                  <td className="text-center">{x.Avg}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8} className="text-center">
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {TableList?.Table1?.length > 0 ? (
      <div style={{ width: "42.1%", float: "right", marginRight: "10px" }}>
        <Row>
          <Table className="table-bordered table-striped admission-table" style={{marginBottom:"0px"}}>
            <tbody>
              {TableList?.Table2?.length > 0 ? (
                TableList?.Table2?.map((x, ind) => {
                  return (
                    <tr key={ind}>
                      <td className="text-center" style={{width:"23.6%"}}>Total</td>
                      <td className="text-center">{Math.round(x.TotalDueChrs * 100) / 100}</td>
                      <td className="text-center">{Math.round(x.TotalCondChrs * 100) / 100}</td>
                      <td className="text-center">{x.Avgg}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Table className="table-bordered table-striped admission-table"  style={{marginBottom:"0px"}}>
            <tbody>
              {TableList?.Table3?.length > 0 ? (
                TableList?.Table3?.map((x, ind) => {
                  return (
                    <tr key={ind}>
                      <td className="text-center" style={{ width: "23.6%" }}>
                        Rate Per Hour{" "}
                      </td>
                      <td className="text-center">{x.RATE}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Table className="table-bordered table-striped admission-table"  style={{marginBottom:"0px"}}>
            <tbody>
              {}

              {TableList?.Table4?.length > 0 ? (
                TableList?.Table4?.map((x, ind) => {
                  return (
                    <tr key={ind}>
                      <td className="text-center" style={{ width: "23.6%" }}>
                        Total Amount
                      </td>
                      <td className="text-center">{Math.round(x.TotalAmount * 100) / 100}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Table className="table-bordered table-striped admission-table"  style={{marginBottom:"0px"}}>
            <tbody>
              {}

              {TableList?.Table6?.length > 0 ? (
                TableList?.Table6?.map((x, ind) => {
                  return (
                    <tr key={ind}>
                      <td className="text-center" style={{ width: "23.6%" }}>
                        In Words
                      </td>
                      <td className="text-center">{x.InWords}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
      </div>
      ) : (
            <tr>
              <td colSpan={8} className="text-center">
               
              </td>
            </tr>
          )}
    </>
  );

  const customModal = (
    <Fragment>
      <Modal
        size="xl"
        // style={{ minWidth: "98vw", width: "100%", margin: "2vh" }}
        isOpen={toggleForm}
        centered
        modalTransition={{ timeout: 10 }}
      >
        <ModalHeader>Class Conduct Sub Report</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table bordered striped responsive style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th rowSpan={2} colSpan={1}>
                      S.No.
                    </th>
                    <th rowSpan="3">Class</th>
                    <th rowSpan="3">CHs (Due)</th>
                    <th rowSpan="3">CHs (Cond)</th>
                    <th rowSpan="3">Start Date</th>
                    <th rowSpan="3">End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {SubClassConduct?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.ClassDate}</td>
                      <td>{item.ChsDue}</td>
                      <td>{item.ChsCond}</td>
                      <td>{item.StartTime}</td>
                      <td>{item.EndTime}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" xs="6">
              {SubClassConductTotal?.map((item, index) => (
                <Table
                  bordered
                  striped
                  responsive
                  style={{ width: "98%", float: "left" }}
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr>
                      <td>Total CHs (Due)</td>
                      <td>{item.chsdue}</td>
                    </tr>
                    <tr>
                      <td>Total CHs (Cond) </td>
                      <td>{Math.round(item.ChsCond * 100) / 100}</td>
                    </tr>
                    <tr>
                      <td>Percentage</td>
                      <td> {item.Avgg} %</td>
                    </tr>
                  </tbody>
                </Table>
              ))}
            </Col>

            {/* <Col lg="6" md="6" xs="6">
              {SubClassConductAmmount?.map((item, index) => (
                <Table
                  bordered
                  striped
                  responsive
                  style={{ width: "99%", float: "left" }}
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr>
                      <td>
                        <b>Amount</b>
                      </td>
                      <td>{Math.round(item.Amount * 100) / 100}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Rates</b>{" "}
                      </td>
                      <td>{item.Rates}</td>
                    </tr>
                    <tr colSpan={1}>
                      <td>
                        {" "}
                        <b>{item.AmInWords}</b>{" "}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              ))}
            </Col> */}
          </Row>
          <Row>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="mt-2"
            >
              <Button color="default" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );

  return (
    <>
      <CrudFormComponent
        formName="Class Conduct Report"
        searchSubmit={submitSearch}
        searchPanel={searchPanel}
        customButton={customButton}
        customTable={customTable}
        cancelSearch={cancelSearch}
        // onClickRow={onClickRow}
        tableColumns={columns}
        tableRows={TableList?.Table}
        featureList={menuTable?.Table2?.filter(
          (x) => x.MenuId === selectedMenu
        )}
        customModal={customModal}
      />
    </>
  );
};

export default ClassConduct;
