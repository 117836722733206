import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import logoimg from "../../../assets/img/logo.png";

const RegisteredCandidatesDegreeProgramReport = forwardRef((props, ref) => {
  const { TableList } = useSelector((state) => state.CrudFormReducer);

  return (
    <div ref={ref} className="card-div portrait-page" style={{ fontSize: 12 }}>
      {/* <div style={{ padding:8}}> */}
      <Row>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "10%" }} className="text-center"></td>
              <td style={{ width: "15%" }} className="text-center">
                <img src={logoimg} height="100" />
              </td>

              <td style={{ width: "60%" }}>
                <h3 className="text-center">
                  SHAIKH AYAZ UNIVERSITY, SHIKARPUR
                </h3>
                <h6 className="text-center">
                  Merit List For The Academic Year 2023
                </h6>
              </td>
              <td style={{ width: "20%" }} className="text-center"></td>
            </tr>
          </tbody>
        </table>
      </Row>

      <Row>
        <Col>
          <div className="Section1" style={{ padding: "20px", fontSize: 10 }}>
            <table
              style={{
                width: "100%",
                border: "1px",
                borderStyle: "solid",
                //   lineHeight:'10px'
              }}
            >
              <thead>
                <tr
                  style={{
                    borderBottom: "0.5px solid",
                    // lineHeight: "30px",
                    color: "white",
                    backgroundColor: "black",
                    padding: 5,
                  }}
                >
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    S.No
                  </th>

                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    Academic Year
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    Program Type
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    NIC B-Form
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    Father Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    Faculty Department Program
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px",
                      borderStyle: "solid",
                      padding: 5,
                    }}
                  >
                    Admission Faculty Department
                  </th>
                </tr>
              </thead>
              <tbody>
                {TableList?.map((x, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom: "0.5px solid",
                      lineHeight: "30px",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                        // padding: "10px",
                      }}
                    >
                      {x.AcademicYear}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.ProgramType}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.NIC_FormB}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.Name}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.FatherName}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.FacultyDepartmentProgram}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      {x.AdmissionFacultyDepartment}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      {/* </div> */}
    </div>
  );
});

export default RegisteredCandidatesDegreeProgramReport;
