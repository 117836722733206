import React, { forwardRef, Fragment } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import logoimg from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";

const CandidateStatusReportPDF = forwardRef((props, ref) => {
  const {
    SearchFields,
    TableLoading,
    FormFields,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const columns = [
    { field: "ADMRegID", name: "Reg. ID", key: "ADMRegID", label: "Reg. ID" },
    {
      field: "ApplicationID",
      name: "App. ID",
      key: "ApplicationID",
      label: "App. ID",
    },
    {
      field: "Cnic",
      name: "CNIC/B-Form/Passport No",
      key: "Cnic",
      label: "CNIC/B-Form/Passport No",
    },
    {
      field: "Name",
      name: "Candidate Name",
      key: "Name",
      label: "Candidate Name",
    },
    {
      field: "ProgramName",
      name: "Program",
      key: "ProgramName",
      label: "Program",
    },
    {
      field: "AdmissionTypeName",
      name: "Admission Type",
      key: "AdmissionTypeName",
      label: "Admission Type",
    },
    {
      field: "MajorName",
      name: "Major",
      key: "MajorName",
      label: "Major",
    },
    {
      field: "AdmissionFacultyDepartmentName",
      name: "Department",
      key: "AdmissionFacultyDepartmentName",
      label: "Department",
    },
    {
      field: "FacultyDepartmentProgramName",
      name: "Department Program",
      key: "FacultyDepartmentProgramName",
      label: "Department Program",
    },
    {
      field: "DomicileName",
      name: "Domicile Province",
      key: "DomicileName",
      label: "Domicile Province",
    },
    {
      field: "DepartmentChoice",
      name: "Department Choices",
      key: "DepartmentChoice",
      label: "Department Choices",
    },
    {
      field: "CandidateStatusName",
      name: "Application Status",
      key: "CandidateStatusName",
      label: "Application Status",
    },
    { field: "Remarks", name: "Remarks", key: "Remarks", label: "Remarks" },
  ];
  return (
    <div ref={ref}>
      <Container fluid>
        <div className="candi">
            <div>
                <h4 style={{fontSize:'30px'}} className="text-center mt-3">Candidate Status Report</h4>
            </div>
          <Table bordered striped style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {SupportingTables?.records?.Table?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td>{item.ADMRegID}</td>
                    <td>{item.ApplicationID}</td>
                    <td>{item.Cnic}</td>
                    <td>{item.Name}</td>
                    <td>{item.ProgramTypeName}</td>
                    <td>{item.AdmissionTypeName}</td>
                    <td>{item.MajorName}</td>
                    <td>{item.AdmissionFacultyDepartmentName}</td>
                    <td>{item.FacultyDepartmentProgramName}</td>
                    <td>{item.DomicileName}</td>
                    <td>{item.DepartmentChoice}</td>
                    <td style={{ width: "80px" }}>
                      {item.Status === 0 ? "Pending" : "Completed"}
                    </td>

                    <td style={{ width: "140px" }}>{item.Remarks}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </div>
  );
});

export default CandidateStatusReportPDF;