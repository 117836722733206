import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Container,
  Row,
  Table,
} from "reactstrap";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { useReactToPrint } from "react-to-print";
import SemesterResultReport from "../Reports/StudentReports/SemesterResultReport";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import {
  Select,
  SessionStorage,
  partYearID,
  semesterId,
} from "../../../common/SetupMasterEnum";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  SET_MULTI_CRUD_SUPPORTINGTABLE,
} from "../../../redux/actionType/CrudActionTypes";
import useFade from "../../../Hooks/useFade";
import { PostRequest } from "../../../utils/Config";
import { ACAD_SEMESTERMARKSHEET } from "../../../utils/UrlConstants";
import { decryptData } from "../../../EncryptData";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";

const initialSearchFields = {
  operationId: Select,
  parameterID: Select,
  academicYearID: 0,
  semesterID: 0,
  facultyDepartmentProgramID: 0,
  studentDetailID: 0,
  userID: 0,
  userIP: 0,
};

const SemesterResult = (args) => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const dispatch = useDispatch();

  const [showSupply, setShowSupply, fadeOutProps] = useFade(false, 700);

  const [Semester] = useSetupDetailList(semesterId);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setShowSupply(true);
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const timeoutRef = useRef(null);

  const printCummulativeAttendanceReport = useRef();

  const handlePrintReport = useReactToPrint({
    content: () => printCummulativeAttendanceReport.current,
  });

  const handleSearchChange = (e) => {
    setIsOpen(false);
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
    dispatch({
      type: SET_MULTI_CRUD_SUPPORTINGTABLE,
      payload: {
        SemesterPart: e.target.selectedValue,
      },
    });
  };

  const handleClickSubmit = () => {
    let payload = {
      operationId: Select,
      parameterID: Select,
      academicYearID: 0,
      facultyDepartmentProgramID: 0,
      semesterID: SearchFields?.semesterID,
      studentDetailID: decryptData("EmplId", SessionStorage),
      userID: decryptData("loginId", SessionStorage),
      userIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(ACAD_SEMESTERMARKSHEET, payload)
      .then((res) => {
        let SemesterResult = {
          name: "SemesterResult",
          value: res?.data?.Table,
        };
        setIsOpen(true);
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: SemesterResult,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Fragment>
      {showSupply && (
        <Container {...fadeOutProps} fluid>
          <Card className="mt-3">
            <CardTitle
              style={{
                backgroundColor: "#772647",
                color: "white",
                textAlign: "center",
              }}
            >
              Student Semester Result
            </CardTitle>
            <CardBody>
              <Row>
                <Col>
                  {/* <Table bordered responsive>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Student</td>
                        <td>Talha Waheed Junejo - 2284160</td>
                        <td style={{ fontWeight: "bold" }}>Section</td>
                        <td>MPM 1 C</td>
                        <td style={{ fontWeight: "bold" }}>Semester</td>
                        <td>Fall 2022</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table bordered responsive>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Current CGPA: 0</td>
                      </tr>
                    </tbody>
                  </Table> */}
                  <Table bordered responsive striped>
                    {/* <thead>
                      <tr className="text-center" colSpan={4}>
                        <th>Semester</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      <tr colSpan="4" className="text-center">
                        <td
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ width: "20%" }}>
                            <FormGroupSelect
                              name="semesterID"
                              onChange={handleSearchChange}
                              value={SearchFields?.semesterID}
                              list={Semester}
                              fieldName="SetupDetailName"
                              fieldId="SetupDetailId"
                              nameValue="SemesterPart"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="text-center">
                    <Button onClick={handleClickSubmit} color="primary">
                      Submit
                    </Button>
                    <Button onClick={handlePrintReport} color="primary">
                      Print
                    </Button>
                  </div>
                </Col>
              </Row>

              <br />

              <Collapse isOpen={isOpen}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Table style={{ width: "50%" }}>
                    <thead>
                      <tr
                        style={{
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        <th
                          style={{ fontSize: 14 }}
                          colSpan="4"
                          className="text-center"
                        >
                          {SupportingTables?.SemesterPart}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {SupportingTables?.SemesterResult?.length > 0 &&
                        SupportingTables?.SemesterResult?.map((x) => {
                          return (
                            <tr
                              style={{
                                border: "1px solid",
                                color: "black",
                                padding: 5,
                              }}
                            >
                              <td
                                className="text-center"
                                style={{
                                  fontSize: 14,
                                  width: "10%",
                                  border: "1px solid",
                                  color: "black",
                                  padding: 5,
                                }}
                              >
                                {x.Course}
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  fontSize: 14,
                                  width: "10%",
                                  border: "1px solid",
                                  color: "black",
                                  padding: 5,
                                }}
                              >
                                {x.Marks}
                              </td>
                            </tr>
                          );
                        })}
                      {/* <tr
                        style={{
                          border: "1px solid",
                          color: "black",
                          padding: 5,
                        }}
                      >
                        <td
                          className="text-center"
                          style={{
                            fontSize: 14,
                            width: "10%",
                            border: "1px solid",
                            color: "black",
                            padding: 5,
                          }}
                        >
                          Fundamentals of Programming
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: 14,
                            width: "10%",
                            border: "1px solid",
                            color: "black",
                            padding: 5,
                          }}
                        >
                          3.5
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </div>
                {/* </CardBody>
                </Card> */}
              </Collapse>

              {/* {onCLickSubmit && ( */}
              {/* <Collapse isOpen={onCLickSubmit}> */}

              {/* </Collapse> */}
              {/* )} */}
            </CardBody>
          </Card>
        </Container>
      )}

      <div style={{ display: "none" }}>
        <SemesterResultReport ref={printCummulativeAttendanceReport} />
      </div>
    </Fragment>
  );
};

export default SemesterResult;
