import { SessionStorage } from "../common/SetupMasterEnum";
import { decryptData } from "../EncryptData";


export const logout = () => {
  let LOGIN_ID = decryptData("loginId", SessionStorage);
  sessionStorage.removeItem(LOGIN_ID);
  sessionStorage.clear();
};



export const isLogin = () => {
  let LOGIN_ID = decryptData("loginId", SessionStorage);
  if (LOGIN_ID) {
    return true;
  }

  return false;
};
