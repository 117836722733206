import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { SET_SELECTED_MENU } from "../redux/actionType/AuthType";
import { SET_INITIAL_CRUD_FORM_STATE } from "../redux/actionType/CrudActionTypes";
import { encryptData } from "../EncryptData";
import { SessionStorage } from "../common/SetupMasterEnum";

const Sidebar = (props) => {
  const dispatch = useDispatch();

  const { menuTable } = useSelector((state) => state.AuthReducer);
  const toggleSidebar = props?.isToggle;
  const [isActive, setActive] = useState(false);
  const [text, setText] = useState("");
  const [parentText, setParentText] = useState("");
  const [isChildActive, setChildActive] = useState(false);
  const [child, setChild] = useState("");
  const [innerData, setInnerData] = useState([]);
  const [innerChildData, setInnerChildData] = useState([]);

  const [currentParams, setCurrentParams] = useState("");

  const menuData = [
    {
      applicationId: 1,
      applicatinName: "HRMS",
      applicationIcon: "ti ti-user",
      children: [
        {
          menuItemId: 10,
          label: "Dashboard",
          menuURL: "/pages/dashboard",
          menuIcon: "ti ti-dashboard",
          children: [],
        },
        {
          menuItemId: 11,
          label: "Employee",
          menuIcon: "ti ti-user",
          applicationId: 1,
          children: [
            {
              menuItemId: 100,
              menuURL: "/pages/hrms/profile",
              label: "Profile",
            },
            // {
            //   menuItemId: 101,
            //   menuURL: "/pages/hrms/departments",
            //   label: "Departments",
            // },
            {
              menuItemId: 102,
              menuURL: "/pages/hrms/employees",
              label: "Employees",
            },
            // {
            //   menuItemId: 103,
            //   menuURL: "/pages/hrms/timeinoutapproval",
            //   label: "Time In Out Approval",
            // },
            // {
            //   menuItemId: 104,
            //   menuURL: "/pages/hrms/probation",
            //   label: "Probations",
            // },
            // {
            //   menuItemId: 106,
            //   menuURL: "/pages/hrms/payslip",
            //   label: "Payslip",
            // },
          ],
        },
      ],
    },
    {
      applicationId: 2,
      applicatinName: "TMS",
      applicationIcon: "ti ti-timer",
      children: [
        {
          menuItemId: 20,
          menuURL: "/pages/tms/attendancestats",
          label: "Attendance Stats",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 21,
          menuURL: "/pages/tms/updatecalendar",
          label: "Update Calendar",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 22,
          menuURL: "/pages/tms/viewcalendar",
          label: "View Calendar",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 23,
          menuURL: "/pages/tms/applyleave",
          label: "Apply Leave",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 24,
          menuURL: "/pages/tms/leaveapproval",
          label: "Leave Approval",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 25,
          menuURL: "/pages/tms/leavehistory",
          label: "Leave History",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 26,
          menuURL: "/pages/tms/shiftscheduler",
          label: "Shift Scheduler",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 27,
          menuURL: "/pages/tms/inorouttime",
          label: "In Or Out Time",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 28,
          menuURL: "/pages/tms/timeadjustment",
          label: "Time Adjustment",
          menuIcon: "ti ti-file",
          children: [],
        },
      ],
    },
    {
      applicationId: 3,
      applicatinName: "PAYROLL",
      applicationIcon: "ti ti-money",
      children: [
        {
          menuItemId: 32,
          label: "Payslip Status",
          menuURL: "/pages/payroll/payslipstatus",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 30,
          label: "Payroll Stats",
          menuIcon: "ti ti-file",
          menuURL: "/pages/payroll/payrollstats",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 31,
          label: "Contracts",
          menuIcon: "ti ti-file",
          menuURL: "/pages/payroll/contracts",
          menuIcon: "ti ti-file",
          children: [],
        },
      ],
    },
    {
      applicationId: 4,
      applicatinName: "SETUP",
      applicationIcon: "ti ti-layers",
      children: [
        {
          menuItemId: 40,
          menuURL: "/pages/setup/Roles",
          label: "Roles",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 41,
          menuURL: "/pages/setup/users",
          label: "Users",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 42,
          menuURL: "/pages/setup/allowances",
          label: "Allowances",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 43,
          menuURL: "/pages/setup/cadre",
          label: "Cadre",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 44,
          menuURL: "/pages/setup/costcenter",
          label: "Cost Center",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 45,
          menuURL: "/pages/setup/designation",
          label: "Designation",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 46,
          menuURL: "/pages/setup/division",
          label: "Division",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 47,
          menuURL: "/pages/setup/employeecategory",
          label: "Employee Category",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 48,
          menuURL: "/pages/setup/status",
          label: "Status",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 49,
          menuURL: "/pages/setup/employeetype",
          label: "Employee Type",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 50,
          menuURL: "/pages/setup/grade",
          label: "Grade",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 51,
          menuURL: "/pages/setup/hiringcriteria",
          label: "Hiring Criteria",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 52,
          menuURL: "/pages/setup/payrolltype",
          label: "Payroll Type",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 53,
          menuURL: "/pages/setup/department",
          label: "Department",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 54,
          menuURL: "/pages/setup/campustype",
          label: "Campus Type",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 55,
          menuURL: "/pages/setup/groups",
          label: "Groups",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 56,
          menuURL: "/pages/setup/emailgroups",
          label: "Email Groups",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 57,
          menuURL: "/pages/setup/employmentstatus",
          label: "Employment Status",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 58,
          menuURL: "/pages/setup/programs",
          label: "Programs",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 59,
          menuURL: "/pages/setup/degree",
          label: "Degree",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 60,
          menuURL: "/pages/setup/faculties",
          label: "Faculties",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 61,
          menuURL: "/pages/setup/subject",
          label: "Subject",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 62,
          menuURL: "/pages/setup/courses",
          label: "Courses",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 63,
          menuURL: "/pages/setup/coursenumber",
          label: "Course Number",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 64,
          menuURL: "/pages/setup/credithours",
          label: "Credit Hours",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 65,
          menuURL: "/pages/setup/classtimeslots",
          label: "Class Time Slots",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 66,
          menuURL: "/pages/setup/programtypes",
          label: "Program Types",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 67,
          menuURL: "/pages/setup/menu",
          label: "Menu",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 68,
          menuURL: "/pages/setup/accesscontrol",
          label: "Access Control",
          menuIcon: "ti ti-file",
          children: [],
        },
      ],
    },
    {
      applicationId: 5,
      applicatinName: "ANNOUNCEMENT",
      applicationIcon: "ti ti-announcement",
      children: [
        {
          menuItemId: 50,
          menuURL: "/pages/announcement/addemail",
          label: "Add Email",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 51,
          menuURL: "/pages/announcement/announcement",
          label: "Announcement",
          menuIcon: "ti ti-file",
          children: [],
        },
      ],
    },
    {
      applicationId: 6,
      applicatinName: "Workflow",
      applicationIcon: "ti ti-pie-chart",
      children: [
        {
          menuItemId: 70,
          menuURL: "/pages/workflow/acrfaculty",
          label: "Faculty",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 71,
          menuURL: "/pages/workflow/acrbps1to4",
          label: "ACR (BPS 1-4)",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 72,
          menuURL: "/pages/workflow/acrbps5to15",
          label: "ACR (BPS 5-15)",
          menuIcon: "ti ti-file",
          children: [],
        },
        {
          menuItemId: 73,
          menuURL: "/pages/workflow/acrbps16andabove",
          label: "ACR (BPS 16 & Above)",
          menuIcon: "ti ti-file",
          children: [],
        },
      ],
    },
  ];

  const parentClick = (params, e) => {
    e.preventDefault();
    setCurrentParams(params);
    if (currentParams === params) {
      setActive(!isActive);
    } else {
      setActive(true);
      setInnerData(
        menuTable?.Table1?.filter((x) => x.ParentId == params.MenuId)
      );
      setParentText(params.MenuName);
      setChildActive(false);
    }
  };
  const childClick = (params, e) => {
    e.preventDefault();
    setChildActive(!isChildActive);
    setInnerChildData(
      menuTable?.Table1?.filter((x) => x.ParentId == params.MenuId)
    );
    setChild(params);
    setText(e.target.innerText);
  };
  const changeState = (menu) => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
      },
    });
    encryptData(menu.MenuId, "MenuId", SessionStorage);
    dispatch({ type: SET_SELECTED_MENU, payload: menu.MenuId });
    setActive(false);
  };

  return (
    <>
      {toggleSidebar ? (
        <>
          <div className="fixed-sidebar-left show">
            <ul className="nav navbar-nav side-nav nicescroll-bar">
              {menuTable &&
                menuTable?.Table1?.filter((item) => item.ParentId === null).map(
                  (item) => (
                    <li
                      className={
                        parentText === item.MenuName && isActive ? "active" : ""
                      }
                      key={item.MenuId}
                    >
                      <a
                        className="dropdownm"
                        onClick={(e) => parentClick(item, e)}
                      >
                        <div className="pull-left">
                          <i className={item.IconClass}></i>
                          <span className="right-nav-text">
                            {item.MenuName}
                          </span>
                        </div>
                        <div className="pull-right">
                          <i className="ti ti-angle-down"></i>
                        </div>
                        <div className="clearfix"></div>
                      </a>
                    </li>
                  )
                )}
            </ul>
          </div>
          {isActive ? (
            <div className="submenu show">
              <div className="slimScrollDiv">
                <div className="nicescroll-bar">
                  <Scrollbars style={{ height: "460px" }}>
                    <div id="childm" className="submenuinn">
                      <ul id="dropdown1" className="list-group d-block">
                        {innerData.map((menu, ind) => {
                          return (
                            <Fragment key={ind}>
                              <li className="sub">
                                {menuTable?.Table1?.filter(
                                  (x) => x.ParentId == menu.MenuId
                                ).length === 0 ? (
                                  <Link
                                    className="noChild"
                                    to={menu.MenuURL}
                                    onClick={() => changeState(menu)}
                                  >
                                    <i className={menu.IconClass}></i>
                                    <span>{menu.MenuName}</span>
                                    <span className="holder"></span>
                                  </Link>
                                ) : (
                                  <>
                                    <a
                                      className="haveChild"
                                      id={
                                        text == menu.MenuName && isChildActive
                                          ? "childClass"
                                          : ""
                                      }
                                      onClick={(e) => childClick(menu, e)}
                                    >
                                      <i
                                        id={
                                          text == menu.MenuName && isChildActive
                                            ? "childIcon"
                                            : ""
                                        }
                                        className="ti ti-user"
                                      ></i>
                                      <span>{menu.MenuName}</span>
                                      <span className="holder"></span>
                                    </a>
                                    {text == menu.MenuName ? (
                                      <>
                                        {isChildActive ? (
                                          <ul
                                            id=""
                                            className="list-group d-block"
                                            style={{
                                              borderTop: "1px solid #fcb30c",
                                              borderRadius: "0px",
                                            }}
                                          >
                                            {innerChildData.map((subMenu) => {
                                              return (
                                                <li
                                                  className="sub"
                                                  key={subMenu.MenuId}
                                                >
                                                  <Link
                                                    to={subMenu.MenuURL}
                                                    className="makeLabel"
                                                    onClick={() =>
                                                      changeState(subMenu)
                                                    }
                                                  >
                                                    {subMenu.MenuName}
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </li>
                            </Fragment>
                          );
                        })}
                      </ul>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Sidebar;
