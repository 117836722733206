import React, { forwardRef, Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";

import { useState } from "react";

import logo from "../../assets/img/header-img.jpg";
import { formatDateFunction2 } from "../../functions/DateFormatFunction";

const PrintOfferLetter = forwardRef((props, ref) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const [number, setNumber] = useState(13);

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <div className="portrait-page" ref={ref}>
      <table
        style={{
          fontSize: 12,
          margin: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                padding: "20px 20px 10px",
                paddingBottom: "0px",
              }}
            >
              <img width="100%" src={logo} alt="" />
            </td>
          </tr>
          <tr>
            <td style={{ padding: "0px 20px", textAlign: "right" }}>
              <p
                style={{
                  fontFamily: "Open Sans,sansserif",
                  margin: "0px",
                }}
              >
                {" "}
                {formatDateFunction2(
                  SupportingTables?.OfferLetterData?.[0]?.CreatedDate
                ,",", "long", "monthly")}
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "6px 20px" }}>
              <h4
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  borderBottom: "2px solid #660033",
                  fontFamily: "Times New Roman, Times, serif",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    padding: "5px 140px 2px 15px",
                    backgroundColor: "#660033",
                    color: "#fff",
                    clipPath:
                      "polygon(4% 0, 61% 0, 71% 55%, 77% 90%, 78% 93%, 0 92%, 0% 100%, 3% 18%",
                    margin: "-2px 0",
                    fontWeight: "500",
                  }}
                >
                  CONDITIONAL OFFER!
                </span>
              </h4>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "8px 20px" }}>
              <p
                style={{
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                  margin: "0px",
                }}
              >
                <strong>Merit List Reference No:</strong>{" "}
                {SupportingTables?.OfferLetterData?.[0]?.MeritListReferenceNo}
                <br />
                <strong>Degree Program Offered:</strong>{" "}
                {SupportingTables?.OfferLetterData?.[0]?.DegreeProgramOffered}
                <br />
                <strong>Class Commencement Date: </strong>{" "}
                {formatDateFunction2(
                  SupportingTables?.OfferLetterData?.[0]?.ClassCommencementDate
                )}
                <br />
                <strong>Condition:</strong>{" "}
                {SupportingTables?.OfferLetterData?.[0]?.Condition}
                <br />
                <strong>Offer Letter validity:</strong> From {formatDateFunction2(SupportingTables?.OfferLetterData?.[0]?.OfferLettervalidityfrom)} To {formatDateFunction2(SupportingTables?.OfferLetterData?.[0]?.OfferLettervalidityTo)}
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px 20px" }}>
              <p
                style={{
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                }}
              >
                Dear Candidate,
              </p>
              <p
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "12px",
                  paddingTop: "8px",
                }}
              >
                Thank you for choosing The Shaikh Ayaz University Shikarpur. We
                are pleased to offer you admission in{" "}
                <span
                  style={{
                    borderBottom: "1px solid",
                    fontWeight: "900",
                  }}
                >
                  {SupportingTables?.OfferLetterData?.[0]?.DegreeProgramOffered}
                </span>
                , bachelor degree program and welcome you as one of our student.
                Your offer is provisional and subject to verification of your
                academic records and the eligibility criteria laid down in
                admission policy 2024.
              </p>
              <p
                style={{
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                }}
              >
                {" "}
                Please supply the supporting original documents by hand to the
                office of Directorate Admission as soon as possible.{" "}
              </p>
              <h4
                style={{
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                }}
              >
                <strong>ACCEPTING THIS OFFER:</strong>
              </h4>
              <p
                style={{
                  fontFamily: "Open Sans,sans-serif ",
                  fontSize: "12px",
                }}
              >
                To accept this offer and guarantee a place on the program, you
                need to submit a signed and dated copy of the offer acceptance
                to the office of Director Admission. Please ensure that you have
                read and understood the terms and conditions in the offer before
                signing this offer acceptance letter.{" "}
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "30px 20px" }}></td>
          </tr>
          <tr>
            <td style={{ padding: "10px 20px", textAlign: "right" }}>
              <span
                style={{
                  borderTop: "1px solid",
                  padding: "8px 2px",
                  textAlign: "center",
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                }}
              >
                <strong>Candidate's Signature </strong>
              </span>
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px 20px" }}>
              <p
                style={{
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                  marginLeft: "20px",
                  marginTop: "2px",
                }}
              >
                &#10132; Welcome to the Shaikh Ayaz University Shikarpur!!!
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "30px 20px" }}></td>
          </tr>
          <tr>
            <td style={{ padding: "10px 20px" }}>
              <span
                style={{
                  borderTop: "1px solid",
                  padding: "8px 2px",
                  textAlign: "center",
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                }}
              >
                <strong>Director Admissions</strong>
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "50px 20px 20px", textAlign: "right" }}>
              <p
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  borderBottom: "2px solid #660033",
                  fontFamily: "Open Sans,sans-serif",
                  fontSize: "12px",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    display: "inline-block",
                    textAlign: "right",
                    padding: "5px 10px 2px 120px",
                    backgroundColor: "#660033",
                    color: "#fff",
                    clipPath: "polygon(37% 0%, 100% 0, 100% 100%, 18% 109%)",
                  }}
                >
                  Directorate of Admission
                  <br />
                  Tel: 0726-920367
                  <br />
                  admission@saus.edu.pk
                  <br />
                  www.saus.edu.pk
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    //   </Container>
    // </div>
  );
});

export default PrintOfferLetter;
