import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../assets/css/login.css";
import logoimg from "../assets/img/logo.png";
import loginbg from "../assets/img/login-bg2.jpg";
import {
  Admission_ADM_ForgetPassword,
} from "../utils/Config";
import { Search } from "../common/SetupMasterEnum";
import FormGroupInput from "../components/GeneralComponent/FormGroupInput";
import { CustomErrorMessage, CustomSuccessAlert } from "../components/Alert";

const initialFormFields = {
  niC_FormB: "",
  OperationId: Search,
};
const CandidateForgotPassword = (props) => {
  const [formFields, setFormFields] = useState(initialFormFields);
  const handleSearchChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };
  function getForgotedPassword() {
    Admission_ADM_ForgetPassword(formFields)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError == 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.MESSAGE);
          props.history.push("/admissionlogin");
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.MESSAGE);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (formFields.niC_FormB.length === 13) {
      getForgotedPassword();
    } else {
      CustomErrorMessage("CNIC/B-Form number will atleast be of 13 digits");
    }
  };
  return (
    <div className="auth-page">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg="6">
            <Card className="auth-form">
              <CardBody className="p-5 pt-3 pb-3">
                <div className="login-brand">
                  <img src={logoimg} height="130" />
                </div>
                <form onSubmit={onSubmit}>
                  <h4 className="authf-title mb-1">Forgot Password</h4>
                  <FormGroupInput
                    isIcon={true}
                    iconClass="fa fa-mobile"
                    // type="email"
                    isNumber="true"
                    label="CNIC/B-Form"
                    name="niC_FormB"
                    maxLength="13"
                    required
                    onChange={handleSearchChange}
                    value={formFields.niC_FormB}
                  />
                  <FormGroup className="form-group text-right">
                    <Link
                      type="button"
                      to="/admissionlogin"
                      className="forgot-link"
                    >
                      Login
                    </Link>
                  </FormGroup>
                  <div className="text-center mt-4 mb-4">
                    <Button color="primary" className="w-50" type="submit">
                      Submit
                    </Button>
                  </div>
                </form>
                <div className="form-copyright text-center">
                  Copyright © 2022 SALU.
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg="6"
            style={{
              backgroundImage: `url(${loginbg})`,
              backgroundSize: "cover",
            }}
          ></Col>
        </Row>
      </Container>
    </div>
  );
};

export default CandidateForgotPassword;
