import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Delete,
  Insert,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { CURRENT_SEMESTER } from "../../../utils/UrlConstants";

const StudentCourseOutline = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const [formLoader, setFormLoader] = useState(false);

  const timeoutRef = useRef(null);

  useEffect(() => {
    setFormLoader(true);
    getSemesterAttandance();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getSemesterAttandance = () => {
    const payload = {
      operationId: Select,
      studentDetailID: params?.id1,
      courseSemesterMappingID: params?.id2,
      parameterID: Delete,
      userID: 0,
    };
    PostRequest(CURRENT_SEMESTER, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table1,
          },
        });
        let studentDetails = {
          name: "StudentDetails",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: studentDetails,
        });
        timeoutRef.current = setTimeout(() => setFormLoader(false), 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "DayDate", name: "Day Date" },
    { field: "OutlineDescription", name: "Outline Description" },
  ];

  return (
    <AcademicFormComponent
      SemesterName="Regestered Courses Fall 2022"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      formLoader={formLoader}
    //   tableColorCode={true}
    />
  );
};

export default StudentCourseOutline;
