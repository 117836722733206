import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Input, Label, Table, Tooltip } from "reactstrap";
import {
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../../../components/Alert";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { PostFormRequest, PostRequest } from "../../../utils/Config";
import {
  CURRENT_SEMESTER_FACULTY_OPS,
  FACULTY_LECTURE_UPLOAD_COURSE_LECTURES_TIME_SLOT_MAPPING,
} from "../../../utils/UrlConstants";
import Spinner from "react-spinkit";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";

const FacultyFinalTermAttendance = (props) => {
  const { FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const {
    lecturerId = null,
    course = null,
    timeslotList = [],
    attandanceList = [],
    setAttandanceList = () => {},
    setCourseId = null,
  } = props;

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const params = useParams();

  const [formLoad, setFormLoad] = useState(true);

  const timeoutRef = useRef(null);

  const intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    enrollmentID: 0,
    MidTermExamMarks: 0,
    FinalExamMarks: 0,
    CpMarks: 0,
    IsSaved: true,
    IsLockByLecturer: true,
  };

  const intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_ = {
    FacultyLecturerCourseOutlineTimeSlotMappingID: 0,
    CourseSemesterLocationTimeSlotMappingID: 0,
    OutlineDescription: "",
    IsTaken: false,
    IsCompleted: false,
  };

  const [
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
  ] = useState(intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_);

  const [
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    setFacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
  ] = useState(
    intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_
  );

  const intialFormFields = {
    operationId: 1,
    parameterID: 7,
    employeeID: decryptData("EmplId", SessionStorage),
    courseSemesterLecturerMappingId: 0,
    courseSemesterMappingID: 0,
    userID: 0,
    userIP: decryptData(UserNetworkInfo)?.IPv4,
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_: [
      enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    ],
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_: [
      facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    ],
  };

  useEffect(() => {
    getFacultyExamRecap();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getFacultyExamRecap = () => {
    const payload = {
      ...intialFormFields,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
    };
    PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
      .then((res) => {
        setAttandanceList(res?.data?.Table);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: intialFormFields,
          },
        });
        setList(res?.data?.Table);
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Name", name: "Name" },
    { field: "Action", name: "Mid Term Marks" },
    // { field: "CPMarks", name: "CP Marks" },
    // { field: "FinalExamMarks", name: "Final Marks" },
    // { field: "IsSaved", name: "Saved" },
    // { field: "IsLockByLecturer", name: "Locked by Lecturer" },
    // { field: "HODRemarks", name: "HOD Remarks" },
    // { field: "IsLockByHod", name: "Lock by Hod" },
    // { field: "IsLockByExamController", name: "Lock by Exam Controller" },
  ];

  const handleInputChange = (e, index, name, type) => {
    e.preventDefault();
    if (type == "Lock") {
      TableList[index][name] = true;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    } else if (type == "Unlock") {
      TableList[index][name] = false;

      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    } else if (TableList[index]["MidTermExamMarks"] == "") {
    } else {
      TableList[index][e.target.name] = e.target.value;
      let totalMarks =
        +TableList[index]["MidTermExamMarks"] +
        +TableList[index]["FinalExamMarks"] +
        +TableList[index]["CPMarks"];
      if (totalMarks <= 100) {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: TableList,
            FormFields: FormFields,
          },
        });
      } else {
        CustomErrorMessage("Total Marks Should be less than 100");
      }
    }
  };

  const onSave = () => {
    const payload = {
      ...FormFields,
      operationId: Insert,
      parameterID: 7,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
      enrolledStudentAcademicRecordDetail_FacultyOps_TBL_: TableList?.map(
        (x) => ({
          EnrolledStudentAcademicRecordDetailID:
            x.EnrolledStudentAcademicRecordDetailID,
          enrollmentID: x.EnrollmentID,
          MidTermExamMarks: x.MidTermExamMarks,
          FinalExamMarks: x.FinalExamMarks,
          CpMarks: x.CpMarks,
          IsSaved: x.Issaved,
          IsLockByLecturer: x.IsLockByLecturer,
        })
      ),
    };
    PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              <>
                {list?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ color: "#4473c5" }}>{index + 1}</td>
                    <td style={{ color: "#4473c5" }}>{item.Name}</td>
                    <td style={{ color: "#4473c5" }}>
                      <div className="form-check-inline mt-1">
                        <Label className="form-check-Label">
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            checked={item.Issaved}
                            onChange={(e) => {
                              list[index].Issaved = e.target.checked;
                              setList([...list]);
                            }}
                          />{" "}
                          Present
                        </Label>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Final Term Attendance"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      customTable={customTable}
      onClickSave={onSave}
    />
  );
};

export default FacultyFinalTermAttendance;
