import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Input, Label, Row } from "reactstrap";
import CardCrudComponent from "../../../components/FormComponents/CardCrudForm";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import DatePicker from "react-datepicker";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";
import {
  businessUnit,
  cadreId,
  campusCity,
  campusType,
  company,
  costCenter,
  department,
  designation,
  domainId,
  employeeCategorySetupMasterId,
  employmentStatusId,
  facultyTypeId,
  gender,
  jobCategory,
  lecturerCatID,
  location,
  qualificationId,
  Search,
  Select,
  SessionStorage,
  userTypeId,
} from "../../../common/SetupMasterEnum";

import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  ADM_EligibilityCriteriaDependency,
  PostRequest,
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_CreateEmployee,
  Setup_GetEmployeeProfile,
  Setup_MasterDetails_All_Dropdowns,
  Setup_SearchEmployee,
  Setup_ShiftTiming,
} from "../../../utils/Config";
import Swal from "sweetalert2";
import { formatDateFunction } from "../../../functions/DateFormatFunction";
import { decryptData } from "../../../EncryptData";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { ADM_ELIGIBILITY_CRITERIA_DEPENDENCY } from "../../../utils/UrlConstants";

const initialSearchFields = {
  EmployeeCateId: 0,
  EmployeeName: "",
  EmployeeCode: "",
  CampusTypeId: 0,
  DepartmentId: 0,
  operationId: Search,
};
const initialFormFields = {
  employeeId: 0,
  employeeCode: 0,
  costCenterId: 0,
  hiringDepartmentId: 0,
  previousEmployeeCode: "",
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  fatherName: "",
  countryId: 0,
  cityId: 0,
  religionId: 0,
  genderId: 0,
  nationalityID: 0,
  matrialStatusId: 0,
  dateOfBirth: "",
  countryOfBirthId: 0,
  placeOfBirth: 0,
  domicileDistrict: "",
  highestDegreeid: 0,
  totalExperience: 0,
  cnic: "",
  cnicIssueDate: "",
  cnicExpiryDate: "",
  filerNonFiler: 0,
  ntn: "",
  ssn: "",
  primaryCellNo: "",
  homePhoneNo: "",
  workEmail: "",
  secondaryCellNo: "",
  officialPhoneNo: "",
  personalEmail: "",
  currentAddress: "",
  correspondenceAddress: 0,
  softwreLoginId: "",
  fileNo: "",
  employeeSectorId: 0,
  provinceId: 0,
  actual_DesignationId: 0,
  campusTypeId: 0,
  campusCityId: 0,
  employeeCateId: 0,
  employementStatusId: 0,
  hiringDesignationId: 0,
  hiringEmployeeGrade: 0,
  additionalChargeId: 0,
  currentDesignationId: 0,
  currentDepartmentId: 0,
  currentGradeId: 0,
  employeeStatusId: 0,
  facultyTypeId: 0,
  statusId: 0,
  idStatusId: 0,
  payRollTypeId: 0,
  joiningDate: "",
  probotionStartDate: "",
  probationEndDate: "",
  probotionExtendToDate: "",
  probationPeriodDays: 0,
  contractStartDate: "",
  contractEndDate: "",
  confirmationDate: "",
  relievingRetirementDate: "",
  tmsShiftId: 0,
  reportingto: 0,
  designationofRA: "",
  cardNumber: 0,
  accessCardId: 0,
  collectReasonId: 0,
  accessCardIssueDate: "",
  accessCardCollectDate: "",
  priodEndDate: "",
  inchargeId: 0,
  noticePeriodDays: 0,
  fax: "",
  isContractual: 0,
  pictureName: "",
  tmsLeaveId: 0,
  resignedDate: "",
  noOfChildren: 0,
  lastworkingDate: "",
  terminationTypeId: 0,
  noticePeriodTypeId: 0,
  hodid: 0,
  officialMobileNumber: "",
  managerId: 0,
  personalityDescription: "",
  officeExtension: "",
  leaveTypeId: 0,
  payScaleId: 0,
  domainId: 0,
  remarks: "",
  isDeleted: 0,
  isActive: 1,
  userId: 0,
  ModifiedBy: 0,
  userIP: "192.168.616",
  companyId: 0,
  locationId: 0,
  businessUnitId: 0,
  jobCategoryId: 0,
  FacultyDepartmentProgramId: 0,
  FacultyDepartmentID: 0,
  CHRSEmpCatagoryID: 0,
  QualificationID: 0,
};
const initialSelectionList = {
  EmployeeTypeList: [],
  CampusList: [],
  DepartmentList: [],
  GenderList: [],
  CompanyList: [],
  LocationList: [],
  BusinessUnitList: [],
  JobCategoryList: [],
  DesignationList: [],
  CostCenterList: [],
  DomainList: [],
  UserTypeList: [],
};
const Employees = () => {
  const { SearchFields, FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const [EmployeeCategoryList] = useSetupDetailList(qualificationId);
  // console.log(EmployeeCategoryList);

  const dispatch = useDispatch();
  const [selectionList, setSelectionList] = useState(initialSelectionList);
  const [imageUpload, setImageUpload] = useState();
  const [formLoad, setFormLoad] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [qualificationData, setQualificationData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [dob, setDob] = useState("");
  const [joinDate, setJoinDate] = useState("");
  const [checkStatus, setCheckStatus] = useState("false");
  useEffect(() => {
    getEmployee();
    getMasterDetailAllDropdown();
    getShiftData();
    getEmployeeDropDown();
  }, []);
  useEffect(() => {
    onChange_ADM_EligibilityCriteriaDependency({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    });
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        SearchFields: initialSearchFields,
        FormFields: {
          ...initialFormFields,
          userId: decryptData("loginId", SessionStorage),
        },
      },
    });
  }, []);

  const onChange_ADM_EligibilityCriteriaDependency = (payload) => {
    if (payload.paremeterID != undefined) {
      // ADM_EligibilityCriteriaDependency(payload)
      PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
        .then((res) => {
          if (payload.caseID === 2) {
            const data = { name: "Departments", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          } else if (payload.caseID === 3) {
            const data = { name: "Programs", value: res?.data?.Table };
            dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  function getEmployee() {
    setFormLoad(true);
    Setup_GetEmployeeProfile()
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: {
              ...initialFormFields,
              userId: decryptData("loginId", SessionStorage),
            },
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  }
  function getEmployeeDropDown() {
    const payload = {
      operationId: 1,
      employeeId: decryptData("EmplId", SessionStorage),
      case: 1,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {
        let data = {
          name: "EmployeeList",
          value: res.data.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: Select,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        setSelectionList({
          ...selectionList,
          CampusList: res.data.filter(
            (item) => item.SetupMasterId == campusType
          ),
          DepartmentList: res.data.filter(
            (item) => item.SetupMasterId == department
          ),
          GenderList: res.data.filter((item) => item.SetupMasterId == gender),

          CompanyList: res.data.filter((item) => item.SetupMasterId == company),

          JobCategoryList: res.data.filter(
            (item) => item.SetupMasterId == jobCategory
          ),
          LocationList: res.data.filter(
            (item) => item.SetupMasterId == location
          ),
          BusinessUnitList: res.data.filter(
            (item) => item.SetupMasterId == businessUnit
          ),

          CostCenterList: res.data.filter(
            (item) => item.SetupMasterId == costCenter
          ),
          DesignationList: res.data.filter(
            (item) => item.SetupMasterId == designation
          ),
          DomainList: res.data.filter((item) => item.SetupMasterId == domainId),
          EmployeeTypeList: res.data
          .filter(
            (item) => item.SetupMasterId == employeeCategorySetupMasterId && item.SetupDetailId !== 2396
          ),
          CadreList: res.data.filter((item) => item.SetupMasterId == cadreId),
          EmploymentStatus: res.data.filter(
            (item) => item.SetupMasterId == employmentStatusId
          ),
          FacultyTypeList: res.data.filter(
            (item) => item.SetupMasterId == facultyTypeId
          ),
          UserTypeList: res.data.filter(
            (item) =>
              item.SetupMasterId == userTypeId && item.SetupDetailId !== 2443
          ),
        });
 
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const columns = [{ field: "SetupDetailName", name: "Allowances Type" }];

  const handleSearchChange = (e) => {
    if (e.target.name == "CampusTypeId") {
      SearchFields.CampusCityId = 1644;
    }
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };
  function getShiftData() {
    Setup_ShiftTiming()
      .then((res) => {
        setShiftList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleAddChange = (e) => {
    if (e.target.name == "companyId") {
      let number = Number.isNaN(e.target.value);
      if (number == true) {
        FormFields.businessUnitId = 0;
        FormFields.hiringDepartmentId = 0;
        FormFields.jobCategoryId = 0;
        FormFields.hiringDesignationId = 0;
      }
    } else if (e.target.name === "FacultyDepartmentID") {
      onChange_ADM_EligibilityCriteriaDependency({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value,
      });
      let data1 = { name: "FacultyDepartmentProgramId", value: 0 };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (e.target.name == "businessUnitId") {
      let number = Number.isNaN(e.target.value);
      if (number == true) {
        FormFields.hiringDepartmentId = 0;
        FormFields.jobCategoryId = 0;
        FormFields.hiringDesignationId = 0;
      }
    } else if (e.target.name == "hiringDepartmentId") {
      let number = Number.isNaN(e.target.value);
      if (number == true) {
        FormFields.jobCategoryId = 0;
        FormFields.hiringDesignationId = 0;
      }
    } else if (e.target.name == "jobCategoryId") {
      let number = Number.isNaN(e.target.value);
      if (number == true) {
        FormFields.hiringDesignationId = 0;
      }
    } else if (e.target.name == "campusTypeId") {
      FormFields.campusCityId = 1644;
    }
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const AllDateSet = (event, type) => {
    if (type === "dateOfBirth") {
      setDob(event);
      let data = {
        name: "dateOfBirth",
        value: formatDateFunction(event, "-"),
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
    } else if (type === "joiningDate") {
      setJoinDate(event);
      let data = {
        name: "joiningDate",
        value: formatDateFunction(event, "-"),
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
    }
  };
  const handleImageChange = (event) => {
    setImageUpload(event.target.files[0]);
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" sm="6" xs="12">
        <FormGroupSelect
          label="Campus Type"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == campusType
          )}
          name="CampusTypeId"
          value={SearchFields?.CampusTypeId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="3" md="3" sm="6" xs="12">
        <FormGroupSelect
          label="Campus City"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == SearchFields?.CampusTypeId
          )}
          name="CampusCityId"
          value={SearchFields?.CampusCityId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          disabled={!SearchFields?.CampusTypeId}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Department"
          list={selectionList?.DepartmentList}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          name="DepartmentId"
          value={SearchFields?.DepartmentId}
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Employee Category"
          list={selectionList?.EmployeeTypeList}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          name="EmployeeCateId"
          value={SearchFields?.EmployeeCateId}
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Employee Name"
          name="EmployeeName"
          onChange={handleSearchChange}
          value={SearchFields?.EmployeeName}
          maxLength="200"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Employee Code"
          name="EmployeeCode"
          onChange={handleSearchChange}
          value={SearchFields?.EmployeeCode}
          isNumber="true"
        />
      </Col>
    </Fragment>
  );

  const submitForm = (_, setToggleForm) => {
    setSubmitLoad(true);
    Setup_CreateEmployee(FormFields, imageUpload)
      .then((res) => {
        dispatch({
          type: RESET_FORM_FIELDS,
          payload: {
            ...initialFormFields,
            userId: decryptData("loginId", SessionStorage),
          },
        });
        if (res?.data?.Table[0]?.haserror == 0) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              setDob("");
              setJoinDate("");
              getEmployee();
              setSubmitLoad(false);
              setToggleForm(false);
            } else if (results.isConfirmed) {
              setDob("");
              setJoinDate("");
              getEmployee();
              setSubmitLoad(false);
              setToggleForm(false);
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
              setSubmitLoad(false);
              setToggleForm(false);
              setDob("");
              setJoinDate("");
            } else if (results.isConfirmed) {
              setSubmitLoad(false);
              setToggleForm(false);
              setDob("");
              setJoinDate("");
            }
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        }).then((results) => {
          if (results.isDismissed) {
            setSubmitLoad(false);
            setToggleForm(false);
            setDob("");
            setJoinDate("");
          } else if (results.isConfirmed) {
            setSubmitLoad(false);
            setToggleForm(false);
            setDob("");
            setJoinDate("");
          }
        });
        console.error(err);
      });
  };

  const submitSearch = () => {
    setFormLoad(true);
    console.log(SearchFields)
    debugger
    Setup_SearchEmployee(SearchFields)
      .then((res) => {
        setFormLoad(false);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: {
              ...initialFormFields,
              userId: decryptData("loginId", SessionStorage),
            },
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        setFormLoad(false);
        console.error(err);
      });
  };
  const cancelSearch = () => {
    getEmployee();
  };
  const onCancelForm = () => {
    setDob("");
    setJoinDate("");
  };

  const onEditRow = (obj) => {};

  const onDeleteRow = (obj) => {};

  const formPanel = (
    <Fragment>
      <Row>
        <Col lg="12" md="12" xs="12">
          <h5>Company Information</h5>
        </Col>
        <hr className="mb-2" />

        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            label="Location"
            required
            list={selectionList?.LocationList}
            name="locationId"
            onChange={handleAddChange}
            value={FormFields?.locationId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            required
            label="Company Domain"
            list={selectionList?.DomainList}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            value={FormFields?.domainId}
            name="domainId"
            onChange={handleAddChange}
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            label="Company"
            required
            list={SupportingTables?.MasterDropdown?.filter(
              (x) => x.SetupMasterId == company
            )}
            name="companyId"
            onChange={handleAddChange}
            value={FormFields?.companyId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            required
            label="Business Unit"
            list={SupportingTables?.MasterDropdown?.filter(
              (x) =>
                x.SetupMasterId == businessUnit &&
                x.parentid == FormFields?.companyId
            )}
            name="businessUnitId"
            onChange={handleAddChange}
            value={FormFields?.businessUnitId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            disabled={!FormFields?.companyId}
          />
        </Col>
        <Col md="3" lg="3">
          <FormGroupSelect
            label="Faculty Department"
            name="FacultyDepartmentID"
            list={SupportingTables?.Departments}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            value={FormFields?.FacultyDepartmentID}
            onChange={handleAddChange}
          />
        </Col>
        <Col md="3" lg="3">
          <FormGroupSelect
            label="Faculty Department Program"
            name="FacultyDepartmentProgramId"
            list={SupportingTables?.Programs}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            value={FormFields?.FacultyDepartmentProgramId}
            onChange={handleAddChange}
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            required
            label="Designation"
            list={SupportingTables?.MasterDropdown?.filter(
              (x) => x.SetupMasterId == designation
            )}
            name="hiringDesignationId"
            onChange={handleAddChange}
            value={FormFields?.hiringDesignationId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            required
            label="Cost Center"
            list={selectionList?.CostCenterList}
            name="costCenterId"
            onChange={handleAddChange}
            value={FormFields?.costCenterId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            label="Campus Type"
            list={SupportingTables?.MasterDropdown?.filter(
              (x) => x.SetupMasterId == campusType
            )}
            name="campusTypeId"
            value={FormFields?.campusTypeId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            label="Campus City"
            list={SupportingTables?.MasterDropdown?.filter(
              (x) =>
                x.SetupMasterId == campusCity &&
                x.parentid == FormFields?.campusTypeId
            )}
            name="campusCityId"
            value={FormFields?.campusCityId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            onChange={handleAddChange}
            disabled={!FormFields?.campusTypeId}
            required
          />
        </Col>

        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            label="Incharge"
            name="inchargeId"
            onChange={handleAddChange}
            value={FormFields?.inchargeId}
            list={SupportingTables?.EmployeeList}
            fieldId="Id"
            fieldName="Name"
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12" xs="12" className="pt-3">
          <h5>Personal Information</h5>
        </Col>
        <hr className="mb-2" />
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            type="text"
            required
            label="CNIC"
            name="cnic"
            onChange={handleAddChange}
            value={FormFields?.cnic}
            isNumber="true"
            maxLength="13"
            minLength="11"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            required
            label="First Name"
            name="firstName"
            onChange={handleAddChange}
            value={FormFields?.firstName}
            maxLength="50"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            label="Middle Name"
            name="middleName"
            onChange={handleAddChange}
            value={FormFields?.middleName}
            maxLength="50"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            label="Last Name"
            name="lastName"
            onChange={handleAddChange}
            value={FormFields?.lastName}
            maxLength="50"
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            label="User Type"
            list={selectionList?.UserTypeList}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            onChange={handleAddChange}
            name="userTypeId"
            value={FormFields?.userTypeId}
            required
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            label="Employee Category"
            list={selectionList?.EmployeeTypeList}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            onChange={handleAddChange}
            name="employeeCateId"
            value={FormFields?.employeeCateId}
            required
          />
        </Col>

        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            name="cadreId"
            list={selectionList?.CadreList}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            value={
              FormFields?.employeeCateId === 2112 ? 0 : FormFields?.cadreId
            }
            label="Cadre"
            disabled={FormFields?.employeeCateId === 2112 ? true : false}
            onChange={handleAddChange}
            required={FormFields?.employeeCateId === 2112 ? false : true}
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            name="CHRSEmpCatagoryID"
            list={SupportingTables?.MasterDropdown?.filter(
              (x) => x.SetupMasterId === lecturerCatID
            )}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            value={
              FormFields?.employeeCateId === 2112
                ? FormFields?.CHRSEmpCatagoryID
                : 0
            }
            label="Positions"
            onChange={handleAddChange}
            required={FormFields?.employeeCateId === 2112 ? true : false}
            disabled={FormFields?.employeeCateId === 2112 ? false : true}
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            list={EmployeeCategoryList}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            label="Qualification"
            onChange={handleAddChange}
            name="QualificationID"
            value={FormFields?.QualificationID}
            required
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            list={shiftList}
            fieldId="TMSShiftId"
            fieldName="ShiftName"
            label="Shift Timing"
            onChange={handleAddChange}
            name="tmsShiftId"
            value={FormFields?.tmsShiftId}
            required
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <FormGroupSelect
            name="employementStatusId"
            list={selectionList?.EmploymentStatus}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
            value={FormFields?.employementStatusId}
            label="Employment Status"
            onChange={handleAddChange}
            required
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <div className="form-group">
            <label className="form-label">
              Date of Birth<span className="text-danger">*</span>
            </label>
            <DatePicker
              // selected={dob}
              // onChange={(e) => AllDateSet(e, "dateOfBirth")}
              // className="form-control"
              // name="dateOfBirth"
              // required={true}
              // showYearDropdown={true}
              // placeholderText={dateFormatPlaceholder}
              // dateFormat={dateFormat}

              selected={dob}
              onChange={(e) => AllDateSet(e, "dateOfBirth")}
              className="form-control"
              name="dateOfBirth"
              required={true}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
              placeholderText={dateFormatPlaceholder}
              dateFormat={dateFormat}
            />
          </div>
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupSelect
            required
            label="Gender"
            list={selectionList?.GenderList}
            name="genderId"
            onChange={handleAddChange}
            value={FormFields?.genderId}
            fieldId="SetupDetailId"
            fieldName="SetupDetailName"
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            required
            label="Personal Email"
            name="personalEmail"
            onChange={handleAddChange}
            type="email"
            value={FormFields?.personalEmail}
          />
        </Col>
        <Col lg="3" md="3" xs="12">
          <FormGroupInput
            required
            label="Father/Husband Name"
            name="fatherName"
            onChange={handleAddChange}
            value={FormFields?.fatherName}
            maxLength="100"
          />
        </Col>
        <Col lg="3" md="3" sm="6" xs="12">
          <div className="form-group">
            <label className="form-label">
              Date of Joining<span className="text-danger">*</span>
            </label>
            <DatePicker
              // selected={joinDate}
              // onChange={(e) => AllDateSet(e, "joiningDate")}
              // className="form-control"
              // name="joiningDate"
              // required={true}
              // showYearDropdown={true}
              // placeholderText={dateFormatPlaceholder}
              // dateFormat={dateFormat}

              selected={joinDate}
              onChange={(e) => AllDateSet(e, "joiningDate")}
              className="form-control"
              name="joiningDate"
              required={true}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={dateFormatPlaceholder}
              dateFormat={dateFormat}
            />
          </div>
        </Col>
        <Col lg="3" md="3" xs="12">
          <Label>Upload Image</Label>
          <Input
            label="Upload Image"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleImageChange}
          />
        </Col>
      </Row>
    </Fragment>
  );
  return (
    <CardCrudComponent
      formName="Employee"
      buttonName="Create New Employee"
      modalSize="lg"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      formLoad={formLoad}
      submitLoad={submitLoad}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      checkStatus={checkStatus}
      onCancelForm={onCancelForm}
      disableForceFormToggle={true}
    />
  );
};

export default Employees;
