import React, { forwardRef } from "react";
import logoimg from "../../../../assets/img/logo.png";
import salulogo from "../../../../assets/img/logo.png";
import { useSelector } from "react-redux";

const StudentTranscriptReport = forwardRef((props, ref) => {
  const { TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );

  const {
    SemesterOne,
    SemesterOneSum,
    SemesterTwo,
    SemesterTwoSum,
    SemesterThree,
    SemesterThreeSum,
    SemesterFour,
    SemesterFourSum,
    SemesterFive,
    SemesterFiverSum,
    SemesterSix,
    SemesterSixSum,
    SemesterSeven,
    SemesterSevenSum,
    SemesterEight,
    SemesterEightSum,
    TotalSum,
    StudentRecord,
  } = SupportingTables;
  return (
    <>
      <div
        ref={ref}
        style={{
          fontSize: 11,
          margin: 10,
          padding: "0 20px",
          backgroundColor: "#fff",
        }}
      >
        <table style={{ width: "100%" }}>
          <thead className="text-center">
            <tr>
              <th style={{ width: "100%" }}>
                <h4 className="text-center" style={{ marginBottom: "0" }}>
                  <img src={salulogo} height="50" />
                  Shah Abdul Latif University Khairpur
                </h4>
                {/* <h6>Sindh Pakistan</h6> */}
                <hr style={{ margin: "0", height: "2px" }} />
                <hr style={{ margin: "0", height: "2px" }} />
                <div className="text-center" style={{ paddingTop: "10px" }}>
                  {/* <p>Book No: 002</p> */}
                  <h6 style={{ marginLeft: "5px", textAlign: "center" }}>
                    Transcript
                  </h6>
                  {/* <p>Certificate No: 002</p> */}
                </div>
                <div>
                  <h6>
                    {" "}
                    Bachelor of {
                      StudentRecord?.[0]?.AdmissionFacultyDepartment
                    }{" "}
                    - {StudentRecord?.[0]?.FacultyDepartmentProgram}
                  </h6>
                </div>
              </th>
            </tr>
          </thead>
          <div>
            <p style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Department : {StudentRecord?.[0]?.AdmissionFacultyDepartment}
            </p>
            <p>Name : {StudentRecord?.[0]?.Name}</p>
          </div>
          <tbody style={{height:"710px"}}>
            <div className="d-flex" style={{ width: "100%" }}>
              {SemesterOne?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterOne?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterOne?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterOneSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}

              {SemesterTwo?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterTwo?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterTwo?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterTwoSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>

            <div className="d-flex" style={{ width: "96%" }}>
              {SemesterThree?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterThree?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterThree?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterThreeSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}

              {SemesterFour?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterFour?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterFour?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterFourSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>

            <div className="d-flex" style={{ width: "100%" }}>
              {SemesterFive?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterFive?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterFive?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterFiverSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}

              {SemesterSix?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterSix?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterSix?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterSixSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>

            <div className="d-flex" style={{ width: "100%" }}>
              {SemesterSeven?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterSeven?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterSeven?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterSevenSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}

              {SemesterEight?.length > 0 ? (
                <table
                  style={{ width: "50%", margin: "5px" }}
                  className="text-center"
                >
                  <thead className="tbl-hd-bgsalu">
                    <tr>
                      <th className="tbl-row-border-alt">
                        {SemesterEight?.[0]?.Semester}
                      </th>
                    </tr>
                    <tr colSpan={7} className="text-center">
                      <th className="tbl-row-border-alt">Courses</th>
                      <th className="tbl-row-border-alt">Credit</th>
                      <th className="tbl-row-border-alt">Obt</th>
                      <th className="tbl-row-border-alt">Grade</th>
                      <th className="tbl-row-border-alt">Gp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SemesterEight?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td className="tbl-row-border-alt">{x.Courses}</td>
                        <td className="tbl-row-border-alt">{x.CreditHours}</td>
                        <td className="tbl-row-border-alt">{x.Obt}</td>
                        <td className="tbl-row-border-alt">{x.Grade}</td>
                        <td className="tbl-row-border-alt">{x.GP}</td>
                      </tr>
                    ))}
                    {SemesterEightSum?.map((x, i) => (
                      <tr className="text-center" key={i}>
                        <td
                          style={{
                            border: "1px solid #000",

                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumCreditHours}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumObt}
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          GPA
                        </td>
                        <td className="tbl-row-border-alt" id="bold">
                          {x.SumGP}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>

            <div className="d-flex text-center">
              {SemesterOne?.length > 0 ? (
                <table
                  className="text-center"
                  style={{ width: "50%", margin: "5px" }}
                >
                  <tbody>
                    <tr>
                      <th className="tbl-row-border-alt">
                        TOTAL MAXIMUM MARKS
                      </th>
                      <th className="tbl-row-border-alt">
                        {TotalSum?.[0]?.TotalObtainMarks}
                      </th>
                    </tr>
                    <tr>
                      <th className="tbl-row-border-alt">
                        PROGRAM CREDIT HOURS
                      </th>
                      <th className="tbl-row-border-alt">
                        {TotalSum?.[0]?.ProgramCreditHours}
                      </th>
                    </tr>
                  </tbody>
                </table>
              ) : (
                ""
              )}
              {SemesterOne?.length > 0 ? (
                <table
                  className="text-center"
                  style={{ width: "50%", margin: "5px" }}
                >
                  <tbody>
                    <tr>
                      <th className="tbl-row-border-alt">
                        TOTAL OBTAINED MARKS
                      </th>
                      <th className="tbl-row-border-alt">
                        {TotalSum?.[0]?.TotalObtainMarks}
                      </th>
                    </tr>
                    <tr>
                      <th className="tbl-row-border-alt">CGPA</th>
                      <th className="tbl-row-border-alt">
                        {TotalSum?.[0]?.CGPA}
                      </th>
                    </tr>
                  </tbody>
                </table>
              ) : (
                "Data Not Found"
              )}
            </div>

           
          </tbody>
          <footer>
          <div style={{ margin: "5px" }}>
              <p>Prepared by __________________</p>
              <p className="mb-0">Checked by __________________</p>
              <p id="bold" className="text-right mb-0">
                Controller Examination
              </p>
              <p className="mb-0">Issuance Date: - {Date()}</p>
              <p className="mb-0">
                Note: The University reserves the right to issuing any
                correction in the result, if any mistake is detected later on.
              </p>
            </div>
          </footer>
        </table>
      </div>
    </>
  );
});

export default StudentTranscriptReport;
