import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Insert,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import {
  CURRENT_SEMESTER,
  CURRENT_SEMESTER_FACULTY_OPS,
} from "../../../utils/UrlConstants";

const FacultySchedule = () => {
  const enrolledStudentAcademicRecordDetail_FacultyOps_TBL_ = [
    {
      enrolledStudentAcademicRecordDetailID: 0,
      enrollmentID: 0,
      midTermExamMarks: 0,
      finalExamMarks: 0,
      cpMarks: 0,
      isSaved: true,
      isLockByLecturer: true,
    },
  ];

  const facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_ = [
    {
      facultyLecturerCourseOutlineTimeSlotMappingID: 0,
      courseSemesterLocationTimeSlotMappingID: 0,
      outlineDescription: "",
      isTaken: false,
      isCompleted: false,
    },
  ];
  const intialFormFields = {
    operationId: Select,
    parameterID: Insert,
    employeeID: decryptData("EmplId", SessionStorage),
    courseSemesterLecturerMappingId: 0,
    courseSemesterMappingID: 0,
    userID: 0,
    userIP: "string",
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const [formLoader, setFormLoader] = useState(false);

  const timeoutRef = useRef(null);

  useEffect(() => {
    setFormLoader(true);
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: new Array(0),
        FormFields: intialFormFields,
      },
    });
    getSemesterSchedule();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getSemesterSchedule = () => {
    const payload = {
      ...intialFormFields,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
    };
    PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: intialFormFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoader(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "LocName", name: "Location Name" },
    { field: "SectionName", name: "Section" },
    { field: "DayDate", name: "Date" },
    { field: "TimeSlot", name: "Time Slot" },
  ];

  return (
    <AcademicFormComponent
      SemesterName="My Schedule"
      columns={columns}
      rows={TableList}
      formLoader={formLoader}
    />
  );
};

export default FacultySchedule;
