import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { forwardRef } from "react";
import logo from "../../../assets/img/header-img.jpg";

const SecureAdmissionSeatFacultyWiseReport = forwardRef((props, ref) => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  return (
    <div style={{ margin: 20 }} className="page-break portrait-page" ref={ref}>
      <Row>
        <Col>
          <div className="table-responsive">
            <p className="underLineText mb-3 mt-3">
              Secure Admission Seat Faculty Wise List Report
            </p>
            <table
              style={{
                border: "1px",
                borderStyle: "solid",
              }}
              className="table "
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Cnic</th>
                  <th>Name</th>
                  <th className="text-center">Deparment</th>
                  <th className="text-center">Program</th>
                  <th className="text-center">Reg No</th>
                  <th className="text-center">Seat Type</th>
                </tr>
              </thead>
              <tbody>
                {TableList?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.Cnic}</td>
                      <td>{item.Name}</td>
                      <td className="text-center">{item.Deparment}</td>
                      <td className="text-center">{item.Program}</td>
                      <td className="text-center">{item.Reg_No}</td>
                      <td className="text-center">{item.SeatType}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default SecureAdmissionSeatFacultyWiseReport;
