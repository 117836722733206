import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import {
  boardId,
  designation,
  examinationId,
  grade,
  SessionStorage,
  totalMarksId,
  typeOfOrganization,
  yearId,
} from "../../../common/SetupMasterEnum";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import {
  formatDateFunction,
  formatDateFunction2,
} from "../../../functions/DateFormatFunction";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../utils/Config";
import DatePicker from "react-datepicker";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../common/dateFormatEnum";
import { decryptData } from "../../../EncryptData";
import { ADMISSION_USER_ID, REG_ID } from "../../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { CustomErrorMessage } from "../../../components/Alert";
const initialFormFields = {
  AdmARID: 0,
  ADMRegID: 0,
  NameOfOrganization: "",
  DesignationId: 0,
  DesignationName: "",
  PlaceofPostingName: "",
  TypeOfOrganizationName: "",
  BPS_GradeId: 0,
  PlaceofPostingId: 0,
  FromDate: new Date(),
  ToDate: new Date(),
  TypeOfOrganizationId: 0,
  IsActive: 1,
  IsDeleted: 0
};
const Employment = (props) => {
  initialFormFields.ADMRegID = decryptData(REG_ID, SessionStorage);
  const dispatch = useDispatch();
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const EmployementRecord = SupportingTables?.students?.Table8;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState(initialFormFields);
  const [employmentData, setEmploymentData] = useState([]);
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    });
  };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  useEffect(() => {
    setEmploymentData(EmployementRecord);
  }, [EmployementRecord]);

  const columns = [
    { field: "NameOfOrganization", name: "Name of Organization" },
    { field: "DesignationName", name: "Designation" },
    { field: "BPS_GradeName", name: "BPS/Grade" },
    { field: "PlaceofPostingName", name: "Place of Posting" },
    { field: "TypeOfOrganizationName", name: "Type of Organization" },
    { field: "FromDate", name: "From" },
    { field: "ToDate", name: "To" },
  ];
  const onAdd = (e) => {
    e.preventDefault();
    formFields.index > -1
      ? (employmentData[formFields.index] = formFields)
      : employmentData.push({
          ...formFields,
          index: -1,
        });
    setEmploymentData([...employmentData]);
    setFormFields({
      ...initialFormFields,
    });
  };
  const onEdit = (index, obj) => {
    setFormFields({
      ...obj,
      index: index,
    });
  };
  const onDelete = (index) => {
    employmentData.splice(index, 1);
    setEmploymentData([...employmentData]);
  };
  const onCancel = () => {
    setFormFields({
      ...initialFormFields,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();
    if (statusData[0]?.PersonalInfo == 0 || statusData[0]?.GATGeneralId == 0 || statusData[0]?.Academics == 0) {
      CustomErrorMessage("Kindly Complete Admission Application Step Wise");
    } else if (employmentData.length == 0) {
      Swal.fire({
        title: "Error",
        text: "Please Add Academic Information",
        icon: "error",
      });
    } else {
      let formData = new FormData();
      Object.keys(basicValues).forEach((d) => {
        formData.append(d, basicValues[d]);
      });
      employmentData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(`ADMEmployement_[${index}].${item}`, element[item]);
        });
      });
      updateAdmission(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          getStudendRecords();
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: SupportingTables.ProgramId,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {

        let data = {
          name: "students",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const AllDateSet = (event, type) => {
    if (type === "FromDate") {
      setFormFields({
        ...formFields,
        FromDate: formatDateFunction(event, "-"),
      });
    } else if (type === "ToDate") {
      setFormFields({
        ...formFields,
        ToDate: formatDateFunction(event, "-"),
      });
    }
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Employment Details</CardTitle>
        <CardBody>
          <form onSubmit={onAdd}>
            <Row>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Name of Organization"
                  name="NameOfOrganization"
                  value={formFields?.NameOfOrganization}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Designation"
                  name="DesignationId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == designation
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.DesignationId}
                  onChange={handleInputChangeSelect}
                  required
                  nameValue="DesignationName"
                />
              </Col>
              {/* <Col md="3" lg="3">
                <FormGroupInput
                  label="Group/ Major Subjects"
                  name="MajorSubject"
                  value={formFields?.MajorSubject}
                  onChange={handleInputChange}
                />
              </Col> */}
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="BPS/Grade"
                  name="BPS_GradeId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == grade
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.BPS_GradeId}
                  onChange={handleInputChangeSelect}
                  nameValue="BPS_GradeName"
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Place of Posting"
                  name="PlaceofPostingId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == 1067
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.PlaceofPostingId}
                  onChange={handleInputChangeSelect}
                  nameValue="PlaceofPostingName"
                  required
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Type of Organization"
                  name="TypeOfOrganizationId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == typeOfOrganization
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={formFields?.TypeOfOrganizationId}
                  onChange={handleInputChangeSelect}
                  nameValue="TypeOfOrganizationName"
                  required
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <div className="form-group">
                  <label className="form-label">From Date<span class="text-danger">*</span></label>
                  <DatePicker
                    selected={new Date(formFields?.FromDate)}
                    dateFormat={dateFormat}
                    // minDate={formFields?.FromDate}
                    onChange={(e) => AllDateSet(e, "FromDate")}
                    className="form-control"
                    name="FromDate"
                    placeholderText={dateFormatPlaceholder}
                    required
                  />
                </div>
              </Col>
              <Col lg="3" md="3" xs="12">
                <div className="form-group">
                  <label className="form-label">To Date<span class="text-danger">*</span></label>
                  <DatePicker
                    selected={new Date(formFields?.ToDate)}
                    dateFormat={dateFormat}
                    // minDate={formFields?.ToDate}
                    onChange={(e) => AllDateSet(e, "ToDate")}
                    className="form-control"
                    name="ToDate"
                    placeholderText={dateFormatPlaceholder}
                    required
                  />
                </div>
              </Col>
              {/* <Col md="3" lg="3">
                <FormGroupInput
                  label="Type of Organization"
                  name="ObtainedMarks"
                  value={formFields?.ObtainedMarks}
                  onChange={handleInputChange}
                  isNumber="true"
                  required
                  maxLength="5"
                />
              </Col> */}
              {/* <Col md="3" lg="3">
                  <FormGroupInput
                    label="From Date"
                    name="TotalMarks"
                    value={formFields?.TotalMarks}
                    onChange={handleInputChange}
                    isNumber="true"
                    maxLength="5"
                    required
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupInput
                    label="To Date"
                    name="TotalMarks"
                    value={formFields?.TotalMarks}
                    onChange={handleInputChange}
                    isNumber="true"
                    maxLength="5"
                    required
                  />
                </Col> */}
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Add/Update
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Employment Records</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={employmentData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" onClick={onUpdate} disabled={statusData[0]?.Status == 1 ? true : false}>
                  {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Employment;
