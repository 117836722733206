import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { dateFormat, dateFormatPlaceholder } from "../../utils/CommonMethods";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";

const initialValues = {
  name: "",
  fatherName: "",
  surname: "",
  designation: "",
  gradeBps: "",
  department: "",
  dateOfFirstAppointment: "",
  inBps: "",
  highestQualification: "",
  totalService: "",
  cnic: "",
  cellNumber: "",
  whatsapp: "",
  email: "",
};


const ACRBPS5To15 = () => {

  const [formFields, setFormFields] = useState({
    ...initialValues,
  });

  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const concernedcolumns = [
    { field: "name", name: "Name" },
    { field: "fatherName", name: "Father Name" },
    { field: "surname", name: "Surname" },
    { field: "designation", name: "Designation" },
    { field: "gradeBps", name: "Grade/BPS" },
    { field: "department", name: "Department" },
    { field: "dateOfFirstAppointment", name: "Date Of First Appointment" },
    { field: "inBps", name: "In BPS" },
    { field: "highestQualification", name: "Highest Qualification" },
    { field: "totalService", name: "Total Service" },
    { field: "cnic", name: "CNIC" },
    { field: "cellNumber", name: "Cell Number" },
    { field: "whatsapp", name: "WhatsApp" },
    { field: "email", name: "Email" },
  ];
  const concernedrows = [
    { name: "Shahzad", fatherName: "Khan", surname: "Arman", designation: "Professor", gradeBps: "BPS-5", department: "Technology", 
    dateOfFirstAppointment: "10-01-2008", inBps: "BPS-10", highestQualification: "Graduation", totalService: "5 years", cnic: "43201-1213331-4", cellNumber: "0312-1214444",
    whatsapp: "0312401344", email: "khan@gmail.com"},

    { name: "Usama", fatherName: "Ahsan", surname: "Rehan", designation: "Deen", gradeBps: "BPS-14", department: "Technology", 
    dateOfFirstAppointment: "01-11-2010", inBps: "BPS-5", highestQualification: "Masters", totalService: "2 year", cnic: "41201-9200211-5", cellNumber: "0322-1213333",
    whatsapp: "032111111", email: "ashan@gmail.com"},
  ];

  const reportperiodcolumns = [
    { field: "fromDate", name: "From Date" },
    { field: "toDate", name: "To Date" },
  ];

  const reportperiodrows = [
    {
      fromDate: "11-01-2010",
      toDate: "17-06-2012",
    },
    {
      fromDate: "21-01-2021",
      toDate: "22-12-2021",
    },
  ];

  const statusList = [
    { id: 1, name: "Approved" },
    { id: 2, name: "Rejected" },
    { id: 3, name: "Discuss" },
  ];


  const AllDateSet = (event, type) => {
    if (type === "fromDate") {
      setFormFields({
        ...formFields,
        fromDate: event,
      });
    } else if (type === "toDate") {
      setFormFields({
        ...formFields,
        toDate: event,
      });
    }
  };

  const onEdit = () => { };
  const onDelete = () => { };

  return (
    <Container fluid>
    <h6 className="m-0 pt-3 pb-2">Annual Confidential Report For Employees (BPS 5-15)</h6>
    <Card>
    <CardTitle>Report for the Period</CardTitle>
    <CardBody>
    <Row>
    <Col lg='3' md='3' xs='12'>
    <div className='form-group'>
      <label className='form-label'>From Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
    </Col>
    <Col lg='3' md='3' xs='12'>
    <div className='form-group'>
      <label className='form-label'>To Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
    </Col>
    </Row>

    {/*<Row className="pb-3 pt-3">
    <Col lg="12" md="12" sm="12" className="text-right">
    <Button color="primary">Save</Button>
    </Col>
    </Row>
    
    <Row>
    <Col lg='12' md='12' xs='12'>
        <FormGroupTable
          columns={reportperiodcolumns}
          rows={reportperiodrows}
          onEdit={onEdit}
          onDelete={onDelete}
        />
    </Col>
  </Row>*/}
    <p className="m-0 pt-3"><strong>Note:</strong> This report is to be written by the Reporting Officer for each calender year and is to be initiated in the first week of December, unless it is written at any other time of the transfer of the Reporting Officer</p>
   
    </CardBody>
    </Card>

    <Card>
    <CardTitle>
    Important Instructions:
    </CardTitle>
    <CardBody>
    <Row>
    <Col lg="12" md="12" sm="12">
    <ul className="ps-3">
    <li>If the Countersigning Officer differs with the grading or remarks given by the Reporting Officer, he should score it out and give his own grading in red ink.</li>
    <li>Annual Confidential Report (ACR) is the most important record for the assessment of a Teacher. At the same time quality of ACR is the most important record for the assessment of a Teacher. At the same time quality of ACR is the most important record for the assessment of a Teacher. At the same time quality of ACR is a measure of the competence of the Reporting Officer and Countersigning Officer. It is, therefore, essential that utmost care be exercised by Reporting officer and Countersigning Officer.</li>
    <li>Reporting Officer is expected to counsel the teacher being reported upon about this weak point(s) and advise him how to improve. Adverse Remarks should normally be recorded when the teacher fails to improve despite counseling.</li>
    <li>The ACR from should be filled in a neat and tidy manner. Cuttings/erasiures should be avoided and must be initialed, where made..</li>
    <li>The reporting Officer should be
    <ul className="ps-4">
    <li>As objective as possible.</li>
    <li>Clear and direct, not ambiguous or evasive in his remarks</li>
    <li>Whenever the appraisal, it must be substantiated.</li>
    </ul>
    </li>
    <li>Time schedule for completion of ACR writing
    <ul className="ps-4">
    <li>The Reporting Officer should record his remarks by the end of November and send the report must be substantiated.</li>
    <li>The Countersigning Officer should record his remarks by the end of the 1st week of December</li>
    </ul>
    </li>
    <li> Vague impression based on inadequate knowledge or isolated incident should be avoided</li>
    </ul>
    </Col>
    </Row>
    </CardBody>
    </Card>

    <Card className='mt-3'>
        <CardTitle>Part-I (To be filled by the Concerned)</CardTitle>
        <CardBody>
          <Row>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Name' name='name' onChange={handleInputChange} value={formFields.name} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Father Name' name='fatherName' onChange={handleInputChange} value={formFields.fatherName} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Surname' name='surname' onChange={handleInputChange} value={formFields.surname} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Designation' name='designation' onChange={handleInputChange} value={formFields.designation} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Grade/BPS' name='gradeBps' onChange={handleInputChange} value={formFields.gradeBps} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Department' name='department' onChange={handleInputChange} value={formFields.department} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <div className='form-group'>
            <label className='form-label'>Date Of First Appointment</label>
            <DatePicker
              selected={formFields.fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "fromDate")}
              className='form-control'
              name='fromDate'
              placeholderText={dateFormatPlaceholder}
            />
           </div>
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='In BPS' name='inBps' onChange={handleInputChange} value={formFields.inBps} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Highest Qualification' name='highestQualification' onChange={handleInputChange} value={formFields.highestQualification} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <div className='form-group'>
            <label className='form-label'>Date Of Birth</label>
            <DatePicker
              selected={formFields.fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "fromDate")}
              className='form-control'
              name='fromDate'
              placeholderText={dateFormatPlaceholder}
            />
          </div> </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Total Service' name='totalService' onChange={handleInputChange} value={formFields.totalService} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='CNIC' name='cnic' onChange={handleInputChange} value={formFields.cnic} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Cell Number' name='cellNumber' onChange={handleInputChange} value={formFields.cellNumber} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='WhatsApp' name='whatsapp' onChange={handleInputChange} value={formFields.whatsapp} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Email' name='email' onChange={handleInputChange} value={formFields.email} />
          </Col>
          </Row>

         {/*<Row>
          <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
          <Button color="primary">Save</Button>
          </Col>
        </Row>

          <Row>
          <Col lg='12' md='12' xs='12'>
              <FormGroupTable
                columns={concernedcolumns}
                rows={concernedrows}
                onEdit={onEdit}
                onDelete={onDelete}
              />
          </Col>
          </Row>*/} 
        </CardBody>
      </Card>

      <Card>
      <CardTitle>Part-I (To Be Filled By The Reporting Officer)</CardTitle>
      <CardBody>
    <Row>
      <Col lg="12" md="12" sm="12">
      <h6>Guidance For Evaluation</h6>
      <Table bordered className="text-center">
      <tbody>
      <tr>
      <td>Excellent/Highly Accepted</td>
      <td>Good/Accepted</td>
      <td>Average/Minimally Accepted</td>
      <td>Needs Improvement</td>
      <td>Unsatisfactory</td>
      </tr>
      <tr>
      <td>A1</td>
      <td>A</td>
      <td>B</td>
      <td>C</td>
      <td>D</td>
      </tr>
      </tbody>
      </Table>

      <Table bordered>
      <thead>
      <tr>
      <th rowSpan={2} className="text-center align-middle" style={{width:"5%"}}>S.No.</th>
      <th rowSpan={2} className="text-center align-middle" style={{width:"40%"}}>Factor</th>
      <th colSpan={5} className="text-center align-middle">Performance</th>
      <th rowSpan={2} className="text-center align-middle">Remarks</th>
      </tr>

      <tr>
      <th className="text-center align-middle">A1</th>
      <th className="text-center align-middle">A</th>
      <th className="text-center align-middle">B</th>
      <th className="text-center align-middle">C</th>
      <th className="text-center align-middle">D</th>
      </tr>
      </thead>
      <tbody>
      <tr><td colSpan={8}>Attitude And Conduct</td></tr>
      <tr>
      <td className="text-center align-middle">01</td>
      <td>Behavior with Faculty/Staff</td>
      <td className="text-center"><Input name="behaviorfaculty" type="radio" /></td>
      <td className="text-center"><Input name="behaviorfaculty" type="radio" /></td>
      <td className="text-center"><Input name="behaviorfaculty" type="radio" /></td>
      <td className="text-center"><Input name="behaviorfaculty" type="radio" /></td>
      <td className="text-center"><Input name="behaviorfaculty" type="radio" /></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">02</td>
      <td>Behavior with Students</td>
      <td className="text-center"><Input name="behavior" type="radio" /></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">03</td>
      <td>Offers assistance willingly</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">04</td>
      <td>Puntuality And Time Management</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">05</td>
      <td>Positive Attitude towards work</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">06</td>
      <td>Regularity And Attendance</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">07</td>
      <td>Self Discipline and Potential for Future Promotion</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">08</td>
      <td>Accepts constructive criticism positively</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">09</td>
      <td>Willingness to learn</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr><td colSpan={8}>Computer Skills</td></tr>
      <tr>
      <td className="text-center align-middle">10</td>
      <td>Office Automation skills (MS-Word, MS-Excel, MS-Powerpoint)</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">11</td>
      <td>Typing Speed and Accuracy</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr><td colSpan={8}>Communication Skills</td></tr>
      <tr>
      <td className="text-center align-middle">12</td>
      <td>English Communication Skills</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">13</td>
      <td>Record keeping and updating of all necessary written information</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">14</td>
      <td>Handling routine information secrecy of departmental Affairs</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">15</td>
      <td>Has the ability to work under pressure</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">16</td>
      <td>Has the ability to recognize deficiencies and seeks help when appropriate</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr><td colSpan={8}>Quality And Quantity Of Work</td></tr>
      <tr>
      <td className="text-center align-middle">17</td>
      <td>Neatness and Accuracy of work under normal conditions</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">18</td>
      <td>Neatness and Accuracy of work under pressure</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">19</td>
      <td>Volume of work produced under normal conditions without any error</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td className="text-center align-middle">20</td>
      <td>Volume of work produced under pressure without any error</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr><td colSpan={8}>Comments: (If any)<Input type="textarea" rows={2} /></td></tr>
     
      </tbody>
      </Table>
      </Col>
      </Row>

      <Row>
      <Col lg="12" md="12" sm="12">
      <h6>Overall Rating</h6>
      <Table bordered className="text-center">
      <tbody>
      <tr>
      <td>Excellent/Highly Accepted</td>
      <td>Good/Accepted</td>
      <td>Average/Minimally Accepted</td>
      <td>Needs Improvement</td>
      <td>Unsatisfactory</td>
      </tr>
      <tr>
      <td>&nbsp;</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      </tbody>
      </Table>
      </Col>
      </Row>
     {/* <Row>
      <Col lg="12" md="12" sm="12" className="text-right">
      <Button color="primary">Save</Button>
      </Col>
        </Row>*/}
      </CardBody>
      </Card>

      
      <Card>
      <CardTitle>Part-III (To be filled by the reporting officer)</CardTitle>
      <CardBody>
      <Row>
      <Col lg="12" md="12" sm="12">
      <Table bordered style={{width:"100%"}}>
      <tbody>
      <tr>
      <td style={{width:"40%"}}>Potential for Development/Promotion</td>
      <td>
      <label>Yes</label>
      <Input  
      name="growth"
      type="radio"/>
      </td>
      <td>
      <label>No</label>
      <Input     
      name="growth"
      type="radio"/>
      </td>
      </tr>
      <tr>
      <td style={{width:"40%"}}>Does he/she accept new assignments willingly</td>
      <td>
      <label>Yes</label>
      <Input  
      name="willingly"
      type="radio"/>
      </td>
      <td>
      <label>No</label>
      <Input     
      name="willingly"
      type="radio"/>
      </td>
      </tr>
      <tr>
      <td style={{width:"40%"}}>Does he/she take initiative and show leadership</td>
      <td>
      <label>Yes</label>
      <Input  
      name="initiative"
      type="radio"/>
      </td>
      <td>
      <label>No</label>
      <Input     
      name="initiative"
      type="radio"/>
      </td>
      </tr>
      <tr>
      <td style={{width:"40%"}}>Is he/she fit for higher level Responsibilities?</td>
      <td>	
      <label>Now</label>
      <Input  
      name="level"
      type="radio"/>
      </td>
      <td>
      <label>Later</label>
      <Input     
      name="level"
      type="radio"/>
      </td>
      </tr>

      <tr>
      <td style={{width:"40%"}}>Does he/she require any additional training?</td>
      <td>
      <label>Yes</label>
      <Input  
      name="require"
      type="radio"/>
      </td>
      <td>
      <label>No</label>
      <Input     
      name="require"
      type="radio"/>
      </td>
      </tr>

      <tr>
      <td style={{width:"40%"}}>If yes, nature and type of training</td>
      <td colSpan={2}>
      <Input
          type="textarea"
          rows={6}
          className="reporting-officer"
      />
      </td>
      
      </tr>
      </tbody>
      </Table>
      </Col>
      </Row>
      {/*<Row>
      <Col lg='12' md='12' xs='12' className="text-right">
        <Button color="primary">Save</Button>
      </Col>
      </Row>*/}
      </CardBody>
      </Card>

      <Card>
      <CardTitle>
      Reporting Officer (HOD)
      </CardTitle>
      <CardBody>
      <Row>
     
      <Col lg='12' md='12' xs='12'>
      <FormGroup>
      <Label>Remarks: (If any)</Label>
      <Input
          type="textarea"
          rows={6}
          className="faculty-textarea"
      />
  </FormGroup>
    </Col>
    </Row>
    <Row>
     <Col lg='3' md='3' xs='12'>
        <FormGroupInput label='Name' name='reportingName' onChange={handleInputChange} value={formFields.reportingName} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <div className='form-group'>
      <label className='form-label'> Date </label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
    </div>   
     </Col>
     <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Status"
                name="statusList"
                list={statusList}
                fieldId="id"
                fieldName="name"
              />
            </Col>
    </Row>
   {/* <Row>
      <Col lg='12' md='12' xs='12' className="text-right">
        <Button color="primary">Save</Button>
      </Col>
    </Row>*/}
      </CardBody>
      </Card>

      <Card>
      <CardTitle>
    Countersigning Authority
      </CardTitle>
      <CardBody>
      <Row>
      <Col lg='12' md='12' xs='12'>
      <FormGroup>
      <label>I agree with the above evaluation</label>
      <Input  
      name="evaluation"
      type="radio" className="me-3"/>
     
      <label>I disagree with the above evaluation</label>
      <Input  
      name="evaluation"
      type="radio"/>
      </FormGroup>
      </Col>
      <Col lg='12' md='12' xs='12'>
      <FormGroup>
      <Label>If disagrees with Reporting Officer, give reasons:</Label>
      <Input
          type="textarea"
          rows={6}
          className="faculty-textarea"
      />
  </FormGroup>
    </Col>
    </Row>
    <Row>
      <Col lg='12' md='12' xs='12' className="text-right">
        <Button color="primary">Save</Button>
      </Col>
    </Row>
      </CardBody>
      </Card>
    </Container>
  );
};

export default ACRBPS5To15