import React, { Fragment } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";

const ResearchTopic = () => {
  return (
    <Fragment>
      <form
      //   onSubmit={onUpdate}
      >
        <Card className="mt-3">
          <CardTitle>Research Topic Details and Supervisor</CardTitle>
          <CardBody>
            <Row>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Name of Scholar"
                  name="Name"
                //   value={formFields?.Name}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Subject/Field of Study"
                  name="FatherName"
                //   value={formFields?.FatherName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Title/Topic of research"
                  name="SurName"
                //   value={formFields?.SurName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardTitle>Research Topic Details and Supervisor</CardTitle>
          <CardBody>
            <Row>
              <Col md="4" lg="4">
                <FormGroupInput
                  label="Name of Scholar"
                  name="Name"
                //   value={formFields?.Name}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                  disabled
                />
              </Col>
              <Col md="4" lg="4">
                <FormGroupInput
                  label="Subject/Field of Study"
                  name="FatherName"
                //   value={formFields?.FatherName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                  required
                />
              </Col>
              <Col md="4" lg="4">
                <FormGroupInput
                  label="Title/Topic of research"
                  name="SurName"
                //   value={formFields?.SurName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardTitle>Available Facilities</CardTitle>
          <CardBody>
            <Row>
              <Col md="4" lg="4">
                <FormGroupInput
                  label="Title/Topic of research"
                  name="SurName"
                //   value={formFields?.SurName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                />
              </Col>
              
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
        <CardTitle>Research Co-Supervisor List</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                // columns={columns}
                // rows={academicData}
                // onEdit={onEdit}
                // onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Add
                    {/* {editValue === true ? "Edit" : "Add"} */}
                  </Button>
                  <Button type="button" 
                //   onClick={onCancel}
                   color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
        </CardBody>
      </Card>
        <Card className="mt-3">
          <CardTitle>Research Supervisor Details</CardTitle>
          <CardBody>
            <Row>
              <Col md="3" lg="3">
                <FormGroupInput
                  label="Name"
                  name="Name"
                //   value={formFields?.Name}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                  disabled
                />
              </Col>
              <Col md="9" lg="9">
                <FormGroupInput
                  label="Address"
                  name="FatherName"
                //   value={formFields?.FatherName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                  required
                />
              </Col>
              <Col md="12" lg="12">
                <FormGroupInput
                  label="Signifying his willingness to undertake the guidance"
                  name="SurName"
                //   value={formFields?.SurName}
                //   onChange={handleInputChange}
                  isAlphabetic="true"
                />
              </Col>
            </Row>
            <Row>
            <Col>
              <div className="text-right">
                <Button
                  color="primary"
                //   onClick={onUpdate}
                //   disabled={statusData[0]?.Status == 1 ? true : false}
                >
                  {/* {submitLoad ? <Spinner color="light" size="sm" /> : null} */}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
          </CardBody>
        </Card>
      </form>
    </Fragment>
  );
};

export default ResearchTopic;
