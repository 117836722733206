import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ResetPasswordModal from "../pages/ResetPasswordModal";
import {
  RESET_FORM_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../redux/actionType/CrudActionTypes";
import FormGroupInput from "../components/GeneralComponent/FormGroupInput";
import { PostRequest, SecuritySetup_ForgetCredentials, SecuritySetup_ResetPassword, Setup_Dashboard } from "../utils/Config";
import Swal from "sweetalert2";
import { PasswordStrength } from "../utils/Constants";
import { Insert, SessionStorage } from "../common/SetupMasterEnum";
import { RESETPASSWORD } from "../utils/EncryptedConstants";
import { decryptData } from "../EncryptData";
import { SETUP_DASHBOARD } from "../utils/UrlConstants";

const initialFormFields = {
  Cnic: "",
  LoginId: "",
  OperationId: Insert,
};

const Dashboard = (props) => {
  const { FormFields, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const [dashboardList, setDashboardList] = useState([]);
  const [openTogel, setOpenToggle] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    let data = {
      name: "ResetPassword",
      value: decryptData(RESETPASSWORD, SessionStorage),
    };
    dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: data });
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: initialFormFields });
    getDashboardData();
  }, []);
  const getDashboardData = () => {
    Setup_Dashboard()
    // PostRequest(SETUP_DASHBOARD)
      .then((res) => {
        setDashboardList(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleInputChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const handleSubmitResetPassword = (e) => {
    e.preventDefault();
    SecuritySetup_ForgetCredentials(FormFields)
    .then((res) => {
          if (res?.data?.Table[0]?.Column1 == 2) {
            dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
            setOpenToggle(false);
            Swal.fire({
              title: "Success",
              icon: "success",
              text: res?.data?.Table[0]?.Column2,
            });
          } else {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: res?.data?.Table[0]?.Column2,
            });
          }
        });

  };

  return (
    <Container fluid className="pt-2">
      {SupportingTables?.ResetPassword == "0" ? (
        <Modal
          isOpen={openTogel}
          centered
          // toggle={toggle}
          modalTransition={{ timeout: 10 }}
          backdrop="static"
        >
          <ModalHeader
          // toggle={toggle}
          >
            Reset Password
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmitResetPassword}>
              <Row>
                {/* <Col lg="12" md="12" xs="12"></Col>
                <Col lg="12" md="12" xs="12">
                  <FormGroupInput
                    className="row"
                    label="Old Password"
                    name="OldPass"
                    required={true}
                    onChange={handleInputChange}
                    value={FormFields?.OldPass}
                    type="password"
                  />
                </Col>
                <Col lg="12" md="12" xs="12">
                  <FormGroupInput
                    label="New Password"
                    name="NewPass"
                    required={true}
                    onChange={handleInputChange}
                    value={FormFields?.NewPass}
                    type="password"
                  />
                </Col> */}
                <Col lg="12" md="12" xs="12">
                <FormGroupInput
                  type="email"
                    label="Login ID"
                    name="LoginId"
                    required
                    onChange={handleInputChange}
                    value={FormFields?.LoginId}
                  />
                  {/* <FormGroupInput
                    label="Confirm New Password"
                    name="ConfirmPass"
                    required={true}
                    onChange={handleInputChange}
                    value={FormFields?.ConfirmPass}
                    type="password"
                  /> */}
                </Col>
                <Col lg="12" md="12" xs="12">
                  <Button color="primary" className="mt-2">
                    Change Password
                  </Button>
                  <Button
                    color="primary"
                    className="mt-2"
                    onClick={() => setOpenToggle(false)}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
      ) : null}
      {dashboardList.length > 0 ?
      <Row>
        {dashboardList?.map((item, ind) => {
          return (
            <Col lg="3" md="4" key={ind}>
              <Card className={`card-s${ind + 1} card-hover`} style={{border:"1px solid rgba(0,0,0,.06)",textAlign:"center", boxShadow:"unset", backgroundColor:"#f7f7f7", padding:"14px 0px"}}> 
                <CardBody>
                  <p className="card-number mb-0 pb-0">{item.VALUE_}</p>
                  <p className="card-total mb-0">{item.NAME_}</p>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
      : <h2 className="text-center">No Data Found</h2>
}
    </Container>
  );
};

export default Dashboard;
