import React, { Fragment, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import logoimg from "../../../../assets/img/logo.png";

const AcademicSemesterResultReport = forwardRef((props, ref) => {
  const { TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );

  return (
    <>
      <div
        ref={ref}
        className="text-center"
        style={{
          fontSize: 10,
          margin: "10px 10px 10px 10px",
          padding: "10px 10px",
        }}
      >
        
          <Fragment>
              <h5>The Gazatte</h5>
              {/* {SupportingTables?.SearchDepartmentStudent?.map((x, ind) => {}) */}
              <p>RESULT OF {SupportingTables?.SearchDepartmentStudent?.[0].Part}</p>
              <h6>
                <u>ACADEMIC SESSION : {SupportingTables?.SearchDepartmentStudent?.[0].AcademicYear} </u>
              </h6>
              <br />
              <p>
                <b>
                  <u>DEPARTMENT OF {SupportingTables?.SearchDepartmentStudent?.[0].FacultyDepartmentProgram}</u>
                </b>
              </p>
              <br />
              <p>
                <b>
                  <u>CLASS: {SupportingTables?.SearchDepartmentStudent?.[0].FacultyDepartmentProgram} {SupportingTables?.SearchDepartmentStudent?.[0].Part}</u>
                </b>
              </p>
              <br />
              <p>
                [A] CANDIDATES HAVING THE FOLLOWING SEAT/ROLL NUMBERS ARE
                DECLARED SUCCESSFUL.
              </p>
              <br />
              <table className="d-flex text-center justify-content-center">
                <tbody>
                  <tr >
                {SupportingTables?.SearchDepartmentStudent?.filter(i => i.status === "Passed")?.map((x, ind) =>(
                    <td key={ind} className="tbl-row-border">{x.Reg_No}</td>
                ))} 
                  </tr>

                </tbody>
              </table>
              <br />
              <p>
                [B] CANDIDATES HAVING THE FOLLOWING SEAT/ROLL NUMBERS ARE
                DECLARED FAIL.
              </p>
              <br />
              <table className="d-flex text-center justify-content-center">
                <tbody>
                <tr >
                {SupportingTables?.SearchDepartmentStudent?.filter(i => i.status === "Failed")?.map((x, ind) =>(
                    <td key={ind} className="tbl-row-border">{x.Reg_No}</td>
                ))} 
                  </tr>
                 
                </tbody>
              </table>
         <br />
              <div className="text-left">
                <p>
                  <b>
                    <u>N.B</u>
                  </b>
                </p>
                <p>
                  The University reserves the right to correct, modify or change
                  the result if it is found to have been wrongly computed or
                  compiled. The typological errors if any will not entitle
                  anybody to interpret the result in his/her favour and to claim
                  any advantage there from. The university therefore further
                  reserves the right to correct such mistakes as and when they
                  come to notice. No action will against the university
                </p>
                <p>
                  <b>NOTE:-</b>
                </p>
                <p>
                  The marks certificates will be sent to the department
                  concerned within due course of time
                </p>
              </div>
              <br />
              <br />
              <p className="text-left">Date of Announcement</p>
              <div className="d-flex justify-content-between">
                <p className="text-left">January 23, 2023</p>
                <p className="text-right" id="bold">
                  CONTROLLER OF EXAMINATIONS
                </p>
              </div>
            </Fragment>
          
      </div>
    </>
  );
});

export default AcademicSemesterResultReport;
