import React, { forwardRef } from "react";
import logoimg from "../../../../assets/img/logo.png";

const RemunerationAssignmentBillReport = forwardRef((props, ref) => {
  return (
    <>
      <div
        ref={ref}
        className="text-center"
        style={{
          fontSize: 11,
          margin: "20px",
          padding: "10px 10px",
        }}
      >
        <div>
          <h4 className="text-center mb-0">
            <img src={logoimg} height="65" />
            <u>Sheikh Ayaz University Shikarpur</u>
          </h4>
        </div>
        <p>
          DETAIL OF CLAIM SUBMITTED BY MR, BAKHTIAR AHMED KUBAR, ASSISTANT
          PROFESSOR, DEPARTMENT OF BASIC SCIENCE & RATED STUDIES
        </p>
        <p> ACADEMIC SESSION , 2nd TERM 2022</p>
        <div
          className="d-flex justify-content-center"
          style={{ width: "100%" }}
        >
          <table style={{ width: "100%" }}>
            <thead className="tbl-row-bgmr">
              <tr>
                <th className="tbl-row-border">S.No</th>
                <th className="tbl-row-border" colSpan={2}>
                  RATE (Rs).
                </th>
                <th className="tbl-row-border">CLASS</th>
                <th className="tbl-row-border">DEPTT.</th>
                <th className="tbl-row-border">SESSION</th>
                <th className="tbl-row-border">PART</th>
                <th className="tbl-row-border" colSpan={2}>
                  SUBJECT
                </th>
                <th className="tbl-row-border">NO. OF STD.</th>
                <th className="tbl-row-border">Fresh/ Imp/ Failure</th>
                <th className="tbl-row-border">Project RS. 300/=</th>
                <th className="tbl-row-border">AMOUNT</th>
                <th className="tbl-row-border">V.VOCE RS.80/= </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tbl-row-border">1</td>
                <td className="tbl-row-border" rowSpan={5} colSpan={2}>
                  Checking of Scripts 130 Per Candidate
                </td>
                <td className="tbl-row-border">BBA</td>
                <td className="tbl-row-border">Business Administration</td>
                <td className="tbl-row-border">2022</td>
                <td className="tbl-row-border">I</td>
                <td className="tbl-row-border" colSpan={2}>
                  Pakistan Studies
                </td>
                <td className="tbl-row-border">1</td>
                <td className="tbl-row-border">Imp/Failure</td>
                <td className="tbl-row-border" rowSpan={5}></td>
                <td className="tbl-row-border">130</td>
                <td className="tbl-row-border">80</td>
              </tr>

              <tr>
                <td className="tbl-row-border">2</td>

                <td className="tbl-row-border">COMM</td>
                <td className="tbl-row-border">Commerce</td>
                <td className="tbl-row-border">2022</td>
                <td className="tbl-row-border">I</td>
                <td className="tbl-row-border" colSpan={2}>
                  Pakistan Studies
                </td>
                <td className="tbl-row-border">5</td>
                <td className="tbl-row-border">Fresh</td>
                <td className="tbl-row-border">1500</td>
                <td className="tbl-row-border"></td>
              </tr>

              <tr>
                <td className="tbl-row-border">3</td>

                <td className="tbl-row-border">COMM</td>
                <td className="tbl-row-border">Commerce</td>
                <td className="tbl-row-border">2022</td>
                <td className="tbl-row-border">I</td>
                <td className="tbl-row-border" colSpan={2}>
                  Pakistan Studies
                </td>
                <td className="tbl-row-border">1</td>
                <td className="tbl-row-border">Imp/Failure</td>
                <td className="tbl-row-border">130</td>
                <td className="tbl-row-border">80</td>
              </tr>

              <tr>
                <td className="tbl-row-border">4</td>

                <td className="tbl-row-border">B-ED-(H)</td>
                <td className="tbl-row-border">Teacher Education</td>
                <td className="tbl-row-border">2022</td>
                <td className="tbl-row-border">I</td>
                <td className="tbl-row-border" colSpan={2}>
                  Pakistan Studies
                </td>
                <td className="tbl-row-border">20</td>
                <td className="tbl-row-border">Fresh</td>
                <td className="tbl-row-border">2600</td>
                <td className="tbl-row-border"></td>
              </tr>

              <tr>
                <td className="tbl-row-border">5</td>

                <td className="tbl-row-border">B-ED-(H)</td>
                <td className="tbl-row-border">Teacher Education</td>
                <td className="tbl-row-border">2022</td>
                <td className="tbl-row-border">I</td>
                <td className="tbl-row-border" colSpan={2}>
                  Pakistan Studies
                </td>
                <td className="tbl-row-border">2</td>
                <td className="tbl-row-border">Imp/ Failure</td>
                <td className="tbl-row-border">260</td>
                <td className="tbl-row-border">160</td>
              </tr>
              <tr>
                <td colSpan={12} className="tbl-row-border"></td>
                <td className="tbl-row-border bolder">4620</td>
                <td className="tbl-row-border bolder">320</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-start">
          <table style={{ width: "92%" }}>
            <thead className="tbl-row-bgmr">
              <tr>
                <th className="tbl-row-border" colSpan={1}>
                  S.No
                </th>
                <th className="tbl-row-border" colSpan={1}>
                  RATE (Rs).
                </th>
                <th className="tbl-row-border" colSpan={2}>
                  CLASS
                </th>
                <th className="tbl-row-border" colSpan={3}>
                  Paper Setting
                </th>
                <th className="tbl-row-border" colSpan={2}>
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tbl-row-border" colSpan={1}>
                  1
                </td>
                <td className="tbl-row-border" colSpan={1}>
                  1200 per Paper
                </td>
                <td className="tbl-row-border" colSpan={2}></td>
                <td className="tbl-row-border" colSpan={3}></td>
                <td className="tbl-row-border bolder" colSpan={2}>
                  6000
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-start">
          <table style={{ width: "92%" }}>
            <thead className="tbl-row-bgmr">
              <tr>
                <th className="tbl-row-border" colSpan={2}>
                  S.No
                </th>
                <th className="tbl-row-border" colSpan={2}>
                  RATE (Rs).
                </th>
                <th className="tbl-row-border" colSpan={8}>
                  Invigilator
                </th>
                <th className="tbl-row-border" colSpan={1}>
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tbl-row-border" colSpan={2}>
                  1
                </td>
                <td className="tbl-row-border" colSpan={2}>
                  Per duty 600
                </td>
                <td className="tbl-row-border" colSpan={8}></td>
                <td className="tbl-row-border bolder" colSpan={1}>
                  3000
                </td>
              </tr>
              <tr>
                <td className="tbl-row-border" colSpan={10}></td>
                <td className="tbl-row-border bolder" colSpan={2}>
                  Grand Total
                </td>
                <td className="tbl-row-border bolder">13940</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{ marginTop: "300px" }}
          className="text-center d-flex justify-content-between"
        >
          <p>Auditor</p>
          <p>Controller</p>
          <p>HOD Signature</p>
          <p>Signature of Teacher</p>
        </div>
      </div>
    </>
  );
});
export default RemunerationAssignmentBillReport;
