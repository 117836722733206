import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { admissionProgramId } from "../../common/SetupMasterEnum";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../utils/Config";
import { SETUP_MASTER_DETAIL_ALL_DROPDOWN } from "../../utils/UrlConstants";

const initialFormFields = {
  Programs: 0,
};

const DocumentCheckList = () => {
  const columns = [
    { field: "Documents", name: "Documents" },
    { field: "NoOfCopies", name: "No of Copies" },
  ];
  const rows = [
    {
      Documents: "Printed Copy of Admission Form",
      NoOfCopies: "1",
    },
    {
      Documents: "Paid Admission Fee Challan (For candidates paying fees physically)",
      NoOfCopies: "1",
    },
    {
      Documents:
        "Attested recent Photographs (2 Passport size and 1 having dimension 1'x 1. ½”)",
      NoOfCopies: "3",
    },
    {
      Documents:
        "Attested Copy of Pass/Marks Certificate of the last Qualifying Examination",
      NoOfCopies: "1",
    },
    {
      Documents:
        "Attested Copy of Transfer Certificate from Principal of the College /Institute last attended ",
      NoOfCopies: "1",
    },
    {
      Documents:
        "Attested Copy of Character Certificate from Principal of the College /Institute last attended ",
      NoOfCopies: "1",
    },
    {
      Documents:
        "Copy of Migration Certificate (Board other than Sukkur & Larkana)",
      NoOfCopies: "1",
    },
    {
      Documents:
        "Copy of Eligibility Certificate (Board other than Sukkur & Larkana)",
      NoOfCopies: "1",
    },
    {
      Documents: "Affidavit / Undertaking as prescribed",
      NoOfCopies: "1",
    },
   
    {
      Documents:
        "Admission Fees Challan of PKR 2,000 to be deposited in HBL Branch or Online through HBL Connect",
      NoOfCopies: "1",
    },
  ];

  const rowsPostGraduate = [
    {
      Documents:
        "Attested copies (Pass/Transcripts) of all the cretificates & degrees/CNIC/Domiciles",
      NoOfCopies: "1",
    },
    {
      Documents: "Passport size fresh Photographs (Attested)",
      NoOfCopies: "3",
    },
    {
      Documents:
        "NOC/Permission Letter/Study Leave in case of employees where applicable",
      NoOfCopies: "1",
    },
    {
      Documents:
        "Eligibility Certificate if your last degree is other than this University",
      NoOfCopies: "1",
    },
  ];

  const [formFields, setFormFields] = useState(initialFormFields);
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    getMasterDetailAllDropdown();
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };

    PostRequest(SETUP_MASTER_DETAIL_ALL_DROPDOWN, payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res?.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleInputChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>Checklist</CardTitle>
        <CardBody>
          <Row>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Program"
                name="Programs"
                list={SupportingTables?.tables?.filter(
                  (x) => x.SetupMasterId == admissionProgramId
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={formFields.Programs}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Document Checklist</CardTitle>
        <CardBody>
          <Row>
            <Col md="12" lg="12">
              {formFields.Programs === 3479 || formFields.Programs === 3480 ? (
                <h6 className="text-center mb-3">
                  You must submit the following list of documents to the
                  Admission Office before the deadline day so that your
                  application can be approved.
                </h6>
              ) : (
                <h6 className="text-center mb-3">Please Select Program</h6>
              )}
            </Col>
            {formFields.Programs === 3479 ? (
              <Col md="12" lg="12">
                <FormGroupTable
                  className
                  columns={columns}
                  rows={rows}
                  hideAction={true}
                  hideSerialNumber={true}
                />
              </Col>
            ) : null}
            {formFields.Programs === 3480 ? (
              <Col md="12" lg="12">
                <FormGroupTable
                  className
                  columns={columns}
                  rows={rowsPostGraduate}
                  hideAction={true}
                  hideSerialNumber={true}
                />
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default DocumentCheckList;
