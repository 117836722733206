import React, { Fragment, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import {
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { PostFormRequest } from "../../../utils/Config";
import { STUDENT_LOGIN_OPS } from "../../../utils/UrlConstants";
import AcademicsChallan from "../Reports/StudentReports/AcademicsChallan";

const StudentStatus = (props) => {
  const initialFormFields = {
    OperationId: Select,
    StudentDetailId: decryptData("EmplId", SessionStorage),
    BPVoucherId: 0,
    Remarks: "",
    DocumentPath: "",
    UserId: 0,
    PageNumber: 1,
    RowsOfPage: 10,
    CampusTypeID: 0,
    CampusCityID: 0,
    FacultyDepartmentID: 0,
    FacultyDepartmentProgramId: 0,
    FeeStatusID: 0,
  };

  const candidateStatusFields = {
    Reg_No: "",
    Major: "",
    AdmissionFacultyDepartment: "",
    FacultyDepartmentProgram: "",
    Name: "",
    FatherName: "",
    Gender: "",
    Cnic: "",
    PostalAddress: "",
    CorrespondatAddress: "",
    Mobile: "",
    Email: "",
    Nationality: "",
    BPVoucherId: 0,
    DocumentPath: "",
    ActiveVoucherExist: 0,
  };

  const [formFields, setFormFields] = useState(initialFormFields);
  const [studentStatus, setStudentStatus] = useState(candidateStatusFields);
  const [uploadFeeChallanButton, setUploadFeeChallanButton] = useState(true);
  const challanForAdmission = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    onLoadFucntion();
  }, []);

  const onLoadFucntion = () => {
    let formData = new FormData();
    Object.keys(initialFormFields).forEach((d) => {
      formData.append(d, initialFormFields[d]);
    });
    getStudentStatusRecord(formData, "onLoad");
  };

  const getStudentStatusRecord = (formData, type) => {
    PostFormRequest(STUDENT_LOGIN_OPS, formData)
      .then((res) => {
        if (type === "onLoad") {
          let studentVoucher = {
            name: "studentVoucher",
            value: res?.data?.Table1?.[0],
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: studentVoucher,
          });

          let studentParticularDetail = {
            name: "studentParticularDetail",
            value: res?.data?.Table2,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: studentParticularDetail,
          });
          setFormFields({
            ...formFields,
            BPVoucherId: res?.data?.Table1?.[0]?.BPVoucherId,
          });
          setStudentStatus({
            ...studentStatus,
            Reg_No: res.data.Table?.[0]?.Reg_No,
            Major: res.data.Table?.[0]?.Major,
            AdmissionFacultyDepartment:
              res.data.Table?.[0]?.AdmissionFacultyDepartment,
            FacultyDepartmentProgram:
              res.data.Table?.[0]?.FacultyDepartmentProgram,
            Name: res.data.Table?.[0]?.Name,
            FatherName: res.data.Table?.[0]?.FatherName,
            Gender: res.data.Table?.[0]?.Gender,
            Cnic: res.data.Table?.[0]?.Cnic,
            PostalAddress: res.data.Table?.[0]?.PostalAddress,
            CorrespondatAddress: res.data.Table?.[0]?.CorrespondatAddress,
            Mobile: res.data.Table?.[0]?.Mobile,
            Email: res.data.Table?.[0]?.Email,
            Nationality: res.data.Table?.[0]?.Nationality,
            BPVoucherId: res?.data?.Table1?.[0]?.BPVoucherId,
            DocumentPath: res?.data?.Table?.[0]?.DocumentPath,
            ActiveVoucherExist: res?.data?.Table?.[0]?.ActiveVoucherExist,
          });
        } else {
          if (res?.data?.Table?.[0]?.HasError === 0) {
            CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
            document.getElementById("documentPath").value = [];
            setUploadFeeChallanButton(true);
            onLoadFucntion();
          } else {
            CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleImageUpload = (event) => {
    setFormFields({
      ...formFields,
      DocumentPath: event.target.files[0],
    });
    if (Object.keys(event.target.files[0]) !== []) {
      setUploadFeeChallanButton(false);
    }
  };

  const uploadChallan = () => {
    document.getElementById("documentPath").value = [];
    formFields.OperationId = Insert;
    let formData = new FormData();
    Object.keys(formFields).forEach((d) => {
      formData.append(`${d}`, formFields[d]);
    });
    getStudentStatusRecord(formData, "ImageUploaded");
  };

  const handlePrintChallanForAcademics = useReactToPrint({
    content: () => challanForAdmission.current,
  });

  return (
    <Container fluid>
      <Card>
        <CardTitle>
          <Row className="adjustTextToCentervertically">
            <Col lg="10" md="6" xs="6">
              Candidate Status
            </Col>
            <Col lg="2" md="6" xs="6">
              <div className="move-right">
                <Button
                  className={
                    studentStatus?.ActiveVoucherExist === 1 ? "blink_me" : ""
                  }
                  disabled={
                    studentStatus?.ActiveVoucherExist === 0 ? true : false
                  }
                  onClick={handlePrintChallanForAcademics}
                >
                  Print Active Challan
                </Button>
              </div>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table bordered striped responsive>
            <tbody>
              <tr>
                <td>Registration No</td>
                <td>{studentStatus?.Reg_No}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{studentStatus?.Name}</td>
              </tr>
              <tr>
                <td>Father Name</td>
                <td>{studentStatus?.FatherName}</td>
              </tr>
              <tr>
                <td>Gender</td>

                <td>{studentStatus?.Gender}</td>
              </tr>
              <tr>
                <td>Faculty Department Program</td>
                <td>{studentStatus?.FacultyDepartmentProgram}</td>
              </tr>
              <tr>
                <td>Cnic</td>
                <td>{studentStatus?.Cnic}</td>
              </tr>

              <tr>
                <td>Email</td>
                <td>{studentStatus?.Email}</td>
              </tr>
              <tr>
                <td>Mobile</td>
                <td>{studentStatus?.Mobile}</td>
              </tr>
              {/* <tr>
                <td>Upload scan photo of paid fee challan</td>
                <td>
                  <div>
                    <Input
                      className="mt-1 mb-1"
                      type="file"
                      id="documentPath"
                      accept="image/*"
                      color="default"
                      name="FileName"
                      onChange={handleImageUpload}
                      // disabled={
                      //   studentStatus?.BPVoucherId === undefined ? true : false
                      // }
                    />
                    <Button
                      disabled={uploadFeeChallanButton}
                      className="mt-1  mb-1"
                      onClick={uploadChallan}
                    >
                      Upload Fee Challan
                    </Button>
                  </div>
                </td>
              </tr> */}
              <tr>
                <td>View Uploaded Fee Challan</td>
                <td>
                  <a
                    href={
                      studentStatus?.DocumentPath === undefined
                        ? null
                        : studentStatus?.DocumentPath
                    }
                    target="_blank"
                    className="btn btnic btn-secondary"
                  >
                    <i className="fa fa-eye" color="primary"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <div style={{ display: "none" }}>
        <AcademicsChallan ref={challanForAdmission} />
      </div>
    </Container>
  );
};

export default StudentStatus;
