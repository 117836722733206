import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "../layout/AdminLayout";
import Login from "../pages/Login";
import { isLogin, logout } from "../utils";
import PrivateRoute from "./PrivateRoutes";
import NoDataFound from "../pages/NoDataFound";
import ForgotUsername from "../pages/ForgotUsername";
import ForgotPassword from "../pages/ForgotPassword";
import NewUserRegistration from "../pages/NewUserRegistration";
import NewUserLogin from "../pages/NewUserLogin";
import CandidateForgotPassword from "../pages/CandidateForgotPassword";
import AdmitCard from "../pages/Admissions/AdmitCard";
import ReceiptForm from "../pages/Admissions/ReceiptForm";
import { SessionStorage } from "../common/SetupMasterEnum";
import { LOGIN_TYPE } from "../utils/EncryptedConstants";
import { decryptData } from "../EncryptData";

const Redirection = () => {
  let loginType = decryptData(LOGIN_TYPE, SessionStorage);
  if (loginType !== undefined) {
    if (loginType === "1") {
      logout();
      return <Redirect to="/login" />;
    }
    // Open this when you are deploying on SAUS_REACT
    else {
      logout();
      return <Redirect to="/admissionlogin" />;
    }
  } else {
    logout();

    // Open this when you are deploying on SALU_ACADEMICS
    // return <Redirect to="/login" />;

    //Open this when you are deploying on SAUS_REACT
    return <Redirect to="/admissionlogin" />;
  }
};

export default function MainRoutes() {
  return (
    <>
      <Switch>
        <Route
          path="/pages"
          render={(props) =>
            isLogin() ? <AdminLayout {...props} /> : <Redirection />
          }
        />
        {/* Open this when you are deploying on SAUS_ACADEMICS */}
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/forgotusername" component={ForgotUsername}></Route>
        <Route exact path="/forgotpassword" component={ForgotPassword}></Route>

        {/* Open this when you are deploying on SAUS_REACT */}
        <Route exact path="/admissionlogin" component={NewUserLogin}></Route>
        <Route
          exact
          path="/candidateforgotpassword"
          component={CandidateForgotPassword}
        ></Route>
        <Route
          exact
          path="/admissionregistration"
          component={NewUserRegistration}
        ></Route>
        {/* <Route exact path="/receiptform" component={ReceiptForm} ></Route> */}
        <PrivateRoute exact component={NoDataFound} />
      </Switch>
    </>
  );
}
