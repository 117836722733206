import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Delete,
  Search,
  Select,
  SessionStorage,
  campusCity,
} from "../../../common/SetupMasterEnum";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  RESET_FORM_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import {
  ACAD_DEPARTMENTCOMMITTEEMEMBERS,
  ACAD_SETUPCOMMITEE,
  SETUPFACULTYDEPARTMENTPROGRAMLECTURER,
  SETUP_ACADEMIC_YEAR,
} from "../../../utils/UrlConstants";
import { PostRequest } from "../../../utils/Config";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { Fragment } from "react";
import { Col, Label } from "reactstrap";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import { getUserIPInfo } from "../../../functions/generalFunctions";
import ReactSelect from "react-select";

const DepartmentCommitteeMember = () => {
  const initialFormFields = {
    operationId: Select,
    departmentCommitteeMembersID: 0,
    committeeID: 0,
    facultyDepartmentProgramID: 0,
    setupFacultyDepartmentProgramLecturerID: 0,
    isActive: true,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialSearchFields = {
    operationId: Select,
    departmentCommitteeMembersID: 0,
    committeeID: 0,
    facultyDepartmentProgramID: 0,
    setupFacultyDepartmentProgramLecturerID: 0,
    isActive: true,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialCommitteeFields = {
    operationId: Select,
    setupCommitteeID: 0,
    employeeID: decryptData("EmplId", SessionStorage),
    academicyearID: 0,
    campusCityID: campusCity,
    facultyDepartmentProgramID: 0,
    committeeName: "",
    isActive: true,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const initialFacultyDepartmentProgramLecturer = {
    OperationID: Search,
    SetupFacultyDepartmentProgramLecturerID: 0,
    CampusID: 0,
    CampusCityID: 0,
    FacultyDepartmentID: 0,
    FacultyDepartmentProgramID: 0,
    EmployeeID: 0,
    IsActive: true,
  };

  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [departmentList, setDepartmentList] = useState();

  const [committees, setCommittees] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const [
    setupFacultyDepartmentProgramLecturer,
    setSetupFacultyDepartmentProgramLecturer,
  ] = useState([]);

  useEffect(() => {
    getDepartmentCommitteeMembers();
    getSetupCommitee();
    getSetupFacultyDepartmentProgramLecturer();
  }, []);

  function getSetupCommitee() {
    PostRequest(ACAD_SETUPCOMMITEE, initialCommitteeFields)
      .then((res) => {
        setCommittees(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getDepartmentCommitteeMembers() {
    PostRequest(ACAD_DEPARTMENTCOMMITTEEMEMBERS, initialFormFields)
      .then((res) => {
        console.log(res?.data);
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table?.map((x) => ({
              ...x,
              IsActive: x.IsActive === true ? "Active" : "In Active",
            })),
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        setDepartmentList(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getSetupFacultyDepartmentProgramLecturer() {
    const payload = {
      ...initialFacultyDepartmentProgramLecturer,
      ...getUserIPInfo(),
    };
    PostRequest(SETUPFACULTYDEPARTMENTPROGRAMLECTURER, payload)
      .then((res) => {
        let data = res?.data?.Table?.map((x) => ({
          ...x,
          label: x.Column1,
          value: x.SetupFacultyDepartmentProgramLecturerID,
        }));
        setSetupFacultyDepartmentProgramLecturer(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "Name", name: "Name" },
    { field: "CommitteeName", name: "Committee Name" },
    { field: "IsActive", name: "Active" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Committee"
          name="committeeID"
          list={committees}
          fieldName="CommitteeName"
          fieldId="SetupCommitteeID"
          onChange={handleSearchChange}
          value={SearchFields?.committeeID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="facultyDepartmentProgramID"
          list={departmentList}
          fieldName="Dept"
          fieldId="DeptID"
          onChange={handleSearchChange}
          value={SearchFields?.facultyDepartmentProgramID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="isActive"
          onChange={handleSearchChange}
          value={SearchFields?.isActive}
        />
      </Col>
    </Fragment>
  );

  const handleInputChangeSelect = (event) => {
    console.log(event);
    setSelectedOption(event);
    let data = {
      name: "setupFacultyDepartmentProgramLecturerID",
      value: event?.value,
    };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Committee"
          name="committeeID"
          list={committees}
          fieldName="CommitteeName"
          fieldId="SetupCommitteeID"
          onChange={handleAddChange}
          value={FormFields?.committeeID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="facultyDepartmentProgramID"
          list={departmentList}
          fieldName="Dept"
          fieldId="DeptID"
          onChange={handleAddChange}
          value={FormFields?.facultyDepartmentProgramID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <Label>Candidate Name</Label>
        <ReactSelect
          closeMenuOnSelect={true}
          onChange={handleInputChangeSelect}
          options={setupFacultyDepartmentProgramLecturer}
          value={selectedOption}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="isActive"
          onChange={handleAddChange}
          value={FormFields?.isActive}
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    PostRequest(ACAD_DEPARTMENTCOMMITTEEMEMBERS, SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table?.map((x) => ({
              ...x,
              IsActive: x.IsActive === true ? "Active" : "In Active",
            })),
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
        setDepartmentList(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
  };

  const handleCancel = () => {
    dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
    setSelectedOption(null);
  };

  const submitForm = (id) => {
    const payload = {
      operationId: id,
      departmentCommitteeMembersID: FormFields?.departmentCommitteeMembersID,
      committeeID: FormFields?.committeeID,
      facultyDepartmentProgramID: FormFields?.facultyDepartmentProgramID,
      setupFacultyDepartmentProgramLecturerID:
        FormFields?.setupFacultyDepartmentProgramLecturerID,
      isActive: FormFields?.isActive,
      userIP: decryptData(UserNetworkInfo)?.IPv4,
      userID: decryptData("loginId", SessionStorage),
    };
    PostRequest(ACAD_DEPARTMENTCOMMITTEEMEMBERS, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        setSelectedOption(null);
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getDepartmentCommitteeMembers();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    console.log(obj);
    const data = {
      departmentCommitteeMembersID: obj?.DepartmentCommitteeMembersID,
      committeeID: obj?.CommitteeID,
      setupFacultyDepartmentProgramLecturerID:
        obj?.SetupFacultyDepartmentProgramLecturerID,
      facultyDepartmentProgramID: obj?.FacDeptID,
      isActive: obj?.IsActive == "Active" ? true : false,
    };
    let lecturer = {
      label: obj?.Name,
      value: obj?.SetupFacultyDepartmentProgramLecturerID,
    };
    setSelectedOption(lecturer);
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    console.log(obj);
    const payload = {
      operationId: Delete,
      departmentCommitteeMembersID: obj?.DepartmentCommitteeMembersID,
      committeeID: obj?.CommitteeID,
      setupFacultyDepartmentProgramLecturerID:
        obj?.SetupFacultyDepartmentProgramLecturerID,
      facultyDepartmentProgramID: obj?.facultyDepartmentProgramID,
      isActive: false,
      userIP: decryptData(UserNetworkInfo)?.IPv4,
      UserId: decryptData("loginId", SessionStorage),
    };
    PostRequest(ACAD_DEPARTMENTCOMMITTEEMEMBERS, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getDepartmentCommitteeMembers();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CrudFormComponent
      formName="Department Commitee Member"
      modalSize="lg"
      buttonName="Add"
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      searchPanel={searchPanel}
      formPanel={formPanel}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      tableColumns={columns}
      tableRows={TableList}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default DepartmentCommitteeMember;
