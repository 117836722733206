import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../common/dateFormatEnum";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";

const initialFormFields = {
  ExaminationId: 0,
};
const OfficeUseOnly = () => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const [formFields, setFormFields] = useState(initialFormFields);
  const [date, setDate] = useState("");
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const AllDateSet = (event, type) => {
    if (type === "Date") {
      setDate(event);
    }
  };

  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Department Pre-Admission Test Result</CardTitle>
        <CardBody>
          <Row>
            <Col lg="3" md="3" xs="12">
              <FormGroupInput
                isIcon={true}
                label={`Test Score For ${
                  SupportingTables?.PostgrauateProgramId == 3653 ||
                  SupportingTables?.PostgrauateProgramId == 3654
                    ? "MS/MPhill"
                    : "PhD"
                }`}
                name="loginId"
                // placeholder="Test Score For PhD"
                required
                disabled
              />
            </Col>
            <Col lg="3" md="3" xs="12">
              <FormGroupInput
                isIcon={true}
                label="Merit S.No"
                name="loginId"
                // placeholder="Merit S.No"
                required
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col
              lg="12"
              md="12"
              xs="6"
              style={{ fontSize: 16, fontWeight: "bold", padding: 15 }}
            >
              <p>
                {" "}
                <div className="form-group">
                  The Applicant has qualified Pre-admission test and he/she may
                  be provisionally admitted in
                  {SupportingTables?.PostgrauateProgramId == 3653 ||
                  SupportingTables?.PostgrauateProgramId == 3654 ? (
                    <Fragment>
                      <Input
                        type="checkbox"
                        className="form-check-Input form-check-input mx-1"
                        disabled
                      />
                      <label>MS</label>
                      <Input
                        type="checkbox"
                        className="form-check-Input form-check-input  mx-1"
                        disabled
                      />
                      <label>MPhill</label>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {" "}
                      <Input
                        type="checkbox"
                        className="form-check-Input form-check-input  mx-1"
                        disabled
                      />
                      <label>PhD</label>
                    </Fragment>
                  )}
                </div>
                Program for the year{" "}
                <input
                  type="text"
                  disabled
                  style={{ borderTop: 0, borderLeft: 0, borderRight: 0 }}
                ></input>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="4" xs="12">
              <div className="form-group">
                <label className="form-label">To Date</label>
                <DatePicker
                  selected={date}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "Date")}
                  className="form-control"
                  name="Date"
                  placeholderText={dateFormatPlaceholder}
                  // required
                  disabled
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default OfficeUseOnly;
