import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { SessionStorage } from "../../../../common/SetupMasterEnum";
import { decryptData } from "../../../../EncryptData";
import { Admision_CreateAdmissionRecord } from "../../../../utils/Config";
import {
  ADMISSION_USER_ID,
  REG_ID,
} from "../../../../utils/EncryptedConstants";

const Undertaking = (props) => {
  const initialFormFields = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking: 0,
  };
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const underTakingData = SupportingTables?.students?.Table4[0];
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
    ...underTakingData,
  });
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.checked == true ? 1 : 0,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();

    let formData = new FormData();
    Object.keys(formFields).forEach((d) => {
      formData.append(d, formFields[d]);
    });
    updateAdmission(formData);
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };
  return (
    <Fragment>
      {/* <Card className="mt-3">
        <CardTitle>Undertaking Details</CardTitle>
        <CardBody> */}
      <form onSubmit={onUpdate}>
        <Row>
          <Col>
            <div className="form-check-inline mt-3">
              <label className="form-check-Label form-label">
                <Input
                  type="checkbox"
                  checked={formFields?.UnderTaking == 1 ? true : false}
                  className="form-check-Input form-check-input"
                  name="UnderTaking"
                  onChange={handleInputChange}
                  value={formFields?.UnderTaking}
                  disabled
                />
                <span style={{ fontSize: 15, marginLeft: 8 }}>
                  I, hereby undertake that the entries made in admission form
                  are coreect and complete to the best of my knowledge and that
                  my admission to the University is provisional and is subject
                  to concellation if any irregularity is found in my admission
                  form/documents, Futher, my name has not been
                  registered/enrolled as regular student for any other degree in
                  any field of study at othere Institute/University, I
                  acknowledge that I have uploaded my CNIC Front, CNIC Back,
                  Domicile, PRC & Other relevant documents. I also undertake to
                  abide by the rules and regulations of the Shaikh Ayaz
                  University Shikarpur.
                </span>
                {/* <span style={{ fontSize: 15, marginLeft: 8 }}>
                     {underTakingData?.UnderTakingDetails}
                    </span> */}
              </label>
            </div>
          </Col>
        </Row>
        {/* <Row>
              <Col>
                <div className="text-right">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={formFields?.UnderTaking == false ? true : false}
                  >
                    Update
                  </Button>
                </div>
              </Col>
            </Row> */}
      </form>
      {/* </CardBody>
      </Card> */}
    </Fragment>
  );
};

export default Undertaking;
