import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { dateFormat, dateFormatPlaceholder } from "../../utils/CommonMethods";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";


const initialValues = {
  name: "",
  designation: "",
  department: "",
  dateOfAppointmentWithGrade: "",
  yearsOfServicesAtSalu: "",
  presentgradeBps: "",
  reportForThePeriodStart: "",
  reportForThePeriodEnd: "",

  class: "",
  courseName: "",
  courseCreditHours: "",

  title: "",
  journal: "",
  volume: "",
  publicationDate: "",
  PP: "",

  titleOfBookJpirmal: "",
  publicationYear: "",

  titleOfThesisDissertation:"",
  nameOfStudent:"",
  degree:"",

  nameOfMphilStudents:"",
  dateOfDissertationApproved:"",
  remarks:"",

  nameOfStudents:"",
  sinceWhenWithYou:"",
  dateOfDissertationApprovedphd:"",
  remarksphd:"",

  titleOfTheProjects:"",
  sponsoringAgency:"",

  participationInConference:"",

  awards:"",

  remarksPerformance:"",

  reportingName:"",
  reportingNameStatus:"",

  endorsementName:"",
  
  remarksReportingOfficer:"",

};

const ACRFaculty = () => {

  const [formFields, setFormFields] = useState({
    ...initialValues,
  });

  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const teacherconcernedcolumns = [
    { field: "name", name: "Name" },
    { field: "designation", name: "Designation" },
    { field: "department", name: "Department" },
    { field: "dateOfAppointmentWithGrade", name: "Date Of Appointment With Grade" },
    { field: "YearsOfServicesAtSALU", name: "Years Of Services At SALU" },
    { field: "PresentGradeBPS", name: "Present Grade BPS" },
    { field: "employmentStatus", name: "Employment Status" },
    { field: "reportforPeriodFromDate", name: "Report for Period From Date" },
    { field: "reportforPeriodToDate", name: "Report for Period To Date" },
    { field: "semester", name: "Semester" },
  ];

  const teacherconcernedrows = [
    {
      name: "Ahsan Akram",
      designation: "Associate Professor",
      department: "Technology",
      dateOfAppointmentWithGrade: "20-10-2002",
      YearsOfServicesAtSALU: "2 years",
      PresentGradeBPS: "BPS-16",
      employmentStatus: "Permanent",
      reportforPeriodFromDate: "20-10-2020",
      reportforPeriodToDate: "20-10-2021",
      semester: "1st",
    },
    {
      name: "Faiz khan",
      designation: "Lecturer",
      department: "Technology",
      dateOfAppointmentWithGrade: "20-04-2022",
      YearsOfServicesAtSALU: "2 years",
      PresentGradeBPS: "BPS-15",
      employmentStatus: "Probation",
      reportforPeriodFromDate: "13-05-2020",
      reportforPeriodToDate: "19-07-2021",
      semester: "2nd",
    },
  ];

  const classcolumns = [
    { field: "class", name: "Class" },
    { field: "courseName", name: "Course Name" },
    { field: "courseCreditHours", name: "Course Credit Hours" },
    { field: "semester", name: "Semester" },
  ];

  const classrows = [
    {
      class: "20-A",
      courseName: "Islamic History",
      courseCreditHours: "3",
      semester:"1st",
    },
    {
      class: "20-B",
      courseName: "English",
      courseCreditHours: "3",
      semester: "2nd",
    },
  ];

  const hecrecognizedcolumns = [
    { field: "title", name: "Title" },
    { field: "journal", name: "Journal" },
    { field: "volume", name: "Volume" },
    { field: "publicationDate", name: "Publication Date" },
    { field: "pp", name: "PP" },
  ];

  const hecrecognizedrows = [
    {
      title: "Title1",
      journal: "Journal1",
      volume: "Volume1",
      publicationDate: "11-04-2020",
      pp: "Admin",
    },
    {
      title: "Title2",
      journal: "Journal2",
      volume: "Volume2",
      publicationDate: "10-01-2021",
      pp: "Admin",
    },
  ];


  const booksauthoredcolumns = [
    { field: "titleOfBookJpirmal", name: "Title Of Book/JPIRMAL" },
    { field: "publicationYear", name: "Publication Year" },
  ];

  const booksauthoredrows = [
    {
      titleOfBookJpirmal: "East Of Eden",
      publicationYear: "2006",
    },
    {
      titleOfBookJpirmal: "Mortal Engines",
      publicationYear: "2017",
    },
  ];

  const thesiscolumns = [
    { field: "titleOfThesisDissertation", name: "Title Of Thesis/Dissertation" },
    { field: "nameOfStudent", name: "Name Of Student" },
    { field: "degree", name: "Degree" },
  ];

  const thesisrows = [
    {
      titleOfThesisDissertation: "Centre for Leadership Innovation",
      nameOfStudent: "Salman Ahmed",
      degree: "BS",
    },
    {
      titleOfThesisDissertation: "Centre for Research in Law",
      nameOfStudent: "Amir Shah",
      degree: "MSc",
    },
  ];

  const mphilstudentscolumns = [
    { field: "nameOfMphilStudents", name: "Name Of M. PHIL Students" },
    { field: "dateOfDissertationApproved", name: "Date Of Dissertation Approved" },
    { field: "remarks", name: "Remarks" },
  ];

  const mphilstudentsrows = [
    {
      nameOfMphilStudents: "Shahid Khan",
      dateOfDissertationApproved: "22-07-2021",
      remarks: "Approved",
    },
    {
      nameOfMphilStudents: "Amir Khan",
      dateOfDissertationApproved: "10-04-2021",
      remarks: "Approved",
    },
  ];

  const phdstudentscolumns = [
    { field: "nameOfStudents", name: "Name Of Students" },
    { field: "sinceWhenWithYou", name: "Since When With You" },
    { field: "remarksphd", name: "Remarks" },
  ];

  const phdstudentsrows = [
    {
      nameOfStudents: "Khurram Waseem",
      sinceWhenWithYou: "3 years",
      remarksphd: "Approved",
    },
    {
      nameOfStudents: "Kamran Khan",
      sinceWhenWithYou: "5 years",
      remarksphd: "Pending",
    },
  ];

  
  const projectscolumns = [
    { field: "titleOfTheProjects", name: "Title Of The Projects" },
    { field: "sponsoringAgency", name: "Sponsoring Agency" },
  ];

  const projectsrows = [
    {
      titleOfTheProjects: "Centre for International Business",
      sponsoringAgency: "Agency1",
    },
    {
      titleOfTheProjects: "Marketing",
      sponsoringAgency: "Agency2",
    },
  ];


  const participationcolumns = [
    { field: "participationInConference", name: "Participation In Conference/Workshop/Seminars Attended And Paper Presented" },
  ];

  const participationrows = [
    {
      participationInConference: "Scholar’s Conference",
    },
    {
      participationInConference: "Build Your Base Seminar",
    },
  ];

  const awardscolumns = [
    { field: "awards", name: "Awards" },
  ];

  const awardsrows = [
    {
      awards: "Leadership Award",
    },
    {
      awards: "Perfect Presence",
    },
  ];

  const statusList = [
    { id: 1, name: "Approved" },
    { id: 2, name: "Rejected" },
    { id: 3, name: "Discuss" },
  ];

  const designationTeacherConcerned = [
    { id: 1, name: "Lecturer" },
    { id: 2, name: "Junior Lecturer" },
    { id: 3, name: "Professor" },
  ];


  const departmentTeacherConcerned = [
    { id: 1, name: "Technology" },
  ];

  const gradebpsTeacherConcerned = [
    { id: 1, name: "BPS-1" },
    { id: 1, name: "BPS-2" },
    { id: 1, name: "BPS-3" },
    { id: 1, name: "BPS-4" },
    { id: 1, name: "BPS-5" },
    { id: 1, name: "BPS-6" },
    { id: 1, name: "BPS-7" },
    { id: 1, name: "BPS-8" },
    { id: 1, name: "BPS-9" },
    { id: 1, name: "BPS-10" },
    { id: 1, name: "BPS-11" },
    { id: 1, name: "BPS-12" },
    { id: 1, name: "BPS-13" },
    { id: 1, name: "BPS-14" },
    { id: 1, name: "BPS-15" },
    { id: 1, name: "BPS-16" },

  ];



  const onEdit = () => { };
  const onDelete = () => { };

  const AllDateSet = (event, type) => {
    if (type === "fromDate") {
      setFormFields({
        ...formFields,
        fromDate: event,
      });
    } else if (type === "toDate") {
      setFormFields({
        ...formFields,
        toDate: event,
      });
    }
  };

  return (
    <Container fluid>
      <h6 className="m-0 pt-3 pb-2">Annual Confidential Report For Teachers</h6>
      <Card>
        <CardTitle>Important Instructions:</CardTitle>
        <CardBody>
          <Row>
            <Col lg='12' md='12' xs='12'>
              <ul className="ps-3">
                <li>If the Countersigning Officer differs with the grading or remarks given by the Reporting Officer, he should score it out and give his own grading in red ink </li>
                <li>Annual Confidential Report (ACR) is the most important record for the assessment of a Teacher. At the same time quality of ACR is the most important record for the assessment of a Teacher. At the same time quality of ACR is the most important record for the assessment of a Teacher. At the same time quality of ACR is a measure of the competence of the Reporting Officer and Countersigning Officer. It is, therefore, essential that utmost care be exercised by Reporting officer and Countersigning Officer</li>
                <li>Reporting Officer is expected to counsel the teacher being reported upon about this weak point(s) and advise him how to improve. Adverse Remarks should normally be recorded when the teacher fails to improve despite counseling.</li>
                <li>The reporting Officer should be:
                  <ul className="ps-3">
                    <li>As objective as possible.</li>
                    <li>Clear and direct, not ambiguous or evasive in his remarks</li>
                    <li>Whenever the appraisal, it must be substantiated. </li>
                  </ul>
                </li>
                <li>The ACR from should be filled in a neat and tidy manner. Cuttings/erasiures should be avoided and must be initialed, where made.. </li>
                <li>The 2nd Countersigning Officer should underline, in red ink, remarks which in his opinion are adverse and should be communicated to the officer reported upon</li>
                <li>Time schedule for completion of ACR writing:
                  <ul className="ps-3">
                    <li>Report writing should completed within the month of February.</li>
                    <li>Teacher Concerned after filling Part-I and Part-II, will submit perscribed ACR form to Chairman/HOD up to 1st November.</li>
                    <li>The Reporting Officer should record his remarks in part-II and part-III by the end of November and send the report to the Countersigning Officer</li>
                    <li>The Countersigning Officer should record his remarks by the end of the 1st week of December</li>
                  </ul>
                </li>
                <li>Vague impression based on inadequate knowledge or isolated incident should be avoided.</li>
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>
          <Row>
            <Col lg='12' md='12' xs='12'>
              PART-I (To be filled in by the Teacher Concerned)
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Row>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Name' name='name' onChange={handleInputChange} value={formFields.name} />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupSelect
                label="Designation"
                name="designation"
                list={designationTeacherConcerned}
                fieldId="id"
                fieldName="name"
              />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupSelect
              label="Department"
              name="department"
              list={departmentTeacherConcerned}
              fieldId="id"
              fieldName="name"
            />
            </Col>
            <Col lg='3' md='3' xs='12'>
            <div className='form-group'>
            <label className='form-label'>Date Of Appointment With Grade</label>
            <DatePicker
              selected={formFields.fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "fromDate")}
              className='form-control'
              name='fromDate'
              placeholderText={dateFormatPlaceholder}
            />
          </div> 
           </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Years Of Services At SALU' name='yearsOfServicesAtSalu' onChange={handleInputChange} value={formFields.yearsOfServicesAtSalu} />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupSelect
              label="Present Grade BPS"
              name="presentGradeBPS"
              list={gradebpsTeacherConcerned}
              fieldId="id"
              fieldName="name"
            />
              </Col>
            <Col lg='3' md='3' xs='12'>
              <label className="form-label">Employment Status</label>
              <FormGroup>
                <label className="form-label">Permanent</label>
                <Input
                  name="employment"
                  type="radio"
                  className="me-2"
                />
                <label className="form-label">Probation</label>
                <Input
                  name="employment"
                  type="radio"
                />
              </FormGroup>
            </Col>
            <Col lg='3' md='3' xs='12'>
              <div className='form-group'>
                <label className='form-label'>Report for Period From Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
            </Col>
            <Col lg='3' md='3' xs='12'>
              <div className='form-group'>
                <label className='form-label'>Report for Period To Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
            </Col>

            
          </Row>

          <Row>
            <Col lg='12' md='12' xs='12' className="text-right pb-3 pt-3">
              <Button color="primary">Save</Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroupTable columns={teacherconcernedcolumns} rows={teacherconcernedrows} onEdit={onEdit} onDelete={onDelete} />
              </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
         
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Class' name='class' onChange={handleInputChange} value={formFields.class} />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Course Name' name='courseName' onChange={handleInputChange} value={formFields.courseName} />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Course Credit Hours' name='courseCreditHours' onChange={handleInputChange} value={formFields.courseCreditHours} />
            </Col>
            <Col lg='3' md='3' xs='12'>
            <label className="form-label">Semester</label>
            <FormGroup>
              <label className="form-label">1st</label>
              <Input
                name="semester"
                type="radio"
                className="me-2"
              />
              <label className="form-label">2nd</label>
              <Input
                name="semester"
                type="radio"
              />
            </FormGroup>
          </Col>
          </Row>
          <Row>
            <Col lg='12' md='12' xs='12' className="text-right pb-3">
              <Button color="primary">Save</Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroupTable columns={classcolumns} rows={classrows} onEdit={onEdit} onDelete={onDelete} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
      <CardTitle>Research Work, Publications</CardTitle>
    <CardBody>
          <Row>
            <Col lg='12' md='12' xs='12'>
            <p>1) In HEC recognized Journal in reporting year</p>
            </Col>
          </Row>
      <Row>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Title' name='title' onChange={handleInputChange} value={formFields.title} />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Journal' name='journal' onChange={handleInputChange} value={formFields.journal} />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Volume' name='volume' onChange={handleInputChange} value={formFields.volume} />
            </Col>
        
            <Col lg='3' md='3' xs='12'> 
            <div className='form-group'>
            <label className='form-label'>Publication Date </label>
            <DatePicker
              selected={formFields.fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "fromDate")}
              className='form-control'
              name='fromDate'
              placeholderText={dateFormatPlaceholder}
            />
          </div> </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='P.P' name='PP' onChange={handleInputChange} value={formFields.PP} />
            </Col>
          </Row>
          <Row>
          <Col lg='12' md='12' xs='12' className="text-right pb-3">
            <Button color="primary">Save</Button>
          </Col>
        </Row>
      <Row>
      <Col>
        <FormGroupTable columns={hecrecognizedcolumns} rows={hecrecognizedrows} onEdit={onEdit} onDelete={onDelete} />
      </Col>
    </Row>
      </CardBody>
      </Card>


      <Card>
    <CardBody>
          <Row>
            <Col lg='12' md='12' xs='12'>
            <p>2) Books Authored/Edited</p>
            </Col>
          </Row>
      <Row>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Title Of Book/JPIRMAL' name='titleOfBookJpirmal' onChange={handleInputChange} value={formFields.titleOfBookJpirmal} />
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Publication Year' name='publicationYear' onChange={handleInputChange} value={formFields.publicationYear} />
            </Col>
          </Row>
          <Row>
          <Col lg='12' md='12' xs='12' className="text-right pb-3">
            <Button color="primary">Save</Button>
          </Col>
        </Row>
      <Row>
      <Col>
        <FormGroupTable columns={booksauthoredcolumns} rows={booksauthoredrows} onEdit={onEdit} onDelete={onDelete} />
      </Col>
    </Row>
      </CardBody>
      </Card>

      <Card>
      <CardTitle>Academic Performance</CardTitle>
      <CardBody>
            <Row>
              <Col lg='12' md='12' xs='12'>
              <p>1) Thesis/Dissertations/Monographs completed during the reporting period (BS/MA/MSc):</p>
              </Col>
            </Row>
          <Row>
              <Col lg='3' md='3' xs='12'>
                <FormGroupInput label='Title Of Thesis/Dissertation' name='titleOfThesisDissertation' onChange={handleInputChange} value={formFields.titleOfThesisDissertation} />
              </Col>
              <Col lg='3' md='3' xs='12'>
                <FormGroupInput label='Name Of Student' name='nameOfStudent' onChange={handleInputChange} value={formFields.nameOfStudent} />
              </Col>
              <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Degree' name='degree' onChange={handleInputChange} value={formFields.degree} />
            </Col>
            </Row>
            <Row>
            <Col lg='12' md='12' xs='12' className="text-right pb-3">
              <Button color="primary">Save</Button>
            </Col>
          </Row>
        <Row>
        <Col>
          <FormGroupTable columns={thesiscolumns} rows={thesisrows} onEdit={onEdit} onDelete={onDelete} />
        </Col>
      </Row>
        </CardBody>
        </Card>


        <Card>
        <CardBody>
              <Row>
                <Col lg='12' md='12' xs='12'>
                <p>2) No. of M. Phil Produced during the reporting period:</p>
                </Col>
              </Row>
            <Row>
                <Col lg='3' md='3' xs='12'>
                  <FormGroupInput label='Name Of M. PHIL Students' name='nameOfMphilStudents' onChange={handleInputChange} value={formFields.nameOfMphilStudents} />
                </Col>
                <Col lg='3' md='3' xs='12'>
                <div className='form-group'>
                <label className='form-label'>Date Of Dissertation Approved  </label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div> </Col>
                <Col lg='3' md='3' xs='12'>
                <FormGroupInput label='Remarks' name='remarks' onChange={handleInputChange} value={formFields.remarks} />
              </Col>
              </Row>
              <Row>
              <Col lg='12' md='12' xs='12' className="text-right pb-3">
                <Button color="primary">Save</Button>
              </Col>
            </Row>
          <Row>
          <Col>
            <FormGroupTable columns={mphilstudentscolumns} rows={mphilstudentsrows} onEdit={onEdit} onDelete={onDelete} />
          </Col>
        </Row>
          </CardBody>
          </Card>

          
        <Card>
        <CardBody>
              <Row>
                <Col lg='12' md='12' xs='12'>
                <p>3) No. of Ph.Ds Produced during the reporting period:</p>
                </Col>
              </Row>
            <Row>
                <Col lg='3' md='3' xs='12'>
                  <FormGroupInput label='Name Of Students' name='nameOfStudents' onChange={handleInputChange} value={formFields.nameOfStudents} />
                </Col>
                <Col lg='3' md='3' xs='12'>
                  <FormGroupInput label='Since When With You' name='sinceWhenWithYou' onChange={handleInputChange} value={formFields.sinceWhenWithYou} />
                </Col>
                <Col lg='3' md='3' xs='12'>
                <div className='form-group'>
                <label className='form-label'>Date Of Dissertation Approved </label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>  
              </Col>
               <Col lg='3' md='3' xs='12'>
                <FormGroupInput label='Remarks' name='remarksphd' onChange={handleInputChange} value={formFields.remarksphd} />
              </Col>
              </Row>
              <Row>
              <Col lg='12' md='12' xs='12' className="text-right pb-3 pt-2">
                <Button color="primary">Save</Button>
              </Col>
            </Row>
          <Row>
          <Col>
            <FormGroupTable columns={phdstudentscolumns} rows={phdstudentsrows} onEdit={onEdit} onDelete={onDelete} />
          </Col>
        </Row>
          </CardBody>
          </Card>


          <Card>
          <CardBody>
                <Row>
                  <Col lg='12' md='12' xs='12'>
                  <p>4) Involvement in research projects:</p>
                  </Col>
                </Row>
              <Row>
                  <Col lg='3' md='3' xs='12'>
                    <FormGroupInput label='Title Of The Projects' name='titleOfTheProjects' onChange={handleInputChange} value={formFields.titleOfTheProjects} />
                  </Col>
                  <Col lg='3' md='3' xs='12'>
                    <FormGroupInput label='Sponsoring Agency' name='sponsoringAgency' onChange={handleInputChange} value={formFields.sponsoringAgency} />
                  </Col>
                </Row>

                <Row>
                <Col lg='12' md='12' xs='12' className="text-right pb-3">
                  <Button color="primary">Save</Button>
                </Col>
              </Row>
            <Row>
            <Col>
              <FormGroupTable columns={projectscolumns} rows={projectsrows} onEdit={onEdit} onDelete={onDelete} />
            </Col>
          </Row>
            </CardBody>
            </Card>

            <Card>
            <CardBody>
                  <Row>
                    <Col lg='12' md='12' xs='12'>
                    <p>5) Participation In Conference Workshop Seminars Attended and Paper Presented:</p>
                    </Col>
                  </Row>
                <Row>
                    <Col lg='6' md='6' xs='12'>
                      <FormGroupInput label='Participation In Conference/Workshop/Seminars Attended and Paper Presented' name='participation' onChange={handleInputChange} value={formFields.participation} />
                    </Col>
                  </Row>
  
                  <Row>
                  <Col lg='12' md='12' xs='12' className="text-right pb-3">
                    <Button color="primary">Save</Button>
                  </Col>
                </Row>
              <Row>
              <Col>
                <FormGroupTable columns={participationcolumns} rows={participationrows} onEdit={onEdit} onDelete={onDelete} />
              </Col>
            </Row>
              </CardBody>
              </Card>
              <Card>
              <CardBody>
                    <Row>
                      <Col lg='12' md='12' xs='12'>
                      <p>6) Awards:</p>
                      </Col>
                    </Row>
                  <Row>
                      <Col lg='3' md='3' xs='12'>
                        <FormGroupInput label='Awards' name='awards' onChange={handleInputChange} value={formFields.awards} />
                      </Col>
                    </Row>
    
                    <Row>
                    <Col lg='12' md='12' xs='12' className="text-right pb-3">
                      <Button color="primary">Save</Button>
                    </Col>
                  </Row>
                <Row>
                <Col>
                  <FormGroupTable columns={awardscolumns} rows={awardsrows} onEdit={onEdit} onDelete={onDelete} />
                </Col>
              </Row>
                </CardBody>
                </Card>

                <Card>
                <CardTitle>Any Other Responsibility Assigned By University</CardTitle>
                <CardBody>
                <Row>
                <Col>
                <FormGroup>
                <Input
                    type="textarea"
                    rows={6}
                    className="faculty-textarea"
                />
            </FormGroup>
            </Col>
              </Row>
              <Row>
              <Col lg='12' md='12' xs='12' className="text-right pb-0">
                <Button color="primary">Save</Button>
              </Col>
            </Row>
                </CardBody>
                </Card>

                <Card>
                <CardTitle>PART-II (To be filled by the HOD of the concerned Department)</CardTitle>
                <CardBody>
                <Row>
                <Col lg="12" md="12" sm="12">
                <Table bordered style={{width:"100%"}} responsive>
                <thead>

                <tr>
                <th rowspan="3" className="align-middle text-center">Factor</th>
                <th colspan="5" className="text-center">Performance</th>
                <th></th>
               </tr>
              
               <tr>
                <th className="text-center">Excellent/Highly Accepted</th>
                <th className="text-center">Good/Accepted</th>
                <th className="text-center">Average/Minimally Accepted</th>
                <th className="text-center">Needs Improvement</th>
                <th className="text-center">Unsatisfactory</th>
                <th className="text-center">Remarks</th>
               </tr>
              
               <tr>
                <th className="text-center">A1</th>
                <th className="text-center">A</th>
                <th className="text-center">B</th>
                <th className="text-center">C</th>
                <th className="text-center">D</th>
                <th className="text-center"></th>
               </tr>
               
                <tr>
                <td style={{width:"20%"}}>1) Attendance, punctuality</td>
                <td className="text-center"><Input name="attendance" type="radio" /></td>
                <td className="text-center"><Input name="attendance" type="radio" /></td>
                <td className="text-center"><Input name="attendance" type="radio" /></td>
                <td className="text-center"><Input name="attendance" type="radio" /></td>
                <td className="text-center"><Input name="attendance" type="radio" /></td>
                <td className="text-center"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} />
                </td>
                </tr>

                <tr>
                <td>2) Conference, Seminars, Workshop conducted</td>
                <td className="text-center"><Input name="conference" type="radio" /></td>
                <td className="text-center"><Input name="conference" type="radio" /></td>
                <td className="text-center"><Input name="conference" type="radio" /></td>
                <td className="text-center"><Input name="conference" type="radio" /></td>
                <td className="text-center"><Input name="conference" type="radio" /></td>
                <td className="text-center"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} />
                </td>
                </tr>

                <tr>
                <td>3) Contribution in new ideas and methods; self starter, makes practical and workable suggestion for improvement</td>
                <td className="text-center align-middle"><Input name="contribution" type="radio" /></td>
                <td className="text-center align-middle"><Input name="contribution" type="radio" /></td>
                <td className="text-center align-middle"><Input name="contribution" type="radio" /></td>
                <td className="text-center align-middle"><Input name="contribution" type="radio" /></td>
                <td className="text-center align-middle"><Input name="contribution" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>
                <tr>
                <td>4) Competence in field/subject of specialization/assignment</td>
                <td className="text-center align-middle"><Input name="competence" type="radio" /></td>
                <td className="text-center align-middle"><Input name="competence" type="radio" /></td>
                <td className="text-center align-middle"><Input name="competence" type="radio" /></td>
                <td className="text-center align-middle"><Input name="competence" type="radio" /></td>
                <td className="text-center align-middle"><Input name="competence" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>5) Maintains positive class room environment, supportive and caring to students</td>
                <td className="text-center align-middle"><Input name="maintains" type="radio" /></td>
                <td className="text-center align-middle"><Input name="maintains" type="radio" /></td>
                <td className="text-center align-middle"><Input name="maintains" type="radio" /></td>
                <td className="text-center align-middle"><Input name="maintains" type="radio" /></td>
                <td className="text-center align-middle"><Input name="maintains" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>6) Research work guidance. Is professional and ethical in guidance/supervision?</td>
                <td className="text-center align-middle"><Input name="research" type="radio" /></td>
                <td className="text-center align-middle"><Input name="research" type="radio" /></td>
                <td className="text-center align-middle"><Input name="research" type="radio" /></td>
                <td className="text-center align-middle"><Input name="research" type="radio" /></td>
                <td className="text-center align-middle"><Input name="research" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>7) Teaching work.
                <ul className="ps-4">
                <li>Quality</li>
                <li>Quantity</li>
                </ul>
                </td>
                <td className="text-center align-middle"><Input name="teaching" type="radio" /></td>
                <td className="text-center align-middle"><Input name="teaching" type="radio" /></td>
                <td className="text-center align-middle"><Input name="teaching" type="radio" /></td>
                <td className="text-center align-middle"><Input name="teaching" type="radio" /></td>
                <td className="text-center align-middle"><Input name="teaching" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>8) Work commitment, ability to give quality and quanity as assigned</td>
                <td className="text-center align-middle"><Input name="work" type="radio" /></td>
                <td className="text-center align-middle"><Input name="work" type="radio" /></td>
                <td className="text-center align-middle"><Input name="work" type="radio" /></td>
                <td className="text-center align-middle"><Input name="work" type="radio" /></td>
                <td className="text-center align-middle"><Input name="work" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>9) Working relationship with, Head of Department/Colleagues</td>
                <td className="text-center align-middle"><Input name="working" type="radio" /></td>
                <td className="text-center align-middle"><Input name="working" type="radio" /></td>
                <td className="text-center align-middle"><Input name="working" type="radio" /></td>
                <td className="text-center align-middle"><Input name="working" type="radio" /></td>
                <td className="text-center align-middle"><Input name="working" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>10) His over all performance in the department</td>
                <td className="text-center align-middle"><Input name="department" type="radio" /></td>
                <td className="text-center align-middle"><Input name="department" type="radio" /></td>
                <td className="text-center align-middle"><Input name="department" type="radio" /></td>
                <td className="text-center align-middle"><Input name="department" type="radio" /></td>
                <td className="text-center align-middle"><Input name="department" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>11) Extra-Curricular Activity</td>
                <td className="text-center"><Input name="activity" type="radio" /></td>
                <td className="text-center"><Input name="activity" type="radio" /></td>
                <td className="text-center"><Input name="activity" type="radio" /></td>
                <td className="text-center"><Input name="activity" type="radio" /></td>
                <td className="text-center"><Input name="activity" type="radio" /></td>
                <td className="text-center"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td> 12) Sense of responsibility: perseverance and consistency in attending duty </td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>13) Understanding of Islam (Applicable of Muslims only)</td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><Input name="understanding" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>14) Cooperation: (extending and eliciting cooperation from colleagues /subordinates)</td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td>15) Emotional stability: (self control, clear thinking and sense of direction in tense situation)</td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><Input name="cooperation" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                <tr>
                <td className="align-middle">16) Over-all Rating</td>
                <td className="text-center align-middle"><Input name="rating" type="radio" /></td>
                <td className="text-center align-middle"><Input name="rating" type="radio" /></td>
                <td className="text-center align-middle"><Input name="rating" type="radio" /></td>
                <td className="text-center align-middle"><Input name="rating" type="radio" /></td>
                <td className="text-center align-middle"><Input name="rating" type="radio" /></td>
                <td className="text-center align-middle"><FormGroupInput name='remarksPerformance' onChange={handleInputChange} value={formFields.remarksPerformance} /></td>
                </tr>

                </thead>
                </Table>
                </Col>
                </Row>
                <Row>
                <Col lg='12' md='12' xs='12' className="text-right pb-0">
                  <Button color="primary">Save</Button>
                </Col>
              </Row>
                </CardBody>
                </Card>

                <Card>
                <CardTitle>To be filled by the reporting officer</CardTitle>
                <CardBody>
                <Row>
                <Col lg="12" md="12" sm="12">
                <Table bordered style={{width:"100%"}}>
                <tbody>
                <tr>
                <td style={{width:"40%"}}>Potential for growth and development</td>
                <td>
                <label>Yes</label>
                <Input  
                name="growth"
                type="radio"/>
                </td>
                <td>
                <label>No</label>
                <Input     
                name="growth"
                type="radio"/>
                </td>
                </tr>

                <tr>
                <td style={{width:"40%"}}>Is he/she fit for higher level responsibilities?</td>
                <td>	
                <label>Yes</label>
                <Input  
                name="level"
                type="radio"/>
                </td>
                <td>
                <label>No</label>
                <Input     
                name="level"
                type="radio"/>
                </td>
                </tr>

                <tr>
                <td style={{width:"40%"}}>Does he/she require any additional training?</td>
                <td>
                <label>Yes</label>
                <Input  
                name="require"
                type="radio"/>
                </td>
                <td>
                <label>No</label>
                <Input     
                name="require"
                type="radio"/>
                </td>
                </tr>

                <tr>
                <td style={{width:"40%"}}>If yes, nature and type of training</td>
                <td colSpan={2}>
                <Input
                type="textarea"
                rows={2}
                className="reporting-officer"
            />
               
                </td>
                </tr>
                </tbody>
                </Table>
                </Col>
                </Row>
                <Row>
                <Col lg='12' md='12' xs='12' className="text-right">
                  <Button color="primary">Save</Button>
                </Col>
              </Row>
                </CardBody>
                </Card>

                <Card>
                <CardTitle>
                Reporting Officer (HOD/Chairman/Director)
                </CardTitle>
                <CardBody>
                <Row>
                <Col lg='12' md='12' xs='12'>
                <FormGroup>
                <Input
                    type="textarea"
                    rows={6}
                    className="faculty-textarea"
                />
            </FormGroup>
              </Col>
              </Row>
              <Row>
               <Col lg='3' md='3' xs='12'>
                  <FormGroupInput label='Name' name='reportingName' onChange={handleInputChange} value={formFields.reportingName} />
                </Col>
                <Col lg='3' md='3' xs='12'>
                <div className='form-group'>
                <label className='form-label'> Date </label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>    
              </Col>
              <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Status"
                name="statusList"
                list={statusList}
                fieldId="id"
                fieldName="name"
              />
            </Col>
              </Row>
              <Row>
                <Col lg='12' md='12' xs='12' className="text-right">
                  <Button color="primary">Save</Button>
                </Col>
              </Row>
                </CardBody>
                </Card>
  
                <Card>
                <CardTitle>
                Reporting Officer (HOD/Chairman/Director)
                </CardTitle>
                <CardBody>
                <Row>
                <Col lg='12' md='12' xs='12'>
                <FormGroup>
                <Label>Remarks: (if any):</Label>
                <Input
                    type="textarea"
                    rows={6}
                    className="faculty-textarea"
                />
            </FormGroup>
              </Col>
              </Row>
              <Row>
               <Col lg='3' md='3' xs='12'>
                  <FormGroupInput label='Name' name='reportingName' onChange={handleInputChange} value={formFields.reportingName} />
                </Col>
                <Col lg='3' md='3' xs='12'>
                <div className='form-group'>
                <label className='form-label'> Date </label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>    
              </Col>
              <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Status"
                name="statusList"
                list={statusList}
                fieldId="id"
                fieldName="name"
              />
            </Col>
              </Row>
              <Row>
                <Col lg='12' md='12' xs='12' className="text-right">
                  <Button color="primary">Save</Button>
                </Col>
              </Row>
                </CardBody>
                </Card>

                <Card>
                <CardTitle>
                Endorsement By The Dean Of The Faculty Concerned
                </CardTitle>
                <CardBody>
                <Row>
                <Col lg='12' md='12' xs='12'>
                <FormGroup>
                <label>I agree with the above evaluation</label>
                <Input  
                name="evaluation"
                type="radio" className="me-3"/>
               
                <label>I disagree with the above evaluation</label>
                <Input  
                name="evaluation"
                type="radio"/>
                </FormGroup>
                </Col>
                <Col lg='12' md='12' xs='12'>
                <FormGroup>
                <Label>If Disagrees with Chairman/HOD, Give Reasons:</Label>
                <Input
                    type="textarea"
                    rows={6}
                    className="faculty-textarea"
                />
            </FormGroup>
              </Col>
              </Row>
              <Row>
               <Col lg='3' md='3' xs='12'>
                  <FormGroupInput label='Name' name='reportingName' onChange={handleInputChange} value={formFields.reportingName} />
                </Col>
                <Col lg='3' md='3' xs='12'>
                <div className='form-group'>
                <label className='form-label'> Date </label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>   
               </Col>
               <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Status"
                name="statusList"
                list={statusList}
                fieldId="id"
                fieldName="name"
              />
            </Col>
              </Row>
              <Row>
                <Col lg='12' md='12' xs='12' className="text-right">
                  <Button color="primary">Save</Button>
                </Col>
              </Row>
                </CardBody>
                </Card>

                <Card>
                <CardTitle>
               Vice Chancellor
                </CardTitle>
                <CardBody>
                <Row>
                <Col lg='12' md='12' xs='12'>
                <FormGroup>
                <label>I agree with the report</label>
                <Input  
                name="evaluation"
                type="radio" className="me-3"/>
               
                <label>I disagree with the report</label>
                <Input  
                name="evaluation"
                type="radio"/>
                </FormGroup>
                </Col>
                <Col lg='12' md='12' xs='12'>
                <FormGroup>
                <Label>If Disagrees with Chairman/HOD, Give Reasons:</Label>
                <Input
                    type="textarea"
                    rows={6}
                    className="faculty-textarea"
                />
            </FormGroup>
              </Col>
              </Row>
              <Row>
              <Col lg='3' md='3' xs='12'>
                 <FormGroupInput label='Name' name='reportingName' onChange={handleInputChange} value={formFields.reportingName} />
               </Col>
               <Col lg='3' md='3' xs='12'>
               <div className='form-group'>
               <label className='form-label'> Date </label>
               <DatePicker
                 selected={formFields.fromDate}
                 dateFormat={dateFormat}
                 onChange={(e) => AllDateSet(e, "fromDate")}
                 className='form-control'
                 name='fromDate'
                 placeholderText={dateFormatPlaceholder}
               />
             </div>   
              </Col>
              <Col lg="3" md="3" xs="12">
              <FormGroupSelect
                label="Status"
                name="statusList"
                list={statusList}
                fieldId="id"
                fieldName="name"
              />
            </Col>
             </Row>
              <Row>
                <Col lg='12' md='12' xs='12' className="text-right">
                  <Button color="primary">Save</Button>
                </Col>
               </Row>
            </CardBody>
         </Card>

    </Container>
  );
};

export default ACRFaculty