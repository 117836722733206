import axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { documentType, SessionStorage } from "../../../common/SetupMasterEnum";
import {
  Admision_CreateAdmissionRecord,
  Admission_GetStudendAdmissionRecord,
} from "../../../utils/Config";
import { decryptData } from "../../../EncryptData";
import { ADMISSION_USER_ID, REG_ID } from "../../../utils/EncryptedConstants";
import { CustomErrorMessage } from "../../../components/Alert";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { checkUGdocumentsList } from "../../../functions/checkUGdocumentsList";
import { SaveFile } from "../../../utils/SaveFile";

const initialFormFields = {
  ADMDocId: 0,
  ADMRegID: 0,
  DocumentName: "",
  DocumentTypeID: 0,
  DocumentTypeName: "",
  DocumentPath: {},
  DocumentPathNew: "",
  UserIP: "192.168.168",
  FileName: "",
};

const Documents = (props) => {
  initialFormFields.ADMRegID = decryptData(REG_ID, SessionStorage);
  const dispatch = useDispatch();
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const documentRecords = SupportingTables?.students?.Table5;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const statusData = SupportingTables?.students?.Table6;
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
  });
  const inputRef = useRef(null);
  const [documentData, setDocumentData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [updateButton, setUpdateButton] = useState(false);
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    });
  };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  const handleDocChange = (event) => {
    setFormFields({
      ...formFields,
      DocumentPathNew: URL.createObjectURL(event.target.files[0]),
      DocumentPath: event.target.files[0],
      FileName: event.target.files[0].name,
    });
  };
  useEffect(() => {
    setDocumentData(documentRecords);
  }, [documentRecords]);

  const onAdd = (e) => {
    e.preventDefault();
    e.target["DocumentPath"].value = [];
    if (
      documentData.some(
        (x) =>
          x.DocumentTypeID == formFields.DocumentTypeID &&
          x.DocumentTypeID != 3252
      )
    ) {
      CustomErrorMessage("Cannot insert more than one Document of same type");
    } else {
      documentData.push(formFields);
      setDocumentData([...documentData]);
      setFormFields({
        ...initialFormFields,
      });
    }
  };

  const onDelete = (index) => {
    documentData.splice(index, 1);
    setDocumentData([...documentData]);
  };
  const onCancel = () => {
    setFormFields({
      ...initialFormFields,
    });
    document.getElementById("DP").value = [];
  };
  const onUpdate = (e) => {
    e.preventDefault();
    setUpdateButton(true);
    if (statusData?.length != 0) {
      if (
        statusData[0]?.PersonalInfo == 0 ||
        statusData[0]?.Academics == 0 ||
        statusData[0]?.Preference == 0
      ) {
        CustomErrorMessage("Kindly Complete Admission Application Step Wise");
        setUpdateButton(false);
      } else if (documentData.length == 0) {
        Swal.fire({
          title: "Error",
          text: "Please Add Document Information",
          icon: "error",
        });
        setUpdateButton(false);
      } else {
        if (checkUGdocumentsList(documentData) == false) {
          CustomErrorMessage(
            "It is mandatory to upload CNIC"
          );
          setUpdateButton(false);
        } else {
          let formData = new FormData();
          Object.keys(basicValues).forEach((d) => {
            formData.append(d, basicValues[d]);
          });
          documentData.forEach((element, index) => {
            Object.keys(element).forEach((item) => {
              formData.append(
                `AdmissionDocument_[${index}].${item}`,
                element[item]
              );
            });
          });
          updateAdmission(formData);
        }
      }
    } else {
      CustomErrorMessage("Please fill data step wise");
      setUpdateButton(false);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          getStudendRecords();
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        setUpdateButton(false);
        // setSubmitLoad(false);
      });
  };

  const getStudendRecords = () => {
    const payload = {
      ADMRegId: decryptData(REG_ID, SessionStorage),
      Programid: 3479,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
          setUpdateButton(false);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setUpdateButton(false);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Document Information</CardTitle>
        <CardBody>
          <div className="alert alert-warning mb-2">
            <strong>
              {/* Kindly upload all relevant documents as mentioned in the academic
              records and checklist Items along with Domicile, CNIC, PRC,
              Passport Size Photograph */}
            It is mandatory to upload CNIC
            </strong>
          </div>
          <form onSubmit={(e) => onAdd(e)}>
            <Row style={{ alignItems: "flex-end" }}>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  name="DocumentName"
                  value={formFields?.DocumentName}
                  label="Document Name"
                  onChange={handleInputChange}
                  required
                  maxLength="200"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  label="Document Type"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == documentType
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  onChange={handleInputChangeSelect}
                  name="DocumentTypeID"
                  value={formFields?.DocumentTypeID}
                  nameValue="DocumentTypeName"
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                {/* <FormGroupInput
                  label="Upload Attachment"
                  type="file"
                  name="UploadAttachment"
                  // value={uploadAttach.name}
                  onChange={handleDocChange}
                  required
                /> */}
                <div className="form-group">
                  <label className="form-label">
                    Upload Attachment<span className="text-danger">*</span>
                  </label>
                  <Input
                    id="DP"
                    type="file"
                    name="DocumentPath"
                    onChange={handleDocChange}
                    accept=".pdf/,image/jpeg,image/jpg,image/png,text/csv,.docx,.doc,.xls,.txt"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="4"></Col>
              <Col lg="8" md="8" sm="8" xs="12" className="text-right">
                <Button color="primary" type="submit">
                  Add
                </Button>
                <Button color="default" type="button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>Document List</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-striped mb-0 mt-2">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Document Type</th>
                      <th className="text-center">Download</th>
                      <th className="text-center">View</th>
                      <th className="text-center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentData?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.DocumentName}</td>
                          <td>{item.DocumentTypeName}</td>
                          <td className="text-center">
                            {/* <a
                              className="btn round-cubre btn-primary"
                              onClick={SaveFile(item.DocumentPathNew, "AnyName")}
                              // download
                              // href={item.DocumentPathNew}
                            > */}
                            <i
                              onClick={() => {
                                SaveFile(
                                  item.DocumentPathNew,
                                  "Document_Image"
                                );
                              }}
                              className="fa fa-download"
                            ></i>
                            {/* </a> */}
                          </td>
                          <td className="text-center">
                            <a
                              className="btn round-cubre btn-info"
                              href={item.DocumentPathNew}
                              // download
                              target="_blank"
                            >
                              <i className="fa fa-eye" color="primary"></i>
                            </a>
                          </td>
                          <td className="text-center">
                            <Button
                              className="btn btnic"
                              color="danger"
                              type="button"
                              onClick={() => onDelete(index)}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12" className="text-right mt-2">
              <Button
                color="primary"
                className="btn"
                onClick={onUpdate}
                disabled={
                  statusData[0]?.Status == 1
                    ? true
                    : updateButton === true
                    ? true
                    : false
                }
                // disabled={statusData[0]?.Status == 1 ? true : false}
              >
                {submitLoad ? <Spinner color="light" size="sm" /> : null}
                Upload
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Documents;
