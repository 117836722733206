export const MENU_TABLE = "e45b3703-dad0-432d-a670-affde393af03";

export const EMAIL = "430149761-6ED2-421B-95E5-E1F272CB6247";

export const MOBILE = "430149761-6ED2-421B-95E5-E1F272C43247";

export const EMPLOYEE_NAME = "43014901-6ED2-443B-95E5-E1F272CB0047";

export const LAST_NAME = "49876501-6ED2-113B-95E5-E1F999CB0047";

export const TYPE = "09812501-4WA2-773B-85E5-A1F999CB5565";

export const EMPLOYEE_DESIGNATION = "0129E68BE2764EC091624AA238FC3C9A";

export const RESETPASSWORD = "fc1f7976-1329-412f-be3d-97d463d7a27e";

export const USER_ID = "4D73DBE5-D54B-4AEE-AD74-D863EFCB869A";

export const LOGIN_TYPE = "4D73DHO9-DY7B-4AWC-AQ54-D863EFCBI9T5";

export const REG_ID = "8D10DKJ9-TY9B-4XOC-CQ54-A375EFCBI9X0";

export const CNIC_BFORM = "2D83DKJ9-TY9B-4FTS-CQ54-A275EFCBI9X0";

export const ADMISSION_USER_ID = "8Z10DAJ9-TY9B-4XOC-CP54-A375ZFCBI9U0";

export const MIDDLE_NAME = "5A10DAM1-ZY9A-4XCC-AP90-A375ZFCBI0U5";

export const PROFILE_ONE = "4D73RHO9-DZ7B-4AWC-AQ54-D8%^EFDGI9T5";

export const PROFILE_ZERO = "4893RHO9-D380B-4AWC-AQ54-D8%^EFDGP6T5";

export const UserNetworkInfo = "10232501-4ZX2-903B-01E5-Z2F999CB5511";

export const STUDENT_DETAIL_ID = "10232501-4ZX2-903B-01D5-Z2F888CB5511";
