import React, { useEffect, useRef, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Improvement_Suppli,
  Insert,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import { decryptData } from "../../../EncryptData";
import { SET_INITIAL_CRUD_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { ACAD_SUPPLI_IMPROVEMENT_OPS } from "../../../utils/UrlConstants";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { Button, Col, Container, Row, Table } from "reactstrap";
import useFade from "../../../Hooks/useFade";

const SupplementaryAndImprovement = () => {
  const [suppliImprovmentOps_TBL_, setsuppliImprovmentOps_TBL_] = useState([]);

  const initialFormFields = {
    operationId: Select,
    parameterID: Select,
    feeTypeID: 0,
    studentDetailID: decryptData("EmplId", SessionStorage),
    userID: 0,
    userIP: decryptData(UserNetworkInfo)?.IPv4,
    suppliImprovmentOps_TBL_: [
      {
        supplyImprovmentStudentAcademicRecordDetailID: 0,
        studentDetailID: 0,
        enrolledStudentAcademicRecordDetailID: 0,
        feeTypeID: 0,
      },
    ],
  };

  const { FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  // const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const timeoutRef = useRef(null);

  const [myBucket, setMyBucket] = useState([]);
  const [showSupply, setShowSupply, fadeOutProps] = useFade(false, 700);

  useEffect(() => {
    getAcad_Suppli_Improvement_Ops();
    getAcad_Suppli_Improvement_Ops_MyBucket();
    // return () => clearTimeout(timeoutRef.current);
  }, []);

  const getAcad_Suppli_Improvement_Ops = () => {
    PostRequest(ACAD_SUPPLI_IMPROVEMENT_OPS, initialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
          },
        });
        setShowSupply(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAcad_Suppli_Improvement_Ops_MyBucket = () => {
    const payload = {
      ...initialFormFields,
      parameterID: Insert,
    };
    PostRequest(ACAD_SUPPLI_IMPROVEMENT_OPS, payload)
      .then((res) => {
        setMyBucket(res?.data?.Table);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Course", name: "Course Name" },
    { field: "Grade", name: "Grade" },
    { field: "Marks", name: "Marks" },
    { field: "Part", name: "Part" },
    { field: "Semester", name: "Semester" },
    { field: "Amount", name: "Amount" },
    { field: "Selected", name: "Selected Course" },
  ];

  const MyBucketColumns = [
    { field: "Course", name: "Course Name" },
    { field: "Part", name: "Part" },
    { field: "Semester", name: "Semester" },
  ];

  const handleClickApply = () => {
    const payload = {
      operationId: Insert,
      parameterID: 0,
      feeTypeID: 0,
      studentDetailID: decryptData("EmplId", SessionStorage),
      userID: 0,
      userIP: decryptData(UserNetworkInfo)?.IPv4,
      suppliImprovmentOps_TBL_: suppliImprovmentOps_TBL_,
    };
    PostRequest(ACAD_SUPPLI_IMPROVEMENT_OPS, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          // setsuppliImprovmentOps_TBL_([]);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClickCheck = (e, index, item) => {
    const { checked } = e.target;
    debugger;
    if (checked === true) {
      let data = {
        supplyImprovmentStudentAcademicRecordDetailID: 0,
        studentDetailID: decryptData("EmplId", SessionStorage),
        enrolledStudentAcademicRecordDetailID:
          item?.EnrolledStudentAcademicRecordDetailID,
        feeTypeID: Improvement_Suppli,
      };
      suppliImprovmentOps_TBL_.push({ ...data });
      setsuppliImprovmentOps_TBL_([...suppliImprovmentOps_TBL_]);
      myBucket.push({
        ...item,
      });
      setMyBucket([...myBucket]);
    } else {
      let indexOfMyBucket = myBucket.findIndex(
        (x) =>
          x.EnrolledStudentAcademicRecordDetailID ===
          item.EnrolledStudentAcademicRecordDetailID
      );
      myBucket.splice(indexOfMyBucket, 1);
      setMyBucket([...myBucket]);
    }
  };

  const customTable = (
    <div {...fadeOutProps}>
      <Row className="mb-3">
        <Col lg="12" md="12" xs="12" className="text-center">
          <h4
            style={{
              color: "#3d1d77",
            }}
          >
            My Bucket
          </h4>
        </Col>
      </Row>
      {showSupply && (
        <Container>
          {myBucket.length > 0 ? (
            <Table bordered striped responsive style={{ width: "100%" }}>
              <thead>
                <tr className="text-center">
                  <th rowSpan={2} colSpan={1}>
                    S.No.
                  </th>
                  {MyBucketColumns?.map((column, index) => (
                    <th
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {myBucket?.map((item, index) => (
                  <tr
                    className="text-center"
                    style={{ verticalAlign: "middle" }}
                    key={index}
                  >
                    <td>{index + 1}</td>
                    <td>{item?.Course}</td>
                    <td>{item?.Part}</td>
                    <td>{item?.Semester}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <table class="table-bordered table-striped admission-table table">
              <tbody>
                <tr>
                  <td class="text-center">No Course Selected</td>
                </tr>
              </tbody>
            </table>
          )}
        </Container>
      )}

      <Row>
        <Col lg="12" md="12" xs="12" className="text-center">
          <Button
            color="primary"
            className="btn"
            onClick={handleClickApply}
            disabled={myBucket?.length > 0 ? false : true}
          >
            Apply
          </Button>
        </Col>
      </Row>
      <br />
      <hr />
      <br />
      <Row className="mb-3">
        <Col lg="12" md="12" xs="12" className="text-center">
          <h4
            style={{
              color: "rgb(119, 38, 71)",
            }}
          >
            Available Courses
          </h4>
        </Col>
      </Row>
      <Table bordered striped responsive style={{ width: "100%" }}>
        <thead>
          <tr className="text-center">
            <th rowSpan={2} colSpan={1}>
              S.No.
            </th>
            {columns?.map((column, index) => (
              <th
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                key={index}
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TableList?.map((item, index) => (
            <tr
              className="text-center"
              style={{ verticalAlign: "middle" }}
              key={index}
            >
              <td>{index + 1}</td>
              <td>{item?.Course}</td>
              <td>{item?.Grade}</td>
              <td>{item?.Marks}</td>
              <td>{item?.Part}</td>
              <td>{item?.Semester}</td>
              <td>{item?.Amount}</td>
              <td>
                <input
                  type="checkbox"
                  class="form-check-Input form-check-input"
                  value={item?.IsChecked}
                  onChange={(e) => handleClickCheck(e, index, item)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <AcademicFormComponent
      SemesterName="Regestered Courses Fall 2023"
      customTable={customTable}
      tableColorCode={true}
    />
  );
};

export default SupplementaryAndImprovement;
