import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import { forwardRef } from "react";
import logo from "../../../assets/img/header-img.jpg";
import headerimg from "../../../assets/img/header-img-report.jpg";

const AdmissionStatisticsReport = forwardRef((props, ref) => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();

  const columns = [
    {
      field: "AdmissionFacultyDepartment",
      name: "Admission Faculty Department",
    },
    {
      field: "FacultyDepartmentProgram",
      name: "Faculty Department Program",
    },
    {
      field: "TotalApplicationRecieved",
      name: "Total Application Recieved ",
    },
    {
      field: "Male",
      name: "Male",
    },
    {
      field: "Female",
      name: "Female",
    },
    {
      field: "Approved",
      name: "Approved",
    },

    {
      field: "Pending",
      name: "Pending",
    },
   
    {
      field: "Moved",
      name: "Moved",
    },

    {
      field: "Rejected",
      name: "Rejected",
    },
    {
      field: "Revert",
      name: "Revert",
    },
    {
      field: "Trashed",
      name: "Trashed",
    },
  
    {
      field: "TotalPaid",
      name: "Total Paid",
    },    
    {
      field: "TotalUnPaid",
      name: "Total UnPaid",
    },
    {
      field: "AmountDeposited",
      name: "Amount Deposited",
    },
  ];
  return (
    <div style={{ margin: 10 }} className="landscape-page" ref={ref}>
      <div>
        <img src={headerimg} style={{ width: "100%" }} />
      </div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>S.No.</th>
            {columns &&
              columns.map((column, index) => (
                <th key={index}>{column.name}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {TableList?.Table?.map((item, ind) => {
            return (
              <tr key={ind}>
                <td>{ind + 1}</td>
                <td>{item?.AdmissionFacultyDepartment}</td>
                <td>{item?.FacultyDepartmentProgram}</td>
                <td>{item?.TotalApplicationRecieved}</td>
                <td>{item?.Male}</td>
                <td>{item?.Female}</td>
                

                <td>{item?.Approved}</td>
                <td>{item?.Pending}</td>
                <td>{item?.Moved}</td>

                <td>{item?.Rejected}</td>
                <td>{item?.Revert}</td>
                <td>{item?.Trashed}</td>
                <td>{item?.TotalPaid}</td>
                <td>{item?.TotalUnPaid}</td>

                <td>{item?.AmountDeposited}</td>
              </tr>
            );
          })}
          {TableList?.Table1?.map((tab, id) => {
            return (
              <tr key={id}>
              <td colSpan={2}></td>
              <td style={{fontWeight: "bold"}}>{tab?.Total}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalApplicationRecieved}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalMale}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalFemale}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalApproved}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalPending}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalMoved}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalRejected}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalRevert}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalTrashed}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalPaid}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalUnPaid}</td>
              <td style={{fontWeight: "bold"}}>{tab?.TotalAmountDeposited}</td>
            </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  );
});

export default AdmissionStatisticsReport;
