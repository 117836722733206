import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import {
  academicYearId,
  campusCity,
  campusType,
  Delete,
  Insert,
  partYearID,
  Search,
  semesterId,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteWithConfirmation,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { ENROLLMENTOPS } from "../../../utils/UrlConstants";


const initialSearchFields = {
  OperationId: Search,
  EnrollmentID: 0,
  ProgramID:0,
  AcademicYearID: 0,
  EnrollYearID: 0,
  StudentDetailID:0,
  CampusCityID:0,
  FacultyDepartmentID:0,
  FacultyDepartmentProgramID:0,
  PartID:0,
  SetupSectionID:0,
};

const initialFormFields = {
  OperationId: 0,
  EnrollmentID: 0,
  ProgramID:0,
  AcademicYearID: 0,
  EnrollYearID: 0,
  StudentDetailID:0,
  CampusCityID:0,
  FacultyDepartmentID:0,
  FacultyDepartmentProgramID:0,
  PartID:0,
  SetupSectionID:0,
};

// Previous Payload
// const initialSearchFields = {
//   OperationId: Search,
//   EnrollmentID: 0,
//   EnrollmentDetailID: 0,
//   AcademicYearID: 0,
//   Cnic: "",
//   AdmRegID: 0,
//   CampusID: 0,
//   CampusCityID: 0,
//   FacultyDepartmentID: 0,
//   FacultyDepartmentProgramID: 0,
//   PartID: 0,
//   SemesterID: 0,
//   SetupSectionID: 0,
//   StatusTypeID: 0,
//   StudentDetailID: 0,
//   IsActive: false,
//   IsExisting: false,
//   CreatedBy: decryptData("loginId", SessionStorage),
//   ModifiedBy: decryptData("loginId", SessionStorage),
//   UserIP: decryptData(UserNetworkInfo)?.IPv4,
// };
// const initialFormFields = {
//   OperationId: 0,
//   EnrollmentID: 0,
//   EnrollmentDetailID: 0,
//   AcademicYearID: 0,
//   Cnic: "",
//   AdmRegID: 0,
//   CampusID: 1284,
//   CampusCityID: 1644,
//   FacultyDepartmentID: 0,
//   FacultyDepartmentProgramID: 0,
//   PartID: 0,
//   SemesterID: 0,
//   SetupSectionID: 0,
//   StatusTypeID: 0,
//   StudentDetailID: 0,
//   IsActive: false,
//   CreatedBy: decryptData("loginId", SessionStorage),
//   ModifiedBy: decryptData("loginId", SessionStorage),
//   UserIP: decryptData(UserNetworkInfo)?.IPv4,
// };



const EnrollmentOps = () => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [campusCityList] = useSetupDetailList(campusCity, FormFields?.campusID);
  const [campusList] = useSetupDetailList(campusType);
  const [AcademicYear] = useSetupDetailList(academicYearId);
  const [Semester] = useSetupDetailList(semesterId);
  const [PartYear] = useSetupDetailList(partYearID);

  const dispatch = useDispatch();
  const [enableButtons, setEnableButtons] = useState(true);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    // getSectionsList();
    getEnrollmentOps();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
  }, []);

  function getEnrollmentOps() {
    PostRequest(ENROLLMENTOPS, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "Name", name: "Name" },
    { field: "Cnic", name: "Cnic" },
    { field: "Reg_No", name: "Registration Number" },
    { field: "DeprtProg", name: "Department Program" },
    { field: "EnrollmentYear", name: "Enrollment Year" },
    
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={AcademicYear}
          label="Academic Year"
          name="AcademicYearID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
        />
      </Col>
      {/* <Col md="3" lg="3">
        <FormGroupInput
          label="CNIC"
          name="Cnic"
          isNumber="true"
          value={SearchFields?.Cnic}
          onChange={handleSearchChange}
        />
      </Col> */}
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Campus"
          name="CampusID"
          onChange={handleSearchChange}
          value={SearchFields?.CampusID}
          list={campusList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Campus City"
          name="CampusCityID"
          onChange={handleSearchChange}
          value={SearchFields?.CampusCityID}
          list={campusCityList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDepartmentID"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDepartmentID}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyDepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartID"
          onChange={handleSearchChange}
          value={SearchFields?.PartID}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="SemesterID"
          onChange={handleSearchChange}
          value={SearchFields?.SemesterID}
          list={Semester}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="2" md="2" xs="12">
        <FormGroupCheckbox
          label=" Is Active"
          name="IsActive"
          value={SearchFields?.IsActive}
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="2" md="2" xs="12">
        <FormGroupCheckbox
          label=" Is Existing"
          name="IsExisting"
          value={SearchFields?.IsExisting}
          onChange={handleSearchChange}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      OperationId: Search
    };

    PostRequest(ENROLLMENTOPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    // const payload = {
    //   ...FormFields,
    //   OperationID: id,
    //   EnrollmentID: 0,
    //   EnrollmentDetailID: 0,
    //   AdmRegID: 0,
    //   StatusTypeID: 0,
    //   CreatedBy: decryptData("loginId", SessionStorage),
    //   ModifiedBy: decryptData("loginId", SessionStorage),
    //   UserIP: decryptData(UserNetworkInfo)?.IPv4,
    // };

    const payload ={
      ...FormFields,
      OperationId: id,
    }

    PostRequest(ENROLLMENTOPS, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
          getEnrollmentOps();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const onEditRow = (obj) => {};

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        const payload = {
          ...initialFormFields,
          OperationId: Delete,
          EnrollmentID: obj?.EnrollmentID,
        };

        PostRequest(ENROLLMENTOPS, payload)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
              getEnrollmentOps();
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getEnrollmentOps();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    const payload = {
      ...FormFields,
      OperationID: 0,
    };

    PostRequest(ENROLLMENTOPS, payload)
      .then((res) => {
        onChange_Select_Department_Program({
          operationID: 6,
          caseID: 3,
          paremeterID: res?.data?.Table[0]?.AdmissionFacultyDepartmentId,
        }).then((res) => {
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
        });
        let data = {
          Cnic: FormFields?.Cnic,
          AcademicYearID: FormFields?.AcademicYearID,
          CampusID: 1284,
          CampusCityID: 1644,
          FacultyDepartmentID:
            res?.data?.Table[0]?.AdmissionFacultyDepartmentId,
          FacultyDepartmentProgramID:
            res?.data?.Table[0]?.FacultyDepartmentProgramId,
          StudentDetailID: res?.data?.Table[0]?.StudentDetailID,
        };
        dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
        setEnableButtons(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const getSectionsList = (e) => {
  //   const payload = {
  //     ...initialFormFields,
  //     OperationID: 6,
  //   };

  //   PostRequest(ENROLLMENTOPS, payload)
  //     .then((res) => {
  //       let data = {
  //         name: "SectionList",
  //         value: res?.data?.Table,
  //       };
  //       dispatch({
  //         type: SET_INITIAL_DROPDOWN_FORM_STATE,
  //         payload: data,
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  const formPanel = (
    <Fragment>

    </Fragment>
  )


  const handleModalSave = (e) => {
    e.preventDefault();
    const payload = {
      OperationId: Insert,
      EnrollmentID: 0,
      ProgramID:0,
      AcademicYearID: 0,
      EnrollYearID: 0,
      StudentDetailID:0,
      CampusCityID:0,
      FacultyDepartmentID:0,
      FacultyDepartmentProgramID:0,
      PartID:0,
      SetupSectionID:0,
      UserID: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    }

    PostRequest(ENROLLMENTOPS, payload)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              CustomSuccessAlert(res?.data?.Table?.[0]?.Column1);
              getEnrollmentOps();
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Column1);
            }
          })
          .catch((err) => {
            console.error(err);
          });
  }



  const customModal = (
    <Modal
      isOpen={toggle}
      centered
      modalTransition={{ timeout: 10 }}
      backdrop="static"
    >
      <ModalHeader>Add/Edit Department Fee Structure</ModalHeader>
      <ModalBody>
        <form onSubmit={handleModalSave}>
          {formPanel && (
            <Row>
              <Col lg="6" md="6" xs="12">
                
              </Col>
             
           
            </Row>
          )}
        </form>
      </ModalBody>
    </Modal>
  );


  // const formPanel = (
  //   <Fragment>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         list={AcademicYear}
  //         label="Year of Admission"
  //         name="AcademicYearID"
  //         fieldId="SetupDetailId"
  //         fieldName="SetupDetailName"
  //         onChange={handleAddChange}
  //         value={FormFields?.AcademicYearID}
  //         required
  //       />
  //     </Col>
  //     <Col md="4" lg="4">
  //       <FormGroupInput
  //         label="CNIC"
  //         name="Cnic"
  //         isNumber="true"
  //         value={FormFields?.Cnic}
  //         onChange={handleAddChange}
  //       />
  //     </Col>

  //     <Col lg="4" md="4" xs="12" className="verticalAllign">
  //       <Button className="btn" color="primary" onClick={handleSearchClick}>
  //         Search
  //       </Button>
  //     </Col>

  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Campus"
  //         name="CampusID"
  //         onChange={handleAddChange}
  //         value={FormFields?.CampusID}
  //         list={campusList}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         disabled={enableButtons}
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Campus City"
  //         name="CampusCityID"
  //         onChange={handleAddChange}
  //         value={FormFields?.CampusCityID}
  //         list={campusCityList}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         disabled={
  //           enableButtons === false
  //             ? FormFields?.CampusID == null
  //               ? true
  //               : false
  //             : true
  //         }
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department"
  //         name="FacultyDepartymentID"
  //         onChange={async (e) => {
  //           onChange_Select_Department_Program({
  //             operationID: 6,
  //             caseID: 3,
  //             paremeterID: e.target.value,
  //           }).then((res) => {
  //             dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
  //           });
  //           handleAddChange(e);
  //         }}
  //         value={FormFields?.FacultyDepartmentID}
  //         list={SupportingTables?.Departments}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         disabled={enableButtons}
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Faculty Department Program"
  //         name="FacultyDepartmentProgramID"
  //         onChange={handleAddChange}
  //         value={FormFields?.FacultyDepartmentProgramID}
  //         list={SupportingTables?.Programs}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         disabled={
  //           enableButtons === false
  //             ? FormFields?.FacultyDepartymentID == null
  //               ? true
  //               : false
  //             : true
  //         }
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Part"
  //         name="PartID"
  //         onChange={handleAddChange}
  //         value={FormFields?.PartID}
  //         list={PartYear}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         disabled={enableButtons}
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Semester"
  //         name="SemesterID"
  //         onChange={handleAddChange}
  //         value={FormFields?.SemesterID}
  //         list={Semester}
  //         fieldName="SetupDetailName"
  //         fieldId="SetupDetailId"
  //         required
  //         disabled={enableButtons}
  //       />
  //     </Col>
  //     <Col lg="4" md="4" xs="12">
  //       <FormGroupSelect
  //         label="Section"
  //         name="SetupSectionID"
  //         onChange={handleAddChange}
  //         value={FormFields?.SetupSectionID}
  //         list={SupportingTables?.SectionList}
  //         fieldName="SectionCode"
  //         fieldId="SetupSectionID"
  //         required
  //         disabled={enableButtons}
  //       />
  //     </Col>
  //     <Col lg="3" md="3" xs="12">
  //       <FormGroupCheckbox
  //         label=" Is Active"
  //         name="IsActive"
  //         value={FormFields?.IsActive}
  //         onChange={handleAddChange}
  //         disabled={enableButtons}
  //       />
  //     </Col>
  //   </Fragment>
  // );
  const customButton = (
    <Fragment>
      <Button color="primary" onClick={handleModalSave}>
        Process Enrollment
      </Button>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Enrollment Ops"
      buttonName=""
      modalSize="lg"
      tableColumns={columns}
      tableRows={TableList}
      // formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
      customModal={customModal}
      customButton={customButton}

    />
  );
};

export default EnrollmentOps;
