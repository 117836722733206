import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import {
  Delete,
  facultyTypeId,
  qualificationId,
  Search,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  DeleteWithConfirmation,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
} from "../../../redux/actionType/CrudActionTypes";
import {
  Setup_MasterDetails_All_Dropdowns,
  Acad_SetupCourse,
  Setup_Setup_ADM_SeatType,
  PostRequest,
} from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { ACAD_SETUP_FACULTY_RATE } from "../../../utils/UrlConstants";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";

const SetupFacultyRate = () => {
  const initialSearchFields = {
    OperationID: 1,
    SetupFacultyRateID: 0,
    PerRateHour: 0,
    QualificationID: 0,
    CreatedBy: decryptData("loginId", SessionStorage),
    ModifiedBy: 0,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const initialFormFields = {
    OperationID: 2,
    SetupFacultyRateID: 0,
    PerRateHour: 0,
    QualificationID: 0,
    CreatedBy: decryptData("loginId", SessionStorage),
    ModifiedBy: 0,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [QualificationList] = useSetupDetailList(qualificationId);
  const dispatch = useDispatch();

  useEffect(() => {
    getSetupFaculty();
  }, []);

  const getSetupFaculty = () => {
    PostRequest(ACAD_SETUP_FACULTY_RATE, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Qualification", name: "Qualification" },
    { field: "PerHourRate", name: "Per Hour Rate" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Qualification"
          list={QualificationList}
          name="QualificationID"
          onChange={handleSearchChange}
          value={SearchFields?.QualificationID}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...initialSearchFields,
      ...SearchFields,
    };
    PostRequest(ACAD_SETUP_FACULTY_RATE, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      ...initialFormFields,
      ...FormFields,
      OperationID: id,
    };
    PostRequest(ACAD_SETUP_FACULTY_RATE, payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res?.data?.Table[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table[0]?.Message);
          getSetupFaculty();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    let data = {
      QualificationID: obj?.QualificationID,
      PerRateHour: obj?.PerHourRate,
      SetupFacultyRateID: obj?.SetupFacultyRateID,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        let data = {
          OperationID: Delete,
          QualificationID: obj?.QualificationID,
          PerRateHour: obj?.PerHourRate,
          SetupFacultyRateID: obj?.SetupFacultyRateID,
        };
        PostRequest(ACAD_SETUP_FACULTY_RATE, data)
          .then((res) => {
            if (res.data.Table[0].HasError === 0) {
              CustomSuccessAlert(res.data.Table[0].Message);
              getSetupFaculty();
            } else {
              CustomErrorMessage(res.data.Table[0].Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialSearchFields,
    });
    getSetupFaculty();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Qualification"
          list={QualificationList}
          name="QualificationID"
          onChange={handleAddChange}
          value={FormFields?.QualificationID}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Rate Per Hour"
          name="PerRateHour"
          maxLength="5"
          required
          isNumber="true"
          onChange={handleAddChange}
          value={FormFields?.PerRateHour}
          disabled={FormFields?.QualificationID > 0 ? false : true}
        />
      </Col>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Faculty Rate"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
    />
  );
};

export default SetupFacultyRate;
