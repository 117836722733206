import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  formatDateFunction2,
  formatTimeFromDate,
} from "../../../functions/DateFormatFunction";
import {
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";
import { TMS_TMS_Employee_TimeAdjustmentRequest } from "../../../utils/Config";
import { Delete, Search, Select, SessionStorage } from "../../../common/SetupMasterEnum";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  PeriodFrom: "",
  PeriodTo: "",
};
const initialFormFields = { SetupDetailName: "" };

const TimeAdjustment = () => {
  const {
    SearchFields,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    getTimeAdjustmentForEmployees();
  }, []);

  function getTimeAdjustmentForEmployees() {
    const payload = {
      operationId: Select,
      timeAdjustmentRequestId: 0,
      dailyActivityId: 0,
      employeeId: decryptData("EmplId", SessionStorage),
      employeeComment: "string",
      isTimeOutRequest: true,
      originalDateTime: "2022-07-26T18:50:02.465Z",
      requestedDateTime: "2022-07-26T18:50:02.465Z",
      fromDate: "2022-07-26T18:50:02.465Z",
      toDate: "2022-07-26T18:50:02.465Z",
      approvarId: 0,
      approverComment: "string",
      adjustmentReasonId: 0,
      imagePath: "string",
      createdBy: 0,
      modifiedBy: 0,
      isActive: true,
      userIP: "string",
    };
    TMS_TMS_Employee_TimeAdjustmentRequest(payload)
      .then((res) => {

        let data = res.data.Table.map((x) => ({
          ...x,
          CreatedDate: formatDateFunction2(x.CreatedDate),
          RequestedDateTime: formatDateFunction2(x.RequestedDateTime),
          OriginalDateTime1: formatTimeFromDate(x.OriginalDateTime1),
          RequestedDateTime1: formatTimeFromDate(x.RequestedDateTime1),
        }));
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: data,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "CreatedDate", name: "Applied Date" },
    { field: "RequestedDateTime", name: "Applied For" },
    { field: "OriginalDateTime1", name: "Original Time" },
    { field: "RequestedDateTime1", name: "Request Time" },
    { field: "IsTimeOutRequest", name: "Type" },
    { field: "SetupDetailName", name: "Reason" },
    { field: "EmployeeComment", name: "Comment" },
    { field: "Employee", name: "Applied By" },
    { field: "status", name: "Status" },
  ];

  const AllDateSet = (event, type) => {


    if (type === "PeriodFrom") {
      SearchFields.PeriodFrom = event;
      let data1 = {
        name: "PeriodFrom",
        value: SearchFields?.PeriodFrom,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    } else if (type === "PeriodTo") {
      SearchFields.PeriodTo = event;
      let data1 = {
        name: "PeriodTo",
        value: SearchFields?.PeriodTo,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    }
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select From Date</label>
          <DatePicker
            selected={SearchFields?.PeriodFrom}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodFrom")}
            className="form-control"
            name="PeriodFrom"
            placeholderText={dateFormatPlaceholder}
            required
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select To Date</label>
          <DatePicker
            selected={SearchFields?.PeriodTo}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodTo")}
            className="form-control"
            name="PeriodTo"
            required
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      operationId: Search,
      timeAdjustmentRequestId: 0,
      dailyActivityId: 0,
      employeeId: decryptData("EmplId", SessionStorage),
      employeeComment: "string",
      isTimeOutRequest: true,
      originalDateTime: "2022-07-26T18:50:02.465Z",
      requestedDateTime: "2022-07-26T18:50:02.465Z",
      fromDate: SearchFields?.PeriodFrom,
      toDate: SearchFields?.PeriodTo,
      approvarId: 0,
      approverComment: "string",
      adjustmentReasonId: 0,
      imagePath: "string",
      createdBy: 0,
      modifiedBy: 0,
      isActive: true,
      userIP: "string",
    };
    TMS_TMS_Employee_TimeAdjustmentRequest(payload)
      .then((res) => {
  
        if (res.data.Table.length === 0) {
          CustomErrorMessage("No Record Found");
        } else {
          let data = res.data.Table.map((x) => ({
            ...x,
            CreatedDate: formatDateFunction2(x.CreatedDate),
            RequestedDateTime: formatDateFunction2(x.RequestedDateTime),
            OriginalDateTime1: formatTimeFromDate(x.OriginalDateTime1),
            RequestedDateTime1: formatTimeFromDate(x.RequestedDateTime1),
          }));
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: data,
              FormFields: initialFormFields,
              SearchFields: initialSearchFields,
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {};

  const onRevertRow = (obj) => {

    if (obj.status === "Pending") {
      const payload = {
        operationId: Delete,
        timeAdjustmentRequestId: obj.TimeAdjustmentRequestId,
        dailyActivityId: 0,
        employeeId: 0,
        employeeComment: "string",
        isTimeOutRequest: true,
        originalDateTime: "2022-07-26T18:50:02.465Z",
        requestedDateTime: "2022-07-26T18:50:02.465Z",
        fromDate: "2022-07-26T18:50:02.465Z",
        toDate: "2022-07-26T18:50:02.465Z",
        approvarId: 0,
        approverComment: "string",
        adjustmentReasonId: 0,
        imagePath: "string",
        createdBy: 0,
        modifiedBy: decryptData("EmplId", SessionStorage),
        isActive: true,
        userIP: "string",
      };
      TMS_TMS_Employee_TimeAdjustmentRequest(payload)
        .then((res) => {

          if (res.data.Table[0].HassError === 0) {
            CustomSuccessAlert(res.data.Table[0].Message);
            getTimeAdjustmentForEmployees();
          } else {
            CustomErrorMessage("Something went wrong");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      CustomErrorMessage("This Request has been Processed");
    }
  };

  return (
    <CrudFormComponent
      formName="Search"
      tableColumns={columns}
      tableRows={TableList}
      onRevert={onRevertRow}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default TimeAdjustment;
