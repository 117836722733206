import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Input, Label } from "reactstrap";
import {
  academicYearId,
  Search,
  Select,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { SETUP_DATE_OF_ANNOUNCEMENT } from "../../../utils/UrlConstants";
import moment from "moment";

const initialSearchFields = {
  OperationID: Search,
  AcademicYearID: 0,
  DateOfAnnouncement: "2023-08-18T07:33:52.508Z",
  DateOfAnnouncementID: 0,
  IsActive: true,
  CreatedBy: decryptData("loginId", SessionStorage),
  ModifiedBy: decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const initialFormFields = {
  OperationID: Select,
  AcademicYearID: 0,
  DateOfAnnouncement: "2023-08-18T07:33:52.508Z",
  DateOfAnnouncementID: 0,
  IsActive: true,
  CreatedBy: decryptData("loginId", SessionStorage),
  ModifiedBy: decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
};

const RenumerationTaskAssignment = () => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [academicYearList] = useSetupDetailList(academicYearId);

  const dispatch = useDispatch();

  useEffect(() => {
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
  }, []);

  const columns = [
    { field: "AcademicYear", name: "Academic Year" },
    { field: "DateOfAnnouncement", name: "Date Of Announcement" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <Label>
          Announcement Date
          <span className="text-danger">*</span>
        </Label>
        <Input
          name="DateOfAnnouncement"
          type="date"
          onChange={handleSearchChange}
          value={SearchFields?.DateOfAnnouncement}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="IsActive"
          value={SearchFields?.IsActive}
          onChange={handleSearchChange}
        />
      </Col>
    </Fragment>
  );

  const formPanel = (
    <Fragment>
      <Col lg="5" md="5" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          onChange={handleAddChange}
          value={FormFields?.AcademicYearID}
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <Label>
          Announcement Date
          <span className="text-danger">*</span>
        </Label>
        <Input
          name="DateOfAnnouncement"
          type="date"
          onChange={handleAddChange}
          value={FormFields?.DateOfAnnouncement}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="IsActive"
          value={FormFields?.IsActive}
          onChange={handleSearchChange}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    console.log(SearchFields);
    // const payload = {
    //   ...SearchFields,
    //   OperationID: Search,
    // };
    // PostRequest(SETUP_DATE_OF_ANNOUNCEMENT, payload)
    //   .then((res) => {
    //     dispatch({
    //       type: SET_INITIAL_CRUD_FORM_STATE,
    //       payload: {
    //         List: res?.data?.Table,
    //         FormFields: initialFormFields,
    //         SearchFields: initialSearchFields,
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };

  const submitForm = (id) => {
    const payload = {
      ...FormFields,
      OperationID: id,
    };
    PostRequest(SETUP_DATE_OF_ANNOUNCEMENT, payload)
      .then((res) => {
        if (res?.data?.Table[0].HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0].Message);
        } else {
          CustomErrorMessage(res?.data?.Table?.[0].Message);
          return;
        }
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    console.log(obj);
    // let data = {
    //   ...FormFields,
    //   AcademicYearID: obj?.AcademicYearID,
    //   DateOfAnnouncement: formatDateFunction1(obj?.DateOfAnnouncement, "-"),
    //   DateOfAnnouncementID: moment(obj.DateOfAnnouncementID).format("YYYY-MM-DD"),
    //   IsActive: obj?.IsActive,
    // };
    // dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });

    // onChange_Select_Department_Program({
    //   operationID: 6,
    //   caseID: 3,
    //   paremeterID: obj.FacultyDepartmentID,
    // }).then((res) => {
    //   dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
    // });
  };

  const onDeleteRow = (obj) => {
    console.log(obj);
    // DeleteWithConfirmation().then((result) => {
    //   if (result.isConfirmed) {

    //     let data = {
    //       ...FormFields,
    //       OperationID: Delete,
    //       AcademicYearID: obj?.AcademicYearID,
    //       DateOfAnnouncement: formatDateFunction1(obj?.DateOfAnnouncement, "-"),
    //       DateOfAnnouncementID: obj.DateOfAnnouncementID,
    //       IsActive: obj?.IsActive,
    //     };
    //     PostRequest(SETUP_DATE_OF_ANNOUNCEMENT, data)
    //       .then((res) => {
    //         const { err, msg } = apiErrorChecker(res);
    //         if (err) {
    //           CustomErrorMessage(msg);
    //           return;
    //         }
    //         dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
    //         getFacultyDepartmentProgramExams();
    //         CustomSuccessAlert(msg);
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //       });
    //   }
    // });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  return (
    <CrudFormComponent
      formName="Renumeration Task Assignment"
      buttonName="Add"
      modalSize="lg"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
    />
  );
};

export default RenumerationTaskAssignment;
