import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Swal from "sweetalert2";
import { dateFormat, dateFormatPlaceholder } from "../../common/dateFormatEnum";

import { CustomErrorMessage, CustomSuccessAlert, DeleteWithConfirmation } from "../../components/Alert";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import DatePicker from "react-datepicker";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
  RESET_SEARCH_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import {
  Hostel_HostelInstruction,
  Hostel_MangeHostel,
} from "../../utils/Config";
import { formatDateFunction } from "../../functions/DateFormatFunction";
import { decryptData } from "../../EncryptData";
import { SessionStorage } from "../../common/SetupMasterEnum";

const initialSearchFields = {
  OperationID: 0,
  HostelInstructionId: 0,
  InstructionEffectiveFrom: new Date(),
  InstructionEffectiveTo: new Date(),
  Instruction: "",
  IsActive: 0,
  UserId: 0,
};
const initialFormFields = {
  OperationID: 1,
  HostelInstructionId: 0,
  InstructionEffectiveFrom: null,
  InstructionEffectiveTo: null,
  Instruction: "",
  IsActive: 0,
  UserId: 0,
};

const HostelInstruction = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");

  const dispatch = useDispatch();

  const [dropdownList, setDropDownList] = useState([]);
  useEffect(() => {
    manageHostelInstruction();
    // getDropDown();
  }, []);

  const manageHostelInstruction = () => {
    Hostel_HostelInstruction(initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //   function getDropDown() {
  //     const payload = {
  //       operationId: Select,
  //     };

  //     Setup_MasterDetails_All_Dropdowns(payload)
  //       .then((res) => {
  //         setDropDownList(res.data);
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   }
  const columns = [
    { field: "AcademicYearName", name: "Academic Year" },
    { field: "instructionEffectiveFrom", name: "Effective From" },
    { field: "instructionEffectiveTo", name: "Effective To" },
    { field: "Instruction", name: "Instruction" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };
  const AllDateSet = (event, type) => {
    if (type === "FromDateSearch") {
      setSearchFromDate(event);
      let date = formatDateFunction(event, "-");
      SearchFields.InstructionEffectiveFrom = date;
      let data1 = {
        name: "InstructionEffectiveFrom",
        value: SearchFields.InstructionEffectiveFrom,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "ToDateSearch") {
      setSearchToDate(event);
      let date = formatDateFunction(event, "-");
      SearchFields.InstructionEffectiveTo = date;
      let data1 = {
        name: "InstructionEffectiveTo",
        value: SearchFields.InstructionEffectiveTo,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "FromDate") {
      let date = formatDateFunction(event, "-");
      FormFields.InstructionEffectiveFrom = event;
      let data1 = {
        name: "InstructionEffectiveFrom",
        value: FormFields.InstructionEffectiveFrom,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    } else if (type === "ToDate") {
      let date = formatDateFunction(event, "-");
      FormFields.InstructionEffectiveTo = event;
      let data1 = {
        name: "InstructionEffectiveTo",
        value: FormFields.InstructionEffectiveTo,
      };
      dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data1 });
    }
  };
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">
            Effective From<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={searchFromDate}
            onChange={(e) => AllDateSet(e, "FromDateSearch")}
            className="form-control"
            name="InstructionEffectiveFrom"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">
            Effective To<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={searchToDate}
            onChange={(e) => AllDateSet(e, "ToDateSearch")}
            className="form-control"
            name="InstructionEffectiveTo"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Instruction"
          name="Instruction"
          onChange={handleSearchChange}
          value={SearchFields?.Instruction}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      OperationID: 0,
      HostelInstructionId: 0,
      InstructionEffectiveFrom: formatDateFunction(
        SearchFields?.InstructionEffectiveFrom,
        "-"
      ),
      InstructionEffectiveTo: formatDateFunction(
        SearchFields?.InstructionEffectiveTo,
        "-"
      ),
      Instruction: SearchFields?.Instruction,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    Hostel_HostelInstruction(payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const submitForm = (id) => {
    const payload = {
      OperationID: 1,
      HostelInstructionId: FormFields?.HostelInstructionId,
      InstructionEffectiveFrom: FormFields?.InstructionEffectiveFrom,
      InstructionEffectiveTo: FormFields?.InstructionEffectiveTo,
      Instruction: FormFields?.Instruction,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };
    Hostel_HostelInstruction(payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          manageHostelInstruction();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onEditRow = (obj) => {
    const data = {
      OperationID: 1,
      HostelInstructionId: obj?.HostelInstructionId,
      InstructionEffectiveFrom: new Date(obj?.instructionEffectiveFrom),
      InstructionEffectiveTo: new Date(obj?.instructionEffectiveTo),
      Instruction: obj?.Instruction,
      IsActive: 1,
      IsDeleted: 0,
      UserId: decryptData("loginId", SessionStorage),
    };

    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });
  };

  const onDeleteRow = (obj) => {
    DeleteWithConfirmation().then((result) => {
      if (result.isConfirmed) {
        const data = {
          OperationID: 1,
          HostelInstructionId: obj?.HostelInstructionId,
          InstructionEffectiveFrom: obj?.instructionEffectiveFrom,
          InstructionEffectiveTo: obj?.instructionEffectiveTo,
          Instruction: obj?.Instruction,
          IsActive: 1,
          IsDeleted: 1,
          UserId: decryptData("loginId", SessionStorage),
        };

        Hostel_HostelInstruction(data)
          .then((res) => {
            if (res.data.Table[0].HasError === 0) {
              CustomSuccessAlert(res.data.Table[0].Message);
              manageHostelInstruction();
            } else {
              CustomErrorMessage(res.data.Table[0].Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const cancelSearch = () => {
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
    manageHostelInstruction();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <div className="form-group">
          <label className="form-label">
            Effective From<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={FormFields?.InstructionEffectiveFrom}
            onChange={(e) => AllDateSet(e, "FromDate")}
            className="form-control"
            name="InstructionEffectiveFrom"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="6" md="6" xs="12">
        <div className="form-group">
          <label className="form-label">
            Effective To<span className="text-danger">*</span>
          </label>
          <DatePicker
            selected={FormFields?.InstructionEffectiveTo}
            onChange={(e) => AllDateSet(e, "ToDate")}
            className="form-control"
            name="InstructionEffectiveTo"
            required={true}
            showYearDropdown={true}
            dateFormat={dateFormat}
            placeholderText={dateFormatPlaceholder}
          />
        </div>
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Instruction"
          name="Instruction"
          onChange={handleAddChange}
          value={FormFields?.Instruction}
        />
      </Col>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Hostel Instruction"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onEdit={onEditRow}
      onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      handleCancel={handleCancel}
    />
  );
};

export default HostelInstruction;
