import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../common/dateFormatEnum";
import {
  academicYearId,
  correspondanceId,
  districtId,
  domicileProvinceId,
  gender,
  Insert,
  nationalityId,
  religionId,
  Search,
  seatTypeId,
  SessionStorage,
  undergraduateId,
  Update,
} from "../../../common/SetupMasterEnum";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  formatDateFunction,
  formatDateFunction1,
} from "../../../functions/DateFormatFunction";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { Acad_StudentDetailOps } from "../../../utils/Config";
import { decryptData } from "../../../EncryptData";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../../redux/actionType/CrudActionTypes";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import { useParams } from "react-router";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";

const initialFormFields = {
  AcademicYearId: 0,
  StudentDetailID: 0, //decryptData(STUDENT_DETAIL_ID, SessionStorage),
  OperationId: 1,
  Reg_No: 0,
  MajorId: 0,
  AdmissionFacultyTypeId: 0,
  AdmissionFacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  InterestIn: "",
  Year: 0,
  Name: "",
  FatherName: "",
  SurName: "",
  GenderID: 0,
  GuardianName: "",
  Cnic: "",
  DOB:
    // decryptData(STUDENT_DETAIL_ID, SessionStorage) == 0
    // new Date(),
    "2022-02-02",
  PostalAddress: "",
  PermanantAddress: "",
  NationalityID: 0,
  ReligionID: 0,
  DomicileID: 0,
  CorrespondatAddressId: 0,
  DomicileDistricId: 0,
  Ph: "",
  Mobile: "",
  Email: "",
  AlternativeNumber: "", //Newly Added
  LanguageId: 0, //Newly Added
  Other: "",
  WhatsAppNo: "",
  AreEmployeedAnyWhere: false,
  Picture: "",
  LastDegreeFromSAUS: "",
  IsActive: true,
  CreatedBy: 1, //decryptData("loginId", SessionStorage),
  ModifiedBy: 1, //decryptData("loginId", SessionStorage),
  UserIP: decryptData(UserNetworkInfo)?.IPv4,
  FileName: "",
  SeatTypeId: 0,
};

const StudentDetails = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [seatTypeList] = useSetupDetailList(seatTypeId);

  useEffect(() => {
    onChange_Select_Department_Program({
      operationID: Search,
      caseID: 1,
      paremeterID: undergraduateId,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    if (params?.id !== "new") {
      getStudentList(params.id);
    }
  }, []);
  const [fileName, setFileName] = useState("");
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const [formFields, setFormFields] = useState({
    ...initialFormFields,
  });
  const [updateButton, setUpdateButton] = useState(false);

  const [profileImage, setProfileImage] = useState();
  const handleInputChange = (event) => {
    if (event.target.type == "radio") {
      if (event.target.value == "1") {
        setFormFields({
          ...formFields,
          AreEmployeedAnyWhere: true,
        });
      } else {
        setFormFields({
          ...formFields,
          AreEmployeedAnyWhere: false,
        });
      }
    } else if (event.target.name === "MajorId") {
      onChange_Select_Department_Program({
        operationID: Search,
        caseID: 2,
        paremeterID: event.target.value,
      }).then((res) =>
        dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
      );
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "AdmissionFacultyDepartmentId") {
      onChange_Select_Department_Program({
        operationID: Search,
        caseID: 3,
        paremeterID: event.target.value,
      }).then((res) =>
        dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
      );
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "DomicileID") {
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
      });
    } else {
      setFormFields({
        ...formFields,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleImageUpload = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
    setFileName(event.target.files[0].name);
    setFormFields({
      ...formFields,
      Picture: event.target.files[0],
      FileName: event.target.files[0].name,
    });
  };

  const AllDateSet = (event, type) => {
    setFormFields({
      ...formFields,
      DOB: formatDateFunction(event, "-"),
    });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (formFields.DOB == null) {
      CustomErrorMessage("Please Enter Date of Birth");
    } else {
      setUpdateButton(true);
      formFields.OperationId = params?.id === "new" ? Insert : Update;

      let formData = new FormData();
      Object.keys(formFields).forEach((d) => {
        formData.append(d, formFields[d]);
      });
      updateAdmission(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    Acad_StudentDetailOps(payload)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table[0]?.Column1);
          if (params?.id === "new") {
            props.history.push(`/pages/academics/enrollment/studentlist`);
          }
        } else {
          CustomErrorMessage(res?.data?.Table[0]?.Column1);
          setUpdateButton(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        setUpdateButton(false);
      });
  };

  function getStudentList(id) {
    let payload = {
      ...initialFormFields,
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: decryptData("loginId", SessionStorage),
      UserIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    let formData = new FormData();
    Object.keys(payload).forEach((d) => {
      if (d === "StudentDetailID") formData.append(d, id);
      else formData.append(d, initialFormFields[d]);
    });
    Acad_StudentDetailOps(formData)
      .then((res) => {
        let data = {
          name: "studentList",
          value: res?.data?.Table[0],
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        setFormFields({
          ...initialFormFields,
          OperationId: 3,
          AcademicYearId: res?.data?.Table?.[0]?.AcademicYearId,
          StudentDetailID: res?.data?.Table?.[0]?.StudentDetailID,
          Reg_No: res?.data?.Table?.[0]?.Reg_No,
          MajorId: res?.data?.Table?.[0]?.MajorId,
          AdmissionFacultyTypeId: res?.data?.Table?.[0]?.AdmissionFacultyTypeId,
          AdmissionFacultyDepartmentId:
            res?.data?.Table?.[0]?.AdmissionFacultyDepartmentId,
          FacultyDepartmentProgramId:
            res?.data?.Table?.[0]?.FacultyDepartmentProgramId,
          InterestIn: res?.data?.Table?.[0]?.InterestIn,
          Year: res?.data?.Table?.[0]?.Year,
          Name: res?.data?.Table?.[0]?.Name,
          FatherName: res?.data?.Table?.[0]?.FatherName,
          SurName: res?.data?.Table?.[0]?.SurName,
          GenderID: res?.data?.Table?.[0]?.GenderID,
          GuardianName: res?.data?.Table?.[0]?.GuardianName,
          Cnic: res?.data?.Table?.[0]?.Cnic,
          DOB: formatDateFunction(res?.data?.Table?.[0]?.DOB, "-"),
          PostalAddress: res?.data?.Table?.[0]?.PostalAddress,
          PermanantAddress: res?.data?.Table?.[0]?.PermanantAddress,
          NationalityID: res?.data?.Table?.[0]?.NationalityID,
          ReligionID: res?.data?.Table?.[0]?.ReligionID,
          DomicileID: res?.data?.Table?.[0]?.DomicileID,
          CorrespondatAddressId: res?.data?.Table?.[0]?.CorrespondatAddressId,
          DomicileDistricId: res?.data?.Table?.[0]?.DomicileDistricId,
          Ph: res?.data?.Table?.[0]?.Ph,
          Mobile: res?.data?.Table?.[0]?.Mobile,
          Email: res?.data?.Table?.[0]?.Email,
          AlternativeNumber: res?.data?.Table?.[0]?.AlternativeNumber, //Newly Added
          LanguageId: res?.data?.Table?.[0]?.LanguageId, //Newly Added
          Other: res?.data?.Table?.[0]?.Other,
          WhatsAppNo: res?.data?.Table?.[0]?.WhatsAppNo,
          AreEmployeedAnyWhere: false,
          Picture: res?.data?.Table?.[0]?.Picture,
          LastDegreeFromSAUS: res?.data?.Table?.[0]?.LastDegreeFromSAUS,
          SeatTypeId: res?.data?.Table?.[0]?.SeatTypeId,
          IsActive: true,
          CreatedBy: decryptData("loginId", SessionStorage),
          ModifiedBy: decryptData("loginId", SessionStorage),
          UserIP: decryptData(UserNetworkInfo)?.IPv4,
          // FileName: "",
        });
        onChange_Select_Department_Program({
          operationID: Search,
          caseID: 2,
          paremeterID: res?.data?.Table?.[0]?.MajorId,
        }).then((res) =>
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
        );
        onChange_Select_Department_Program({
          operationID: Search,
          caseID: 3,
          paremeterID: res?.data?.Table?.[0]?.AdmissionFacultyDepartmentId,
        }).then((res) =>
          dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <Fragment>
      <Container fluid>
        <form onSubmit={onUpdate}>
          <Card className="mt-3">
            <CardTitle>Student Details</CardTitle>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col md="9">
                  <Row>
                    <Col md="4" lg="4">
                      <FormGroupInput
                        label="Reg #"
                        name="Reg_No"
                        value={formFields?.Reg_No}
                        onChange={handleInputChange}
                        // isNumber="true"
                        required
                      />
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupInput
                        label="CNIC Number"
                        name="Cnic"
                        value={formFields?.Cnic}
                        onChange={handleInputChange}
                        isNumber="true"
                        maxLength="13"
                        minLength="13"
                        required
                        disabled={params?.id !== "new" ? true : false}
                      />
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupSelect
                        label="Academic Year"
                        name="AcademicYearId"
                        list={academicYearList}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={formFields?.AcademicYearId}
                        onChange={handleInputChange}
                        required
                      />
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupSelect
                        label="Gender"
                        name="GenderID"
                        list={SupportingTables?.MasterDropdown?.filter(
                          (x) => x.SetupMasterId == gender
                        )}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={formFields?.GenderID}
                        onChange={handleInputChange}
                        required
                      />
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupInput
                        label="Name"
                        name="Name"
                        value={formFields?.Name}
                        onChange={handleInputChange}
                        isAlphabetic="true"
                        required
                      />
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupInput
                        label="Father's Name"
                        name="FatherName"
                        value={formFields?.FatherName}
                        onChange={handleInputChange}
                        isAlphabetic="true"
                        required
                      />
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupInput
                        label="Surname"
                        name="SurName"
                        value={formFields?.SurName}
                        onChange={handleInputChange}
                        isAlphabetic="true"
                      />
                    </Col>

                    <Col md="4" lg="4">
                      <FormGroupInput
                        label="Guardian's Name (Other than Father)"
                        name="GuardianName"
                        value={formFields?.GuardianName}
                        onChange={handleInputChange}
                        isAlphabetic="true"
                      />
                    </Col>

                    <Col lg="4" md="4" xs="12">
                      <div className="form-group">
                        <label className="form-label">
                          Date of Birth<span className="text-danger">*</span>
                        </label>
                        <Input
                          type="date"
                          className="form-control"
                          name="DOB"
                          value={formFields.DOB}
                          onChange={handleInputChange}
                          // onChange={(e) => AllDateSet(e, "DateOfBirth")}
                          placeholderText={dateFormatPlaceholder}
                        />
                        {/* <DatePicker
                          selected={formFields?.DOB}
                          onChange={(e) => AllDateSet(e, "DateOfBirth")}
                          className="form-control"
                          name="DOB"
                          // peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormat={dateFormat}
                          dropdownMode="select"
                          placeholderText={dateFormatPlaceholder}
                        /> */}
                      </div>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupSelect
                        label="Nationality"
                        name="NationalityID"
                        list={SupportingTables?.MasterDropdown?.filter(
                          (x) => x.SetupMasterId == nationalityId
                        )}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={formFields?.NationalityID}
                        onChange={handleInputChange}
                        required
                      />
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroupSelect
                        label="Religion"
                        name="ReligionID"
                        list={SupportingTables?.MasterDropdown?.filter(
                          (x) => x.SetupMasterId == religionId
                        )}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={formFields?.ReligionID}
                        onChange={handleInputChange}
                        required
                      />
                    </Col>
                    <Col md="8" lg="8">
                      <FormGroupInput
                        label="Current Address"
                        name="PostalAddress"
                        value={formFields?.PostalAddress}
                        onChange={handleInputChange}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md="3" className="text-center">
                  <Row>
                    <Col>
                      <div className="alert alert-warning mb-2">
                        <strong>
                          Please upload passport size photo. Do not upload
                          selfies
                        </strong>
                      </div>
                      <div className="profile-info text-center mb-3">
                        <div className="profile-img-wrap">
                          <img
                            src={
                              profileImage !== undefined
                                ? profileImage
                                : formFields?.Picture
                            }
                            className=""
                            width={150}
                            height={150}
                            alt={formFields?.Name}
                            name="image"
                          />
                          <div className="fileupload">
                            <p className="btn-text-edit">
                              <i className="fa fa-pencil"></i>
                            </p>
                            <Input
                              className="upload"
                              type="file"
                              accept="image/*"
                              color="default"
                              name="Picture"
                              onChange={handleImageUpload}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md="6" lg="6">
                  <FormGroupInput
                    label="Permanent Address"
                    name="PermanantAddress"
                    value={formFields?.PermanantAddress}
                    onChange={handleInputChange}
                    required
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Correspondence Address"
                    name="CorrespondatAddressId"
                    list={SupportingTables?.MasterDropdown?.filter(
                      (x) => x.SetupMasterId == correspondanceId
                    )}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={formFields?.CorrespondatAddressId}
                    onChange={handleInputChange}
                    required
                  />
                </Col>

                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Domicile Province"
                    name="DomicileID"
                    list={SupportingTables?.MasterDropdown?.filter(
                      (x) => x.SetupMasterId == domicileProvinceId
                    )}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={formFields?.DomicileID}
                    onChange={handleInputChange}
                    required
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Domicile District"
                    name="DomicileDistricId"
                    list={SupportingTables?.MasterDropdown?.filter(
                      (x) =>
                        x.SetupMasterId == districtId &&
                        x.SetupDetailId != 5615 &&
                        x.SetupDetailId != 5617 &&
                        x.SetupDetailId != 5618 &&
                        x.SetupDetailId != 5619 &&
                        x.SetupDetailId != 5620 &&
                        x.SetupDetailId != 5621
                    )}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={
                      formFields?.DomicileID === 3603
                        ? formFields?.DomicileDistricId
                        : 5616
                    }
                    onChange={handleInputChange}
                    required
                    disabled={formFields?.DomicileID !== 3603 ? true : false}
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupInput
                    label="Residence Number"
                    name="Ph"
                    value={formFields?.Ph}
                    onChange={handleInputChange}
                    isNumber="true"
                    maxLength="25"
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupInput
                    label="Mobile No"
                    name="Mobile"
                    value={formFields?.Mobile}
                    onChange={handleInputChange}
                    isNumber="true"
                    maxLength="25"
                    required
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupInput
                    label="Email Address"
                    name="Email"
                    value={formFields?.Email}
                    type="email"
                    onChange={handleInputChange}
                    disabled={params?.id !== "new" ? true : false}
                  />
                </Col>
                <Col lg="3" md="3" xs="12">
                  <div className="form-group">
                    <Label>
                      Are You Employed anywhere?
                      <span className="text-danger">*</span>
                    </Label>
                    <div className="form-control" onChange={handleInputChange}>
                      <Input
                        type="radio"
                        value={1}
                        name="AreEmployeedAnyWhere"
                        checked={formFields.AreEmployeedAnyWhere == 1}
                      />
                      Yes
                      <Input
                        type="radio"
                        value={0}
                        name="AreEmployeedAnyWhere"
                        checked={formFields.AreEmployeedAnyWhere == 0}
                      />
                      No
                    </div>
                  </div>
                </Col>
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Seat Type"
                    name="SeatTypeId"
                    value={formFields?.SeatTypeId}
                    onChange={handleInputChange}
                    list={seatTypeList}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    required
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardTitle>Academic Details</CardTitle>
            <CardBody>
              <Row>
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Intermediate Majors"
                    name="MajorId"
                    list={SupportingTables?.MajorIds?.Table}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={formFields?.MajorId}
                    disabled={params?.id !== "new" ? true : false}
                    onChange={handleInputChange}
                    form="Form1"
                    required
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Faculty Department"
                    name="AdmissionFacultyDepartmentId"
                    list={SupportingTables?.Departments}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={formFields?.AdmissionFacultyDepartmentId}
                    disabled={params?.id !== "new" ? true : false}
                    onChange={handleInputChange}
                    form="Form1"
                    required
                  />
                </Col>
                <Col md="3" lg="3">
                  <FormGroupSelect
                    label="Faculty Department Program"
                    name="FacultyDepartmentProgramId"
                    list={SupportingTables?.Programs}
                    fieldId="SetupDetailId"
                    fieldName="SetupDetailName"
                    value={formFields?.FacultyDepartmentProgramId}
                    disabled={params?.id !== "new" ? true : false}
                    onChange={handleInputChange}
                    form="Form1"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-right">
                    <Button color="primary" type="submit">
                      {params?.id !== "new" ? "Update" : "Save"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </Container>
    </Fragment>
  );
};

export default StudentDetails;
