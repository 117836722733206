import React, { Fragment, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import {
  academicYearId,
  campusCity,
  campusType,
  partYearID,
  Select,
  semesterId,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import {
  AlreadyExistAlert,
  CustomErrorMessage,
  SuccessAlert,
} from "../../../components/Alert";
import FormGroupCheckbox from "../../../components/GeneralComponent/FormGroupCheckbox";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import useFade from "../../../Hooks/useFade";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { RESET_FORM_FIELDS } from "../../../redux/actionType/AuthType";
import {
  RESET_SEARCH_FIELDS,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  SET_MULTI_CRUD_FORM_FIELD,
} from "../../../redux/actionType/CrudActionTypes";
import {
  ADM_EligibilityCriteriaDependency,
  PostRequest,
} from "../../../utils/Config";
import { STATUS_TYPE } from "../../../utils/Constants";
import { ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, ENROLLED_STUDENT_ACADEMIC_RECORD } from "../../../utils/UrlConstants";

const initialSearchFields = {
  operationId: 1,
  setupDegreeParamID: null,
  campusID: null,
  campusCityID: null,
  facultyDepartymentID: null,
  facultyDepartmentProgramID: null,
  degree: "",
  creditHRS: null,
  isActive: true,
  userId: decryptData("EmplId", SessionStorage),
  userIP: "192.168.1.104",
  facultyDepartmentId: null,
  facultyDepartmentProgramId: null,
  minCGPA: null,
};
const initialFormFields = {
  operationId: null,
  userId: null,
  userIP: "",
  enrolledStudentAcademicRecordMasterID: null,
  cnic: "",
  partId: null,
  sectionId: null,
  semesterId: null,
  statusId: null,
  acad_EnrolledStudentAcademicRecordMaster: [],
  acad_EnrolledStudentAcademicRecordDetail: [],
  facultyDepartmentProgramId: null,
  facultyDepartmentId: null,
};

const EnrolledStudentAcademicRecordMaster = {
  academicYearID: null,
  enrollmentDetailID: null,
  partID: null,
  semesterID: null,
  takeStatusTypeID: null,
  statusID: null,
  randomId: "",
};

const EnrolledStudentAcademicRecordDetail = {
  enrollmentDetailID: null,
  statusTypeID: null,
  midTermExamMarks: null,
  finalExamMarks: null,
  cpMarks: null,
  isSaved: true,
  isPassed: true,
  isLockByLecturer: false,
  isLockByExamController: false,
  courseSemesterFacDeptProgLectMappingID: null,
  randomId: "",
};

const EnrolledStudentAcademicRecord = () => {
  const { SearchFields, FormFields, TableList, SupportingTables } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const [showSupply, setShowSupply, fadeOutProps] = useFade(false, 150);
  const [showNormal, setShowNormal, fadeOutPropsNormal] = useFade(false, 150);
  const [newModal, setNewModal] = useState(false);
  const [cnicSearch, setCnicSearch] = useState(null);
  const [listing, setListing] = useState({
    statusList: [],
  });
  const [
    enrolledStudentAcademicRecordMaster,
    setEnrolledStudentAcademicRecordMaster,
  ] = useState(EnrolledStudentAcademicRecordMaster);
  const [
    enrolledStudentAcademicRecordDetail,
    setEnrolledStudentAcademicRecordDetail,
  ] = useState(EnrolledStudentAcademicRecordDetail);

  const [masterArray, setMasterArray] = useState([]);
  const [list, setList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [facultyDeptList, setFacultyDeptList] = useState([]);
  const [facultyDeptProgList, setFacultyDeptProgList] = useState([]);

  const [studentList, setStudentList] = useState([]);
  const [studentListLoading, setStudentListLoading] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [campusCityList] = useSetupDetailList(campusCity, FormFields?.campusID);
  const [campusList] = useSetupDetailList(campusType);
  const [yearList] = useSetupDetailList(academicYearId);
  const [semesterList] = useSetupDetailList(semesterId);
  const [partList] = useSetupDetailList(partYearID);

  const dispatch = useDispatch();

  const getFacultyDeptProgList = (payload) => {
    // ADM_EligibilityCriteriaDependency(payload)
    PostRequest(ADM_ELIGIBILITY_CRITERIA_DEPENDENCY, payload)
      .then((res) => {
        if (payload.caseID === 2) {
          setFacultyDeptList(res.data.Table);
        } else {
          setFacultyDeptProgList(res.data.Table);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: initialFormFields });
    getEnrollments();
    // onChange_Select_Department_Program({
    //   operationID: 6,
    //   caseID: 2,
    //   paremeterID: 0
    // }).then((res) => {
    //   dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
    // });
    getFacultyDeptProgList({
      operationID: 6, //Search,
      caseID: 2,
      paremeterID: 0,
    });
    return () => {
      clearTimeout(ref1.current);
      clearTimeout(ref2.current);
    };
  }, []);

  function getEnrollments() {
    const payload = {
      ...initialFormFields,
      operationId: Select,
      userId: decryptData("EmplId", SessionStorage),
      userIP: "192.168.1.104",
    };

    PostRequest(ENROLLED_STUDENT_ACADEMIC_RECORD, payload)
      .then((res) => {
        setList(res.data.Table);
        setListing({ statusList: res.data.Table1 });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "AcademicYear", name: "Campus" },
    { field: "SemesterName", name: "Campus City" },
    { field: "FacultyDepartyment", name: "Faculty Department" },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
    { field: "StudentName", name: "CreditHRS" },
    { field: "StatusName", name: "Degree" },
    { field: "MinCGPA", name: "CGPA" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
    if (e.target.name === "statusId") {
      selectStatus(e.target.value);
    }
  };

  const selectStatus = (value) => {
    let obj = listing?.statusList?.find((x) => x.StatusTypeID === value);
    if (obj.Flex === STATUS_TYPE.Normal) {
      setShowSupply(false);
      ref1.current = setTimeout(() => setShowNormal(true), 151);
    } else if (obj.Flex === STATUS_TYPE.Supply) {
      setShowNormal(false);
      ref2.current = setTimeout(() => setShowSupply(true), 151);
    }
    setStudentList([]);
    setCourseList([]);
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Campus"
          name="campusID"
          onChange={handleSearchChange}
          value={SearchFields?.campusID}
          list={campusList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Campus City"
          name="campusCityID"
          onChange={handleSearchChange}
          value={SearchFields?.campusCityID}
          list={campusCityList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          disabled={SearchFields?.campusID === null}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="facultyDepartymentID"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.facultyDepartymentID}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="facultyDepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.facultyDepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          disabled={SearchFields?.facultyDepartymentID == null}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Credit Hours"
          name="creditHRS"
          type={"number"}
          onChange={handleSearchChange}
          value={SearchFields?.creditHRS}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="Degree"
          type={"text"}
          name="degree"
          onChange={handleSearchChange}
          value={SearchFields?.degree}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          label="CGPA"
          type={"number"}
          name="minCGPA"
          onChange={handleSearchChange}
          value={SearchFields?.minCGPA}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupCheckbox
          label="Is Active"
          name="isActive"
          onChange={handleSearchChange}
          value={SearchFields?.isActive}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = (id) => {
    const payload = {
      ...SearchFields,
      operationId: id,
    };

    PostRequest(ENROLLED_STUDENT_ACADEMIC_RECORD, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    let masterArray = [];
    let detailArray = [];
    for (let a = 0; a <= studentList.length - 1; a++) {
      if (studentList[a].checked === true)
        masterArray.push({
          academicYearID: FormFields.academicYearId,
          enrollmentDetailID: studentList[a].EnrollmentDetailID,
          partID: FormFields.partId,
          semesterID: FormFields.semesterId,
          takeStatusTypeID: FormFields?.statusId,
          statusID: FormFields?.statusId,
          randomId: null,
        });
    }
    for (let a = 0; a <= courseList.length - 1; a++) {
      if (courseList[a].checked === true)
        detailArray.push({
          enrollmentDetailID: 0,
          statusTypeID: FormFields?.statusId,
          midTermExamMarks: 0,
          finalExamMarks: 0,
          cpMarks: 0,
          isSaved: false,
          isPassed: false,
          isLockByLecturer: false,
          isLockByExamController: false,
          isLockByHod: false,
          courseSemesterFacDeptProgLectMappingID:
            courseList[a].CourseSemesterFacDeptProgLectMappingID,
          randomId: null,
          courseSemesteMappingID: courseList[a].CourseSemesteMappingID,
          CourseSemesterLecturerMappingId:
            courseList[a].CourseSemesterLecturerMappingId,
        });
    }

    const payload = {
      ...FormFields,
      operationId: id,
      userId: decryptData("EmplId", SessionStorage),
      userIP: "192.168.1.104",
      acad_EnrolledStudentAcademicRecordMaster: masterArray,
      acad_EnrolledStudentAcademicRecordDetail: detailArray,
    };

    PostRequest(ENROLLED_STUDENT_ACADEMIC_RECORD, payload)
      .then((res) => {
        if (res?.data?.Table[0]?.HasError === 0) {
          SuccessAlert();
          getEnrollments();
          toggleNewForm();
        } else {
          AlreadyExistAlert();
          return;
        }
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getEnrollments();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const searchCnic = () => {
    if (searchCnic === null) {
      CustomErrorMessage("Please Enter Roll Number First");
      return;
    }
    let data = { ...FormFields, cnic: cnicSearch, operationId: 6 };
    PostRequest(ENROLLED_STUDENT_ACADEMIC_RECORD, data)
      .then((res) => {
        setStudentList(res?.data?.Table);
        setCourseList(res?.data?.Table1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleNewForm = () => {
    setNewModal(false);
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: TableList,
        FormFields: initialFormFields,
        SearchFields: initialSearchFields,
      },
    });
    setCourseList([]);
    setStudentList([]);
  };

  const handleToggleStudent = (e) => {
    e.preventDefault();
    if (!studentModal) {
      setStudentListLoading(true);
      PostRequest(ENROLLED_STUDENT_ACADEMIC_RECORD, {
        ...FormFields,
        operationId: 7,
      })
        .then((res) => {
          setStudentListLoading(false);
          setStudentList(res.data.Table.map((x) => ({ ...x, checked: false })));
          setCourseList(res.data.Table1.map((x) => ({ ...x, checked: false })));
        })
        .catch((err) => setStudentListLoading(false));
    }
    // if (studentModal === true) {
    //   dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: initialFormFields });
    // }
    setStudentModal(!studentModal);
  };

  const editRecord = (obj, index) => {
    let payload = {
      ...initialFormFields,
      enrolledStudentAcademicRecordMasterID:
        obj.EnrolledStudentAcademicRecordMasterID,
      operationId: 9,
    };
    PostRequest(ENROLLED_STUDENT_ACADEMIC_RECORD, payload)
      .then((res) => {
        let courses = res.data.Table1.map((x) => ({
          ...x,
          CourseName: x.courseName,
          CourseCode: x.courseCode,
          CreditHours: x.creditHours,
        }));
        let student = res.data.Table.map((x) => ({ ...x, checked: true }));
        selectStatus(res.data.Table?.[0]?.StatusID);
        setStudentList(student);
        setCourseList(courses);
        dispatch({
          type: SET_CRUD_FROM_FIELDS,
          payload: {
            name: "academicYearId",
            value: res.data.Table?.[0]?.AcademicYearID,
          },
        });
        dispatch({
          type: SET_MULTI_CRUD_FORM_FIELD,
          payload: {
            enrolledStudentAcademicRecordMasterID:
              res.data.Table?.[0]?.EnrolledStudentAcademicRecordMasterID,
            partId: res.data.Table?.[0]?.PartID,
            semesterId: res.data.Table?.[0]?.SemesterID,
            statusId: res.data.Table?.[0]?.StatusID,
          },
        });
        setNewModal(true);
      })
      .catch((err) => setStudentListLoading(false));
  };

  return (
    <div style={{ padding: 10 }}>
      <Card>
        <CardTitle>Search Registered Courses</CardTitle>
        <CardBody>
          <form onSubmit={submitSearch}>
            <Row>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Part"
                  name="partId"
                  onChange={handleAddChange}
                  value={FormFields?.partId}
                  list={partList}
                  fieldName="SetupDetailName"
                  fieldId="SetupDetailId"
                  required
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Semester"
                  name="semesterId"
                  onChange={handleAddChange}
                  value={FormFields?.semesterId}
                  list={semesterList}
                  fieldName="SetupDetailName"
                  fieldId="SetupDetailId"
                  required
                  disabled={FormFields?.partId === null}
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Section"
                  name="sectionId"
                  onChange={handleAddChange}
                  value={FormFields?.sectionId}
                  list={sectionList}
                  fieldName="SectionName"
                  fieldId="SetupSectionID"
                  required
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  label="Status"
                  name="statusId"
                  onChange={handleAddChange}
                  value={FormFields?.statusId}
                  list={listing.statusList}
                  fieldName="Status"
                  fieldId="StatusTypeID"
                  required
                />
              </Col>
              <Col lg="12" md="12" xs="12" className="text-right">
                <Button color="primary" className="btn" type="submit">
                  Search
                </Button>
                <Button className="btn" color="default" onClick={cancelSearch}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="6" xs="12">
              Registered Courses List
            </Col>
            <Col lg="6" md="6" xs="12" className="text-right">
              <Button
                className="btn"
                color="primary"
                type="button"
                onClick={() => setNewModal(true)}
              >
                Add Course Registration
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Row style={{ padding: 15 }}>
            <table className="table table-striped mb-0 mt-2">
              <thead>
                <tr>
                  <th>Academic Year</th>
                  <th>Semester</th>
                  <th>Student</th>
                  <th>Status Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.AcademicYear}</td>
                    <td>{obj.SemesterName}</td>
                    <td>{obj.StudentName}</td>
                    <td>{obj.StatusName}</td>
                    <td>
                      <Button onClick={() => editRecord(obj, index)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <FormGroupTable columns={columns} rows={list} /> */}
          </Row>
        </CardBody>
      </Card>

      {/* New Enrollment Modal */}
      <Modal
        isOpen={newModal}
        centered
        toggle={toggleNewForm}
        modalTransition={{ timeout: 10 }}
        style={{ minWidth: "98vw", width: "100%", margin: "2vh" }}
      >
        <ModalHeader>Add/Edit Registration Courses</ModalHeader>
        <ModalBody>
          <div style={{ height: "82vh" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitForm(2);
              }}
            >
              {/* Basic Information Card */}
              <Card>
                <CardHeader>
                  <b>Basic Information</b>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        label="Year"
                        name="academicYearId"
                        onChange={handleAddChange}
                        value={FormFields?.academicYearId}
                        list={yearList}
                        fieldName="SetupDetailName"
                        fieldId="SetupDetailId"
                        required
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        label="Faculty Department"
                        name="facultyDepartmentId"
                        onChange={(e) => {
                          handleAddChange(e);
                          getFacultyDeptProgList({
                            operationID: 6, //Search,
                            caseID: 3,
                            paremeterID: e.target.value,
                          });
                        }}
                        value={FormFields?.facultyDepartmentId}
                        list={facultyDeptList}
                        fieldName="SetupDetailName"
                        fieldId="SetupDetailId"
                        required
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        label="Faculty Department Program"
                        name="facultyDepartmentProgramId"
                        onChange={(e) => {
                          handleAddChange(e);
                          PostRequest(ENROLLED_STUDENT_ACADEMIC_RECORD, {
                            ...FormFields,
                            operationId: 8,
                            facultyDepartmentProgramId: e.target.value,
                          })
                            .then((res) => {
                              setSectionList(res.data.Table);
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                        value={FormFields?.facultyDepartmentProgramId}
                        list={facultyDeptProgList}
                        fieldName="SetupDetailName"
                        fieldId="SetupDetailId"
                        required
                        disabled={FormFields?.facultyDepartmentId === null}
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        label="Part"
                        name="partId"
                        onChange={handleAddChange}
                        value={FormFields?.partId}
                        list={partList}
                        fieldName="SetupDetailName"
                        fieldId="SetupDetailId"
                        required
                        // disabled={FormFields?.yearId > 0 || true}
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        label="Section"
                        name="sectionId"
                        onChange={handleAddChange}
                        value={FormFields?.sectionId}
                        list={sectionList}
                        fieldName="SectionName"
                        fieldId="SetupSectionID"
                        required
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        label="Semester"
                        name="semesterId"
                        onChange={handleAddChange}
                        value={FormFields?.semesterId}
                        list={semesterList?.filter(
                          (x) => ![1]?.includes(x.flex2)
                        )}
                        fieldName="SetupDetailName"
                        fieldId="SetupDetailId"
                        required
                        // disabled={FormFields?.partId === null}
                      />
                    </Col>
                    <Col lg="3" md="3" xs="12">
                      <FormGroupSelect
                        label="Status Type"
                        name="statusId"
                        onChange={handleAddChange}
                        value={FormFields?.statusId}
                        list={[
                          {
                            id: listing?.statusList?.find(
                              (x) => x.Flex === "Normal"
                            )?.StatusTypeID,
                            name: "Normal",
                          },
                          {
                            id: listing?.statusList?.find(
                              (x) => x.Flex === "Suppli"
                            )?.StatusTypeID,
                            name: "Suppli",
                          },
                        ]}
                        fieldName="name"
                        fieldId="id"
                        required
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* Normal Courses */}
              {showNormal && (
                <Card {...fadeOutPropsNormal}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <b>Normal Courses</b>
                      {FormFields?.enrolledStudentAcademicRecordMasterID ===
                        null && (
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#833a58",
                            // ...':hover : backgroundColor: gray'
                          }}
                          type="submit"
                          onClick={handleToggleStudent}
                        >
                          <b>+ Add Students</b>
                          <Badge
                            style={{
                              position: "absolute",
                              color: "white",
                              top: 5,
                              padding: 5,
                            }}
                            color="primary"
                          >
                            {studentList.filter((x) => x.checked === true)
                              .length > 0 && (
                              <b>
                                {
                                  studentList.filter((x) => x.checked === true)
                                    .length
                                }
                              </b>
                            )}
                          </Badge>
                        </Button>
                      )}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <input
                              type={"checkbox"}
                              defaultChecked={false}
                              onChange={(e) => {
                                let list = courseList.map((x) => ({
                                  ...x,
                                  checked: e.target.checked,
                                }));
                                setCourseList(list);
                              }}
                            />{" "}
                            Select
                          </th>
                          <th>Course Code</th>
                          <th>Course Name</th>
                          <th>Course Credit Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList.map((x, i) => (
                          <tr key={i}>
                            <td>
                              <input
                                type={"checkbox"}
                                checked={x.checked}
                                onChange={(e) => {
                                  courseList[i].checked = e.target.checked;
                                  setCourseList([...courseList]);
                                }}
                              />
                            </td>
                            <td>{x.CourseCode}</td>
                            <td>{x.CourseName}</td>
                            <td>{x.CreditHours}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Button type="submit">Save Normal Courses</Button>
                  </CardBody>
                </Card>
              )}

              {/* Supply / Improvement Courses */}
              {showSupply && (
                <Card {...fadeOutProps}>
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <b>Supply / Improvement Courses</b>
                      <div style={{ display: "flex" }}>
                        <FormGroupInput
                          placeholder={"Search Roll Number"}
                          value={cnicSearch}
                          onChange={(e) => setCnicSearch(e.target.value)}
                        />
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#833a58",
                          }}
                          type="button"
                          onClick={searchCnic}
                        >
                          <b>Search</b>
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <input
                              type={"checkbox"}
                              onChange={(e) => {
                                let list = courseList.map((x) => ({
                                  ...x,
                                  checked: e.target.checked,
                                }));
                                setCourseList(list);
                              }}
                            />{" "}
                            Select
                          </th>
                          <th>Course Code</th>
                          <th>Course Name</th>
                          <th>Course Credit Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList.map((x, i) => (
                          <tr>
                            <td>
                              <input
                                type={"checkbox"}
                                checked={x.checked}
                                onChange={(e) => {
                                  courseList[i].checked = e.target.checked;
                                  setCourseList([...courseList]);
                                }}
                              />
                            </td>
                            <td>{x.CourseCode}</td>
                            <td>{x.CourseName}</td>
                            <td>{x.CreditHours}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Button type="submit">Save Improve / Suppli Courses</Button>
                  </CardBody>
                </Card>
              )}
            </form>
          </div>

          {/* Save or Cancel Button */}
          <Row>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="default" onClick={toggleNewForm}>
                Cancel
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>

      {/* Student List Modal */}
      <Modal
        centered
        toggle={handleToggleStudent}
        isOpen={studentModal}
        size="lg"
      >
        <ModalHeader>Student List</ModalHeader>
        <ModalBody>
          <div style={{ height: "70vh", overflow: "auto" }}>
            {studentListLoading ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Spinner color="secondary" />
              </div>
            ) : (
              <Table>
                <thead>
                  <tr>
                    <th>
                      <label>
                        <input
                          type={"checkbox"}
                          defaultChecked={false}
                          onChange={(e) => {
                            let list = studentList.map((x) => ({
                              ...x,
                              checked: e.target.checked,
                            }));
                            setStudentList(list);
                          }}
                        />{" "}
                        Select
                      </label>
                    </th>
                    <th>CNIC</th>
                    <th>Roll Number</th>
                    <th>Student Name</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList.map((item, index) => (
                    <tr key={item.EnrollmentDetailID}>
                      <td>
                        <input
                          type={"checkbox"}
                          checked={studentList[index].checked}
                          onChange={(e) => {
                            studentList[index].checked = e.target.checked;
                            setStudentList([...studentList]);
                          }}
                        />
                      </td>
                      <td>{item.Cnic}</td>
                      <td>{item.EnrollmentID}</td>
                      <td>{item.StudentName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EnrolledStudentAcademicRecord;
