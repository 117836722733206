import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Label } from "reactstrap";
import {
  campusCity,
  campusType,
  department,
  employeeCase,
  Approve,
  Search,
  Select,
  Update,
  SessionStorage,
} from "../../../common/SetupMasterEnum";
import { CustomErrorMessage, CustomSuccessAlert } from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  RESET_FORM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  TMS_Leave_Applied_Operations,
} from "../../../utils/Config";
import SelectDropDown from "react-select";
import makeAnimated from "react-select/animated";
import { formatDateFunction2 } from "../../../functions/DateFormatFunction";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  EmployeeList: new Array(0),
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  EmployeeId: 0,
};
const initialFormFields = {
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  EmployeeId: 0,
  LeaveAppliedId: 0,
};

const LeaveApproval = () => {
  const {
    SearchFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();

  useEffect(() => {
    getLeavesForApproval();
    getDropDown();
    getEmployeeDropDown();
  }, []);

  function getLeavesForApproval() {
    const payload = {
      operationId: Select,
      leaveAppliedId: 0,
      employeeId: 0,
      leaveStatusId: 0,
      employeeReason: "string",
      leaveTypeId: 0,
      isFlexiDeduct: true,
      fromDate: "2022-07-19T16:08:01.562Z",
      toDate: "2022-07-19T16:08:01.562Z",
      duration: 0,
      offset: 0,
      noOfDays: 0,
      halfDayValue: 0,
      halfDayDeductionAgainstLeaveID: 0,
      inchargeId: 0,
      inchargeReason: "string",
      withoutPay: 0,
      createdDate: "2022-07-19T16:08:01.562Z",
      createdBy: 0,
      isActive: true,
      modifiedDate: "2022-07-19T16:08:01.562Z",
      modifiedBy: 0,
      userIP: "string",
    };
  
    TMS_Leave_Applied_Operations(payload)
      .then((res) => {
   
        let data = res.data.Table2.map((x) => ({
          ...x,
          FromDate: formatDateFunction2(x.FromDate),
          ToDate: formatDateFunction2(x.ToDate)
        }
        ))
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: data,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getDropDown() {
    const payload = {
      operationId: Select,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
      
        let data = {
          name: "SuportingTable",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getEmployeeDropDown() {
    const payload = {
      
        operationId: Select,
        employeeId: decryptData("EmplId", SessionStorage),
        case: employeeCase
      
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {

        let data = {
          name: "EmployeeList",
          value: res.data.Table.map((x, index) => ({
            ...x,
            label: x.Name,
            value: index + 1,
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "Name", name: "Employee" },
    { field: "EmployeeReason", name: "Reason" },
    { field: "SetupDetailName", name: "Type" },
    { field: "FromDate", name: "From Date" },
    { field: "ToDate", name: "To Date" },
    { field: "NoOfDays", name: "Days" },
    { field: "SetupDetailName1", name: "Remarks" },
  ];

  const handleSearchChange = (e) => {
      let data = { name: e.target.name, value: e.target.value };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });

  };

  const handleSearchDropDownChange = (e) => {

    let data = { name: "EmployeeId", value: e.Id };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {};

  const handleInputChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Campus Type"
          list={SupportingTables?.SuportingTable?.filter(
            (x) => x.SetupMasterId == campusType
          )}
          name="CampusTypeId"
          value={SearchFields?.CampusTypeId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.SuportingTable?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == SearchFields?.CampusTypeId
          )}
          label="Campus City"
          name="CampusCityId"
          onChange={handleSearchChange}
          value={SearchFields?.CampusCityId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          disabled={!SearchFields?.CampusTypeId}
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.SuportingTable?.filter(
            (x) => x.SetupMasterId == department
          )}
          label="Department"
          name="DepartmentId"
          onChange={handleSearchChange}
          value={SearchFields?.DepartmentId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          required
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <Label>Employees</Label>
        <SelectDropDown
          closeMenuOnSelect={true}
          components={animatedComponents}
          onChange={handleSearchDropDownChange}
          options={SupportingTables?.EmployeeList}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      operationId: Search,
      leaveAppliedId: 0,
      employeeId: SearchFields.EmployeeId,
      leaveStatusId: 0,
      employeeReason: "string",
      leaveTypeId: 0,
      isFlexiDeduct: true,
      fromDate: "2022-07-19T16:08:01.562Z",
      toDate: "2022-07-19T16:08:01.562Z",
      duration: 0,
      offset: 0,
      noOfDays: 0,
      halfDayValue: 0,
      halfDayDeductionAgainstLeaveID: 0,
      inchargeId: 0,
      inchargeReason: "string",
      withoutPay: 0,
      createdDate: "2022-07-19T16:08:01.562Z",
      createdBy: 0,
      isActive: true,
      modifiedDate: "2022-07-19T16:08:01.562Z",
      modifiedBy: 0,
      userIP: "string",
    };

    TMS_Leave_Applied_Operations(payload)
      .then((res) => {
   
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {};

  const onConfirmRow = (obj) => {
    const payload = {
      operationId: Update,
      leaveAppliedId: obj.LeaveAppliedId,
      employeeId: obj.EmployeeId,
      leaveStatusId: Approve,
      employeeReason: "string",
      leaveTypeId: 0,
      isFlexiDeduct: true,
      fromDate: obj.FromDate,
      toDate: obj.ToDate,
      duration: 0,
      offset: 0,
      noOfDays: 0,
      halfDayValue: 0,
      halfDayDeductionAgainstLeaveID: 0,
      inchargeId: 0,
      inchargeReason: "string",
      withoutPay: 0,
      createdDate: "2022-07-19T16:08:01.562Z",
      createdBy: decryptData("loginId", SessionStorage),
      isActive: true,
      modifiedDate: "2022-07-19T16:08:01.562Z",
      modifiedBy: decryptData("loginId", SessionStorage),
      userIP: "string",
    };
   
    TMS_Leave_Applied_Operations(payload)
      .then((res) => {
    

       if (res.data.Table[0].HasError == 0) {
          dispatch({ type: RESET_FORM_FIELDS, payload: initialSearchFields });
          let msg = res.data.Table[0].Message
          CustomSuccessAlert(msg);
        } else {
          let msg = res.data.Table[0].Message
          CustomErrorMessage(msg);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onRefuseRow = (obj) => {};

  return (
    <CrudFormComponent
      formName="Leave"
      buttonName="Add"
      tableColumns={columns}
      tableRows={TableList}
      hideAction={false}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onConfirm={onConfirmRow}
      onRefuse={onRefuseRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default LeaveApproval;
