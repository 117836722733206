import React from "react";
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Container, Progress, Row } from "reactstrap";

const Departments = () => {
  return (
    <Container fluid>
      <Card className="mt-3">
          <CardTitle>Departments</CardTitle>
          <CardBody>
            <Row>
              <Col lg='4' md='4' xs='12'>
                <Card className="department-card">
                  <CardTitle>
                    <h5>Administration</h5>
                  </CardTitle>
                  <CardBody>
                          <div>
                            <ul>
                              <li><a href="#">Employees</a></li>
                              <li><strong>26</strong></li>
                            </ul>
                            <ul>
                            <li><a href="#">Probations</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Time off Request(s)</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                          </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='4' md='4' xs='12'>
                <Card className="department-card">
                  <CardTitle>
                    <h5>Management</h5>
                  </CardTitle>
                  <CardBody>
                          <div>
                            <ul>
                              <li><a href="#">Employees</a></li>
                              <li><strong>26</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Probations</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                            <ul>
                            <li><a href="#">Time off Request(s)</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                          </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='4' md='4' xs='12'>
                <Card className="department-card">
                  <CardTitle>
                    <h5>Professional Services</h5>
                  </CardTitle>
                  <CardBody>
                          <div>
                            <ul>
                              <li><a href="#">Employees</a></li>
                              <li><strong>26</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Probations</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Time off Request(s)</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                          </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='4' md='4' xs='12'>
              <Card className="department-card">
                  <CardTitle>
                    <h5>Research & Development</h5>
                  </CardTitle>
                  <CardBody>
                          <div>
                            <ul>
                              <li><a href="#">Employees</a></li>
                              <li><strong>26</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Probations</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Time off Request(s)</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                          </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='4' md='4' xs='12'>
                <Card className="department-card">
                  <CardTitle>
                    <h5>Special Title Treatment</h5>
                  </CardTitle>
                  <CardBody>
                          <div>
                            <ul>
                              <li><a href="#">Employees</a></li>
                              <li><strong>26</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Probations</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                            <ul>
                              <li><a href="#">Time off Request(s)</a></li>
                              <li><strong>2</strong></li>
                            </ul>
                          </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
      </Card>
    </Container>
  );
};

export default Departments;
