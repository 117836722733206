import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { CardTitle, Table } from "reactstrap";
import {
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import { decryptData } from "../../../EncryptData";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { CURRENT_SEMESTER } from "../../../utils/UrlConstants";

const StudentCourse = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    getSemesterAttandance();
  }, []);

  const getSemesterAttandance = () => {
    const payload = {
      operationId: Select,
      studentDetailID: params?.id1,
      courseSemesterMappingID: params?.id2,
      parameterID: Update,
      userID: 0,
    };
    PostRequest(CURRENT_SEMESTER, payload)
      .then((res) => {
        let studentCourse = {
          name: "StudentCourse",
          value: res?.data?.Table1,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: studentCourse,
        });
        let studentDetails = {
          name: "StudentDetails",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: studentDetails,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "DayDate", name: "Day Date" },
    { field: "Attandance", name: "Attandance" },
  ];

  const customTable = (
    <Fragment>
      <CardTitle
        style={{
          backgroundColor: "#772647",
          color: "white",
          textAlign: "center",
        }}
      >
        Course Name- {SupportingTables?.StudentCourse?.[0]?.Courses}
      </CardTitle>
      <Table bordered responsive striped>
        <tbody>
          <tr>
            <td
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: "bold",
              }}
            >
              Class Participation Marks
            </td>
            <td> {SupportingTables?.StudentCourse?.[0]?.CPMarks}</td>
          </tr>
          <tr>
            <td
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: "bold",
              }}
            >
              Mid Term Exam Marks
            </td>
            <td> {SupportingTables?.StudentCourse?.[0]?.MidTermExamMarks}</td>
          </tr>
          <tr>
            <td
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: "bold",
              }}
            >
              Final Exam Marks
            </td>
            <td> {SupportingTables?.StudentCourse?.[0]?.FinalExamMarks}</td>
          </tr>
          <tr>
            <td
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontWeight: "bold",
              }}
            >
              Lock By Lecturer
            </td>
            <td>
              {" "}
              {SupportingTables?.StudentCourse?.[0]?.IsLockByLecturer ===
              false ? (
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-unlock text-success"
                ></i>
              ) : (
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-lock text-danger"
                ></i>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Fall 2022"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      customTable={customTable}
    />
  );
};

export default StudentCourse;
