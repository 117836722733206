import React from "react";

const NoPageFound = () => {
  return (
    <>
        <h3 className="text-center no-page-found">No Page Found</h3>
    </>
  );
};

export default NoPageFound;
