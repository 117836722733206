import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  adjustmentReasons,
  Approve,
  campusCity,
  campusType,
  department,
  Rejected,
  Search,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  formatDateFunction1,
  formatDateFunction2,
  formatTimeFromDate,
} from "../../../functions/DateFormatFunction";
import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  SecuritySetup_Employee_Hierarchical_Operations,
  Setup_MasterDetails_All_Dropdowns,
  TMS_TMS_Incharge_TimeAdjustmentRequest,
} from "../../../utils/Config";
import SelectDropDown from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import {
  dateFormat,
  dateFormatPlaceholder,
} from "../../../utils/CommonMethods";
import { decryptData } from "../../../EncryptData";

const initialSearchFields = {
  EmployeeList: new Array(0),
  CampusTypeId: 0,
  CampusCityId: 0,
  DepartmentId: 0,
  EmployeeId: 0,
  PeriodFrom: "",
  PeriodTo: "",
};
const initialFormFields = {
  DailyActivityId: 0,
  RequestNumber: 0,
  RequestCreationTime: 0,
  EmployeeName: "",
  OriginalTime: "",
  RequestedTime: "",
  CurrentStatus: "",
  Reason: "",
  Comment: "",
  Attachment: "",
  ManagedOn: "",
  ManagedBy: "",
  ManagerComments: "",
  DayEndProcessed: "",
  ProcessedOn: "",
};

const InchargeTimeAdjustment = () => {
  const {
    SearchFields,
    FormFields,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    getTimeAdjustmentForEmployees();
    getDropDown();
    getEmployeeDropDown();
  }, []);

  function getTimeAdjustmentForEmployees() {
    const payload = {
      operationId: Select,
      timeAdjustmentRequestId: 0,
      dailyActivityId: 0,
      employeeId: decryptData("EmplId", SessionStorage),
      employeeComment: "string",
      isTimeOutRequest: true,
      originalDateTime: "string",
      requestedDateTime: "2022-07-26T18:50:02.465Z",
      fromDate: "2022-07-26T18:50:02.465Z",
      toDate: "2022-07-26T18:50:02.465Z",
      approvarId: decryptData("EmplId", SessionStorage),
      statusId: 0,
      approverComment: "string",
      adjustmentReasonId: 0,
      imagePath: "string",
      createdBy: 0,
      modifiedBy: 0,
      isActive: true,
      userIP: "string",
    };
    TMS_TMS_Incharge_TimeAdjustmentRequest(payload)
      .then((res) => {
        setFromDate("");
        setToDate("");
        let data = res.data.Table.map((x) => ({
          ...x,
          CreatedDate: formatDateFunction2(x.CreatedDate),
          RequestedDateTime: formatDateFunction2(x.RequestedDateTime),
          OriginalDateTime1: formatTimeFromDate(x.OriginalDateTime1),
          RequestedDateTime1: formatTimeFromDate(x.RequestedDateTime1),
        }));
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: data,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const cancelSearch = () => {
    setFromDate("");
    setToDate("");
    getTimeAdjustmentForEmployees();
  };

  function getDropDown() {
    const payload = {
      operationId: Select,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "SuportingTable",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getEmployeeDropDown() {
    const payload = {
      operationId: Select,
      employeeId: decryptData("EmplId", SessionStorage),
      case: 3,
    };
    SecuritySetup_Employee_Hierarchical_Operations(payload)
      .then((res) => {
        let data = {
          name: "EmployeeList",
          value: res.data.Table.map((x, index) => ({
            ...x,
            label: x.Name,
            value: index + 1,
          })),
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "CreatedDate", name: "Applied Date" },
    { field: "RequestedDateTime", name: "Applied For" },
    { field: "OriginalDateTime1", name: "Original Time" },
    { field: "RequestedDateTime1", name: "Request Time" },
    { field: "IsTimeOutRequest", name: "Type" },
    { field: "SetupDetailName", name: "Reason" },
    { field: "EmployeeComment", name: "Comment" },
    // { field: "Attactments", name: "Attactments" },
    // { field: "Remarks", name: "Remarks" },
    { field: "Employee", name: "Applied By" },
  ];

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleSearchDropDownChange = (e) => {
    let data = { name: "EmployeeId", value: e.Id };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          label="Campus Type"
          list={SupportingTables?.SuportingTable?.filter(
            (x) => x.SetupMasterId == campusType
          )}
          name="CampusTypeId"
          value={SearchFields?.CampusTypeId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.SuportingTable?.filter(
            (x) =>
              x.SetupMasterId == campusCity &&
              x.parentid == SearchFields?.CampusTypeId
          )}
          label="Campus City"
          name="CampusCityId"
          onChange={handleSearchChange}
          value={SearchFields?.CampusCityId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          disabled={!SearchFields?.CampusTypeId}
        />
      </Col>
      <Col lg="4" md="4" xs="12">
        <FormGroupSelect
          list={SupportingTables?.SuportingTable?.filter(
            (x) => x.SetupMasterId == department
          )}
          label="Department"
          name="DepartmentId"
          onChange={handleSearchChange}
          value={SearchFields?.DepartmentId}
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select From Date</label>
          <DatePicker
            selected={fromDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodFrom")}
            className="form-control"
            name="PeriodFrom"
            placeholderText={dateFormatPlaceholder}
            required
          />
        </div>
      </Col>
      <Col lg="3" md="3" xs="12">
        <div className="form-group">
          <label className="form-label">Select To Date</label>
          <DatePicker
            selected={toDate}
            dateFormat={dateFormat}
            onChange={(e) => AllDateSet(e, "PeriodTo")}
            className="form-control"
            name="PeriodTo"
            placeholderText={dateFormatPlaceholder}
            required
          />
        </div>
      </Col>
      <Col lg="4" md="4" xs="12">
        <Label>Employees</Label>
        <SelectDropDown
          closeMenuOnSelect={true}
          components={animatedComponents}
          onChange={handleSearchDropDownChange}
          options={SupportingTables?.EmployeeList}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    if (SearchFields.EmployeeId != 0) {
      const payload = {
        operationId: Search,
        timeAdjustmentRequestId: 0,
        dailyActivityId: 0,
        employeeId: SearchFields?.EmployeeId,
        employeeComment: "string",
        isTimeOutRequest: true,
        originalDateTime: "string",
        requestedDateTime: "2022-07-26T18:50:02.465Z",
        fromDate: SearchFields?.PeriodFrom,
        toDate: SearchFields?.PeriodTo,
        approvarId: decryptData("EmplId", SessionStorage),
        statusId: 0,
        approverComment: "string",
        adjustmentReasonId: 0,
        imagePath: "string",
        createdBy: 0,
        modifiedBy: 0,
        isActive: true,
        userIP: "string",
      };
      TMS_TMS_Incharge_TimeAdjustmentRequest(payload)
        .then((res) => {
          setFromDate("");
          setToDate("");
          let data = res.data.Table.map((x) => ({
            ...x,
            CreatedDate: formatDateFunction2(x.CreatedDate),
            RequestedDateTime: formatDateFunction2(x.RequestedDateTime),
            OriginalDateTime1: formatTimeFromDate(x.OriginalDateTime1),
            RequestedDateTime1: formatTimeFromDate(x.RequestedDateTime1),
          }));
          dispatch({
            type: SET_INITIAL_CRUD_FORM_STATE,
            payload: {
              List: data,
              FormFields: initialFormFields,
              SearchFields: initialSearchFields,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      CustomErrorMessage("Please Select Employee");
    }
  };

  const submitForm = (id) => {};

  const onConfirmRow = (obj) => {
    let data = {
      DailyActivityId: obj.DailyActivityId,
      RequestNumber: obj.TimeAdjustmentRequestId,
      RequestCreationTime: obj.CreatedDate,
      EmployeeName: obj.Employee,
      OriginalTime: obj.OriginalDateTime,
      RequestedTime: obj.RequestedDateTime,
      CurrentStatus: obj.status,
      Comments: obj.EmployeeComment,
      Reason: obj.SetupDetailId,
      TimeRequested: obj.RequestedDateTime1,
    };
    dispatch({ type: SET_ALL_CRUD_FROM_FIELDS, payload: data });

    toggle();
  };

  const onRefuseRow = (obj) => {
    const payload = {
      operationId: Update,
      timeAdjustmentRequestId: obj.TimeAdjustmentRequestId,
      dailyActivityId: 0,
      employeeId: 0,
      employeeComment: "string",
      isTimeOutRequest: true,
      originalDateTime: "2022-07-26T18:50:02.465Z",
      requestedDateTime: "2022-07-26T18:50:02.465Z",
      fromDate: "2022-07-26T18:50:02.465Z",
      toDate: "2022-07-26T18:50:02.465Z",
      approvarId: 0,
      statusId: Rejected,
      approverComment: "",
      adjustmentReasonId: 0,
      imagePath: "string",
      createdBy: 0,
      modifiedBy: decryptData("EmplId", SessionStorage),
      isActive: true,
      userIP: "string",
    };

    TMS_TMS_Incharge_TimeAdjustmentRequest(payload)
      .then((res) => {
        if (res.data.Table[0].HassError === 0) {
          CustomSuccessAlert(res.data.Table[0].Message);
          getTimeAdjustmentForEmployees();
          getDropDown();
          getEmployeeDropDown();
        } else {
          CustomErrorMessage(res.data.Table[0].Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSaveModal = () => {
    approveTimeAdjustment();
  };

  const AllDateSet = (event, type) => {
    if (type === "PeriodFrom") {
      setFromDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodFrom = date;
      let data1 = {
        name: "PeriodFrom",
        value: SearchFields?.PeriodFrom,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    } else if (type === "PeriodTo") {
      setToDate(event);
      let date = formatDateFunction1(event, "-");
      SearchFields.PeriodTo = date;
      let data1 = {
        name: "PeriodTo",
        value: SearchFields?.PeriodTo,
      };
      dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data1 });
    }
  };

  function approveTimeAdjustment() {
    if (
      FormFields?.ManagerComments != "" &&
      FormFields.ManagerComments != undefined
    ) {
      const payload = {
        operationId: Update,
        timeAdjustmentRequestId: FormFields?.RequestNumber,
        dailyActivityId: FormFields?.DailyActivityId,
        employeeId: decryptData("EmplId", SessionStorage),
        employeeComment: "string",
        isTimeOutRequest: true,
        originalDateTime: "string",
        requestedDateTime: "2022-07-26T18:50:02.465Z",
        fromDate: "2022-07-26T18:50:02.465Z",
        toDate: "2022-07-26T18:50:02.465Z",
        approvarId: decryptData("EmplId", SessionStorage),
        statusId: Approve,
        approverComment: FormFields?.ManagerComments,
        adjustmentReasonId: 0,
        imagePath: "string",
        createdBy: 0,
        modifiedBy: decryptData("EmplId", SessionStorage),
        isActive: true,
        userIP: "string",
      };

      TMS_TMS_Incharge_TimeAdjustmentRequest(payload)
        .then((res) => {
          if (res.data.Table[0].HassError === 0) {
            toggle();
            CustomSuccessAlert(res.data.Table[0].Message);
            getTimeAdjustmentForEmployees();
            getDropDown();
            getEmployeeDropDown();
          } else {
            CustomErrorMessage(res.data.Table[0].Message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      CustomErrorMessage("Please Add Manager Comment");
    }
  }

  const customModal = (
    <Modal
      isOpen={modal}
      size="lg"
      centered
      toggle={toggle}
      modalTransition={{ timeout: 10 }}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Discrepancy Request</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg="12" md="12" xs="12">
            <Table bordered striped responsive>
              <tbody>
                <tr>
                  <td>Request Number</td>
                  <td>
                    <FormGroup>
                      <Input
                        type="text"
                        className="form-control"
                        style={{ width: "51%" }}
                        value={FormFields?.RequestNumber}
                        disabled={true}
                      />
                    </FormGroup>
                  </td>
                </tr>

                <tr>
                  <td>Request Creation Time</td>
                  <td>
                    <Input
                      type="text"
                      className="form-control"
                      style={{ width: "51%" }}
                      value={FormFields?.RequestCreationTime}
                      disabled={true}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Employee Name</td>
                  <td>{FormFields?.EmployeeName}</td>
                </tr>

                <tr>
                  <td>Original Time</td>
                  <td>{formatTimeFromDate(FormFields?.OriginalTime)}</td>
                </tr>

                <tr>
                  <td>Requested Time</td>
                  <td>
                    <Input
                      type="text"
                      className="form-control"
                      style={{ width: "51%" }}
                      value={FormFields?.RequestedTime}
                      disabled={true}
                    />
                    <div style={{ marginTop: "7px", display: "flex" }}>
                      <FormGroup>
                        <Input
                          type="text"
                          className="form-control"
                          style={{ width: "51%" }}
                          value={FormFields?.TimeRequested}
                          disabled={true}
                        />
                      </FormGroup>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Current Status</td>
                  <td>{FormFields?.CurrentStatus}</td>
                </tr>

                <tr>
                  <td>Reason</td>
                  <td>
                    <Col>
                      <FormGroupSelect
                        list={SupportingTables?.SuportingTable?.filter(
                          (x) => x.SetupMasterId == adjustmentReasons
                        )}
                        name="Reason"
                        onChange={handleAddChange}
                        value={FormFields?.Reason}
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        disabled
                      />
                    </Col>
                  </td>
                </tr>

                <tr>
                  <td>Comments</td>
                  <td>
                    <textarea
                      className="form-contreol"
                      rows="3"
                      name="Comments"
                      onChange={handleAddChange}
                      disabled={true}
                      value={FormFields?.Comments}
                      style={{
                        width: "100%",
                        border: "1px solid #ebebeb",
                        background: "#f9f9f9",
                      }}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>Managed On</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Managed By</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Manager Comments</td>
                  <td>
                    <textarea
                      className="form-contreol"
                      rows="3"
                      name="ManagerComments"
                      onChange={handleAddChange}
                      value={FormFields?.ManagerComments}
                      required
                      style={{
                        width: "100%",
                        border: "1px solid #ebebeb",
                        background: "#f9f9f9",
                      }}
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td>Day End Processed</td>
                  <td>False</td>
                </tr>

                <tr>
                  <td>Processed On</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveModal}>
          Save
        </Button>
        <Button color="default" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <CrudFormComponent
      formName="Search"
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      onConfirm={onConfirmRow}
      onRefuse={onRefuseRow}
      cancelSearch={cancelSearch}
      initialFormFields={initialFormFields}
      customModal={customModal}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default InchargeTimeAdjustment;
