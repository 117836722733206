import React, { forwardRef, Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import logoimg from "../../assets/img/logo.png";
import { Select, SessionStorage } from "../../common/SetupMasterEnum";
import { decryptData } from "../../EncryptData";
import {
  Admission_ADM_CandidateStatus,
  Admission_PrintAdmissionForm,
} from "../../utils/Config";
import { REG_ID } from "../../utils/EncryptedConstants";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";

import QRCode from "react-qr-code";

const AdmissionChallan = forwardRef((props, ref) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    getCadidateStatus();
  }, []);

  const getAdmissionReportInfo = (ADMRegID, ProgramId) => {
    Admission_PrintAdmissionForm(ADMRegID, ProgramId).then((res) => {
      let data = {
        name: "ReportData",
        value: {
          Table: res.data?.Table,
          Table1: res.data?.Table1,
          Table2: res?.data?.Table2,
          Table3: res?.data?.Table3,
          Table4: res?.data?.Table4,
          Table5: res?.data?.Table5,
        },
      };
      dispatch({
        type: SET_INITIAL_DROPDOWN_FORM_STATE,
        payload: data,
      });
    });
  };

  const getCadidateStatus = () => {
    let data = {
      operationID: Select,
      admRegID: decryptData(REG_ID, SessionStorage),
      createdBy: 0,
      modifiedBy: 0,
      isActive: true,
      userIp: "string",
    };
    Admission_ADM_CandidateStatus(data)
      .then((res) => {
        let data = {
          name: "CandidateStatus",
          value: res.data.Table[0],
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        getAdmissionReportInfo(
          res?.data?.Table[0]?.ADMRegID,
          res?.data?.Table[0]?.ProgramId
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div style={{ fontSize: 12 }} ref={ref}>
      <Container fluid>
        <div style={{ fontSize: 12, padding: "20px" }} className="card-div">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td className="text-center">
                  <img src={logoimg} height="90" />
                </td>

                <td colspan="3">
                  <table
                    style={{
                      width: "100%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="text-center">
                          {" "}
                          <p style={{ fontWeight: "bold" }}>
                            {SupportingTables?.ReportData?.Table[0]?.University}
                          </p>
                          <p>
                            {SupportingTables?.ReportData?.Table[0]?.Heading}
                          </p>
                          <p>{SupportingTables?.ReportData?.Table[0]?.Title}</p>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="text-center">
                  <QRCode
                    value={`${SupportingTables?.ApplicationId}`}
                    size="70"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Tick ( ) Any One Program
                </td>
                <td
                  colSpan={2}
                  className="text-center"
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 10,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "30px",
                        backgroundColor: "white",
                        lineHeight: "31px",
                      }}
                    >
                      {SupportingTables?.ReportData?.Table?.[0]
                        ?.BachelorProgram == 1 ? (
                        <i style={{ color: "black" }} class="fa fa-check"></i>
                      ) : null}
                    </div>
                    <p style={{ margin: "0px 4px" }}>Bachelor's Program</p>
                  </div>
                </td>
                <td
                  colSpan={6}
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: 10,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "30px",
                        backgroundColor: "white",
                        lineHeight: "27px",
                      }}
                    ></div>
                    <p style={{ margin: "0px 4px" }}>Master's Program</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th style={{ height: "10px" }}></th>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Name of Program
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {
                    SupportingTables?.ReportData?.Table[0]
                      ?.FacultyDepartmentProgramName
                  }
                </td>
                {/* <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </td>
                <td
                  colSpan="8"
                  style={{
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.ProgramTypeName}
                </td> */}
              </tr>
              <tr>
                <th style={{ height: "10px" }}></th>
              </tr>
              <tr>
                <td
                  colSpan="8"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  A-PERSONAL INFORMATION:
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  1. Name:
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.Name}
                </td>
                <td
                  colSpan="7"
                  rowSpan="6"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={SupportingTables?.ReportData?.Table[0]?.Picture}
                    height="90"
                  />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  2. Father's Name:
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.FatherName}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  3. Surname:
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.SurName}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  4. Gender:
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    width: "15%",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.GenderName}
                  {/* <div>
                    <lable>Male</lable>
                    <input style={{ marginRight: 20 }} type="checkbox" ></input>
                    <lable>Female</lable>
                    <input type="checkbox"></input>
                  </div> */}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  5. Guardian's Name (Other than father):
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.GuardianName}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  6. CNIC No:
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.Cnic}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  7. Date of Birth:
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.DOB}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  8. Postal Address:
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.PostalAddress}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  9. Permanent Address:
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.PermanantAddress}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  10. Correspondant Address:
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  Postal Address
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    width: "30%",
                    fontWeight: "bold",
                  }}
                >
                  11. Nationality:
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    width: "10%",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.NationalityName}
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    width: "10%",
                    fontWeight: "bold",
                  }}
                >
                  12. Religion
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    width: "10%",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.ReligionName}
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    width: "15%",
                    fontWeight: "bold",
                  }}
                >
                  13. Domicile
                </td>
                <td
                  colSpan="5"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    width: "35%",
                    lineHeight: "10px",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.DomicileName}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  14. Phone No (i) Residence:
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.Ph}
                </td>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                    // width:"10%"
                  }}
                >
                  (ii) Mobile:
                </td>
                <td
                  colSpan="3"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.Mobile}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  15. Email Address:
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]?.Email}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  16. Are you employeed anywhere? (Yes/No). If Yes, Department:
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]
                    ?.AreEmployeedAnyWhere === 0
                    ? "No"
                    : "Yes"}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                    fontWeight: "bold",
                  }}
                >
                  17. If not admitted on merit basis, I may be consider On
                  Self-Finance Base (Yes/No):
                </td>
                <td
                  colSpan="7"
                  style={{
                    border: "1px",
                    borderStyle: "solid",
                  }}
                >
                  {SupportingTables?.ReportData?.Table[0]
                    ?.NotAdmittedOnMeritBasis_ConsiderOnSelfFinanceBase === 0
                    ? "No"
                    : "Yes"}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="8"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  B-ACEDAMIC RECORD:
                </td>
              </tr>
              <tr>
                <th style={{ height: "5px" }}></th>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%", border: "2px", borderStyle: "solid" }}>
            <thead style={{ backgroundColor: "black", color: "white" }}>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Examination
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Degree Name
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Group / Major Subjects
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Year
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Seat No.
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Obtained Marks
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Total Marks
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Board
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  University
                </th>
              </tr>
            </thead>
            <tbody>
              {SupportingTables?.ReportData?.Table1?.map((x, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {x.ExaminationName}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.DegreeName === "" ? "-" : x.DegreeName}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.MajorSubjects}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.YearName}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.SeatNo_RollNumber}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.ObtainedMarks}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.TotalMarks}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.BoardName}
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {x.University}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="portrait-page">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ height: "5px" }}></td>
                </tr>
                <tr>
                  <td
                    colSpan="11"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "black",
                      color: "white",
                    }}
                  >
                    C-CHOICE OF THE PROGRAM FOR ADMISSION IN ORDER OF
                    PREFERENCE:
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="12"
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    WRITE ANY THREE CHOICES: (Other than the Main Program) if
                    not selected in Merit List the Admission will be considered
                    from any of the following choices (if eligible):
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    01.
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {
                      SupportingTables?.ReportData?.Table2[0]
                        ?.FacultyDepartmentProgramName
                    }
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    02.
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {
                      SupportingTables?.ReportData?.Table2[1]
                        ?.FacultyDepartmentProgramName
                    }
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    03.
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    {
                      SupportingTables?.ReportData?.Table2[2]
                        ?.FacultyDepartmentProgramName
                    }
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    04.
                  </td>
                  <td
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                      textAlign: "center",
                    }}
                  >
                    -
                  </td>
                </tr>
                {/* <tr>
                <th style={{ height: "10px" }}></th>
              </tr> */}
                <tr>
                  <td
                    colSpan="11"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      backgroundColor: "black",
                      color: "white",
                    }}
                  >
                    UNDERTAKING
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="11"
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    I, hereby undertake that the entries made in admission form
                    are coreect and complete to the best of my knowledge and
                    that my admission to the University is provisional and is
                    subject to concellation if any irregularity is found in my
                    admission form/documents, Futher, my name has not been
                    registered/enrolled as regular student for any other degree
                    in any field of study at othere Institute/University, I
                    acknowledge that I have uploaded my CNIC Front, CNIC Back,
                    Domicile, PRC & Other relevant documents. I also undertake
                    to abide by the rules and regulations of the Shaikh Ayaz
                    University Shikarpur.
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Dated: _/_/{new Date().getFullYear()}
                  </td>
                  <td
                    colSpan="11"
                    style={{
                      border: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    Sgnature of Applicant
                  </td>
                </tr>
                <tr>
                  <th style={{ height: "5px" }}></th>
                </tr>
                <tr>
                  <td
                    colSpan="11"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                    }}
                  >
                    Form Fees Rs. 2,000
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
});

export default AdmissionChallan;
