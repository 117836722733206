import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Table,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import Swal from "sweetalert2";
import {
  RESET_FORM_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_CRUD_FROM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
} from "../../redux/actionType/CrudActionTypes";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import {
  PostRequest,
  Setup_ADM_FacultyDerpartmentFeeStructure,
  Setup_MasterDetails_All_Dropdowns,
} from "../../utils/Config";
import {
  admissionProgramId,
  departmentId,
  facultyDepartmentId,
  facultyTypeId,
  particular,
  programs,
  programTypes,
  Search,
  Select,
  semester,
  SessionStorage,
  typeOfSeatId,
  FeeTypeId,
  Update,
  feesTypeId,
} from "../../common/SetupMasterEnum";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupCheckbox from "../../components/GeneralComponent/FormGroupCheckbox";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";
import { decryptData } from "../../EncryptData";
import useSetupDetailList from "../../Hooks/useSetupDetailList";
import { ADM_FACULTY_DEPARTMENT_FEE_STRUCTURE } from "../../utils/UrlConstants";

const initialSearchFields = {
  FacultyID: 0,
  DepartmentID: 0,
  ProgramID: 0,
  ProgramTypeID: 0
};
const initialFormFields = {
  ADMDeptFeeStructureID: 0,
  FacultyID: 0,
  DepartmentID: 0,
  ProgramTypeID: 0,
  ProgramID: 0,
  ParticularID: 0,
  SemesterID: 0,
  MeritAmount: "",
  SelfFinanceAmount: "",
  IsActive: false,
};


const FeeStructure = (props) => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [dropdownList, setDropDownList] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getDropDown();
    // return () => {
    //   dispatch({
    //     type: SET_INITIAL_CRUD_FORM_STATE,
    //     payload: {
    //       List: new Array(0),
    //       FormFields: initialFormFields,
    //       SearchFields: initialSearchFields,
    //     },
    //   });
    // };
  }, []);

  function getDropDown() {
    const payload = {
      operationId: Select,
    };

    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        setDropDownList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const submitSearch = () => {
    const payload = {
      operationID: Search,
      admDeptFeeStructureID: 0,
      facultyID: SearchFields?.FacultyID,
      departmentID: SearchFields?.DepartmentID,
      programID: SearchFields?.ProgramID,
      programTypeID: SearchFields?.ProgramTypeID,
      admParticularID: 0,
      semesterID: 0,
      merit: 0,
      selfFinance: 0,
      isActive: true,
      createdBy: decryptData("loginId", SessionStorage),
      modifiedBy: decryptData("loginId", SessionStorage),
      userIp: "string",
    };
    PostRequest(ADM_FACULTY_DEPARTMENT_FEE_STRUCTURE, payload)
    // Setup_ADM_FacultyDerpartmentFeeStructure(payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialSearchFields });
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
        setShowTable(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      operationID: id,
      admDeptFeeStructureID: 0,
      facultyID: FormFields?.FacultyID,
      departmentID: FormFields?.DepartmentID,
      programID: FormFields?.ProgramID,
      programTypeID: FormFields?.ProgramTypeID,
      admParticularID: FormFields?.ParticularID,
      semesterID: FormFields?.SemesterID,
      merit: FormFields?.MeritAmount,
      selfFinance: FormFields?.SelfFinanceAmount,
      isActive: FormFields?.IsActive,
      createdBy: decryptData("loginId", SessionStorage),
      modifiedBy: decryptData("loginId", SessionStorage),
      userIp: "string",
    };
    PostRequest(ADM_FACULTY_DEPARTMENT_FEE_STRUCTURE, payload)
    // Setup_ADM_FacultyDerpartmentFeeStructure(payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Column1);
        } else {
          CustomErrorMessage(res.data.Table[0].Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({ type: RESET_SEARCH_FIELDS, payload: initialSearchFields });
    setShowTable(false);
  };

  const handleCancel = () => {
    dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
  };

  const columns = [{ field: "TimeIn", name: "No Data" }];

  const onEditInOutRow = (e, item, index, type) => {
    e.preventDefault();
    let obj;
    if (type === "AdmissionFee") {
      obj = TableList?.Table[index];
     
      setToggle(true);

    } else if (type === "TutionFee") {
      obj = TableList?.Table1[index];

      setToggle(true);
    } else if (type === "UniversityDevelopmentFund") {
      obj = TableList?.Table2[index];
      setToggle(true);
    } else if (type === "ServiceFee") {
      obj = TableList?.Table3[index];
      setToggle(true);
    } else if (type === "ExaminationFee") {
      obj = TableList?.Table4[index];
      setToggle(true);
    }
    FormFields.MeritAmount = obj.Merit;
    FormFields.SelfFinanceAmount = obj.SelfFinance;
    FormFields.ADMDeptFeeStructureID = obj.ADMDeptFeeStructureID;
    FormFields.FacultyID = obj.FacultyID
    FormFields.DepartmentID = obj.DepartmentID
    FormFields.ParticularID = obj.ParticularID
    FormFields.ProgramID = obj.ProgramID
    FormFields.ProgramTypeID = obj.ProgramTypeID
    FormFields.SemesterID = obj.SemesterID
  };

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == facultyTypeId)}
          label="Faculty"
          name="FacultyID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) =>
              x.SetupMasterId == departmentId && x.parentid == SearchFields?.FacultyID
          )}
          label="Departments"
          name="DepartmentID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          value={SearchFields?.DepartmentID}
          disabled={!SearchFields?.FacultyID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == programTypes)}
          label="Program Type"
          name="ProgramTypeID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          value={SearchFields?.ProgramTypeID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == admissionProgramId)}
          label="Program"
          name="ProgramID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleSearchChange}
          value={SearchFields?.ProgramID}
          required
        />
      </Col>
    </Fragment>
  );

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == facultyTypeId)}
          label="Faculty"
          name="FacultyID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          value={FormFields?.FacultyID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter(
            (x) =>
              x.SetupMasterId == departmentId && x.parentid == FormFields?.FacultyID
          )}
          label="Departments"
          name="DepartmentID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          value={FormFields?.DepartmentID}
          disabled={!FormFields?.FacultyID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == programTypes)}
          label="Program Type"
          name="ProgramTypeID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          value={FormFields?.ProgramTypeID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == admissionProgramId)}
          label="Program"
          name="ProgramID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          value={FormFields?.ProgramID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == particular)}
          label="Particular"
          name="ParticularID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          value={FormFields?.ParticularID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          list={dropdownList?.filter((x) => x.SetupMasterId == semester)}
          label="Semester"
          name="SemesterID"
          fieldId="SetupDetailId"
          fieldName="SetupDetailName"
          onChange={handleAddChange}
          value={FormFields?.SemesterID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupCheckbox
          label=" Is Active"
          name="IsActive"
          value={FormFields?.IsActive}
          onChange={handleAddChange}
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Merit Amount"
          name="MeritAmount"
          onChange={handleAddChange}
          value={FormFields?.MeritAmount}
          isNumber="true"
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupInput
          label="Self Finance Amount"
          name="SelfFinanceAmount"
          onChange={handleAddChange}
          value={FormFields?.SelfFinanceAmount}
          isNumber="true"
          required
        />
      </Col>
    </Fragment>
  );



  const customTable = (
    <Table className="table-bordered table-striped admission-table">
      <thead>
        <tr>
          <th rowSpan="3">S.no</th>
          <th rowSpan="3">Particulars</th>
          <th colSpan="8">{TableList?.Table6?.[0]?.Department}</th>
        </tr>

        <tr>
          <th colSpan="2">Bs Part 1</th>
          <th colSpan="2">Bs Part II - III & IV</th>
          <th colSpan="2">Bs Part III (Old Master)</th>
          <th colSpan="2">Bs Part IV (Old Master)</th>
        </tr>

        <tr>
          <th>Merit</th>
          <th>Self-Fin</th>
          <th>Merit</th>
          <th>Self-Fin</th>
          <th>Merit</th>
          <th>Self-Fin</th>
          <th>Merit</th>
          <th>Self-Fin</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Admission Fee</td>
          {TableList?.Table?.map((item, index) => (
            <>
              <td>
                {item.Merit}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "AdmissionFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
              <td>
                {item.SelfFinance}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "AdmissionFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
            </>
          ))}
        </tr>

        <tr>
          <td>2</td>
          <td>Tution Fee</td>
          {TableList?.Table1?.map((item, index) => (
            <>
              <td>
                {item.Merit}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "TutionFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
              <td>
                {item.SelfFinance}{" "}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "TutionFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
            </>
          ))}
        </tr>
        <tr>
          <td>3</td>
          <td>University Development Fund</td>
          {TableList?.Table2?.map((item, index) => (
            <>
              <td>
                {item.Merit}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(
                          e,
                          item,
                          index,
                          "UniversityDevelopmentFund"
                        )
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
              <td>
                {item.SelfFinance}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(
                          e,
                          item,
                          index,
                          "UniversityDevelopmentFund"
                        )
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
            </>
          ))}
        </tr>
        <tr>
          <td>4</td>
          <td>Service Fee</td>
          {TableList?.Table3?.map((item, index) => (
            <>
              <td>
                {item.Merit}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "ServiceFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
              <td>
                {item.SelfFinance}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "ServiceFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
            </>
          ))}
        </tr>
        <tr>
          <td>5</td>
          <td>Examination Fee</td>
          {TableList?.Table4?.map((item, index) => (
            <>
              <td>
                {item.Merit}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "ExaminationFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
              <td>
                {item.SelfFinance}
                {menuTable?.Table2?.filter(
                  (x) => x.MenuId === selectedMenu
                ).some((x) => x.FeatureId === 2) ? (
                  <a href="">
                    {" "}
                    <i
                      className="fa fa-pencil p-0"
                      onClick={(e) =>
                        onEditInOutRow(e, item, index, "ExaminationFee")
                      }
                    ></i>
                  </a>
                ) : null}
              </td>
            </>
          ))}
        </tr>
        <tr>
          <td></td>
          <td>Total</td>
          {TableList?.Table5?.map((item, index) => (
            <>
              <td>{item.Merit}</td>
              <td>{item.SelfFinance}</td>
            </>
          ))}
        </tr>
      </tbody>
    </Table>
  );

  const handleModalSave = (e) => {
    e.preventDefault();
    const payload = {
      operationID: Update,
      admDeptFeeStructureID: FormFields.ADMDeptFeeStructureID,
      facultyID: FormFields?.FacultyID,
      departmentID: FormFields?.DepartmentID,
      programID: FormFields?.ProgramID,
      admParticularID: FormFields?.ParticularID,
      semesterID: FormFields?.SemesterID,
      merit: FormFields?.MeritAmount,
      selfFinance: FormFields?.SelfFinanceAmount,
      isActive: true,
      createdBy: decryptData("loginId", SessionStorage),
      modifiedBy: decryptData("loginId", SessionStorage),
      userIp: "string",
    };

    Setup_ADM_FacultyDerpartmentFeeStructure(payload)
      .then((res) => {
        dispatch({ type: RESET_FORM_FIELDS, payload: initialFormFields });
        if (res.data.Table[0].HasError === 0) {
          CustomSuccessAlert(res.data.Table[0].Column1);
        } else {
          CustomErrorMessage(res.data.Table[0].Column1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const customModal = (
    <Modal
      isOpen={toggle}
      centered
      modalTransition={{ timeout: 10 }}
      backdrop="static"
    >
      <ModalHeader>Add/Edit Department Fee Structure</ModalHeader>
      <ModalBody>
        <form onSubmit={handleModalSave}>
          {formPanel && (
            <Row>
              <Col lg="6" md="6" xs="12">
                <FormGroupSelect
                  list={dropdownList?.filter(
                    (x) => x.SetupMasterId == facultyTypeId
                  )}
                  label="Faculty"
                  name="FacultyID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={FormFields?.FacultyID}
                  disabled
                />
              </Col>
              <Col lg="6" md="6" xs="12">
                <FormGroupSelect
                  list={dropdownList?.filter(
                    (x) =>
                      x.SetupMasterId == departmentId &&
                      x.parentid == FormFields?.FacultyID
                  )}
                  label="Departments"
                  name="DepartmentID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={FormFields?.DepartmentID}
                  disabled
                />
              </Col>
              <Col lg="6" md="6" xs="12">
                <FormGroupSelect
                  list={dropdownList?.filter(
                    (x) => x.SetupMasterId == programTypes
                  )}
                  label="Program"
                  name="ProgramID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={FormFields?.ProgramID}
                  disabled
                />
              </Col>
              <Col lg="6" md="6" xs="12">
                <FormGroupSelect
                  list={dropdownList?.filter(
                    (x) => x.SetupMasterId == particular
                  )}
                  label="Particular"
                  name="ParticularID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={FormFields?.ParticularID}
                  disabled
                />
              </Col>
              <Col lg="6" md="6" xs="12">
                <FormGroupSelect
                  list={dropdownList?.filter(
                    (x) => x.SetupMasterId == semester
                  )}
                  label="Semester"
                  name="SemesterID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={FormFields?.SemesterID}
                  disabled
                />
              </Col>
              <Col lg="6" md="6" xs="12">
                <FormGroupInput
                  label="Merit Amount"
                  name="MeritAmount"
                  onChange={handleAddChange}
                  value={FormFields?.MeritAmount}
                  isNumber="true"
                  required
                />
              </Col>
              <Col lg="6" md="6" xs="12">
                <FormGroupInput
                  label="Self Finance Amount"
                  name="SelfFinanceAmount"
                  onChange={handleAddChange}
                  value={FormFields?.SelfFinanceAmount}
                  isNumber="true"
                  required
                />
              </Col>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button color="primary" type="submit">
                  Save
                </Button>
                <Button color="default" onClick={() => setToggle(false)}>
                  Cancel
                </Button>
              </div>
            </Row>
          )}
        </form>
      </ModalBody>
    </Modal>
  );

  const customMessage = (
    <div className="text-center">
      <h3>Please Search</h3>
    </div>
  );

  return (
    <Fragment>
      <CrudFormComponent
        formName="Department Fee Structure"
        buttonName="Add"
        hideAction={false}
        tableColumns={columns}
        tableRows={TableList}
        searchPanel={searchPanel}
        formPanel={formPanel}
        formSubmit={submitForm}
        searchSubmit={submitSearch}
        initialFormFields={initialFormFields}
        featureList={menuTable?.Table2?.filter(
          (x) => x.MenuId === selectedMenu
        )}
        cancelSearch={cancelSearch}
        handleCancel={handleCancel}
        customTable={showTable === true ? customTable : customMessage}
        customModal={customModal}
      />
    </Fragment>
  );
};

export default FeeStructure;
