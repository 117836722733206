import React, { Fragment, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import Academics from "./UnderGraduateTabs/Academics";
import Documents from "./UnderGraduateTabs/Documents";
import PersonalInfo from "./UnderGraduateTabs/PersonalInfo";
import Preference from "./UnderGraduateTabs/Preference";
import Undertaking from "./UnderGraduateTabs/Undertaking";
import CheckStatus from "./UnderGraduateTabs/CheckStatus";

const UnderGraduate = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  return (
    <Fragment>
        <Nav pills>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => setActiveTab("1")}
            >
              Personal Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => setActiveTab("2")}
            >
              Academics
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "active" : ""}
              onClick={() => setActiveTab("3")}
            >
              Preferences
            </NavLink>
          </NavItem>
          {/* <NavItem>
          <NavLink
            className={activeTab === "4" ? "active" : ""}
            onClick={() => setActiveTab("4")}
          >
            Eligibilty Certificate
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "5" ? "active" : ""}
            onClick={() => setActiveTab("5")}
          >
            Migration Certificate
          </NavLink>
        </NavItem> */}

          <NavItem>
            <NavLink
              className={activeTab === "4" ? "active" : ""}
              onClick={() => setActiveTab("4")}
            >
              Documents
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "5" ? "active" : ""}
              onClick={() => setActiveTab("5")}
            >
              Undertaking
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "6" ? "active" : ""}
              onClick={() => setActiveTab("6")}
            >
              Status
            </NavLink>
          </NavItem>
          {/* <NavItem>
          <NavLink
            className={activeTab === "7" ? "active" : ""}
            onClick={() => setActiveTab("7")}
          >
            Approval
          </NavLink>
        </NavItem> */}
        </Nav>
        <TabContent className="tab-content-inner" activeTab={activeTab}>
          <TabPane tabId="1">
            <PersonalInfo {...props} />
          </TabPane>
          <TabPane tabId="2">
            <Academics {...props} />
          </TabPane>
          <TabPane tabId="3">
            <Preference {...props} />
          </TabPane>
          {/* <TabPane tabId="4">
          <EligibiltyCertificate
            {...props}
          />
        </TabPane>
        <TabPane tabId="5">
          <MigrationCertificate
            {...props}
          />
        </TabPane> */}
          <TabPane tabId="4">
            <Documents {...props} />
          </TabPane>
          <TabPane tabId="5">
            <Undertaking {...props} />
          </TabPane>

          <TabPane tabId="6">
            <CheckStatus {...props} />
          </TabPane>
          {/* <TabPane tabId="7">
          <Approval
            {...props}
          />
        </TabPane> */}
        </TabContent>
    </Fragment>
  );
};
export default UnderGraduate;
