import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Table } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import {
  GENERATE_VOUCHER,
  SUPPLE_IMPROVEMENT,
} from "../../../utils/UrlConstants";
import { PostRequest } from "../../../utils/Config";
import { decryptData } from "../../../EncryptData";
import {
  Select,
  SessionStorage,
  academicYearId,
  partYearID,
  semesterId,
} from "../../../common/SetupMasterEnum";
import { TYPE, UserNetworkInfo } from "../../../utils/EncryptedConstants";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import { useReactToPrint } from "react-to-print";
import { useState } from "react";
import { useRef } from "react";
import ChallanForEnrollment from "../../Admissions/ChallanForEnrollment";

const SuppleImprovement = () => {
  const initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    EnrollmentDetailID: 0,
    HodRemarks: "string",
    IsLockByLecturer: true,
    IsLockByHod: true,
  };

  const [EnrolledStudentAcademicRecordDetail_HODOps_TBL_] = useState(
    initialEnrolledStudentAcademicRecordDetail_HODOps_TBL_
  );

  const initialSearchFields = {
    OperationId: Select,
    ParameterID: Select,
    AcademicYearId: 0,
    SemesterId: 0,
    FacultyDepartmentId: 0,
    FacultyLecturerID: 0,
    CourseSemesterMappingID: 0,
    SetupFacultyDepartmentProgramLecturerID: 0,
    CourseSemesterLecturerMappingId: 0,
    UserId: 0,
    EnrolledStudentAcademicRecordDetail_HODOps_TBL_: [
      EnrolledStudentAcademicRecordDetail_HODOps_TBL_,
    ],
  };

  const initialFormFields = {
    OperationId: 0,
    AcademicYearId: 0,
    StudentDetailid: null,
    FeeTypeId: 0,
    FacultyDeptId: 0,
    FacultyDeptProgramId: 0,
    BpVoucherId: 0,
    PartId: 0,
    SemesterId: null,
    UserId: 0,
    UserIP: decryptData(UserNetworkInfo)?.IPv4,
  };

  const { SupportingTables, SearchFields, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [academicYearList] = useSetupDetailList(academicYearId);
  const [PartYear] = useSetupDetailList(partYearID);
  const [semesterList] = useSetupDetailList(semesterId, SearchFields?.PartId);

  const [voucherId, setVoucherId] = useState(null);
  const challanForEnrollment = useRef();
  const timeoutRef = useRef(null);

  useEffect(() => {
    getVouchers();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
  }, []);

  const getVouchers = () => {
    PostRequest(SUPPLE_IMPROVEMENT, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearId"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearId}
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDeptId"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDeptId}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="FacultyDeptProgramId"
          onChange={handleSearchChange}
          value={SearchFields?.FacultyDeptProgramId}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          disabled={!SearchFields?.facultyDeptId}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Part"
          name="PartId"
          onChange={handleSearchChange}
          value={SearchFields?.PartId}
          list={PartYear}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="SemesterId"
          onChange={handleSearchChange}
          value={SearchFields?.SemesterId}
          list={semesterList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {};

  const submitForm = (id) => {};

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
  };

  const columns = [
    { field: "AcademicYear", name: "AcademicYear" },
    { field: "Cnic", name: "Cnic" },
    { field: "FatherName", name: "Father Name" },
    { field: "Name", name: "Name" },
    { field: "Part", name: "Part" },
    { field: "FeeType", name: "Fee Type" },
    { field: "p_BillingMonth", name: "Billing Month" },
    { field: "p_ConsumerNumber", name: "Consumer Number" },
    { field: "p_Status", name: "Status" },
    { field: "p_Amount", name: "Amount" },
  ];

  const supplyImprovement = (index, obj) => {
    PostRequest(SUPPLE_IMPROVEMENT, {
      ...initialFormFields,
      BpVoucherId: obj.BPVoucherId,
      OperationId: 3,
    })
      .then((res) => {
        setVoucherId(res.data);
        timeoutRef.current = setTimeout(
          () => handlePrintChallanForEnrollment(),
          200
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handlePrintChallanForEnrollment = useReactToPrint({
    content: () => challanForEnrollment.current,
  });

  const customTable = (
    <Fragment>
      <Table bordered striped responsive style={{ width: "100%" }}>
        <thead>
          <tr className="text-center">
            {/* <th>Preview</th> */}
            {columns &&
              columns.map((column, index) => (
                <th key={index}>{column.name}</th>
              ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {TableList?.map((item, ind) => {
            return (
              <tr className="text-center" key={ind}>
                {/* <td className="text-center">
                <a
                  //   href={item.DocumentPath}
                  target="_blank"
                  className="btn-eye btnic btn-secondary"
                >
                  <i className="fa fa-eye"></i>
                </a>
              </td> */}
                <td>{item.AcademicYear}</td>
                <td>{item.Cnic}</td>
                <td>{item.FatherName}</td>
                <td>{item.Name}</td>
                <td>{item.Part}</td>
                <td>{item.FeeType}</td>
                <td>{item.p_BillingMonth}</td>
                <td>{item.p_ConsumerNumber}</td>
                <td>{item.p_Status}</td>
                <td>{item.p_Amount}</td>
                <td className="text-center">
                  <Button
                    color="success"
                    className="btnic"
                    size="sm"
                    onClick={() => supplyImprovement(ind, item)}
                  >
                    <i className="fa fa-file"></i>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div style={{ display: "none" }}>
        <ChallanForEnrollment
          ref={challanForEnrollment}
          BPvoucherId={voucherId}
        />
      </div>
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Supple & Improvement"
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
    />
  );
};

export default SuppleImprovement;
