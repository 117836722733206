import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import { useSelector } from "react-redux";
import { facultyDepartmentId, SessionStorage } from "../../../../common/SetupMasterEnum";
import Swal from "sweetalert2";
import { Admision_CreateAdmissionRecord } from "../../../../utils/Config";
import { ADMISSION_USER_ID, REG_ID } from "../../../../utils/EncryptedConstants";
import { decryptData } from "../../../../EncryptData";

const initialFormFields = {
  AdmDeptChoicePreferenceID: 0,
  ADMRegID: 1,
  DepartmentID: 0,
  UserIP: "192.168.168",
};
const Preference = (props) => {
  const { SupportingTables } = useSelector((state) => state.CrudFormReducer);
  const preferenceRecords = SupportingTables?.students?.Table3;
  const underTakingData = SupportingTables?.students?.Table4[0];
  const personalInfoData = SupportingTables?.students?.Table1[0];
  const departmentId =
    SupportingTables?.AdmissionFacultyDepartmentId == undefined
      ? 0
      : SupportingTables?.AdmissionFacultyDepartmentId;
  const [formFields, setFormFields] = useState(initialFormFields);
  const [preferenceData, setPreferenceData] = useState([]);
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    });
  };
  const basicValues = {
    OperationId: 2,
    UserId: decryptData(ADMISSION_USER_ID, SessionStorage),
    REGID: decryptData(REG_ID, SessionStorage),
    programid: props.Programid,
    UnderTaking:
      underTakingData?.UnderTaking == undefined
        ? 0
        : underTakingData?.UnderTaking,
  };
  useEffect(() => {
    setPreferenceData(preferenceRecords);
  }, [preferenceRecords]);
  
  const columns = [ {field: "AdmissionFacultyDepartmentName", name: "Department Name"},{ field: "FacultyDepartmentProgramName", name: "Program Name" }];

  const onAdd = (e) => {
    e.preventDefault();
    if (formFields.index > -1) {
      preferenceData[formFields.index] = formFields;
    } else if (
      preferenceData.some((x) => x.DepartmentID === formFields.DepartmentID)
    ) {
      Swal.fire({
        title: "Notification",
        icon: "warning",
        text: "Cannot Add Same Department Twice",
      });
    } else {
      if (preferenceData.length > 2) {
        Swal.fire({
          title: "Notification",
          icon: "warning",
          text: "Your 3 Choices have already been added",
        });
      } else {
        preferenceData.push({
          ...formFields,
          index: -1,
        });
      }
    }
    setPreferenceData([...preferenceData]);
    setFormFields({
      ...initialFormFields,
    });
  };
  const onEdit = (index, obj) => {
    setFormFields({
      ...obj,
      index: index,
    });
  };
  const onDelete = (index) => {
    preferenceData.splice(index, 1);
    setPreferenceData([...preferenceData]);
  };
  const onCancel = () => {
    setFormFields({
      ...initialFormFields,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();
    if (preferenceData.length == 0) {
      Swal.fire({
        title: "Error",
        text: "Please Add Academic Information",
        icon: "error",
      });
    } else {
      let formData = new FormData();
      Object.keys(basicValues).forEach((d) => {
        formData.append(d, basicValues[d]);
      });
      preferenceData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `ADMDepartmentChoicePreference_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateAdmission(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateAdmission = (payload) => {
    // setSubmitLoad(true);
    Admision_CreateAdmissionRecord(payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0]?.MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0]?.MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };
  return (
    <Fragment>

      {/* <Card className="mt-3">
        <CardTitle>Preference Records</CardTitle>
        <CardBody> */}
          <Row className="mt-3">
            <Col>
              <FormGroupTable
                columns={columns}
                rows={preferenceData}
               hideAction={true}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" onClick={onUpdate}>
                  Update
                </Button>
              </div>
            </Col>
          </Row> */}
        {/* </CardBody>
      </Card> */}
    </Fragment>
  );
};

export default Preference;
