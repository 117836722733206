import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { SessionStorage } from "../../../../common/SetupMasterEnum";
import FormGroupInput from "../../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../../../components/GeneralComponent/FormGroupTable";
import { decryptData } from "../../../../EncryptData";
import { Setup_UpdateEmployee } from "../../../../utils/Config";

const Reference = (props) => {
  const {id} = useParams()
  const EmplId = id == 0
  ? decryptData("EmplId", SessionStorage)
  : decryptData("EmplGetId", SessionStorage);
const LoginId = decryptData("loginId", SessionStorage);
  const FirstName = props?.firstName;
  const CNIC = props?.CNIC;
  const initialValues = {
    EmployeeId: EmplId,
    UserId: LoginId,
    EmployeeReferenceDetail_: [],
  };
  const employeeReferenceDetail_ = {
    EmployeeId: EmplId,
    ReferenceId: 0,
    PersonName: "",
    ContactNo: "",
    EmailAddress: "",
    CompanyName: "",
    CompanyLocation: "",
    CompanyLocationId: 0,
    Designation: "",
    DesignationId: 0,
    IsActive: 1,
    IsDeleted: 0,
    UserIP: "192.168.168",
  };
  const formData = props?.data;
  const list = props?.list;
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const [referenceFormFields, setReferenceFormFields] = useState({
    ...employeeReferenceDetail_,
  });
  const [referncData, setReferncData] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);
  const handleInputChange = (event) => {
    setReferenceFormFields({
      ...referenceFormFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeSelect = (event) => {
    let obj = {
      ...referenceFormFields,
      [event.target.name]: event.target.value,
      [event.target.selectedName]:
        event.target.selectedName != undefined && event.target.selectedValue,
    };
    setReferenceFormFields(obj);
  };
  useEffect(() => {
    setReferncData(formData);
  }, [formData]);
  const columns = [
    { field: "PersonName", name: "Name" },
    { field: "CompanyName", name: "Company Name" },
    { field: "Designation", name: "Designation" },
    { field: "ContactNo", name: "Phone Number" },
    { field: "EmailAddress", name: "Email Address" },
    { field: "CompanyLocation", name: "Company Location" },
  ];

  const onAdd = (e) => {
    e.preventDefault();
    referenceFormFields.index > -1
      ? (referncData[referenceFormFields.index] = referenceFormFields)
      : referncData.push({
          ...referenceFormFields,
          index: -1,
        });
    setReferncData([...referncData]);
    setReferenceFormFields({
      ...employeeReferenceDetail_,
    });
  };
  const onEdit = (index, obj) => {
    setReferenceFormFields({
      ...obj,
      index: index,
    });
  };
  const onDelete = (index) => {
    referncData.splice(index, 1);
    setReferncData([...referncData]);
  };
  const onCancel = () => {
    setReferenceFormFields({
      ...employeeReferenceDetail_,
    });
  };
  const onUpdate = (e) => {
    e.preventDefault();
    if (referncData.length == 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please Add Reference Information",
      });
    } else {
      let formData = new FormData();
      formData.append("UserId", formFields.UserId);
      formData.append("EmployeeId", formFields.EmployeeId);
      formData.append("FirstName", FirstName);
      formData.append("CNIC", CNIC);
      referncData.forEach((element, index) => {
        Object.keys(element).forEach((item) => {
          formData.append(
            `EmployeeReferenceDetail_[${index}].${item}`,
            element[item]
          );
        });
      });
      updateEmployee(formData);
    }
  };
  /**
   * Request Call Function
   * @param {*} payload
   */

  const updateEmployee = (payload) => {
    setSubmitLoad(true);
    Setup_UpdateEmployee(payload)
      .then((res) => {
        setSubmitLoad(true);
        if (res?.data?.Table[0]?.haserror === 0) {
          setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].Message,
          }).then((results) => {
            if (results.isDismissed) {
            } else if (results.isConfirmed) {
            }
          });
          // getRoles();
        } else {
          setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].Message,
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
        console.error(err);
      });
  };
  return (
    <Fragment>
      <Card className="mt-3">
        <CardTitle>Reference Details</CardTitle>
        <CardBody>
          <form onSubmit={onAdd}>
            <Row>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Name"
                  name="PersonName"
                  value={referenceFormFields.PersonName}
                  onChange={handleInputChange}
                  required
                  maxLength="100"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Company Name"
                  onChange={handleInputChange}
                  name="CompanyName"
                  value={referenceFormFields.CompanyName}
                  required
                  maxLength="200"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Phone#"
                  isNumber="true"
                  onChange={handleInputChange}
                  name="ContactNo"
                  value={referenceFormFields.ContactNo}
                  required
                  maxLength="50"
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  list={list.DesignationList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  label="Designation"
                  onChange={handleInputChangeSelect}
                  nameValue="Designation"
                  name="DesignationId"
                  value={referenceFormFields.DesignationId}
                  required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupSelect
                  list={list.CountryList}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  label="Company location"
                  onChange={handleInputChangeSelect}
                  name="CompanyLocationId"
                  nameValue="CompanyLocation"
                  value={referenceFormFields.CompanyLocationId}
                  // required
                />
              </Col>
              <Col lg="3" md="3" sm="6" xs="12">
                <FormGroupInput
                  label="Email Address"
                  type="email"
                  onChange={handleInputChange}
                  name="EmailAddress"
                  value={referenceFormFields.EmailAddress}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="4"></Col>
              <Col className="text-right" sm="8">
                <Button color="primary">Add/Update</Button>
                <Button color="default" type="button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>List of References</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable
                columns={columns}
                rows={referncData}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-right">
                <Button color="primary" onClick={onUpdate}>
                  {submitLoad ? <Spinner color="light" size="sm" /> : null}
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Reference;
