import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "reactstrap";
import CrudFormComponent from "../../components/FormComponents/CrudFormComponent";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import {
  Delete,
  Search,
  Select,
  citySetupId,
  countrySetupId,
  SessionStorage,
  academicYearId,
  partYearID,
  semesterId,
  campusCity,
  campusType,
  Insert,
  Update,
  undergraduateId,
} from "../../common/SetupMasterEnum";

import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
} from "../../redux/actionType/CrudActionTypes";
import { PostRequest, Setup_MasterDetails_Operation } from "../../utils/Config";
import { decryptData } from "../../EncryptData";
import { UserNetworkInfo } from "../../utils/EncryptedConstants";
import { useState } from "react";
import { ACAD_ACTIVE_ACADEMICS_OPS } from "../../utils/UrlConstants";
import useSetupDetailList from "../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../functions/generalFunctions";
import { CustomErrorMessage, CustomSuccessAlert } from "../../components/Alert";

const AcademicsInProgres = () => {
  const [page, setPageNo] = useState(1);
  const initialSearchFields = {
    operationID: Search,
    pageNumber: page,
    rowsOfPage: 10,
    programID: undergraduateId,
    campusCityID: 1644,
    academicYearID: 0,
    oddEven: 0,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const initialFormFields = {
    operationID: Select,
    pageNumber: page,
    rowsOfPage: 10,
    programID: undergraduateId,
    campusCityID: 1644,
    academicYearID: 0,
    oddEven: 0,
    userID: decryptData("loginId", SessionStorage),
    userIP: decryptData(UserNetworkInfo)?.IPv4,
  };
  const { SearchFields, FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [academicYearList] = useSetupDetailList(academicYearId);

  const oddEvenList = [
    { SetupDetailId: 1, SetupDetailName: "Odd" },
    { SetupDetailId: 2, SetupDetailName: "Even" },
  ];

  useEffect(() => {
    getAcademicsInProgres();
  }, []);

  function getAcademicsInProgres() {
    PostRequest(ACAD_ACTIVE_ACADEMICS_OPS, initialFormFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: initialSearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const columns = [
    { field: "AcademicYear", name: "Academic Year" },
    { field: "DepartProgram", name: "Department Program" },
    { field: "Part", name: "Part" },
    { field: "Semester", name: "Semester" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const handleAddChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_FROM_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="academicYearID"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.academicYearID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Semester"
          name="oddEven"
          list={oddEvenList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.oddEven}
          required
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    const payload = {
      ...SearchFields,
      operationID: Search,
      pageNumber: page,
      rowsOfPage: 10,
      programID: undergraduateId,
      campusCityID: 1644,
      userID: decryptData("loginId", SessionStorage),
      userIP: decryptData(UserNetworkInfo)?.IPv4,
    };

    PostRequest(ACAD_ACTIVE_ACADEMICS_OPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: initialFormFields,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (id) => {
    const payload = {
      ...FormFields,
      operationID: Update,
      pageNumber: page,
      rowsOfPage: 10,
      programID: undergraduateId,
      campusCityID: 1644,
      userID: decryptData("loginId", SessionStorage),
      userIP: decryptData(UserNetworkInfo)?.IPv4,
    };

    PostRequest(ACAD_ACTIVE_ACADEMICS_OPS, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
          getAcademicsInProgres()
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.Message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    getAcademicsInProgres();
  };

  const handleCancel = () => {
    dispatch({
      type: RESET_FORM_FIELDS,
      payload: initialFormFields,
    });
  };

  const formPanel = (
    <Fragment>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="academicYearID"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleAddChange}
          value={FormFields?.academicYearID}
          required
        />
      </Col>
      <Col lg="6" md="6" xs="12">
        <FormGroupSelect
          label="Semester"
          name="oddEven"
          list={oddEvenList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleAddChange}
          value={FormFields?.oddEven}
          required
        />
      </Col>
    </Fragment>
  );
  return (
    <CrudFormComponent
      formName="Academics In Progress"
      buttonName="Active"
      modalSize="lg"
      tableColumns={columns}
      tableRows={TableList}
      formPanel={formPanel}
      searchPanel={searchPanel}
      formSubmit={submitForm}
      searchSubmit={submitSearch}
      // onEdit={onEditRow}
      // onDelete={onDeleteRow}
      initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      processButtonName="Process"
      showSaveButton={false}
      hideAction={true}
      // handleCancel={handleCancel}
    />
  );
};

export default AcademicsInProgres;
