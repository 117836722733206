import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col } from "reactstrap";
import Swal from "sweetalert2";
import {
  academicYearId,
  admissionTypeId,
  campusCity,
  campusType,
  Delete,
  departmentId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  facultyTypeId,
  Insert,
  majorsAdId,
  meritlistId,
  programTypes,
  Search,
  Select,
  selfFinanceMeritList,
  SessionStorage,
  Update
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation
} from "../../../components/Alert";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupButton from "../../../components/GeneralComponent/FormGroupButton";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import { decryptData } from "../../../EncryptData";

import {
  SET_CRUD_FROM_FIELDS,
  RESET_FORM_FIELDS,
  SET_CRUD_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
  RESET_SEARCH_FIELDS
} from "../../../redux/actionType/CrudActionTypes";
import {
  Admission_MeritList,
  ADM_Approved_Reject_SelfFinance,
  Approved_Reject_MeritList,
  Setup_MasterDetails_All_Dropdowns,
  Setup_Setup_ADM_SeatType
} from "../../../utils/Config";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";

const initialSearchFields = {
  OperationID: Search,
  MeritListID: 0,
  OfferedProgramId: 0,
  AcademicYearId: 0,
  CampusID: 0,
  CampusCityID: 0,
  ProgramID: 0,
  ProgramTypeID: 0,
  AdmissionTypeId: 0,
  AdmissionFacultyDepartmentId: 0,
  FacultyDepartmentProgramId: 0,
  SeatTypeId: 0,
  Cnic: "",
  AdmRegId: 0,
  CreatedBy: decryptData("loginId", SessionStorage),
  ModifiedBy: decryptData("loginId", SessionStorage),
  UserIp: decryptData(UserNetworkInfo)?.IPv4
};

const SelfFinanceOperations = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList
  } = useSelector((state) => state.CrudFormReducer);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    getApproveRejectSelfFinance("onLoad");
  }, []);

  const columns = [
    { field: "FormApplicationID", name: "Application ID" },
    { field: "Name", name: "Name" },
    { field: "Cnic", name: "Cnic" },
    { field: "Program", name: "Degree Program" },
    { field: "OfferedStatus", name: "Offered Status" }
  ];

  function getApproveRejectSelfFinance(operation) {
    ADM_Approved_Reject_SelfFinance(
      operation == "onLoad" ? initialSearchFields : SearchFields
    )
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res.data.Table,
            SearchFields:
              operation == "onLoad" ? initialSearchFields : SearchFields
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const onApproved = (item) => {
    const payload = {
      OperationID: Insert,
      OfferedProgramId: item?.OfferedProgramId,
      AcademicYearId: item?.Academicyearid,
      SeatTypeId: 0,
      Cnic: item?.Cnic,
      AdmRegId: item?.AdmRegId,
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: decryptData("loginId", SessionStorage),
      UserIp: decryptData(UserNetworkInfo)?.IPv4
    };
    WarningWithConfirmation(`Are you sure you want to Approve?`).then(
      (result) => {
        if (result.isConfirmed) {
          ADM_Approved_Reject_SelfFinance(payload)
            .then((res) => {
              if (res?.data?.Table?.[0]?.HasError === 0) {
                CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
                getApproveRejectSelfFinance("onAction");
              } else {
                CustomErrorMessage(res?.data?.Table?.[0]?.Message);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    );
  };

  const onReject = (item) => {
    const payload = {
      OperationID: Update,
      OfferedProgramId: item?.OfferedProgramId,
      AcademicYearId: item?.Academicyearid,
      SeatTypeId: 0,
      Cnic: item?.Cnic,
      AdmRegId: item?.AdmRegId,
      CreatedBy: decryptData("loginId", SessionStorage),
      ModifiedBy: decryptData("loginId", SessionStorage),
      UserIp: decryptData(UserNetworkInfo)?.IPv4
    };
    WarningWithConfirmation(`Are you sure you want to Reject?`).then(
      (result) => {
        if (result.isConfirmed) {
          ADM_Approved_Reject_SelfFinance(payload)
            .then((res) => {
              if (res?.data?.Table?.[0]?.HasError === 0) {
                CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
                getApproveRejectSelfFinance("onAction");
              } else {
                CustomErrorMessage(res?.data?.Table?.[0]?.Message);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    );
  };

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearId"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) => x.SetupMasterId == academicYearId
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearId}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Merit List"
          name="MeritListID"
          list={SupportingTables?.MasterDropdown?.filter(
            (x) =>
              x.SetupMasterId == meritlistId &&
              x.SetupDetailId === selfFinanceMeritList
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.MeritListID}
          required
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupInput
          name="Cnic"
          value={SearchFields?.Cnic}
          onChange={handleSearchChange}
          label="Student CNIC"
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    ADM_Approved_Reject_SelfFinance(SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields
    });
    getApproveRejectSelfFinance("onLoad");
  };

  return (
    <CrudFormComponent
      formName="Self Finance Operation"
      //buttonName="Generate"
      searchPanel={searchPanel}
      tableColumns={columns}
      tableRows={TableList}
      onConfirm={onApproved}
      onRefuse={onReject}
      searchSubmit={submitSearch}
      cancelSearch={cancelSearch}
      //   initialFormFields={initialFormFields}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
    />
  );
};

export default SelfFinanceOperations;
