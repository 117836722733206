import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { admissionProgramId } from "../../common/SetupMasterEnum";
import { CustomErrorMessage } from "../../components/Alert";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";

const initialFormFields = {
  Programs: 3479,
};
const EligibilityCriteriaGeneral = () => {
  const {
    SearchFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const [formFields, setFormFields] = useState(initialFormFields);
  const handleInputChange = (e) => {
    if (e.target.value == 0) {
      CustomErrorMessage("Please Select Program");
      setFormFields({
        ...formFields,
        [e.target.name]: 3479,
      });
    } else {
      setFormFields({
        ...formFields,
        [e.target.name]: e.target.value,
      });
    }
  };
  return (
    <Container fluid>
      <Card className="mt-3">
        <CardTitle>General Instructions & Eligibility Criteria</CardTitle>
        <CardBody>
          <Row>
            <Col md="3" lg="3">
              <FormGroupSelect
                label="Program"
                name="Programs"
                list={SupportingTables?.MasterDropdown?.filter(
                  (x) => x.SetupMasterId == admissionProgramId
                )}
                fieldId="SetupDetailId"
                fieldName="SetupDetailName"
                value={formFields.Programs}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="mt-3">
        <CardTitle>
          General Instructions & Eligibility Criteria for Admission for All
          Programs
        </CardTitle>
        <CardBody>
          <Row>
            <Col>
              <div className="eligibilty-journal">
                <ul>
                  {formFields?.Programs == 3479 ? (
                    <li>
                      Every candidate desirous of taking admission to the
                      Graduate (BS Four-Year Program) shall fill online
                      Admission form. The admission form, duly filled-in by the
                      candidates & supported by all the required documents shall
                      be Uploaded at the Online Admission System.
                    </li>
                  ) : (
                    <li>
                      Every candidate desirous of taking admission to the
                      MS,MPhil & PhD Programs shall fill in the online admission
                      form available at official website of the Shah Abdul Latif
                      University, Khairpur (www.salu.edu.pk). he interested
                      candidates are directed to upload all the necessary
                      documents along with challan of Rs. 2,500/- (MS.MPhil) &
                      Rs. 3,000/- (PhD) deposited at designate HBL branches as
                      admission form processing fee.
                    </li>
                  )}
                  {/* <li>
                    Every candidate desirous of taking admission to the MS,
                    MPhil & PhD program shall fill in the online admission form
                    available at official website of the Shah Abdul Latif
                    University, Khairpur (www.salu.edu.pk). The interested
                    candidates are directed to upload all the necessary
                    documents along with challan of Rs. 2,500/- (MS, MPhil) &
                    Rs. 3,000/- (PhD) deposited at designate HBL branches as
                    admission form processing fee.
                  </li> */}
                  <li>
                    Anyone who has passed the H.S.C Part-II Intermediate
                    Examination or any other examination recognized by the
                    University as equivalent to the H.S.C Part-II Intermediate
                    Examination Annual/ Supplementary 2019 to 2022 and Annual
                    Examination 2021 is eligible to seek admission to the
                    relevant (4 Year Program) course provided that he/she
                    fulfills the requirements of minimum percentage of 50% marks
                    for Science/Arts/Commerce/DAE Students and other conditions
                    of admission as may be prescribed from time-to-time.
                  </li>
                  <li>
                    A candidate who has already completed Bachelor’s pass degree
                    is NOT eligible for admission to BS (Four-Year) course in
                    any discipline. .
                  </li>
                  <li>
                    The foreigners may be considered for admission only when
                    nominated/recommended by the Ministry of Education, Ministry
                    of Finance & Economic Affairs Division Government of
                    Pakistan.
                    <ul>
                      <li>
                        The candidate shall have to produce the following
                        documents in original along with their attested
                        photocopies: i. Marks Certificate of last qualifying
                        examination.
                      </li>
                      <li>
                        Pass Certificate of the last qualifying examination.
                      </li>
                      <li>
                        Transfer/Leaving certificate from Principal of the
                        College/Institute last attended.
                      </li>
                      <li>
                        Character Certificate from Principal of the
                        College/Institute last attended.
                      </li>
                    </ul>
                  </li>
                  <li>
                    A candidate who has passed examination from other Board of
                    Intermediate & Secondary Education, Sukkur & Larkana shall
                    NOT be admitted to any class unless he/she obtains an
                    eligibility certificate from The Shaikh Ayaz University
                    Shikarpur, However, the Registrar may issue a provisional
                    admission certificate if he/she is satisfied that the
                    applicant is prima facie eligible for admission in the
                    university on the condition that he/she obtains a final
                    certificate of eligibility before such date as may be fixed
                    by the Vice Chancellor.
                  </li>
                  <li>
                    Vice Chancellor may refuse admission to any candidate
                    without mentioning any reason.
                  </li>
                  <li>
                    All the admissions shall be provisional until approved by
                    the Vice Chancellor.
                  </li>
                  <li>
                    A student of the university admitted to any discipline as a
                    regular student shall NOT appear in any examination as an
                    external candidate during his/her university studentship.
                  </li>
                  <li>
                    No change of department shall be allowed once the admission
                    is taken.
                  </li>
                  <li>
                    A student shall do all the necessary written work, tutorial
                    and seminars as directed by his/her teachers to his/her
                    /their satisfaction.
                  </li>
                  <li>
                    A student shall do nothing, either in or outside the
                    university that may interfere with the orderly
                    administration and discipline or may bring the university
                    and its administration into disrepute.
                  </li>
                  <li>
                    The admission of student shall stand cancelled automatically
                    if he/she occupies the university hostel or any other part
                    forcibly.
                  </li>
                  <li>
                    The university authorities shall not be responsible for
                    transport/residence arrangements of the students.
                  </li>
                  <li>
                    The regular student is required to maintain at least 75% of
                    attendance as eligibility to appear in the examination.
                  </li>
                  <li>
                    No student shall be re-admitted without the prior permission
                    of the Vice Chancellor, in case his/ her admission is
                    cancelled due to any reason.
                  </li>
                  <li>
                    No ex-student shall be given access to the facilities of
                    using the library, laboratories, seminars, etc. without the
                    prior permission of the Vice Chancellor.
                  </li>
                  <li>
                    Each student admitted to a four-year degree program shall
                    obtain identity card on the prescribed form on payment of
                    the prescribed fee.
                  </li>
                  <li>
                    If identity card is lost or destroyed, a duplicate must be
                    obtained immediately at the payment of Rs.400/=. .
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default EligibilityCriteriaGeneral;
