import React, { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Container, Progress, Row } from "reactstrap";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import FormGroupTable from "../../../components/GeneralComponent/FormGroupTable";
import DatePicker from "react-datepicker";
import { dateFormat, dateFormatPlaceholder } from "../../../utils/CommonMethods";

const initialValues = {
  fromDate: "",
  toDate: "",
  name: "",
};
const Probations = () => {
  const [formFields, setFormFields] = useState({
    ...initialValues,
  });
  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };
  const columns = [
    { field: "name", name: "Name" },
    { field: "startDate", name: "Probation Start Date" },
    { field: "toDate", name: "Probation End Date" },
    { field: "extendProbationTo", name: "Extend Probation To" },
    { field: "status", name: "Status" },
  ];
  const rows = [
    {
      name: "Sharlena Rahode",
      startDate: "05-06-1995",
      toDate: "05-06-1995",
      extendProbationTo:"12-02-2022",
      status: "To Confirm",
    },
    {
      name: "Sharlena Rahode",
      startDate: "05-06-1995",
      toDate: "05-06-1995",
      extendProbationTo:"18-11-2022",
      status: "To Confirm",
    },
  ];

  const onConfirm = () => {};
  const onRefuse = () => {};
  const AllDateSet = (event, type) => {
    if (type === "fromDate") {
      setFormFields({
        ...formFields,
        fromDate: event,
      });
    } else if (type === "toDate") {
      setFormFields({
        ...formFields,
        toDate: event,
      });
    }
  };
  return (
    <Container fluid>
      <Card className='mt-3'>
        <CardTitle>Search Probations</CardTitle>
        <CardBody>
          <Row>
            <Col lg='3' md='3' sm='6' xs='12'>
              <div className='form-group'>
                <label className='form-label'>From Date</label>
                <DatePicker
                  selected={formFields.fromDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "fromDate")}
                  className='form-control'
                  name='fromDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
            </Col>
            <Col lg='3' md='3' sm='6' xs='12'>
              <div className='form-group'>
                <label className='form-label'>To Date</label>
                <DatePicker
                  selected={formFields.toDate}
                  dateFormat={dateFormat}
                  onChange={(e) => AllDateSet(e, "toDate")}
                  className='form-control'
                  name='toDate'
                  placeholderText={dateFormatPlaceholder}
                />
              </div>
            </Col>
            <Col lg='3' md='3' xs='12'>
              <FormGroupInput label='Name' name='name' onChange={handleInputChange} value={formFields.name} />
            </Col>
            <Col lg='12' md='12' xs='12' className='text-right'>
              <Button color='primary'>Search</Button>
              <Button color='default'>Cancel</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>Probation List</CardTitle>
        <CardBody>
          <Row>
            <Col>
              <FormGroupTable columns={columns} rows={rows} onConfirm={onConfirm} onRefuse={onRefuse} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Probations;
