import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import {
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import Spinner from "react-spinkit";
import { useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { PostRequest } from "../../../utils/Config";
import { ACAD_FACULTY_DEPART_TRANSFER } from "../../../utils/UrlConstants";
import {
  academicYearId,
  AcademicYearID,
  partYearID,
  Search,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import { decryptData } from "../../../EncryptData";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";

const initialSearchFields = {
  OperationID: Search,
  PageNumber: 1,
  RowsOfPage: 50,
  Cnic: "",
  DT_DepartmentID: 0,
  DT_DepartmentProgramID: 0,
  EnrollmentID: 0,
  StudentID: 0,
};
const initialFormFields = {
  OperationID: Search,
  PageNumber: 1,
  RowsOfPage: 50,
  Cnic: "",
  DT_DepartmentID: 0,
  DT_DepartmentProgramID: 0,
  EnrollmentID: 0,
  StudentID: 0,
};
const FacultyDepartmentTransfer = () => {
  const { SearchFields, FormFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const {
    SupportingTables: { SearchDepartmentStudent },
  } = useSelector((state) => state.CrudFormReducer);
  const [toggleForm, setToggleForm] = useState(false);
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const [academicYearList] = useSetupDetailList(academicYearId);

  const dispatch = useDispatch();

  const timeoutRef = useRef(null);

  //   const printDPLedgerReport = useRef();

  const [formLoad, setFormLoad] = useState(true);
  const columns = [
    { field: "EnrollmentID", name: "Enrollment ID" },
    { field: "AcademicYear", name: "Academic Year" },
    { field: "FacultyDepartment", name: "Faculty Department" },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
    { field: "Part", name: "Part" },
    { field: "Semester", name: "Semester" },
    { field: "Cnic", name: "Cnic" },
  ];
  const columns2 = [
    { field: "AcademicYear", name: "Academic Year" },
    { field: "FacultyDepartment", name: "Faculty Department" },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
    { field: "Part", name: "Part" },
    { field: "Semester", name: "Semester" },
    { field: "Cnic", name: "Cnic" },
    { field: "FacultyDepartment", name: "Transfer Faculty Department" },
    { field: "FacultyDepartmentProgram", name: "Transfer Department Program" },
  ];
  useEffect(() => {
    getDepartmentTransferList();
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
    return () => clearTimeout(timeoutRef.current);
  }, []);

  function getDepartmentTransferList() {
    PostRequest(ACAD_FACULTY_DEPART_TRANSFER, initialSearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const submitSearch = () => {
    setFormLoad(true);
    let payload = {
      ...SearchFields,
      operationID: Search,
    };
    PostRequest(ACAD_FACULTY_DEPART_TRANSFER, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const searchDepartment = () => {
    setFormLoad(true);
    let payload = {
      OperationID: 6,
      PageNumber: 1,
      RowsOfPage: 50,
      Cnic: SearchFields.Cnic,
      DT_DepartmentID: 0,
      DT_DepartmentProgramID: 0,
      EnrollmentID: 0,
      StudentID: 0,
    };
    PostRequest(ACAD_FACULTY_DEPART_TRANSFER, payload)
      .then((res) => {
        onChange_Select_Department_Program({
          operationID: 6,
          caseID: 3,
          paremeterID: res?.data?.Table?.[0]?.FacultyDepartmentID,
        }).then((res) => {
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: res,
          });
        });
        // dispatch({
        //   type: SET_INITIAL_CRUD_FORM_STATE,
        //   payload: {
        //     List: res?.data?.Table,
        //     SearchFields: initialSearchFields,
        //   },
        // });
        let data = {
          name: "SearchDepartmentStudent",
          value: res?.data?.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
  };
  const onCancel = () => {
    setToggleForm(false);
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
    let data = {
      name: "SearchDepartmentStudent",
      value: [],
    };
    dispatch({
      type: SET_INITIAL_DROPDOWN_FORM_STATE,
      payload: data,
    });
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 3,
      paremeterID: 0,
    }).then((res) => {
      dispatch({
        type: SET_INITIAL_DROPDOWN_FORM_STATE,
        payload: res,
      });
    });
  };
  const handleDepartChange = (e, index) => {
    let obj = SearchDepartmentStudent[index];
    obj = {
      ...obj,
      [e.target.name]: e.target.value,
    };
    SearchDepartmentStudent[index] = obj;
    // dispatch({ type: SET_CRUD_FROM_FIELDS, payload: TableList });
    if (e.target.name === "FacultyDepartmentID") {
      onChange_Select_Department_Program({
        operationID: 6,
        caseID: 3,
        paremeterID: e.target.value,
      }).then((res) => {
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: res,
        });
      });
    }
    let data = {
      name: "SearchDepartmentStudent",
      value: SearchDepartmentStudent,
    };
    dispatch({
      type: SET_INITIAL_DROPDOWN_FORM_STATE,
      payload: data,
    });
    // dispatch({
    //   type: SET_INITIAL_CRUD_FORM_STATE,
    //   payload: {
    //     List: TableList,
    //     FormFields: initialFormFields,
    //     SearchFields: SearchFields,
    //   },
    // });
  };
  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="AcademicYearID"
          list={academicYearList?.sort(
            (a, b) => a.SetupDetailName - b.SetupDetailName
          )}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          required
          onChange={handleSearchChange}
          value={SearchFields?.AcademicYearID}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="DT_DepartmentID"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.DT_DepartmentID}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="DT_DepartmentProgramID"
          onChange={handleSearchChange}
          value={SearchFields?.DT_DepartmentProgramID}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.DT_DepartmentID == null}
        />
      </Col>
    </Fragment>
  );
  const customModal = (
    <Fragment>
      <Modal
        style={{ minWidth: "98vw", width: "100%", margin: "2vh" }}
        isOpen={toggleForm}
        centered
        modalTransition={{ timeout: 10 }}
      >
        <ModalHeader>Faculty Department Transfer</ModalHeader>
        <ModalBody>
          <form>
            <Row style={{ alignItems: "end" }}>
              <Col lg="6" md="6" xs="12">
                <FormGroupInput
                  label="CNIC"
                  name="Cnic"
                  isNumber="true"
                  maxLength={13}
                  onChange={handleSearchChange}
                  value={SearchFields?.Cnic}
                  required
                />
              </Col>
              <Col lg="6" md="6" xs="12">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => searchDepartment()}
                >
                  Search
                </Button>
              </Col>
            </Row>

            <Card className="mt-3">
              <CardTitle className="card-title-inventory">
                Department Transfer List
              </CardTitle>
              <CardBody>
                <Row>
                  <Col>
                    <Table
                      bordered
                      striped
                      responsive
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          {columns2 &&
                            columns2.map((column, index) => (
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                                key={index}
                              >
                                {column.name}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      {SearchDepartmentStudent?.length > 0 ? (
                        <tbody>
                          {SearchDepartmentStudent?.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.AcademicYear}</td>
                              <td>{item.FacultyDepartment}</td>
                              <td>{item.FacultyDepartmentProgram}</td>
                              <td>{item.Part}</td>
                              <td>{item.Semester}</td>
                              <td>{item.Cnic}</td>
                              <td>
                                {" "}
                                <FormGroupSelect
                                  onChange={(e) => handleDepartChange(e, index)}
                                  list={SupportingTables?.Departments}
                                  fieldId="SetupDetailId"
                                  fieldName="SetupDetailName"
                                  value={item.FacultyDepartmentID}
                                  name="FacultyDepartmentID"
                                />
                              </td>
                              <td>
                                <FormGroupSelect
                                  name="FacultyDepartmentProgramID"
                                  onChange={(e) => handleDepartChange(e, index)}
                                  value={item.FacultyDepartmentProgramID}
                                  list={SupportingTables?.Programs}
                                  fieldName="SetupDetailName"
                                  fieldId="SetupDetailId"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : null}
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="mt-2"
              >
                <Button
                  color="primary"
                  type="button"
                  //   onClick={submitSetup}
                >
                  Submit
                </Button>
                <Button color="default" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );

  const customButton = (
    <Fragment>
      {/* {TableList?.length > 0 ? ( */}
      <Fragment>
        <Button
          color="primary"
          onClick={() => setToggleForm(true)}
          // disabled={TableList?.length > 0 ? false : true}
        >
          Add
        </Button>
      </Fragment>
      {/* ) : null} */}
    </Fragment>
  );
  //   const customTable = (
  //     <Fragment>
  //       {formLoad ? (
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <Spinner className="spinner-custom" name="rotating-plane" />
  //         </div>
  //       ) : (
  //           <Table bordered striped responsive style={{ width: "100%" }}>
  //             <thead>
  //               <tr>
  //                 <th>S.No.</th>
  //                 {TableList?.length > 0
  //                   ? Object.keys(TableList?.[0])?.map((column, index) => (
  //                       <Fragment key={index}>
  //                         <th
  //                           style={{
  //                             whiteSpace: "nowrap",
  //                             overflow: "hidden",
  //                           }}
  //                           key={index + 1}
  //                         >
  //                           {column}
  //                         </th>
  //                       </Fragment>
  //                     ))
  //                   : null}
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {TableList?.length > 0
  //                 ? TableList?.map((data, i) => (
  //                     <tr key={i}>
  //                       <td> {i + 1}</td>
  //                       {Object.values(data)?.map((val, index) => {
  //                         return <td key={index}>{val}</td>;
  //                       })}
  //                     </tr>
  //                   ))
  //                 : null}
  //             </tbody>
  //           </Table>
  //       )}
  //     </Fragment>
  //   );

  // Another way to dynamically get the values of an array without knowing key

  // TableList?.map((x, i) => (
  //   <tr>
  //     <td>{i + 1}</td>
  //     {Object.keys(x)?.map((column, index) => {
  //       return <td key={index + 2}>{x[column]}</td>;
  //     })}
  //   </tr>
  // ))

  //   const handlePrintReport = useReactToPrint({
  //     content: () => printDPLedgerReport.current,
  //   });

  //   const customButton = (
  //     <Fragment>
  //       <Button color="primary" onClick={handlePrintReport}>
  //         Print Degree Program Ledger Report
  //       </Button>
  //       <div style={{ display: "none" }}>
  //         <DPLedgerReport ref={printDPLedgerReport} />
  //       </div>
  //     </Fragment>
  //   );

  return (
    <CrudFormComponent
      formName="Faculty Department Transfer"
      customButton={customButton}
      hideAction={true}
      tableColumns={columns}
      tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customModal={customModal}

      //   customTable={customTable}
    />
  );
};

export default FacultyDepartmentTransfer;
