import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupSelect from "../../components/GeneralComponent/FormGroupSelect";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";
import {
  admissionFacultyTypeId,
  admissionProgramId,
  admissionTypeId,
  campusCity,
  campusType,
  domicileProvinceId,
  facultyDepartmentId,
  facultyDepartmentProgramId,
  majorsAdId,
  majorsId,
  programTypes,
  quotaId,
  typeOfSeatId,
} from "../../common/SetupMasterEnum";
import {
  Admission_CandidateList,
  Admission_GetRegisterRecords,
  Admission_GetStudendAdmissionRecord,
  Admission_UpdateRegistrationRecord,
  PostRequest,
  Setup_MasterDetails_All_Dropdowns,
} from "../../utils/Config";
import { SET_INITIAL_DROPDOWN_FORM_STATE } from "../../redux/actionType/CrudActionTypes";
import Swal from "sweetalert2";
import PreviewUnderGraduate from "../Admissions/AdmissionAppicationPreview/PreviewUnderGraduate";
import { CustomErrorMessage } from "../../components/Alert";
import { decryptData } from "../../EncryptData";
import { REG_ID } from "../../utils/EncryptedConstants";
import Select from "react-select";
import { ADMISSION_UPDATE_REGISTRATION_RECORD } from "../../utils/UrlConstants";

const HostelApplications = () => {
  const [pageNo, setPageNo] = useState(1);
  const initialSearchFields = {
    CampusTypeId: 0,
    CampusCityId: 0,
    ProgramId: 0,
    ProgramTypesId: 0,
    AdmissionTypeId: 0,
    MajorId: 0,
    AdmissionFacultyTypeId: 0,
    FacultyDepartmentId: 0,
    FacultyDepartmentProgramId: 0,
    ApplicationStatusId: -1,
    DomicileID: 0,
    TypeOfSeatId: 0,
    QuotaId: 0,
    Candidate: "0",
    PageNumber: 1,
    RowsOfPage: 10,
  };
  const initialFormFields = {
    StudentApplicationId: 0,
    ApprovalAndReject: 0,
    RegistrationId: 0,
    Remarks: "",
  };

  const initialMoveToFields = {
    StudentApplicationId: 0,
    ApprovalAndReject: 4,
    RegistrationId: 0,
    Remarks: "",
    TypeOfSeatId: 0,
    QuotaId: 0,
  };

  const {
    SearchFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const [formFields, setFormFields] = useState(initialFormFields);
  const [moveToFields, setMoveToFields] = useState(initialMoveToFields);
  const [confirmToolTipOpen, setConfirmToolTipOpen] = useState(false);
  const [revertToolTipOpen, setRevertToolTipOpen] = useState(false);
  const [moveToolTipOpen, setMoveToolTipOpen] = useState(false);
  const [rejectToolTipOpen, setRejectToolTipOpen] = useState(false);
  const [trashToolTipOpen, setTrashToolTipOpen] = useState(false);
  const [programId, setProgramId] = useState("");
  const [toggleForm, setToggleForm] = useState(false);
  const [toggleFormSubmit, setToggleFormSubmit] = useState(false);
  const [toggleMoveTo, setToggleMoveTo] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [domicId, setDomicId] = useState(0);

  const toggle = (data) => {
    if (data === "confirm") {
      setConfirmToolTipOpen(!confirmToolTipOpen);
    } else if (data === "revert") {
      setRevertToolTipOpen(!revertToolTipOpen);
    } else if (data === "move") {
      setMoveToolTipOpen(!moveToolTipOpen);
    } else if (data === "reject") {
      setRejectToolTipOpen(!rejectToolTipOpen);
    } else {
      setTrashToolTipOpen(!trashToolTipOpen);
    }
  };
  useEffect(() => {
    getMasterDetailAllDropdown();
    getRegisteredRecords(initialSearchFields);
    getCandidateRecords();
  }, []);

  function getMasterDetailAllDropdown() {
    const payload = {
      operationId: 1,
    };
    Setup_MasterDetails_All_Dropdowns(payload)
      .then((res) => {
        let data = {
          name: "tables",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getRegisteredRecords(payload) {
    Admission_GetRegisterRecords(payload)
      .then((res) => {
        let data = {
          name: "records",
          value: res.data,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const handleInputChange = (e) => {
    setSearchFields({
      ...searchFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddChange = (e) => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  };
  const handleMoveToChange = (e) => {
    setMoveToFields({
      ...moveToFields,
      [e.target.name]: e.target.value,
    });
  };

  const columns = [
    { field: "RegId", name: "Reg. ID" },
    { field: "AppId", name: "App. ID" },
    { field: "CNICPassportNo", name: "CNIC/B-Form/Passport No" },
    { field: "Applicant", name: "Applicant Name" },
    { field: "CampusCityName", name: "Applicant Type" },
    { field: "Program", name: "Program" },
    { field: "AdmissionTypeId", name: "Admission Type" },
    { field: "MajorName", name: "Designation" },
    { field: "AdmissionFacultyDepartmentName", name: "Faculty Dept." },
    { field: "DepartmentChoices", name: "Faculty Dept. Program" },

    { field: "SeatTypeName", name: "Distance (Km)" },

    { field: "DomicileName", name: "Domicile Province" },
    { field: "DomicileName", name: "Gender" },
    // { field: "DepartmentChoices", name: "Dept. Choices" },
    { field: "ApplicationStatusId", name: "Can. App. Status" },
    { field: "AdminStatusName", name: "Adm. App. Status" },
    { field: "Remarks", name: "Remarks" },
    { field: "Action", name: "Action" },
  ];

  const onSearch = (e) => {
    e.preventDefault();
    getRegisteredRecords(searchFields);
  };

  const updateRegistrationRecord = (payload) => {
    // setSubmitLoad(true);

    // Admission_UpdateRegistrationRecord(payload)
    PostRequest(ADMISSION_UPDATE_REGISTRATION_RECORD, payload)
      .then((res) => {
        // setSubmitLoad(true);
        if (res?.data?.Table[0]?.HasError === 0) {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: res?.data?.Table[0].MESSAGE,
          }).then((results) => {
            if (results.isDismissed) {
              setToggleFormSubmit(false);
              getRegisteredRecords(initialSearchFields);
              setToggleMoveTo(false);
            } else if (results.isConfirmed) {
              setToggleFormSubmit(false);
              getRegisteredRecords(initialSearchFields);
              setToggleMoveTo(false);
            }
          });
        } else {
          // setSubmitLoad(false);
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res?.data?.Table[0].MESSAGE,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Something Went Wrong",
        });
        // setSubmitLoad(false);
      });
  };
  const onEdit = (item) => {
    setFormFields({
      ...formFields,
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 7,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    });
    setToggleFormSubmit(true);
    // const payload = {
    //   StudentApplicationId: item.StudentApplicationId,
    //   ApprovalAndReject: 2,
    //   RegistrationId: item.ADMRegID,
    //   Remarks: item.Remarks,
    // };
    // updateRegistrationRecord(payload);
  };
  const onConfirm = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 2,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    updateRegistrationRecord(payload);
  };

  const onRevert = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 5,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    updateRegistrationRecord(payload);
  };
  const onMove = (item) => {
    setDomicId(item?.DomicileID);
    setToggleMoveTo(true);
    setMoveToFields({
      ...moveToFields,
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 4,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    });
    let SeatTypeId = {
      name: "SeatTypeId",
      value: item.SeatTypeId,
    };
    dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: SeatTypeId });
    let QuotaId = {
      name: "QuotaId",
      value: item.QuotaId,
    };
    dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: QuotaId });
    // const payload = {
    //   StudentApplicationId: item.StudentApplicationId,
    //   ApprovalAndReject: 4,
    //   RegistrationId: item.ADMRegID,
    //   Remarks: item.Remarks,
    // };
    // updateRegistrationRecord(payload);
  };
  const onRefuse = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 3,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    updateRegistrationRecord(payload);
  };
  const onTrash = (item) => {
    const payload = {
      StudentApplicationId: item.StudentApplicationId,
      ApprovalAndReject: 8,
      RegistrationId: item.ADMRegID,
      Remarks: item.Remarks,
    };
    updateRegistrationRecord(payload);
  };

  const handlePrivewClick = (e, item) => {
    e.preventDefault();
    setProgramId(item.ProgramId);
    getStudendRecords(item.ProgramId, item.ADMRegID);
  };
  const handleFormCancel = () => {
    setToggleForm(false);
  };
  const handleFormSubmitCancel = () => {
    setToggleFormSubmit(false);
  };

  const handleMoveToSubmitCancel = () => {
    setToggleMoveTo(false);
    setMoveToFields(initialMoveToFields);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    updateRegistrationRecord(formFields);
  };
  const onSubmitMoveTo = (e) => {
    e.preventDefault();
    // const payload = {
    //   StudentApplicationId: item.StudentApplicationId,
    //   ApprovalAndReject: 4,
    //   RegistrationId: item.ADMRegID,
    //   Remarks: item.Remarks,
    // };
    updateRegistrationRecord(moveToFields);
  };
  function getStudendRecords(programID, ADMRegID) {
    const payload = {
      ADMRegId: ADMRegID,
      Programid: programID,
    };
    Admission_GetStudendAdmissionRecord(payload.ADMRegId, payload.Programid)
      .then((res) => {
        if (res.data.Table[0].HasError === 1) {
          CustomErrorMessage(res.data.Table[0].Column1);
        } else {
          let data = {
            name: "students",
            value: res.data,
          };
          dispatch({
            type: SET_INITIAL_DROPDOWN_FORM_STATE,
            payload: data,
          });
          setToggleForm(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getCandidateRecords() {
    Admission_CandidateList()
      .then((res) => {
        let data = {
          name: "candidates",
          value: res.data.Table,
        };
        dispatch({
          type: SET_INITIAL_DROPDOWN_FORM_STATE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onCancel = () => {
    setSelectedOption(null);
    getRegisteredRecords({ ...initialSearchFields, Candidate: "0" });
    setSearchFields({ ...initialSearchFields, Candidate: "0" });
  };
  const handleInputChangeSelect = (event) => {
    setSelectedOption(event);
    // const result = event.Id;
    const result = event.map((obj) => JSON.stringify(obj.value)).join(",");
    searchFields.Candidate = result;
  };

  const handlePagination = (type) => {
    if (type == "Previous") {
      setPageNo(pageNo - 1);
      searchFields.PageNumber = pageNo - 1;
      getRegisteredRecords(searchFields);
    } else if (type == "Next") {
      setPageNo(pageNo + 1);
      searchFields.PageNumber = pageNo + 1;
      getRegisteredRecords(searchFields);
    }
  };

  return (
    <Container fluid>
      <Card className="mt-3">
        <form onSubmit={onSearch}>
          <CardTitle>Online Applications</CardTitle>
          <CardBody>
            <Row>
              <Col lg="3" md="3" xs="12">
                <Label>Applicant Name</Label>
                <Select
                  closeMenuOnSelect={false}
                  // components={animatedComponents}
                  onChange={handleInputChangeSelect}
                  isMulti={true}
                  options={SupportingTables?.candidates}
                  value={selectedOption}
                  maxMenuHeight="40"
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Programs"
                  name="ProgramId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == admissionProgramId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields.ProgramId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Campus Type"
                  name="CampusTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == campusType
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.CampusTypeId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Campus City"
                  name="CampusCityId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == campusCity &&
                      x.parentid == searchFields?.CampusTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.CampusCityId}
                  onChange={handleInputChange}
                />
              </Col>

              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Applicant Types"
                  name="ProgramTypesId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == programTypes &&
                      x.parentid == searchFields?.CampusCityId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.ProgramTypesId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Admission Type"
                  name="AdmissionTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == admissionTypeId &&
                      x.parentid == searchFields?.ProgramTypesId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.AdmissionTypeId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Designation (Faculty Only)"
                  name="MajorId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == majorsAdId &&
                      x.parentid == searchFields?.AdmissionTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.MajorId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Admission Faculty Type"
                  name="AdmissionFacultyTypeId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == admissionFacultyTypeId &&
                      x.parentid == searchFields?.MajorId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.AdmissionFacultyTypeId}
                  onChange={handleInputChange}
                />
              </Col>

              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department"
                  name="FacultyDepartmentId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentId &&
                      x.parentid == searchFields?.AdmissionFacultyTypeId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.FacultyDepartmentId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md="3" lg="3">
                <FormGroupSelect
                  label="Faculty Department Program"
                  name="FacultyDepartmentProgramId"
                  list={SupportingTables?.tables?.filter(
                    (x) =>
                      x.SetupMasterId == facultyDepartmentProgramId &&
                      x.parentid == searchFields?.FacultyDepartmentId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.FacultyDepartmentProgramId}
                  onChange={handleInputChange}
                  // required
                  // disabled={
                  //   programAppliedFor?.AdmissionTypeId == 0 ||
                  //   programAppliedFor?.MajorId == 0 ||
                  //   programAppliedFor?.AdmissionFacultyTypeId == 0 ||
                  //   programAppliedFor?.AdmissionFacultyDepartmentId == 0
                  //     ? true
                  //     : false
                  // }
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == domicileProvinceId
                  )}
                  label="Domicile Province"
                  name="DomicileID"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.DomicileID}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == typeOfSeatId
                  )}
                  label="Distance (Km)"
                  name="TypeOfSeatId"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.TypeOfSeatId}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg="3" md="3" xs="12">
                <FormGroupSelect
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == quotaId
                  )}
                  label="Gender"
                  name="QuotaId"
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.QuotaId}
                  onChange={handleInputChange}
                  disabled={searchFields?.TypeOfSeatId === 3476 ? true : false}
                />
              </Col>

              <Col lg="4" md="4" sm="12" xs="12">
                <div className="form-group">
                  <Label>Admin Application Status</Label>
                  <div className="form-control" onChange={handleInputChange}>
                    <Input type="radio" value={0} name="ApplicationStatusId" />
                    Pending
                    <Input type="radio" value={5} name="ApplicationStatusId" />
                    Reverted
                    <Input type="radio" value={2} name="ApplicationStatusId" />
                    Approved
                    <Input type="radio" value={3} name="ApplicationStatusId" />
                    Rejected
                    <Input type="radio" value={8} name="ApplicationStatusId" />
                    Trashed
                  </div>
                </div>
              </Col>
              {/* <Col md="3" lg="3">
                <FormGroupSelect
                  label="Type of Seat"
                  name="TypeOfSeatId"
                  list={SupportingTables?.tables?.filter(
                    (x) => x.SetupMasterId == typeOfSeatId
                  )}
                  fieldId="SetupDetailId"
                  fieldName="SetupDetailName"
                  value={searchFields?.TypeOfSeatId}
                  onChange={handleInputChange}
                />
              </Col> */}
            </Row>
            <Row>
              <Col>
                <div className="text-right">
                  <Button color="secondary" type="submit">
                    Search
                  </Button>
                  <Button type="button" onClick={onCancel} color="default">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card>
        <CardTitle>
          <Row>
            <Col lg="6" md="3" xs="12">
              Online Application
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table
            className="adm-app-table"
            bordered
            striped
            responsive
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Preview</th>
                <th>S.No.</th>
                {columns &&
                  columns.map((column, index) => (
                    <th
                      // style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      key={index}
                    >
                      {column.name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {SupportingTables?.records?.Table?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td className="text-center">
                      <Button
                        style={{ lineHeight: "1" }}
                        color="secondary"
                        className="btnic"
                        size="sm"
                        onClick={(e) => handlePrivewClick(e, item)}
                      >
                        <i className="fa fa-eye" color="primary"></i>
                      </Button>
                    </td>
                    <td>{ind + 1}</td>
                    <td>{item.ADMRegID}</td>
                    <td>{item.ApplicationID}</td>
                    <td>{item.Cnic}</td>
                    <td>{item.Name}</td>
                    <td>{item.CampusCityName}</td>
                    <td>{item.ProgramName}</td>
                    <td>{item.AdmissionTypeName}</td>
                    <td>{item.MajorName}</td>
                    <td>{item.AdmissionFacultyDepartmentName}</td>
                    <td>{item.FacultyDepartmentProgramName}</td>
                    <td>{item.SeatTypeName}</td>
                    <td>{item.QuotaName === null ? "-" : item.QuotaName}</td>
                    <td>{item.DomicileName}</td>

                    {/* <td>{item.DepartmentChoice}</td> */}
                    <td style={{ width: "80px" }}>
                      {item.CandidateStatusName}
                    </td>
                    <td>{item.AdminStatusName}</td>
                    <td style={{ width: "140px" }}>
                      <Label>{item.Remarks}</Label>
                      {/* <FormGroupInput
                        name="Remarks"
                        maxLength="150"
                        required
                        onChange={(e) => handleAddChange(e, ind)}
                        value={item?.Remarks}
                      /> */}
                      <Button
                        style={{ lineHeight: "1" }}
                        color="warning"
                        className="btnic"
                        size="sm"
                        onClick={() => onEdit(item)}
                        id="edit"
                      >
                        <i className="fa fa-pencil"> </i>
                      </Button>
                    </td>

                    <td style={{ width: "160px" }}>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="success"
                        className="btnic"
                        size="sm"
                        onClick={() => onConfirm(item)}
                        id="confirm"
                        disabled={
                          item.CandidateStatus === 1
                            ? item.AdminStatus === 0
                              ? false
                              : true
                            : true
                        }
                      >
                        <i className="fa fa-thumbs-o-up"> </i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={confirmToolTipOpen}
                        target="confirm"
                        toggle={() => toggle("confirm")}
                      >
                        Approve
                      </Tooltip>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="primary"
                        className="btnic"
                        size="sm"
                        onClick={() => onRevert(item)}
                        id="revert"
                        disabled={
                          item.CandidateStatus === 1
                            ? item.AdminStatus === 0
                              ? false
                              : true
                            : true
                        }
                      >
                        <i className="fa fa-undo"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={revertToolTipOpen}
                        target="revert"
                        toggle={() => toggle("revert")}
                      >
                        Revert
                      </Tooltip>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="secondary"
                        className="btnic"
                        size="sm"
                        onClick={() => onMove(item)}
                        id="move"
                        disabled={
                          item.AdminStatus === 2 || item.AdminStatus === 4
                            ? false
                            : true
                        }
                      >
                        <i className="fa fa-exchange"> </i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={moveToolTipOpen}
                        target="move"
                        toggle={() => toggle("move")}
                      >
                        Allotment
                      </Tooltip>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="danger"
                        className="btnic"
                        size="sm"
                        onClick={() => onRefuse(item)}
                        id="reject"
                        disabled={
                          item.CandidateStatus === 0 ||
                          item.CandidateStatus === 1
                            ? item.AdminStatus === 0
                              ? false
                              : true
                            : true
                        }
                      >
                        <i className="fa fa-remove"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={rejectToolTipOpen}
                        target="reject"
                        toggle={() => toggle("reject")}
                      >
                        Reject
                      </Tooltip>
                      <Button
                        style={{ lineHeight: "1" }}
                        color="danger"
                        className="btnic"
                        size="sm"
                        onClick={() => onTrash(item)}
                        id="trash"
                        disabled={
                          item.CandidateStatus === 0 ||
                          item.CandidateStatus === 1
                            ? item.AdminStatus === 0
                              ? false
                              : true
                            : true
                        }
                      >
                        <i className="fa fa-trash-o"></i>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        isOpen={trashToolTipOpen}
                        target="trash"
                        toggle={() => toggle("trash")}
                      >
                        Trash
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <Button
                color="default"
                disabled={
                  SupportingTables?.records?.Table1?.[0]?.Min_ == 0
                    ? true
                    : false
                }
                onClick={(e) => handlePagination("Previous")}
              >
                Previous
              </Button>
              <Button color="default" onClick={(e) => handlePagination("Next")}>
                Next
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="text-right">
              <p style={{ fontWeight: "bold", margin: 10 }}>
                Showing {SupportingTables?.records?.Table1?.[0]?.Min_} to{" "}
                {SupportingTables?.records?.Table1?.[0]?.Max_}
              </p>
            </Col>
          </Row>
          <Modal
            isOpen={toggleForm}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
            // backdrop="static"
            size="xl"
          >
            <ModalHeader
            // toggle={toggle}
            >
              Record Scrutiny
            </ModalHeader>
            <ModalBody>
              <form>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {programId === 3480 ? (
                      <PreviewUnderGraduate Programid={programId} />
                    ) : null}
                    {programId === 3479 ? (
                      <PreviewUnderGraduate Programid={programId} />
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="default" onClick={handleFormCancel}>
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={toggleFormSubmit}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
          >
            <ModalHeader
            // toggle={toggle}
            >
              Add Remarks
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroupInput
                      name="Remarks"
                      maxLength="150"
                      required
                      onChange={handleAddChange}
                      value={formFields.Remarks}
                    />
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                    <Button
                      color="default"
                      type="button"
                      onClick={handleFormSubmitCancel}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={toggleMoveTo}
            centered
            // toggle={toggle}
            modalTransition={{ timeout: 10 }}
          >
            <ModalHeader
            // toggle={toggle}
            >
              Move To
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmitMoveTo}>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <FormGroupSelect
                      list={SupportingTables?.tables?.filter((x) =>
                        x.SetupMasterId == typeOfSeatId &&
                        SupportingTables?.SeatTypeId == 3476
                          ? x.SetupDetailId !== SupportingTables?.SeatTypeId
                          : x.SetupMasterId == typeOfSeatId &&
                            x.SetupDetailId !== 3476
                      )}
                      label="Seat Type"
                      name="TypeOfSeatId"
                      fieldId="SetupDetailId"
                      fieldName="SetupDetailName"
                      value={moveToFields?.TypeOfSeatId}
                      onChange={handleMoveToChange}
                      required
                    />
                  </Col>
                  {moveToFields?.TypeOfSeatId == 3477 ? (
                    <Col lg="6" md="6" xs="12">
                      <FormGroupSelect
                        list={SupportingTables?.tables?.filter((x) =>
                          x.SetupMasterId == 1101 && domicId !== 3603
                            ? x.SetupDetailId == 3600
                            : x.SetupMasterId == 1101 && x.SetupDetailId != 3594
                        )}
                        label="Quota"
                        name="QuotaId"
                        fieldId="SetupDetailId"
                        fieldName="SetupDetailName"
                        value={moveToFields?.QuotaId}
                        onChange={handleMoveToChange}
                        required
                      />
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="primary" type="submit">
                      Move To Reserved Seat
                    </Button>
                    <Button
                      color="default"
                      type="button"
                      onClick={handleMoveToSubmitCancel}
                    >
                      Close
                    </Button>
                  </div>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </Container>
  );
};

export default HostelApplications;
