import React, { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Table } from "reactstrap";
import CrudFormComponent from "../../../components/FormComponents/CrudFormComponent";
import FormGroupSelect from "../../../components/GeneralComponent/FormGroupSelect";
import {
  SessionStorage,
  academicYearId,
  Insert,
  Select,
} from "../../../common/SetupMasterEnum";

import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_CRUD_SEARCH_FIELDS,
  RESET_SEARCH_FIELDS,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import { decryptData } from "../../../EncryptData";
import { TERM_BACK_SEMESTER } from "../../../utils/UrlConstants";
import useSetupDetailList from "../../../Hooks/useSetupDetailList";
import { onChange_Select_Department_Program } from "../../../functions/generalFunctions";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import { UserNetworkInfo } from "../../../utils/EncryptedConstants";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
} from "../../../components/Alert";

const initialSearchFields = {
  operationID: 0,
  academicYear: 0,
  termBackID: 0,
  regNo: "",
  cnic: "",
  facultyProgram: 0,
  userId: decryptData("loginId", SessionStorage),
  userIP: decryptData(UserNetworkInfo)?.IPv4,
};

const TermBackSemester = () => {
  const { SearchFields, SupportingTables, TableList } = useSelector(
    (state) => state.CrudFormReducer
  );
  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const [academicYearList] = useSetupDetailList(academicYearId);

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_CRUD_FORM_STATE,
      payload: {
        List: [],
        SearchFields: initialSearchFields,
      },
    });
    onChange_Select_Department_Program({
      operationID: 6,
      caseID: 2,
      paremeterID: 0,
    }).then((res) =>
      dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res })
    );
  }, []);

  const columns = [
    { field: "AcademicYear", name: "Academic Year" },
    { field: "Reg_No", name: "Reg_No" },
    { field: "Name", name: "Name" },
    { field: "Cnic", name: "Cnic" },
    { field: "FacultyDepartment", name: "Faculty Department" },
    { field: "FacultyDepartmentProgram", name: "Faculty Department Program" },
    { field: "Part", name: "Part" },
    { field: "Semester", name: "Semester" },
    { field: "CGPA", name: "CGPA" },
    { field: "Status", name: "Status" },
  ];

  const handleSearchChange = (e) => {
    let data = { name: e.target.name, value: e.target.value };
    dispatch({ type: SET_CRUD_SEARCH_FIELDS, payload: data });
  };

  const searchPanel = (
    <Fragment>
      <Col lg="3" md="3" xs="3">
        <FormGroupInput
          isNumber="true"
          label="Reg No."
          name="regNo"
          maxLength="13"
          onChange={handleSearchChange}
          value={SearchFields?.regNo}
        />
      </Col>
      <Col lg="3" md="3" xs="3">
        <FormGroupInput
          isNumber="true"
          label="CNIC/B-Form"
          name="cnic"
          maxLength="13"
          onChange={handleSearchChange}
          value={SearchFields?.cnic}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Academic Year"
          name="academicYear"
          list={academicYearList}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          onChange={handleSearchChange}
          value={SearchFields?.academicYear}
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department"
          name="FacultyDeptId"
          onChange={async (e) => {
            onChange_Select_Department_Program({
              operationID: 6,
              caseID: 3,
              paremeterID: e.target.value,
            }).then((res) => {
              dispatch({ type: SET_INITIAL_DROPDOWN_FORM_STATE, payload: res });
            });
            handleSearchChange(e);
          }}
          value={SearchFields?.FacultyDeptId}
          list={SupportingTables?.Departments}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
        />
      </Col>
      <Col lg="3" md="3" xs="12">
        <FormGroupSelect
          label="Faculty Department Program"
          name="facultyProgram"
          onChange={handleSearchChange}
          value={SearchFields?.facultyProgram}
          list={SupportingTables?.Programs}
          fieldName="SetupDetailName"
          fieldId="SetupDetailId"
          disabled={SearchFields?.FacultyDeptId == null}
        />
      </Col>
    </Fragment>
  );

  const submitSearch = () => {
    PostRequest(TERM_BACK_SEMESTER, SearchFields)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            SearchFields: SearchFields,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cancelSearch = () => {
    dispatch({
      type: RESET_SEARCH_FIELDS,
      payload: initialSearchFields,
    });
  };

  const onRejoin = (index, obj) => {
    let payload = {
      operationID: Select,
      academicYear: obj?.AcademicYearID,
      termBackID: obj?.TermBackID,
      regNo: obj?.Reg_No,
      cnic: obj?.Cnic,
      facultyProgram: obj?.FacultyDepartmentProgramID,
      userId: decryptData("loginId", SessionStorage),
      userIP: decryptData(UserNetworkInfo)?.IPv4,
    };
    PostRequest(TERM_BACK_SEMESTER, payload)
      .then((res) => {
        if (res?.data?.Table?.[0]?.HasError === 0) {
          CustomSuccessAlert(res?.data?.Table?.[0]?.message);
          submitSearch();
        } else {
          CustomErrorMessage(res?.data?.Table?.[0]?.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const customTable = (
    <Fragment>
      {TableList.length > 0 ? (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr className="text-center">
              <th>S.No</th>
              {columns &&
                columns.map((column, index) => <th>{column.name}</th>)}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {TableList?.map((item, ind) => {
              return (
                <tr className="text-center" key={ind}>
                  <td>{ind + 1}</td>
                  <td>{item.AcademicYear}</td>
                  <td>{item.Reg_No}</td>
                  <td>{item.Name}</td>
                  <td>{item.Cnic}</td>
                  <td>{item.FacultyDepartment}</td>
                  <td>{item.FacultyDepartmentProgram}</td>
                  <td>{item.Part}</td>
                  <td>{item.Semester}</td>
                  <td>{item.CGPA}</td>
                  <td>{item.Status}</td>
                  <td className="text-center">
                    <Button
                      color="success"
                      className="btnic"
                      size="sm"
                      onClick={() => onRejoin(ind, item)}
                      disabled={item?.IsRejoin === 1 ? true : false}
                    >
                      <i className="fa fa-undo"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        "No Data Found"
      )}
    </Fragment>
  );

  return (
    <CrudFormComponent
      formName="Term Back Semester"
      tableColumns={columns}
      // tableRows={TableList}
      searchPanel={searchPanel}
      searchSubmit={submitSearch}
      onRejoin={onRejoin}
      featureList={menuTable?.Table2?.filter((x) => x.MenuId === selectedMenu)}
      cancelSearch={cancelSearch}
      customTable={customTable}
    />
  );
};

export default TermBackSemester;
