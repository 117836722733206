import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { ADM_Department_Program_Candidate_Report } from "../../../utils/Config";
import { Select, undergraduateId } from "../../../common/SetupMasterEnum";
import { forwardRef } from "react";
import logo from "../../../assets/img/header-img.jpg";

const UGTotalReport = forwardRef((props, ref) => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables: { UG_Total_Record },
    TableList,
  } = useSelector((state) => state.CrudFormReducer);
  const dispatch = useDispatch();

  return (
    <div style={{ margin: 20 }} className="portrait-page" ref={ref}>
      <Row>
        <Col>
          <table
            style={{
              fontSize: 12,
              margin: 0,
              backgroundColor: "#ffffff",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    // padding: "10px 20px 10px",
                    // marginTop:-10,
                    paddingBottom: "0px",
                  }}
                >
                  <img width="100%" src={logo} alt="" />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-center card-div underLineText">
            Undergraduate Total Report Academic Year (
            {UG_Total_Record?.Table4?.[0]?.AcademicYear})
          </div>
          <div className="table-responsive">
            <p className="mb-0 mt-3">Undergraduate Resigtered Candidates</p>
            <table
              style={{
                border: "1px",
                borderStyle: "solid",
              }}
              className="table "
            >
              <thead>
                <tr>
                  <th>Department</th>
                  <th>Programs</th>
                  <th className="text-center">Pending</th>
                  <th className="text-center">Approved</th>
                  <th className="text-center">Total</th>
                  <th className="text-center">Paid</th>
                  <th className="text-center">Un-Paid</th>
                </tr>
              </thead>
              <tbody>
                {UG_Total_Record?.Table?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.Department}</td>
                      <td>{item.Programs}</td>
                      <td className="text-center">{item.Pendings}</td>
                      <td className="text-center">{item.Approved}</td>
                      <td className="text-center">{item.Total}</td>
                      <td className="text-center">{item.Paid}</td>
                      <td className="text-center">{item.UnPaid}</td>
                    </tr>
                  );
                })}
                {UG_Total_Record?.Table1?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      <td style={{ fontWeight: "bold" }}>Total</td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        {item.Pendings}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        {item.Approved}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        {item.Total}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        {item.Paid}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center"
                      >
                        {item.UnPaid}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Row style={{marginTop: '450px'}}>
        <Col>
          <div className="table-responsive">
          <p className="mb-0 mt-3">Undergraduate Resigtered Candidates</p>
            <table
              style={{
                border: "1px",
                borderStyle: "solid",
              }}
              className="table "
            >
              <thead>
                <tr>
                  {/* <th>S.No.</th> */}
                  <th
                    style={{
                      width: "20%",
                    }}
                  >
                    Degree Program
                  </th>
                  {UG_Total_Record?.Table2 &&
                    UG_Total_Record?.Table2?.map((column, index) => (
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        {column?.abc}
                      </th>
                    ))}
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Fees Deposited <br /> <small>(Million)</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {UG_Total_Record?.Table3 &&
                  UG_Total_Record?.Table3?.map((item, ind) => (
                    <tr key={ind}>
                      {/* <td>{ind + 1}</td> */}
                      <td
                        style={{
                          width: "20%",
                        }}
                      >
                        {item.Program}
                      </td>
                      <td className="text-center">{item.OpenMerit}</td>
                      <td className="text-center">{item.Selffinance}</td>
                      <td className="text-center">{item.MilitaryQuota}</td>
                      <td className="text-center">{item.BalochistanQuota}</td>
                      <td className="text-center">
                        {item.OverseasStudentsQuota}
                      </td>
                      <td className="text-center">{item.DisabledQuota}</td>
                      <td className="text-center">{item.EmployeeQuota}</td>
                      <td className="text-center">{item.SportsQuota}</td>
                      <td className="text-center">
                        {item.FeesDepositedMillion}
                      </td>
                    </tr>
                  ))}
                {UG_Total_Record?.Table4 &&
                  UG_Total_Record?.Table4?.map((item, index) => {
                    return (
                      <tr row key={index}>
                        {/* <td></td> */}
                        <td style={{ fontWeight: "bold" }}>Total</td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalOpenMerit}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalSelffinance}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalMilitaryQuota}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalBalochistanQuota}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalOverseasStudentsQuota}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalDisabledQuota}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalEmployeeQuota}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center"
                        >
                          {item.TotalSportsQuota}
                        </td>
                        <td
                          className="text-center"
                          style={{ fontWeight: "bold" }}
                        >
                          {item["TotalFeesDeposited(Million)"]}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default UGTotalReport;
