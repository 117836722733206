import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { dateFormat, dateFormatPlaceholder } from "../../utils/CommonMethods";
import FormGroupInput from "../../components/GeneralComponent/FormGroupInput";
import FormGroupTable from "../../components/GeneralComponent/FormGroupTable";

const initialValues = {
  name: "",
  fatherName: "",
  surname: "",
  designation: "",
  gradeBps: "",
  department: "",
  dateOfFirstAppointment: "",
  inBps: "",
  highestQualification: "",
  totalService: "",
  cnic: "",
  cellNumber: "",
  whatsapp: "",
  email: "",

  knowledgeOfProduceAndRegulation: "",
  keepingFilesAndPapersInTidyCondition: "",
  promptnessAndAccuracyInDisposingOfWork: "",

  puntuality:"",
  cooperationInWork: "",
  amenabilityToDisciline: "",
  skillInDrafting: "",
  integrity: "",

  typingSkill: "",

  generalRemarksName:"",

  nameBlockLetters:"",

  designationReportingOfficer:"",

  higherOfficer:"",
  nameBlockLettersHigherOfficer:"",
  designationReportingOfficerHigherOfficer:"",


};

const ACRBPS1To4 = () => {

  const [formFields, setFormFields] = useState({
    ...initialValues,
  });

  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const concernedcolumns = [
    { field: "name", name: "Name" },
    { field: "fatherName", name: "Father Name" },
    { field: "surname", name: "Surname" },
    { field: "designation", name: "Designation" },
    { field: "gradeBps", name: "Grade/BPS" },
    { field: "department", name: "Department" },
    { field: "dateOfFirstAppointment", name: "Date Of First Appointment" },
    { field: "inBps", name: "In BPS" },
    { field: "highestQualification", name: "Highest Qualification" },
    { field: "totalService", name: "Total Service" },
    { field: "cnic", name: "CNIC" },
    { field: "cellNumber", name: "Cell Number" },
    { field: "whatsapp", name: "WhatsApp" },
    { field: "email", name: "Email" },
  ];
  const concernedrows = [
    { name: "Liaquat", fatherName: "Khan", surname: "Farman", designation: "Lecturer", gradeBps: "BPS-17", department: "Technology", 
    dateOfFirstAppointment: "10-01-2008", inBps: "BPS-10", highestQualification: "Graduation", totalService: "5 years", cnic: "43201-1213331-4", cellNumber: "0312-1214444",
    whatsapp: "0312401344", email: "Khan@gmail.com"},

    { name: "Ali", fatherName: "Rehan", surname: "Rehman", designation: "Deen", gradeBps: "BPS-15", department: "Technology", 
    dateOfFirstAppointment: "01-11-2010", inBps: "BPS-5", highestQualification: "Masters", totalService: "1 year", cnic: "41201-9200211-5", cellNumber: "0322-1213333",
    whatsapp: "0321331311", email: "Rehan@gmail.com"},
  ];

    const observancecolumns = [
      { field: "knowledgeOfProduceAndRegulation", name: "Knowledge Of Produce And Regulation" },
      { field: "keepingFilesAndPapersInTidyCondition", name: "Keeping Files And Papers In Tidy Condition" },
      { field: "promptnessAndAccuracyInDisposingOfWork", name: "Promptness And Accuracy In Disposing Of Work" },
    ];
  
    const observancerows = [
      {
        knowledgeOfProduceAndRegulation: "Yes",
        keepingFilesAndPapersInTidyCondition: "	Keeping Files 1",
        promptnessAndAccuracyInDisposingOfWork: "Promptness 1",
      },
      {
        knowledgeOfProduceAndRegulation: "Yes",
        keepingFilesAndPapersInTidyCondition: "	Keeping Files 2",
        promptnessAndAccuracyInDisposingOfWork: "Promptness 2",
      },
    ];
  
    const observationcolumns = [
      { field: "puntuality", name: "Puntuality" },
      { field: "cooperationInWork", name: "Cooperation In Work" },
      { field: "amenabilityToDisciline", name: "Amenability To Disciline" },
      { field: "skillInDrafting", name: "Skill In Drafting" },
      { field: "integrity", name: "Integrity" },
    ];
  
    const observationrows = [
      {
        puntuality: "Yes",
        cooperationInWork: "No",
        amenabilityToDisciline: "Yes",
        skillInDrafting: "Good",
        integrity: "No",
        
      },
      {
        puntuality: "Yes",
        cooperationInWork: "No",
        amenabilityToDisciline: "Yes",
        skillInDrafting: "Good",
        integrity: "No",
      },
    ];
  
    const typingskillcolumns = [
      { field: "typingSkill", name: "Typing Skills" },
    ];
  
    const typingskillrows = [
      {
        typingSkill: "Good",
      },
      {
        typingSkill: "Excellent",
      },
    ];

    const generalremarkscolumns = [
      { field: "generalRemarks", name: "General Remarks" },
      { field: "date", name: "Date" },
      { field: "name", name: "Name" },
      { field: "designation", name: "Designation" },
    ];
  
    const generalremarksrows = [
      {
        name: "YASIR IRFAN",
        generalRemarks: "Good",
        date: "30-11-2009",
        designation: "Professor",
      },
      {
        name: "FARMAN KHAN",
        generalRemarks: "Excellent",
        date: "14-09-2020",
        designation: "Lecturer",
      },
    ];

    const higherofficercolumns = [
      { field: "generalRemarks", name: "General Remarks by Higher Officer" },
      { field: "date", name: "Date" },
      { field: "name", name: "Name" },
      { field: "designation", name: "Designation" },
    ];
  
    const higherofficerrows = [
      {
        name: "USMAAN KHAN",
        generalRemarks: "Good",
        date: "22-12-2004",
        designation: "Lecturer",
      },
      {
        name: "SHAHID KHAN",
        generalRemarks: "Excellent",
        date: "15-12-2021",
        designation: "Professor",
      },
    ];

    const reportperiodcolumns = [
      { field: "fromDate", name: "From Date" },
      { field: "toDate", name: "To Date" },
    ];
  
    const reportperiodrows = [
      {
        fromDate: "11-01-2010",
        toDate: "17-06-2012",
      },
      {
        fromDate: "21-01-2021",
        toDate: "22-12-2021",
      },
    ];


  const AllDateSet = (event, type) => {
    if (type === "fromDate") {
      setFormFields({
        ...formFields,
        fromDate: event,
      });
    } else if (type === "toDate") {
      setFormFields({
        ...formFields,
        toDate: event,
      });
    }
  };

  
  const onEdit = () => { };
  const onDelete = () => { };

  return (
    <Container fluid>
    <h6 className="m-0 pt-3 pb-2">Annual Confidential Report For Employees (BPS 1-4)</h6>
    <Card>
    <CardTitle>Report for the Period</CardTitle>
    <CardBody>
    <Row>
    <Col lg='3' md='3' xs='12'>
    <div className='form-group'>
      <label className='form-label'>From Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
    </Col>
    <Col lg='3' md='3' xs='12'>
    <div className='form-group'>
      <label className='form-label'>To Date</label>
      <DatePicker
        selected={formFields.fromDate}
        dateFormat={dateFormat}
        onChange={(e) => AllDateSet(e, "fromDate")}
        className='form-control'
        name='fromDate'
        placeholderText={dateFormatPlaceholder}
      />
     </div>
    </Col>
    </Row>

   {/* <Row className="pb-3 pt-3">
    <Col lg="12" md="12" sm="12" className="text-right">
    <Button color="primary">Save</Button>
    </Col>
    </Row>
    
    <Row>
    <Col lg='12' md='12' xs='12'>
        <FormGroupTable
          columns={reportperiodcolumns}
          rows={reportperiodrows}
          onEdit={onEdit}
          onDelete={onDelete}
        />
    </Col>
  </Row>*/}

    <p className="m-0 pt-3"><strong>Note:</strong> This report is to be written by the Reporting Officer for each calender year and is to be initiated in the first week of December, unless it is written at any other time of the transfer of the Reporting Officer</p>
   
    </CardBody>
    </Card>

      <Card className='mt-3'>
        <CardTitle>Part-I (To be filled by the Concerned)</CardTitle>
        <CardBody>
          <Row>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Name' name='name' onChange={handleInputChange} value={formFields.name} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Father Name' name='fatherName' onChange={handleInputChange} value={formFields.fatherName} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Surname' name='surname' onChange={handleInputChange} value={formFields.surname} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Designation' name='designation' onChange={handleInputChange} value={formFields.designation} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Grade/BPS' name='gradeBps' onChange={handleInputChange} value={formFields.gradeBps} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Department' name='department' onChange={handleInputChange} value={formFields.department} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <div className='form-group'>
            <label className='form-label'>Date Of First Appointment</label>
            <DatePicker
              selected={formFields.fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "fromDate")}
              className='form-control'
              name='fromDate'
              placeholderText={dateFormatPlaceholder}
            />
           </div>
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='In BPS' name='inBps' onChange={handleInputChange} value={formFields.inBps} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Highest Qualification' name='highestQualification' onChange={handleInputChange} value={formFields.highestQualification} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <div className='form-group'>
            <label className='form-label'>Date Of Birth</label>
            <DatePicker
              selected={formFields.fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "fromDate")}
              className='form-control'
              name='fromDate'
              placeholderText={dateFormatPlaceholder}
            />
          </div> </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Total Service' name='totalService' onChange={handleInputChange} value={formFields.totalService} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='CNIC' name='cnic' onChange={handleInputChange} value={formFields.cnic} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Cell Number' name='cellNumber' onChange={handleInputChange} value={formFields.cellNumber} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='WhatsApp' name='whatsapp' onChange={handleInputChange} value={formFields.whatsapp} />
          </Col>
          <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Email' name='email' onChange={handleInputChange} value={formFields.email} />
          </Col>
          </Row>

         {/* <Row>
          <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
          <Button color="primary">Save</Button>
          </Col>
        </Row>

          <Row>
          <Col lg='12' md='12' xs='12'>
              <FormGroupTable
                columns={concernedcolumns}
                rows={concernedrows}
                onEdit={onEdit}
                onDelete={onDelete}
              />
          </Col>
</Row>*/}
        </CardBody>
      </Card>

      <Card>
      <CardTitle>Part-II (To be filled by the Reporting Officer)</CardTitle>
      <CardBody>
      <h6>1) Observance of office produce e.g.</h6>
      <Row>
        <Col lg='4' md='4' xs='12'>
          <FormGroupInput label='Knowledge Of Produce And Regulation' name=' knowledgeOfProduceAndRegulation' onChange={handleInputChange} value={formFields.knowledgeOfProduceAndRegulation} />
        </Col>
        <Col lg='4' md='4' xs='12'>
        <FormGroupInput label='Keeping Files And Papers In Tidy Condition' name='keepingFilesAndPapersInTidyCondition' onChange={handleInputChange} value={formFields.keepingFilesAndPapersInTidyCondition} />
      </Col>
      <Col lg='4' md='4' xs='12'>
      <FormGroupInput label='Promptness And Accuracy In Disposing Of Work' name='promptnessAndAccuracyInDisposingOfWork' onChange={handleInputChange} value={formFields.promptnessAndAccuracyInDisposingOfWork} />
    </Col>
      </Row>

      {/*<Row>
      <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
      <Button color="primary">Save</Button>
      </Col>
      </Row>
        <Row>
          <Col lg='12' md='12' xs='12'>
              <FormGroupTable
                columns={observancecolumns}
                rows={observancerows}
                onEdit={onEdit}
                onDelete={onDelete}
              />
          </Col>
</Row>*/}
      
        <Row className="pt-3">
        <h6>2) Observation on:</h6>
        <Col lg='3' md='3' xs='12'>
          <FormGroupInput label='Puntuality' name='puntuality' onChange={handleInputChange} value={formFields.puntuality} />
        </Col>
        <Col lg='3' md='3' xs='12'>
        <FormGroupInput label='Cooperation In Work' name='cooperationInWork' onChange={handleInputChange} value={formFields.cooperationInWork} />
      </Col>
      <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Amenability To Disciline' name='amenabilityToDisciline' onChange={handleInputChange} value={formFields.amenabilityToDisciline} />
    </Col>
    <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Skill In Drafting' name='skillInDrafting' onChange={handleInputChange} value={formFields.skillInDrafting} />
    </Col>
    <Col lg='3' md='3' xs='12'>
      <FormGroupInput label='Integrity' name='integrity' onChange={handleInputChange} value={formFields.integrity} />
    </Col>
      </Row>

     {/* <Row>
      <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
      <Button color="primary">Save</Button>
      </Col>
      </Row>
         <Row>
          <Col lg='12' md='12' xs='12'>
              <FormGroupTable
                columns={observationcolumns}
                rows={observationrows}
                onEdit={onEdit}
                onDelete={onDelete}
              />
          </Col>
</Row>*/}

         <Row className="pt-3">
         <h6>3) Typing Skill:</h6>
         <Col lg='3' md='3' xs='12'>
           <FormGroupInput label='Typing Skill' name='typingSkill' onChange={handleInputChange} value={formFields.typingSkill} />
         </Col>
       </Row>
    {/*   <Row>
       <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
       <Button color="primary">Save</Button>
       </Col>
       </Row>
          <Row>
           <Col lg='12' md='12' xs='12'>
               <FormGroupTable
                 columns={typingskillcolumns}
                 rows={typingskillrows}
                 onEdit={onEdit}
                 onDelete={onDelete}
               />
           </Col>
</Row>*/}

          
         <Row className="pt-3">
         <h6>4) General Remarks:</h6>
         <Col lg='12' md='12' xs='12'>
         <FormGroup>
         <Input
             type="textarea"
             rows={6}
             className="faculty-textarea"
         />
     </FormGroup>
         </Col>
       </Row>
       <Row>
       <Col lg='3' md='3' xs='12' >
       <div className='form-group'>
            <label className='form-label'>Dated</label>
            <DatePicker
              selected={formFields.fromDate}
              dateFormat={dateFormat}
              onChange={(e) => AllDateSet(e, "fromDate")}
              className='form-control'
              name='fromDate'
              placeholderText={dateFormatPlaceholder}
            />
           </div>
       </Col>
       <Col lg='3' md='3' xs='12' >
       <FormGroupInput label='Name (in block letters)' name='nameBlockLetters' onChange={handleInputChange} value={formFields.nameBlockLetters} />
       </Col>
       <Col lg='3' md='3' xs='12' >
       <FormGroupInput label='Designation of the Reporting Officer' name='designationReportingOfficer' onChange={handleInputChange} value={formFields.designationReportingOfficer} />
       </Col>
       </Row>
      {/* <Row>
       <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
       <Button color="primary">Save</Button>
       </Col>
       </Row>
          <Row>
           <Col lg='12' md='12' xs='12'>
               <FormGroupTable
                 columns={generalremarkscolumns}
                 rows={generalremarksrows}
                 onEdit={onEdit}
                 onDelete={onDelete}
               />
           </Col>
</Row>*/}

           
         <Row className="pt-3">
         <h6>5) General Remarks by Higher Officer:</h6>
         <Col lg='12' md='12' xs='12'>
         <FormGroup>
         <Input
             type="textarea"
             rows={6}
             className="faculty-textarea"
         />
     </FormGroup>
         </Col>
       </Row>
       <Row>
       <Col lg='3' md='3' xs='12' >
       <div className='form-group'>
       <label className='form-label'>Dated</label>
       <DatePicker
         selected={formFields.fromDate}
         dateFormat={dateFormat}
         onChange={(e) => AllDateSet(e, "fromDate")}
         className='form-control'
         name='fromDate'
         placeholderText={dateFormatPlaceholder}
       />
      </div>
       </Col>
       <Col lg='3' md='3' xs='12' >
       <FormGroupInput label='Name (in block letters)' name='nameBlockLettersHigherOfficer' onChange={handleInputChange} value={formFields.nameBlockLettersHigherOfficer} />
       </Col>
       <Col lg='3' md='3' xs='12' >
       <FormGroupInput label='Designation of the Reporting Officer' name='designationReportingOfficerHigherOfficer' onChange={handleInputChange} value={formFields.designationReportingOfficerHigherOfficer} />
       </Col>
       </Row>
       <Row>
       <Col lg='12' md='12' xs='12' className="text-right pt-3 pb-3">
       <Button color="primary">Save</Button>
       </Col>
       </Row>
          {/*<Row>
           <Col lg='12' md='12' xs='12'>
               <FormGroupTable
                 columns={higherofficercolumns}
                 rows={higherofficerrows}
                 onEdit={onEdit}
                 onDelete={onDelete}
               />
           </Col>
</Row>*/}

       </CardBody>
      </Card>
    </Container>
  );
};

export default ACRBPS1To4