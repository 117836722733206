import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, CardTitle, Table, Tooltip } from "reactstrap";
import {
  Delete,
  Insert,
  Select,
  SessionStorage,
  Update,
} from "../../../common/SetupMasterEnum";
import {
  CustomErrorMessage,
  CustomSuccessAlert,
  WarningWithConfirmation,
} from "../../../components/Alert";
import AcademicFormComponent from "../../../components/FormComponents/AcademicFormComponent";
import FormGroupInput from "../../../components/GeneralComponent/FormGroupInput";
import { decryptData } from "../../../EncryptData";
import {
  SET_ALL_CRUD_FROM_FIELDS,
  SET_INITIAL_CRUD_FORM_STATE,
  SET_INITIAL_DROPDOWN_FORM_STATE,
} from "../../../redux/actionType/CrudActionTypes";
import { PostRequest } from "../../../utils/Config";
import {
  CURRENT_SEMESTER,
  CURRENT_SEMESTER_FACULTY_OPS,
} from "../../../utils/UrlConstants";
import Spinner from "react-spinkit";

const FacultySemesterOutline = () => {
  const {
    SearchFields,
    FormFields,
    TableLoading,
    FormLoading,
    SupportingTables,
    TableList,
  } = useSelector((state) => state.CrudFormReducer);

  const { menuTable, selectedMenu } = useSelector((state) => state.AuthReducer);

  const dispatch = useDispatch();

  const params = useParams();

  const timeoutRef = useRef(null);

  const [formLoad, setFormLoad] = useState(true);

  const [saveToolTip, setSaveToolTip] = useState(false);

  const intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_ = {
    EnrolledStudentAcademicRecordDetailID: 0,
    enrollmentID: 0,
    MidTermExamMarks: 0,
    FinalExamMarks: 0,
    CpMarks: 0,
    IsSaved: true,
    IsLockByLecturer: true,
  };

  const intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_ = {
    FacultyLecturerCourseOutlineTimeSlotMappingID: 0,
    CourseSemesterLocationTimeSlotMappingID: 0,
    OutlineDescription: "",
    IsTaken: false,
    IsCompleted: false,
  };

  const [
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    setEnrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
  ] = useState(intialenrolledStudentAcademicRecordDetail_FacultyOps_TBL_);

  const [
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    setFacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
  ] = useState(
    intialfacultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_
  );

  const intialFormFields = {
    operationId: Select,
    parameterID: Delete,
    employeeID: decryptData("EmplId", SessionStorage),
    courseSemesterLecturerMappingId: 0,
    courseSemesterMappingID: 0,
    userID: decryptData("loginId", SessionStorage),
    userIP: "string",
    enrolledStudentAcademicRecordDetail_FacultyOps_TBL_: [
      enrolledStudentAcademicRecordDetail_FacultyOps_TBL_,
    ],
    facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_: [
      facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_,
    ],
  };

  useEffect(() => {
    getFacultyExamRecap();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const getFacultyExamRecap = () => {
    const payload = {
      ...intialFormFields,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
    };
    PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
      .then((res) => {
        dispatch({
          type: SET_INITIAL_CRUD_FORM_STATE,
          payload: {
            List: res?.data?.Table,
            FormFields: intialFormFields,
          },
        });
        timeoutRef.current = setTimeout(() => setFormLoad(false), 300);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { field: "Courses", name: "Courses" },
    { field: "DayDate", name: "Day" },

    { field: "IsTaken", name: "Lecture Taken" },
    { field: "OutlineDescription", name: "Outline Description" },
  ];

  const handleInputChange = (e, index, name, type) => {
    e.preventDefault();
    if (type == "Check") {
      TableList[index][name] = false;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    } else if (type == "Cross") {
      TableList[index][name] = true;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    } else {
      TableList[index][e.target.name] = e.target.value;
      dispatch({
        type: SET_INITIAL_CRUD_FORM_STATE,
        payload: {
          List: TableList,
          FormFields: FormFields,
        },
      });
    }
  };

  const onSave = () => {
    const payload = {
      ...FormFields,
      operationId: Insert,
      parameterID: Delete,
      courseSemesterLecturerMappingId: params?.id1,
      courseSemesterMappingID: params?.id2,
      facultyLecturerCourseOutlineTimeSlotMappingID_FacultyOps_TBL_: TableList,
    };
    WarningWithConfirmation(
      `Are you sure you want to submit course outline details?`
    ).then((result) => {
      if (result.isConfirmed) {
        PostRequest(CURRENT_SEMESTER_FACULTY_OPS, payload)
          .then((res) => {
            if (res?.data?.Table?.[0]?.HasError === 0) {
              CustomSuccessAlert(res?.data?.Table?.[0]?.Message);
            } else {
              CustomErrorMessage(res?.data?.Table?.[0]?.Message);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  const toggle = (data) => {
    if (data === "Save") {
      setSaveToolTip(!saveToolTip);
    }
  };

  const customTable = (
    <Fragment>
      {formLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner className="spinner-custom" name="rotating-plane" />
        </div>
      ) : (
        <Table bordered striped responsive style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="text-center">S.No.</th>
              {columns &&
                columns?.map((column, index) => (
                  <th
                    style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    key={index}
                    className="text-center"
                  >
                    {column.name}
                  </th>
                ))}
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {TableList?.map((item, index) => (
              <tr key={index}>
                <td
                  style={{ width: "5%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {index + 1}
                </td>
                <td
                  style={{ width: "20%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.Courses}
                </td>
                <td
                  style={{ width: "20%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.DayDate}
                </td>
                <td
                  style={{ width: "10%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  {item.IsTaken === true ? (
                    <a
                      href="#"
                      // onClick={(e) =>
                      //   handleInputChange(e, index, "IsTaken", "Check")
                      // }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-check-circle-o text-success"
                      ></i>
                    </a>
                  ) : (
                    <a
                      href="#"
                      // onClick={(e) =>
                      //   handleInputChange(e, index, "IsTaken", "Cross")
                      // }
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-times text-danger"
                      ></i>
                    </a>
                  )}
                </td>
                <td className="text-center">
                  <FormGroupInput
                    name="OutlineDescription"
                    type="textarea"
                    onChange={(e) => handleInputChange(e, index)}
                    value={item?.OutlineDescription}
                    required
                  />
                </td>
                <td
                  style={{ width: "5%", verticalAlign: "middle" }}
                  className="text-center"
                >
                  <Tooltip
                    placement="bottom"
                    isOpen={saveToolTip}
                    target="Confirm"
                    toggle={() => toggle("Save")}
                  >
                    Save Detail
                  </Tooltip>
                  <Button
                    color="success"
                    className="btnic"
                    size="sm"
                    onClick={onSave}
                    id="Confirm"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );

  return (
    <AcademicFormComponent
      SemesterName="Faculty Semester Outline"
      topColumns={SupportingTables?.StudentDetails}
      columns={columns}
      rows={TableList}
      customTable={customTable}
      //   onClickSave={onSave}
    />
  );
};

export default FacultySemesterOutline;
